import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Colors from '../../utils/app_constants/colors_constants';
import Spinner from '../../components/Spinner/Spinner';
import $ from 'jquery'
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead1 = [
    'GENERAL',
    'A',
    'B',
    'C',
    'D',
    'E',
    'N',
    'Remarks'
];

let tHead2 = [
    'LESSON', '/', '/', '/', '/', '/', '/', ''
];

let tHead3 = [
    'TEACHING SKILLS', '/', '/', '/', '/', '/', '/', ''
];

let tBody1 = [
    'Appearence',
    'Confidence',
    'Level Of Interest',
    'Class Control',
    'Relationship With Pupils'
]
let tBody2 = [
    'Lesson Preparation',
    'Fluency of Language',
    'Clarity/Tone of Language',
    'Handwriting on W/Board',
    'Appropriate use of W/Board',
    'Use of other Teaching Material',
    'Use of Technology in Teaching',
    'Subject Knowledge',
    'Dealing With Homework',
]
let tBody3 = [
    'Introduction',
    'Explaining New Ideas/Material',
    'Questioning Techniques',
    'Involvement of All Pupils',
    'Encouraing Participation',
    'Eliciting Information/Ideas',
    'Organization of Activities',
    'Giving Instruction',
    'Demonstration',
    'Movement Round Class',
    'Time Management',
    'Lession Planning',
    'Lesson Sequencing',
    'Dealing With People Question',
    'Balance of Oral/Writen Work',
    'Oral/Written Corrections'
]

class TeacherObservationBlankTempalte extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            teacherEvaluationDetails: [],
            teacherEvaluation: {},
            evaluationComments: {}
        }
    }

    componentDidMount() {
        const { match } = this.props;
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/TeacherEvaluationForm/${match.params.id}`
            )
            .then(success => {
                this.setState({
                    teacherEvaluationDetails: success.TeacherEvaluationDetails,
                    teacherEvaluation: success.TeacherEvaluations,
                    evaluationComments: success.EvaluationComments,
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.log(error);
            });
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
    };

    render() {
        const { isLoading, teacherEvaluation, teacherEvaluationDetails, evaluationComments } = this.state;

        const Template = (
            <ThemeContext.Consumer>
            {(context) => (
            <section class='mb-4'>
                <div class="divclass">
                    <img src={require('../../Assets/images/header.png')}
                    style={{height: 250}}
                    />
                </div>

                {/*                 <div class='row text-center'  >
                    <h2 class='print' className="h1-responsive font-weight-bold my-4 generalHead" >
                        {' '}
                        DORAT AL KHALEEJ PRIVATE SCHOOL{' '}
                    </h2>
                </div>
 */}                <div class='row ' style={{ flexGrow: 1, borderRight: 0, borderLeft: 0, borderStyle: 'solid', borderColor: context.themeColors.primaryColor, margin: '5px', marginTop: 5 }}>

                    <div class="col-md-4">
                        <strong><ins>Teacher Observation </ins></strong>&nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >
                        </label>
                    </div>
                    <div class='col-md-4'>
                        <strong>Subject: </strong>&nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        > {teacherEvaluation.subjectName}</label>
                    </div>
                    <div class="col-md-4">
                        <strong>Prior Notification </strong>&nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 17, color: '#01425e' }}
                        >{teacherEvaluation.withOrwithoutNotficiation}
                        </label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        School: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.schoolName}</label>
                    </div>
                    <div class='col-md-4' style={{ marginTop: 8 }}>
                        Teacher: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.staff}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Date: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.date}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Class: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.className}</label>
                    </div>
                    <div class='col-md-4' style={{ marginTop: 8 }}>
                        Section: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.sectionName}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Topic/Level: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.topic}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Observed From: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.observerFrom}</label>
                    </div>
                    <div class='col-md-4' style={{ marginTop: 8 }}>
                        Observer's Name: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.observerName}</label>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 8 }}>
                        Designation: &nbsp;
								<label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{teacherEvaluation.designation}</label>
                    </div>

                </div>

                <div style={{ marginTop: 30, color: context.themeColors.primaryColor }}>
                    <div className="table-responsive">
                        <table class="table table-hover" >
                            <thead class='headCol borderSpace' style={{ background: context.themeColors.primaryColor, textAlign: 'center' }}>
                                <tr class="vendorListHeading">
                                    {tHead1.map(item => (
                                        <th scope="col" style={{
                                            border: `1px solid ${context.themeColors.primaryColor}`,
                                            borderColor: context.themeColors.primaryColor
                                        }}
                                        >{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {teacherEvaluationDetails.slice(0, 5).map(item => (
                                    <tr>
                                        <th width='35%' style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.evaluationSkill}</th>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeA === 'A' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeB === 'B' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeC === 'C' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeD === 'D' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeE === 'E' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeN === 'N' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td width='35%' style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.remarks !== '' ? item.remarks : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table class="table table-hover" >

                            <thead class='headCol borderSpace' style={{ background: context.themeColors.primaryColor, textAlign: 'center' }}>
                                <tr class="vendorListHeading">
                                    {tHead2.map(item => (
                                        <th scope="col" style={{
                                            border: `1px solid ${context.themeColors.primaryColor}`,
                                            borderColor: context.themeColors.primaryColor
                                        }}
                                        >{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {teacherEvaluationDetails.slice(5, 14).map(item => (
                                    <tr>
                                        <th width='35%' style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.evaluationSkill}</th>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeA === 'A' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeB === 'B' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeC === 'C' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeD === 'D' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeE === 'E' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeN === 'N' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td width='35%' style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.remarks !== '' ? item.remarks : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table class="table table-hover" >

                            <thead class='headCol borderSpace' style={{ background: context.themeColors.primaryColor, textAlign: 'center' }}>
                                <tr class="vendorListHeading">
                                    {tHead3.map(item => (
                                        <th scope="col" style={{
                                            border: `1px solid ${context.themeColors.primaryColor}`,
                                            borderColor: context.themeColors.primaryColor
                                        }}
                                        >{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {teacherEvaluationDetails.slice(14, 30).map(item => (
                                    <tr>
                                        <th width='35%' style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.evaluationSkill}</th>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeA === 'A' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeB === 'B' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeC === 'C' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeD === 'D' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeE === 'E' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.gradeN === 'N' ? <i style={{ color: context.themeColors.primaryColor }} class="fas fa-check-circle"></i> : '-'}</td>
                                        <td width='35%' style={{ border: `1px solid ${context.themeColors.primaryColor}`, borderColor: context.themeColors.primaryColor }}>{item.remarks !== '' ? item.remarks : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class='row ' style={{ flexGrow: 1, borderBottom: 5, borderRight: 0, borderLeft: 0, borderTop: 0, borderStyle: 'solid', borderColor: context.themeColors.primaryColor, margin: '5px', marginTop: 30 }}>

                    <div class="col-md-12">
                        <h7 >**A=EXCELLENT(5)   B=V.GOOD(4) C=GOOD(3)   D=ACCEPTABLE(2)     E=UNSATISFACTORY(1)     N=Not Applicable(0)</h7>
                    </div>
                </div>
                <div class='row ' style={{ flexGrow: 1, borderBottom: 5, borderRight: 0, borderLeft: 0, borderTop: 0, borderStyle: 'solid', borderColor: context.themeColors.primaryColor, margin: '5px', marginTop: 30 }}>

                    <div class="col-md-12">
                        <strong>Any Other Comments: </strong>
                        <label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{evaluationComments.otherComments}</label>
                    </div>
                </div>
                <div class='row ' style={{ flexGrow: 1, borderBottom: 5, borderRight: 0, borderLeft: 0, borderTop: 0, borderStyle: 'solid', borderColor: context.themeColors.primaryColor, margin: '5px', marginTop: 30 }}>

                    <div class="col-md-12">
                        <strong>Recommendations: </strong>
                        <label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{evaluationComments.recommendation}</label>
                    </div>
                </div>
                <div class='row' style={{ margin: '15px' }}>
                    <div class="col-md-7" style={{ marginTop: 46 }}>
                        <h7>This report has been discussed with the Teacher<strong> YES</strong> / <strong>NO: </strong></h7>
                        <label
                            for="name"
                            class=""
                            style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                        >{evaluationComments.discussion}</label>
                    </div>

                    <div class='col-md-4'>
                        <div class='form-inline'>
                            <div class='col-md-5' style={{ borderStyle: 'solid', borderColor: context.themeColors.primaryColor, height: 65 }}>
                                <h5 style={{ margin: 15 }}><strong>Score: </strong></h5>
                            </div>
                            <div class='col-md-7 text-center' style={{ borderStyle: 'solid', borderColor: context.themeColors.primaryColor, }}>
                                <label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                                >{evaluationComments.score}&nbsp;</label>
                                <div style={{ borderBottom: 0, borderRight: 0, borderLeft: 0, borderTop: 5, borderStyle: 'solid', borderColor: context.themeColors.primaryColor, }}>
                                    <h7><strong>150 </strong></h7>
                                </div>
                            </div>
                        </div>
                        <div class='form-inline'>
                            <div class='col-md-5' style={{ borderStyle: 'solid', borderColor: context.themeColors.primaryColor, marginTop: 10 }}>
                                <label style={{ fontSize: 20, }}><strong>Percentage: </strong></label>
                            </div>
                            <div class='col-md-7' style={{ borderStyle: 'solid', borderColor: context.themeColors.primaryColor, marginTop: 10 }}>
                                <label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                                >&nbsp;{evaluationComments.percentage}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row text-center' style={{ marginTop: 100, alignItems: 'center', justifyContent: 'center' }}>
                    <div class='col-md-6'>
                        <h7 style={{ textDecoration: 'overline' }}>Observer's Signature</h7>
                    </div>
                    <div class='col-md-6'>
                        <h7 style={{ textDecoration: 'overline' }}>Teacher's Signature</h7>
                    </div>
                </div>



            </section>

        )}
    </ThemeContext.Consumer>
        )
        return (
            <div>
                <div id='div1'>
                    <div class='container'>
                        {isLoading ? <Spinner /> : Template}
                    </div>
					<div class="page-footer">
						<div class="text-left">Printed By: {this.props.name}</div>
						<div class="text-right" style={{ marginTop: -25, marginRight: 50 }}>
							Powered by School Smart®
						</div>
					</div>
                </div>

                <div class="text-center text-md-right">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
					</label>
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherObservationBlankTempalte);
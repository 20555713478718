import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import SnackBar from '../../../components/SnackBar/SnackBar';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import Pagination from '../../../components/Pagination/Pagination'
import Spinner from '../../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../../hooks/Services/httpWithRequiredSetData';
import general from '../../../utils/general'
import Colors from '../../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import TextInput from '../../../components/TextInput/TextInput'
import TextArea from '../../../components/TextArea'
import DatePicker from 'react-date-picker';
import themeContext from "../../../context/themeContext/ThemeContext";

let tHead = [
    'Student Name',
    'Grade Name',
    'Game',
    'Date',
    'Reason',
    'Note',
    'Actions'
];

let renderFields = [
    'student',
    'grade',
    'game',
    'date',
    'reason',
    'notes',
]

const ViewOffGame = props => {

  const themeColor = useContext(themeContext);

    const [classId, setClassId] = useState('');
    const [sectionId, setSectionId] = useState('');
    const [date, setDate] = useState('')
    const [gameId, setGameId] = useState('')
    const [id, setId] = useState(null);
    const [gameData, setGameData] = useState([])
    const [reason, setReason] = useState('')
    const [notes, setNotes] = useState('')
    const [accountType, setAccountType] = useState(props.role)
    const [accountId, setAccountId] = useState(props.name)
    const [search, setSearch] = useState('')
    const [studentId, setStudentId] = useState('')

    const [classData, setClassData] = useState([]);
    const [sectionData, setSectionData] = useState([]);
    const [offGameData, setOffGameData] = useState([]);
    const [modalData, setModalData] = useState([])
    const [enabled, setEnabled] = useState('')

    const [isClassSectionLoading, classSectionData] = httpWithRequiredSetData(
        '/api/ClassesSections',
        [],
        data => {
            setClassData(data['classes']);
        }
    );
    const [searchURL, setSearchURL] = useState(
        `/api/SearchAllOffGameRecord?pageNumber=1&pageSize=10&classId=&sectionId=&gameId=&date=`
    );


    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});


    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setOffGameData(data['items']);

        setPaginationData(data['paginationMetadata']);
    });

    const onClassChange = inputClassId => {
        setSectionId('');
        if (inputClassId !== 'Choose Class') {
            console.log(classSectionData);

            let getSection = general.dataFilteration(
                classSectionData['gradeSection'],
                ['sectionId', 'section'],
                {
                    id: inputClassId
                }
            );

            setIsLoading(true);
            setClassId(inputClassId);
            setSectionData(getSection)
            setSectionId('')
        }
    };

    const onDateChange = date => {
        //        const tempDate = general.dateToFormat(date)
        setDate(date)
    }

    const onSectionChange = inputSectionId => {
        if (inputSectionId !== 'Choose Section') {
            setIsLoading(true);
            setSectionId(inputSectionId);
        }
    };


    useEffect(() => {
        props.sharedActions
            .getDataWithoutDispatch(
                '/api/Game'
            )
            .then(success => {
                setGameData(success)
                setIsLoading(false)
            })
            .catch(error =>
                setIsLoading(false)
            )

        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    const onHandleStudentId = (e) => {
        setStudentId(e.target.value)
        setIsLoading(true)

        props.sharedActions
            .getDataWithoutDispatch(
                `/api/SearchAllOffStdeuntId/?pageNumber=${parseInt(currentPage)}&pageSize=${parseInt(customPageSize)}&studentId=${e.target.value}`
            )
            .then(success => {
                setOffGameData(success['items']);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
                setIsLoading(false)
            })
            .catch(error => {
                setOffGameData([])
                setIsLoading(false)
                setPaginationData({})
            })
    }

    const onHandleTextChange = (e) => {
        if (e.target.name === 'notes') {
            setNotes(e.target.value)
        } else if (e.target.name === 'reason') {
            setReason(e.target.value)
        }
    }

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesOffGame(
                    classId,
                    sectionId,
                    gameId,
                    date,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setOffGameData(success['items']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setOffGameData([])
            });
    }, [currentPage, classId, sectionId, gameId, date, customPageSize]);

    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };
    const [msg, setSnackMsg] = useState('');

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const onEditClick = id => {
        onTakeItem(id)
        setEnabled(false)
    };

    const onTakeItem = itemId => {
        setModalData([])
        let getData = offGameData.filter(item => item.id === itemId);

        setModalData(getData)
        setId(getData[0].id)
        setNotes(getData[0].notes)
        setReason(getData[0].reason)
    };

    const onClickDelete = () => {
        setIsLoading(true)

        props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/OffGame/',
                id
            )
            .then(success => {
                // if (success.status === 200) {
                let getNewFilterArray = offGameData.filter(item => item.id !== id);
                setSnackMsg('Remove Successfully!');
                setOffGameData(getNewFilterArray);
                setIsLoading(false)
                props.snackbar();
                setId(null);
                // }
            })
            .catch(error => {
                setIsLoading(false)
                console.log(error);
            });
    };

    const onHandleSelectChange = (e) => {
        if (e.target.name === 'gameId') {
            if (e.target.value !== 'Game') {
                setGameId(e.target.value)
            }
        } else if (e.target.name === 'search') {
            setSearch(e.target.value)
        }
    }

    const onHandleSubmit = (e) => {
        e.preventDefault();
        var bodyParameters = { notes, reason, accountType, accountId };
        modalData[0].notes = notes
        modalData[0].reason = reason
        modalData[0].accountType = accountType
        modalData[0].accountId = accountId

        props.sharedActions
            .editPatchRecordWithoutDispatch(
                '/api/OffGame/',
                id,
                bodyParameters,
            )
            .then(success => {
                offGameData.forEach((item, index) => {
                    if (item.id === id) {
                        offGameData.splice(index, 1, modalData[0])
                    }
                })
                setEnabled(false)
                setSnackMsg('Edit Record Successfully!')
                props.snackbar();
            })
            .catch(err => { });

    }

    const PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
        window.location.reload();
    };

    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td
                    style={{
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >
                    <a href="#" data-toggle="tooltip" title="Edit!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item.id)}
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <i class="fas fa-pen"></i>
                        </button>
                    </a>


                    <a href="#" data-toggle="tooltip" title="Delete!">
                        <button
                            onClick={() => onEditClick(item.id)}
                            data-toggle="modal"
                            data-target="#myModal1"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                background: 'none',
                                marginLeft: 5,
                                marginBottom: 5
                            }}
                        >
                            <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                        </button>
                    </a>
                </td>
            </tr>
        ));

    const SearchByStudentId = (
        <React.Fragment>
            <TextInput
                feildName={'studentId'}
                inputLabelName={'Student ID'}
                onHandleChange={onHandleStudentId}
                errors={{}}
                stateData={{
                    studentId: studentId
                }}
                placeholder={'StudentId'}
                isValidte={false}
                customWidth={true}
                enterWidth={'100%'}
            />
        </React.Fragment>)

    const Search = (
        <React.Fragment>
            <SelectionInput
                newFlag="customWidth"
                feildName="classId"
                selectName={'Class'}
                selectedText={'Choose Class'}
                optionsArrys={classData}
                errors={{}}
                isValidte={false}
                stateData={{
                    classId: classId
                }}
                optionType="dynamic"
                onHandleChange={e => onClassChange(e.target.value)}
                property={'className'}
                propertyId={'id'}
                useFlag={false}
            />
            <SelectionInput
                newFlag="customWidth"
                feildName="sectionId"
                selectName={'Section'}
                selectedText={'Choose Section'}
                optionsArrys={sectionData}
                errors={{}}
                isValidte={false}
                stateData={{
                    sectionId: sectionId
                }}
                optionType="dynamic"
                onHandleChange={e => onSectionChange(e.target.value)}
                property={'section'}
                propertyId={'sectionId'}
                useFlag={false}
            />

            <SelectionInput
                newFlag="customWidth"
                feildName={'gameId'}
                selectName={'Game'}
                onHandleChange={e => onHandleSelectChange(e)}
                errors={{}}
                optionsArrys={gameData}
                selectedText={'Game'}
                stateData={{
                    gameData: gameData
                }}
                optionType="dynamicWithPropIdAndName"
                property={'game'}
                propertyId={'id'}
                successflag={'successflag'}
                isValidte={false}
                useFlag={false}
            // editUse={'staffEdit'}
            />

            <div class="col-md-6" style={{ marginTop: 25 }}>
                <div class="md-form mb-0">
                    <label for="email" class="">
                        Date On
											</label>
                    <br />
                    <DatePicker
                        onChange={e => onDateChange(e)}
                        value={date}
                        selectDateOnly
                    />
                </div>
            </div>
        </React.Fragment>
    )
    return (
        <div>
            <SnackBar msg={msg} />
            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Details
								</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
								</button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={onHandleSubmit}>
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            <fieldset disabled={!enabled}>
                                                <TextInput
                                                    customWidth={true}
                                                    enterWidth={'220%'}
                                                    feildName={'reason'}
                                                    inputLabelName={'Reason'}
                                                    onHandleChange={onHandleTextChange}
                                                    errors={{}}
                                                    stateData={{
                                                        reason: reason
                                                    }}
                                                    placeholder={'Reason'}
                                                    isValidte={false}
                                                />

                                                <TextArea
                                                    customWidth={true}
                                                    enterWidth={'220%'}
                                                    feildName={'notes'}
                                                    inputLabelName={'Notes'}
                                                    onHandleChange={onHandleTextChange}
                                                    errors={{}}
                                                    stateData={{
                                                        notes: notes
                                                    }}
                                                    // iconClassName={'fas fa-percentage'}
                                                    placeholder={'Add Note'}
                                                    isValidte={false}
                                                />

                                            </fieldset>
                                            <br />
                                            <button
                                                disabled={enabled}
                                                onClick={() => setEnabled(true)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                                style={{background: themeColor.themeColors.primaryColor}}
                                            >
                                                Edit
												</button>
                                            <button
                                                style={{ marginLeft: 5, background: themeColor.themeColors.primaryColor }}
                                                disabled={!enabled}
                                                type="submit"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Save
												</button>
                                        </div>
                                    ))}
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                style={{ background: '#00435D' }}
                                class="btn btn-danger"
                                data-dismiss="modal"
                            >
                                Close
								</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="myModal1">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Confirm
							</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
							</button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Are you sure ?</h4>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div>
                                        <button
                                            // disabled={this.state.enabled}
                                            data-dismiss="modal"
                                            onClick={() => onClickDelete(id)}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            Yes
										</button>
                                    </div>
                                    <div>
                                        <button
                                            data-dismiss="modal"
                                            // disabled={this.state.enabled}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            No
										</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Off Game Details{' '}
                        </h2>
                    </div>
                    <div
                        style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                    >
                        <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={handleClickNext}
                            handleClickNextBack={handleClickNextBack}
                            handleClickNextFrwd={handleClickNextFrwd}
                        />
                        <div
                            style={{
                                width: 'fit-content',
                                marginTop: -34,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto'
                            }}
                        >
                            <SelectionInput
                                selectName="Entries"
                                selectFont={14}
                                selectFontWeight={'bold'}
                                newFlag="customWidth"
                                feildName="customPageSize"
                                selectedText={'Page Entries'}
                                optionsArrys={['10', '20', '30', '40', '50']}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    customPageSize: customPageSize
                                }}
                                optionType="static"
                                onHandleChange={onChangePageSize}
                                useFlag={false}
                            />
                        </div>
                    </div>
                </div>
                <CustomFilterSectionLayout displayDirection="column">
                    <div
                        style={{
                            width: '30%',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >

                        <SelectionInput
                            selectName={'Search By'}
                            newFlag="customWidth"
                            feildName="search"
                            selectedText={'Search By'}
                            optionsArrys={['Search By Class', 'Search By Student ID']}
                            errors={{}}
                            isValidte={false}
                            stateData={{
                                search
                            }}
                            optionType="static"
                            onHandleChange={e => onHandleSelectChange(e)}
                            useFlag={false}
                        />
                    </div>

                    <div
                        style={{
                            width: '27%',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >

                        {search === 'Search By Class' ? (
                            Search
                        ) : search === 'Search By Student ID' ? (
                            SearchByStudentId
                        ) : ''}
                    </div>
                </CustomFilterSectionLayout>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) :
                offGameData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>
                    : (

                        <div id='div1' className="table-responsive">
                            <div class="">
          <img
            src={require("../../../Assets/images/header.png")}
            style={{
              width: "100%",
              height: "150",
            }}
          />
        </div>
                            <table class="table table-hover">
                                <thead style={{ background:themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <tr>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            <th
                                                style={{
                                                    border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                    borderColor: Colors.WHITE
                                                }}
                                                scope="col"
                                            >
                                                {item}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{bodyRendering(offGameData)}</tbody>
                            </table>
                            <div class="page-footer">
            <div class="text-left">Printed By: {props.role}</div>
            <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
              Powered by School Smart®
            </div>
          </div>
                        </div>
                    )}
                     <div class="text-center text-md-right">
                    <label for="name" class="" onClick={() => PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewOffGame);
import React from 'react'
import Button from '../../components/Button';
import ThemeContext from "../../context/themeContext/ThemeContext";

const constraints = {
    audio: false,
    video: {
        width: 1280, height: 720
    }
}

export default class CaptureVisitor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            enabled: false
        }

        this.switchCamera = this.switchCamera.bind(this);

    }
    async componentDidMount() {
/*         try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints)
            this.handleSuccess(stream)
            console.log(stream);
        }
        catch (e) {
            console.log(e.toString());
        }
 */    }

    handleSuccess = (stream) => {
        const video = document.getElementById('video')
        window.stream = stream;
        video.srcObject = stream;
    }

    stopStreamedVideo = () => {
        const videoElem = document.getElementById('video')
        const stream = videoElem.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach(function (track) {
            track.stop();
        });

        videoElem.srcObject = null;
    }

    async switchCamera() {
        const { enabled } = this.state;
        if (enabled) {
            this.setState({
                enabled: !enabled
            })

            console.log('if');

            const videoElem = document.getElementById('video')
            const stream = videoElem.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(function (track) {
                track.stop();
            });
            videoElem.srcObject = null;
        } else {
            console.log('else');
            this.setState({
                enabled: !enabled
            })

            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints)
                this.handleSuccess(stream)
                console.log(stream);
            }
            catch (e) {
                console.log(e.toString());
            }
        }
    }

    render() {
        const { enabled } = this.state;
        const renderCamera = (
            <ThemeContext.Consumer>
            {(context) => (
            <div class="md-form mb-0">
                <div class='col-md-12'>
                    <label >
                        <p style={{ margin: 0, color: '#000' }}>
                            {'Visitor Image'}
                        </p>
                    </label>
                </div>
                <div class='col-md-12'>
                    <video id='video' width='290px' muted playsinline autoPlay style={{ border: 'solid', borderRadius: 10, borderColor: context.themeColors.primaryColor, }}></video>
                </div>
                <div class='col-md-12 form-inline'>
                    <Button
                        width="100%"
                        buttonClass="customButton"
                        btnName="Capture"
                        textColor='white'
                        loaderBmargin={5}
                        stateData={{ isLoading: false }}
                        onHandleSubmit={this.props.onCapture}
                        margin={'0 auto'}
                    />
                    <canvas hidden id='canvas' width='1280px' height='720px' ></canvas>
                </div>
            </div>
                                                                        )}
                                                                        </ThemeContext.Consumer>
        )
        return (
            <React.Fragment>
                {enabled ? renderCamera : ''}
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName={enabled ? 'Hide Camera' : 'Show Camera'}
                    loaderBmargin={5}
                    stateData={{ isLoading: false }}
                    marginTop={20}
                    onHandleSubmit={() => this.switchCamera()}
                    margin={'0 auto'}
                    textColor='white'
                />


            </React.Fragment >
        )
    }
}
import React, { useEffect, useState, useRef, useContext } from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import Spinner from '../../components/Spinner/Spinner';
import Pagination from '../../components/Pagination/Pagination'
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import themeContext from "../../context/themeContext/ThemeContext";

let tHeadStudent = [
    'Name',
    'Class',
    'Section',
    'Certificate',
    'Actions'
];

let renderFieldStudent = [
    'name',
    'className',
    'section',
    'certificateTitle',
]

let tHeadStaff = [
    'Name',
    'Post',
    'Certificate',
    'Actions'
];

let renderFieldStaff = [
    'name',
    'postName',
    'certificateTitle',
]

const EventAttendanceCertificate = (props) => {

    const themeColor = useContext(themeContext);

    const [post, setPost] = useState('');

    const [attendanceData, setAttendanceData] = useState([]);
    const [eventInfo, setEventInfo] = useState({})

    const [msg, setSnackMsg] = useState('');

    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});
    const mounted = useRef();

    const [searchURL, setSearchURL] = useState(
        `/api/EventAttendance?PageNumber=1&pageSize=10&id=${props.match.params.id}&post=`
    );

    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setAttendanceData(data['EventAttendance']);
        setEventInfo(data['eventInfo'])

        setPaginationData(data['paginationMetadata']);
    });

    const onStudentStaffChange = (value) => {
        setPost(value)
    }

    useEffect(() => {
        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                `/api/EventAttendance?PageNumber=${currentPage}&pageSize=${customPageSize}&id=${props.match.params.id}&post=${post}`
            )
            .then(success => {
                setAttendanceData(success['EventAttendance']);
                setEventInfo(success['eventInfo'])
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setAttendanceData([])
            });
    }, [currentPage, post, customPageSize]);

    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    const PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
    };

    const bodyRendering = data =>
        data &&
        data.map((item, index) =>
            post === 'Student' ? (
                (
                    <tr>
                        <th
                            style={{
                                padding: 2,
                                border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                borderColor: themeColor.themeColors.primaryColor
                            }}
                            scope="row"
                        >
                            {index + 1}
                        </th>
                        {renderFieldStudent.map((item1, index) => (
                            <td
                                style={{
                                    fontWeight: `${index === 0 ? 'bold' : 'normal'}`,
                                    border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                    padding: 2,
                                    borderColor: themeColor.themeColors.primaryColor
                                }}
                            >
                                {item[item1]}
                            </td>
                        )
                        )}
                        <td className='displayElemet'
                            style={{
                                border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                padding: 2,
                                borderColor: themeColor.themeColors.primaryColor
                            }}
                        >
                            <Link to={`/dashboard/EventCertificateTemplate1/${item.attendanceId}/${post}`}>
                                <button
                                    id="firstbutton"
                                    // onClick={() => this.onEditClick(item.id)}
                                    onMouseOver={btnTechHover()}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 20,
                                        border: 'none',
                                        marginLeft: 5,
                                        background: 'none',
                                        marginBottom: 5
                                    }}
                                >
                                    <a href="#" data-toggle="tooltip" title="View!">
									<i style={{ color: '#8B4513' }} class="fas fa-award"></i>

                                    </a>
                                </button>
                            </Link>
                            <Link to={`/dashboard/EventCertificateTemplate2/${item.attendanceId}/${post}`}>
                                <button
                                    id="firstbutton"
                                    // onClick={() => this.onEditClick(item.id)}
                                    onMouseOver={btnTechHover()}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 20,
                                        border: 'none',
                                        marginLeft: 5,
                                        background: 'none',
                                        marginBottom: 5
                                    }}
                                >
                                    <a href="#" data-toggle="tooltip" title="View!">
									<i style={{ color: '#D4AF37' }} class="fas fa-award"></i>
                                    </a>
                                </button>
                            </Link>
                        </td>
                    </tr>
                )
            ) : (<tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFieldStaff.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index === 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                )
                )}
                <td className='displayElemet'
                    style={{
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >
                    <Link to={`/dashboard/EventCertificateTemplate1/${item.attendanceId}/${post}`}>
                        <button
                            id="firstbutton"
                            // onClick={() => this.onEditClick(item.id)}
                            onMouseOver={btnTechHover()}
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <a href="#" data-toggle="tooltip" title="View!">
                            <i style={{ color: '#8B4513' }} class="fas fa-award"></i>

                            </a>
                        </button>
                    </Link>
                    <Link to={`/dashboard/EventCertificateTemplate2/${item.attendanceId}/${post}`}>
                        <button
                            id="firstbutton"
                            // onClick={() => this.onEditClick(item.id)}
                            onMouseOver={btnTechHover()}
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <a href="#" data-toggle="tooltip" title="View!">
                            <i style={{ color: '#D4AF37' }} class="fas fa-award"></i>

                            </a>
                        </button>
                    </Link>
                </td>
            </tr>
                )
        );

    return (
        <div id='div1'>
            <SnackBar msg={msg} />
            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Event Attendance Details{' '}
                        </h2>
                    </div>
                    <div className='displayElemet'>
                        <div
                            style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                        >
                            <Pagination
                                disableBPage={disableBPage}
                                disablepage={disablepage}
                                currentPage={currentPage}
                                pageNumber={pageNumber}
                                handleClickNext={handleClickNext}
                                handleClickNextBack={handleClickNextBack}
                                handleClickNextFrwd={handleClickNextFrwd}
                            />
                            <div
                                style={{
                                    width: 'fit-content',
                                    marginTop: -34,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginLeft: 'auto'
                                }}
                            >
                                <SelectionInput
                                    selectName="Entries"
                                    selectFont={14}
                                    selectFontWeight={'bold'}
                                    newFlag="customWidth"
                                    feildName="customPageSize"
                                    selectedText={'Page Entries'}
                                    optionsArrys={['10', '20', '30', '40', '50']}
                                    errors={{}}
                                    isValidte={false}
                                    stateData={{
                                        customPageSize: customPageSize
                                    }}
                                    optionType="static"
                                    onHandleChange={onChangePageSize}
                                    useFlag={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='displayElemet'>
                    <CustomFilterSectionLayout displayDirection="column">
                        <div
                            style={{
                                width: '30%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >

                            <SelectionInput
                                newFlag="customWidth"
                                feildName="post"
                                selectedText={'Student/Staff'}
                                optionsArrys={general.patientType}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    post
                                }}
                                optionType="static"
                                onHandleChange={e => onStudentStaffChange(e.target.value)}
                                useFlag={false}
                            />

                        </div>
                    </CustomFilterSectionLayout>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header text-center" style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                    <h3> <strong>Event Details</strong></h3>
                </div>
                <div class="card-body">
                    <div class='row' style={{ margin: '0 auto' }}>
                        <div class='col-md-5'>
                            <strong style={{ fontSize: 20 }}>Event Name: </strong>&nbsp;
                                        <label
                                for="name"
                                class=""
                                style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                            >{eventInfo.eventName}</label>
                        </div>
                        <div class='col-md-3'>
                            <strong style={{ fontSize: 20 }}>Date: </strong>&nbsp;
                                        <label
                                for="name"
                                class=""
                                style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                            >{eventInfo.date}</label>
                        </div>
                        <div class='col-md-4'>
                            <strong style={{ fontSize: 20 }}>Session: </strong>&nbsp;
                                        <label
                                for="name"
                                class=""
                                style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                            >{eventInfo.session}</label>
                        </div>

                        <br />
                        <br />

                        <div class='col-md-5'>
                            <strong style={{ fontSize: 20 }}>Month: </strong>&nbsp;
                                        <label
                                for="name"
                                class=""
                                style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                            >{eventInfo.month}</label>
                        </div>
                        <div class='col-md-3'>
                            <strong style={{ fontSize: 20 }}>Time: </strong>&nbsp;
                                        <label
                                for="name"
                                class=""
                                style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                            >{eventInfo.time}</label>
                        </div>
                    </div>
                </div>
            </div>

            <br />


            {isLoading ? (
                <Spinner />
            ) :
                attendanceData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>
                    : (
                        <div>
                            <div className="table-responsive">
                                <table class="table table-hover">
                                    <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                        <tr className='vendorListHeading'>
                                            <th scope="col">#</th>
                                            {post === 'Student' ? (tHeadStudent.map(item => (
                                                item === 'Actions' ?
                                                    <th className='displayElemet'
                                                        style={{
                                                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                            borderColor: Colors.WHITE
                                                        }}
                                                        scope="col"
                                                    >
                                                        {item}
                                                    </th>
                                                    :
                                                    <th
                                                        style={{
                                                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                            borderColor: Colors.WHITE
                                                        }}
                                                        scope="col"
                                                    >
                                                        {item}
                                                    </th>
                                            ))) : (tHeadStaff.map(item => (
                                                item === 'Actions' ?
                                                    <th className='displayElemet'
                                                        style={{
                                                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                            borderColor: Colors.WHITE
                                                        }}
                                                        scope="col"
                                                    >
                                                        {item}
                                                    </th>
                                                    :
                                                    <th
                                                        style={{
                                                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                            borderColor: Colors.WHITE
                                                        }}
                                                        scope="col"
                                                    >
                                                        {item}
                                                    </th>
                                            )))}

                                        </tr>
                                    </thead>
                                    <tbody> {bodyRendering(attendanceData)}</tbody>
                                </table>
                            </div>
                        </div>

                    )
            }
            <div class="text-center text-md-right displayElemet">
                <label for="name" class="" onClick={() => PrintContent('div1')}>
                    <i
                        className="fas fa-print fa-2x animated fadeIn"
                        style={{ marginRight: 4 }}
                    />
                        Print
                            </label>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventAttendanceCertificate);
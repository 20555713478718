import { updateGlobalFlag } from "../getAllAdmin";
import axios from "axios/index";
import config from "../../config/config";

export function getAllData(url, dispatchFunc, dispatchFunc2) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .get(`${config.localhttp}${url}`, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        console.log("action creator response ", res);
        console.log(res.data);
        debugger;
        dispatch(dispatchFunc(res.data));
        dispatch(dispatchFunc2(false));
        return res.data;
      });
  };
}
export function gAllData(url, dispatchFunc) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .get(`${config.localhttp}${url}`, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        console.log("action creator response ", res);
        console.log(res.data);
        dispatch(dispatchFunc(res.data));
        return res.data;
      });
  };
}
export function getDataWithoutDispatch(url) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .get(`${config.localhttp}${url}`, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        console.log("action creator response ", res);
        console.log(res.data);
        return res.data;
      });
  };
}
export function getDataWithoutDispatchthunk(url) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return axios
    .get(`${config.localhttp}${url}`, {
      headers: { Authorization: `Bearer  ${token}` },
    })
    .then((res) => {
      console.log("action creator response ", res);
      console.log(res.data);
      return res.data;
    });
}

export function deleteRecord(url, id, dispatchFunc) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .delete(`${config.localhttp}${url}/${id}`, {
        data: { id }, // or data: jdId, depending on how you handle it in the back end
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          dispatch(dispatchFunc(id));
          return res.data;
        }
      });
  };
}
export function editRecord(url, id, data, dispatchFunc) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .put(`${config.localhttp}${url}${id}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          dispatch(dispatchFunc(data));
          return res.data;
        }
      });
  };
}
export function editPatchRecord(url, id, data, dispatchFunc) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .patch(`${config.localhttp}${url}${id}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          dispatch(dispatchFunc(data));
          return res.data;
        }
      });
  };
}
export function editPatchRecordWithoutDispatch(url, id, data) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .patch(`${config.localhttp}${url}${id}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}
export function simplePostReqWithData(url, data, id) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .post(`${config.localhttp}${url}${id}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}
export function simplePostReq(url, id) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .post(
        `${config.localhttp}${url}${id}`,
        {},
        { headers: { Authorization: `Bearer  ${token}` } }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}
export function addDocumentsWithProgressCallback(url, data, setPercent) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .post(`${config.localhttp}${url}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
        onUploadProgress: (progressEvent) => {
          console.log(
            "percentage: ",
            setPercent(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            ) + "%"
          );
        },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}
export function patchDocumentsWithProgressCallback(url, data, setPercent) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .patch(`${config.localhttp}${url}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
        onUploadProgress: (progressEvent) => {
          console.log(
            "percentage: ",
            setPercent(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            ) + "%"
          );
        },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}

export function putDocumentsWithProgressCallback(url, data, setPercent) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .put(`${config.localhttp}${url}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
        onUploadProgress: (progressEvent) => {
          console.log(
            "percentage: ",
            setPercent(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            ) + "%"
          );
        },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}

export function editRecordWithoutDispatchAndId(url, data) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .put(`${config.localhttp}${url}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}

export function editRecordWithoutDispatch(url, id, data) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .put(`${config.localhttp}${url}${id}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}
export function editRecordWithoutThunk(url, id, data) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");

  return axios
    .put(`${config.localhttp}${url}${id}`, data, {
      headers: { Authorization: `Bearer  ${token}` },
    })
    .then((res) => {
      if (res.status == 200) {
        console.log("action creator response ", res);
        console.log(res.data);
        return res.data;
      }
    });
}

export function simpleAddRequest(url, data) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .post(`${config.localhttp}${url}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("action creator response ", res);
          console.log(res.data);

          return res.data;
        }
      });
  };
}
export function simpleAddRequestWithoutThunk(url, data) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return axios
    .post(`${config.localhttp}${url}`, data, {
      headers: { Authorization: `Bearer  ${token}` },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log("action creator response ", res);
        console.log(res.data);

        return res.data;
      }
    });
}
export function patchParticularRecord(url, data) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .patch(`${config.localhttp}${url}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("action creator response ", res);
          console.log(res.data);

          return res.data;
        }
      });
  };
}
export function addRecord(url, data, dispatchFunc) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .post(`${config.localhttp}${url}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          dispatch(dispatchFunc(data));
          return res.data;
        }
      });
  };
}

export function addRecordWithoutDispatch(url, data) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .post(`${config.localhttp}${url}`, data, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}

export function deleteRecordWithoutDispatch(url, id) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .delete(`${config.localhttp}${url}/${id}`, {
        data: { id }, // or data: jdId, depending on how you handle it in the back end
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}
export function deleteRecordWithoutDispatchWithoutSlash(url, id) {
  let token = "";
  if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
  return (dispatch) => {
    return axios
      .delete(`${config.localhttp}${url}${id}`, {
        data: { id }, // or data: jdId, depending on how you handle it in the back end
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("action creator response ", res);
          console.log(res.data);
          return res.data;
        }
      });
  };
}

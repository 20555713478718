import React from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import Spinner from '../../components/Spinner/Spinner';
import Colors from '../../utils/app_constants/colors_constants';
import TextInput from '../../components/TextInput/TextInput'
import TextArea from '../../components/TextArea'
import { Link } from 'react-router-dom';
import ThemeContext from "../../context/themeContext/ThemeContext";

const tHead = [
    'Award Name',
    'Account ID',
    'Account Type',
    'Actions'
]

class ViewAwardCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: props.name,
            accountType: props.role,
            awardCategoryData: [],
            isLoading: true,
            errorMsg: false,
            id: '',
            modalData: [],
            modalAwardName: '',
            errors: {}
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/AwardCategory/`
            )
            .then(success => {
                this.setState({
                    awardCategoryData: success,
                    isLoading: false
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: '',
                    errorMsg: false,
                    snackColor: ''
                });
            }, 3000);
        }
    }

    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onEditClick = itemId => {
        this.onTakeItem(itemId);
        this.setState({ enabled: false });
    };

    onTakeItem = itemId => {
        this.setState({
            modalData: []
        });
        const { awardCategoryData } = this.state;
        let getData = awardCategoryData.filter(item => item.id === itemId);

        this.setState({
            modalData: getData,
            id: itemId,
            modalAwardName: getData[0].awardName
        })

    }

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    onTakeItemDelete = itemId => {
        const { awardCategoryData } = this.state;

        this.setState({
            isLoading: true
        })

        this.props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/AwardCategory/',
                itemId,
            )
            .then(success => {
                let getNewFilterArray = awardCategoryData.filter(item => item.id !== itemId);
                this.setState({
                    msg: 'Delete Record Successfully!',
                    awardCategoryData: getNewFilterArray,
                    isLoading: false
                });
                this.props.snackbar();
            })
            .catch(error => { });
    };

    onHandleSubmitUpdate = e => {
        e.preventDefault();

        const { modalData, id, awardCategoryData, modalAwardName, accountId, accountType } = this.state;

        const data = {
            awardName: modalAwardName,
            accountId,
            accountType
        }

        modalData[0].awardName = modalAwardName
        modalData[0].accountId = accountId
        modalData[0].acountType = accountType

        this.props.sharedActions
            .editRecordWithoutDispatch(
                '/api/AwardCategory/',
                id,
                data,
            )
            .then(success => {
                awardCategoryData.forEach((item, index) => {
                    if (item.id === id) {
                        awardCategoryData.splice(index, 1, modalData[0])
                    }
                })
                this.setState({ enabled: false, msg: 'Edited Record Successfully!' });
                this.props.snackbar();
                $('#myModal').modal('toggle'); //or  $('#IDModal').modal('hide');
            })
            .catch(err => { });
    };

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
    };

    render() {
        const { errors, isLoading, awardCategoryData, id, modalData, enabled } = this.state;

        const renderTodos = awardCategoryData.map((item, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
                <tr className="bordersSpace" key={index}>
                    <th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}} scope="row">
                        {index + 1}
                    </th>
                    <td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{item.awardName}</td>

                    <td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{item.accountId}</td>
                    <td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{item.accountType}</td>

                    <td className="bordersSpace displayElemet" style={{borderColor: context.themeColors.primaryColor}}>

                        <a href="#" data-toggle="tooltip" title="Edit!">
                            <button
                                id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
                                onMouseOver={this.btnTechHover()}
                                data-toggle="modal"
                                data-target="#myModal"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <i class="fas fa-pen"></i>
                            </button>
                        </a>

                        <a href="#" data-toggle="tooltip" title="Delete!">
                            <button
                                onClick={() => this.onEditClick(item.id)}
                                data-toggle="modal"
                                data-target="#myModal1"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    background: 'none',
                                    marginLeft: 5,
                                    marginBottom: 5
                                }}
                            >
                                <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                            </button>
                        </a>
                    </td>
                </tr>
                                                    )}
                                                    </ThemeContext.Consumer>
            );
        });


        const MainContent = (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <div class="modal" id="myModal1">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18,
                                    background: context.themeColors.primaryColor
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Confirm
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <h4>Are you sure ?</h4>
                                    </div>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-evenly' }}
                                    >
                                        <div>
                                            <button
                                                disabled={this.state.enabled}
                                                data-dismiss="modal"
                                                onClick={() => this.onTakeItemDelete(id)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                                style={{background: context.themeColors.primaryColor}}
                                            >
                                                Yes
											</button>
                                        </div>
                                        <div>
                                            <button
                                                data-dismiss="modal"
                                                disabled={this.state.enabled}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                                style={{background: context.themeColors.primaryColor}}
                                            >
                                                No
											</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" id="myModal">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18,
                                    background: context.themeColors.primaryColor
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Details
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.onHandleSubmitUpdate}>
                                    {modalData &&
                                        modalData.map((item, index) => (
                                            <div key={index}>
                                                <fieldset disabled={!this.state.enabled}>
                                                    <TextInput
                                                        customWidth={true}
                                                        enterWidth={'300px'}
                                                        feildName={'modalAwardName'}
                                                        inputLabelName={'Award Name'}
                                                        onHandleChange={this.onHandleTextChange}
                                                        errors={errors}
                                                        stateData={this.state}
                                                        placeholder={'Award Name'}
                                                        isValidte={false}
                                                    />

                                                </fieldset>
                                                <br />
                                                <br />
                                                <button
                                                    disabled={this.state.enabled}
                                                    onClick={() => this.setState({ enabled: true })}
                                                    type="button"
                                                    class="btn btn-primary buttonAppear"
                                                    style={{background: context.themeColors.primaryColor}}
                                                >
                                                    Edit
												</button>
                                                <button
                                                    style={{ marginLeft: 5 }}
                                                    disabled={!this.state.enabled}
                                                    type="submit"
                                                    class="btn btn-primary buttonAppear"
                                                    style={{background: context.themeColors.primaryColor}}
                                                >
                                                    Save
												</button>
                                            </div>
                                        ))}
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    data-dismiss="modal"
                                >
                                    Close
								</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table class="table table-hover">
                        <thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                            <tr className='vendorListHeading'>
                                <th scope="col">#</th>
                                {tHead.map(item => (
                                    item === 'Actions' ?
                                        <th className='displayElemet'
                                            style={{
                                                border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                borderColor: Colors.WHITE
                                            }}
                                            scope="col"
                                        >
                                            {item}
                                        </th>
                                        :
                                        <th
                                            style={{
                                                border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                borderColor: Colors.WHITE
                                            }}
                                            scope="col"
                                        >
                                            {item}
                                        </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>{renderTodos}</tbody>
                    </table>
                </div>
            </div>
                                                )}
                                                </ThemeContext.Consumer>
        );

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div id='div1'>
                <div className="page-header">
                    <SnackBar msg={this.state.msg} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: context.themeColors.primaryColor}}>
                                {' '}
                    Award Categories{' '}
                            </h2>
                        </div>

                    </div>
                </div>
                <br />

                {isLoading ? <Spinner /> : MainContent}
                <div class="text-center text-md-right displayElemet">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>

            </div>
                                    )}
                                    </ThemeContext.Consumer>
        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewAwardCategory);
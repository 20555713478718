import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";




class ViewPayslip extends Component {
    constructor(props){
        super(props);
        this.state = {
            payslipData: [],
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            backColor: '',
            msg: '',
            editIndex: '',
            id: null,
            staffName: null,
            campusName: null,
            departmentName: null,
            postName: null,
            month: null,
            year: null,
            basicSalary: null,
            taxAmount: null,
            allowances: null,
            bonusAmount: null,
            loanamountDeduction: null,
            attendancededuction: null,
            taxdeduction: null,
            totatAmountDeduct: null,
            grossIncome: null,
            NetIncome: null,
            status: null,
            accountId: null,
            accountType: null, 

        }

        


    }
	static contextType = ThemeContext

    componentDidMount(){
        this.getSimple('EmployeePaySlip', 'payslipData')

    }

	getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured');
        })
    }


    onEditClick = id => {
        const { payslipData } = this.state
        let modalData = payslipData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(modalData)
        this.setState({
            id: modalData[0].id,
            sessionId: modalData[0].sessionId,
            campusId: modalData[0].campusId,
            departmentId: modalData[0].departmentId,
            PostId: modalData[0].PostId,
            staffId: modalData[0].staffId,
            date: modalData[0].date,
            time: modalData[0].time,
            staffName: modalData[0].staffName,
            campusName: modalData[0].campusName,
            departmentName: modalData[0].departmentName,
            postName: modalData[0].postName,
            month: modalData[0].month,
            year: modalData[0].year,
            basicSalary: modalData[0].basicSalary,
            taxAmount: modalData[0].taxAmount,
            allowances: modalData[0].allowances,
            bonusAmount: modalData[0].bonusAmount,
            loanamountDeduction: modalData[0].loanamountDeduction,
            attendancededuction: modalData[0].attendancededuction,
            taxdeduction: modalData[0].taxdeduction,
            totatAmountDeduct: modalData[0].totatAmountDeduct,
            grossIncome: modalData[0].grossIncome,
            NetIncome: modalData[0].NetIncome,
            status: modalData[0].status,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType, 
            
        }, () => {
            $('#myModal1').modal({
                show: true
            })
        })
    }

    
    onHandlePayslip = id => {
        const { payslipData } = this.state
        let payslip = payslipData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                generateIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })



    }

    

    

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const {  id,
            sessionId,
            campusId,
            departmentId,
            PostId,
            staffId,
            time,
            date,
            staffName,
            campusName,
            departmentName,
            postName,
            month,
            year,
            basicSalary,
            taxAmount,
            allowances,
            bonusAmount,
            loanamountDeduction,
            attendancededuction,
            taxdeduction,
            totatAmountDeduct,
            grossIncome,
            NetIncome,
            status,
            accountId,
            accountType,  } = this.state;

        let data = {
            id,
            sessionId,
            campusId,
            departmentId,
            PostId,
            staffId,
            time,
            date,
            staffName,
            campusName,
            departmentName,
            postName,
            month,
            year,
            basicSalary,
            taxAmount,
            allowances,
            bonusAmount,
            loanamountDeduction,
            attendancededuction,
            taxdeduction,
            totatAmountDeduct,
            grossIncome,
            NetIncome,
            status,
            accountId,
            accountType,
        }

        console.log('data model', data)
        this.props.sharedActions.editRecordWithoutDispatch(
            '/api/Payslip/',
            id,
            data
        ).then(success => {
            console.log('success', success)
            this.state.payslipData.splice(this.state.editIndex, 1, data)
            
                
                $('#myModal1').modal('hide')
            
            this.setState({
                msg: 'Record Changed Successfully!',

                
            })
            this.props.snackbar();
            

        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            console.error('Error occured', error);
                
                $('#myModal1').modal('hide')
            
            this.props.snackbar();


        })
    }


    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};


    render(){

        const { isLoading, editToggle, payslipData, msg, slipId,
            slipNo,
            staffId,
            staffName,
            month,
            year,
            basicSalary,
            loanAmount,
            allowances,
            bonusAmount,
            taxAmount,
            totatAmountDeduct,
            attendancededuction,
            noOfAbsents,
            noOfPresents,
            noOfLates,
            noOfLeaves,
            noOfShortLeaves,
            perdaySalary,
            consecutiveLate,
            consecutiveLateSalaryCut,
            absentsalaryCut,
            lateSalaryCut,
            lateHours,
            lateMinutes,
            grossIncome,
            NetIncome,
            date,
            time,
            accountId,
            accountType, } = this.state;

        



        const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
			</div>
        );
        
        const renderPayslipData = payslipData.map((item, index) => {
            return (

            <tr key={index} >
                <th scope="row" className="borderSpace" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.slipNo}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.staffName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.month}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.year}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.grossIncome}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.totatAmountDeduct}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.NetIncome}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    <Link to={{
                        pathname: "PayslipDetails",
                        state: {item: item  }
                    }}  >
                <button className="btn btn-primary"
								onClick={() => this.onHandlePayslip(item.id)}
								style={{
									cursor: 'pointer',
								}}
							>
								View
							</button>
                    </Link>

                </td>
                
            </tr>
            
            );
        }) 

        return (
        <div>
            <section  className="mb-4">
            <h2  
                    style={{ background: this.context.themeColors.primaryColor }}
                    className="h1-responsive font-weight-bold text-center  generalHead my-3">
                    View Payslip
                </h2>
            <div> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Slip No.
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Staff Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Acoount Role
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Month
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Year
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Gross Income
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Total Amount Deduct
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Net Income
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
                                

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderPayslipData}</tbody>
					</table>
				</div>
                </div>
            </div>
			</section>
			</div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewPayslip);

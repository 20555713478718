import React from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Spinner from '../../components/Spinner/Spinner';
import Colors from '../../utils/app_constants/colors_constants';
import { Link } from 'react-router-dom';
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Class',
    'Section',
    'Award',
    'Session',
    'Actions'
];

let renderFields = [
    'className',
    'section',
    'awardName',
    'session',
]

class ViewAwardByStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            awardData: [],
            isLoading: false,
            isLoadingInitialData: true,
            disablepage: false,
            disableBPage: true,
            currentPage: 1,
            todosPerPage: 10,
            pageCounter: 10,
            errorMsg: false
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.props.sharedActions
            .getDataWithoutDispatch(
                `/api/StudentDetail?userName=${this.props.name}`
            )
            .then(success => {
                this.setState({
                    userData: success,
                    studentId: success[0].studentId,
                })
                this.props.sharedActions
                    .getDataWithoutDispatch(
                        `/api/SpecificStudentAward?studentId=${this.state.studentId}`
                    )
                    .then(success => {
                        this.setState({
                            awardData: success,
                            isLoadingInitialData: false,
                        })
                    })
                    .catch(err => {
                        this.setState({
                            isLoadingInitialData: false
                        })
                    })
            })
            .catch(err => {
                this.setState({
                    isLoadingInitialData: false
                })
            })
    }

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: '',
                    errorMsg: false,
                });
            }, 3000);
        }
    }

    render() {
        const { errors, awardData, isLoadingInitialData } = this.state;

        const MainContent = data =>
            data &&
            data.map((item, index) => (
                <ThemeContext.Consumer>
                {(context) => (
                <tr>
                    <th
                        style={{
                            padding: 2,
                            border: `1px solid ${context.themeColors.primaryColor}`,
                            borderColor: context.themeColors.primaryColor
                        }}
                        scope="row"
                    >
                        {index + 1}
                    </th>
                    {renderFields.map((item1, index) => (
                        <td
                            style={{
                                fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                                border: `1px solid ${context.themeColors.primaryColor}`,
                                padding: 2,
                                borderColor: context.themeColors.primaryColor
                            }}
                        >
                            {item[item1]}
                        </td>
                    ))}
                    <td className="bordersSpace">

                        <Link to={`/dashboard/AwardTemplate1/${item.id}`}>
                            <button
                                id="firstbutton"
                                // onClick={() => this.onEditClick(item.id)}
                                onMouseOver={this.btnTechHover()}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <a href="#" data-toggle="tooltip" title="View!">
                                    <i style={{ color: '#01AC8A' }} class="fas fa-medal"></i>
                                </a>
                            </button>
                        </Link>

                        <Link to={`/dashboard/AwardTemplate2/${item.id}`}>
                            <button
                                id="firstbutton"
                                // onClick={() => this.onEditClick(item.id)}
                                onMouseOver={this.btnTechHover()}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <a href="#" data-toggle="tooltip" title="View!">
                                    <i style={{ color: '#01AC8A' }} class="fas fa-medal"></i>
                                </a>
                            </button>
                        </Link>
                    </td>
                </tr>
                )}
                </ThemeContext.Consumer>
            ));

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <div className="page-header">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: context.themeColors.primaryColor}}>
                                {' '}
                    Award For Student Details{' '}
                            </h2>
                        </div>
                    </div>
                </div >
                < br />

                {
                    isLoadingInitialData ? (
                        <Spinner />
                    ) :
                        awardData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                            <strong>No Data</strong>
                        </div>
                            : (
                                <div className="table-responsive">
                                    <table class="table table-hover">
                                        <thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                                            <tr>
                                                <th scope="col">#</th>
                                                {tHead.map(item => (
                                                    <th
                                                        style={{
                                                            border: `1px solid ${context.themeColors.primaryColor}`,
                                                            borderColor: Colors.WHITE
                                                        }}
                                                        scope="col"
                                                    >
                                                        {item}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>{MainContent(awardData)}</tbody>
                                    </table>
                                </div>

                            )}
            </div>
            )}
            </ThemeContext.Consumer>
        );
    }

}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewAwardByStudent);
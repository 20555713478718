import React, { Component } from 'react';
import $ from 'jquery';
import './viewGM.css';
import Axios from 'axios';
import config from '../../../config/config';
import { connect } from 'react-redux';
import SnackBar from '../../../components/SnackBar/SnackBar';

class ViewGm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminData: [],
			modalData: null,
			username: '',
			password: '********',
			name: '',
			enabled: false,
			id: null,
			isLoading: true,
			disablepage: false,
			disableBPage: true,
			currentPage: 1,
			todosPerPage: 10
		};
	}
	handleClickNextFrwd = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		console.log('pagelength: ', pageNumbers.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumbers.length) {
			this.setState({
				disablepage: true
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage + 1,
				disableBPage: false
			}));
		}
	};
	handleClickNext = event => {
		this.setState({
			currentPage: Number(event.target.id),
			disablepage: false,
			disableBPage: false
		});
	};
	handleClickNextBack = () => {
		const { adminData, todosPerPage, count, currentPage } = this.state;
		console.log('current page: ', currentPage);
		if (currentPage === 1) {
			this.setState({
				disableBPage: true,
				disablepage: false
			});
		} else {
			this.setState(prevState => ({
				currentPage: prevState.currentPage - 1
			}));
		}
	};
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		let token = '';
		if (localStorage.jwtToken) {
			token = localStorage.getItem('jwtToken');
		}
		Axios.get(`${config.localhttp}/api/GManager`, {
			headers: { Authorization: `Bearer  ${token}` }
		})
			.then(success => {
				console.log(success);

				this.setState({
					isLoading: false,
					adminData: success.data
				});
			})
			.catch(error => {
				this.setState({
					isLoading: false
				});
				console.log(error);
			});
	}
	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};
	onTakeItemDelete = itemId => {
		this.setState({
			modalData: []
		});
		console.log(itemId);

		const { adminData } = this.state;
		let token = '';
		if (localStorage.jwtToken) {
			token = localStorage.getItem('jwtToken');
		}
		var Headconfig = {
			headers: { Authorization: 'Bearer ' + token }
		};
		var bodyParameters = { id: itemId };

		Axios.delete(`${config.localhttp}/api/GManager/${itemId}`, {
			data: { id: itemId }, // or data: jdId, depending on how you handle it in the back end
			headers: {
				Authorization: 'Bearer ' + token
			}
		})
			.then(response => {
				console.log(response);
				if (response.status == 200) {
					let getData = adminData.filter(item => item.id !== itemId);

					this.setState({
						adminData: getData,
						msg: 'Delete Record Successfully!'
					});
					this.props.snackbar();
				}
			})
			.catch(err => {
				console.log(err);
			});
	};
	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { adminData } = this.state;
		let getData = adminData.filter(item => item.id === itemId);

		this.setState({
			modalData: getData,
			username: getData[0].userName,
			password: '*****',
			name: getData[0].name,
			id: getData[0].id
		});
	};
	onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onHandleSubmit = e => {
		e.preventDefault();
		const { username, password, id, name } = this.state;
		let token = '';
		if (localStorage.jwtToken) {
			token = localStorage.getItem('jwtToken');
		}
		var Headconfig = {
			headers: { Authorization: 'Bearer ' + token }
		};
		var bodyParameters = {
			id,
			userName: this.state.username,
			name: this.state.name,
			password,
			date: new Date().toDateString(),
			time: new Date().toLocaleTimeString(),
			post: 'general manager',
			accountId: this.props.data.unique_name,
			accountType: this.props.data.role
		};
		Axios.put(
			`${config.localhttp}/api/GManager/${id}`,
			bodyParameters,
			Headconfig
		)
			.then(response => {
				console.log(response);

				if (response.status == 200) {
					var admindata = [...this.state.adminData];
					var index = admindata.findIndex(obj => obj.id === id);
					admindata[index].userName = username;
					admindata[index].name = name;
					this.setState({
						adminData: admindata,
						enabled: false,
						msg: 'Edit Record Successfully!'
					});
					this.props.snackbar();
				}
			})
			.catch(err => {
				console.log(err);
			});
	};
	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({ enabled: false });
	};
	render() {
		const { adminData, modalData } = this.state;
		console.log('modal data: ', this.state.modalData);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(adminData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			debugger;
			return index <= 3 ? (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
					>
						{number}
					</a>
				</li>
			) : (
				''
			);
		});
		const { currentPage, todosPerPage } = this.state;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
		const renderTodos = currentTodos.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row">{index + 1}</th>
					<td>{item.name}</td>
					<td>{item.userName}</td>
					<td>{item.accountId}</td>
					<td>{item.accountType}</td>
					<td>{item.date}</td>
					<td>{item.time}</td>
					<td>
						
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onEditClick(item.id)}
								data-toggle="modal"
								data-target="#myModal1"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
							</button>
						</a>
					</td>
				</tr>
			);
		});
		const MainContent = (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<div class="form-group">
														<label for="name">Name:</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															type="text"
															value={this.state.name}
															onChange={this.onHandleText}
															name="name"
															class="form-control"
															id="name"
														/>
													</div>
													<div class="form-group">
														<label for="username">Username:</label>
														<input
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															readOnly={true}
															type="text"
															value={this.state.username}
															onChange={this.onHandleText}
															name="username"
															class="form-control"
															id="username"
														/>
													</div>
													<div class="form-group">
														<label for="pwd">Password:</label>
														<input
															required
															style={{
																borderTop: 0,
																borderLeft: 0,
																borderRight: 0,
																boxShadow: 'none'
															}}
															value={this.state.password}
															onChange={this.onHandleText}
															type="password"
															name="password"
															class="form-control"
															id="password"
														/>
													</div>
												</fieldset>
												<button
													disabled={this.state.enabled}
													onClick={() =>
														this.setState({ enabled: true, password: '' })
													}
													type="button"
													class="btn btn-primary buttonAppear"
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5 }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="table-responsive">
					<table class="table table-hover table-bordered">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Name</th>
								<th scope="col">User Name</th>
								<th scope="col">Account Type</th>
								<th scope="col">Account Id</th>
								<th scope="col">Date</th>
								<th scope="col">Time</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
			</div>
		);
		return (
			<div>
				<div className="page-header">
					<SnackBar msg={this.state.msg} />

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>
								General Manager's&nbsp;<small class="text-muted">Details</small>
							</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: '#01AC8A' }} class="pagination">
								<li
									class={`page-item ${
										this.state.disableBPage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextBack}
										class="page-link"
										href="#"
									>
										{this.state.disableBPage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-backward"></i>
										)}
									</a>
								</li>
								{renderPageNumbers}
								<li
									class={`page-item ${
										this.state.disablepage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextFrwd}
										class="page-link"
										href="#"
									>
										{this.state.disablepage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-forward"></i>
										)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				{this.state.isLoading ? Loader : MainContent}
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		data: state.setUserReducer.users
	};
}
export default connect(
	mapStateToProps,
	null
)(ViewGm);

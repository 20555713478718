import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'

import ThemeContext from "../../context/themeContext/ThemeContext";



class ViewStudentTalentClass extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',

            accountId: this.props.userData.unique_name

        }

    }

	static contextType = ThemeContext

    componentDidMount(){
		this.getSimple('classSectionGradeTeacher', 'classSectionGrade')
        this.getSimple(`StudentDetail?userName=${this.state.accountId}`, 'studentData')
    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

    


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
        
		this.setState({
            [e.target.name]: e.target.value,
        });
        console.log([e.target.name], e.target.value)
	};
	
	onHandleFilter = (e, item) => {
		const { classId, sectionId, sessionId } = item
		const { subjectId } = this.state;
        
		this.setState({
            [e.target.name]: e.target.value,
            report: null
        });
            this.getSimple(`GetStudentSyllabusList/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&term=${e.target.value}&subjectId=${subjectId}`, 'report')
        console.log([e.target.name], e.target.value)
    };
    


    render(){

        const { search, isLoading, editToggle, msg, studentTalents, accountId, accountType, classSectionGrade,
            termList, student, session, talentCategory, talentSubCategory, valid,
            sessionId, classId, sectionId, studentId, term, categoryId, subCategoryId, reward,
            studentName, className, section, sessionName, categoryName, subCategoryName, date,
            
            studentData, report, subjectId
        } = this.state;

        const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
			</div>
		);
        
        const renderStudentData = studentData && studentData.map((item, index) => {
			return (
				<tr key={index}>
					<th scope="row" className="bordersSpace" style={{ padding: 10 }}>
						{index + 1}
					</th>
					<td className="bordersSpace" style={{ padding: 10 }}>
						{item.name}
					</td>
					<td className="bordersSpace" style={{ padding: 10 }}>
						{item.className}
					</td>
					<td className="bordersSpace" style={{ padding: 10 }}>
						{item.section}
					</td>
					<td className="bordersSpace" style={{ padding: 10 }}>
						{item.session}
					</td>
					<td className="bordersSpace" style={{ padding: 10 }}>
						{item.status}
					</td>
					<td className="bordersSpace" style={{ padding: 10 }}>
						<div class="col-md-12">
							<div class="md-form mb-0">
								<select
									
									onChange={this.onHandleText}
									class="custom-select"
									name="subjectId"
								>
									<option>Choose Subject..</option>
									
									{classSectionGrade && classSectionGrade.subject.map((data, i) => {
										return data.classId == item.classId && 
										<option value={data.subjectId}>{data.subjectName}</option>

									})
									
									}
								</select>
							</div>
						</div>
					</td>
					<td className="bordersSpace" style={{ padding: 10 }}>
						<div class="col-md-12">
							<div class="md-form mb-0">
								<select
									
									onChange={e => this.onHandleFilter(e, item)}
									class="custom-select"
									name="term"
									disabled={!subjectId}
								>
									<option>Choose Term..</option>
									<option value="1st">1st Term</option>
									<option value="2nd">2nd Term</option>
									<option value="3rd">3rd Term</option>
								</select>
							</div>
						</div>
					</td>
						<td className="bordersSpace">
								<a 
                        href={
                            `https://dak-backend.schoolsmart.org.uk/api/DownloadSyllabusForAll/?fileName=${report && report.fileName}`
                        }
                        data-toggle="tooltip"
                        title="Download File"
                        >                            
                            <button
								id="firstbutton"
								onMouseOver={this.btnTechHover()}
								disabled={!report}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5,
								}}
							>
								<i className="fas fa-arrow-down"></i>
							</button>
						</a>
						</td>
					
				</tr>
			);
		});
        return (
            <div>
				<section  className="mb-4">
				<h2  
                        style={{ background: this.context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        View Syllabus
                    </h2>
			    <div  className="my-2">
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Session
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Status
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Subject
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Term
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
                                

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderStudentData}</tbody>
					</table>
				</div>
                </div>
            </div>
			</section>
			</div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStudentTalentClass);

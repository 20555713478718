import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as DescriptiveAction from '../../Actions/DescriptiveReport';
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import DropDownsForData from "../DescriptiveReportModule/TermClassSubjectSelect";
import ThemeContext from "../../context/themeContext/ThemeContext";

class HODClassDisciplineView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClassDiscipline: [],
      adminData: [],
      modalData: null,
      enabled: false,
      id: null,
      select: [],
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      dropFlag: false,
      selectClassData: "",
      classId: "",
      sectionId: "",
      sections: [],
      subjects: [],
      session: [],
      sessionId: "",
      subjectId: "",
      subjectName: "",
      staffId: "",
      staffName: "",
      term: "",
      description: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      multiple: [],
      badRequest: false,
      errorText: "",
      Loading: true,
    };
  }
  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    const sections = this.state.selectClassData.staffClassSection;
    let check = sections.filter((item) => item.classId == result.classId);
    console.log(check);

    this.setState({
      sections: check,
      staffId: check[0].staffId,
      staffName: check[0].staffName,
      classId: result.classId,
      className: result.className,
    });
  };
  onSectionChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    const sections = this.state.selectClassData.staffClassSection;
    let check2 = sections.filter(
      (item) =>
        item.classId == this.state.classId && item.sectionId == result.sectionId
    );
    this.setState({
      subjects: check2,
      sectionId: result.sectionId,
      sectionName: result.section,
    });
    console.log(this.state.staffId);
  };
  onSessionChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    this.setState({
      sessionId: result.sessionId,
      sessionName: result.currentSession,
    });
    console.log(this.state);
  };
  onSubjectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    this.setState({
      subjectId: result.subjectId,
      subjectName: result.subjectName,
    });
  };
  componentDidMount() {
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/DescriptiveReport?userName=${this.props.match.params.name}`
      )
      .then((success) => {
        this.setState({
          selectClassData: success,
          Loading: false,
        });
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.sharedActions
      .getDataWithoutDispatch("/api/Session")
      .then((success) => {
        this.setState({
          session: success,
        });
      })
      .catch((error) => {});
  }
  onHandleText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    this.props.history.push({
      pathname: "/dashboard/ClassDisciplineReport",
      state: {
        sessionId: this.state.sessionId,
        classId: this.state.classId,
        sectionId: this.state.sectionId,
        subjectId: this.state.subjectId,
        staffId: this.state.staffId,
        term: this.state.term,
      },
    });
  };
  render() {
    const { classId, sectionId, subjectId, sessionId, term } = this.state;
    const { session } = this.state.session;
    const { classes } = this.state.selectClassData;
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <label style={{ color: "red" }}>No Data</label>
      </div>
    );
    const PreLoading = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );

    const MainContent = (
      <div class="text-center" style={{ marginTop: 20 }}>
        <button
          class="buttonHoverFillColor"
          style={{ width: 180, marginRight: 10 }}
        >
          Sheet 1
        </button>
        <button
          class="buttonHoverFillColor"
          style={{ width: 180, marginLeft: 10 }}
        >
          Sheet 2
        </button>
      </div>
    );
    return (
      <div>
        <SnackBar backColor={"#000"} msg={this.state.msg} />
        {this.state.errorMsg ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Grading Marks Already Exist For This Term
          </p>
        ) : (
          ""
        )}
        {this.state.exist ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Please Fill the Fields
          </p>
        ) : (
          ""
        )}
        {this.state.badRequest ? (
          <p style={{ color: "red", textAlign: "center" }}>
            {this.state.errorText}
          </p>
        ) : (
          ""
        )}
        <div className="page-header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div>
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Class Disciplinary Record
              </h4>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                {/* {renderPageNumbers} */}
              </ul>
            </nav>
          </div>
        </div>
        <br />
        {this.state.Loading ? (
          PreLoading
        ) : (
          <div>
            {" "}
            <DropDownsForData
              onChangeDrop={this.onSelectChange}
              onSectionDrop={this.onSectionChange}
              onSubjectDrop={this.onSubjectChange}
              onSessionDrop={this.onSessionChange}
              onHandleChange={this.onHandleText}
              ClassData={classes}
              SessionData={session}
              SectionData={this.state.sections}
              SubjectData={this.state.subjects}
              flag={this.state.dropFlag}
            />
            <br />
            <div class="col-md-12 text-center">
              <ThemeContext.Consumer>
                {(context) => (
                  <button
                    type="submit"
                    disabled={
                      classId !== "" &&
                      sessionId !== "" &&
                      sectionId !== "" &&
                      term !== "" &&
                      subjectId !== ""
                        ? false
                        : true
                    }
                    style={{
                      backgroundColor:
                        classId !== "" &&
                        sessionId !== "" &&
                        sectionId !== "" &&
                        term !== "" &&
                        subjectId !== ""
                          ? context.themeColors.primaryColor
                          : "#ABD0BC",
                      border: "none",
                      fontSize: 20,
                      borderRadius: 4,
                      color: "white",
                    }}
                    onClick={this.onSearchStudent}
                  >
                    Search
                    <i
                      style={{ color: "white", marginLeft: 4 }}
                      class="fas fa-search"
                    ></i>
                    <span
                      style={{ marginBottom: 5 }}
                      class={`${
                        this.state.btnLoad
                          ? "spinner-border spinner-border-sm"
                          : ""
                      }`}
                    ></span>
                  </button>
                )}
              </ThemeContext.Consumer>
            </div>
            <br />
            {this.state.searchCheck ? Loader : MainContent}
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    users: state.setUserReducer.users,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HODClassDisciplineView);

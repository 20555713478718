import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import Header from '../../Assets/images/3 headers-03.png';
import moment from 'moment'
import GenerateTable1 from './GenerateTable1'

import ThemeContext from "../../context/themeContext/ThemeContext";


class PrintStudentDisciplineReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: false,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            pageNo: this.props.location.state.pageNo,

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

    }
	static contextType = ThemeContext

    componentDidMount(){

    }

    
    
    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
    };


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
    };
    
    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };



    render(){
        const { isLoading, accountId, accountType, pageNo } = this.state;
        const {  studentDiscipline, session, sessionId, term } = this.props.location.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );

        return (
            <div className="page-header"> 
            <div class="row">
                <div class="col-md-12 text-right my-2">
                <select class="custom-select" style={{width: '15%', marginLeft: '2%'}} 
                                                value={pageNo}
                                                name="pageNo"
                                                onChange={this.onHandleText}
                                            >
                                                        <option value="1">Page No. 1</option>
                                                        <option value="2">Page No. 2</option>
                                                       
                                            </select>

                </div>
            </div>
                <div id="printPage" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row" >
								<div
									style={{
                                        width: '100%',
                                        height: '100%'
									}}
								>
									<img src={Header} width={'100%'} height={'100%'} />
								</div>
						</div>

                    <div class="row" style={{marginTop: '2%'}} >
							<div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h3>Student Skill Report</h3>
							</div>
						</div>

                    <div class="my-2 py-2 px-2" style={{
                        border: `3px solid ${this.context.themeColors.primaryColor}`,
                    }} >
                     <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                            <div class="col-md-4" >
                                    <span>Student: <strong style={{color: this.context.themeColors.primaryColor}} >{studentDiscipline && studentDiscipline.info.studentName}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Class: <strong style={{color: this.context.themeColors.primaryColor}} >{studentDiscipline && studentDiscipline.info.className}</strong></span>     
                            </div>
                            <div class="col-md-4"  >
                                    <span>Section: <strong style={{color: this.context.themeColors.primaryColor}} >{studentDiscipline && studentDiscipline.info.section}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Term: <strong style={{color: this.context.themeColors.primaryColor}} >{studentDiscipline && term}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Session: <strong style={{color: this.context.themeColors.primaryColor}} >{studentDiscipline && session.session.filter(data => data.sessionId == sessionId)[0].currentSession}</strong></span>     
                            </div>
                            
                            <div class="col-md-4" >
                                    <span>Page No: <strong style={{color: this.context.themeColors.primaryColor}} >{studentDiscipline && `${pageNo} - 2`}</strong></span>     
                            </div>
                        </div>
                        {isLoading ? Loader : <GenerateTable1 
                        Data={studentDiscipline}
                        pageNo={pageNo}
                        primaryColor={this.context.themeColors.primaryColor}
                        />}
                        <div class="row">

                        <div class="col-md-3 text-left colCheck mx-3" style={{borderColor: this.context.themeColors.primaryColor}}>
								<label class="font-weight-bold">ACTION TAKEN</label> <br />
								<lable>1. CB &nbsp;&nbsp;&nbsp;- &nbsp;Contact Book</lable>{' '}
								<br />
								<lable>2. HoD - &nbsp;Department Head</lable> <br />
								<lable>3. DP &nbsp;&nbsp;- &nbsp;Deputy Principal</lable> <br />
								<lable>4. CP &nbsp;&nbsp;- &nbsp;Contact Parent</lable> <br />
								<lable>
									5. S &nbsp;&nbsp;&nbsp;&nbsp;- &nbsp;Suspension
								</lable>{' '}
								<br />
								<lable>6. Ex &nbsp;&nbsp;- &nbsp;Expulsion</lable> <br />
							</div>
							{pageNo == '2' ? (
								<div class="col-md-3 text-right colCheck offset-md-1" style={{borderColor: this.context.themeColors.primaryColor}}>
									<label class="font-weight-bold">OTHER TRANSGRESSION</label>{' '}
									<br />
									<lable>1. Fi &nbsp;&nbsp;&nbsp;- &nbsp;Fighting</lable> <br />
									<lable>2. LA - &nbsp;Offensive Language</lable> <br />
									<lable>3. VA &nbsp;&nbsp;- &nbsp;Vandalism</lable> <br />
									<lable>4. MP &nbsp;&nbsp;- &nbsp;Mobile Phones</lable>
								</div>
							) : (
								''
                            )}     
                        </div>

                </div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-4">
                            <span>Printed By: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.unique_name}</strong></span>     
                    </div>
                    <div class="col-md-4 text-center">
                             
                            <span>Role: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.role}</strong></span>     

                    </div>
                    <div class="col-md-4 text-right">
                             
                            <span>Time: <strong style={{color: this.context.themeColors.primaryColor}} >{moment().format('HH:mm - MM/DD/YYYY')}</strong></span>     

                    </div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-12 text-right">
                    <button class="btn btn-primary buttonAppear" onClick={() => this.PrintContent('printPage')}>
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintStudentDisciplineReport);

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import TextInput from '../../components/TextInput/TextInput';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import general from '../../utils/general';
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddSectionRenew extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			classNameData: [],
			isLoading: true,
			userName: props.username,
			role: props.role,
			errors: {},
			section: '',
			sectionFor: '',
			classId: '',
			errMsg: false,
			classN: '',
			isLoadingForm: false
		};
	}

	componentDidMount() {
		this.props.sharedActions
			.getDataWithoutDispatch('/api/AddClass')
			.then(success => {
				this.setState({
					classNameData: success,
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});
				console.log(err);
			});
	}

	componentDidUpdate() {
		if (this.state.msg || this.state.errMsg) {
			setTimeout(() => {
				this.setState({
					msg: '',
					errMsg: false
				});
			}, 3000);
		}
	}

	onSelectClassChange = e => {
		console.log(e.target.value);

		this.setState({
			classId: e.target.value
		});
	};

	onChangeSectionHandle = e => {
		this.setState({
			section: e.target.value
		});
	};

	onChangeSectionForHandle = e => {
		this.setState({
			sectionFor: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();

		const {
			userName,
			role,
			className,
			classId,
			section,
			sectionFor,
			classNameData,
			id
		} = this.state;

		var cName = classNameData.filter(classIns => classIns.id == classId);

		if (
			Object.is(className, '') ||
			Object.is(classId, '') ||
			Object.is(section, '') ||
			Object.is(sectionFor, '')
		) {
			this.setState({
				errMsg: true
			});
		} else {
			this.setState({
				isLoadingForm: true
			});
			let data = {
				className: cName[0].className,
				section: section,
				sectionFor: sectionFor,
				accountId: userName,
				accountType: role,
				classId: classId
			};

			this.props.sharedActions
				.simpleAddRequest('/api/Section', data)
				.then(success => {
					this.setState({
						classId: '',
						section: '',
						sectionFor: '',
						msg: 'Successfully Submitted!',
						errMsg: false,
						isLoadingForm: false
					});
					this.props.snackbar();
				})
				.catch(err => {
					if (err && err.response && err.response.status == 400) {
						this.setState({
							isLoading: false,
							subjectData: [],
							msg: err.response.data.Message
						});
						this.props.snackbar();
					}
				});
		}
	};

	render() {
		const { errors, classNameData, isLoading, sectionForData } = this.state;
		const Loader = (
			<ThemeContext.Consumer>
			{(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
			</div>
			)}
			</ThemeContext.Consumer>
		);

		const Form = (
			<ThemeContext.Consumer>
			{(context) => (
			<FormLayoutWrapper
				formName="Add Section"
				borderColor={context.themeColors.primaryColor}
				borderRadius={15}
			>
				{this.state.errMsg ? (
					<p style={{ color: 'red', textAlign: 'center' }}>
						Please fill the form properly
					</p>
				) : (
					''
				)}

				<div class="row">
					<TextInput
						disabledField={true}
						isValidte={false}
						stateData={this.state}
						feildName={'userName'}
						inputLabelName={'Admin Name'}
						errors={errors}
						textFont={16}
						readOnly={true}
					/>

					<TextInput
						disabledField={true}
						isValidte={false}
						stateData={this.state}
						feildName={'role'}
						inputLabelName={'Admin Type'}
						errors={errors}
						textFont={16}
						readOnly={true}
					/>
					<TextInput
						isValidte={false}
						stateData={this.state}
						feildName={'section'}
						inputLabelName={'Section'}
						textFont={16}
						errors={errors}
						onHandleChange={this.onChangeSectionHandle}
					/>

					<SelectionInput
						feildName={'sectionFor'}
						isValidte={false}
						selectName={'Section For'}
						onHandleChange={this.onChangeSectionForHandle}
						errors={errors}
						optionsArrys={general.sectionForData}
						selectedText={'Section For'}
						stateData={this.state}
						// iconClassName={'fas fa-clock animated fadeIn'}
						optionType="static"
						selectFont={16}
						successflag={'successflag'}
					/>

					<SelectionInput
						isValidte={false}
						feildName={'className'}
						selectName={'Class Name'}
						onHandleChange={e => this.onSelectClassChange(e)}
						errors={errors}
						optionsArrys={classNameData}
						selectedText={'Class'}
						stateData={this.state}
						// iconClassName={'fas fa-building'}
						optionType="dynamicWithPropIdAndName"
						property={'className'}
						propertyId={'id'}
						// editUse={'staffEdit'}
					/>
				</div>
				<div class="float-right" style={{ paddingTop: 20 }}>
					<button
						type="submit"
						class="btn"
						onClick={this.onHandleSubmit}
						style={{
							borderRadius: 10,
							backgroundColor: context.themeColors.primaryColor,
							color: 'white'
						}}
					>
						Add
						<span
							style={{
								marginBottom: 5
							}}
							class={`${
								this.state.isLoadingForm
									? 'spinner-border spinner-border-sm'
									: ''
							}`}
						></span>
					</button>
				</div>
			</FormLayoutWrapper>
			)}
			</ThemeContext.Consumer>
		);
		return (
			<div class="container">
				{isLoading ? Loader : Form}
				<SnackBar msg={this.state.msg} />
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		username: state.setUserReducer.users.unique_name
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddSectionRenew);

import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import TextInput from "../../components/TextInput/TextInput";
import FormLayoutWrapper from "../../components/HOC/FormLayoutWrapper";
import * as sharedActions from "../../Actions/sharedActions";
import Button from "../../components/Button";
import SnackBar from "../../components/SnackBar/SnackBar";
import { bindActionCreators } from "redux";
import TextArea from "../../components/TextArea";
import CaptureVisitor from "./CaptureVisitor";
import ThemeContext from "../../context/themeContext/ThemeContext";
import ColorPalette from "../../components/ColorPalette/ColorPalette";

class AddVisitorEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      accountId: props.name,
      accountType: props.role,
      errors: {},
      errorMsg: false,
      enabled: false,
      file: "",
      name: "",
      cnic: "",
      phoneNo: "",
      purpose: "",

      exist: "",
      phoneNoCheck: "",
      cardColor: "",
    };
  }

  onHandleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleNicChange = (e) => {
    if (e.target.value.length <= 8) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onCapture = () => {
    const video = document.getElementById("video");
    const canvas = document.getElementById("canvas");
    var context = canvas.getContext("2d");
    var img = new Image();
    context.drawImage(video, 0, 0, 1280, 720);

    img.src = canvas.toDataURL("image/jpeg");
    img.width = 1280;

    var date = new Date();
    var timestamp = date.getTime();
    timestamp = "visitor" + timestamp;

    fetch(img.src)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], timestamp + ".jpg", {
          type: "image/jpeg",
        });
        this.setState({ file: file });
        console.log(file);
      });
  };

  onSubmitCheck = (e) => {
    e.preventDefault();

    const { phoneNoCheck } = this.state;
    if (Object.is(phoneNoCheck, "")) {
      this.setState({
        errorMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      this.props.sharedActions
        .getDataWithoutDispatch(
          `/api/GetVisitorOrParent/?mobileNo=${phoneNoCheck}`
        )
        .then((success) => {
          this.setState({
            errorMsg: false,
            enabled: false,
            msg: "Visitor Data Exists",
            exist: 200,
            isLoading: false,
            cnic: success.cnic,
            name: success.name,
            phoneNo: success.phoneNo,
          });
          this.props.snackbar();
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 400) {
            this.setState({
              exist: 400,
              msg: "Enter Visitor Data",
              isLoading: false,
            });
            this.props.snackbar();
          } else {
            this.setState({
              isLoading: false,
              exist: "",
            });
            console.log(error);
          }
        });
    }
  };

  onSubmitExistingVisitor = (e) => {
    e.preventDefault();

    const {
      name,
      cnic,
      phoneNo,
      purpose,
      file,
      accountId,
      accountType,
      passNo,
      description,
    } = this.state;
    if (
      Object.is(name, "") ||
      Object.is(cnic, "") ||
      Object.is(phoneNo, "") ||
      Object.is(purpose, "") ||
      Object.is(passNo, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });

      const data = {
        name,
        cnic,
        phoneNo,
        accountId,
        accountType,
        image: file,
        purpose,
        passNo,
        description,
      };

      this.props.sharedActions
        .simpleAddRequest("/api/OldVisitorEntry/", data)
        .then((success) => {
          this.setState({
            errorMsg: false,
            enabled: false,
            file: "",
            name: "",
            cnic: "",
            phoneNo: "",
            purpose: "",
            passNo: "",
            description: "",
            exist: "",
            msg: "Visitor Added Successfully",
            isLoading: false,
          });
          this.props.snackbar();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  onSubmitNewVisitor = (e) => {
    e.preventDefault();

    const {
      name,
      cnic,
      phoneNo,
      purpose,
      file,
      accountId,
      accountType,
      passNo,
      description,
    } = this.state;
    if (
      Object.is(name, "") ||
      Object.is(cnic, "") ||
      Object.is(phoneNo, "") ||
      Object.is(purpose, "") ||
      Object.is(file, "") ||
      Object.is(passNo, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      let image = file;
      let data = new FormData();
      data.append("name", name);
      data.append("cnic", cnic);
      data.append("phoneNo", phoneNo);
      data.append("accountId", accountId);
      data.append("accountType", accountType);
      data.append("image", image);
      data.append("purpose", purpose);
      data.append("passNo", passNo);
      data.append("description", description);
      this.setState({
        isLoading: true,
      });

      console.log(data);

      this.props.sharedActions
        .simpleAddRequest("/api/VisitorEntryRecord/", data)
        .then((success) => {
          this.setState({
            errorMsg: false,
            enabled: false,
            file: "",
            name: "",
            cnic: "",
            phoneNo: "",
            purpose: "",
            passNo: "",
            description: "",
            exist: "",
            msg: "Visitor Added Successfully",
            isLoading: false,
          });
          this.props.snackbar();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }

  clicked = (e, item) => {
    this.setState({
      passNo: item,
    });
  };
  render() {
    const { errors, exist } = this.state;

    const checkVisitor = (
      <div>
        <div className="row" style={{ justifyContent: "center" }}>
          <TextInput
            feildName={"phoneNoCheck"}
            onHandleChange={this.onHandleTextChange}
            errors={errors}
            stateData={this.state}
            placeholder={"Enter Phone #"}
            isValidte={false}
          />
        </div>
        <Button
          icon={true}
          width="100%"
          buttonClass="customButton"
          btnName="Submit"
          loaderBmargin={5}
          stateData={this.state}
          marginTop={20}
          onHandleSubmit={this.onSubmitCheck}
          margin={"0 auto"}
          textColor="white"
        />
      </div>
    );

    const newVisitor = (
      <ThemeContext.Consumer>
        {(context) => (
          <div>
            <div className="row">
              <div class="col-md-6">
                <TextInput
                  customWidth={true}
                  enterWidth={"300px"}
                  feildName={"name"}
                  inputLabelName={"Visitor Name"}
                  onHandleChange={this.onHandleTextChange}
                  errors={errors}
                  stateData={this.state}
                  placeholder={"Visitor Name"}
                  isValidte={false}
                />

                <TextInput
                  customWidth={true}
                  enterWidth={"300px"}
                  feildName={"cnic"}
                  inputLabelName={"Visitor ID"}
                  onHandleChange={this.onHandleNicChange}
                  errors={errors}
                  stateData={this.state}
                  placeholder={"e.g: 12345678"}
                  isValidte={false}
                />

                <TextInput
                  customWidth={true}
                  enterWidth={"300px"}
                  feildName={"phoneNo"}
                  inputLabelName={"Phone Number"}
                  onHandleChange={this.onHandleTextChange}
                  errors={errors}
                  stateData={this.state}
                  placeholder={"Enter Phone #"}
                  isValidte={false}
                />

                <TextArea
                  customWidth={true}
                  enterWidth={"300px"}
                  feildName={"purpose"}
                  inputLabelName={"Purpose of Visit"}
                  onHandleChange={this.onHandleTextChange}
                  errors={errors}
                  stateData={this.state}
                  placeholder={"Add Purpose Of Visiting"}
                  isValidte={false}
                />
              </div>
                
              <div class="col-md-6">
                {/* <TextInput
                  customWidth={true}
                  enterWidth={"300px"}
                  feildName={"passNo"}
                  inputLabelName={"Pass Number"}
                  onHandleChange={this.onHandleTextChange}
                  errors={errors}
                  stateData={this.state}
                  placeholder={"Pass #"}
                  isValidte={false}
                /> */}

                <TextArea
                  customWidth={true}
                  enterWidth={"300px"}
                  feildName={"description"}
                  inputLabelName={"Description"}
                  onHandleChange={this.onHandleTextChange}
                  errors={errors}
                  stateData={this.state}
                  placeholder={"Add Description"}
                  isValidte={false}
                />

                <CaptureVisitor onCapture={this.onCapture} />
                <br />

                <div class="md-form mb-0">
                  <div class="file-upload">
                    <div class="file-select" style={{ borderRadius: 10 }}>
                      <div
                        class="file-select-button"
                        style={{
                          backgroundColor: context.themeColors.primaryColor,
                        }}
                        id="fileName"
                      >
                        Choosen File
                      </div>
                      <div class="file-select-name" id="noFile">
                        {this.state.file === ""
                          ? "Take a snap of visitor"
                          : this.state.file.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ColorPalette
                  // marginTop={20}
                  colors={[
                    "#01ac8a",
                    "#01425e",
                    "#5bc0de",
                    "#d9534f",
                    "#292b2c",
                    "#fcb01a",
                  ]}
                  inputLabelName={"Card Color"}
                  onHandleSubmit={(e, item) => this.clicked(e, item)}
                />
            </div>
            <Button
              width="100%"
              buttonClass="customButton"
              btnName="Submit"
              loaderBmargin={5}
              stateData={this.state}
              marginTop={20}
              onHandleSubmit={this.onSubmitNewVisitor}
              margin={"0 auto"}
              textColor="white"
            />
          </div>
        )}
      </ThemeContext.Consumer>
    );

    const existingVisitor = (
      <div>
        <div className="row">
          <TextInput
            feildName={"name"}
            inputLabelName={"Visitor Name"}
            onHandleChange={this.onHandleTextChange}
            errors={errors}
            readOnly={true}
            stateData={this.state}
            placeholder={"Visitor Name"}
            isValidte={false}
          />

          <TextInput
            readOnly={true}
            feildName={"phoneNo"}
            inputLabelName={"Phone Number"}
            onHandleChange={this.onHandleTextChange}
            errors={errors}
            stateData={this.state}
            placeholder={"Enter Phone #"}
            isValidte={false}
          />

          <TextInput
            readOnly={true}
            feildName={"cnic"}
            inputLabelName={"Visitor ID"}
            onHandleChange={this.onHandleTextChange}
            errors={errors}
            stateData={this.state}
            placeholder={"e.g: 12345678"}
            isValidte={false}
          />

          {/* <TextInput
                        feildName={'passNo'}
                        inputLabelName={'Pass Number'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Pass #'}
                        isValidte={false}
                    /> */}

          <ColorPalette
            // marginTop={20}
            colors={[
              "#01ac8a",
              "#01425e",
              "#5bc0de",
              "#d9534f",
              "#292b2c",
              "#fcb01a",
            ]}
            inputLabelName={"Card Color"}
            onHandleSubmit={(e, item) => this.clicked(e, item)}
          />

          <TextArea
            feildName={"purpose"}
            inputLabelName={"Purpose of Visit"}
            onHandleChange={this.onHandleTextChange}
            errors={errors}
            stateData={this.state}
            placeholder={"Add Purpose Of Visiting"}
            isValidte={false}
          />

          <TextArea
            feildName={"description"}
            inputLabelName={"Description"}
            onHandleChange={this.onHandleTextChange}
            errors={errors}
            stateData={this.state}
            placeholder={"Add Description"}
            isValidte={false}
          />
        </div>
        <Button
          width="100%"
          buttonClass="customButton"
          btnName="Submit"
          loaderBmargin={5}
          stateData={this.state}
          marginTop={20}
          onHandleSubmit={this.onSubmitExistingVisitor}
          margin={"0 auto"}
          textColor="white"
        />
      </div>
    );

    const Form = (
      <ThemeContext.Consumer>
        {(context) => (
          <FormLayoutWrapper
            formName="Visitor Entry"
            borderColor={context.themeColors.primaryColor}
            borderRadius={15}
          >
            {this.state.errorMsg ? (
              <p style={{ color: "red", textAlign: "center" }}>
                Please fill the form properly
              </p>
            ) : (
              ""
            )}

            {Object.is(exist, "")
              ? checkVisitor
              : Object.is(exist, 200)
              ? existingVisitor
              : Object.is(exist, 400)
              ? newVisitor
              : ""}
          </FormLayoutWrapper>
        )}
      </ThemeContext.Consumer>
    );
    return (
      <React.Fragment>
        <SnackBar msg={this.state.msg} />
        {Form}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddVisitorEntry);

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull'

import SnackBar from "../../components/SnackBar/SnackBar";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";


class AddCleanessCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: false,
            successMsg: false,
            msg: '',
            isLoading: false,
            categoryName: '',
            type: ""
        }
    }

    componentDidMount() {

    }


    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
        })
    }

    myFunction = () => {
        // Get the snackbar DIV
        var x = document.getElementById('snackbar');

        // StepperGeneralButtons the "show" class to DIV
        x.className = 'show';

        // After 3 seconds, remove the show class from DIV
        setTimeout(function () {
        }, 3000);
    };

    onHandleTextChange = e => {
        console.log([e.target.name], e.target.value);

        this.setState({
            [e.target.name]: e.target.value
        })
    }


    handleSubmit = e => {
        e.preventDefault();
        const { categoryName, type } = this.state;
        if (
            isNull(categoryName) || isNull(type)
        ) {
            this.setState({
                successMsg: false,
                errorMsg: true,
                msg: 'Please fill the form respectively!'
            })
        } else {

            let data = {
                accountId: this.props.userData.unique_name,
                accountType: this.props.userData.role,
                categoryName,
                type
            }

            console.log(data);

            this.props.sharedActions.addRecordWithoutDispatch(
                `/api/CleanessCategory`,
                data
            ).then(success => {
                console.log(success);

                this.setState({
                    msg: 'Category Added!',
                    isLoading: false,
                    categoryName: '',
                    type: ""
                })
                this.props.snackbar();
            }).catch(error => {
                this.setState({
                    msg: error.response ? error.response.data.Message : 'Error Occured!!',
                    isLoading: false
                })
                this.props.snackbar();
                console.error('Error occured', error);

            })
        }
    }

    render() {

        const { successMsg, errorMsg, msg, isLoading,
            categoryName
        } = this.state;

        return (
            <div className="container">
                <section className="mb-4">
                    <ThemeContext.Consumer>
                        {(context) => (
                            <h2
                                style={{ background: context.themeColors.primaryColor }}
                                className="h1-responsive font-weight-bold text-center my-4 generalHead">
                                Add Cleanliness Category
                            </h2>
                        )}
                    </ThemeContext.Consumer>

                    <SnackBar msg={this.state.msg} backColor={this.state.backColor} />

                    <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                        <ThemeContext.Consumer>
                            {(context) => (
                                <div className="col-md-9 mb-md-0 mb-5" style={{
                                    border: 'solid',
                                    borderColor: context.themeColors.primaryColor,
                                    padding: 40,
                                    borderRadius: 15,
                                    width: 'auto'

                                }}>
                                    <LoaderWrapper isLoading={this.state.isLoading}>
                                        <form id="floor-form" name="floor-form" onSubmit={this.handleSubmit} >
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="md-form mb-0">
                                                        <DateandTime />
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="md-form mb-0">
                                                        <label for="AdminName" class="">
                                                            Admin Name
											</label>
                                                        <input

                                                            type="text"
                                                            name="AdminName"
                                                            class="form-control"
                                                            readOnly={true}
                                                            value={this.props.userData.unique_name}
                                                        />
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="md-form mb-0">
                                                        <label for="AdminType" class="">
                                                            Admin Type
											</label>
                                                        <input

                                                            type="text"
                                                            name="AdminType"
                                                            class="form-control"
                                                            readOnly={true}
                                                            value={this.props.userData.role}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="md-form mb-0" >
                                                        <label for="categoryName">
                                                            Category
                                        </label>
                                                        <input

                                                            type="text"
                                                            name="categoryName"
                                                            class="form-control"
                                                            value={categoryName}
                                                            onChange={this.onHandleTextChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label for="categoryName">
                                                        Type
                                        </label>
                                                    <select class="custom-select"
                                                        value={this.state.type}
                                                        name="type"
                                                        onChange={this.onHandleTextChange}

                                                    >
                                                        <option value="">Please Select Type</option>
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                        <option value="Quarterly">Quarterly</option>
                                                        <option value="HalfYearly">HalfYearly</option>
                                                        <option value="Annually">Annually</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <div className="text-right text-md-center">
                                                <button type="submit" className="btns" style={{
                                                    background: context.themeColors.primaryColor,
                                                }}>
                                                    Add
                                                <span style={{
                                                        marginBottom: 5
                                                    }}
                                                        className={isLoading && 'spinner-border spinner-border-sm'}>
                                                    </span>
                                                </button>
                                            </div>

                                        </form>
                                    </LoaderWrapper>
                                    <div class="status" />
                                </div>
                            )}
                        </ThemeContext.Consumer>
                    </div>
                </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch),
    };
}
function mapStateToProps(state) {
    return {
        userData: state.setUserReducer.users,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCleanessCategory);

import React, { Component } from 'react';
import './ClassSection.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ClassWiseActions from '../../Actions/ClassWiseSearch';
import * as sharedActions from '../../Actions/sharedActions';
import SubStudentComponent from './SubStudentComponent';
class ClassWiseSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dummy: [],
			sessions: []
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.dummy !== nextProps.selectStudent ||
			prevState.sessions !== nextProps.selectSession
		) {
			return {
				dummy: nextProps.selectStudent,
				sessions: nextProps.selectSession
			};
		}
		return null;
	}
	componentDidMount() {
		this.props.sharedActions
			.gAllData(
				'/api/Session',
				this.props.ClassWiseActions.getSessionsForCensus
			)
			.then(success => { })
			.catch(error => { });
		this.props.sharedActions
			.gAllData(
				'/api/ClassSection',
				this.props.ClassWiseActions.getStudentForClassWise
			)
			.then(success => { })
			.catch(error => { });
	}
	render() {
		const { classList } = this.state.dummy;
		const { section } = this.state.dummy;
		const { session } = this.state.sessions;
		console.log('Sessions', session);
		return (
			<div>
				<div style={{ justifyContent: 'center', textAlign: 'center' }}>
					<SubStudentComponent
						data={classList}
						Document={section}
						session={session}
					/>
				</div>
			</div>
		);
	}
}
function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		ClassWiseActions: bindActionCreators(ClassWiseActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectStudent: state.setClassWiseReducer.selectStudent,
		selectSession: state.setClassWiseReducer.selectSession
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ClassWiseSearch);

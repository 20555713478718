import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import TextInput from '../../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../../Actions/sharedActions';
import Button from '../../../components/Button';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import Spinner from '../../../components/Spinner/Spinner';
import general from '../../../utils/general';
import ThemeContext from "../../../context/themeContext/ThemeContext";
class AddDoctor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            accountId: props.name,
            accountType: props.role,
            errors: {},
            errorMsg: false,
            isLoadingInitialData: true,
            mainData: {},
            departmentData: [],
            postData: [],
            staffData: [],
            staffId: '',
            doctorName: ''
        }
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                '/api/StaffPostDepart'
            )
            .then(success => {
                this.setState({
                    mainData: success,
                    departmentData: success.department,
                    isLoadingInitialData: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoadingInitialData: false
                });
                console.log(error);
            });
    }

    onHandleDepartChange = e => {

        const { mainData } = this.state;
        const departmentId = e.target.value
        let filteredPostData = general.dataFilteration(
            mainData.post,
            ['postId', 'postName'],
            { departmentId: parseInt(departmentId) })

        this.setState({
            departmentId: e.target.value,
            postData: filteredPostData,
            staffData: []
        })
    }

    onHandlePostChange = e => {
        if (e.target.value === 'Post') {
            this.setState({
                postId: ''
            })
        } else {
            const { departmentId, mainData } = this.state;
            const postId = e.target.value
            const staffFilteredData = general.dataFilteration(
                mainData.staff,
                ['staffId', 'staffName'],
                {
                    postId: parseInt(postId),
                    departmentId: parseInt(departmentId)
                }
            )
            this.setState({
                [e.target.name]: e.target.value,
                staffData: staffFilteredData
            })
        }
    }

    onHandleSelectChange = (e) => {
        const { mainData } = this.state
        if (e.target.value === 'Staff') {
            this.setState({
                staffId: ''
            })
        } else {
            const staffFilteredData = general.dataFilteration(
                mainData.staff,
                ['staffId', 'staffName'],
                {
                    staffId: e.target.value
                }
            )
            this.setState({
                [e.target.name]: e.target.value,
                doctorName: staffFilteredData[0].staffName
            })
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { staffId, doctorName, accountId, accountType } = this.state;

        if (
            Object.is(doctorName, '') ||
            Object.is(staffId, '')) {
            this.setState({
                error: true,
                errorMsg: true
            })
        }
        else {
            this.setState({
                isLoading: true
            })
            const data = {
                staffId,
                doctorName,
                accountId,
                accountType
            }
            console.log(data);
            this.props.sharedActions
                .simpleAddRequest('/api/Doctor/',
                    data)
                .then(success => {
                    this.setState({
                        msg: 'Added Doctor Successfully',
                        isLoading: false,
                        departmentId: '',
                        staffId: '',
                        postId: '',
                        doctorName:'',
                        staffData:[],
                        postData:'',
                        errorMsg: false,
                        error: false,
                    })
                    this.props.snackbar();
                })
        }

    }
    render() {
        const { errors, isLoadingInitialData, departmentData, postData, staffData } = this.state;

        const Form = (
            <ThemeContext.Consumer>
            {(context) => (
            <FormLayoutWrapper
                formName='Add Doctor'
                borderColor={context.themeColors.primaryColor}
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
								</p>
                ) : (
                        ''
                    )}


                <div className="row">
                    <TextInput
                        feildName={'accountId'}
                        inputLabelName={'Admin Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Admin Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <TextInput
                        feildName={'accountType'}
                        inputLabelName={'Account Type'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Account Type"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'departmentName'}
                        selectName={'Department'}
                        onHandleChange={e => this.onHandleDepartChange(e)}
                        errors={errors}
                        optionsArrys={departmentData}
                        selectedText={'Department'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'departmentName'}
                        propertyId={'departmentId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'postId'}
                        selectName={'Post'}
                        onHandleChange={e => this.onHandlePostChange(e)}
                        errors={errors}
                        optionsArrys={postData}
                        selectedText={'Post'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'postName'}
                        propertyId={'postId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />
                    <SelectionInput
                        marginTop={20}
                        feildName={'staffId'}
                        selectName={'Doctor Name'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={staffData}
                        selectedText={'Doctor'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'staffName'}
                        propertyId={'staffId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />


                </div>
                <br />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Add"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor = {'white'}
                    btnBackColor = {context.themeColors.primaryColor}
                />

            </FormLayoutWrapper>
                                    )}
                                    </ThemeContext.Consumer>
        )
        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {isLoadingInitialData ? <Spinner /> : Form}
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDoctor);
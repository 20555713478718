import React, { Component } from 'react';
import Loader from '../../components/HOC/LoaderWrapper';
import DateandTime from '../Transport/DateandTime';
import * as sharedActions from '../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from 'jquery';
import SnackBar from '../../components/SnackBar/SnackBar';
import LoaderWrapper from '../../components/HOC/LoaderWrapper';
import months from '../../utils/general';
import DatePicker from 'react-date-picker';
import TimePicker from 'rc-time-picker';
import Moment from 'moment';
import ThemeContext from "../../context/themeContext/ThemeContext";
class AddEvent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			eventList: [],
			Budget: [],
			AllData: [],
			backColor: '',
			class: [],
			section: [],
			session: [],
			sessionId: '',
			month: '',
			eventDate: '',
			eventTime: '',
			description: '',
			subject: '',
			EventGrades: [
				{
					classId: '',
					sectionId: ''
				}
			],
			errorMsg: false,
			msg: false
		};
	}

	onClickDeleteItem = arrName => {
		let getStateArr = this.state[`${arrName}`];
		let getDeleteLength = getStateArr.length - 1;
		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			this.setState({
				[arrName]: [...getNewDetails]
			});
		}
	};

	onClickAddItem = (arrName, obj) => {
		let getStateArr = this.state[`${arrName}`];
		getStateArr = [...getStateArr, obj];
		console.log(arrName);
		this.setState({
			[arrName]: getStateArr
		});
	};
	updateSpecialArrObjOnChange = (e, index, arrName) => {
		debugger;
		const changeArrOfObj = this.state[`${arrName}`];
		changeArrOfObj[index][e.target.name] = e.target.value;

		if (e.target.name == 'classId') {
			const Sections = this.state.AllData.section.filter(
				item => item.classId == e.target.value
			);
			this.setState({
				section: Sections
			});
		}
		this.setState({
			[arrName]: changeArrOfObj
		});
		console.log(this.state);
	};
	onChangeText = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();
		const {
			subject,
			eventTime,
			eventDate,
			EventGrades,
			description,
			month,
			sessionId
		} = this.state;
		if (
			Object.is(eventDate, '') ||
			Object.is(eventTime, '') ||
			Object.is(month, '') ||
			Object.is(description, '') ||
			Object.is(subject, '') ||
			Object.is(sessionId, '')
		) {
			this.setState({
				errorMsg: true
			});
		} else {
			let eventDate = Moment(this.state.eventDate).format('MM/DD/YYYY');
			const data = {
				subject,
				eventTime,
				eventDate,
				description,
				month,
				sessionId,
				accountType: this.props.role,
				accountId: this.props.name
			};
			this.props.sharedActions
				.simpleAddRequest('/api/Event', data)
				.then(success => {
					this.setState({
						errorMsg: false,
						eventTime: '',
						eventDate: '',
						description: '',
						sessionId: '',
						month: '',
						subject: '',
						EventGrades: [
							{
								classId: '',
								sectionId: ''
							}
						],
						msg: 'Event Added Successfully',
						backColor: '#01ac8a'
					});
					this.props.snackbar();
				})
				.catch(error => {
					this.props.sharedActions
						.getDataWithoutDispatch(
							`/api/ListOfEventDateClass?date=${error.response.data.Message}`
						)
						.then(success => {
							debugger;
							this.setState({
								eventList: success.BindAllEventByClasses,
								isLoading: false
							});
						})
						.catch(err => {
							this.setState({});
							console.log(err);
						});
					$('#myModal1').modal('show');
					this.setState({
						msg: error.response.data.Message,
						backColor: 'red'
					});
					this.props.snackbar();
				});
		}
		console.log(this.state);
	};

	onHandleSubmitForcefully = e => {
		e.preventDefault();
		const {
			subject,
			eventTime,
			eventDate,
			description,
			month,
			sessionId
		} = this.state;

		const eventDate2 = Moment(this.state.eventDate).format('MM/DD/YYYY');
		const data = {
			subject,
			eventTime,
			eventDate: eventDate2,
			description,
			month,
			sessionId,
			accountType: this.props.role,
			accountId: this.props.name
		};
		this.props.sharedActions
			.simpleAddRequest('/api/ForceInsertEvent', data)
			.then(success => {
				$('#myModal1').modal('hide');
				this.setState({
					errorMsg: false,
					eventTime: '',
					eventDate: '',
					description: '',
					sessionId: '',
					month: '',
					subject: '',
					msg: 'Event Added Successfully',
					backColor: '#01ac8a'
				});
				this.props.snackbar();
			})
			.catch(error => {
				this.setState({
					msg: error.response.data.Message,
					backColor: 'red'
				});
				this.props.snackbar();
			});
		console.log(this.state);
	};
	componentDidMount() {
		this.props.sharedActions
			.getDataWithoutDispatch('/api/ClassSection')
			.then(success => {
				debugger;
				this.setState({
					AllData: success,
					class: success.classList,
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({});
				console.log(err);
			});
		this.props.sharedActions
			.getDataWithoutDispatch('/api/Session')
			.then(success => {
				debugger;
				this.setState({
					session: success.session,
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({});
				console.log(err);
			});
	}

	componentDidUpdate() {
		if (this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					errorMsg: false
				});
			}, 3000);
		}
	}

	onSessionChange = e => {
		this.setState({
			sessionId: e.target.value
		});
	};

	changeDate = (date, state) => {
		this.setState({
			[state]: date
		});
		console.log(date);
	};
	onTimeChange = e => {
		this.setState({
			eventTime: e.format('LT')
		});
		console.log(e.format('LT'));
	};
	render() {
		const format = 'h:mm';

		const now = Moment()
			.hour(0)
			.minute(0);

		const Modal = (
			<div>
				<div class="modal fade" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Warning
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="text-center">
									<label class="font-weight-bold text-danger">
										Already Event Added on this Date {this.state.msg}
									</label>
								</div>
								<table class="table table-sm">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Subject</th>
											<th scope="col">Grades</th>
											<th scope="col">Description</th>
										</tr>
									</thead>
									<tbody>
										{this.state.eventList.map((item, index) => (
											<tr>
												<th>{index + 1}</th>
												<td>{item.subject}</td>
												<td>
													{item.classes.map((items, index) => (
														<label>{items.className},</label>
													))}
												</td>
												<td>{item.description}</td>
											</tr>
										))}
									</tbody>
								</table>
								<div class="text-right text-md-center">
									<button
										onClick={this.onHandleSubmitForcefully}
										class="buttonHover2"
									>
										Add Event Forcefully
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		return (
			<ThemeContext.Consumer>
			{(context) => (
			<div>
				{Modal}
				<section class="mb-4">
					<h2 class="h1-responsive font-weight-bold text-center my-4 generalHead"
            style={{ background: context.themeColors.primaryColor }}>
						Add Events
					</h2>

					<SnackBar msg={this.state.msg} backColor={this.state.backColor} />
					<p class="text-center w-responsive mx-auto mb-5" />

					<div
						class="row"
						style={{
							justifyContent: 'center',
							textAlign: 'center'
						}}
					>
						<div
							class="col-md-9 mb-md-0 mb-5"
							style={{
								border: 'solid',
								borderColor: context.themeColors.primaryColor,
								padding: 40,
								borderRadius: 15,
								width: 'auto'
							}}
						>
							<LoaderWrapper isLoading={this.state.isLoading}>
								<form id="contact-form" name="contact-form">
									<div class="row">
										<div class="col-md-12">
											<div class="md-form mb-0">
												<DateandTime />
											</div>
										</div>
									</div>
									<br />
									<div class="row">
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="font-weight-bold">
													Admin Name
												</label>
												<input
													value={this.props.name}
													readOnly={true}
													type="text"
													name="AdminName"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="font-weight-bold">
													Admin Type
												</label>
												<input
													value={this.props.role}
													readOnly={true}
													type="text"
													name="AdminType"
													class="form-control"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="font-weight-bold">
													Month
												</label>
												<select
													onChange={this.onHandleTextChange}
													class="custom-select"
													name="month"
												>
													<option selected={this.state.msg ? true : false}>
														Select Month...
													</option>
													{months.Months.map((item, index) => (
														<option key={index} value={`${item}`}>
															{item}
														</option>
													))}
												</select>
											</div>
										</div>

										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="email" class="font-weight-bold">
													Event / Subject
												</label>
												<input
													placeholder="Enter Subject / Event"
													type="text"
													name="subject"
													value={this.state.subject}
													className="form-control check"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="email" class="font-weight-bold">
													Event Time
												</label>
												<br />
												<TimePicker
													style={{
														width: 200,
														borderColor: 'black',
														borderStyle: 'solid',
														borderWidth: 'thin'
													}}
													showSecond={false}
													defaultValue={now}
													className="xxx"
													onChange={this.onTimeChange}
													format={format}
													use24Hours
													inputReadOnly
												/>
											</div>
										</div>
										<div class="col-md-6" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="font-weight-bold">
													Event Date
												</label>
												<br />
												<DatePicker
													onChange={e => this.changeDate(e, 'eventDate')}
													value={this.state.eventDate}
												/>
											</div>
										</div>
										<div class="col-md-6 text-center" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="name" class="font-weight-bold">
													Session
												</label>
												<select
													onChange={this.onSessionChange}
													class="custom-select"
													name="month"
												>
													<option
														value=""
														selected={this.state.msg ? true : false}
													>
														Select Session...
													</option>
													{this.state.session.map((item, index) => (
														<option key={index} value={`${item.sessionId}`}>
															{item.currentSession}
														</option>
													))}
												</select>
											</div>
										</div>
										<div class="col-md-12" style={{ marginTop: 20 }}>
											<div class="md-form mb-0">
												<label for="email" class="font-weight-bold">
													Description
												</label>
												<textarea
													style={{ fontStyle: 'italic' }}
													placeholder="Write Description of the Event.."
													rows="5"
													name="description"
													value={this.state.description}
													className="form-control check"
													onChange={this.onHandleTextChange}
												/>
											</div>
										</div>
										{/* <div class="col-md-12">
											<br />
											<br />
											<MultipleEvents
												onHandleTextChange={this.onChangeText}
												stateData={this.state}
												updateSpecialArrObjOnChange={
													this.updateSpecialArrObjOnChange
												}
												onClickAddItem={this.onClickAddItem}
												onClickDeleteItem={this.onClickDeleteItem}
											/>
										</div> */}
									</div>
									<br />
									<br />
									{this.state.errorMsg ? (
										<p style={{ color: 'red', textAlign: 'center' }}>
											Please fill the form properly
										</p>
									) : (
										''
									)}
									<div class="text-right text-md-right">
										<button onClick={this.onHandleSubmit} class="buttonHover2">
											Add
										</button>
									</div>
								</form>
							</LoaderWrapper>
							<div class="status" />
						</div>
					</div>
				</section>
			</div>
			              )}
						  </ThemeContext.Consumer>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		auth: state.setUserReducer.auth,
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name,
		accountId: state.setUserReducer.users.accountId
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEvent);

import React from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import SnackBar from '../../components/SnackBar/SnackBar';
import * as sharedActions from '../../Actions/sharedActions';
import SelectionInput from '../../components/SelectionInput/SelectionInput'
import { array } from 'prop-types';
import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewClassRenew extends React.Component {
    state = {
        classData: [],
        isLoading: true,
        msg: '',
        modalData: null,
        enabled: false,
        id: null,
        accountType: '',
        accountId: '',
        selectFlag: true,
        className: '',
        gradeId: '',
        gradeName: '',
        editFlag: true,
        currentPage: 1,
        todosPerPage: 10,
        isLoadingEditDelete: false,
        errors: {},
        gradeData: [],
        disablepage: false,
        disabledPB: true,
        disabledP: true,
        successflag: false,
        count: 1
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }
    componentDidMount() {

        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.props.sharedActions
            .getDataWithoutDispatch(
                '/api/viewclass'
            )
            .then(success => {
                this.setState({
                    isLoading: false,
                    classData: success
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                });
            })

        this.props.sharedActions
            .getDataWithoutDispatch(
                '/api/AddGrade'
            )
            .then(success => {
                this.setState({
                    gradeData: success,
                    isLoading: false
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                });
                console.log(err);
            })
    }

    onTakeItem = itemId => {

        this.setState({
            modalData: []
        });
        const { classData } = this.state;

        let getData = classData.filter(item => item.classId === itemId);

        this.setState({
            modalData: getData,
            accountType: getData[0].accountType,
            accountId: getData[0].accountId,
            className: getData[0].className,
            id: getData[0].classId,
            gradeName: getData[0].gradeName,
            gradeId: getData[0].id
        });
    };

    onTakeItemDelete = itemId => {
        const { classData } = this.state;
        this.setState({
            modalData: [],
            isLoading: true
        });

        this.props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/addclass',
                itemId
            )
            .then(success => {
                const result = classData.filter(classItem =>
                    classItem.classId !== itemId
                )

                this.setState({
                    msg: "Deleted Successfully!",
                    classData: result,
                    isLoading: false
                })
                this.props.snackbar();
            })
    }

    onChangeClass = e => {
        this.setState({
            className: e.target.value
        })
    }


    onSelectGradeChange = e => {

        this.setState({
            gradeId: e.target.value,
        })
    }


    onHandleSubmit = e => {
        e.preventDefault();
        const { className, gradeId, id, accountType, accountId, classData, gradeData } = this.state;

        var gName = gradeData.filter((grade) => grade.id == gradeId);

        this.setState({
            gradeName: gName[0].gradeName
        })

        const { gradeName } = this.state;
        var bodyParameters = { id, className, gradeId, accountId, accountType, gradeName: gName[0].gradeName };

        console.log(gName)
        console.log(bodyParameters.gradeName)
        this.props.sharedActions
            .editRecordWithoutDispatch(
                '/api/addclass/',
                id,
                bodyParameters,
            )
            .then(success => {
                classData.filter((classItem, index) => {
                    if (classItem.classId === id) {
                        classData.splice(index, 1, bodyParameters)
                    }
                })
                this.setState({
                    enabled: false,
                    msg: 'Edited Record Successfully!',
                    editFlag: true,
                    selectFlag: true
                });
                this.props.snackbar();
            })
            .catch(err => { });
    };


    onEditClick = itemId => {
        this.onTakeItem(itemId);
        this.setState({
            enabled: false,
        });
    };

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    handleClickNextFrwd = () => {
        const { classData, todosPerPage, count, currentPage } = this.state;
        let pageNumbers = [];
        for (
            let i = 1;
            i <= Math.ceil(classData.length / todosPerPage);
            i++
        ) {
            pageNumbers.push(i);
        }
        console.log('pagelength: ', pageNumbers.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumbers.length) {
            this.setState({
                disablepage: true
            });
        } else {
            this.setState(prevState => ({
                currentPage: prevState.currentPage + 1,
                disableBPage: false
            }));
        }
    };

    handleClickNextBack = () => {
        const { classData, todosPerPage, count, currentPage } = this.state;
        console.log('current page: ', currentPage);
        if (currentPage === 1) {
            this.setState({
                disableBPage: true,
                disablepage: false
            });
        } else {
            this.setState(prevState => ({
                currentPage: prevState.currentPage - 1
            }));
        }
    };

    handleClickNext = event => {
        this.setState({
            currentPage: Number(event.target.id),
            disablepage: false,
            disableBPage: false
        });
    };


    render() {
        const { modalData, classData, isLoading, selectFlag, editFlag, className, errors, gradeName } = this.state;
        const Loader = (
            <ThemeContext.Consumer>
            {(context) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div class="spinner-border" style={{ color: context.themeColors.primaryColor
 }}></div>
            </div>
            )}
            </ThemeContext.Consumer>
        );

        let pageNumbers = [];
        for (
            let i = 1;
            i <= Math.ceil(classData.length / this.state.todosPerPage);
            i++
        ) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return index <= 3 ? (
                <ThemeContext.Consumer>
                {(context) => (
                <li
                    class={`page-item ${
                        this.state.currentPage === number ? 'active' : ''
                        } `}
                >
                    <a
                        key={number}
                        id={number}
                        onClick={this.handleClickNext}
                        class="page-link "
                        href="#"
                        style={{backgroundColor: context.themeColors.primaryColor}}
                    >
                        {number}
                    </a>
                </li>
                )}
                </ThemeContext.Consumer>
            ) : (
                    ''
                );
        });
        const { currentPage, todosPerPage } = this.state;
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = classData.slice(indexOfFirstTodo, indexOfLastTodo);
        const renderTodos = currentTodos.map((item, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td style={{ padding: 2 }}>{item.className}</td>
                    <td style={{ padding: 2 }}>{item.gradeName}</td>
                    <td style={{ padding: 2 }}>{item.accountId}</td>
                    <td style={{ padding: 2 }}>{item.accountType}</td>

                    <td style={{ padding: 2 }}>
                        <a href="#" data-toggle="tooltip" title="Edit!">
                            <button
                                id="firstbutton"
                                onClick={() => this.onEditClick(item.classId)}
                                onMouseOver={this.btnTechHover()}
                                data-toggle="modal"
                                data-target="#myModal"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <i class="fas fa-pen"></i>
                            </button>
                        </a>
                        <a href="#" data-toggle="tooltip" title="Delete!">
                            <button
                                onClick={() => this.onEditClick(item.classId)}
                                data-toggle="modal"
                                data-target="#myModal1"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    background: 'none',
                                    marginLeft: 5,
                                    marginBottom: 5
                                }}
                            >
                                <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                            </button>
                        </a>
                    </td>
                </tr>
                )}
                </ThemeContext.Consumer>
            );
        });

        const MainContent = (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <div class="modal" id="myModal1">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18,
                                    background: context.themeColors.primaryColor
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Confirm
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            <div style={{ textAlign: 'center' }}>
                                                <h4>Are you sure ?</h4>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                            >
                                                <div>
                                                    <button
                                                        disabled={this.state.enabled}
                                                        data-dismiss="modal"
                                                        onClick={() => this.onTakeItemDelete(this.state.id)}
                                                        type="button"
                                                        class="btn btn-primary buttonAppear"
                                                        style={{background: context.themeColors.primaryColor}}
                                                    >
                                                        Yes
													</button>
                                                </div>
                                                <div>
                                                    <button
                                                        data-dismiss="modal"
                                                        disabled={this.state.enabled}
                                                        type="button"
                                                        class="btn btn-primary buttonAppear"
                                                        style={{background: context.themeColors.primaryColor}}
                                                    >
                                                        No
													</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" id="myModal">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18,
                                    background: context.themeColors.primaryColor
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Details
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.onHandleSubmit}>
                                    {modalData &&
                                        modalData.map((item, index) => (
                                            <div key={index}>
                                                <fieldset disabled={!this.state.enabled}>
                                                    <div class="form-group">
                                                        <label for="classname" style={{
                                                            padding: 10
                                                        }}>
                                                            Class Name</label>
                                                        <input
                                                            style={{
                                                                borderTop: 0,
                                                                borderLeft: 0,
                                                                borderRight: 0,
                                                                boxShadow: 'none'
                                                            }}
                                                            type='text'
                                                            value={this.state.className}
                                                            onChange={this.onChangeClass}
                                                            class="form-control"
                                                            id="classname"
                                                            disabled={editFlag}
                                                        />
                                                    </div>
                                                    <SelectionInput
                                                        feildName={'grade'}
                                                        newFlag={
                                                            'customWidth'
                                                        }
                                                        isValidte={false}
                                                        selectName={'Grade'}
                                                        onHandleChange={this.onSelectGradeChange}
                                                        errors={errors}
                                                        optionsArrys={this.state.gradeData}
                                                        selectedText={'Grade'}
                                                        stateData={this.state}
                                                        optionType='dynamicWithPropIdAndName'
                                                        property={'gradeName'}
                                                        propertyId={'id'}
                                                        successflag={'successflag'}>
                                                    </SelectionInput>

                                                </fieldset>
                                                <br />
                                                <button
                                                    disabled={this.state.enabled}
                                                    onClick={() =>
                                                        this.setState({ enabled: true, editFlag: false })
                                                    }
                                                    type="button"
                                                    class="btn btn-primary buttonAppear"
                                                    style={{background: context.themeColors.primaryColor}}
                                                >
                                                    Edit
												</button>
                                                <button
                                                    style={{ marginLeft: 5, background: context.themeColors.primaryColor }}
                                                    disabled={!this.state.enabled}
                                                    type="submit"
                                                    class="btn btn-primary buttonAppear"
                                                >
                                                    Save
												</button>
                                            </div>
                                        ))}
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={() => this.setState({
                                        editFlag: true
                                    })}
                                >
                                    Close
								</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table class="table table-hover">
                        <thead style={{ background: context.themeColors.primaryColor
, color: 'white' }}>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Class Name</th>
                                <th scope="col">Grade Name</th>
                                <th scope="col">AccountId</th>
                                <th scope="col">AccountType</th>

                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>{renderTodos}</tbody>
                    </table>
                </div>
            </div >
            )}
            </ThemeContext.Consumer>
        )


        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div>
                <div classname='page-header'>
                    <SnackBar msg={this.state.msg} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>
                        <div>
                        <h2 class="h1-responsive font-weight-bold text-center my-4 generalHead"
            style={{ background: context.themeColors.primaryColor }}
          >
            View Class
          </h2>
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul style={{ color: context.themeColors.primaryColor
 }} class="pagination">
                                <li
                                    class={`page-item ${
                                        this.state.disableBPage ? 'disabled' : ''
                                        }`}
                                >
                                    <a
                                        onClick={this.handleClickNextBack}
                                        class="page-link"
                                        href="#"
                                    >
                                        {this.state.disableBPage ? (
                                            <i
                                                style={{ color: 'grey' }}
                                                class="far fa-stop-circle"
                                            ></i>
                                        ) : (
                                                <i class="fas fa-backward"></i>
                                            )}
                                    </a>
                                </li>
                                {renderPageNumbers}
                                <li
                                    class={`page-item ${
                                        this.state.disablepage ? 'disabled' : ''
                                        }`}
                                >
                                    <a
                                        onClick={this.handleClickNextFrwd}
                                        class="page-link"
                                        href="#"
                                    >
                                        {this.state.disablepage ? (
                                            <i
                                                style={{ color: 'grey' }}
                                                class="far fa-stop-circle"
                                            ></i>
                                        ) : (
                                                <i class="fas fa-forward"></i>
                                            )}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {isLoading ? Loader : MainContent}
            </div>
            )}
            </ThemeContext.Consumer>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(ViewClassRenew)
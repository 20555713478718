import React, { useEffect, useRef, useState } from "react";
import useHttp from "../../../hooks/http";
import Spinner from "../../../components/Spinner/Spinner";
import $ from "jquery";
import Colors from "../../../utils/app_constants/colors_constants";
import Font_Constant from "../../../utils/app_constants/fonts_constants";
import { General } from "../../../utils";
import * as sharedActions from "../../../Actions/sharedActions";
import { connect } from "react-redux";
import config from "../../../config/config";
import { bindActionCreators } from "redux";
import ValidationText from "../../../utils/app_constants/validate_messages_constants";
import Axios from "axios";
import SnackBar from "../../../components/SnackBar/SnackBar";
import StaffDocumentUploadSection from "../StaffDocumentUploadSection/StaffDocumentUploadSection";
import UploadProgressBar from "../../../components/UploadProgressBar/UploadProgressBar";
import URL_CONSTANTS from "../../../utils/app_constants/URL_constants";
import ThemeContext from "../../../context/themeContext/ThemeContext";
function StaffFileUploadView(props) {
  const [isDocExist, setIsDocExist] = useState(null);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [percent, setPercent] = useState(0);
  const [customLoader, setCustomLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const { match } = props;
  const { type } = props.match.params;
  const [isLoading, fetchData, setFetchData, setIsLoading] = useHttp(
    `/api/DocumentStaff/?id=${match.params.id}`,
    []
  );
  debugger;
  const checkIsDocExist = () => {
    props.sharedAction
      .getDataWithoutDispatch(
        `/api/CheckStaffDocumentExist?staffId=${match.params.id}`
      )
      .then((success) => {
        setIsDocExist(false);
      })
      .catch((error) => {
        setIsDocExist(true);
      });
  };
  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      $('[data-toggle="tooltip"]').tooltip();
    }
  });
  useEffect(() => {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    checkIsDocExist();
  }, []);
  const btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  const getSplitingResult = (input, valueNum) => {
    if (input === "No") return input;
    debugger;
    let takeInput = input.split("_");
    return takeInput[valueNum].toString();
  };
  let getAllFileName =
    fetchData && fetchData[0] && fetchData[0].t1
      ? fetchData.map((item, index) => {
          return {
            fileName: item["t1"]["fileName"],
            id: item["t1"]["id"],
          };
        })
      : null;
  let getAllKey =
    fetchData && fetchData[0] && fetchData[0].t1
      ? fetchData.map((item) => item["t1"]["fileName"])
      : null;
  let getKeysOfParentDocs =
    fetchData && fetchData ? Object.keys(fetchData) : null;
  let getMeaningParentProp =
    getKeysOfParentDocs &&
    getKeysOfParentDocs.filter(
      (item) =>
        item !== "id" &&
        item !== "staffId" &&
        item !== "StaffFirstName" &&
        item !== "lastName" &&
        item !== "fileNameExperince" &&
        item !== "fileNameMinistry" &&
        item !== "fileNameQualification" &&
        item !== "fileNamePassport" &&
        item !== "fileNameIdCard" &&
        item !== "fileNameIlets"
    );
  debugger;
  let getValueOfParentProps =
    getKeysOfParentDocs &&
    getKeysOfParentDocs.filter(
      (item) =>
        item !== "id" &&
        item !== "experienceLetter" &&
        item !== "ministryApproval" &&
        item !== "lastQualification" &&
        item !== "passportCopy" &&
        item !== "idCardCopy" &&
        item !== "iletsCertificate" &&
        item !== "staffId" &&
        item !== "StaffFirstName" &&
        item !== "lastName"
    );
  debugger;
  const getDesireObj = (getAllFileName, getMeaningParentProp) => {
    debugger;
    let desire = {};
    let getFileName = getAllFileName;
    let getParentProp = getMeaningParentProp;
    getFileName &&
      getFileName.map((item, index) => {
        desire[getParentProp[index]] = item["fileName"];
      });
    getFileName &&
      getFileName.map((item, index) => {
        desire["id_" + getParentProp[index]] = item["id"];
      });

    debugger;
    return desire;
  };
  let getDesireObject = getDesireObj(getAllFileName, getMeaningParentProp);

  debugger;
  const docsChange = (e, data) => {
    debugger;
    let updateData = { ...fetchData };
    debugger;
    let singleDoc = new FormData();
    e.preventDefault();
    try {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (
        !General.checkForImageOrFile(
          false,
          true,
          errors,
          file.size,
          2.1,
          file.name,
          "fileType",
          setErrors
        )
      ) {
        singleDoc.append("fileName", file);
        singleDoc.append("staffId", fetchData["staffId"]);
        debugger;
        setCustomLoader(true);
        let token = "";
        if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
        Axios.patch(
          `${config.localhttp}/api/DocumentStaff/${data["id"]}/?field=${data["fieldName"]}`,
          singleDoc,
          {
            headers: { Authorization: `Bearer  ${token}` },
            onUploadProgress: (progressEvent) => {
              console.log(
                "percentage: ",
                setPercent(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                ) + "%"
              );
            },
          }
        )
          .then((success) => {
            debugger;
            debugger;
            updateData[data["fieldValue"]] = "Yes_" + data["fieldValue"];
            updateData[data["updatedProps"]] =
              success.data[data["updatedProps"]];
            debugger;
            console.log(updateData);
            setFetchData(updateData);
            setCustomLoader(false);
            setSnackbarMsg("File upload successfully!");
            props.snackbar();
            setTimeout(() => {
              setPercent(0);
            }, 2000);
          })
          .catch((error) => {
            debugger;
          });
      } else {
        debugger;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const renderStaffDoc =
    getMeaningParentProp && getMeaningParentProp.length > 0 ? (
      getMeaningParentProp &&
      getMeaningParentProp.map((item, index) => {
        return (
          <ThemeContext.Consumer>
            {(context) => (
              <tr key={index}>
                <th
                  style={{
                    fontWeight: "bold",
                    border: `1px solid ${context.themeColors.primaryColor}`,
                  }}
                  scope="row"
                >
                  {index + 1}
                </th>
                <td
                  style={{
                    fontWeight: "bold",
                    border: `1px solid ${context.themeColors.primaryColor}`,
                  }}
                >
                  {item == "iletsCertificate" ? "IELTS Certificate" : 
                  General.camelCaseToCamelCase(item)}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: `1px solid ${context.themeColors.primaryColor}`,
                  }}
                >
                  {getSplitingResult(fetchData[item], 0) === "Yes" ? (
                    <span
                      style={{ fontWeight: "bold", color: Colors.LIGHT_GREEN }}
                    >
                      Yes
                    </span>
                  ) : (
                    <span
                      style={{ fontWeight: "bold", color: Colors.RED_DANGER }}
                    >
                      No
                    </span>
                  )}
                </td>

                <td
                  style={{
                    fontWeight: "bold",
                    border: `1px solid ${context.themeColors.primaryColor}`,
                  }}
                >
                  {fetchData[getValueOfParentProps[index]] === null ? (
                    <span
                      style={{ fontWeight: "bold", color: Colors.RED_DANGER }}
                    >
                      No file
                    </span>
                  ) : (
                    <span style={{ fontWeight: "bold" }}>
                      {fetchData[getValueOfParentProps[index]]}
                    </span>
                  )}
                </td>

                <td
                  style={{
                    fontWeight: "bold",
                    border: `1px solid ${context.themeColors.primaryColor}`,
                  }}
                >
                  {getSplitingResult(fetchData[item], 0) === "No" ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div>
                        <i
                          style={{
                            color: Colors.RED_DANGER,
                            fontSize: Font_Constant.MEDIUM_ICON,
                            marginRight: 6,
                            marginTop: 8,
                          }}
                          class="fas fa-ban"
                        ></i>
                      </div>
                      <div>
                        <label class="btn-btn-default">
                          <a
                            id="tooltipId"
                            data-toggle="tooltip"
                            title="upload"
                          >
                            <div
                              style={{
                                color: customLoader
                                  ? Colors.DISABLE_GREY
                                  : Colors.BLACK,
                                fontSize: Font_Constant.MEDIUM_ICON,
                                marginTop: 8,
                              }}
                              class="fa fa-upload fa-2x"
                            ></div>
                          </a>
                          <input
                            disabled={customLoader}
                            onChange={(e) =>
                              docsChange(e, {
                                id: fetchData["id"],
                                fieldName: fetchData[item],

                                fieldValue: item,
                                updatedProps: getValueOfParentProps[index],
                              })
                            }
                            id="inputFile"
                            className="filenew-upload"
                            type="file"
                            // accept="image/*"
                          />
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <button
                        onMouseOver={btnTechHover()}
                        disabled={customLoader}
                        style={{
                          paddingLeft: 0,
                          cursor: "pointer",
                          fontSize: 20,
                          border: "none",
                          background: "none",
                          marginLeft: 5,
                          marginBottom: 5,
                        }}
                      >
                        <a
                          style={{ display: "block" }}
                          href={`${URL_CONSTANTS.URL_STAFF_DOCS}${
                            fetchData[getValueOfParentProps[index]]
                          }`}
                        >
                          <a
                            id="tooltipId"
                            data-toggle="tooltip"
                            title="download"
                          >
                            <i
                              style={{
                                color: customLoader
                                  ? Colors.DISABLE_GREEN
                                  : Colors.LIGHT_GREEN,
                                fontSize: Font_Constant.MEDIUM_ICON,
                              }}
                              class="fas fa-file-download"
                            ></i>
                          </a>
                        </a>
                      </button>
                      {/* <a
										href={`http://www.schoolsmart.org.uk\\Uploads\\StaffDocuments\\${fetchData[0].t1[item]}`}
									> */}
                      <div>
                        <label class="btn-btn-default">
                          <a
                            id="tooltipId"
                            data-toggle="tooltip"
                            title="upload"
                          >
                            <div
                              style={{
                                color: customLoader
                                  ? Colors.DISABLE_GREY
                                  : Colors.BLACK,
                                fontSize: Font_Constant.MEDIUM_ICON,
                                marginTop: 8,
                              }}
                              class="fa fa-upload fa-2x"
                            ></div>
                          </a>
                          <input
                            onChange={(e) =>
                              docsChange(e, {
                                id: fetchData["id"],
                                fieldName: fetchData[item],

                                fieldValue: item,
                                updatedProps: getValueOfParentProps[index],
                              })
                            }
                            id="inputFile"
                            className="filenew-upload"
                            type="file"
                            // accept="image/*"
                          />
                        </label>
                      </div>

                      {/* <button
											onMouseOver={btnTechHover()}
											style={{
												paddingLeft: 0,
												cursor: 'pointer',
												fontSize: 20,
												border: 'none',
												background: 'none',
												marginLeft: 5,
												marginBottom: 5
											}}
											>
											<a id="tooltipId" data-toggle="tooltip" title="upload">
											<i
											style={{
														color: Colors.BLACK,
														fontSize: Font_Constant.MEDIUM_ICON
													}}
													class="fas fa-upload"
													></i>
													</a>
												</button> */}
                      {/* </a> */}
                    </div>
                  )}
                </td>
              </tr>
            )}
          </ThemeContext.Consumer>
        );
      })
    ) : (
      <p style={{ textAlign: "center" }}>No Files</p>
    );
  console.log("document fetch data: ", fetchData);

  return isLoading || isDocExist === null ? (
    <Spinner />
  ) : isDocExist ? (
    <React.Fragment>
      <div style={{ width: "45%" }}>
        <SnackBar backColor="#000" msg={snackbarMsg} />
        <ThemeContext.Consumer>
          {(context) => (
            <h2
              style={{ background: context.themeColors.primaryColor }}
              className="h1-responsive font-weight-bold text-left my-4 generalHead"
            >
              {" "}
              Staff Documents{" "}
            </h2>
          )}
        </ThemeContext.Consumer>
      </div>
      <UploadProgressBar percent={percent} />

      <br />
      <div>
        {errors["fileType"] ? (
          <span style={{ color: Colors.RED_DANGER }}>
            {ValidationText.FILE_EXTENSION_MESSAGE}
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="table-responsive">
        <table class="table table-hover">
          <ThemeContext.Consumer>
            {(context) => (
              <thead
                style={{
                  background: context.themeColors.primaryColor,
                  color: "white",
                }}
              >
                <tr>
                  <th
                    style={{
                      fontWeight: "bold",
                      border: `1px solid ${Colors.WHITE}`,
                    }}
                    scope="col"
                  >
                    #
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      border: `1px solid ${Colors.WHITE}`,
                    }}
                    scope="col"
                  >
                    Staff Documents
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      border: `1px solid ${Colors.WHITE}`,
                    }}
                    scope="col"
                  >
                    Status
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      border: `1px solid ${Colors.WHITE}`,
                    }}
                    scope="col"
                  >
                    FileName
                  </th>

                  <th
                    style={{
                      fontWeight: "bold",
                      border: `1px solid ${Colors.WHITE}`,
                    }}
                    scope="col"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
            )}
          </ThemeContext.Consumer>
          <tbody>{renderStaffDoc}</tbody>
        </table>
      </div>
    </React.Fragment>
  ) : (
    <StaffDocumentUploadSection
      {...props}
      // onSkipDocuments={(onSkipDocuments)}
      id={match.params.id}
      type={match.params.type}
    />
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffFileUploadView);

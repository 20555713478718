import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import { Types } from "../../hooks/HookReducers/StaffReducer";
const RenderEditModules = (props) => {

  const {
    moduleData,
    stringConversion,
    btnTechHover,
    match,
    studentImage,
    id,
    redirectLink = "/dashboard/EditAdmissionForm/",
  } = props;
  
  debugger;
  let keys = Object.keys(moduleData);
  debugger;
  keys = keys.filter(
    (item) =>
      item !== "CampusGrade" &&
      item !== "ParentDocuments" &&
      item !== "StudentDocuments" &&
      item !== "RoutesName" &&
      item !== "DriverName" &&
      item !== "AgentName" &&
      item !== "Siblings" &&
      item !== "StudentDocs" &&
      item !== "ParentDocs" &&
      item !== "StudentImageUpload" &&
      item !== "Sessions" &&
      item !== "StaffImageUpload" &&
      item !== "ExtraFields" &&
      item !== "StaffDoc"
  );
  const getConvertedKeys = keys.map((item) => stringConversion(item));

  let data = null;

  console.log(props);
  return (
    <React.Fragment>
      {getConvertedKeys.map((item, index) => (
        <tr>
          <td style={{ fontWeight: "bolder" }}>{index + 1}</td>
          <td style={{ textAlign: "center", fontWeight: "bolder" }}>{item}</td>
          <td>
            {/* <Link to={`/dashboard/EditAdmissionForm/${keys[index]}`}> */}
            <a id="tooltipId" data-toggle="tooltip" title="Edit!">
              <button
                onClick={() =>
                  props.history.push(
                    redirectLink + keys[index],
                    moduleData,
                    studentImage
                  )
                }
                id="firstbutton"
                onMouseOver={() => btnTechHover()}
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i class="fas fa-pen"></i>
              </button>
            </a>
            {/* </Link> */}
          </td>
        </tr>
      ))}
    </React.Fragment>
  );
};

export default RenderEditModules;

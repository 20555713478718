import React from "react";
import ItemComponent from "../ItemComponent/ItemComponent";
import ThemeContext from "../../context/themeContext/ThemeContext";

export default function ViewComponent(props) {
  const {
    extraData,
    extraRedirectProperty,
    conditionalIconsColor,
    mainData = [],
    headData = [],
    exceptionalHandler,
    excludedData = [],
    redirectIds = [],
    onSetTerm,
    children = () => <div></div>,
  } = props;

  return (
    <div>
      <table class="table table-bordered">
        <thead className="text-center">
          <ThemeContext.Consumer>
            {(context) => (
              <tr
                style={{ background: context.themeColors.primaryColor }}
                className="table-row"
              >
                {headData.length > 0 ? (
                  headData.map((item, index) => (
                    <th style={{ textAlign: "center" }} key={index}>
                      {item}
                    </th>
                  ))
                ) : (
                  <td>no data found</td>
                )}
              </tr>
            )}
          </ThemeContext.Consumer>
        </thead>
        <tbody>
          {mainData && mainData.length > 0 ? (
            mainData.map((item, index) => (
              <tr>
                <td style={{ textAlign: "center", paddingLeft: 0 }}>
                  {index + 1}
                </td>
                <ItemComponent
                  extraData={extraData}
                  conditionalIconsColor={conditionalIconsColor}
                  exceptionalHandler={exceptionalHandler}
                  extraRedirectProperty={extraRedirectProperty}
                  onSetTerm={onSetTerm}
                  redirectIds={redirectIds}
                  excludedData={excludedData}
                  objItem={item}
                  rowIndex={index}
                  data={mainData}
                />
              </tr>
            ))
          ) : (
            <tr style={{ color: "red", border: "none" }}>
              <td></td>
              <td></td>
              <td
                colSpan={2}
                className="text-danger"
                style={{ textAlign: "center" }}
              >
                {" "}
                No Data
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
      <React.Fragment>{children()}</React.Fragment>
    </div>
  );
}

import React from "react";
import { useContext } from "react";
import ThemeContext from "../../context/themeContext/ThemeContext";

const Spinner = (props) => {
  const themeColor = useContext(ThemeContext);
  const {
    display = "flex",
    justifyContent = "center",
    color = themeColor.themeColors.primaryColor,
  } = props;
  return (
    <div style={{ display, justifyContent }}>
      <div class="spinner-border" style={{ color }}></div>
    </div>
  );
};

export default Spinner;

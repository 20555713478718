import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SkillAction from "../../Actions/StudentSkill";
import * as sharedActions from "../../Actions/sharedActions";
import GraphGeneralLayout from "../../components/Graphs/GraphGeneralLayout/GraphGeneralLayout2.jsx";
import { Pie } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import SnackBar from "../../components/SnackBar/SnackBar";
import MultipleSkills from "./MultipleSkill";
import ThemeContext from "../../context/themeContext/ThemeContext";

import $ from "jquery";
// import TableComponent from "./TableComponent";
class PrintSkillReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectAverage: [],
      GraphData: {
        datasets: [
          {
            // barPercentage: 0.4,
            // minBarLength: 2,
            label: "Course Average",
            data: [2, 2, 2],
            backgroundColor: [
              "#dfeaea",
              "#ea766c",
              "#abe2b9",
              "#adbceb",
              "#be9e9d",
              "#9d5b4d",
              "#e4d86b",
              "#9ecf54",
              "#e4d86b",
              "#ea766c",
              "#abe2b9",
              "#adbceb",
            ],
            borderColor: [
              "#abe2b9",
              // '#ea766c',
              // '#adbceb',
              // '#be9e9d',
              // '#9d5b4d',
              // '#e4d86b',
              // '#9ecf54',
              // '#e4d86b'
            ],
            // borderWidth: 2
          },
        ],
        labels: ["BIO", "ICT", "MATH", "SOCIAL", "PHYS", "CHEM", "ENG", "ISL"],
        options: {
          showLines: true,
        },
      },
      lineGraph: true,
      pieGraph: false,
      barGraph: false,
      SkillReports: [],
      adminData: [],
      modalData: null,
      vehicleNo: "",
      capacity: "",
      transportType: "",
      enabled: false,
      id: null,
      select: [],
      efforts: "",
      participation: "",
      average: "",
      academicProgress: "",
      studentName: "",
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      increament: 1,
      selectClassData: "",
      classId: "",
      sectionId: "",
      sections: [],
      subjects: [],
      session: [],
      sessionId: "",
      subjectId: "",
      staffId: "",
      term: "",
      average: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      multiple: [],
      skill: [],
      subview: [],
      subs: [],
      Merge: [],
      Teacher: [],
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  // static getDerivedStateFromProps(nextProps, prevState) {
  //     debugger
  //     if (prevState.adminData !== nextProps.AllTransportData || prevState.selectClassData !== nextProps.selectData
  //         || prevState.session !== nextProps.SessionSelect) {

  //         return {
  //             adminData: nextProps.AllTransportData,
  //             selectClassData: nextProps.selectData,
  //             session: nextProps.SessionSelect
  //         };
  //     }
  //     return null;
  // }
  onSelectChange = (e) => {
    console.log(e.target.value);

    const sections = this.state.selectClassData.staffClassSection;
    let check = sections.filter((item) => item.classId == e.target.value);
    console.log(check);

    this.setState({
      sections: check,
      staffId: check[0].staffId,
      classId: e.target.value,
    });
  };
  onSectionChange = (e) => {
    console.log(e.target.value);
    const sections = this.state.selectClassData.staffClassSection;
    let check2 = sections.filter(
      (item) =>
        item.classId == this.state.classId && item.sectionId == e.target.value
    );
    this.setState({
      subjects: check2,
      sectionId: e.target.value,
    });
    console.log(this.state.staffId);
  };
  onSubjectChange = (e) => {
    console.log(e.target.value);
    this.setState({
      subjectId: e.target.value,
    });
  };
  componentDidUpdate() {
    $(".tooltip").tooltip("hide");

    if (this.state.msg || this.state.errorMsg || this.state.exist) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          exist: false,
        });
      }, 3000);
    }
  }
  PrintContent = (el) => {
    setTimeout(() => {
      window.print();
      window.location.reload();
    }, 700);
  };
  // PrintContent = el => {
  // 	var restorepage = document.body.innerHTML;
  // 	var Printcontent = document.getElementById(el).innerHTML;
  // 	document.body.innerHTML = Printcontent;
  // 	window.print();
  // 	document.body.innerHTML = restorepage;
  // };
  componentDidMount() {
    const { match } = this.props;
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/consolidatedSkillReport/?studentId=${match.params.id}&classId=${match.params.class}&sectionId=${match.params.section}&term=${match.params.term}&sessionId=${match.params.session}`
      )
      .then((success) => {
        debugger;
        this.setState({
          adminData: success,
          average: success.skill,
          skill: success.skill.subjectSkills,
          Teacher: success.classTeacher,
          subjectAverage: success.subjectAverage,
          searchCheck: false,
        });
        console.log(success);
        let graphData = { ...this.state.GraphData };
        let getSubjectName = success.subjectAverage.map((item) =>
          item.subjectName.slice(0, 3).toUpperCase()
        );

        let getMarks = success.subjectAverage.map((item) => item.average);

        graphData["datasets"][0]["data"] = getMarks;
        graphData["labels"] = getSubjectName;
        this.setState({
          GraphData: graphData,
        });
        debugger;
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.sharedActions
      .gAllData("/api/Session", this.props.SkillAction.getSessionsForSkills)
      .then((success) => {})
      .catch((error) => {});
    // this.props.sharedActions.getAllData(`/api/Assignments/getassign/Student/?Classid=0&secid=0`, this.props.AssignmentActions.getParticularAssignment, this.props.AssignmentActions.updateAssignmentFlag)
    //     .then((success) => {
    //
    //         this.setState({
    //             isLoading: false,
    //         })
    //     }).catch((err) => {
    //         this.props.AssignmentActions.updateAssignmentFlag(false);

    //         this.setState({
    //             isLoading: false
    //         });
    //
    //         console.log(err);
    //     })
  }
  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    this.props.sharedActions
      .getAllData(
        `/api/studentSkillView/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&term=${this.state.term}&sessionId=${this.state.sessionId}&subjectId=${this.state.subjectId}`,
        this.props.SkillAction.setAllSkillsViewInRedux,
        this.props.SkillAction.updateSkillFlagView
      )
      .then((success) => {
        this.setState({
          isLoading: false,
          btnLoad: false,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.props.SkillAction.updateSkillFlagView(false);

        this.setState({
          isLoading: false,
          btnLoad: false,
          searchCheck: true,
        });

        console.log(err);
      });
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/Vehicle/",
        itemId,
        this.props.routesActions.deleteVehicle
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.skillReportId === itemId);

    this.setState({
      modalData: getData,
      efforts: getData[0].efforts,
      average: getData[0].average,
      academicProgress: getData[0].academicProgress,
      id: getData[0].skillReportId,
      studentName: getData[0].stduentName,
      participation: getData[0].participation,
    });
  };

  onHandleTextNumber = (e) => {
    console.log(e.target.value);
    if (e.target.value <= 5) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    console.log(this.state.effort);
  };
  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { efforts, participation, academicProgress, id } = this.state;
    let data = { id, efforts, participation, academicProgress };

    this.props.sharedActions
      .editPatchRecord(
        "/api/editSkillReport/?id=",
        id,
        data,
        this.props.SkillAction.updateSkill
      )
      .then((success) => {
        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        // this.props.campusActions.updateCampusFlag(true);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(this.state);
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    if (e.target.value <= 5) {
      const changeArrOfObj = this.state[`${arrName}`];
      changeArrOfObj[index][e.target.name] = e.target.value;
      this.setState({
        [arrName]: changeArrOfObj,
      });
    }
  };
  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onGraphChange = (e) => {
    debugger;
    if (e.target.value == "Line") {
      this.setState({
        lineGraph: true,
        pieGraph: false,
        barGraph: false,
      });
    } else if (e.target.value == "Pie") {
      this.setState({
        lineGraph: false,
        pieGraph: true,
        barGraph: false,
      });
    } else if (e.target.value == "Bar") {
      this.setState({
        lineGraph: false,
        pieGraph: false,
        barGraph: true,
      });
    }
  };
  render() {
    console.log("paprams", this.props.match.params.section);
    const { studentDescription } = this.state.adminData;
    console.log("Meta Data", studentDescription);
    const { skill, subview, lineGraph, pieGraph, barGraph } = this.state;
    console.log("Skills", this.state.skill);
    console.log("Subjects", this.state.Merge);
    console.log("Average Marks of Student", this.state.subview);

    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );

    const MainContent = (
      <div>
        <div id="div1">
          <div class="container">
            <div class="divclass">
              <img
                src={require("../../Assets/images/header.png")}
                style={{ height: 250 }}
              />
            </div>
            <br />
            <div class="row text-center">
              <div class="col-md-12">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Academic Year: &nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {studentDescription && studentDescription.session}
                  </label>
                </div>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-md-4">
                <div class="md-form mb-0">
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    Performance Report
                  </label>
                </div>
              </div>
              <div class="col-md-4 text-right">
                <div class="md-form mb-0">
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    تقرير أداء الطالب
                  </label>
                </div>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-md-4">
                <div class="md-form mb-0">
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {studentDescription && studentDescription.term}&nbsp;Term
                  </label>
                </div>
              </div>
              <div class="col-md-4 text-right">
                <div class="md-form mb-0">
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    الفصل الاول
                  </label>
                </div>
              </div>
            </div>
            <div class="row colCheckb justify-content-between">
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    Name: {studentDescription && studentDescription.stduentName}
                  </label>
                </div>
              </div>
              <div class="col-md-6 text-right">
                <div class="md-form mb-0">
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {studentDescription && studentDescription.fullNameArabic}:
                    اسم الطالب
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    Grade: {studentDescription && studentDescription.className}{" "}
                    {studentDescription && studentDescription.section}
                  </label>
                </div>
              </div>
              <div class="col-md-6 text-right">
                <div class="md-form mb-0">
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    : صف
                  </label>
                </div>
              </div>
            </div>
            <br />
            <ThemeContext.Consumer>
              {(context) => (
                <div
                  class="row"
                  style={{
                    borderTop: "solid",
                    color: context.themeColors.primaryColor,
                  }}
                >
                  <table class="table text-center">
                    <thead
                      class="bordersSpace"
                      style={{
                        background: context.themeColors.primaryColor,
                        color: "white",
                      }}
                    >
                      <tr class="vendorListHeading">
                        <td
                          class="bordersSpace	"
                          style={{ verticalAlign: "middle" }}
                        >
                          Subject / المواضيع
                        </td>
                        <td class="bordersSpace">
                          Academic Progress / التقدم العلمي
                        </td>
                        <td class="bordersSpace">Efforts / جهود</td>
                        <td class="bordersSpace">Participation / مشاركة</td>
                        <td class="bordersSpace">Average / متوسط</td>
                        <td class="bordersSpace">Teacher/ متوسط</td>
                      </tr>
                    </thead>
                    <tbody>
                      {skill &&
                        skill.map((item, index) => {
                          return (
                            <tr>
                              <td
                                class="bordersSpace"
                                style={{ fontWeight: "bold" }}
                              >
                                {item.subjectName}
                              </td>
                              {item.stdSubSkills.map((data, index) => (
                                <React.Fragment>
                                  <td
                                    className="bordersSpace"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {data.academicProgress}
                                  </td>
                                  <td
                                    className="bordersSpace"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {data.efforts}
                                  </td>
                                  <td
                                    className="bordersSpace"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {data.participation}
                                  </td>
                                  <td
                                    className="bordersSpace"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {data.average}
                                  </td>
                                  <td
                                    className="bordersSpace"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {data.staffName}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                          );
                        })}
                      <td
                        colSpan="4"
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          color: "#01425e",
                          border: "solid",
                        }}
                      >
                        Overall Performance / متوسط ​​الأداء العام
                      </td>
                      <td
                        colSpan="2"
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          color: "#01425e",
                          border: "solid",
                        }}
                      >
                        {this.state.average.average}
                      </td>
                    </tbody>
                  </table>
                </div>
              )}
            </ThemeContext.Consumer>
            <div class="row justify-content-between">
              <div
                class="col-md-6"
                style={{ border: "solid", borderColor: "#01425e" }}
              >
                <div class="md-form mb-0 boldText">
                  <label class="">5 - Excellent &nbsp;</label>
                  <small>
                    (complete all tasks on time to the best of his/her ability.)
                  </small>
                  <br />
                  <small style={{ float: "right" }}>
                    ٥- ممتاز أكمل جميع المهام في الوقت المحدد بأفضل ما لديه
                  </small>
                  <br />
                  <label>4 - Very Good &nbsp;</label>
                  <small>
                    (usually completes all the work to the best of his/her
                    ability.)
                  </small>
                  <br />
                  <small style={{ float: "right" }}>
                    ٤- جيد جدا عادة ما يكمل كل العمل بأفضل ما لديه
                  </small>
                  <br />
                  <label>3 - Good &nbsp;</label>
                  <small>
                    (works well but would benefits from an increase in efforts.)
                  </small>
                  <br />
                  <small style={{ float: "right" }}>
                    ٣- جيد يعمل بشكل جيد ولكن سيستفيد من زيادة الجهود
                  </small>
                  <br />
                  <label>2 - Poor &nbsp;</label>
                  <small>(efforts offen below the level expected)</small>
                  <br />
                  <small style={{ float: "right" }}>
                    ٢- الجهود السيئة تنطلق دون المستوى المتوقع
                  </small>
                  <br />
                  <label>1 - Unsatisfactory &nbsp;</label>
                  <small>
                    (efforts is considerably below the level expected)
                  </small>
                  <br />
                  <small style={{ float: "right" }}>
                    ١- الجهود غير المرضية أقل بكثير من المستوى المتوقع
                  </small>
                  <br />
                </div>
              </div>
              <div
                class="col-md-3 text-center tableCenter"
                style={{ border: "solid", color: "#01425e" }}
              >
                <div class="md-form mb-0">
                  <table class="table-bordered">
                    <tr class="bordersSpace">
                      <th class="bordersSpace">عدد ايام الغياب</th>
                      <th class="bordersSpace">Days Absence</th>
                    </tr>
                    <tr class="bordersSpace">
                      <th>بعذر / Excused</th>
                      <th>بدون عذر / Non-Excused</th>
                    </tr>
                    <tr class="bordersSpace">
                      <td>10</td>
                      <td>20</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <br />

            <div class="row boldText">
              <div class="col-md-4">
                <div class="md-form mb-0"></div>
                <label></label>
              </div>
              <div class="col-md-4 text-center">
                <div class="md-form mb-0"></div>
                <label>School Stamp \ ختم المدرسة</label>
              </div>
              <div class="col-md-4 text-right">
                <div class="md-form mb-0"></div>
                <label>Class Teacher \ معلم الصف </label>
                <br />
                <label>
                  {this.state.Teacher.classTeacher}
                  &nbsp;&nbsp;: Name: \ الاسم
                </label>
                <br />
                <label>Signature \ التوقيع</label>
              </div>
            </div>
            <div class="hojaa">
              <br />
              <GraphGeneralLayout
                gradingScale={"1 - 4"}
                titleName={"Analysis"}
                titleIcon={"fas fa-chart-bar"}
                graphList={true}
                onGraphChange={this.onGraphChange}
                children={() => (
                  <div class="row">
                    <div class="col-md-11">
                      {lineGraph ? (
                        <Line
                          height={90}
                          width={200}
                          data={this.state.GraphData}
                        />
                      ) : pieGraph ? (
                        <Pie
                          height={90}
                          width={200}
                          data={this.state.GraphData}
                        />
                      ) : barGraph ? (
                        <Bar height={100} data={this.state.GraphData} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div class="text-center text-md-right displayElemet">
          <label for="name" class="" onClick={() => this.PrintContent("div1")}>
            <i
              className="fas fa-print fa-2x animated fadeIn"
              style={{ marginRight: 4 }}
            />
            Print It Out
          </label>
        </div>
      </div>
    );

    return <div>{this.state.searchCheck ? Loader : MainContent}</div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SkillAction: bindActionCreators(SkillAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllTransportData: state.setStudentSkillReducer.DataP,
    selectData: state.setStudentSkillReducer.selectStudent,
    SessionSelect: state.setStudentSkillReducer.selectSession,
    allTransportState: state.setStudentSkillReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(null, mapDispatchToProps)(PrintSkillReport);

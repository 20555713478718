import React, { Component } from "react";
import "./Form.css";

class StudentParentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { parentData } = this.props;
    console.log(parentData, "Parent Info Haii");
    return (
      <div>
        <section class="mb-4">
          <div class="row print">
            <div class="col-md-12">
              <div class="md-form mb-0">
                <h2 className="FormHeadingStyle">Parent Information:</h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row rowCheck" style={{ marginTop: 10 }}>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Father Name:</label>{" "}
                &nbsp;&nbsp; {parentData && parentData.fatherName}{" "}
              </div>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Father Occupation:</label>{" "}
                &nbsp;&nbsp; {parentData && parentData.occupation}{" "}
              </div>
            </div>
            <div class="row rowCheck">
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Father Mobile No: </label>
                &nbsp;&nbsp; {parentData && parentData.parentMobileNo}
              </div>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Father Email Id:</label>
                &nbsp;&nbsp; {parentData && parentData.parentEmailId}{" "}
              </div>
            </div>

            <div class="row rowCheck">
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Father Id Card No:</label>{" "}
                &nbsp;&nbsp; {parentData && parentData.parentIdCardNo}
              </div>
            </div>
            <div class="row rowCheck"></div>
            <div class="row rowCheck" style={{ marginTop: 10 }}>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Mother Name:</label>{" "}
                &nbsp;&nbsp; {parentData && parentData.motherName}{" "}
              </div>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Mother Occupation:</label>{" "}
                &nbsp;&nbsp; {parentData && parentData.motherOccupation}{" "}
              </div>
            </div>
            <div class="row rowCheck">
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Mother Mobile No: </label>
                &nbsp;&nbsp; {parentData && parentData.motherMobileNo}
              </div>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Mother Email Id:</label>
                &nbsp;&nbsp; {parentData && parentData.motherEmaild}{" "}
              </div>
            </div>

            <div class="row rowCheck">
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Mother Id Card No:</label>{" "}
                &nbsp;&nbsp; {parentData && parentData.motherIdCardNo}
              </div>
            </div>
            <div class="row rowCheck"></div>
            <div class="row rowCheck">
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Guardian Name:</label>
                &nbsp;&nbsp; {parentData && parentData.garduainName}
              </div>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>
                  Guardian Relationship:
                </label>
                &nbsp;&nbsp; {parentData && parentData.garduainRelationship}
              </div>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>
                  Family Gross Income:
                </label>
                &nbsp;&nbsp; {parentData && parentData.income}
              </div>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Emergency Contact:</label>
                &nbsp;&nbsp; {parentData && parentData.emergencyContact}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default StudentParentInfo;

import React, { Component } from 'react';
import Colors from '../../utils/app_constants/colors_constants';
import moment from 'moment';

const PrintView = props => {
	const {
		data,
		head,
		renderFields,
		accountId,
		accountType,
		printFn,
		title
	} = props;

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${Colors.SEA_GREEN_THEME}`,
						borderColor: Colors.SEA_GREEN_THEME
					}}
					scope="row"
				>
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${Colors.SEA_GREEN_THEME}`,
							padding: 2,
							borderColor: Colors.SEA_GREEN_THEME
						}}
					>
						{item[item1]}
					</td>
				))}
			</tr>
		));

	const print = (
		<section class="mb-4">
			<div class="divclass">
				<img
					src={require('../../Assets/images/header.png')}
					alt="Logo Banner"
				/>
			</div>
			<div
				class="row text-center"
				style={{
					flexGrow: 1,
					borderBottom: 5,
					borderRight: 0,
					borderLeft: 0,
					borderStyle: 'solid',
					borderColor: '#01AC8A',
					margin: '5px'
				}}
			>
				<div class="col-md-12 print">
					<h2
						style={{
							backgroundColor: '#01ac8a',
							textAlign: 'center',
							color: 'white'
						}}
						className="h1-responsive font-weight-bold text-center my-4 generalHead"
					>
						{' '}
						{title}{' '}
					</h2>
				</div>
			</div>
			<br />
			<div className="table-responsive">
				<table class="table table-hover table-bordered">
					<thead style={{ background: '#01AC8A', color: 'white' }}>
						<tr class="vendorListHeading">
							<th scope="col">#</th>
							{head.map(item => (
								<th
									style={{
										border: `1px solid ${Colors.SEA_GREEN_THEME}`,
										borderColor: Colors.WHITE
									}}
									scope="col"
								>
									{item}
								</th>
							))}
						</tr>
					</thead>
					<tbody>{bodyRendering(data)}</tbody>
				</table>
			</div>
		</section>
	);

	return (
		<div>
			<div id="div1">
				<div class="container">
					{print}
					<div class="page-footer">
						<div class="text-left">Printed By: {props.accountId}</div>
						<div class="text-right" style={{ marginTop: -25, marginRight: 50 }}>
							Powered by School Smart®
						</div>
					</div>
				</div>
			</div>

			<div
				style={{ marginTop: 20 }}
				class="text-center text-md-right displayElemet"
			>
				<label for="name" class="" onClick={() => printFn('div1')}>
					<i
						className="fas fa-print fa-2x animated fadeIn"
						style={{ marginRight: 4 }}
					/>
					Print It Out
				</label>
			</div>
		</div>
	);
};
export default PrintView;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import * as sharedActions from "../Actions/sharedActions";
import { connect } from "react-redux";
import SnackBar from "../components/SnackBar/SnackBar";
import { bindActionCreators } from "redux";

class EnglishDeanDashboard extends Component {
  state = {
    id: "",
    deanPost: "",
    deanPostId: "",
    coordinatorPost: "",
    DeanData: [],
    redirect: "",
    redirect2: "",
    errorMsg: false,
    msg: "",
    backColor: "",
    staffId: "",
    languageCordinatorId: "",
    ICTCordinatorId: "",
    mathsCordinatorId: "",
    previousPost: "",
    viewAPIData: [],
    staffApiCheck: false,
    labelCheck: false,
    userName: "",
    password: "",
  };
  componentDidMount() {
    $(document).click(function (e) {
      if (!$(e.target).is(".panel-body")) {
        $(".collapse").collapse("hide");
      }
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/DeanEnglish/?deanPostId=${this.props.match.params.id}`
      )
      .then((success) => {
        debugger;
        this.setState({
          DeanData: success,
          languageCordinatorId: success[0].id,
          mathsCordinatorId: success[1].id,
          ICTCordinatorId: success[2].id,
        });
      })
      .catch((err) => {
        this.setState({
          msg: "No Record Found",
          isLoading: false,
        });
        this.props.snackbar();

        console.log(err);
      });
  }
  setRedirect = (deanName) => {
    if (deanName == "Coordinator Language") {
      this.setState({
        id: this.state.DeanData[0].id,
        deanPost: this.state.DeanData[0].postDean,
        coordinatorPost: this.state.DeanData[0].postCoordinator,
        deanPostId: this.state.DeanData[0].deanPostId,
      });
    } else if (deanName == "Coordinator Maths") {
      this.setState({
        id: this.state.DeanData[1].id,
        deanPost: this.state.DeanData[1].postDean,
        coordinatorPost: this.state.DeanData[1].postCoordinator,
        deanPostId: this.state.DeanData[1].deanPostId,
      });
    } else if (deanName == "Coordinator ICT") {
      this.setState({
        id: this.state.DeanData[2].id,
        deanPost: this.state.DeanData[2].postDean,
        coordinatorPost: this.state.DeanData[2].postCoordinator,
        deanPostId: this.state.DeanData[2].deanPostId,
      });
    }
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      deanPost,
      id,
      staffId,
      deanPostId,
      userName,
      password,
      labelCheck,
    } = this.state;
    if (
      Object.is(id, "") ||
      Object.is(staffId, "") ||
      Object.is(deanPost, "") ||
      Object.is(userName, "") ||
      Object.is(password, "") ||
      labelCheck == true
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      const data = {
        deanPostId: deanPostId,
        deanPostName: deanPost,
        coordinatorPostId: id,
        userName,
        password,
        staffId,
        accountType: this.props.role,
        accountId: this.props.name,
      };
      this.props.sharedActions
        .simpleAddRequest("/api/Coordinator ", data)
        .then((success) => {
          this.setState({
            errorMsg: false,
            ledger: "",
            amount: "",
            account: "",
            sessionId: "",
            payTo: "",
            msg: "Coordinator Added Successfully",
            backColor: "#01ac8a",
          });
          $("#myModal1").modal("hide");
          this.props.snackbar();
        })
        .catch((error) => {
          this.setState({
            msg: error.response.data.Message,
            backColor: "red",
          });
          this.props.snackbar();
        });
    }
    console.log(this.state);
  };

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  hitViewAPI = (HodID) => {
    this.setState({ viewAPIData: [] });
    this.props.sharedActions
      .getDataWithoutDispatch(`/api/Coordinator/?coordinatorPostId=${HodID}`)
      .then((success) => {
        let data = [];
        data.push({ ...success });
        debugger;
        this.setState({
          viewAPIData: data,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.setState({});

        console.log(err);
      });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecordWithoutDispatchWithoutSlash("/api/Coordinator/?id=", itemId)
      .then((success) => {
        debugger;
        let deleteData = this.state.viewAPIData.filter(
          (item) => item.id != itemId
        );
        this.setState({
          viewAPIData: deleteData,
          msg: "Record Deleted Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {
        debugger;
      });
  };
  hitStaffIdAPI = (e) => {
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/GetGradeCoordinatorStaffById/?staffId=${e.target.value}`
      )
      .then((success) => {
        debugger;
        this.setState({
          staffId: success.staffId,
          staffName: success.staffName,
          previousPost: success.postName,
          staffApiCheck: true,
        });
      })
      .catch((err) => {
        this.setState({
          staffApiCheck: false,
        });

        console.log(err);
      });
  };
  hitUserNameAPI = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    debugger;
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/CheckCoordinatorUsername/?userName=${e.target.value}`
      )
      .then((success) => {
        this.setState({
          labelCheck: false,
        });
      })
      .catch((err) => {
        debugger;
        this.setState({
          labelCheck: true,
        });

        console.log(err);
      });
    this.setState({
      userName: e.target.value,
    });
    console.log(e.target.value);
  };
  render() {
    return (
      <div>
        <div class="modal fade" id="myModalView">
          <div class="modal-dialog modal-lg">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  View Data
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {/* <div class="text-center">
									<label class="font-weight-bold text-danger">
										Already Event Added on this Date {this.state.msg}
									</label>
								</div> */}
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Post Name</th>
                      <th scope="col">Dean Name</th>
                      <th scope="col">User Name</th>
                      <th scope="col">Password</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">AccountId</th>
                      <th scope="col">AccountType</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.viewAPIData.map((item, index) => (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{item.deanPostName}</td>
                        <td>{item.name}</td>
                        <td>{item.userName}</td>
                        <td>{item.password}</td>
                        <td>{item.date}</td>
                        <td>{item.time}</td>
                        <td>{item.accountId}</td>
                        <td>{item.accountType}</td>
                        <td>
                          <a href="#" data-toggle="tooltip" title="Delete!">
                            <button
                              onClick={() => this.onTakeItemDelete(item.id)}
                              style={{
                                cursor: "pointer",
                                fontSize: 20,
                                border: "none",
                                background: "none",
                                marginLeft: 5,
                                marginBottom: 5,
                              }}
                            >
                              <i
                                style={{ color: "#D11A2A" }}
                                class="fas fa-trash-alt"
                              ></i>
                            </button>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog modal-lg">
            <div class="modal-content modal_content_custom">
              <div class="modal-header modal_custom_header">
                <h4 style={{ color: "white" }} class="modal-title">
                  Add Coordinator
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <section class="mb-4">
                  <div
                    class="row"
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      class="col-md-9 mb-md-0 mb-5"
                      style={{
                        padding: 0,
                        borderRadius: 15,
                        width: "auto",
                      }}
                    >
                      <form id="contact-form" name="contact-form">
                        <div class="row">
                          <div class="col-md-6" style={{ marginTop: 20 }}>
                            <div class="md-form mb-0">
                              <label for="name" class="font-weight-bold">
                                Admin Name
                              </label>
                              <input
                                value={this.props.name}
                                readOnly={true}
                                type="text"
                                name="AdminName"
                                class="form-control"
                                onChange={this.onHandleTextChange}
                              />
                            </div>
                          </div>

                          <div class="col-md-6" style={{ marginTop: 20 }}>
                            <div class="md-form mb-0">
                              <label for="name" class="font-weight-bold">
                                Admin Type
                              </label>
                              <input
                                value={this.props.role}
                                readOnly={true}
                                type="text"
                                name="AdminType"
                                class="form-control"
                                onChange={this.onHandleTextChange}
                              />
                            </div>
                          </div>
                          <div class="col-md-6" style={{ marginTop: 20 }}>
                            <div class="md-form mb-0">
                              <label for="email" class="font-weight-bold">
                                Staff Id
                              </label>
                              <input
                                placeholder="Enter Staff Id"
                                type="number"
                                name="staffId"
                                className="form-control check"
                                onChange={this.hitStaffIdAPI}
                              />
                            </div>
                          </div>
                          {this.state.staffApiCheck ? (
                            <React.Fragment>
                              <div class="col-md-6" style={{ marginTop: 20 }}>
                                <div class="md-form mb-0">
                                  <label for="name" class="font-weight-bold">
                                    Post Name
                                  </label>
                                  <input
                                    disabled={true}
                                    placeholder="Pay to"
                                    type="text"
                                    name="payTo"
                                    class="form-control"
                                    value={this.state.previousPost}
                                    onChange={this.onHandleTextChange}
                                  />
                                </div>
                              </div>
                              <div class="col-md-6" style={{ marginTop: 20 }}>
                                <div class="md-form mb-0">
                                  <label for="email" class="font-weight-bold">
                                    Staff Name
                                  </label>
                                  <input
                                    placeholder="Enter Staff Id"
                                    type="text"
                                    name="staffId"
                                    value={this.state.staffName}
                                    className="form-control check"
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                              <div class="col-md-6" style={{ marginTop: 20 }}>
                                <div class="md-form mb-0">
                                  {this.state.labelCheck ? (
                                    <React.Fragment>
                                      <label class="text-center text-danger font-weight-bold">
                                        <span class="fas fa-times"></span>{" "}
                                        Username Already Exist
                                      </label>
                                    </React.Fragment>
                                  ) : (
                                    <label for="email" class="font-weight-bold">
                                      User Name
                                    </label>
                                  )}
                                  <input
                                    placeholder="Add User Name"
                                    type="text"
                                    name="userName"
                                    value={this.state.userName}
                                    className="form-control"
                                    onChange={(e) => this.hitUserNameAPI(e)}
                                  />
                                </div>
                              </div>
                              <div class="col-md-6" style={{ marginTop: 20 }}>
                                <div class="md-form mb-0">
                                  <label for="email" class="font-weight-bold">
                                    Password
                                  </label>
                                  <input
                                    placeholder="Enter Password"
                                    type="text"
                                    name="password"
                                    value={this.state.password}
                                    className="form-control check"
                                    onChange={this.onHandleTextChange}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </div>
                        <br />
                        <br />
                        {this.state.errorMsg ? (
                          <p style={{ color: "red", textAlign: "center" }}>
                            Please fill the form properly
                          </p>
                        ) : (
                          ""
                        )}
                        <div class="text-right text-md-right">
                          <button
                            onClick={this.onHandleSubmit}
                            class="buttonHover2"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                      <div class="status" />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <section class="mb-4">
          <SnackBar msg={this.state.msg} backColor={this.state.backColor} />
          <h4 class="text-center" style={{ letterSpacing: 10 }}>
            English Dean Dashboard
          </h4>
          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
              marginBottom: -45,
            }}
          >
            <div class="col-md-8">
              <div class="md-form mb-0">
                <hr class="style-head" />
              </div>
            </div>
          </div>

          <p class="text-center w-responsive mx-auto mb-5" />

          <div class="row justify-content-center">
            <div class="col-md-3 animated slideInLeft">
              <div
                class="card shadowForBox"
                style={{ backgroundColor: "#00a559" }}
              >
                <div class="card-body text-center">
                  <Link
                    to={`/dashboard/EnglishHODDashboard/${this.state.languageCordinatorId}`}
                  >
                    <h5
                      class="card-title font-weight-bold"
                      style={{ color: "white" }}
                    >
                      Coordinator Language
                    </h5>
                  </Link>
                  <button
                    class="buttonHover2"
                    data-toggle="modal"
                    data-target="#myModal1"
                    onClick={() => this.setRedirect("Coordinator Language")}
                  >
                    Assign
                  </button>{" "}
                  --{" "}
                  <button
                    class="buttonHover2"
                    style={{ width: 70 }}
                    data-toggle="modal"
                    data-target="#myModalView"
                    onClick={() =>
                      this.hitViewAPI(this.state.languageCordinatorId)
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3  animated slideInRight">
              <div
                class="card shadowForBox"
                style={{ backgroundColor: "#dc4a38" }}
              >
                <div class="card-body text-center">
                  <Link
                    to={`/dashboard/EnglishHODDashboard/${this.state.mathsCordinatorId}`}
                  >
                    <h5
                      class="card-title font-weight-bold"
                      style={{ color: "white" }}
                    >
                      Coordinator Maths & Sciences
                    </h5>
                  </Link>
                  <button
                    data-toggle="modal"
                    data-target="#myModal1"
                    class="buttonHover2"
                    onClick={() => this.setRedirect("Coordinator Maths")}
                  >
                    Assign
                  </button>{" "}
                  --{" "}
                  <button
                    class="buttonHover2"
                    style={{ width: 70 }}
                    data-toggle="modal"
                    data-target="#myModalView"
                    onClick={() =>
                      this.hitViewAPI(this.state.mathsCordinatorId)
                    }
                  >
                    View
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row justify-content-center">
            <div class="col-md-3 animated slideInUp">
              <div
                class="card shadowForBox"
                style={{ backgroundColor: "#003d59" }}
              >
                <div class="card-body text-center">
                  <Link
                    to={`/dashboard/EnglishHODDashboard/${this.state.ICTCordinatorId}`}
                  >
                    <h5
                      class="card-title font-weight-bold"
                      style={{ color: "white" }}
                    >
                      Coordinator Business & ICT
                    </h5>
                  </Link>
                  <button
                    data-toggle="modal"
                    data-target="#myModal1"
                    class="buttonHover2"
                    onClick={() => this.setRedirect("Coordinator ICT")}
                  >
                    Assign
                  </button>{" "}
                  <label style={{ color: "white" }}> --</label>{" "}
                  <button
                    class="buttonHover2"
                    style={{ width: 70 }}
                    data-toggle="modal"
                    data-target="#myModalView"
                    onClick={() => this.hitViewAPI(this.state.ICTCordinatorId)}
                  >
                    View
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    auth: state.setUserReducer.auth,
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
    accountId: state.setUserReducer.users.accountId,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnglishDeanDashboard);

import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config/config";
import {
  addDataMiddleware,
  customDataAddingMiddleWare,
  customSubToolDataAddingMiddleWare,
} from "../../utils/general";

const HttpWithCaSubToolViewDataMiddlware = (
  url,
  dependencies,
  addData,
  commonMiddlware = true,
  commonMiddlwareProperty = ""
) => {
  debugger;

  const [isLoading, setIsLoading] = useState(true);
  const [fetchData, setFetchData] = useState(null);
  const [completeData, setCompleteData] = useState({});

  useEffect(() => {
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    axios
      .get(`${config.localhttp}${url}`, {
        headers: { Authorization: `Bearer  ${token}` },
      })
      .then((res) => {
        console.log("hook data get: ", res);
        console.log(res.data);
        debugger;
        let requireData = [];
        setCompleteData(res.data);
        if (commonMiddlware) {
          requireData = addDataMiddleware(addData, res.data);
        } else {
          debugger;
          requireData = customSubToolDataAddingMiddleWare(
            addData,
            res.data[commonMiddlwareProperty]
          );
        }
        setIsLoading(false);
        debugger;
        setFetchData(requireData);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, dependencies);

  return commonMiddlware
    ? [isLoading, fetchData, setFetchData, setIsLoading]
    : [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading,
        completeData,
        setCompleteData,
      ];
};
export default HttpWithCaSubToolViewDataMiddlware;

import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../Actions/sharedActions";
import Button from "../../components/Button";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import InfoComponent from "../../components/InfoComponent/InfoComponent";
import ListView from "../../components/List/ListView/ListView.jsx";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import SnackBar from "../../components/SnackBar/SnackBar";
import ThemeContext from "../../context/themeContext/ThemeContext";
import useHttp from "../../hooks/http.js";
import {
  dataFilteration,
  isEmptyFieldInArrOBJ,
} from "../../utils/general/index";
import validateinput, {
  CHECK_MARKS_RANGE,
} from "../../validator/inputValidator";
import HODSubjectDisciplineView from "./HODSubjectDisciplineView";

function HODSubjectDisciplineEdit(props) {
  const [error, setError] = useState({});
  const [editStudentData, setEditStudentData] = useState([]);
  const [snackbarMessage, setMsg] = useState("");
  const [studentData, setStudentData] = useState("");
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [term, setTerm] = useState("");
  const [sessions, setSessions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [sections, setSections] = useState([]);

  const [customLoader, setCustomLoader] = useState(false);
  const [isLoader, fetchData] = useHttp(
    `/api/DescriptiveReport?userName=${props.match.params.name}`,
    []
  );

  const [isLoading, fetchSessionData] = useHttp(`/api/Session`, []);
  console.log("fetch Session Data: ", fetchSessionData);
  const onSetTerm = (e) => {
    if (e.target.value == "Choose Term") {
      setTerm("");
    } else {
      setTerm(e.target.value);
    }
  };

  const isValid = (data, validateType) => {
    const { errors, isValid } = validateinput(data, validateType);
    if (!isValid) {
      setError(errors);
    }
    return isValid;
  };
  const onChangeMarks = (e, rowIndex) => {
    if (isValid({ marks: e.target.value }, CHECK_MARKS_RANGE)) {
      let data = [...editStudentData];

      data[rowIndex][e.target.name] = e.target.value;

      setEditStudentData(data);
    }
  };
  const onSessionChange = (e) => {
    if (e.target.value !== "Choose Session") setSessionId(e.target.value);
  };
  const onSubjectChange = (e) => {
    if (e.target.value !== "Choose Subject") setSubjectId(e.target.value);
  };
  const onChangeClassName = (e) => {
    const { staffClassSection } = fetchData;
    if (e.target.value !== "Choose Class") {
      let getSections = dataFilteration(
        staffClassSection,
        ["section", "sectionId"],
        {
          classId: parseInt(e.target.value),
        }
      );

      setClassId(parseInt(e.target.value));
      setSections(getSections);
      setSectionId("");
      setSubjectId("");
    }
  };
  const onSectionChange = (e) => {
    if (e.target.value !== "Choose Section") {
      const { staffClassSection } = fetchData;
      let getSubjects = dataFilteration(
        staffClassSection,

        ["subjectName", "subjectId"],
        {
          classId: classId,
          sectionId: parseInt(e.target.value),
        }
      );
      setSectionId(parseInt(e.target.value));
      setSubjects(getSubjects);
    }
  };

  const onEditSearch = () => {
    setCustomLoader(true);
    props.sharedAction
      .getDataWithoutDispatch(
        `/api/DisciplinaryRecord/?classId=${classId}&sectionId=${sectionId}&term=${term}&subjectId=${subjectId}&sessionId=${sessionId}`
      )
      .then((success) => {
        setEditStudentData(success);
        setCustomLoader(false);
      })
      .catch((error) => {
        if (error && error.response && error.response.status == 404) {
          // 404-Error no understanding of 404 Error
          setEditStudentData([]);
          setMsg("404 Not Found");
          props.snackbar();
          setCustomLoader(false);
        }
        if (error && error.response && error.response.status == 400) {
          setEditStudentData([]);

          setMsg(error.response.data.Message);
          props.snackbar();

          setCustomLoader(false);
        }
      });
  };

  const onEditSubmit = () => {
    props.sharedAction
      .editRecordWithoutDispatchAndId(`/api/DisciplinaryRecord`, {
        DisciplinaryRecord: [...editStudentData],
      })
      .then((success) => {
        setTerm("");
        setSectionId("");
        setClassId("");
        setSubjectId("");
        setSessionId("");
        setEditStudentData([]);
        setMsg("Successfully Updated");
        props.snackbar();
      })
      .catch((error) => {
        if (error && error.response && error.response.status == 400) {
          setEditStudentData([]);

          setMsg(error.response.data.Message);
          props.snackbar();

          setCustomLoader(false);
        }
      });
  };

  return (
    <div>
      <ThemeContext.Consumer>
        {(context) => (
          <h2
            style={{ background: context.themeColors.primaryColor }}
            className="text-center generalHead"
          >
            Edit Student Discipline Record
          </h2>
        )}
      </ThemeContext.Consumer>

      <SnackBar backColor={"#000"} msg={snackbarMessage} />

      <LoaderWrapper isLoading={isLoader && isLoading ? true : false}>
        <SelectionInput
          isValidte={false}
          stateData={{
            term: term,
          }}
          errors={{}}
          feildName={"term"}
          optionsArrys={["1st Term", "2nd Term", "3rd Term"]}
          type="static"
          useFlag={false}
          selectedText={"Choose Term"}
          onHandleChange={onSetTerm}
          newFlag="customWidth"
        />
        <div
          className="dispFlexRow"
          style={{ justifyContent: "center", marginBottom: 10 }}
        >
          <div>
            <SelectionInput
              newFlag="customWidth"
              feildName="classId"
              selectedText={"Choose Class Name"}
              optionsArrys={fetchData && fetchData.classes}
              errors={{}}
              isValidte={false}
              stateData={{
                classId: classId,
              }}
              optionType="dynamic"
              onHandleChange={onChangeClassName}
              property={"className"}
              propertyId={"classId"}
              useFlag={false}
            />
          </div>
          <div>
            <SelectionInput
              newFlag="customWidth"
              feildName={"sectionId"}
              selectedText={"Choose Section"}
              isValidte={false}
              optionsArrys={sections.length < 1 ? [] : sections}
              errors={{}}
              stateData={{
                sectionId: sectionId,
              }}
              optionType="dynamic"
              onHandleChange={onSectionChange}
              property={"section"}
              propertyId={"sectionId"}
              useFlag={false}
            />
          </div>
          <div>
            <SelectionInput
              newFlag="customWidth"
              feildName={"subjectId"}
              selectedText={"Choose Subject"}
              isValidte={false}
              optionsArrys={subjects.length < 1 ? [] : subjects}
              errors={{}}
              stateData={{
                subjectId: subjectId,
              }}
              optionType="dynamic"
              onHandleChange={onSubjectChange}
              property={"subjectName"}
              propertyId={"subjectId"}
              useFlag={false}
            />
          </div>

          <div>
            <SelectionInput
              newFlag="customWidth"
              feildName={"sessionId"}
              selectedText={"Choose Session"}
              isValidte={false}
              optionsArrys={fetchSessionData && fetchSessionData.session}
              errors={{}}
              stateData={{
                sessionId: sessionId,
              }}
              optionType="dynamic"
              onHandleChange={onSessionChange}
              property={"currentSession"}
              propertyId={"sessionId"}
              useFlag={false}
            />
          </div>
          <div style={{ marginTop: 35 }}>
            <Button
              buttonClass="searchBtnClass"
              icon={true}
              btnName={""}
              customClause={
                (sessionId !== "" &&
                sectionId !== "" &&
                classId !== "" &&
                subjectId !== "" &&
                term !== ""
                  ? false
                  : true) || customLoader
              }
              iconSize={19}
              onHandleSubmit={onEditSearch}
            />
          </div>
        </div>

        {error["rangeError"] ? (
          <InfoComponent
            classType="text-danger"
            fontSize={15}
            message={`Note: marks can only be assigned into the range of 0 - 4`}
          />
        ) : (
          ""
        )}

        <LoaderWrapper isLoading={customLoader}>
          <ListView
            excludedData={[
              "sectionId",
              "classId",
              "sessionId",
              "subjectId",
              "studentId",
              "term",
              "accountId",
              "accountType",
              "section",
              "className",
            ]}
            tableMainData={editStudentData}
            tableHeadData={["S #", "Student Name", "Marks"]}
            onChangeMarks={onChangeMarks}
          />

          {editStudentData.length > 0 ? (
            <Button
              buttonClass="customButton"
              btnName={"Update"}
              customClause={isEmptyFieldInArrOBJ(editStudentData, "marks")}
              iconSize={19}
              onHandleSubmit={onEditSubmit}
            />
          ) : null}
        </LoaderWrapper>
      </LoaderWrapper>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HODSubjectDisciplineEdit);

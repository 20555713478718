import React, { useEffect, useState,useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import Pagination from '../../../components/Pagination/Pagination';
import Spinner from '../../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../../hooks/Services/httpWithRequiredSetData';
import Colors from '../../../utils/app_constants/colors_constants';
import TextArea from '../../../components/TextArea';
import ThemeContext from "../../../context/themeContext/ThemeContext";

let tHead = [
	'Name',
	'Title',
	'Date',
	'Time',
	'Account ID',
	'Account Type',
	'Actions'
];

let renderFields = [
	'name',
	'title',
	'date',
	'time',
	'accountId',
	'accountType'
];

const DeanInbox = props => {
	const themeContext = useContext(ThemeContext);
	const [accountId, setAccountId] = useState(props.name);
	const [accountType, setAccountType] = useState(props.role);

	const [update, setUpdate] = useState('');
	const [message, setMessage] = useState({});
	const [isMessageLoading, setIsMessageLoading] = useState(false);

	const [inboxData, setInboxData] = useState([]);

	const [searchURL, setSearchURL] = useState(
		`/api/DeanInbox/?username=${accountId}&pageNumebr=1&pageSize=10`
	);

	const [disableBPage, setdisableBPage] = useState(true);
	const [customPageSize, setCustomPageSize] = useState('10');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumber, setPageNumber] = useState([]);
	const [disablepage, setdisablepage] = useState(false);
	const [pagination, setPaginationData] = useState({});

	const [
		isLoading,
		fetchData,
		setFetchData,
		setIsLoading
	] = httpWithRequiredSetData(searchURL, [], data => {
		let pageNumber = [];
		for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
			pageNumber.push(i);
		}
		setPageNumber(pageNumber);
		setInboxData(data['message']);

		setPaginationData(data['paginationMetadata']);
	});

	const handleClickNextFrwd = () => {
		console.log('pagelength: ', pageNumber.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumber.length) {
			setdisablepage(true);
		} else {
			setCurrentPage(prevState => prevState + 1);
			console.log(currentPage);

			setdisableBPage(false);
		}
	};

	useEffect(() => {
		console.log('Do something after counter has changed', currentPage);
		setIsLoading(true);
		props.sharedActions
			.getDataWithoutDispatch(
				`/api/DeanInbox/?username=${accountId}&pageNumebr=${currentPage}&pageSize=${customPageSize}`
			)
			.then(success => {
				setInboxData(success['message']);
				setIsLoading(false);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);
			})
			.catch(error => {
				setIsLoading(false);
				setInboxData([]);
			});
	}, [currentPage, update, customPageSize]);

	const handleClickNext = event => {
		setCurrentPage(Number(event.target.id));
		setdisableBPage(false);
		setdisableBPage(false);
	};
	const handleClickNextBack = () => {
		if (currentPage === 1) {
			setdisableBPage(true);
			setdisablepage(false);
		} else {
			setCurrentPage(prevState => prevState - 1);
		}
	};

	const onChangePageSize = e => {
		if (e.target.value !== 'Page Entries') {
			setCustomPageSize(e.target.value);
			setCurrentPage(1);
		}
	};

	const getMessage = id => {
		setIsMessageLoading(true);
		props.sharedActions
			.getDataWithoutDispatch(`/api/ViewDeanMsg/?id=${id}`)
			.then(success => {
				setMessage(success);
				setIsMessageLoading(false);

				setUpdate(id);
			})
			.catch(error => {
				setIsMessageLoading(false);
				setMessage('');
			});
	};

	const PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			
			<tr>
				
				<th
					style={{
						padding: 2,
						border: `1px solid ${themeContext.themeColors.primaryColor}`,
						borderColor: themeContext.themeColors.primaryColor
					}}
					scope="row"
				>
					<span
						style={{
							height: '10px',
							width: '10px',
							backgroundColor: `${item.status == 0 ? 'red' : '#bbb'}`,
							borderRadius: '50%',
							display: 'inline-block'
						}}
					></span>{' '}
					{''}
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${themeContext.themeColors.primaryColor}`,
							padding: 2,
							borderColor: themeContext.themeColors.primaryColor,
							backgroundColor: `${item.status == 0 ? '#e6e6e6' : '#fff'}`
						}}
					>
						{item[item1]}
					</td>
				))}
				<td
					className="displayElemet"
					style={{
						border: `1px solid ${themeContext.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeContext.themeColors.primaryColor
					}}
				>
					<a href="#" data-toggle="tooltip" title="View!">
						<button
							id="firstbutton"
							onClick={() => getMessage(item.id)}
							data-toggle="modal"
							data-target="#myModal1"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
						>
							<i
								class="fas fa-envelope"
								style={{ color: themeContext.themeColors.primaryColor }}
							></i>
						</button>
					</a>
				</td>
			</tr>
		));

	const messageView = (
		<div class="row">
			<div class="col-md-12 form-inline">
				<div class="col-md-2">
					<strong>From</strong>
				</div>
				<div class="col-md-10 border-bottom">
					<strong
						style={{
							backgroundColor: '#bbb'
						}}
					>
						{message.name}
					</strong>
				</div>
			</div>

			<br />

			<div class="col-md-12 form-inline" style={{ marginTop: '20px' }}>
				<div class="col-md-2">
					<strong>Date & Time</strong>
				</div>
				<div class="col-md-10 border-bottom">
					<strong
						style={{
							backgroundColor: '#bbb'
						}}
					>
						{message.date}
					</strong>
					{'     '}
					<strong
						style={{
							backgroundColor: '#bbb',
							marginLeft: '10px'
						}}
					>
						{' '}
						{message.time}{' '}
					</strong>
				</div>
			</div>

			<div class="col-md-12 form-inline" style={{ marginTop: '20px' }}>
				<div class="col-md-12 text-center border-bottom">
					<h4 className="h1-responsive font-weight-bold my-4 generalHead">
						<strong
							style={{
								backgroundColor: Colors.SEA_GREEN_THEME,
								color: '#fff'
							}}
						>
							MESSAGE
						</strong>
						{'     '}
					</h4>
				</div>
			</div>

			<div class="col-md-12 form-inline" style={{ marginTop: '20px' }}>
				<div class="col-md-12 text-center border-bottom">
					<div class="col-md-12 ">
						<TextArea
							row={15}
							readOnly={true}
							customWidth={true}
							enterWidth={'220%'}
							feildName={'message'}
							errors={{}}
							stateData={message}
							// iconClassName={'fas fa-percentage'}
							placeholder={'Message'}
							isValidte={false}
						/>
					</div>
					<br />
					<br />
				</div>
				<br />
				<br />
			</div>
		</div>
	);

	return (
		<div id="div1">
			<div class="modal" id="myModal1">
				<div class="modal-dialog modal-lg modal-dialog-centered">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
								borderTopRightRadius: 18
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Message
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<div>
								<div>{isMessageLoading ? <Spinner /> : messageView}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div style={{ textAlign: 'right' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div >
						<h2 style={{ backgroundColor:themeContext.themeColors.primaryColor}} className="h1-responsive font-weight-bold text-left my-4 generalHead">
							{' '}
							Dean Inbox{' '}
						</h2>
					</div>
					<div class="displayElemet">
						<div
							style={{
								marginTop: 40,
								display: 'flex',
								flexDirection: 'column'
							}}
						>
							<Pagination
								disableBPage={disableBPage}
								disablepage={disablepage}
								currentPage={currentPage}
								pageNumber={pageNumber}
								handleClickNext={handleClickNext}
								handleClickNextBack={handleClickNextBack}
								handleClickNextFrwd={handleClickNextFrwd}
							/>
							<div
								style={{
									width: 'fit-content',
									marginTop: -34,
									display: 'flex',
									justifyContent: 'flex-end',
									marginLeft: 'auto'
								}}
							>
								<SelectionInput
									selectName="Entries"
									selectFont={14}
									selectFontWeight={'bold'}
									newFlag="customWidth"
									feildName="customPageSize"
									selectedText={'Page Entries'}
									optionsArrys={['10', '20', '30', '40', '50']}
									errors={{}}
									isValidte={false}
									stateData={{
										customPageSize: customPageSize
									}}
									optionType="static"
									onHandleChange={onChangePageSize}
									useFlag={false}
								/>
							</div>
						</div>
					</div>
				</div>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {pagination['totalPages']}
					</span>
				</div>
			</div>
			<br />
			{isLoading ? (
				<Spinner />
			) : inboxData.length < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				<div className="table-responsive">
					 <ThemeContext.Consumer>
            {(context) => (
					<table class="table table-hover">
						<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
							<tr class="vendorListHeading">
								<th scope="col">#</th>
								{tHead.map(item =>
									item === 'Actions' ? (
										<th
											className="displayElemet"
											style={{
												border: `1px solid ${context.themeColors.primaryColor}`,
												borderColor: Colors.WHITE
											}}
											scope="col"
										>
											{item}
										</th>
									) : (
										<th
											style={{
												border: `1px solid ${context.themeColors.primaryColor}`,
												borderColor: Colors.WHITE
											}}
											scope="col"
										>
											{item}
										</th>
									)
								)}
							</tr>
						</thead>
						<tbody>{bodyRendering(inboxData)}</tbody>
					</table>
					 )}
					 </ThemeContext.Consumer>
				</div>
			)}

			<div class="text-center text-md-right displayElemet">
				<label for="name" class="" onClick={() => PrintContent('div1')}>
					<i
						className="fas fa-print fa-2x animated fadeIn"
						style={{ marginRight: 4 }}
					/>
					Print
				</label>
			</div>
			<div class="text-center text-md-right">
				{/* <strong>{props.name}</strong> */}
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeanInbox);

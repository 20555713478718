import React, { Component } from 'react';

const TextBoxesForMarks = props => {
	const {
		itemObj,
		onHandleTextChange,
		stateData,
		rowIndex,
		updateSpecialArrObjOnChange,
		arrName
	} = props;

	let keys = Object.keys(itemObj);
	// console.log(stateData.siblingsDetails[0]['age'])
	if (keys.includes('entryExamId')) {
		let newKeys = keys.filter(item => item !== 'entryExamId');
		keys = [...newKeys];
	}

	return (
		<React.Fragment>
			{keys.map((item, index) =>
				item === 'subjectId' ? (
					<td key={index}>
						<select
							class="custom-select"
							name={`${item}`}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
						>
							<option selected={true}>Select Subject..</option>
							{stateData.subjects &&
								stateData.subjects.map((item, index) => (
									<option key={index} value={`${item.subjectId}`}>
										{item.subjectName}
									</option>
								))}
						</select>{' '}
					</td>
				) : (
					<td key={index}>
						<input
							required={true}
							type="number"
							placeholder={`${item}`}
							class="form-control"
							name={`${item}`}
							value={stateData[arrName][rowIndex][`${item}`]}
							onChange={e => updateSpecialArrObjOnChange(e, rowIndex, arrName)}
						/>
					</td>
				)
			)}
		</React.Fragment>
	);
};
export default TextBoxesForMarks;

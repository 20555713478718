import React, { Component } from "react";
import * as ParentActions from "../../Actions/Parents";
import * as SharedActions from "../../Actions/sharedActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import Snackbar from "../../components/SnackBar/SnackBar";
import MultipleCheque from "./MultipleCheque";
import moment from "moment";
import ThemeContext from "../../context/themeContext/ThemeContext";
import MultipleDiscounts from "./MultipleDiscounts";
import { object } from "prop-types";

class ReceiptBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ChequeDetails: [
        {
          chequeNo: "",
          date: "",
          bankName: "",
          amount: "",
        },
      ],
      DiscountDetails: [
        {
          discountType: "",
          feeName: "",
          fee: "",
          percentage: "",
          discount: "",
        },
      ],
      chequeDiv: false,
      radioCheck: false,
      dataCollection: [],
      payableType: "",
      january: "",
      february: "",
      march: "",
      april: "",
      may: "",
      june: "",
      july: "",
      august: "",
      september: "",
      october: "",
      november: "",
      december: "",
      feeMonthsArray: [],
      feeMonths: "",
      disableCheck: false,
      studentFeeTypes: [],
      DuesInfo: [],
      newDuesInfo: [],
      mergeArray: [],
      modalData: [],
      parentData: [],
      feeType: [],
      afterRender: [],
      balance: 0,
      showAmount: false,
      classFee: "",
      paymentType: "",
      status: "",
      classId: "",
      sectionId: "",
      studentId: "",
      studentName: "",
      feeTypeAmount: 0,
      amountPaid: "",
      cnic: "",
      password: "",
      msg: "",
      errorMsg: "",
      errorText: "",
      enableDropdown: false,
      deleteFlag: false,
      updateFlag: false,
      formLoading: true,
      addFlag: false,
      btnLoad: false,
      backColor: "",

      id: "",
      paymentTypeUpdate: "",
      balanceUpdate: 0,
      status: "",
      amountPaidUpdate: "",
      multiplyAmount: 0,
      tutionFee: 0,
      selectedOption: "Monthly",
      lengthCheck: false,
      isChecked: false,
      registration: "Registration",
      registrationFee: "",
      admission: "Admission",
      admissionFee: "",
      canteen: "Canteen",
      canteenFee: "",
      stationary: "Stationary",
      stationaryFee: "",
      entryTest: "Entry Test",
      entryTestFee: "",
      bus: "Bus",
      busFee: "",
      security: "Security",
      securityFee: "",
      uniform: "Uniform",
      uniformFee: "",
      late: "Late",
      lateFees: "",
      monthly: "",
      monthlyFee: "",
      quaterly: "",
      quaterlyFee: "",
      halfyearly: "",
      halfyearlyFee: "",
      annually: "",
      annuallyFee: "",
      tution: "tutionFee",
      tutionFeeData: "",
      month: "",
      year: "",
      totalAmount: 0,

      registrationFee2: "",

      admissionFee2: "",

      canteenFee2: "",

      stationaryFee2: "",

      entryTestFee2: "",

      busFee2: "",

      securityFee2: "",

      uniformFee2: "",

      lateFees2: "",

      monthlyFee2: "",

      quaterlyFee2: "",

      halfyearlyFee2: "",

      annuallyFee2: "",
      tutionFee2: "",

      discount: "",
      discountTotal: "0",
      installmentValue: "0",
      exactAmount: "",
      amountAfterDivide: "",
      showCalculation: false,
      feePaidBy: "",
      sessionId: "",
      sessions: [],
      discountType: [
        "Family / Relative",
        "Staff",
        "institutional",
        "Speical Discount",
        "Full Pay Discount",
        "Siblings Discount",
      ],
    };
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.props.SharedActions.getDataWithoutDispatch("/api/Session")
      .then((success) => {
        console.log("Sessions Success", success);
        this.setState({
          sessions: success.session,
        });
      })
      .catch((error) => {});
  }

  onClassChange = (e) => {
    console.log(e.target.value);
    const Sections = this.state.studentFilterData.sections;
    let filtering = Sections.filter((item) => item.classId == e.target.value);
    console.log(filtering);

    this.setState({ classId: e.target.value, sections: filtering });
  };

  onSectionChange = (e) => {
    console.log(e.target.value);
    const Subjects = this.state.studentFilterData.student;
    let studentFiltering = Subjects.filter(
      (item) =>
        item.classId == this.state.classId && item.sectionId == e.target.value
    );
    this.setState({ sectionId: e.target.value, students: studentFiltering });
  };

  onSearchParent = (e) => {
    e.preventDefault();
    this.setState({
      btnLoad: true,
    });
    this.props.SharedActions.getDataWithoutDispatch(
      `/api/GetChildrenWithFees/?parentId=${this.state.cnic}`
    )
      .then((success) => {
        this.setState({
          btnLoad: false,
          parentData: success.childrenList,
          // name: success.name,
          // password: success.password,
          // userName: success.userName,
          enableDropdown: true,
        });
        console.log(success);
        // this.HitFeeTypeApi();
      })
      .catch((error) => {
        try {
          this.setState({
            btnLoad: false,
            errorText: error.response.data.Message,
            badError: true,
            formLoading: true,
          });
          console.log(error);
        } catch (error) {}
      });
  };

  HitFeeTypeApi = (e) => {
    this.props.SharedActions.getDataWithoutDispatch(
      `/api/StudentFeeStructure/?classId=${this.state.classId}`
    )
      .then((success) => {
        debugger;
        this.setState({
          formLoading: false,
          feeType: success,
          dataCollection: success,
          registrationFee2: success.RegistrationFee,
          admissionFee2: success.admissionFee,
          entryTestFee2: success.entryTestFee,
          busFee2: success.busFee,
          stationaryFee2: success.stationaryFee,
          securityFee2: success.securityFee,
          canteenFee2: success.canteenFee,
          uniformFee2: success.uniformFee,
          lateFees2: success.lateFees,
          monthlyFee2: success.monthlyFee,
          quaterlyFee2: success.quaterlyFee,
          halfyearlyFee2: success.halfyearlyFee,
          annuallyFee2: success.annuallyFee,
          tutionFee2: success.tutionFee,
        });
        console.log(success);
      })
      .catch((error) => {
        debugger;
        this.setState({
          formLoading: true,
        });
        console.log(error);
      });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      studentId,
      classId,
      registration,
      registrationFee,
      admission,
      admissionFee,
      canteen,
      canteenFee,
      stationary,
      stationaryFee,
      entryTest,
      entryTestFee,
      bus,
      busFee,
      security,
      securityFee,
      uniform,
      uniformFee,
      late,
      lateFees,
      monthly,
      monthlyFee,
      quaterly,
      quaterlyFee,
      halfyearly,
      halfyearlyFee,
      annually,
      annuallyFee,
      year,
      month,
      sectionId,
      amountPaid,
      feeTypeAmount,
      paymentType,
      totalAmount,
      feeMonths,
      payableType,
      studentFeeTypes,
      ChequeDetails,
      DiscountDetails,
      feePaidBy,
      discount,
      sessionId,
    } = this.state;
    if (Object.is(sessionId, "")) {
      this.setState({
        msg: "Select Student First",
        backColor: "red",
      });
      // this.props.snackbar();
    } else {
      var data = {
        paymentMethod: this.state.paymentType,
        feesPaidBy: this.state.feePaidBy,
        paymentType: this.state.payableType,
      };
      this.state.studentFeeTypes.map((item, index) => {
        this.state.studentFeeTypes[index]["ReceiptBooking"] = {
          ...data,
          ...this.state.studentFeeTypes[index]["ReceiptBooking"],
        };
      });
      console.log("KEYS", this.state.studentFeeTypes);
      var bodyParameters = {
        listStudentRecipents: [...this.state.studentFeeTypes],
        ChequeDetails,
      };
      debugger;
      this.props.SharedActions.simpleAddRequest(
        `/api/ReciptBooking`,
        bodyParameters
      )
        .then((success) => {
          debugger;
          this.setState({
            userName: "",
            classId: "",
            sectionId: "",
            studentId: "",
            cnic: "",
            msg: "Added Successfully",
            backColor: "#01ac8a",
            formLoading: true,
            enableDropdown: false,
            payableType: '',
            ChequeDetails: [
              {
                chequeNo: "",
                date: "",
                bankName: "",
                amount: "",
              },
            ],
          });
          // this.props.snackbar();
        })
        .catch((error) => {
          debugger;
          try {
            this.setState({
              msg: error.response.data.Message,
              backColor: "red",
            });
          } catch (error) { }

          // this.props.snackbar();
          console.log(error);
        });
    }
    console.log(this.state);
    console.log("Body Parameters", bodyParameters);
  };
  HideUnHide = (e) => {
    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  };

  // componentDidUpdate() {
  // 	if (
  // 		this.state.msg ||
  // 		this.state.errorMsg ||
  // 		this.state.badError ||
  // 		this.state.updateFlag ||
  // 		this.state.deleteFlag
  // 	) {
  // 		setTimeout(() => {
  // 			this.setState({
  // 				msg: false,
  // 				errorMsg: false,
  // 				badError: false,
  // 				updateFlag: false,
  // 				deleteFlag: false
  // 			});
  // 		}, 3000);
  // 	}
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.feeTypeAmount !== this.state.feeTypeAmount ||
      prevState.disableCheck !== this.state.disableCheck
    ) {
      var dt = new Date();
      let monthNumber = new Date().getMonth();
      let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let monthName = monthNames[monthNumber];
      debugger;
      this.setState({
        year: dt.getFullYear(),
        month: monthName,
        totalAmount: +this.state.feeTypeAmount + +this.state.multiplyAmount,
      });
    } else if (prevState.tutionFee !== this.state.tutionFee) {
      debugger;
      var dt = new Date();
      let monthNumber = new Date().getMonth();
      let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let monthName = monthNames[monthNumber];
      this.setState({
        year: dt.getFullYear(),
        month: monthName,
        totalAmount: +this.state.feeTypeAmount + +this.state.tutionFee,
      });
    }
  }

  Message = (msg, color, fontSize, flag) => {
    if (flag) {
      return (
        <p style={{ fontSize: fontSize }} class={`text-${color}`}>
          {msg}
        </p>
      );
    }
  };

  ondiscountChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    debugger;
    console.log(this.state);
    if (e.target.value === "Cheque") {
      this.setState({
        chequeDiv: true,
        [e.target.name]: e.target.value,
      });
    } else {
      debugger;
      this.setState({
        chequeDiv: false,
        [e.target.name]: e.target.value,
      });
    }
  };

  onAddClick = (stdId, stdName) => {
    this.setState({
      addFlag: true,
      studentId: stdId,
      studentName: stdName,
    });
  };

  onUpdate = (e) => {
    e.preventDefault();
    const {
      balanceUpdate,
      status,
      amountPaidUpdate,
      paymentTypeUpdate,
    } = this.state;
    if (Object.is(amountPaidUpdate, "") || Object.is(paymentTypeUpdate, "")) {
      this.setState({
        msg: "Select the Payment Type First",
        backColor: "red",
      });
      // this.props.snackbar();
    } else {
      var bodyParameters = {
        balance: balanceUpdate,
        status: "Paid",
        amountPaid: amountPaidUpdate,
        paymentType: paymentTypeUpdate,
      };
      this.props.SharedActions.editPatchRecordWithoutDispatch(
        `/api/StudentPayDues/?id=`,
        this.state.id,
        bodyParameters
      )
        .then((success) => {
          var data = this.state.newDuesInfo.filter(
            (item) => item.id != this.state.id
          );
          debugger;
          this.setState({
            newDuesInfo: data,
            formLoading: false,
            msg: success.data,
            backColor: "#01ac8a",
          });
          // this.props.snackbar();
        })
        .catch((error) => {
          debugger;
          this.setState({
            msg: error.response.data.Message,
            backColor: "red",
            badError: true,
          });
          // this.props.snackbar();
          console.log(error);
        });
    }
    console.log(this.state);
  };
  onStudentSelect = (e) => {
    console.log(e.target.value);
    this.setState({ sessionId: parseInt(e.target.value), formLoading: false });
  };
  feeTypeSelect = (e, feeId, studentId) => {
    console.log(e.target.checked);
    console.log(e.target.name);
    const obj = {
      studentId: studentId,
      ReceiptBooking: {
        sessionId: this.state.sessionId,
        accountId: this.props.userData.unique_name,
        accountType: this.props.userData.role,
      },
    };

    //Filteration of Some Fee types Addition
    if (e.target.checked == true && e.target.name == "canteenFee") {
      debugger;

      this.setState({
        multiplyAmount: +this.state.multiplyAmount + +feeId,
        studentFeeTypes: [...this.state.studentFeeTypes, obj],
      });
    } else if (e.target.checked == true && e.target.name == "busFee") {
      debugger;
      this.setState({
        multiplyAmount: +this.state.multiplyAmount + +feeId,
        studentFeeTypes: [...this.state.studentFeeTypes, obj],
      });
    } else if (e.target.checked == true && e.target.name == "stationaryFee") {
      debugger;
      this.setState({
        multiplyAmount: +this.state.multiplyAmount + +feeId,
        studentFeeTypes: [...this.state.studentFeeTypes, obj],
      });
    } else if (e.target.checked == true && e.target.name == "tutionFee") {
      debugger;
      this.setState({
        multiplyAmount: +this.state.multiplyAmount + +feeId,
        studentFeeTypes: [...this.state.studentFeeTypes, obj],
      });
    } else if (e.target.checked == false && e.target.name == "canteenFee") {
      const clearObj = this.state.studentFeeTypes.filter(
        (item) => item.feeType != e.target.name
      );
      this.setState({
        multiplyAmount: +this.state.multiplyAmount - +feeId,
        [e.target.name]: "",
        studentFeeTypes: clearObj,
      });
      debugger;
    } else if (e.target.checked == false && e.target.name == "busFee") {
      const clearObj = this.state.studentFeeTypes.filter(
        (item) => item.feeType != e.target.name
      );
      this.setState({
        multiplyAmount: +this.state.multiplyAmount - +feeId,
        [e.target.name]: "",
        studentFeeTypes: clearObj,
      });
      debugger;
    } else if (e.target.checked == false && e.target.name == "stationaryFee") {
      const clearObj = this.state.studentFeeTypes.filter(
        (item) => item.feeType != e.target.name
      );
      this.setState({
        multiplyAmount: +this.state.multiplyAmount - +feeId,
        [e.target.name]: "",
        studentFeeTypes: clearObj,
      });
      debugger;
    } else if (e.target.checked == false && e.target.name == "tutionFee") {
      const clearObj = this.state.studentFeeTypes.filter(
        (item) => item.feeType != e.target.name
      );
      this.setState({
        multiplyAmount: +this.state.multiplyAmount - +feeId,
        [e.target.name]: "",
        studentFeeTypes: clearObj,
      });
      debugger;
    } else if (e.target.checked == true && e.target.name != "canteenFee") {
      debugger;
      this.setState({
        feeTypeAmount: +this.state.feeTypeAmount + +feeId,
        [e.target.name]: feeId,
        studentFeeTypes: [...this.state.studentFeeTypes, obj],
      });
    } else if (
      (e.target.checked == false && e.target.name != "canteenFee") ||
      e.target.name != "busFee" ||
      e.target.name != "stationaryFee" ||
      e.target.name != "tutionFee"
    ) {
      const clearObj = this.state.studentFeeTypes.filter(
        (item) => item.studentId != studentId
      );
      debugger;
      this.setState({
        feeTypeAmount: +this.state.feeTypeAmount - +feeId,
        [e.target.name]: "",
        studentFeeTypes: clearObj,
      });
    } else {
      console.log("Maslaaa");
      debugger;
    }
    //End Conditions
    console.log(this.state);
    debugger;
  };

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    const changeArrOfObj = this.state[`${arrName}`];
    changeArrOfObj[index][e.target.name] = e.target.value;
    this.setState({
      [arrName]: changeArrOfObj,
    });
  };

  updateDiscountAmount = (e, index, arrName) => {
    if (e.target.name === "percentage") {
      const changeArrOfObj = this.state[`${arrName}`];

      var divideHundred = changeArrOfObj[index]["fee"] / 100;

      console.log("FEE", this.state["DiscountDetails"][index]["fee"]);

      changeArrOfObj[index]["discount"] = divideHundred * e.target.value;
      changeArrOfObj[index]["percentage"] = e.target.value;

      debugger;
      var dTotal = this.state.DiscountDetails.reduce(function (prev, cur) {
        return prev + cur.discount;
      }, 0);

      // var calculate = this.state.feeTypeAmount - dTotal;
      this.setState({
        // exactAmount: calculate,
        discountTotal: dTotal,
        [arrName]: changeArrOfObj,
      });
    }
  };

  updateFeeTypeAndAmount = (e, index, arrName) => {
    const changeArrOfObj = this.state[`${arrName}`];
    changeArrOfObj[index][e.target.name] = JSON.parse(e.target.value).feeName;
    changeArrOfObj[index]["fee"] = JSON.parse(e.target.value).feeAmount;

    debugger;
    this.setState({
      [arrName]: changeArrOfObj,
    });
  };
  updateSpecialArrObjOnChangeDate = (e, index, arrName) => {
    console.log(e.target.value);
    let Date1 = moment(e.target.value).format("MM/DD/YYYY");
    debugger;
    const changeArrOfObj = this.state[`${arrName}`];
    changeArrOfObj[index]["date"] = Date1;
    this.setState({
      [arrName]: changeArrOfObj,
    });
  };

  handleOptionChange = (e) => {
    console.log(e.target.checked);
    debugger;
    if (e.target.name == "Full Payment") {
      var divideAmount = this.state.exactAmount / 12;
      this.setState({
        installmentValue: 12,
        // amountAfterDivide: divideAmount,
        // disableCheck: true,
        payableType: "Full Payment",
        radioCheck: true,
      });
    } else if (e.target.name == "Two Installment") {
      this.setState({
        installmentValue: 3,
        // amountAfterDivide: +this.state.exactAmount / 3,
        // disableCheck: true,
        payableType: "Two Installment",
        radioCheck: true,
        multiplyAmount: +this.state.multiplyAmount * 3,
        // monthly: '',
        // quaterly: 'Quater',
        // halfyearly: '',
        // annually: '',
        // halfyearlyFee: '',
        // monthlyFee: '',
        // halfyearlyFee: '',
        // annuallyFee: '',
        // tutionFee: e.target.value
      });
      this.getNext3Months();
    } else if (e.target.name == "Three Installment") {
      this.setState({
        installmentValue: 2,
        // amountAfterDivide: +this.state.exactAmount / 2,
        // disableCheck: true,
        multiplyAmount: +this.state.multiplyAmount * 6,
        payableType: "Three Installment",
      });
      this.getNext6Months();
    } else if (e.target.name == "Four Installment") {
      this.setState({
        installmentValue: 1,
        // amountAfterDivide: +this.state.exactAmount / 1,
        // disableCheck: true,
        multiplyAmount: +this.state.multiplyAmount * 12,
        payableType: "Four Installment",
      });
      this.getNext12Months();
    }
  };
  getDueInformation = (dueId) => {
    debugger;
    var getData = this.state.DuesInfo[0].StudentFeeDues.filter(
      (item) => item.id == dueId
    );
    this.setState({
      modalData: getData,
      id: getData[0].id,
      amountPaidUpdate: getData[0].balance,
      balanceUpdate: 0,
    });
    console.log("Data After Filter", getData);
  };
  CalculateMonths = () => {
    var StringMonths = "";
    var AfterMerge = StringMonths.concat(
      this.state.january,
      this.state.february,
      this.state.march,
      this.state.april,
      this.state.may,
      this.state.june,
      this.state.july,
      this.state.august,
      this.state.september,
      this.state.october,
      this.state.november,
      this.state.december
    );
    this.setState({
      feeMonths: AfterMerge,
    });
    console.log("All Months", AfterMerge);
  };
  Reset = () => {
    var elem = document.getElementById("CBOX1");
    debugger;
    elem.checked = false;
    this.setState({
      disableCheck: false,
      payableType: "",
      totalAmount: 0,
      radioCheck: false,
      multiplyAmount: 0,
      feeTypeAmount: 0,
    });
    var StringMonths = "";
    var AfterMerge = StringMonths.concat(
      this.state.january,
      this.state.february,
      this.state.march,
      this.state.april,
      this.state.may,
      this.state.june,
      this.state.july,
      this.state.august,
      this.state.september,
      this.state.october,
      this.state.november,
      this.state.december
    );
    console.log("Month 1", this.state.april);
    console.log("Month 2", this.state.may);
    console.log("Month 3", this.state.june);
    console.log("All Months", AfterMerge);
  };
  getNext3Months = () => {
    debugger;
    const currentMonth = new Date().getMonth() - 1; // getMonth() is zero-indexed; add 1 to give conventional month number.
    const ans = [1, 2, 3].map((n) => (currentMonth + n) % 12);
    let cal = ans.map((item) => {
      if (item == "0") {
        console.log("Junuary");

        this.setState({
          january: " January",
        });
      } else if (item == 1) {
        console.log("February");

        this.setState({
          february: " February",
        });
      } else if (item == 2) {
        console.log("March");

        this.setState({
          march: " March",
        });
      } else if (item == 3) {
        console.log("April");

        this.setState({
          april: " April",
        });
      } else if (item == 4) {
        console.log("May");
        this.setState({
          may: " May",
        });
      } else if (item == 5) {
        console.log("June");
        this.setState({
          june: " June",
        });
      } else if (item == 6) {
        console.log("July");
        this.setState({
          june: " July",
        });
      } else if (item == 7) {
        console.log("August");
        this.setState({
          august: " August",
        });
      } else if (item == 8) {
        console.log("September");
        this.setState({
          september: " September",
        });
      } else if (item == 9) {
        console.log("October");
        this.setState({
          october: " October",
        });
      } else if (item == 10) {
        console.log("November");
        this.setState({
          november: " November",
        });
      } else if (item == 11) {
        console.log("December");
        this.setState({
          december: " December",
        });
      } else {
        console.log("Kuch Naa Milaa");
      }
      console.log(this.state.feeMonths, "Months Lay lo");
    });
    this.setState(
      {
        feeMonthsArray: ans,
      },
      this.CalculateMonths
    );
    console.log("monthss", ans); // returns array of next three conventional month numbers; %12 caters for end of year wrap-around.
  };
  getNext6Months = () => {
    debugger;
    const currentMonth = new Date().getMonth() - 1;
    debugger; // getMonth() is zero-indexed; add 1 to give conventional month number.
    const ans = [1, 2, 3, 4, 5, 6].map((n) => (currentMonth + n) % 12);
    let cal = ans.map((item) => {
      if (item == "0") {
        console.log("Junuary");

        this.setState({
          january: " January",
        });
      } else if (item == 1) {
        console.log("February");

        this.setState({
          february: " February",
        });
      } else if (item == 2) {
        console.log("March");

        this.setState({
          march: " March",
        });
      } else if (item == 3) {
        console.log("April");

        this.setState({
          april: " April",
        });
      } else if (item == 4) {
        console.log("May");
        this.setState({
          may: " May",
        });
      } else if (item == 5) {
        console.log("June");
        this.setState({
          june: " June",
        });
      } else if (item == 6) {
        console.log("July");
        this.setState({
          july: " July",
        });
      } else if (item == 7) {
        console.log("August");
        this.setState({
          august: " August",
        });
      } else if (item == 8) {
        console.log("September");
        this.setState({
          september: " September",
        });
      } else if (item == 9) {
        console.log("October");
        this.setState({
          october: " October",
        });
      } else if (item == 10) {
        console.log("November");
        this.setState({
          november: " November",
        });
      } else if (item == 11) {
        console.log("December");
        this.setState({
          december: " December",
        });
      } else {
        console.log("Kuch Naa Milaa");
      }
      console.log(this.state.feeMonths, "Months Lay lo");
    });
    this.setState(
      {
        feeMonthsArray: ans,
      },
      this.CalculateMonths
    );

    console.log("monthss", ans); // returns array of next three conventional month numbers; %12 caters for end of year wrap-around.
  };
  getNext12Months = () => {
    debugger;
    const currentMonth = new Date().getMonth(); // getMonth() is zero-indexed; add 1 to give conventional month number.
    const ans = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
      (n) => (currentMonth + n) % 12
    );
    let cal = ans.map((item) => {
      if (item == "0") {
        console.log("Junuary");

        this.setState({
          january: " January",
        });
      } else if (item == 1) {
        console.log("February");

        this.setState({
          february: " February",
        });
      } else if (item == 2) {
        console.log("March");

        this.setState({
          march: " March",
        });
      } else if (item == 3) {
        console.log("April");

        this.setState({
          april: " April",
        });
      } else if (item == 4) {
        console.log("May");
        this.setState({
          may: " May",
        });
      } else if (item == 5) {
        console.log("June");
        this.setState({
          june: " June",
        });
      } else if (item == 6) {
        console.log("July");
        this.setState({
          july: " July",
        });
      } else if (item == 7) {
        console.log("August");
        this.setState({
          august: " August",
        });
      } else if (item == 8) {
        console.log("September");
        this.setState({
          september: " September",
        });
      } else if (item == 9) {
        console.log("October");
        this.setState({
          october: " October",
        });
      } else if (item == 10) {
        console.log("November");
        this.setState({
          november: " November",
        });
      } else if (item == 11) {
        console.log("December");
        this.setState({
          december: " December",
        });
      } else {
        console.log("Kuch Naa Milaa");
      }
      console.log(this.state.feeMonths, "Months Lay lo");
    });
    this.setState(
      {
        feeMonthsArray: ans,
      },
      this.CalculateMonths
    );
    console.log("monthss", ans); // returns array of next three conventional month numbers; %12 caters for end of year wrap-around.
  };

  calcuateFees = () => {
    var minusTheAmount = this.state.feeTypeAmount - this.state.discountTotal;

    this.setState({
      exactAmount: +minusTheAmount / +this.state.installmentValue,
      showCalculation: true,
    });
  };
  render() {
    console.log("Fee Paid By", this.state.feePaidBy);
    console.log("Dues Information", this.state.DuesInfo);
    console.log("Cheque Info", this.state.ChequeDetails);
    console.log("Discount Info", this.state.DiscountDetails);
    var msgTotal = this.state.DiscountDetails.reduce(function (prev, cur) {
      return prev + cur.discount;
    }, 0);
    console.log("Discount Total", msgTotal);
    console.log("Data Collection", this.state.dataCollection);
    const {
      formLoading,
      parentData,
      DuesInfo,
      newDuesInfo,
      sessions,
    } = this.state;

    const FormLoading = (
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        <label class="text-danger font-weight-bold">No Data </label>
      </div>
    );
    const DuesInformation = (
      <div>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Remaining Balance
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form id="button">
                  <div class="row" style={{ justifyContent: "center" }}>
                    <div class="col-md-12">
                      <div class="md-form mb-0">
                        <label for="name" class="">
                          Select Payment Type
                        </label>
                        <select
                          onChange={this.onHandleTextChange}
                          class="custom-select"
                          name="paymentTypeUpdate"
                        >
                          <option>Payment Type..</option>
                          <option value="Card">Card</option>
                          <option value="Cash">Cash</option>
                          <option value="Cheque">Cheque</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  onClick={this.onUpdate}
                  class="btn btn-primary"
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="card shadowForBox">
          <div class="card-body">
            <ThemeContext.Consumer>
              {(context) => (
                <h4
                  class="card-title text-center"
                  style={{
                    color: context.themeColors.primaryColor,
                    background: context.themeColors.primaryColor,
                  }}
                >
                  Dues Information
                </h4>
              )}
            </ThemeContext.Consumer>
            <hr />
            {this.state.lengthCheck ? (
              <div class="text-center">
                <lable class="font-weight-bold text-primary">No Dues</lable>
              </div>
            ) : (
              newDuesInfo.map((data, indexe) => (
                <React.Fragment>
                  <hr />
                  <div class="row">
                    <div class="col-md-2">
                      <lable class="font-weight-bold">Month:</lable>
                      <label style={{ marginLeft: 5 }}>{data.month}</label>
                    </div>
                    <div class="col-md-2">
                      <lable class="font-weight-bold">Year:</lable>
                      <label style={{ marginLeft: 5 }}>{data.year}</label>
                    </div>
                    <div class="col-md-2">
                      <lable class="font-weight-bold">Amount Paid:</lable>
                      <label style={{ marginLeft: 5 }}>{data.amountPaid}</label>
                    </div>
                    <div class="col-md-2">
                      <lable class="font-weight-bold">Total Amount:</lable>
                      <label style={{ marginLeft: 5 }}>
                        {data.totalAmount}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <lable class="font-weight-bold">Balance:</lable>
                      <label style={{ marginLeft: 5 }}>{data.balance}</label>
                    </div>
                    <div class="col-md-2">
                      <lable class="font-weight-bold">Status:</lable>
                      <label class="text-danger" style={{ marginLeft: 5 }}>
                        {data.status}
                      </label>
                    </div>
                    <div class="col-md-12">
                      <div class="text-right">
                        <button
                          type="submit"
                          class="buttonHoverFill"
                          data-toggle="modal"
                          data-target="#exampleModal"
                          onClick={() => this.getDueInformation(data.id)}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))
            )}
          </div>
        </div> */}
      </div>
    );
    const StudentListView = (
      <div>
        {DuesInformation}
        <br />
        <div class="frontside">
          <div class="card shadowForBox">
            <div class="card-body">
              <ThemeContext.Consumer>
                {(context) => (
                  <h4
                    style={{ color: context.themeColors.primaryColor }}
                    class="card-title text-center"
                  >
                    Add Fees
                  </h4>
                )}
              </ThemeContext.Consumer>
              {/* <Snackbar msg={this.state.msg} backColor={this.state.backColor} /> */}
{/* 
              {this.state.msg !== "" && (
                <p style={{ textAlign: "center", color: "red" }}>
                  {this.state.msg}
                </p>
              )} */}
              <p class="card-text text-center">
                Fees Collection of the Students
              </p>
              {/* <button
								href="#"
								class="btn btn-sm"
								style={{ backgroundColor: '#01425e', color: 'white' }}
							>
								<i class="fa fa-plus"></i>
							</button> */}
              <fieldset
                disabled={this.state.disableCheck}
                style={
                  this.state.disableCheck
                    ? {
                        backgroundColor: "#ABD0BC",
                      }
                    : {}
                }
              >
                <div class="row">
                  <div class="col-md-6">
                    <ul class="list-inline">
                      <lable class="font-weight-bold">Student Name:</lable>
                      {this.state.parentData &&
                        this.state.parentData.map((item, index) => (
                          <ThemeContext.Consumer>
                            {(context) => (
                              <React.Fragment>
                                <li class="">
                                  <input
                                    id="CBOX1"
                                    style={{ marginRight: 20 }}
                                    type="checkbox"
                                    onClick={(e) =>
                                      this.feeTypeSelect(
                                        e,
                                        item.totalFee,
                                        item.studentId
                                      )
                                    }
                                    checked={this.state.check}
                                    name={item.stduentName}
                                  />
                                  &nbsp;
                                  <a
                                    style={{
                                      color: context.themeColors.primaryColor,
                                    }}
                                  >
                                    {item.stduentName}
                                  </a>
                                </li>
                              </React.Fragment>
                            )}
                          </ThemeContext.Consumer>
                        ))}
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="list-inline">
                      <lable class="font-weight-bold">Amount:</lable>
                      {this.state.parentData &&
                        this.state.parentData.map((item, index) => (
                          <React.Fragment>
                            <ThemeContext.Consumer>
                              {(context) => (
                                <li class="">
                                  <a
                                    style={{
                                      color: context.themeColors.primaryColor,
                                    }}
                                    s
                                  >
                                    {item.totalFee}/.
                                  </a>
                                </li>
                              )}
                            </ThemeContext.Consumer>
                          </React.Fragment>
                        ))}
                    </ul>
                  </div>
                </div>
              </fieldset>

              {/* <div class="col-md-12 text-center">
                  <lable class="font-weight-bold">Tution Fee :</lable>
                  <a>&nbsp;{this.state.multiplyAmount}</a>
                </div> */}
              {/* <div class="col-md-12 text-center">
                  <lable class="font-weight-bold">Total :</lable>
                  <a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                    {this.state.totalAmount}
                  </a>
                </div> */}

              {/* <div class="col-md-4">
									<div class="md-form mb-0"></div>
								</div> */}
              {/* <div className="row">
                <div class="col-md-4">
                  <div class="md-form mb-0">
                    <div class="">
                      <label className="font-weight-bold">
                        Do you want any discount?
                      </label>
                      <select
                        onChange={this.ondiscountChange}
                        class="custom-select"
                        name="discount"
                      >
                        <option>Discount</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}
              {this.state.discount === "yes" ? (
                <div class="row">
                  <div class="col-md-10">
                    <MultipleDiscounts
                      onHandleTextChange={this.onHandleTextChange}
                      stateData={this.state}
                      updateSpecialArrObjOnChange={
                        this.updateSpecialArrObjOnChange
                      }
                      updateFeeTypeAndAmount={this.updateFeeTypeAndAmount}
                      updateDiscountAmount={this.updateDiscountAmount}
                      onClickAddItem={this.onClickAddItem}
                      onClickDeleteItem={this.onClickDeleteItem}
                    />
                  </div>
                  <div class="col-md-10 text-right">
                    <lable class="font-weight-bold">Total Discount:</lable>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;{this.state.discountTotal}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div class="row">
                <div class="col-md-8">
                  <form>
                    <ThemeContext.Consumer>
                      {(context) => (
                        <React.Fragment>
                          <label
                            style={{
                              color: context.themeColors.primaryColor,
                              fontWeight: "bold",
                            }}
                          >
                            <input
                              type="radio"
                              value={this.state.monthlyFee2}
                              checked={this.state.payableType == "Full Payment"}
                              onChange={this.handleOptionChange}
                              name="Full Payment"
                            />
                            Monthly
                          </label>

                          <label
                            style={{
                              marginLeft: 8,
                              color: context.themeColors.primaryColor,
                              fontWeight: "bold",
                            }}
                          >
                            <input
                              type="radio"
                              value={this.state.quaterlyFee2}
                              checked={
                                this.state.payableType == "Two Installment"
                              }
                              onChange={this.handleOptionChange}
                              name="Two Installment"
                            />
                            Two Installments
                          </label>

                          <label
                            style={{
                              marginLeft: 8,
                              color: context.themeColors.primaryColor,
                              fontWeight: "bold",
                            }}
                          >
                            <input
                              type="radio"
                              value={this.state.halfyearlyFee2}
                              checked={
                                this.state.payableType == "Three Installment"
                              }
                              onChange={this.handleOptionChange}
                              name="Three Installment"
                            />
                            Three Installments
                          </label>
                          <label
                            style={{
                              marginLeft: 8,
                              color: context.themeColors.primaryColor,
                              fontWeight: "bold",
                            }}
                          >
                            <input
                              type="radio"
                              value={this.state.annuallyFee2}
                              checked={
                                this.state.payableType == "Four Installment"
                              }
                              onChange={this.handleOptionChange}
                              name="Four Installment"
                            />
                            Full Payment
                          </label>
                        </React.Fragment>
                      )}
                    </ThemeContext.Consumer>
                  </form>
                </div>
                {/* <div class="col-md-4 text-right">
                  <ThemeContext.Consumer>
                    {(context) => (
                      <button
                        style={{
                          background: context.themeColors.primaryColor,
                          borderColor: context.themeColors.primaryColor,
                        }}
                        onClick={this.Reset}
                        class="btn btn-primary"
                      >
                        {" "}
                        <span
                          class="fas fa-trash-restore"
                          style={{ marginRight: 5 }}
                        ></span>
                        Reset
                      </button>
                    )}
                  </ThemeContext.Consumer>
                </div> */}
              </div>
              <div className="row">
                {/* <div className="col-md-12 text-center">
                  <button
                    className="btn btn-primary"
                    onClick={this.calcuateFees}
                  >
                    Calculate Total Fees
                  </button>
                </div> */}
                {this.state.showCalculation ? (
                  <div class="col-md-12 text-center pt-2">
                    {/* <lable class="font-weight-bold">Fee Type:</lable> */}
                    <lable class="font-weight-bold"> Fees:</lable>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;{this.state.feeTypeAmount}
                    </span>
                    <span className="font-weight-bold ml-2"> - </span>
                    <lable class="font-weight-bold ml-3">
                      {" "}
                      Total Discount:
                    </lable>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;{this.state.discountTotal}
                    </span>
                    <span className="font-weight-bold ml-2"> / </span>
                    <lable class="font-weight-bold ml-3">
                      {" "}
                      Installment Value:
                    </lable>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;{this.state.installmentValue}
                    </span>
                    <span className="font-weight-bold ml-5"> = </span>
                    <label class="ml-3">
                      {" "}
                      Total Fees: {this.state.exactAmount}{" "}
                    </label>
                  </div>
                ) : (
                  ""
                )}
                <div class="col-md-4">
                  <div class="md-form mb-0">
                    <div class="">
                      <label className="font-weight-bold">
                        Select Payment Type:
                      </label>
                      <select
                        onChange={this.onHandleTextChange}
                        class="custom-select"
                        name="paymentType"
                      >
                        <option>Payment Type</option>
                        <option value="Card">Card</option>
                        <option value="Cash">Cash</option>
                        <option value="Cheque">Cheque</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="md-form mb-0">
                    <label style={{ color: "black" }} for="name" class="">
                      Fees Paid By:
                    </label>
                    <input
                      type="text"
                      name="feePaidBy"
                      value={this.state.feePaidBy}
                      class="form-control"
                      onChange={this.onHandleTextChange}
                    />
                  </div>
                </div>
              </div>
              {this.state.chequeDiv ? (
                <div class="row">
                  <div class="col-md-8">
                    <MultipleCheque
                      onHandleTextChange={this.onHandleTextChange}
                      stateData={this.state}
                      updateSpecialArrObjOnChange={
                        this.updateSpecialArrObjOnChange
                      }
                      updateSpecialArrObjOnChangeDate={
                        this.updateSpecialArrObjOnChangeDate
                      }
                      onClickAddItem={this.onClickAddItem}
                      onClickDeleteItem={this.onClickDeleteItem}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div class="row">
                <div class="col-md-12">
                  <div class="text-right">
                    <ThemeContext.Consumer>
                      {(context) => (
                        <button
                          type="submit"
                          onClick={this.onHandleSubmit}
                          style={{
                            background: context.themeColors.primaryColor,
                          }}
                          className={"btns"}
                          // class="buttonHoverFill"
                        >
                          Pay
                        </button>
                      )}
                    </ThemeContext.Consumer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <ThemeContext.Consumer>
        {(context) => (
          <React.Fragment>
            <h3
              class="h1-responsive font-weight-bold text-center my-4 generalHead"
              style={{ background: context.themeColors.primaryColor }}
            >
              Receipt Booking
            </h3>

            {this.state.badError ? (
              <p style={{ color: "red", textAlign: "center" }}>
                {this.state.errorText}
              </p>
            ) : (
              ""
            )}
            <div class="row text-center" style={{ justifyContent: "center" }}>
              <div class="col-md-6" style={{ marginTop: 20 }}>
                <div class="md-form mb-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter parent ID Card # e.g : 12345678"
                      name="cnic"
                      value={this.state.cnic}
                      onChange={this.onHandleTextChange}
                    />
                    <div class="input-group-append">
                      <button
                        class="btn"
                        onClick={this.onSearchParent}
                        style={{
                          backgroundColor: context.themeColors.primaryColor,
                        }}
                        type="button"
                      >
                        <span
                          style={{ marginBottom: 5 }}
                          class={`${
                            this.state.btnLoad
                              ? "spinner-border spinner-border-sm"
                              : "fas fa-search"
                          }`}
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.enableDropdown ? (
                <div class="col-md-3" style={{ marginTop: 20 }}>
                  <div class="md-form mb-0">
                    {/* <label className="font-weight-bold">Sessions</label> */}
                    <select
                      onChange={this.onStudentSelect}
                      class="custom-select"
                      name="staffId"
                      style={{ backgroundColor: "#01425e", color: "white" }}
                    >
                      <option selected={this.state.msg ? true : false}>
                        Select Session..
                      </option>
                      {sessions &&
                        sessions.map((item, index) => (
                          <option key={index} value={`${item.sessionId}`}>
                            {item.currentSession}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <br />
            <br />
            {formLoading ? FormLoading : StudentListView}
          </React.Fragment>
        )}
      </ThemeContext.Consumer>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    SharedActions: bindActionCreators(SharedActions, dispatch),
    ParentActions: bindActionCreators(ParentActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptBooking);

import React, { useEffect, useRef, useContext } from "react";
import Stepper from "bs-stepper";
import StaffImageUploadSection from "../imageUploadSection/StaffImageUploadSection";
import StaffDocumentUploadSection from "../StaffDocumentUploadSection/StaffDocumentUploadSection";
import themeContext from "../../../context/themeContext/ThemeContext";
export default function DocumentSectionStep(props) {
  const themeColor = useContext(themeContext);
  const { id } = props;
  const stepper = useRef();
  useEffect(() => {
    stepper.current = new Stepper(document.querySelector("#stepper2"), {
      linear: false,
      animation: true,
    });
  }, []);

  const onSkipClick = (e) => {
    // e.preventDefault();
    stepper.current.next();
  };
  const onSkipDocuments = (e) => {
    e.preventDefault();
    props.history.push("/dashboard/ViewStaff");
  };
  return (
    <div id="stepper2" class="bs-stepper">
      <div style={{ width: "70%", margin: "0 auto" }}>
        <div class="bs-stepper-header" role="tablist">
          <div class="step" data-target="#logins-part">
            <button
              type="button"
              class="step-trigger"
              role="tab"
              aria-controls="logins-part"
              // disabled={true}
              id="logins-part-trigger"
            >
              <span
                style={{ backgroundColor: themeColor.themeColors.primaryColor }}
                class="bs-stepper-circle"
              >
                1
              </span>
              <span class="bs-stepper-label">Image Upload</span>
            </button>
          </div>
          <div class="line"></div>
          <div class="step" data-target="#information-part">
            <button
              // disabled={true}
              type="button"
              class="step-trigger"
              role="tab"
              aria-controls="information-part"
              id="information-part-trigger"
            >
              <span
                style={{ backgroundColor: themeColor.themeColors.primaryColor }}
                class="bs-stepper-circle"
              >
                2
              </span>
              <span class="bs-stepper-label">Document Upload</span>
            </button>
          </div>
        </div>
      </div>
      <div class="bs-stepper-content">
        <div
          id="logins-part"
          class="content"
          role="tabpanel"
          aria-labelledby="logins-part-trigger"
        >
          <StaffImageUploadSection
            {...props}
            id={id}
            onSkipClick={(e) => onSkipClick(e)}
          />
        </div>
        <div
          id="information-part"
          class="content"
          role="tabpanel"
          aria-labelledby="information-part-trigger"
        >
          <StaffDocumentUploadSection
            {...props}
            onSkipDocuments={onSkipDocuments}
            id={id}
          />
        </div>
      </div>
    </div>
  );
}

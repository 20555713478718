import React, { Component } from "react";
import "./Form.css";

class StudentPreviousSchoolDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { prevSchool } = this.props;
    console.log(prevSchool, "Previous School Ka Data Haii");
    return (
      <div>
        <section class="mb-4">
          {/* <div class="row print">
            <div class="col-md-12">
              <div class="md-form mb-0">
                <h2
                  style={{
                    backgroundColor: "#0A4F5E",
                    textAlign: "center",
                    color: "white"
                  }}
                >
                  Previous School Details:
                </h2>
              </div>
            </div>
          </div> */}
          <div class="container">
            <div class="row rowCheck" style={{ marginTop: 10 }}>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>
                  Previous School Name:
                </label>
                &nbsp;&nbsp;{prevSchool && prevSchool.previousSchoolName}{" "}
              </div>

              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>From Year:</label>{" "}
                &nbsp;&nbsp;{prevSchool && prevSchool.fromYear}
              </div>
            </div>
            <div class="row rowCheck">
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Year Upto: </label>
                &nbsp;&nbsp;{prevSchool && prevSchool.yearUpto}
              </div>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Reason Leaving:</label>{" "}
                &nbsp;&nbsp;{prevSchool && prevSchool.reasonLeaving}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default StudentPreviousSchoolDetail;

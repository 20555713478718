import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../../Actions/sharedActions";
import LoaderWrapper from "../../../components/HOC/LoaderWrapper";
import SnackBar from "../../../components/SnackBar/SnackBar";
import ViewComponent from "../../../components/ViewComponent/ViewComponent";
import $ from "jquery";
import useHttp from "../../../hooks/http";
import useCustomHttpWithDataMiddleware from "../../../hooks/Services/httpWithCustomDataMiddleware";
import {
  RENDER_SUB_TOOLS,
  RENDER_TOOLS,
  DELETE_CA,
  ASSIGN_MARKS,
  viewClassReturn,
  disableColorOverCondition,
  addDataMiddleware,
  customDataAddingMiddleWare,
  customSubToolDataAddingMiddleWare,
} from "../../../utils/general/index";
import { General } from "../../../utils";
import Modal from "../../../components/Modal";
import Colors from "../../../utils/app_constants/colors_constants";
import httpWithCaSubToolViewDataMiddlware from "../../../hooks/Services/httpWithCaSubToolViewDataMiddlware";
import { useContext } from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";
import CustomFilterSectionLayout from "../../../components/CustomFilterSectionLayout/CustomFilterSectionLayout";
import SelectionInput from "../../../components/SelectionInput/SelectionInput";

import axios from "axios";
import config from "../../../config/config";

function ContinuousSubToolViewAdmin(props) {
  const themeContext = useContext(ThemeContext);
  const [snackbarMessage, setMsg] = useState("");
  const [studentData, setStudentData] = useState("");
  const [classId, setClassId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [studentId, setStudentId] = useState("");

  const [subjectId, setSubjectId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [term, setTerm] = useState("");
  const [customLoader, setCustomLoader] = useState(false);
  const [isSessionLoading, sessions] = useHttp("/api/Session", []);
  const [isClassSectionStaffLoading, classSectionStaff] = useHttp(
    "/api/CA_PrimaryList_DropdownFor_Admin",
    []
  );
  const [sections, setsections] = useState([]);
  const [AllStaff, setAllStaff] = useState([]);
  const [allSubjects, setallSubjects] = useState([]);
  const [staffId, setstaffId] = useState("");
  // const [subjectId, setsubjectId] = useState("");
  var [isLoading, fetchDropData] = useHttp(
    `/api/GetChildByParent?userName=${props.name}`,
    []
  );

  debugger;
  const deleteHandler = (id, data, ...args) => {
    debugger;
    let getItem = data.filter((item) => item.pId === parseInt(id));
    $(`${getItem[0]["Action"][3]["modalKey"]}`).modal("show");

    setDeleteId(id);
  };
  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.postFlag
    ) {
      setMsg("Add Record Successfully");
      props.snackbar();
      let state = {};
      props.history.replace({ ...props.location, state });
    }
  }, []);
  const [
    isLoader,
    fetchData,
    setFetchData,
    setIsLoading,
  ] = httpWithCaSubToolViewDataMiddlware(
    `/api/CA_PrimaryList`,
    [],
    {
      Action: [
        {
          renderType: RENDER_TOOLS,
          isEnable: true,
          type: "actionLink",
          exceptionCase: true,
          title: "Create Tool",
          initialPath: "/dashboard/CASubToolView",
          iconName: "fas fa-plus-square",
          iconColor: "#01AC8A",
        },
        {
          renderType: RENDER_SUB_TOOLS,
          isEnable: true,
          exceptionCase: true,
          type: "actionLink",
          title: "Create Sub Tool",
          initialPath: "/dashboard/CASubToolView",
          iconName: "fas fa-tools",
          iconColor: "#9d9d00",
        },
        {
          renderType: ASSIGN_MARKS,
          isEnable: true,
          exceptionCase: true,
          type: "actionLink",
          title: "Assign Marks",
          initialPath: "/dashboard/SelectionCriteria",
          iconName: "fab fa-markdown",
          iconColor: "red",
        },
        {
          isEnable: true,
          type: "actionLink",
          title: "Monthly Report View",
          initialPath: "/dashboard/ContinousNewToolCreation",
          iconName: "fas fa-list",
          iconColor: Colors.SEA_GREEN_THEME,
        },

        {
          isEnable: true,
          type: "actionLink",
          title: "Average Report",
          initialPath: "/dashboard/CaViewExamFeature",
          iconName: "fas fa-list-alt",
          iconColor: Colors.BLACK,
        },
        {
          isEnable: true,
          type: "actionLink",
          title: "Final Report",
          initialPath: "/dashboard/CaAverageSheet",
          iconName: "fas fa-clipboard-list",
          iconColor: Colors.SEA_GREEN_THEME,
        },
        {
          renderType: DELETE_CA,
          handler: deleteHandler,
          isEnable: true,
          exceptionCase: true,
          type: "modalButton",
          modalKey: "#deleteModal",
          title: "Delete",
          initialPath: "/dashboard/ContinousNewToolCreation",
          iconName: "fas fa-trash-alt",
          iconColor: Colors.RED_DANGER,
        },
      ],
    },
    false,
    "PrimaryToolViews"
  );
  const remoteDeleteHandler = (deleteId) => {
    let getFetchData = $.extend(true, [], fetchData);
    debugger;
    props.sharedAction
      .deleteRecordWithoutDispatch(`/api/PrimaryInfo`, deleteId)
      .then((success) => {
        debugger;
        let getFilterData = getFetchData.filter(
          (item) => item.pId !== parseInt(deleteId)
        );
        debugger;
        setFetchData(getFilterData);
      })
      .catch((error) => {
        debugger;
      });
  };

  const onSubmit = () => {
    props.sharedAction
      .simpleAddRequest(`/api/DisciplinaryRecord`, {
        DisciplinaryRecord: [...studentData],
      })
      .then((success) => {
        setTerm("Choose Term");
        setSectionId("");
        setClassId("");
        setSubjectId("");
        setSessionId("");
        setStudentData([]);
        setMsg("Successfully Submitted");
        props.snackbar();
      })
      .catch((error) => {
        if (error && error.response && error.response.status == 404) {
          //404-Error no understanding of 404 Error
          setMsg("404 Not Found");

          props.snackbar();
        }
      });
  };

  const onClassChange = (inputClassid) => {
    setSectionId("");
    if (inputClassid !== "Choose Class") {
      console.log(classSectionStaff);

      let getSection = General.dataFilteration(
        classSectionStaff["section"],
        ["sectionId", "section"],
        {
          classId: inputClassid,
        }
      );

      let getStaff = General.dataFilteration(
        classSectionStaff["teachers"],
        ["staffId", "staffName"],
        {
          classId: inputClassid,
          section: "G",
        }
      );

      // setIsLoading(true);
      setClassId(inputClassid);
      setsections(getSection);
      console.log("Filtered Sections", getSection);
    }
  };

  const onChangeSection = (sectionId) => {
    let getStaff = General.dataFilteration(
      classSectionStaff["teachers"],
      ["staffId", "staffName"],
      {
        classId: classId,
        sectionId: sectionId,
      }
    );
    setAllStaff(getStaff);
    setSectionId(sectionId);
  };

  const onChangeStaff = (staffId) => {
    if (staffId !== "Choose Staff") {
      console.log(classSectionStaff);

      let getSubjects = General.dataFilteration(
        classSectionStaff["subject"],
        ["id", "subjectName"],
        {
          classId: classId,
        }
      );

      // setIsLoading(true);
      setstaffId(staffId);
      setallSubjects(getSubjects);
    }
  };

  const searchFilterWise = () => {
    setIsLoading(true);
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    axios
      .get(
        `${
          config.localhttp
        }${`/api/CA_PrimaryList_AdminPortal/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&staffId=${staffId}&term=${term}&subjectId=${subjectId}`}`,
        {
          headers: { Authorization: `Bearer  ${token}` },
        }
      )
      .then((res) => {
        console.log("hook data get: ", res);
        console.log(res.data);
        debugger;
        let requireData = [];
        // setCompleteData(res.data);
        requireData = customSubToolDataAddingMiddleWare(
          {
            Action: [
              {
                renderType: RENDER_TOOLS,
                isEnable: true,
                type: "actionLink",
                exceptionCase: true,
                title: "Create Tool",
                initialPath: "/dashboard/CASubToolView",
                iconName: "fas fa-plus-square",
                iconColor: "#01AC8A",
              },
              {
                renderType: RENDER_SUB_TOOLS,
                isEnable: true,
                exceptionCase: true,
                type: "actionLink",
                title: "Create Sub Tool",
                initialPath: "/dashboard/CASubToolView",
                iconName: "fas fa-tools",
                iconColor: "#9d9d00",
              },
              {
                renderType: ASSIGN_MARKS,
                isEnable: true,
                exceptionCase: true,
                type: "actionLink",
                title: "Assign Marks",
                initialPath: "/dashboard/SelectionCriteria",
                iconName: "fab fa-markdown",
                iconColor: "red",
              },
              {
                isEnable: true,
                type: "actionLink",
                title: "Monthly Report View",
                initialPath: "/dashboard/ContinousNewToolCreation",
                iconName: "fas fa-list",
                iconColor: Colors.SEA_GREEN_THEME,
              },

              {
                isEnable: true,
                type: "actionLink",
                title: "Average Report",
                initialPath: "/dashboard/CaViewExamFeature",
                iconName: "fas fa-list-alt",
                iconColor: Colors.BLACK,
              },
              {
                isEnable: true,
                type: "actionLink",
                title: "Final Report",
                initialPath: "/dashboard/CaAverageSheet",
                iconName: "fas fa-clipboard-list",
                iconColor: Colors.SEA_GREEN_THEME,
              },
              {
                renderType: DELETE_CA,
                handler: deleteHandler,
                isEnable: true,
                exceptionCase: true,
                type: "modalButton",
                modalKey: "#deleteModal",
                title: "Delete",
                initialPath: "/dashboard/ContinousNewToolCreation",
                iconName: "fas fa-trash-alt",
                iconColor: Colors.RED_DANGER,
              },
            ],
          },
          res.data["PrimaryToolViews"]
        );
        console.log("Require Data", requireData);
        setIsLoading(false);
        debugger;
        setFetchData(requireData);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const onTermChange = (e) => {
    if (e.target.value !== "Choose Term") setTerm(e.target.value);
    console.log("Term" , term);
  };
  console.log(fetchData);
  console.log("CLASSSTAFFSECTIN", classSectionStaff);
  return (
    <div>
      <h2
        style={{
          width: "fit-content",
          background: themeContext.themeColors.primaryColor,
        }}
        className="text-center generalHead"
      >
        Continuous Assessment
      </h2>
      <SnackBar backColor={"#000"} msg={snackbarMessage} />
      <LoaderWrapper marginTop={20} isLoading={isLoader ? true : false}>
        <LoaderWrapper marginTop={20} isLoading={customLoader}>
          <CustomFilterSectionLayout displayDirection="column">
            <div
              style={{
                width: "16%",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SelectionInput
                newFlag="customWidth"
                feildName="sessionId"
                selectedText={"Choose Session"}
                optionsArrys={sessions && sessions["session"]}
                errors={{}}
                isValidte={false}
                stateData={{
                  sessionId: sessionId,
                }}
                optionType="dynamic"
                onHandleChange={(e) => setSessionId(e.target.value)}
                property={"currentSession"}
                propertyId={"sessionId"}
                useFlag={false}
              />

              <SelectionInput
                newFlag="customWidth"
                feildName="term"
                selectedText={"Choose Term"}
                optionsArrys={["1st", "2nd", "3rd"]}
                errors={{}}
                isValidte={false}
                stateData={{
                  term: term,
                }}
                type="static"
                useFlag={false}
                selectedText={"Choose Term"}
                onHandleChange={onTermChange}
                useFlag={false}
              />

              {sessionId !== "" ? (
                <React.Fragment>
                  <SelectionInput
                    newFlag="customWidth"
                    feildName="classId"
                    selectedText={"Choose Class"}
                    optionsArrys={
                      classSectionStaff && classSectionStaff.classes
                    }
                    errors={{}}
                    isValidte={false}
                    stateData={{
                      classId: classId,
                    }}
                    optionType="dynamic"
                    onHandleChange={(e) => onClassChange(e.target.value)}
                    property={"className"}
                    propertyId={"id"}
                    useFlag={false}
                  />
                  <SelectionInput
                    newFlag="customWidth"
                    feildName="sectionId"
                    selectedText={"Choose Section"}
                    optionsArrys={sections && sections}
                    errors={{}}
                    isValidte={false}
                    stateData={{
                      sectionId: sectionId,
                    }}
                    optionType="dynamic"
                    onHandleChange={(e) => onChangeSection(e.target.value)}
                    property={"section"}
                    propertyId={"sectionId"}
                    useFlag={false}
                  />
                  <SelectionInput
                    newFlag="customWidth"
                    feildName="staffId"
                    selectedText={"Choose Staff"}
                    optionsArrys={AllStaff && AllStaff}
                    errors={{}}
                    isValidte={false}
                    stateData={{
                      staffId: staffId,
                    }}
                    optionType="dynamic"
                    onHandleChange={(e) => onChangeStaff(e.target.value)}
                    property={"staffName"}
                    propertyId={"staffId"}
                    useFlag={false}
                  />
                  <SelectionInput
                    newFlag="customWidth"
                    feildName="subjectId"
                    selectedText={"Choose Subject"}
                    optionsArrys={allSubjects && allSubjects}
                    errors={{}}
                    isValidte={false}
                    stateData={{
                      subjectId: subjectId,
                    }}
                    optionType="dynamic"
                    onHandleChange={(e) => setSubjectId(e.target.value)}
                    property={"subjectName"}
                    propertyId={"id"}
                    useFlag={false}
                  />
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
            <div className="text-right mt-4">
              <button
                className="btn btn-primary"
                style={{ background: themeContext.themeColors.primaryColor }}
                onClick={searchFilterWise}
              >
                Search
              </button>
            </div>
          </CustomFilterSectionLayout>
          <ViewComponent
            children={() => (
              <React.Fragment>
                {fetchData &&
                  fetchData.map((item, index) => (
                    <div
                      class="modal fade"
                      id={`deleteModal${item.pId}`}
                      tabindex={`${index}`}
                      role="dialog"
                      aria-labelledby={`exampleModalLabel${index}`}
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content modal_content_custom">
                          <div
                            style={{
                              borderBottomLeftRadius: 30,
                              borderBottomRightRadius: 30,
                              borderTopLeftRadius: 18,
                              borderTopRightRadius: 18,
                              background: themeContext.themeColors.primaryColor,
                            }}
                            class="modal-header modal_custom_header"
                          >
                            <h4 style={{ color: "white" }} class="modal-title">
                              Confirm
                            </h4>
                            <button
                              style={{ color: "white" }}
                              type="button"
                              class="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>
                          <div class="modal-body">
                            <div>
                              <div style={{ textAlign: "center" }}>
                                <h4>Are you sure ?</h4>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <div>
                                  <button
                                    data-dismiss="modal"
                                    onClick={() =>
                                      remoteDeleteHandler(deleteId)
                                    }
                                    style={{
                                      background:
                                        themeContext.themeColors.primaryColor,
                                    }}
                                    type="button"
                                    class="btn btn-primary buttonAppear"
                                  >
                                    Yes
                                  </button>
                                </div>
                                <div>
                                  <button
                                    data-dismiss="modal"
                                    type="button"
                                    style={{
                                      background:
                                        themeContext.themeColors.primaryColor,
                                    }}
                                    class="btn btn-primary buttonAppear"
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </React.Fragment>
            )}
            mainData={fetchData}
            conditionalIconsColor={disableColorOverCondition}
            exceptionalHandler={viewClassReturn}
            extraRedirectProperty="renderType"
            headData={[
              "S #",
              "Name",

              "Grade",
              "Subject Name",
              "Session",
              "Actions",
            ]}
            excludedData={[
              "pId",
              "tools",
              "subTools",
              "term",
              "gradeName",
              "className",
              "section",
              "courseType",
            ]}
            redirectIds={["pId"]}
          />
        </LoaderWrapper>
      </LoaderWrapper>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContinuousSubToolViewAdmin);

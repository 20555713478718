import React from "react";
import Colors from "../../utils/app_constants/colors_constants";
import { useContext } from "react";
import themeContext from "../../context/themeContext/ThemeContext";

export default function Pagination(props) {
  const themeColor = useContext(themeContext);
  const {
    handleClickNextFrwd,
    disableBPage,
    handleClickNextBack,
    handleClickNext,
    disablepage,
    pageNumber,
    currentPage,
    paginationListRange = 3,
  } = props;
  const renderPageNumbers = pageNumber.map((number, index) => {
    return index <= paginationListRange ? (
      <li class={`page-item`}>
        <a
          key={number}
          id={number}
          onClick={handleClickNext}
          class="page-link "
          href="#"
          style={{
            background: `${
              currentPage === number ? themeColor.themeColors.primaryColor : ""
            }`,
            color: `${
              currentPage === number
                ? "#fff"
                : themeColor.themeColors.primaryColor
            }`,
          }}
        >
          {number}
        </a>
      </li>
    ) : (
      ""
    );
  });
  return (
    <nav aria-label="Page navigation example">
      <ul
        style={{ color: themeColor.themeColors.primaryColor }}
        class="pagination"
      >
        <li class={`page-item ${disableBPage ? "disabled" : ""}`}>
          <a onClick={handleClickNextBack} class="page-link" href="#">
            {disableBPage ? (
              <i style={{ color: "grey" }} class="far fa-stop-circle"></i>
            ) : (
              <i
                style={{ color: themeColor.themeColors.primaryColor }}
                class="fas fa-backward"
              ></i>
            )}
          </a>
        </li>

        {renderPageNumbers}
        <li class={`page-item ${disablepage ? "disabled" : ""}`}>
          <a onClick={handleClickNextFrwd} class="page-link" href="#">
            {disablepage ? (
              <i style={{ color: "grey" }} class="far fa-stop-circle"></i>
            ) : (
              <i
                style={{ color: themeColor.themeColors.primaryColor }}
                class="fas fa-forward"
              ></i>
            )}
          </a>
        </li>
      </ul>
    </nav>
  );
}

import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import Colors from '../../../utils/app_constants/colors_constants';
import general from '../../../utils/general';
import httpWithRequiredSetData from '../../../hooks/Services/httpWithRequiredSetData';
import Spinner from '../../../components/Spinner/Spinner';
import TextInput from '../../../components/TextInput/TextInput';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import SnackBar from '../../../components/SnackBar/SnackBar';
import themeContext from "../../../context/themeContext/ThemeContext";

const MessageManager = props => {
    const themeColor = useContext(themeContext);

	const [msg, setSnackMsg] = useState('');
	const [coordinatorData, setCoordinatorData] = useState({});
	const [update, setUpdate] = useState(false);
	const [managerData, setManagerData] = useState({});
	const [managerPostdata, setManagerPostData] = useState([]);
	const [managerStaffData, setManagerStaffData] = useState([]);
	const [recipients, setRecipients] = useState([]);
	const [state, setState] = useState({
		ToStaffId: '',
		title: '',
		message: '',
		sendAll: false,
		accountId: props.name,
		accountType: props.role,
		errorMsg: false,
		postId: ''
	});

	const [isLoadingCoordinator, coordinator] = httpWithRequiredSetData(
		'/api/GetCommunicationCoordinator',
		[],
		data => {
			setCoordinatorData(data);
		}
	);

	const [isLoadingManager, manager] = httpWithRequiredSetData(
		'/api/ListOfPostAndManager ',
		[],
		data => {
			setManagerData(data);
			setManagerPostData(data['managerPost']);
		}
	);

	useEffect(() => {}, [update]);

	const onHandleSelectChange = e => {
		if (
			e.target.value !== 'Select Manager' &&
			e.target.value !== 'Select Staff'
		) {
			if (e.target.name !== 'postId') {
				const filteredStaff = managerData.managerList.filter(
					item => item.staffId === parseInt(e.target.value)
				);
				const target = e.target;
				setState(prevState => ({
					...prevState,
					[target.name]: target.value
				}));
				setRecipients(filteredStaff);
			} else {
				const filteredManagerStaff = general.dataFilteration(
					managerData.managerList,
					['id', 'postId', 'staffId', 'name'],
					{
						postId: e.target.value
					}
				);

				setManagerStaffData(filteredManagerStaff);
				const target = e.target;
				setState(prevState => ({
					...prevState,
					[target.name]: target.value
				}));
			}
		}
	};

	const onHandleTextChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));
	};

	const onSubmit = e => {
		e.preventDefault();

		if (state['message'] === '' || recipients.length === 0) {
			setState(prevState => ({
				...prevState,
				errorMsg: true
			}));
		} else {
			setState(prevState => ({
				...prevState,
				isLoading: true
			}));

			const recipientsTemp = recipients.map(item => ({
				ToStaffId: item.staffId,
				FromPostId: coordinatorData.postId,
				FromStaffId: coordinatorData.staffId,
				title: state['title'],
				message: state['message'],
				accountId: state['accountId'],
				accountType: state['accountType']
			}));
			const data = {
				ManagerMessages: recipientsTemp
			};

			props.sharedActions
				.simpleAddRequest('/api/CoordinatorMessageToManager', data)
				.then(success => {
					setSnackMsg('Message Sent Successfully!');
					props.snackbar();
					setState(prevState => ({
						isLoading: false,
						ToStaffId: '',
						title: '',
						message: '',
						accountId: props.name,
						accountType: props.role,
						errorMsg: false,
						postId: '',
						sendAll: false
					}));
					setRecipients([]);
				})
				.catch(error => {
					if (error && error.response && error.response.status == 404) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							isLoading: false
						}));
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					} else if (error && error.response && error.response.status == 400) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							isLoading: false
						}));
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					}
				});
		}
	};

	useEffect(() => {
		if (state['errorMsg']) {
			setTimeout(() => {
				setState(prevState => ({
					...prevState,
					errorMsg: false
				}));
			}, 3000);
		}
	});

	const allStaff = e => {
		recipients.pop();
		setState(prevState => ({
			...prevState,
			sendAll: !state['sendAll']
		}));
		if (!state['sendAll']) {
			managerData.managerList.map(item => {
				recipients.push(item);
			});
		} else {
			setRecipients([]);
		}
		console.log(recipients);
	};

	const cards = (
		<div class="card">
			<div
				class="card-header text-center"
				style={{ backgroundColor: '#d3d3d3', color: '#fff' }}
			>
				<h3>
					<strong>NEW MESSAGE</strong>
				</h3>
			</div>
			<div class="card-body">
				<div class="row ">
					<div class="col-md-12">
						{state.errorMsg ? (
							<p style={{ color: 'red', textAlign: 'center' }}>
								Please add recipients and a message
							</p>
						) : (
							''
						)}
					</div>
					<div class="col-md-1" style={{ paddingLeft: '25px' }}>
						<strong>To</strong>
					</div>
					<div class="col-md-11 border-bottom">
						<strong style={{ color: '#bbb' }}>Recipeints </strong>
						{recipients.map(item => (
							<div>
								<strong
									style={{
										backgroundColor: '#bbb'
									}}
								>
									{item.name}
								</strong>
							</div>
						))}
					</div>
					<div class="col-md-1"></div>
					<div class="col-md-6 d-flex ">
						<SelectionInput
							isFeildDisabled={state['sendAll']}
							feildName={'postId'}
							onHandleChange={e => onHandleSelectChange(e)}
							errors={{}}
							optionsArrys={managerPostdata}
							selectedText={'Select Manager'}
							stateData={state}
							// iconClassName={'fas fa-building'}
							optionType="dynamicWithPropIdAndName"
							property={'postName'}
							propertyId={'postId'}
							isValidte={false}
							// editUse={'staffEdit'}
							disab
						/>
						<SelectionInput
							isFeildDisabled={state['sendAll']}
							feildName={'managerStaffId'}
							onHandleChange={e => onHandleSelectChange(e)}
							errors={{}}
							optionsArrys={managerStaffData}
							selectedText={'Select Staff'}
							stateData={state}
							// iconClassName={'fas fa-building'}
							optionType="dynamicWithPropIdAndName"
							property={'name'}
							propertyId={'staffId'}
							isValidte={false}
							// editUse={'staffEdit'}
						/>
					</div>
					<div class="col-md-5" style={{ marginTop: '38px' }}>
						<div class="custom-control custom-switch">
							<input
								type="checkbox"
								class="custom-control-input"
								id="customSwitch1"
								onChange={allStaff}
							/>
							<label class="custom-control-label" for="customSwitch1">
								<strong>Send to all staff</strong>
							</label>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							padding: '10px',
							marginTop: '15px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>From</strong>
						</div>
						<div class="col-md-6 d-flex">
							<TextInput
								feildName={'coordinatorPostName'}
								errors={{}}
								stateData={coordinatorData}
								placeholder="Coordinator Post Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>

							<TextInput
								feildName={'coordinatorName'}
								errors={{}}
								stateData={coordinatorData}
								placeholder="Coordinator Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '15px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>Title</strong>
						</div>
						<div class="col-md-12">
							<TextInput
								feildName={'title'}
								errors={{}}
								stateData={state}
								placeholder="Add Title"
								type={'text'}
								isValidte={false}
								onHandleChange={onHandleTextChange}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '30px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>Message</strong>
						</div>
						<div class="col-md-12">
							<TextArea
								feildName={'message'}
								onHandleChange={onHandleTextChange}
								errors={{}}
								stateData={state}
								// iconClassName={'fas fa-percentage'}
								placeholder={'Add Message'}
								isValidte={false}
								row={15}
								customWidth={true}
								enterWidth={'180%'}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '30px'
						}}
					>
						<Button
							customClause={false}
							btnBackColor={Colors.DARK_SEA_GREEN_THEME}
							width="150%"
							buttonClass="customButton"
							btnName="Send"
							loaderBmargin={5}
							stateData={state}
							marginTop={20}
							onHandleSubmit={onSubmit}
							margin={'0 auto'}
							textColor={'white'}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<React.Fragment>
			<SnackBar msg={msg} />
			<div>
				<h2 className="h1-responsive font-weight-bold my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
					{' '}
					Message To Manager{' '}
				</h2>
			</div>
			<br />
			<br />

			{isLoadingManager ? <Spinner /> : cards}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		name: state.setUserReducer.users.unique_name,
		role: state.setUserReducer.users.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MessageManager);

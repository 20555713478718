import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general';
import Colors from '../../utils/app_constants/colors_constants';
import SnackBar from '../../components/SnackBar/SnackBar';
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = ['Name', 'Post Name', 'Staff Post', 'Class', 'Actions'];

let renderFields = ['name', 'postName', 'staffPost', 'className'];

const ViewStaffCoordinatorRenew = props => {
	const themeColor = useContext(ThemeContext);

	const [accountId, setAccountId] = useState(props.name);
	const [accountType, setAccountType] = useState(props.role);

	const [coordinatorData, setCoordinatorData] = useState([]);
	const [state, setState] = useState({
		postId: ''
	});
	const [modalData, setModalData] = useState([]);
	const [enabled, setEnabled] = useState('');
	const [id, setId] = useState('');
	const [status, setStatus] = useState('');
	const [msg, setSnackMsg] = useState('');

	const [searchURL, setSearchURL] = useState(
		`/api/StaffListCoordinator/?pageNumber=1&pageSize=10&`
	);

	const [disableBPage, setdisableBPage] = useState(true);
	const [customPageSize, setCustomPageSize] = useState('10');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumber, setPageNumber] = useState([]);
	const [disablepage, setdisablepage] = useState(false);
	const [pagination, setPaginationData] = useState({});

	const [
		isLoading,
		fetchData,
		setFetchData,
		setIsLoading
	] = httpWithRequiredSetData(searchURL, [], data => {
		let pageNumber = [];
		for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
			pageNumber.push(i);
		}
		setPageNumber(pageNumber);
		setCoordinatorData(data['staffList']);

		setPaginationData(data['paginationMetadata']);
	});

	const handleClickNextFrwd = () => {
		console.log('pagelength: ', pageNumber.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumber.length) {
			setdisablepage(true);
		} else {
			setCurrentPage(prevState => prevState + 1);
			console.log(currentPage);

			setdisableBPage(false);
		}
	};

	useEffect(() => {
		console.log('Do something after counter has changed', currentPage);
		setIsLoading(true);
		props.sharedActions
			.getDataWithoutDispatch(
				general.condtionalServicesViewStaffCoordinator(
					state['postId'],
					currentPage,
					customPageSize
				)
			)
			.then(success => {
				setCoordinatorData(success['staffList']);
				setIsLoading(false);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);
			})
			.catch(error => {
				setIsLoading(false);
				setCoordinatorData([]);
			});
	}, [currentPage, state['postId'], customPageSize]);

	const handleClickNext = event => {
		setCurrentPage(Number(event.target.id));
		setdisableBPage(false);
		setdisableBPage(false);
	};
	const handleClickNextBack = () => {
		if (currentPage === 1) {
			setdisableBPage(true);
			setdisablepage(false);
		} else {
			setCurrentPage(prevState => prevState - 1);
		}
	};

	const onChangePageSize = e => {
		if (e.target.value !== 'Page Entries') {
			setCustomPageSize(e.target.value);
			setCurrentPage(1);
		}
	};

	const onHandleSelectChange = e => {
		if (e.target.value !== 'Complain Type' && e.target.value !== 'Status') {
			const target = e.target;
			setState(prevState => ({
				...prevState,
				[target.name]: target.value
			}));
		}
	};

	const onEditClick = id => {
		onTakeItem(id);
		setEnabled(false);
	};

	const onTakeItem = itemId => {
		setModalData([]);
		let getData = coordinatorData.filter(item => item.id === itemId);

		setModalData(getData);
		setId(getData[0].id);
		setState(prevState => ({
			...prevState,
			status: getData[0].status
		}));
	};

	const onHandleSubmit = e => {
		e.preventDefault();

		modalData[0].status = state['status'];
		modalData[0].accountType = accountType;
		modalData[0].accountId = accountId;

		props.sharedActions
			.editPatchRecordWithoutDispatch(
				'/api/StudentComplains/',
				id,
				modalData[0]
			)
			.then(success => {
				coordinatorData.forEach((item, index) => {
					if (item.id === id) {
						coordinatorData.splice(index, 1, modalData[0]);
					}
				});
				setEnabled(false);
				setSnackMsg('Edit Record Successfully!');
				props.snackbar();
			})
			.catch(err => {});
	};

	const onClickDelete = id => {
		setIsLoading(true);

		props.sharedActions
			.deleteRecordWithoutDispatchWithoutSlash(
				'/api/DeleteGradeCoordinator/?id=',
				id
			)
			.then(success => {
				// if (success.status === 200) {
				let getNewFilterArray = coordinatorData.filter(item => item.id !== id);
				setSnackMsg('Remove Successfully!');
				setCoordinatorData(getNewFilterArray);
				setIsLoading(false);
				props.snackbar();
				// }
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
			});
	};

	const PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor
					}}
					scope="row"
				>
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${themeColor.themeColors.primaryColor}`,
							padding: 2,
							borderColor: themeColor.themeColors.primaryColor
						}}
					>
						{item[item1]}
					</td>
				))}
				<td
					className="displayElemet"
					style={{
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeColor.themeColors.primaryColor
					}}
				>
					<a href="#" data-toggle="tooltip" title="Delete!">
						<button
							onClick={() => onEditClick(item.id)}
							data-toggle="modal"
							data-target="#myModal1"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								background: 'none',
								marginLeft: 5,
								marginBottom: 5
							}}
						>
							<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
						</button>
					</a>
				</td>
			</tr>
		));

	return (
		<div id="div1">
			<SnackBar msg={msg} />
			<div class="modal" id="myModal1">
				<div class="modal-dialog">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
								borderTopRightRadius: 18,
								background: themeColor.themeColors.primaryColor
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Confirm
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<div>
								<div style={{ textAlign: 'center' }}>
									<h4>Are you sure ?</h4>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly'
									}}
								>
									<div>
										<button
											// disabled={this.state.enabled}
											data-dismiss="modal"
											onClick={() => onClickDelete(id)}
											type="button"
											class="btn btn-primary buttonAppear"
											style={{background: themeColor.themeColors.primaryColor}}
										>
											Yes
										</button>
									</div>
									<div>
										<button
											data-dismiss="modal"
											// disabled={this.state.enabled}
											type="button"
											class="btn btn-primary buttonAppear"
											style={{background: themeColor.themeColors.primaryColor}}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="modal" id="myModal">
				<div class="modal-dialog">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
								borderTopRightRadius: 18,
								background: themeColor.themeColors.primaryColor
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Details
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<form onSubmit={onHandleSubmit}>
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<fieldset disabled={!enabled}>
												<SelectionInput
													newFlag="customWidth"
													useFlag={false}
													feildName={'status'}
													selectName={'Status'}
													onHandleChange={e => onHandleSelectChange(e)}
													errors={{}}
													optionsArrys={
														state['complainType'] === 'Exclusion'
															? general.statusExclusion
															: general.statusNonExclusion
													}
													selectedText={'Status'}
													stateData={state}
													// iconClassName={'fas fa-building'}
													optionType="static"
													successflag={'successflag'}
													isValidte={false}
													// editUse={'staffEdit'}
												/>
											</fieldset>
											<br />
											<button
												disabled={enabled}
												onClick={() => setEnabled(true)}
												type="button"
												class="btn btn-primary buttonAppear"
												style={{background: themeColor.themeColors.primaryColor}}
											>
												Edit
											</button>
											<button
												style={{ marginLeft: 5, background: themeColor.themeColors.primaryColor }}
												disabled={!enabled}
												type="submit"
												class="btn btn-primary buttonAppear"
											>
												Save
											</button>
										</div>
									))}
							</form>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								style={{ background: '#00435D' }}
								class="btn btn-danger"
								data-dismiss="modal"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
			<div style={{ textAlign: 'right' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
							{' '}
							Grade Coordinator Details{' '}
						</h2>
					</div>
					<div class="displayElemet">
						<div
							style={{
								marginTop: 40,
								display: 'flex',
								flexDirection: 'column'
							}}
						>
							<Pagination
								disableBPage={disableBPage}
								disablepage={disablepage}
								currentPage={currentPage}
								pageNumber={pageNumber}
								handleClickNext={handleClickNext}
								handleClickNextBack={handleClickNextBack}
								handleClickNextFrwd={handleClickNextFrwd}
							/>
							<div
								style={{
									width: 'fit-content',
									marginTop: -34,
									display: 'flex',
									justifyContent: 'flex-end',
									marginLeft: 'auto'
								}}
							>
								<SelectionInput
									selectName="Entries"
									selectFont={14}
									selectFontWeight={'bold'}
									newFlag="customWidth"
									feildName="customPageSize"
									selectedText={'Page Entries'}
									optionsArrys={['10', '20', '30', '40', '50']}
									errors={{}}
									isValidte={false}
									stateData={{
										customPageSize: customPageSize
									}}
									optionType="static"
									onHandleChange={onChangePageSize}
									useFlag={false}
								/>
							</div>
						</div>
					</div>
				</div>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {pagination['totalPages']}
					</span>
				</div>
			</div>
			<br />
			{isLoading ? (
				<Spinner />
			) : coordinatorData.length < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
							<tr class="vendorListHeading">
								<th scope="col">#</th>
								{tHead.map(item =>
									item === 'Actions' ? (
										<th
											className="displayElemet"
											style={{
												border: `1px solid ${themeColor.themeColors.primaryColor}`,
												borderColor: Colors.WHITE
											}}
											scope="col"
										>
											{item}
										</th>
									) : (
										<th
											style={{
												border: `1px solid ${themeColor.themeColors.primaryColor}`,
												borderColor: Colors.WHITE
											}}
											scope="col"
										>
											{item}
										</th>
									)
								)}
							</tr>
						</thead>
						<tbody>{bodyRendering(coordinatorData)}</tbody>
					</table>
				</div>
			)}

			<div class="text-center text-md-right displayElemet">
				<label for="name" class="" onClick={() => PrintContent('div1')}>
					<i
						className="fas fa-print fa-2x animated fadeIn"
						style={{ marginRight: 4 }}
					/>
					Print
				</label>
			</div>
			<div class="text-center text-md-right">
				<strong>{props.name}</strong>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStaffCoordinatorRenew);

import React, { Component } from "react";
import SnackBar from "../../components/SnackBar/SnackBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ClassWiseActions from "../../Actions/ClassWiseSearch";
import * as sharedActions from "../../Actions/sharedActions";
import ProgressBarComponent from "./ProgressBarComponent";
import $ from "jquery";
import ThemeContext from "../../context/themeContext/ThemeContext";
import { Pie } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import GraphGeneralLayout from "../../components/Graphs/GraphGeneralLayout/GraphGeneralLayout2.jsx";

class SubStudentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      modalData: null,
      sections: [],
      sectionName: "",
      className: "",
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      select: [],
      handler: [],
      check: true,
      currentSession: "",
      male: "0",
      female: "0",
      total: "0",
      apiSession: "- / -",
      apiSection: "-",
      apiClass: "-",
      GraphData: {
        datasets: [
          {
            // barPercentage: 0.4,
            // minBarLength: 2,
            label: "Course Average",
            data: [2, 2, 2],
            backgroundColor: [
              "#dfeaea",
              "#ea766c",
              "#abe2b9",
              "#adbceb",
              "#be9e9d",
              "#9d5b4d",
              "#e4d86b",
              "#9ecf54",
              "#e4d86b",
              "#ea766c",
              "#abe2b9",
              "#adbceb",
            ],
            borderColor: [
              "#abe2b9",
              // '#ea766c',
              // '#adbceb',
              // '#be9e9d',
              // '#9d5b4d',
              // '#e4d86b',
              // '#9ecf54',
              // '#e4d86b'
            ],
            // borderWidth: 2
          },
        ],
        labels: ["BIO", "ICT", "MATH", "SOCIAL", "PHYS", "CHEM", "ENG", "ISL"],
        options: {
          showLines: true,
        },
      },
      lineGraph: true,
      pieGraph: false,
      barGraph: false,
      showGraphs: false
    };
  }
  onGraphChange = (e) => {
    debugger;
    if (e.target.value == "Line") {
      this.setState({
        lineGraph: true,
        pieGraph: false,
        barGraph: false,
      });
    } else if (e.target.value == "Pie") {
      this.setState({
        lineGraph: false,
        pieGraph: true,
        barGraph: false,
      });
    } else if (e.target.value == "Bar") {
      this.setState({
        lineGraph: false,
        pieGraph: false,
        barGraph: true,
      });
    }
  };
  onSelectSectionChange = (e) => {
    console.log(e.target.value);

    const sections = this.props.Document;
    let check = sections.filter((item) => item.classId == e.target.value);
    console.log(check);

    this.setState({
      sections: check,
      className: e.target.value,
    });
  };
  onSelectStudentChange = (e) => {
    console.log(e.target.value);

    this.setState({
      sectionName: e.target.value,
    });
  };
  onSelectSessionChange = (e) => {
    console.log(e.target.value);

    this.setState({
      currentSession: e.target.value,
    });
    console.log(this.state);
  };
  onHandleSubmit = (e) => {
    this.props.sharedActions
      .getAllData(
        `/api/Census/?classId=${this.state.className}&sessionId=${this.state.currentSession}&sectionId=${this.state.sectionName}`,
        this.props.ClassWiseActions.setAllClassWiseInRedux,
        this.props.ClassWiseActions.updateClassWiseFlag
      )
      .then((success) => {
        this.setState({
          isLoading: false,
          check: false,
          // msg: true,
          errorMsg: false,
          male: this.state.adminData.Male,
          female: this.state.adminData.Female,
          total: this.state.adminData.Total,
          apiSection: this.state.adminData.section,
          apiSession: this.state.adminData.currentSession,
          apiClass: this.state.adminData.className,
        });
        let graphData = { ...this.state.GraphData };
        // let getSubjectName = this.state.adminData.studentClass.map((item) =>
        //   item.className.toUpperCase()
        // );

        // let getMarks = this.state.adminData.studentClass.map((item) => item.age);
        debugger;
        graphData["datasets"][0]["data"] = [this.state.adminData.Male, this.state.adminData.Female, this.state.adminData.Total];
        graphData["labels"] = ['Male', "Female", "Total"];
        this.setState({
          GraphData: graphData,
          showGraphs: true
        });
      })
      .catch((err) => {
        // this.props.CensusActions.updateCensusFlag(false);
        this.setState({
          isLoading: false,
          check: true,
          msg: false,
          errorMsg: true,
          male: "0",
          female: "0",
          total: "0",
          apiSection: "-",
          apiSession: "- / -",
          apiClass: "-",
        });

        console.log(err);
      });
    console.log(this.state);
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.adminData !== nextProps.AllTransportData) {
      return {
        adminData: nextProps.AllTransportData,
      };
    }
    return null;
  }
  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
    $('[data-toggle="tooltip"]').tooltip();
  }
  Message = (msg, color, fontSize, flag) => {
    if (flag) {
      return (
        <p style={{ fontSize: fontSize }} class={`text-${color}`}>
          {msg}
        </p>
      );
    }
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  render() {
    const { data } = this.props;
    const { session } = this.props;
    const { adminData } = this.state;
    const { skill, subview, lineGraph, pieGraph, barGraph } = this.state;
    console.log("Data he Data", adminData);
    return (
      <div>
        <div className="page-header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2>Search&nbsp;</h2>
            </div>
            <div>
              <h3>
                <small class="text-muted">Gender Wise</small>
              </h3>
            </div>
          </div>
        </div>
        <div class="row no-print" style={{ justifyContent: "center" }}>
          <div class="col-md-4" style={{ marginTop: 20 }}>
            <div class="md-form mb-0">
              <label for="email" class="">
                Session's
              </label>
              <select
                onChange={this.onSelectSessionChange}
                class="custom-select"
                name="sectionName"
              >
                <option selected={this.state.msg ? true : false}>
                  Select Current Session..
                </option>
                {session &&
                  session.map((item, index) => (
                    <option value={`${item.sessionId}`}>
                      {item.currentSession}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div class="col-md-4" style={{ marginTop: 20 }}>
            <div class="md-form mb-0">
              <label for="email" class="">
                Class Name
              </label>
              <select
                onChange={this.onSelectSectionChange}
                class="custom-select"
                name="studentId"
              >
                <option selected={this.state.msg ? true : false}>
                  Choose Class..
                </option>
                {data &&
                  data.map((item, index) => (
                    <option key={index} value={`${item.id}`}>
                      {item.className}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div class="col-md-4" style={{ marginTop: 20 }}>
            <div class="md-form mb-0">
              <label for="email" class="">
                Class Section
              </label>
              <select
                onChange={this.onSelectStudentChange}
                class="custom-select"
                name="sectionName"
              >
                <option selected={this.state.msg ? true : false}>
                  Choose Section...
                </option>
                {this.state.sections.map((item, index) => (
                  <option value={`${item.sectionId}`}>{item.section}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <br />
        <div class="text-center text-md-right no-print">
          {/* <a href="#" data-toggle="tooltip" title="Search!"> */}
          <ThemeContext.Consumer>
            {(context) => (
              <button
                id="firstbutton"
                onClick={this.onHandleSubmit}
                onMouseOver={this.btnTechHover()}
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  // border: "none",
                  marginLeft: 5,
                  background: context.themeColors.primaryColor,
                  color: "#FFF",
                  marginBottom: 5,
                }}
              >
                Search
                <i class="ml-2 fas fa-search"></i>
              </button>
            )}
          </ThemeContext.Consumer>
          {/* </a> */}
        </div>
        {this.Message("No Record Found!", "danger", 20, this.state.errorMsg)}
        <div class="">
          <img
            src={require("../../Assets/images/header.png")}
            style={{
              width: "100%",
              height: "150",
            }}
          />
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="md-form mb-0">
              <ProgressBarComponent percentage={this.state.male} />
              <label style={{ fontWeight: "bold", fontSize: 20 }}>
                Male Students
              </label>{" "}
            </div>
          </div>
          <div class="col-md-4">
            <div class="md-form mb-0">
              <ProgressBarComponent percentage={this.state.female} />
              <label style={{ fontWeight: "bold", fontSize: 20 }}>
                Female Students
              </label>{" "}
            </div>
          </div>
          <div class="col-md-4">
            <div class="md-form mb-0">
              <ProgressBarComponent percentage={this.state.total} />
              <label style={{ fontWeight: "bold", fontSize: 20 }}>
                Total Students
              </label>
            </div>
          </div>
        </div>
        {this.state.showGraphs ?
          <div class="mb-2">
            <br />
            <GraphGeneralLayout
              gradingScale={""}
              titleName={"Analysis"}
              titleIcon={"fas fa-chart-bar"}
              graphList={true}
              onGraphChange={this.onGraphChange}
              children={() => (
                <div class="row">
                  <div class="col-md-11">
                    {lineGraph ? (
                      <Line
                        height={90}
                        width={200}
                        data={this.state.GraphData}
                      />
                    ) : pieGraph ? (
                      <Pie
                        height={90}
                        width={200}
                        data={this.state.GraphData}
                      />
                    ) : barGraph ? (
                      <Bar height={100} data={this.state.GraphData} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            />

            <div
              class="text-center text-md-right displayElemet"
              style={{ marginBottom: 10 }}
            >
              {/* <label for="name" class="" onClick={() => this.PrintContent("div1")}> */}
              <label for="name" class="" onClick={() => window.print()}>
                <i
                  className="fas fa-print fa-2x animated fadeIn"
                  style={{ marginRight: 4 }}
                />
            Print
          </label>
            </div>
          </div>
          : null}

        {/* <div class="row">
                <div class="col-md-12">
                    <div class="md-form mb-0">
                        <fieldset style={{ border: "solid", borderColor: "#01AC8A", height: "auto" }}>
                            <legend class="scheduler-border">Information:</legend>
                            <div class="row" style={{ marginLeft: 80 }}>
                                <div class="col-md-4">
                                    <div class="md-form mb-0">
                                        <label style={{ fontWeight: "bold", marginRight: 5 }}>Male: </label> <label style={{ fontSize: 20 }}> {this.state.male} </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="md-form mb-0">
                                        <label style={{ fontWeight: "bold", marginRight: 5 }}>Female:</label> <label style={{ fontSize: 20 }}>{this.state.female}</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="md-form mb-0">
                                        <label style={{ fontWeight: "bold", marginRight: 5 }}>Total:</label> <label style={{ fontSize: 20 }}>{this.state.total}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="md-form mb-0">
                                        <label style={{ fontWeight: "bold", marginRight: 5 }}>Session: </label> <label style={{ fontSize: 20 }}> {this.state.apiSession} </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="md-form mb-0">
                                        <label style={{ fontWeight: "bold", marginRight: 5 }}>Section: </label> <label style={{ fontSize: 20 }}> {this.state.apiSection} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="md-form mb-0">
                                        <label style={{ fontWeight: "bold", marginRight: 5 }}>Class: </label> <label style={{ fontSize: 20 }}> {this.state.apiClass} </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    </div>
            </div> */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    ClassWiseActions: bindActionCreators(ClassWiseActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
    AllTransportData: state.setClassWiseReducer.Data,
    allTransportState: state.setClassWiseReducer,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubStudentComponent);

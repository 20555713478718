import React, { useEffect, useState } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../Actions/sharedActions";
import httpWithRequiredSetData from "../../hooks/Services/httpWithRequiredSetData";
import Spinner from "../../components/Spinner/Spinner";
import SnackBar from "../../components/SnackBar/SnackBar";
import Colors from "../../utils/app_constants/colors_constants";
import { useContext } from "react";
import ThemeContext from "../../context/themeContext/ThemeContext";

const ManagerPostDashboard = (props) => {
  const themeColor = useContext(ThemeContext);
  const [managerData, setManagerData] = useState([]);
  const [specificPostData, setSpecificPostData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [msg, setSnackMsg] = useState("");

  const [state, setState] = useState({
    accountId: props.name,
    accountType: props.role,
    errorMsg: "",
    postData: { staffId: "", name: "" },
    backColor: "",
  });

  const [isManagerLoading, managers] = httpWithRequiredSetData(
    `/api/GetManagerPost`,
    [],
    (data) => {
      setManagerData(data);
    }
  );

  useEffect(() => {
    if (state["backColor"] !== "") {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          backColor: "",
        }));
      }, 3000);
    }
    if (msg || state["errorMsg"]) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          errorMsg: false,
        }));
      }, 3000);
    }
  });

  const onTakeItem = (item) => {
    setModalData({});
    setModalData(item);
  };

  const onTakeItemDelete = (itemId) => {
    props.sharedActions
      .deleteRecordWithoutDispatchWithoutSlash("/api/Managers/", itemId)
      .then((success) => {
        let deleteData = specificPostData.filter((item) => item.id !== itemId);
        setSpecificPostData(deleteData);
        setSnackMsg("Record Deleted Successfully!");
        props.snackbar();
      })
      .catch((error) => {});
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (state.postData["name"] === "" || state.postData["staffId"] === "") {
      setState((prevState) => ({
        ...prevState,
        errorMsg: true,
      }));
    } else {
      const data = {
        name: state.postData["name"],
        staffId: state.postData["staffId"],
        postId: modalData.postId,
        post: modalData.postName,
        accountType: props.role,
        accountId: props.name,
      };

      props.sharedActions
        .simpleAddRequest("/api/Managers", data)
        .then((success) => {
          setSnackMsg("Added Manager Successfuly!");
          setState((prevState) => ({
            ...prevState,
            postData: { staffId: "", name: "" },
          }));
          props.snackbar();
          $("#myModal1").modal("hide");
        })
        .catch((error) => {
          setSnackMsg(error.response.data.Message);
          setState((prevState) => ({
            ...prevState,
            backColor: "#FF0000",
          }));
          props.snackbar();
        });
    }
  };

  const viewPost = (postId) => {
    setIsLoading(true);
    props.sharedActions
      .getDataWithoutDispatch(`/api/GetManagerByPostId/?postId=${postId}`)
      .then((success) => {
        setSpecificPostData(success);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSpecificPostData([])
      });
  };

  const getStaffId = (e) => {
    props.sharedActions
      .getDataWithoutDispatch(
        `/api/GetStaffIdByManager/?staffId=${e.target.value}`
      )
      .then((success) => {
        setState((prevState) => ({
          ...prevState,
          postData: success,
        }));
      })
      .catch((err) => {
        setState((prevState) => ({
          ...prevState,
          postData: { staffId: "", name: "" },
        }));
      });
  };

  const cards = (props) => (
    <div class="col-md-3">
      <div
        class="card shadowForBox"
        style={{
          backgroundColor: Colors.YELLOW,
          marginTop: "20px",
        }}
      >
        <div class="card-body text-center">
          <h5
            class="card-title font-weight-light"
            style={{ color: "black", height: 40 }}
          >
            {props.postName}
          </h5>
          <button
            class="buttonHover2"
            data-toggle="modal"
            data-target="#myModal1"
            onClick={() => onTakeItem(props)}
          >
            Assign
          </button>{" "}
          --{" "}
          <button
            class="buttonHover2"
            style={{ width: 70 }}
            data-toggle="modal"
            data-target="#myModalView"
            onClick={() => viewPost(props.postId)}
          >
            View
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <SnackBar msg={msg} backColor={state["backColor"]} />
      <div class="modal fade" id="myModalView">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content modal_content_custom">
            <div
              style={{
                background: themeColor.themeColors.primaryColor,
                borderBottomLeftRadius: 30,
                borderBottomRightRadius: 30,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
              }}
              class="modal-header modal_custom_header text-center"
            >
              <h4 style={{ color: "white" }} class="modal-title">
                <strong>View</strong>
              </h4>
              <button
                style={{ color: "white" }}
                type="button"
                class="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Post Name</th>
                    <th scope="col">Name</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Password</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">AccountId</th>
                    <th scope="col">AccountType</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <Spinner />
                  ) : specificPostData.length < 1 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "red",
                      }}
                    >
                      <strong>No Data</strong>
                    </div>
                  ) : (
                    specificPostData.map((item, index) => (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{item.post}</td>
                        <td>{item.name}</td>
                        <td>{item.userName}</td>
                        <td>{item.password}</td>
                        <td>{item.date}</td>
                        <td>{item.time}</td>
                        <td>{item.accountId}</td>
                        <td>{item.accountType}</td>
                        <td>
                          <a href="#" data-toggle="tooltip" title="Delete!">
                            <button
                              onClick={() => onTakeItemDelete(item.id)}
                              style={{
                                cursor: "pointer",
                                fontSize: 20,
                                border: "none",
                                background: "none",
                                marginLeft: 5,
                                marginBottom: 5,
                              }}
                            >
                              <i
                                style={{ color: "#D11A2A" }}
                                class="fas fa-trash-alt"
                              ></i>
                            </button>
                          </a>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog modal-lg">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  background: themeColor.themeColors.primaryColor,
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Add Manager
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <section class="mb-4">
                  <div
                    class="row"
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      class="col-md-9 mb-md-0 mb-5"
                      style={{
                        padding: 0,
                        borderRadius: 15,
                        width: "auto",
                      }}
                    >
                      <form id="contact-form" name="contact-form">
                        <div class="row">
                          <div class="col-md-6" style={{ marginTop: 20 }}>
                            <div class="md-form mb-0">
                              <label for="name" class="font-weight-bold">
                                Admin Name
                              </label>
                              <input
                                value={props.name}
                                readOnly={true}
                                type="text"
                                name="AdminName"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-md-6" style={{ marginTop: 20 }}>
                            <div class="md-form mb-0">
                              <label for="name" class="font-weight-bold">
                                Admin Type
                              </label>
                              <input
                                value={props.role}
                                readOnly={true}
                                type="text"
                                name="AdminType"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-md-6" style={{ marginTop: 20 }}>
                            <div class="md-form mb-0">
                              <label for="email" class="font-weight-bold">
                                Staff Id
                              </label>
                              <input
                                placeholder="Enter Staff Id"
                                type="number"
                                name="staffId"
                                className="form-control check"
                                onChange={getStaffId}
                              />
                            </div>
                          </div>
                          {state.postData["staffId"] !== "" ? (
                            <React.Fragment>
                              <div class="col-md-6" style={{ marginTop: 20 }}>
                                <div class="md-form mb-0">
                                  <label for="name" class="font-weight-bold">
                                    Staff Name
                                  </label>
                                  <input
                                    value={state.postData["name"]}
                                    readOnly={true}
                                    type="text"
                                    name="staffName"
                                    class="form-control"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6" style={{ marginTop: 20 }}>
                                <div class="md-form mb-0">
                                  <label for="email" class="font-weight-bold">
                                    Post Name
                                  </label>
                                  <input
                                    type="text"
                                    name="postName"
                                    value={modalData.postName}
                                    className="form-control check"
                                    readOnly={true}
                                  />
                                </div>
                              </div>

                              <div class="col-md-6" style={{ marginTop: 20 }}>
                                <div class="md-form mb-0">
                                  <label for="email" class="font-weight-bold">
                                    Post For
                                  </label>
                                  <input
                                    type="text"
                                    name="postName"
                                    value={modalData.postFor}
                                    className="form-control check"
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </div>
                        <br />
                        <br />
                        {state.errorMsg ? (
                          <p style={{ color: "red", textAlign: "center" }}>
                            Please fill the form properly
                          </p>
                        ) : (
                          ""
                        )}
                        <div class="text-right text-md-right">
                          <button
                            style={{
                              background: themeColor.themeColors.primaryColor,
                            }}
                            onClick={onHandleSubmit}
                            class="buttonHover2"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                      <div class="status" />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div class="row justify-content-center">
              <div class="col-md-12 text-center">
                <img
                  src={require("../../Assets/New_Admission_Icon/manager schoolbusyellow.svg")}
                  class="img-fluid"
                  alt="Responsive image"
                  height="50"
                  width="50"
                />
                <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                  Manager Dashboard
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isManagerLoading ? (
        <Spinner />
      ) : managerData.length < 1 ? (
        <div
          style={{ display: "flex", justifyContent: "center", color: "red" }}
        >
          <strong>No Data</strong>
        </div>
      ) : (
        <div class="row justify-content-center  animated slideInUp">
          {managerData.map((item, index) => cards(item))}
        </div>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    name: state.setUserReducer.users.unique_name,
    role: state.setUserReducer.users.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerPostDashboard);

import React, { useState } from "react";
import FormLayoutWrapper from "../../../../components/HOC/FormLayoutWrapper";
import SelectionInput from "../../../../components/SelectionInput/SelectionInput";
import RowWrapper from "../../../../components/style/RowWrapper";
import CustomLabel from "../../../../components/Label/CustomLabel";
import Button from "../../../../components/Button";
import useHttp from "../../../../hooks/http";
import LoaderWrapper from "../../../../components/HOC/LoaderWrapper";
import * as sharedActions from "../../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import SnackBar from "../../../../components/SnackBar/SnackBar";
import { connect } from "react-redux";
import validateInput, {
  CHECK_PRIMARY_INFO,
  CHECK_CUT_OFF,
} from "../../../../validator/inputValidator";
import {
  dataFilteration,
  addDataMiddleware,
  isEmptyFieldInArrOBJ,
  dateConversion,
} from "../../../../utils/general/index";
import TextInput from "../../../../components/TextInput/TextInput";
import DatePicker from "react-date-picker";
import TimePicker from "../../../../components/TimePicker";
import { useContext } from "react";
import ThemeContext from "../../../../context/themeContext/ThemeContext";
const format = "h:mm a";
function CutOffPost(props) {
  const themeContext = useContext(ThemeContext);
  const [errors, setError] = useState({});
  const [cutOffTime, setCutOffTime] = useState("");
  const [cutOffDate, setCutOffDate] = useState(null);
  const [snackbarMessage, setMsg] = useState("");
  const [customLoader, setCustomLoader] = useState(false);

  const isValid = () => {
    const { errors, isValid } = validateInput(
      { cutOffTime, cutOffDate },
      CHECK_CUT_OFF
    );
    debugger;
    if (!isValid) {
      debugger;
      setError(errors);
    } else {
      setError(errors);
    }
    debugger;
    return isValid;
  };
  const onChangeDate = (date) => {
    debugger;
    setCutOffDate(date);
  };

  debugger;

  const onSubmit = () => {
    console.log(cutOffTime);
    console.log(cutOffDate);
    debugger;
    if (isValid()) {
      setCustomLoader(true);
      props.sharedAction
        .getDataWithoutDispatch(
          `/api/cutoffTimeValidation?date=${dateConversion(
            new Date(cutOffDate).toISOString()
          )}`
        )
        .then((success1) => {
          debugger;
          props.sharedAction
            .simpleAddRequest(`/api/SubstitutionCuttOffTime/`, {
              cutOffTime: new Date(cutOffTime).toLocaleTimeString(),
              date: dateConversion(new Date(cutOffDate).toISOString()),
              accountId: props.name,
              accountType: props.role,
            })
            .then((success) => {
              debugger;
              setCustomLoader(false);
              setCutOffTime("");
              setCutOffDate(null);
              setMsg("Successfully Submitted");
              props.snackbar();
            })
            .catch((error) => {
              if (error && error.response && error.response.status == 400) {
                setCustomLoader(false);
                //404-Error no understanding of 404 Error
                setMsg(error.response.data.Message);

                props.snackbar();
              }
            });
        })
        .catch((erro) => {
          debugger;
          setMsg(erro.response.data.Message);

          props.snackbar();
          setCustomLoader(false);
        });
    }
  };
  const onChange = (value) => {
    setCutOffTime(new Date(value && value._d).setSeconds(0));
  };
  return (
    <div>
      <SnackBar backColor={"#000"} msg={snackbarMessage} />

      <FormLayoutWrapper
        styleHead={true}
        formName={"CutOff Information"}
        borderColor={themeContext.themeColors.primaryColor}
        borderRadius={5}
        errorFlag={false}
        flagName={"error"}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <p>Cut off Date:</p>
            <DatePicker
              minDate={new Date()}
              style={{ borderRadius: 5 }}
              value={cutOffDate === "" ? null : cutOffDate}
              onChange={(e) => onChangeDate(e)}
            />
            {errors && errors["cutOffDate"] ? (
              <p style={{ color: "red", fontSize: 10 }}>Field Required</p>
            ) : (
              ""
            )}
          </div>
          <div>
            <p>Cut off Time:</p>
            <TimePicker
              className="newDateTimePicker"
              showSecond={false}
              placeholder={"Hr-Min-(AM/PM)"}
              name="cutOffTime"
              onChange={(e) => onChange(e, "cutOffTime")}
              format={format}
              use12Hours
              // inputReadOnly
            />
            {errors && errors["c : null utOffTime"] ? (
              <p style={{ color: "red", fontSize: 10 }}>Field Required</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <RowWrapper wrap={"wrap"}>
          <TextInput
            // marginTop={20}
            feildName={"accountId"}
            // inputLabelName={'Percentage Basic'}
            errors={{}}
            stateData={{ accountId: props.role, disabledField: true }}
            // iconClassName={'fas fa-percentage'}
            placeholder={"Account Id"}
            isValidte={false}
            disabledField={"disabledField"}
          />
          <TextInput
            // marginTop={20}
            feildName={"accountType"}
            // inputLabelName={'Percentage Basic'}
            errors={{}}
            stateData={{ accountType: props.name, disabledField: true }}
            // iconClassName={'fas fa-percentage'}
            isValidte={false}
            placeholder={"Account Type"}
            disabledField={"disabledField"}
          />
        </RowWrapper>
        <br />
        <br />
        <Button
          textColor={"#fff"}
          buttonClass={"btnStandard"}
          btnName="Submit"
          onHandleSubmit={onSubmit}
          stateData={{ isLoading: customLoader }}
        />
      </FormLayoutWrapper>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CutOffPost);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AssignmentActions from '../../Actions/Assigment';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import DatePicker from 'react-date-picker';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import moment from 'moment'

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddAssignment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentName: '',
			submissiondate: '',
			assignmentPaper: '',
			marks: '',
			subjectId: '',
			staffId: '',
			gradeName: 'No Data',
			gradeId: '',
			sessionId: '',
			classId: '',
			file: '',
            reminderDate: '',
            term: '',
            termList: [{id: '1st', name: '1st Term'},{id: '2nd', name: '2nd Term'},{id: '3rd', name: '3rd Term'}],
			select: [],
			sessions: [],
			Subject: [],
			Section: [],
            Staff: [],
            staffData: null,
			chooseFile: '',
			msg: false,
			errorMsg: false
		};
	}
	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			prevState.select !== nextProps.selectData ||
			prevState.sessions !== nextProps.selectSession
		) {
			return {
				select: nextProps.selectData,
				sessions: nextProps.selectSession
			};
		}
		return null;
    }
    
    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }

	componentDidMount() {

        this.getSimple(`DescriptiveReport?userName=${this.props.userData.unique_name}`, 'staffData')


		$('#chooseFile').bind('change', function() {
			var filename = $('#chooseFile').val();
			if (/^\s*$/.test(filename)) {
				$('.file-upload').removeClass('active');
				$('#noFile').text('No file chosen...');
			} else {
				$('.file-upload').addClass('active');
				$('#noFile').text(filename.replace('C:\\fakepath\\', ''));
			}
		});
		this.props.sharedActions
			.gAllData(
				'/api/classSectionGradeTeacher',
				this.props.AssignmentActions.getClassForAssignment
			)
			.then(success => {
				console.log(success);
			})
			.catch(error => {
				console.log(error);
			});
		this.props.sharedActions
			.gAllData(
				'/api/Session',
				this.props.AssignmentActions.getSessionForAssignment
			)
			.then(success => {})
			.catch(error => {});
	}
	handleChange = (date, state) => {
		this.setState({
			[state]: date
		});
		console.log(date);
	};

	handleSubmit = e => {
		e.preventDefault();
		const {
			gradeId,
			staffId,
			sectionId,
			subjectId,
			sessionId,
            classId,
            term,
			marks,
			submissiondate,
			chooseFile,
			assignmentName,
			assignmentPaper,
			reminderDate,

			staffData
		} = this.state;
		e.preventDefault();
		if (
			Object.is(subjectId, '') ||
			Object.is(gradeId, '') ||
			Object.is(reminderDate, '') ||
			Object.is(sessionId, '') ||
			Object.is(classId, '') ||
			Object.is(marks, '') ||
			Object.is(assignmentName, '') ||
			Object.is(assignmentPaper, '') ||
			Object.is(chooseFile, '') ||
			Object.is(submissiondate, '') ||
			Object.is(staffId, '') ||
			Object.is(term, '') ||
			Object.is(sectionId, '')
		) {
			this.setState({
				msg: "Please fill the form respectively!!"
			});
			this.props.snackbar();
		} else {
			console.log(this.state);
			// const { subjectPaper, examId, gradeId, subjectId, sessionId, classId, examTime, examDate, Duration, chooseFile, TotalMarks } = this.state;
			let file = this.state.chooseFile;
			let accountType = this.state.accountType;
			let accountId = this.state.accountId;
			accountId = this.props.userData.unique_name;
			accountType = this.props.userData.role;
			let data = new FormData();

			if(sectionId == 'all'){
				staffData.staffClassSection.map((item, ind) => {
					if(item.classId == classId){
						data.append('sectionId', item.sectionId);
					}
				})

			}else{
				data.append('sectionId', sectionId);

			}

			data.append('file', file);
			data.append('subjectId', subjectId);
			data.append('staffId', staffId);
			data.append('assignmentName', assignmentName);
			data.append('submissiondate', moment(submissiondate, "YYYY-MM-DD").format("MM/DD/YYYY"));
			data.append('reminderDate', moment(reminderDate, "YYYY-MM-DD").format("MM/DD/YYYY"));
			data.append('assignmentPaper', assignmentPaper);
			data.append('classId', classId);
			data.append('sessionId', sessionId);
			data.append('marks', marks);
			data.append('gradeId', gradeId);
			data.append('term', term);
			data.append('accountId', accountId);
			data.append('accountType', accountType);
			console.log('DATA Part', data);

			this.props.sharedActions
				.addRecord(
					'/api/Assignments',
					data,
					this.props.AssignmentActions.addAssignment
				)
				.then(success => {
					this.setState({
						assignmentName: '',
						assignmentPaper: '',
						staffId: '',
						chooseFile: '',
                        marks: '',
                        term: '',
						msg: "Record Added Successfully!!",
						isLoading: false
					});
					this.props.snackbar();
					// this.props.campusActions.updateCampusFlag(true);
				})
				.catch(error => {
					this.setState({
						msg: error.response ? error.response.data.Message : 'Error Occured!!',
						isLoading: false
					})
					this.props.snackbar();
					console.log(error);
				});
		}
		console.log(this.state);
	};
	inputData = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onSelectClassChange = e => {
		console.log(e.target.value);

		const Grade = this.state.select.grade;
		const Subject = this.state.select.subject;
		const Staff = this.state.select.staff;
		const Section = this.state.select.sections;
		let check = Grade.filter(item => item.classId == e.target.value);

		console.log(check);
		this.setState({
			gradeName: check[0] && check[0].gradeName,
			classId: e.target.value,
			gradeId: check[0] && check[0].gradeId
		});
		let checkTwo = Subject.filter(item => item.classId == e.target.value);

		this.setState({
			Subject: checkTwo
		});
		let checkThird = Staff.filter(item => item.classId == e.target.value);

		this.setState({
			Staff: checkThird
		});
		let checkFourth = Section.filter(item => item.classId == e.target.value);

		this.setState({
			Section: checkFourth
		});
	};
	onSelectSubjectChange = e => {
		console.log(e.target.value);

		this.setState({
			subjectId: e.target.value
		});
	};
	onSelectSessionChange = e => {
		console.log(e.target.value);

		this.setState({
			sessionId: e.target.value
		});
	};
	onChangeFile = e => {
		this.setState({
			chooseFile: e.target.files[0]
		});
		console.log(this.state.chooseFile);
	};
	
	onSectionChange = e => {
		const { classId, staffData  } = this.state
		console.log(e.target.value);
		let d = staffData.staffClassSection.filter((item, index) => {
			return classId == item.classId
		})
		console.log(d);
		
		this.setState({
			sectionId: e.target.value,
			staffId: d.length > 0 && d[0].staffId,
		});
	};
	onStaffChange = e => {
		console.log(e.target.value);

		this.setState({
			staffId: e.target.value
		});
	};
	render() {
		console.log('classSectionGrade', this.state.select);
		const { classes } = this.state.select;
		console.log('Classes ka data', classes);
		const { session } = this.state.sessions;
        console.log('Session ka data', session);
        const { staffData, classId, sectionId, staffId, subjectId, termList, term, submissiondate, reminderDate } = this.state
		return (
			<div className="container">
				<section class="mb-4">
				<ThemeContext.Consumer>
                    {(context) => (
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        Add Assignment
                    </h2>
                     )}
                </ThemeContext.Consumer>
				<SnackBar msg={this.state.msg} backColor={this.state.backColor} />

				 <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                <ThemeContext.Consumer>
                {(context) => (
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: context.themeColors.primaryColor,
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                      <LoaderWrapper isLoading={this.state.isLoading}>
							<form
								onSubmit={this.handleSubmit}
								id="contact-form"
								name="contact-form"
							>
								<div class="row">
									<div class="col-md-12">
										<div class="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
								<div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Name
											</label>
											<input
												
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
									</div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="name" class="">
												Admin Type
											</label>
											<input
												
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Class Name
											</label>
											<select
												
												onChange={this.onSelectClassChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Name...
												</option>
												{staffData &&
													staffData.classes.map((item, index) => (
														<option key={index} value={`${item.classId}`}>
															{item.className}
														</option>
													))}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Section Name
											</label>
											<select
												
												onChange={this.onSectionChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Section...
												</option>
												{classId && staffData.staffClassSection.map((item, index) => {
													return classId == item.classId && <option key={index} value={`${item.sectionId}`}>
														{item.section}
													</option>
												})}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Staff Name
											</label>
											<select
												
												onChange={this.onStaffChange}
												value={staffId}
												disabled
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Choose Staff...
												</option>
												{sectionId && staffData.staffClassSection.map((item, index) => {
													return classId == item.classId && sectionId == item.sectionId && <option key={index} value={`${item.staffId}`}>
														{item.staffName}
													</option>
												})}
											</select>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Grade Name
											</label>
											<input
												
												type="text"
												name="gradeId"
												class="form-control"
												readOnly={true}
												value={this.state.gradeName}
											/>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Sessions
											</label>
											<select
												onChange={this.onSelectSessionChange}
												class="custom-select"
												name="staffId"
												
											>
												<option selected={this.state.msg ? true : false}>
													Choose Session...
												</option>
												{session &&
													session.map((item, index) => (
														<option key={index} value={`${item.sessionId}`}>
															{item.currentSession}
														</option>
													))}
											</select>
										</div>
									</div>

                                    <div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="term" class="">
												Term
											</label>
											<select
												onChange={this.onHandleTextChange}
												class="custom-select"
												name="term"
												
											>
												<option selected={this.state.msg ? true : false}>
													Choose Term...
												</option>
												{
													termList.map((item, index) => (
														<option key={index} value={`${item.id}`}>
															{item.name}
														</option>
													))}
											</select>
										</div>
									</div>


									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="email" class="">
												Subject Name
											</label>
											<select
												
												onChange={this.onSelectSubjectChange}
												class="custom-select"
												name="staffId"
											>
												<option selected={this.state.msg ? true : false}>
													Subject Name...
												</option>
												{staffId && staffData.staffClassSection.map((item, index) => {
													return classId == item.classId && sectionId == item.sectionId && staffId == item.staffId && <option key={index} value={`${item.subjectId}`}>
														{item.subjectName}
													</option>
												})}
											</select>
										</div>
									</div>

									<div class="col-md-6" style={{ marginTop: 20 }}>
                                        <div className="md-form mb-0 " >
                                        <label for="submissiondate">
													Submission Date
                                            </label>
                                            <input class="custom-select" 
                                                type='date'
                                                value={submissiondate}
                                                name="submissiondate"
                                                onChange={this.onHandleTextChange}
                                            />
                                                    
						            </div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
                                        <div className="md-form mb-0 " >
                                        <label for="reminderDate">
												Reminder Date
                                            </label>
                                            <input class="custom-select" 
                                                type='date'
                                                value={reminderDate}
                                                name="reminderDate"
                                                onChange={this.onHandleTextChange}
                                            />
                                                    
						            </div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Assignment Name
											</label>
											<input
												
												type="text"
												name="assignmentName"
												class="form-control"
												value={this.state.assignmentName}
												placeholder="Assignment Name"
												onChange={this.onHandleTextChange}
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Marks
											</label>
											<input
												
												onChange={this.onHandleTextChange}
												type="text"
												name="marks"
												class="form-control"
												value={this.state.marks}
												placeholder="Marks"
											/>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 50 }}>
										<div class="md-form mb-0">
											<div class="file-upload">
												<div class="file-select">
													<div class="file-select-button" id="fileName">
														Choose File
													</div>
													<div class="file-select-name" id="noFile">
														No File Choosen
													</div>
													<input
														type="file"
														name="chooseFile"
														id="chooseFile"
														onChange={this.onChangeFile}
													/>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6" style={{ marginTop: 20 }}>
										<div class="md-form mb-0">
											<label for="name" class="">
												Description
											</label>
											<textarea
												
												onChange={this.onHandleTextChange}
												type="text"
												name="assignmentPaper"
												class="form-control"
												value={this.state.assignmentPaper}
												placeholder="Description about Assignment"
											/>
										</div>
									</div>
								</div>
								<br />
								<br />
								<div className="text-center text-md-center">
								<button type="submit" className="btns" style={{
                                                    background: context.themeColors.primaryColor,
                                            }}>
										Add
										<span
											style={{ marginBottom: 5 }}
											class={`${
												this.state.isLoading
													? 'spinner-border spinner-border-sm'
													: ''
											}`}
										></span>
									</button>
								</div>
							</form>
							</LoaderWrapper>
                            <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
                    </div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
		AssignmentActions: bindActionCreators(AssignmentActions, dispatch)
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
		selectData: state.setAssignmentReducer.select,
		selectSession: state.setAssignmentReducer.selectSession
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAssignment);

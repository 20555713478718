import React, { useState, Context, useEffect } from 'react';
import InboxContext from '../InboxContext/index';
import useHttp from '../../hooks/http';
import { connect } from 'react-redux';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';

function InboxGlobalContext(props) {
	const [inboxDataa, setInboxDataa] = useState([]);
	const [countInbox, setCountInbox] = useState('');
	const [account, setAccount] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [disableBPage, setdisableBPage] = useState(true);
	const [customPageSize, setCustomPageSize] = useState('10');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumber, setPageNumber] = useState([]);
	const [disablepage, setdisablepage] = useState(false);
	const [pagination, setPaginationData] = useState({});
	const [update, setUpdate] = useState('');

	useEffect(() => {
		props.sharedActions
			.getDataWithoutDispatch(
				`/api/MsgNotification/?userName=${props.name}&role=${props.role}&pageNumber=${currentPage}&pageSize=${customPageSize}`
			)
			.then(data => {
				setIsLoading(true);
				setInboxDataa(data['message']);
				setCountInbox(data.countInbox);
				setAccount(data.account);

				let pageNumber = [];
				for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}

				setPageNumber(pageNumber);
				setPaginationData(data['paginationMetadata']);
				setIsLoading(false);
			})
			.catch(err => {
				setAccount('');
				setInboxDataa([]);
				setCountInbox('');
				setIsLoading(false);

				setPageNumber([]);
				setPaginationData({});
			});
	}, [props.role, props.name, currentPage, customPageSize, update]);
	return (
		<InboxContext.Provider
			value={{
				Inbox: inboxDataa,
				setInboxDataa,
				countInbox: countInbox,
				setCountInbox,
				account: account,
				setAccount,
				pagination,
				setPaginationData,
				pageNumber,
				setPageNumber,
				disableBPage,
				setdisableBPage,
				disablepage,
				setdisablepage,
				customPageSize,
				setCustomPageSize,
				currentPage,
				setCurrentPage,
				isLoading,
				update,
				setUpdate
			}}
		>
			{props.children}
		</InboxContext.Provider>
	);
}

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InboxGlobalContext);

import React, { useContext } from "react";
import { MAIN_HEADING, SUB_HEADING, SMALL_HEADING } from "../../utils/general";
import ThemeContext from "../../context/themeContext/ThemeContext";

export default function CustomHeading(props) {
  const {
    type = "default",
    headingText = "default Text",
    className = "text-center generalHead m-5",
  } = props;
  const themeContext = useContext(ThemeContext);
  return type == "default" ? (
    <h4
      style={{ background: themeContext.themeColors.primaryColor }}
      className={className}
    >
      {headingText}
    </h4>
  ) : type === MAIN_HEADING ? (
    <h1
      style={{
        background: themeContext.themeColors.primaryColor,
        width: "fit-content",
      }}
      className={className}
    >
      {headingText}
    </h1>
  ) : type === SUB_HEADING ? (
    <h2
      style={{ background: themeContext.themeColors.primaryColor }}
      className={className}
    >
      {headingText}
    </h2>
  ) : type === SMALL_HEADING ? (
    <h4
      style={{ background: themeContext.themeColors.primaryColor }}
      className={className}
    >
      {headingText}
    </h4>
  ) : null;
}

import React, { useEffect, useRef, useState, useReducer } from "react";
import useHttp from "../../../hooks/http";
import Spinner from "../../../components/Spinner/Spinner";
import StaffEditReducer, {
  Types,
} from "../../../hooks/HookReducers/StaffEditReducer";
import RenderEditModules from "../../Admission/RenderEditModules";
import { General } from "../../../utils";
import useCustomHttp from "../../../hooks/Services/customHttp";
import { useContext } from "react";
import themeContext from "../../../context/themeContext/ThemeContext";

function StaffEditStepsRendering(props) {
  const themeColor = useContext(themeContext);
  const { match } = props;
  const [newState, dispatch] = useReducer(
    StaffEditReducer.staffReducer,
    StaffEditReducer.staffInitailState
  );
  const [isLoading, fetchData] = useCustomHttp(
    `/api/Staff/${match.params.id}`,
    dispatch,
    Types.ON_SET_COMPELETE_STATE,
    "getApiData",
    []
  );
  const main = useRef();
  const [errors, setErrors] = useState({});

  return isLoading ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <h1
        style={{ background: themeColor.themeColors.primaryColor }}
        className="generalHead"
      >
        Edit Sections
      </h1>
      <br />
      <div className="table-responsive">
        <table class="table table-hover">
          <thead
            style={{
              background: themeColor.themeColors.primaryColor,
              color: "white",
            }}
          >
            <tr>
              <th scope="col">#</th>
              <th style={{ textAlign: "center" }} scope="col">
                Form Sections
              </th>

              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <RenderEditModules
              {...props}
              id={match.params.id}
              btnTechHover={General.btnTechHover}
              moduleData={newState}
              redirectLink={`/dashboard/EditStaffFrom/`}
              stringConversion={General.stringConversion}
              studentImage={newState.imagePreviewUrl}
            />
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default StaffEditStepsRendering;

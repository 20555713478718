import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import Colors from '../../utils/app_constants/colors_constants';
import general from '../../utils/general';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import Spinner from '../../components/Spinner/Spinner';
import TextInput from '../../components/TextInput/TextInput';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar/SnackBar';
import themeContext from "../../context/themeContext/ThemeContext";

const TeacherMessageParent = props => {
    const themeColor = useContext(themeContext);

	const [msg, setSnackMsg] = useState('');
	const [update, setUpdate] = useState(false);
    
    const [mainData, setMainData ] = useState([])
    const [classData, setClassData ] = useState([])
    const [sectionData, setSectionData ] =useState([])
    const [sessionData, setSessionData] = useState([])
	const [studentData, setStudentData] = useState([])
	const [staffData, setStaffData ] = useState({})
	const [recipients, setRecipients] = useState([]);
	const [state, setState] = useState({
		ToparentId: '',
		title: '',
		message: '',
		sendAll: false,
		accountId: props.name,
		accountType: props.role,
        errorMsg: false,
        Form:'',
        classId: '',
        sectionId:'',
        sessionId:'',
        studentId:''
	});

    const [isClassSectionLoading, classSectionData] = httpWithRequiredSetData(
        '/api/ClassesSections',
        [],
        data => {
            setMainData(data)
            setClassData(data['classes']);
        }
    );

    const [isSessionLoading, sessions] = httpWithRequiredSetData(
        '/api/session',
        [],
        data => {
            setSessionData(data['session'])
        }
	);
	
	const [isLoadingSender, Sender] = httpWithRequiredSetData(
		`/api/StaffDashboard/?username=${props.name}`,
		[],
		data => {
			setStaffData(data);
		}
	);

	const onHandleTextChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));

        if(e.target.name === 'studentId'){
            var getStudent = general.dataFilteration(
                studentData,
                ['classId', 'name', 'parentId', 'sectionId', 'sessionId', 'status', 'studentId'],
                {
                    studentId: e.target.value
                }
			);
			setRecipients(getStudent)
        }
	};

	const onSubmit = e => {
		e.preventDefault();

		if (state['message'] === '' || recipients.length === 0) {
			setState(prevState => ({
				...prevState,
				errorMsg: true
			}));
		} else {
			setState(prevState => ({
				...prevState,
				isLoading: true
			}));

			const recipientsTemp = recipients.map(item => ({
				parentId: item.parentId,
				studentId: item.studentId,
				fromStaffId: staffData.staffId,
				title: state['title'],
				message: state['message'],
				accountId: state['accountId'],
				accountType: state['accountType']
			}));
			const data = {
				TeacherMsgParents: recipientsTemp
			};

			props.sharedActions
				.simpleAddRequest('/api/TeacherMsg', data)
				.then(success => {
					setSnackMsg('Message Sent Successfully!');
					props.snackbar();
					setState(prevState => ({
						classId: '',
						sectionId: '',
						sessionId:'',
						studentId: '',
						isLoading: false,
						ToparentId: '',
						title: '',
						message: '',
						accountId: props.name,
						accountType: props.role,
						errorMsg: false,
						sendAll: false
					}));
					setRecipients([]);
					setStudentData([])
					setSectionData([])
				})
				.catch(error => {
					if (error && error.response && error.response.status == 404) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							isLoading: false
						}));
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					} else if (error && error.response && error.response.status == 400) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							isLoading: false
						}));
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					}
				});
		}
	};

	useEffect(() => {
		if (state['errorMsg']) {
			setTimeout(() => {
				setState(prevState => ({
					...prevState,
					errorMsg: false
				}));
			}, 3000);
		}
    });

    useEffect(() => {
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesStudentParent(
                    state['classId'],
                    state['sectionId'],
                    state['sessionId'],
                    state['studentId']
                )
            )
            .then(success => {
                setStudentData(success);
            })
            .catch(error => {
				setStudentData([])
				setRecipients([])
				setState(prevState => ({
					...prevState,
					sendAll: false
				}));

            });
    }, [state['classId'], state['sectionId'], state['sessionId']]);
        
      const handleOptionChange = (e) => {

        const target = e.target
        setState(prevState => ({
            ...prevState,
			[target.name]: target.value,
			ToparentId: '',
			title: '',
			message: '',
			classId: '',
			sectionId:'',
			sessionId:'',
			studentId:''
		}));
		setSectionData([])
		setRecipients([])
		setStudentData([])
    };

    const onHandleClassChange = e => {
		const target = e.target;

        if (e.target.value !== 'Class') {

            let getSection = general.dataFilteration(
                classSectionData['gradeSection'],
                ['sectionId', 'section'],
                {
                    id: e.target.value
                }
            );

            setState(prevState => ({
                ...prevState,
                classId: target.value,
            }));

            setSectionData(getSection)
        }
	}
	
	const allStaff = e => {
		recipients.pop();
		setState(prevState => ({
			...prevState,
			sendAll: !state['sendAll']
		}));
		if (!state['sendAll']) {
			studentData.map(item => {
				recipients.push(item);
			});
		} else {
			setRecipients([]);
		}
		console.log(recipients);
    };

	const allStudent = (
        <div class='col-md-12'>
        <div class='col-md-6 d-flex'>
                <SelectionInput
                    feildName={'classId'}
                                                        selectName={'Class'}
                                                        onHandleChange={e => onHandleClassChange(e)}
                                                        errors={{}}
                                                        optionsArrys={classData}
                                                        selectedText={'Class'}
                                                        stateData={state}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'className'}
                                                        propertyId={'id'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />
                                                    <SelectionInput
                                                        feildName={'sectionId'}
                                                        selectName={'Section'}
                                                        onHandleChange={e => onHandleTextChange(e)}
                                                        errors={{}}
                                                        optionsArrys={sectionData}
                                                        selectedText={'Section'}
                                                        stateData={state}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'section'}
                                                        propertyId={'sectionId'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />

                                                    <SelectionInput
                                                        feildName={'sessionId'}
                                                        selectName={'Session'}
                                                        onHandleChange={e => onHandleTextChange(e)}
                                                        errors={{}}
                                                        optionsArrys={sessionData}
                                                        selectedText={'Session'}
                                                        stateData={state}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'currentSession'}
                                                        propertyId={'sessionId'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />
        </div>
        </div>		
	)

    const singleStudent = (
        <div class='col-md-12 ml-5'>
        <div class='col-md-6 d-flex'>
                <SelectionInput
                    feildName={'classId'}
                                                        selectName={'Class'}
                                                        onHandleChange={e => onHandleClassChange(e)}
                                                        errors={{}}
                                                        optionsArrys={classData}
                                                        selectedText={'Class'}
                                                        stateData={state}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'className'}
                                                        propertyId={'id'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />
                                                    <SelectionInput
                                                        feildName={'sectionId'}
                                                        selectName={'Section'}
                                                        onHandleChange={e => onHandleTextChange(e)}
                                                        errors={{}}
                                                        optionsArrys={sectionData}
                                                        selectedText={'Section'}
                                                        stateData={state}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'section'}
                                                        propertyId={'sectionId'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />

                                                    <SelectionInput
                                                        feildName={'sessionId'}
                                                        selectName={'Session'}
                                                        onHandleChange={e => onHandleTextChange(e)}
                                                        errors={{}}
                                                        optionsArrys={sessionData}
                                                        selectedText={'Session'}
                                                        stateData={state}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'currentSession'}
                                                        propertyId={'sessionId'}
                                                        successflag={'successflag'}
                                                        isValidte={false}
                                                    // editUse={'staffEdit'}
                                                    />

                                                <SelectionInput
                                                        feildName={'studentId'}
                                                        selectName={'Student'}
                                                        onHandleChange={e => onHandleTextChange(e)}
                                                        errors={{}}
                                                        optionsArrys={studentData}
                                                        selectedText={'Student'}
                                                        stateData={state}
                                                        // iconClassName={'fas fa-building'}
                                                        optionType="dynamicWithPropIdAndName"
                                                        property={'name'}
                                                        propertyId={'studentId'}
                                                        successflag={'successflag'}
														isValidte={false}
														isFeildDisabled = {state['sendAll']}
                                                    // editUse={'staffEdit'}
                                                    />

        </div>
{ studentData.length === 0 ? '' : <div class="col-md-12 text-center mt-3">
						<div class="custom-control custom-switch">
							<input
								type="checkbox"
								class="custom-control-input"
								id="customSwitch1"
								onChange={allStaff}
							/>
							<label class="custom-control-label" for="customSwitch1">
								<strong>Send to all parents</strong>
							</label>
						</div>
	</div> }
        </div>     
    )

	const cards = (
		<div class="card">
			<div
				class="card-header text-center"
				style={{ backgroundColor: '#d3d3d3', color: '#fff' }}
			>
				<h3>
					<strong>NEW MESSAGE</strong>
				</h3>
			</div>
			<div class="card-body">
				<div class="row ">
					<div class="col-md-12">
						{state.errorMsg ? (
							<p style={{ color: 'red', textAlign: 'center' }}>
								Please add recipients and a message
							</p>
						) : (
							''
						)}
					</div>
					<div class="col-md-1" style={{ paddingLeft: '25px' }}>
						<strong>To</strong>
					</div>
					<div class="col-md-11 border-bottom">
						<strong style={{ color: '#bbb' }}>Recipeints </strong>
						{recipients.map(item => (
							<div>
								<strong
									style={{
										backgroundColor: '#bbb'
									}}
								>
									{item.name}
								</strong>
							</div>
						))}
					</div>

					<div class="col-md-1"></div>
                    { singleStudent }
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							padding: '10px',
							marginTop: '15px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>From</strong>
						</div>
						<div class="col-md-6 d-flex">
							<TextInput
								feildName={'accountType'}
								errors={{}}
								stateData={state}
								placeholder="Coordinator Post Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>

							<TextInput
								feildName={'accountId'}
								errors={{}}
								stateData={state}
								placeholder="Coordinator Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '15px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>Title</strong>
						</div>
						<div class="col-md-12">
							<TextInput
								feildName={'title'}
								errors={{}}
								stateData={state}
								placeholder="Add Title"
								type={'text'}
								isValidte={false}
								onHandleChange={onHandleTextChange}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '30px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>Message</strong>
						</div>
						<div class="col-md-12">
							<TextArea
								feildName={'message'}
								onHandleChange={onHandleTextChange}
								errors={{}}
								stateData={state}
								// iconClassName={'fas fa-percentage'}
								placeholder={'Add Message'}
								isValidte={false}
								row={15}
								customWidth={true}
								enterWidth={'180%'}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '30px'
						}}
					>
						<Button
							btnBackColor={themeColor.themeColors.primaryColor}
							width="150%"
							buttonClass="customButton"
							btnName="Send"
							loaderBmargin={5}
							stateData={state}
							marginTop={20}
							onHandleSubmit={onSubmit}
							margin={'0 auto'}
							textColor={'white'}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<React.Fragment>
			<SnackBar msg={msg} />
			<div>
				<h2 className="h1-responsive font-weight-bold my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
					{' '}
					Message To Parent{' '}
				</h2>
			</div>
			<br />

			{isClassSectionLoading ? <Spinner /> : cards}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		name: state.setUserReducer.users.unique_name,
		role: state.setUserReducer.users.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TeacherMessageParent);

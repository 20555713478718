import { images } from "../../utils";
let hrLinks = [
  {
    imagePath: images.imgStaffDocs,
    linkPath: "/dashboard/StaffModule",
    subtitle: "Manage Staff Recruitment",
    title: "STAFF REGISTRATION FORM",
  },
  {
    imagePath: images.imgHrDevelopmentMain,
    linkPath: "/dashboard/HrDevelopmentDashboard",
    subtitle: "Manage Development and Training",
    title: "HR DEVELOPMENT AND TRAINING",
  },
  {
    imagePath: images.imgHRFrontDesk,
    linkPath: "/dashboard/FrontDeskDashboard",
    subtitle: "Staff Registration",
    title: "ONLINE REGISTRATION FORM",
  },
  {
    imagePath: images.imgDepartment,
    linkPath: "/dashboard/DepartmentDashboard",
    subtitle: "Manage Department Details",
    title: "DEPARTMENT MODULE",
  },
  {
    imagePath: images.imgPost,
    linkPath: "/dashboard/PostDashboard",
    subtitle: "Manage Post Activities",
    title: "POST MODULE",
  },
  {
    imagePath: images.imgPayRoll,
    linkPath: "/dashboard/PayrollDashboard",
    subtitle: "Manage Staff Payroll",
    title: "PAYROLL",
  },
  {
    imagePath: images.imgStudentCoordinator,
    linkPath: "/dashboard/StudentCoordinatorDashboard",
    subtitle: "Manage Student Coordinator",
    title: "STUDENT COORDINATOR",
  },
  {
    imagePath: images.imgStaffCoordinator,
    linkPath: "/dashboard/StaffCoordinatorDashboard",
    subtitle: "Manage Staff Coordinator",
    title: "STAFF COORDINATOR",
  },
  {
    imagePath: images.imgStaffCoordinator,
    linkPath: "/dashboard/StaffLeaveHRDashboard",
    subtitle: "Manage Staff Leave and Short Leave",
    title: "STAFF LEAVE",
  },
  {
    linkPath: "/dashboard/CMSAdminDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
  {
    linkPath: "/dashboard/BioMetricDashboard",
    headingImagePath: "",
    imagePath: images.imgBioMetric,
    subtitle: "Manage Biometric Attendance & Registeration",
    // color: "",
    title: "BIOMETRIC",
  },
];
let ownerLinks = [
  {
    isImage: false,
    smartImage: images.imageFSmart,
    moduleImage: images.imageModulefianance,
    imagePath: images.imgFDash1,
    headingImagePath: images.imgHRHeading,
    linkPath: "/dashboard/FinanceDashboard",
    subtitle: "Manage Finance Activities",
    color: "#932F8F",
    title: "FINANCE ",
  },
  {
    isImage: false,
    smartImage: images.imageStuSmart,
    moduleImage: images.imageModuleStudent,
    linkPath: "/dashboard/AdmissionDashboard",
    headingImagePath: images.imgHRHeading,
    imagePath: images.imgAdmissionDash,
    subtitle: "Manage Student Activities",
    color: "#65c8ce",
    title: "STUDENT",
  },
  {
    isImage: false,
    smartImage: images.imageLiSmart,
    moduleImage: images.imgLibraryManage,
    linkPath: "/dashboard/LibraryDashboard",
    headingImagePath: images.imgLibraryManage,
    imagePath: images.imgLibraryManage,
    subtitle: "Manage Library Activites",
    color: "#8CC63F",
    title: "LIBRARY",
  },
  {
    isImage: false,
    smartImage: images.imagehrSmart,
    moduleImage: images.imageModuleHr,
    linkPath: "/dashboard/HrDashboard",
    headingImagePath: images.imgHRHeading,
    imagePath: images.imgHr,
    subtitle: "Manage Human Resource Activites",
    color: "#314857",
    title: "HR",
  },
  {
    isImage: false,
    smartImage: images.imageAdminsmart,
    moduleImage: images.imageModuleAdministration,
    linkPath: "/dashboard/AdministrationDashboard",
    imagePath: images.imgAdDash,
    subtitle: "Manage Administration Activities",
    color: "#fcb01a",
    title: "ADMINISTRATION",
  },
  {

    isImage: false,
    smartImage: images.imageCCmart,
    moduleImage: images.imageModuleCommunication,
    linkPath: "/dashboard/CommunicationDashboard",
    imagePath: images.imgComDash1,
    subtitle: "Manage Communication Activities",
    color: "#61c4ae",
    title: "COMMUNICATION",
  },
  {

    isImage: false,
    smartImage: images.imagePsSmart,
    moduleImage: images.imageModulePastoral,
    linkPath: "/dashboard/PastoralDashboard",
    imagePath: images.imgPasDash1,
    subtitle: "Manage Pastoral Activities",
    color: "#287abf",
    title: "PASTORAL",
  },
  {
    isImage: false,
    smartImage: images.imageAdSmart,
    moduleImage: images.imageModuleAdmins,
    linkPath: "/dashboard/AddAdmin",
    headingImagePath: "",
    imagePath: images.imgAdminForm,
    subtitle: "Manage Different Admin",
    color: "#f04f24",
    title: "ADMINS",
  },
  {

    isImage: false,
    smartImage: images.imageMaSmart,
    moduleImage: images.imageModuleManagers,
    linkPath: "/dashboard/ManagerPostDashboard",
    headingImagePath: "",
    imagePath: images.imgManagerDashboard,
    subtitle: "Manage Different Manager",
    color: "#fbd80d",
    title: "MANAGERS",
  },

  {
    isImage: false,
    smartImage: images.imageAcadsmart,
    moduleImage: images.imageModuleAcademic,
    imagePath: images.imgAcDash,
    headingImagePath: images.imgFinanaceHeading,
    linkPath: "/dashboard/AcademicsDashboard",
    subtitle: "Manage Academic Activities",
    color: "#e0cf36",
    title: "ACADEMIC",
  },
  {
    isImage: false,
    smartImage: images.imageTTSmart,
    moduleImage: images.imageModuleTimetable,
    linkPath: "/dashboard/TimeTableModule",
    imagePath: images.imgAcademicTimeTable,
    subtitle: "Timetable Activities",
    color: "#8cc63f",
    title: "TIMETABLE",
  },
  {

    isImage: false,
    smartImage: images.imageSSSmart,
    moduleImage: images.imageModuleSystem,
    imagePath: images.imageSystemSmart,
    headingImagePath: images.imgSystemSmart,
    linkPath: "/dashboard/SystemSmartDashboard",
    subtitle: "Manage Academic Activities",
    color: "#9999CB",
    title: "SYSTEM",
  },
  //new enwe nwe
  // {
  // 	imagePath: images.imgFDash,
  // 	linkPath: '/dashboard/FinanceDashboard'
  // },
  // {
  // 	linkPath: '/dashboard/',
  // 	imagePath: images.imgBDash
  // },
  // {
  // 	linkPath: '/dashboard/AdministrationDashboard',
  // 	imagePath: images.imgAdDash
  // },
  // {
  // 	linkPath: '/dashboard/AcademicsDashboard',
  // 	imagePath: images.imgAcDash
  // },

  //end end end
  // {
  // 	linkPath: '/dashboard/',
  // 	imagePath: images.imgComDash
  // },
  // {
  // 	linkPath: '/dashboard/HrDashboard',
  // 	imagePath: images.imgHRDash
  // },
  // {
  // 	linkPath: '/dashboard/',
  // 	imagePath: images.imgPasDash
  // },
  // {
  // 	linkPath: '/dashboard/',
  // 	imagePath: images.imgSysDash
  // },
  // {
  // 	linkPath: '/dashboard',
  // 	imagePath: images.imgGMDash
  // },
  //New NEw NEw
  //   {
  //     linkPath: "/dashboard/CommunicationDashboard",
  //     imagePath: images.imgComDash1,
  //   },
  //   {
  //     linkPath: "/dashboard/HrDashboard",
  //     imagePath: images.imgHRDash1,
  //   },
  //   {
  //     linkPath: "/dashboard/PastoralDashboard",
  //     imagePath: images.imgPasDash1,
  //   },
  //   {
  //     linkPath: "/dashboard/",
  //     imagePath: images.imgSysDash1,
  //   },
  //   {
  //     linkPath: "/dashboard/LibraryDashboard",
  //     imagePath: images.imgLibraryManage,
  //   },
  //ENd NEw NEw New
];

let Census_Links = [
  {
    linkPath: "/dashboard/SearchStudent",
    headingImagePath: "",
    imagePath: images.imgViewStudentWise,
    subtitle: "Search students by session and class filters",
    color: "#65c8ce",
    title: "SEARCH STUDENTS",
  },
  {
    linkPath: "/dashboard/ClassWiseSearch",
    headingImagePath: "",
    imagePath: images.imgViewClassWise,
    subtitle: "Search number of students",
    color: "#65c8ce",
    title: "SEARCH GENDER WISE",
  },
  {
    linkPath: "/dashboard/AgeWiseSearch",
    headingImagePath: "",
    imagePath: images.imgViewAgeWise,
    subtitle: "Search number of students age wise",
    color: "#65c8ce",
    title: "SEARCH AGE WISE",
  },
  // {
  //   linkPath: "/dashboard/CategoryWiseSearch",
  //   headingImagePath: "",
  //   imagePath: images.imgManagerDashboard,
  //   subtitle: "Search student category wise",
  //      color: "#65c8ce",
  //   title: "SEARCH CATEGORY WISE",
  // },
  // {
  //   linkPath: "/dashboard/UserWiseSearch",
  //   headingImagePath: "",
  //   imagePath: images.imgManagerDashboard,
  //   subtitle: "Search student username wise",
  //      color: "#65c8ce",
  //   title: "USERNAME SEARCH",
  // },
  // {
  //   linkPath: "/dashboard/FamilyWiseSearch",
  //   headingImagePath: "",
  //   imagePath: images.imgManagerDashboard,
  //   subtitle: "Search student family wise",
  //   color: "#fcb01a",
  //   title: "SEARCH FAMILY WISE",
  // },
  // {
  //   linkPath: "/dashboard/StudentResultStatus",
  //   headingImagePath: "",
  //   imagePath: images.imgManagerDashboard,
  //   subtitle: "Search student result wise",
  //   color: "#fcb01a",
  //   title: "SEARCH RESULT WISE",
  // },
];
let Inventory_Links = [
  {
    linkPath: "/dashboard/AddCategory",
    headingImagePath: "",
    imagePath: images.imgAddCategory,
    subtitle: "Add Category for the Items",
    color: "#fcb01a",
    title: "ADD CATEGORY",
  },
  {
    linkPath: "/dashboard/ViewCategory",
    headingImagePath: "",
    imagePath: images.imgViewCategory,
    subtitle: "View Category of the Items",
    color: "#fcb01a",
    title: "VIEW CATEGORY",
  },

  {
    linkPath: "/dashboard/AddSuppliers",
    headingImagePath: "",
    imagePath: images.imgAddSupplier,
    subtitle: "Add Suppliers Details",
    color: "#fcb01a",
    title: "ADD SUPPLIERS",
  },
  {
    linkPath: "/dashboard/ViewSuppliers",
    headingImagePath: "",
    imagePath: images.imgViewSupplier,
    subtitle: "View Suppliers Details",
    color: "#fcb01a",
    title: "VIEW SUPPLIERS",
  },
  {
    linkPath: "/dashboard/AddItems",
    headingImagePath: "",
    imagePath: images.imgAddItem,
    subtitle: "Add Items",
    color: "#fcb01a",
    title: "ADD ITEMS",
  },
  {
    linkPath: "/dashboard/ViewItems",
    headingImagePath: "",
    imagePath: images.imgViewItem,
    subtitle: "View Items",
    color: "#fcb01a",
    title: "VIEW ITEMS",
  },

  {
    linkPath: "/dashboard/AddStock",
    headingImagePath: "",
    imagePath: images.imgAddStock,
    subtitle: "Add Items Stock",
    color: "#fcb01a",
    title: "ADD STOCK",
  },
  {
    linkPath: "/dashboard/ViewStock",
    headingImagePath: "",
    imagePath: images.imgViewStock,
    subtitle: "View Items Stock",
    color: "#fcb01a",
    title: "VIEW STOCK",
  },
  {
    linkPath: "/dashboard/AddStockUsage",
    headingImagePath: "",
    imagePath: images.imgAddStockUsage,
    subtitle: "Stock Usage Activities",
    color: "#fcb01a",
    title: "STOCK USAGE",
  },
  {
    linkPath: "/dashboard/ViewStockUsage",
    headingImagePath: "",
    imagePath: images.imgViewStockUsage,
    subtitle: "View Stock Usage Activities",
    color: "#fcb01a",
    title: "VIEW STOCK USAGE",
  },
  {
  linkPath: "/dashboard/CMSManagerDashboard",
  imagePath: images.imgAcademicCMS,
  color: "#8DC640",
  subtitle: "Competency Management System",
  title: "CMS",
}
];
let academicCMSLinks = [
  {
    linkPath: "/dashboard/TimeTableModule",
    imagePath: images.imgTT,
    subtitle: "TimeTable Generation",
    color: "#8DC640",
    title: "TIMETABLE",
  },
  {
    linkPath: "/dashboard/IndentDashboard",
    imagePath: images.imgAcademicIndent,
    subtitle: "Indent Information",
    color: "#8DC640",
    title: "INDENT FORM",
  },
  {
    subtitle: "Academic Teacher Evaulation",
    color: "#8DC640",
    title: "TEACHER EVALUATION",
    linkPath: "/dashboard/TeacherEvaluationDashboard",
    imagePath: images.imgAcademicTeacherEvaluation,
  },
  // {
  //   subtitle: "Manage Academic Calendar",
  //   color: "#8DC640",
  //   title: "ACADEMIC CALENDAR",
  //   linkPath: "/dashboard/AcademicCalendarMain",
  //   imagePath: images.imgAcademicCalendar,
  // },
  // {
  //   linkPath: "/dashboard/BioMetricDashboard",
  //   headingImagePath: "",
  //   imagePath: images.imgBioMetric,
  //   subtitle: "Manage Biometric Attendance & Registeration",
  //   color: "#8DC640",
  //   title: "BIOMETRIC",
  // },
];
let homeWorkDashlinks = [
  {
    subtitle: "Add Homework Details",
    color: "#fcb01a",
    title: "ADD HOMEWORK",
    linkPath: "/dashboard/AddHomeWork",
    imagePath: images.imgAddHomeWork,
  },
  {
    subtitle: "View Homework Details",
    color: "#fcb01a",
    title: "VIEW HOMEWORK",
    linkPath: "/dashboard/ViewHomeWork",
    imagePath: images.imgViewHomework,
  },
];
let academicSIDLinks = [
  {
    subtitle: "Student Skills Management",
    color: "#fcb01a",
    title: "STUDENT SKILLS",
    linkPath: "/dashboard/StudentSkillViewAdmin",
    imagePath: images.imgAcademicSkill,
  },
  {
    subtitle: "Student descriptive management",
    color: "#fcb01a",
    title: "STUDENT DESCRIPTIVE",
    linkPath: "/dashboard/StudentDescriptiveViewAdmin",
    imagePath: images.imgAcademicDescriptive,
  },
  {
    subtitle: "Admin Role Student Discipline",
    color: "#fcb01a",
    title: "STUDENT DISCIPLINE",
    linkPath: "/dashboard/AdminRoleStudentDiscipline",
    imagePath: images.imgViewSubDiscipline,
  },
  {
    subtitle: "Update Class Discipline",
    color: "#fcb01a",
    title: "CLASS DISCIPLINE",
    linkPath: "/dashboard/UpdateClassDiscipline",
    imagePath: images.imgClassDiscipline,
  },
  {
    subtitle: "Continouse Assessment",
    color: "#fcb01a",
    title: "CONTINUOUS ASSESSMENT MODULE",
    linkPath: "/dashboard/CADashboardAdmin",
    imagePath: images.imgCAImage,
  },
  {
    subtitle: "Student Progress Report",
    color: "#fcb01a",
    title: "STUDENT PROGRESS",
    linkPath: "/dashboard/StudentProgressReportDashboard",
    imagePath: images.DashboardStudentProgressReport,
  },
  {
    subtitle: "Leave Reports",
    color: "#fcb01a",
    title: "LEAVE",
    linkPath: "/dashboard/LeaveDashboard",
    imagePath: images.imgAcademicLeave,
  },
  {
    linkPath: "/dashboard/StudentAttendanceDashboard",
    headingImagePath: "",
    imagePath: images.imgStudentAttendance,
    subtitle: "Handle Student Attendance Details",
    color: "#fcb01a",
    title: "STUDENT ATTENDANCE",
  }
];
let caModuleLinks = [
  // {
  //   linkPath: "/dashboard/caPrimaryInfo",
  //   imagePath: images.imgCaPmTool,
  //   subtitle: "Primary Information",
  //   title: "PRIMARY INFO",
  // },
  {
    subtitle: "Continouse assessment View",
    title: "CA DETAILS",
    linkPath: "/dashboard/caSubToolView",
    imagePath: images.imgCAViewImage,
  },
];
let caModuleLinksAdmin = [
  {
    linkPath: "/dashboard/caPrimaryInfo",
    imagePath: images.imgCaPmTool,
    subtitle: "Primary Information",
    title: "PRIMARY INFO",
  },
  {
    subtitle: "Continouse assessment View",
    title: "CA DETAILS",
    linkPath: "/dashboard/caSubToolViewAdmin",
    imagePath: images.imgCAViewImage,
  },
];
let academicLmsLink = [
  {
    linkPath: "/dashboard/ViewHomeWork",
    imagePath: images.imgAcademicHomeWork,
    subtitle: "Homework Activities",
    color: "#66c8ce",
    title: "HOMEWORK",
  },
  {
    linkPath: "/dashboard/ViewAssignment",
    imagePath: images.imgAcademicAssignment,
    subtitle: "Assignment Activities",
    color: "#66c8ce",
    title: "ASSIGNMENT",
  },
  {
    linkPath: "/dashboard/ViewLessonPlan",
    imagePath: images.imgAcademicLesson,
    subtitle: "Lesson Plan Activities",
    color: "#66c8ce",
    title: "LESSON PLAN",
  },
  {
    linkPath: "/dashboard/ViewExamInfo",
    imagePath: images.imgAcademicExam,
    subtitle: "Exam Info Activities",
    color: "#66c8ce",
    title: "EXAM INFO",
  },
  {
    linkPath: "/dashboard/ViewExamDetails",
    imagePath: images.imgAcademicExam,
    subtitle: "Exam Activities",
    color: "#66c8ce",
    title: "EXAM",
  },
  {
    linkPath: "/dashboard/ViewSyllabus",
    imagePath: images.imgAcademicSyllabus,
    subtitle: "Syllabus Activities",
    color: "#66c8ce",
    title: "SYLLABUS",
  },
  {
    linkPath: "/dashboard/ViewQuizInfo",
    imagePath: images.imgAcademicQuiz,
    subtitle: "Quiz Info Activities",
    color: "#66c8ce",
    title: "QUIZ INFO",
  },
  {
    linkPath: "/dashboard/ViewQuiz",
    imagePath: images.imgAcademicQuiz,
    subtitle: "Quiz Activities",
    color: "#66c8ce",
    title: "QUIZ",
  },
  {
    linkPath: "/dashboard/ViewTestInfo",
    imagePath: images.imgAcademicTest,
    subtitle: "Short Test Info Activities",
    color: "#66c8ce",
    title: "SHORT TEST INFO",
  },
  {
    linkPath: "/dashboard/ViewTest",
    imagePath: images.imgAcademicTest,
    subtitle: "Short Test Activities",
    color: "#66c8ce",
    title: "SHORT TEST",
  },
  {
    linkPath: "/dashboard/ViewPastPapers",
    imagePath: images.imgAcademicPastPaper,
    subtitle: "Paper Activities",
    color: "#66c8ce",
    title: "PAPER",
  },
  {
    linkPath: "/dashboard/OnlineResourceDashboard",
    imagePath: images.imgAcademicOnlineResource,
    subtitle: "Online Resources",
    color: "#66c8ce",
    title: "Online Resources",
  },
];

let OnlineResourceLinks = [
  {
    subtitle: "Add Online Resources",
    color: "#66c8ce",
    title: "Add Online Resources",
    linkPath: "/dashboard/AddOnlineResource",
    imagePath: images.imgAcademicOnlineResource,
  },
  {
    subtitle: "View Online Resources",
    color: "#66c8ce",
    title: "View Online Resources",
    linkPath: "/dashboard/ViewOnlineResource",
    imagePath: images.imgAcademicOnlineResource,
  },
]
let OnlineResourceLinks_Staff = [
  // {
  //   subtitle: "Add Online Resources",
  //   color: "#66c8ce",
  //   title: "Add Online Resources",
  //   linkPath: "/dashboard/AddOnlineResource",
  //   imagePath: images.imgAcademicOnlineResource,
  // },
  {
    subtitle: "View Online Resources",
    color: "#66c8ce",
    title: "View Online Resources",
    linkPath: "/dashboard/ViewOnlineResource",
    imagePath: images.imgAcademicOnlineResource,
  },
]

let academicPlan = [
  {
    subtitle: "Class Activities",
    color: "#66c8ce",
    title: "CLASS",
    linkPath: "/dashboard/ClassDashboard",
    imagePath: images.imgAcademicClass,
  },
  {
    subtitle: "Section Activities",
    color: "#66c8ce",
    title: "SECTION",
    linkPath: "/dashboard/SectionDashboard",
    imagePath: images.imgAcademicSection,
  },
  {
    subtitle: "Session Activities",
    color: "#66c8ce",
    title: "SESSION",
    linkPath: "/dashboard/SessionDashboard",
    imagePath: images.imgAcademicSession,
  },
  {
    subtitle: "Subject Activities",
    color: "#66c8ce",
    title: "SUBJECT",
    linkPath: "/dashboard/SubjectDashboard",
    imagePath: images.imgAcademicSubject,
  },
]
let academicLinks = [
  {
    linkPath: "/dashboard/AcademicLMS",
    imagePath: images.imgAcademicLMS,
    color: "#66c8ce",
    subtitle: "Learning Management System",
    title: "LMS",
  },
  {
    linkPath: "/dashboard/AcademicCMS",
    imagePath: images.imgAcademicCMS,
    color: "#8cc63f",
    subtitle: "Competency Management System",
    title: "CMS ",
  },
  {
    linkPath: "/dashboard/AcademicSID",
    imagePath: images.imgAcademicSID,
    color: "#f04f24",
    subtitle: "Student Information System",
    title: "SID",
  },
  {
    linkPath: "/dashboard/AcademicDeanDashboard",
    imagePath: images.imgAcHead,
    color: "#E0CF36",
    subtitle: "Manager HODs Dashboard",
    title: "ACADEMIC HEAD",
  },
  {
    linkPath: "/dashboard/CMSAdminDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS OF ADMIN",
  },
  {
    linkPath: "/dashboard/AcademicsPlanDashboard",
    imagePath: images.imgAcademicPlan,
    color: "#66c8ce",
    subtitle: "Academic Plan dashboard modules",
    title: "Academic Plan",
  }
];

let timetableLinks = [
  {
    linkPath: "/dashboard/timeTable",
    imagePath: images.addTT,
  },
  {
    linkPath: "/dashboard/viewTimeTable",
    imagePath: images.viewTT,
  },
];
let LPWLinks = [
  {
    subtitle: "Add lesson per week data",
    title: "ADD LESSON PER WEEK",
    linkPath: "/dashboard/lessonperweek",
    imagePath: images.addLPW,
    color: "#8cc63f",

  },
  {
    color: "#8cc63f",
    subtitle: "View lesson per week data",
    title: "VIEW LESSON PER WEEK",
    linkPath: "/dashboard/viewlessonperweek",
    imagePath: images.viewLPW,
  },
];
let financeLinks = [
  {
    linkPath: "/dashboard/AccountsDashboard",
    imagePath: images.imgAccounts,
    title: "ACCOUNT MODULE",
    subtitle: "Manage Accounts Activities",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/BudgetDashboard",
    imagePath: images.imgBudgetDash,
    title: "BUDGET MODULE",
    subtitle: "Manager Budget Activities",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/CMSAdminDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

let AdminCMSDashboard_Link=[
  {
    linkPath: "/dashboard/IndentAdminDashboard",
    imagePath: images.imgAcademicIndent,
    subtitle: "Indent Information",
    color: "#8DC640",
    title: "INDENT FORM",
  },
  {
    linkPath: "/dashboard/AdminLeaveDashboard",
    imagePath: images.imgStaffLeave,
    subtitle: "Manage Staff Leave and Short Leave",
    color: "#8DC640",
    title: "STAFF LEAVE",
  },
  {
    linkPath: "/dashboard/ViewHrDevelopment",
    imagePath: images.imgHrDevelopmentMain,
    subtitle: "View Traning & Development",
    title: "STAFF TRAINING & DEVELOPMET",
  }
  
];
let AdminLeaves_Link=[
  {
    linkPath: "/dashboard/AddAdminLeave",
    imagePath: images.imgAddStaffLeave,
    subtitle: "Add Leave Application",
    color: "#8DC640",
    title: "ADD LEAVE",
  },
  {
    linkPath: "/dashboard/ViewAdminLeave",
    imagePath: images.imgViewStaffLeave,
    subtitle: "View Leave Application",
    color: "#8DC640",
    title: "VIEW LEAVE",
  },
  {
    linkPath: "/dashboard/AddAdminShortLeave",
    imagePath: images.imgAddStaffShortLeave,
    subtitle: "Add Short Leave Application",
    color: "#8DC640",
    title: "ADD SHORT LEAVE",
  },
  {
    linkPath: "/dashboard/ViewAdminShortLeave",
    imagePath: images.imgViewStaffShortLeave,
    subtitle: "View Short Leave Application",
    color: "#8DC640",
    title: "VIEW SHORT LEAVE",
  },
]
let Dean_Leaves_Link=[
  {
    linkPath: "/dashboard/AddDeanLeave",
    imagePath: images.imgAddStaffLeave,
    subtitle: "Add Leave Application",
    color: "#8DC640",
    title: "ADD LEAVE",
  },
  {
    linkPath: "/dashboard/ViewDeanLeave",
    imagePath: images.imgViewStaffLeave,
    subtitle: "View Leave Application",
    color: "#8DC640",
    title: "VIEW LEAVE",
  },
  {
    linkPath: "/dashboard/AddDeanShortLeave",
    imagePath: images.imgAddStaffShortLeave,
    subtitle: "Add Short Leave Application",
    color: "#8DC640",
    title: "ADD SHORT LEAVE",
  },
  {
    linkPath: "/dashboard/ViewDeanShortLeave",
    imagePath: images.imgViewStaffShortLeave,
    subtitle: "View Short Leave Application",
    color: "#8DC640",
    title: "VIEW SHORT LEAVE",
  },
];
let ManagerLeaves_Link=[
  {
    linkPath: "/dashboard/AddManagerLeave",
    imagePath: images.imgAddStaffLeave,
    subtitle: "Add Leave Application",
    color: "#8DC640",
    title: "ADD LEAVE",
  },
  {
    linkPath: "/dashboard/ViewManagerLeave",
    imagePath: images.imgViewStaffLeave,
    subtitle: "View Leave Application",
    color: "#8DC640",
    title: "VIEW LEAVE",
  },
  {
    linkPath: "/dashboard/AddManagerShortLeave",
    imagePath: images.imgAddStaffShortLeave,
    subtitle: "Add Short Leave Application",
    color: "#8DC640",
    title: "ADD SHORT LEAVE",
  },
  {
    linkPath: "/dashboard/ViewManagerShortLeave",
    imagePath: images.imgViewStaffShortLeave,
    subtitle: "View Short Leave Application",
    color: "#8DC640",
    title: "VIEW SHORT LEAVE",
  },
]
let HODLeaves_Link=[
  {
    linkPath: "/dashboard/AddHODLeave",
    imagePath: images.imgAddStaffLeave,
    subtitle: "Add Leave Application",
    color: "#8DC640",
    title: "ADD LEAVE",
  },
  {
    linkPath: "/dashboard/ViewHODLeave",
    imagePath: images.imgViewStaffLeave,
    subtitle: "View Leave Application",
    color: "#8DC640",
    title: "VIEW LEAVE",
  },
  {
    linkPath: "/dashboard/AddHODShortLeave",
    imagePath: images.imgAddStaffShortLeave,
    subtitle: "Add Short Leave Application",
    color: "#8DC640",
    title: "ADD SHORT LEAVE",
  },
  {
    linkPath: "/dashboard/ViewHODShortLeave",
    imagePath: images.imgViewStaffShortLeave,
    subtitle: "View Short Leave Application",
    color: "#8DC640",
    title: "VIEW SHORT LEAVE",
  },
]
let DeanLeaves_Link=[
  {
    linkPath: "/dashboard/AddDeanLeave",
    imagePath: images.imgAddStaffLeave,
    subtitle: "Add Leave Application",
    color: "#8DC640",
    title: "ADD LEAVE",
  },
  {
    linkPath: "/dashboard/ViewDeanLeave",
    imagePath: images.imgViewStaffLeave,
    subtitle: "View Leave Application",
    color: "#8DC640",
    title: "VIEW LEAVE",
  },
  {
    linkPath: "/dashboard/AddDeanShortLeave",
    imagePath: images.imgAddStaffShortLeave,
    subtitle: "Add Short Leave Application",
    color: "#8DC640",
    title: "ADD SHORT LEAVE",
  },
  {
    linkPath: "/dashboard/ViewDeanShortLeave",
    imagePath: images.imgViewStaffShortLeave,
    subtitle: "View Short Leave Application",
    color: "#8DC640",
    title: "VIEW SHORT LEAVE",
  },
]
let IndentManagerDashboard_Link=[
  {
    linkPath: "/dashboard/IndentManagerAdd",
    imagePath: images.imgAcademicIndent,
    title: "ADD INDENT",
    subtitle: "Add New Indent Requests",
    color: "#8DC640",
  },
  {
    linkPath: "/dashboard/IndentManagerView",
    imagePath: images.imgViewIndent,
    title: "VIEW INDENT",
    subtitle: "Manage Indent Forms",
    color: "#8DC640",
  },
];
let IndentHODDashboard_Link=[
  {
    linkPath: "/dashboard/IndentHODAdd",
    imagePath: images.imgAcademicIndent,
    title: "ADD INDENT",
    subtitle: "Add New Indent Requests",
    color: "#8DC640",
  },
  {
    linkPath: "/dashboard/IndentHODView",
    imagePath: images.imgViewIndent,
    title: "VIEW INDENT",
    subtitle: "Manage Indent Forms",
    color: "#8DC640",
  },
];
let IndentDeanDashboard_Link=[
  {
    linkPath: "/dashboard/IndentDeanAdd",
    imagePath: images.imgAcademicIndent,
    title: "ADD INDENT",
    subtitle: "Add New Indent Requests",
    color: "#8DC640",
  },
  {
    linkPath: "/dashboard/IndentDeanView",
    imagePath: images.imgViewIndent,
    title: "VIEW INDENT",
    subtitle: "Manage Indent Forms",
    color: "#8DC640",
  },
];
let IndentAdminDashboard_Link=[
  {
    linkPath: "/dashboard/IndentAdminAdd",
    imagePath: images.imgAcademicIndent,
    title: "ADD INDENT",
    subtitle: "Add New Indent Requests",
    color: "#8DC640",
  },
  {
    linkPath: "/dashboard/IndentAdminView",
    imagePath: images.imgViewIndent,
    title: "VIEW INDENT",
    subtitle: "Manage Indent Forms",
    color: "#8DC640",
  },
];
let Accountant_Dashboard_Links = [
  {
    linkPath: "/dashboard/AccountsDashboard",
    imagePath: images.imgAccounts,
    title: "ACCOUNT MODULE",
    subtitle: "Manage Accounts Activities",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
  {
    linkPath: "/dashboard/ViewHrDevelopment",
    imagePath: images.imgHrDevelopmentMain,
    subtitle: "View Traning & Development",
    title: "STAFF TRAINING & DEVELOPMET",
  }
  
];
let budget_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddBudget",
    imagePath: images.imgAssignBudget,
    title: "ADD BUDGET",
    subtitle: "Assign Budget for Items",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/ViewBudget",
    imagePath: images.imgViewBudget,
    title: "VIEW BUDGET",
    subtitle: "View Budget for Items",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/AddBudgetItems",
    imagePath: images.imgAddBudgetItems,
    title: "ADD BUDGET ITEMS",
    subtitle: "Add Budget Items",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/ViewBudgetItems",
    imagePath: images.imgViewBudgetItems,
    title: "VIEW BUDGET ITEMS",
    subtitle: "View Budget Items",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/SearchItemsBySession",
    imagePath: images.imgViewBudgetItems,
    title: "SEARCH ITEMS",
    subtitle: "Search Budget Items Session Wise",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/AccountLedger",
    imagePath: images.imgAccountLedg,
    title: "ACCOUNT LEDGER",
    subtitle: "View and Manage Accounts Ledger",
    color: "#8a3593",
  },
];
let budgetLinks = [
  {
    linkPath: "/dashboard/AddBudget",
    imagePath: images.imgAddIcon,
  },
  {
    linkPath: "/dashboard/ViewBudget",
    imagePath: images.imgViewIcon,
  },
];
let HrsmartLinks = [
  {
    linkPath: "/dashboard/SRCScreen",
    imagePath: images.imgHRSRM,
  },
  {
    linkPath: "/dashboard/HRDevAndTrainScreen",
    imagePath: images.imgHRDevTrain,
  },
  {
    linkPath: "/dashboard/FrontDeskOffScreen",
    imagePath: images.imgHRFrontDesk,
  },
  {
    linkPath: "/dashboard/DepartmentScreen",
    imagePath: images.imgHRDept,
  },
  {
    linkPath: "/dashboard/AssignPostScreen",
    imagePath: images.imgHRpost,
  },
  {
    linkPath: "/dashboard/Appraisal",
    imagePath: images.imgHRSalary,
  },
  {
    linkPath: "/dashboard/printStaffAdmissionForm",
    imagePath: images.imgHRStaffDoc,
  },
  {
    linkPath: "/dashboard/AddBudget",
    imagePath: images.imgHRVacany,
  },
  {
    linkPath: "/dashboard/AddBudget",
    imagePath: images.imgHrInterview,
  },
];
const timeTable_module_links = [
  {
    linkPath: "/dashboard/timetable",
    imagePath: images.imgAcademicTimeTable,
    color: "#8cc63f",
    subtitle: "Timetable Generation",
    title: "TIMETABLE",

  },
  {
    subtitle: "Timetable Details",
    title: "TIMETABLE VIEW",
    linkPath: "/dashboard/viewTimetable",
    imagePath: images.viewTT,
    color: "#8cc63f",
  },
  {
    subtitle: "Lession Per Week",
    title: "LESSON PER WEEK",
    linkPath: "/dashboard/LessonperweekScreen",
    imagePath: images.imgLPW,
    color: "#8cc63f",
  },
  {
    subtitle: "Add Off Time",
    title: "CUT OFF ",
    linkPath: "/dashboard/cutoffPost",
    imagePath: images.ttAddCutOffTime,
    color: "#8cc63f",
  },
  {
    subtitle: "View Off Time",
    title: "CUT OFF DETAILS",
    linkPath: "/dashboard/CutOffView",
    imagePath: images.ttViewCutOffTime,
    color: "#8cc63f",
  },
  {
    subtitle: "Substitution Period Details ",
    title: "SUBSTITUTION PERIOD",
    linkPath: "/dashboard/SubstitutionPeriodList",
    imagePath: images.ttSubstitutionPeriodList,
    color: "#8cc63f",
  },
  {
    subtitle: "Substitution Result",
    title: "APPLY SUBSTITUTION",
    linkPath: "/dashboard/ApplySubstitution",
    imagePath: images.ttApplySubstitution,
    color: "#8cc63f",
  },
  {
    subtitle: "Substitution Periority",
    title: "SUBSTITUTION PRIORITY",
    linkPath: "/dashboard/SubstitutionPeriority",
    imagePath: images.ttApplySubstitution,
    color: "#8cc63f",
  },
  {
    subtitle: "Substitution Periority List",
    title: "SUBSTITUTION PRIORITY DETAILS",
    linkPath: "/dashboard/SubstitutionPeriodList",
    imagePath: images.ttApplySubstitution,
    color: "#8cc63f",
  },
  {
    subtitle: "Periority List",
    title: "ALL PRIORITY",
    linkPath: "/dashboard/allPriorityList",
    imagePath: images.ttAllPriorityList,
    color: "#8cc63f",
  },
  {
    subtitle: "Daily Substitution",
    title: "DAILY SUBSTITUTION RECORD",
    linkPath: "/dashboard/DailySubstitutionRecord",
    imagePath: images.ttAllDailySubstitution,
    color: "#8cc63f",
  },
  {
    subtitle: "View Timetable Of Staff",
    title: "STAFF TIMETABLE",
    linkPath: "/dashboard/StaffTimeTableByCoordinator",
    imagePath: images.imgStaffTimeTable,
    color: "#8cc63f",
  },
  {
    subtitle: "View Consolidated Timetable",
    title: "CONSOLIDATED TIMETABLE",
    linkPath: "/dashboard/ConsolidatedTimeTable",
    imagePath: images.imgConsolidatedTimeTable,
    color: "#8cc63f",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
  {
    subtitle: "Manage Academic Calendar",
    color: "#8DC640",
    title: "ACADEMIC CALENDAR",
    linkPath: "/dashboard/AcademicCalendarMain",
    imagePath: images.imgAcademicCalendar,
  },

];

const timeTable_module_links2 = [
  // {
  //   linkPath: "/dashboard/TimeTableModule",
  //   imagePath: images.imgTT,
  //   subtitle: "TimeTable Generation",
  //       color:"#9999CB",
  //   title: "TIMETABLE",
  // },
  // {
  //   subtitle: "Timetable Details",
  //   title: "TIMETABLE VIEW",
  //   linkPath: "/dashboard/viewTimetable",
  //   color:"#9999CB",
  //   imagePath: images.viewTT,
  // },
  // {
  //   subtitle: "Lession Per Week",
  //   title: "LESSON PER WEEK",
  //   linkPath: "/dashboard/LessonperweekScreen",
  //   color:"#9999CB",
  //   imagePath: images.imgLPW,
  // },
  // {
  //   subtitle: "Add Off Time",
  //   title: "CUT OFF ",
  //   linkPath: "/dashboard/cutoffPost",
  //   color:"#9999CB",
  //   imagePath: images.ttAddCutOffTime,
  // },
  // {
  //   subtitle: "View Off Time",
  //   title: "CUT OFF DETAILS",
  //   linkPath: "/dashboard/CutOffView",
  //   color:"#9999CB",
  //   imagePath: images.ttViewCutOffTime,
  // },
  // {
  //   subtitle: "Substitution Period Details ",
  //   title: "SUBSTITUTION PERIOD",
  //   linkPath: "/dashboard/SubstitutionPeriodList",
  //   color:"#9999CB",
  //   imagePath: images.ttSubstitutionPeriodList,
  // },
  // {
  //   subtitle: "Substitution Result",
  //   title: "APPLY SUBSTITUTION",
  //   linkPath: "/dashboard/ApplySubstitution",
  //   color:"#9999CB",
  //   imagePath: images.ttApplySubstitution,
  // },
  // {
  //   subtitle: "Substitution Periority",
  //   title: "SUBSTITUTION PRIORITY",
  //   linkPath: "/dashboard/SubstitutionPeriority",
  //   color:"#9999CB",
  //   imagePath: images.ttApplySubstitution,
  // },
  // {
  //   subtitle: "Substitution Periority List",
  //   title: "SUBSTITUTION PRIORITY DETAILS",
  //   linkPath: "/dashboard/SubstitutionPeriodList",
  //   color:"#9999CB",
  //   imagePath: images.ttApplySubstitution,
  // },
  // {
  //   subtitle: "Periority List",
  //   title: "ALL PRIORITY",
  //   linkPath: "/dashboard/allPriorityList",
  //   color:"#9999CB",
  //   imagePath: images.ttAllPriorityList,
  // },
  // {
  //   subtitle: "Daily Substitution",
  //   title: "DAILY SUBSTITUTION RECORD",
  //   linkPath: "/dashboard/DailySubstitutionRecord",
  //   color:"#9999CB",
  //   imagePath: images.ttAllDailySubstitution,
  // },
  // {
  //   subtitle: "View Timetable Of Staff",
  //   title: "STAFF TIMETABLE",
  //   linkPath: "/dashboard/StaffTimeTableByCoordinator",
  //   color:"#9999CB",
  //   imagePath: images.imgStaffTimeTable,
  // },
  // {
  //   subtitle: "View Consolidated Timetable",
  //   title: "CONSOLIDATED TIMETABLE",
  //   linkPath: "/dashboard/ConsolidatedTimeTable",
  //   color:"#9999CB",
  //   imagePath: images.imgConsolidatedTimeTable,
  // },
];
const Communication_Dashboard_Links = [
  {
    linkPath: "/dashboard/ListOfRecipients",
    imagePath: images.imgCommuncationCoordinator,
    title: "COMMUNICATION COORDINATOR",
    subtitle: "Manage Communication Coordinator",
  },
  // {
  //   title: "PARENT MANAGEMENT",
  //   subtitle: "Parent Details",

  //   linkPath: "/dashboard/ParentPortalManagerDashboard",
  //   imagePath: images.imgParentManager,
  // },
  {
    title: "BULLETIN",
    subtitle: "Bulletin Details",

    linkPath: "/dashboard/AddBulletin",
    imagePath: images.imgBulletin,
  },
  {
    title: "CALENDER",
    subtitle: "Calender Details",
    linkPath: "/dashboard/CEventCalender",
    imagePath: images.imgEventCalender,
  },
  {
    title: "PORTAL COORDINATOR",
    subtitle: "Manage Portal Coordinator",
    linkPath: "/dashboard/UsersList",
    imagePath: images.imgPortalCoordinator,
  },
  {
    linkPath: "/dashboard/CMSAdminDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  }
];

const Communication_Staff_Dashboard_Links = [
  {
    subtitle: "Compose New Message",
    color: "#61c4ae",
    title: "NEW MESSAGE TO COORDINATOR",
    linkPath: "/dashboard/StaffMessage",
    imagePath: images.imgNewMessage,
  },
  {
    subtitle: "Compose New Message",
    color: "#61c4ae",
    title: "NEW MESSAGE TO PARENT",
    linkPath: "/dashboard/TeacherMessageParent",
    imagePath: images.imgNewMessage,
  },
  {
    subtitle: "View Messages",
    color: "#61c4ae",
    title: "INBOX WITH COORDINATOR",
    linkPath: "/dashboard/Inbox",
    imagePath: images.imgInbox,
  },
  {
    subtitle: "View Messages",
    color: "#61c4ae",
    title: "INBOX WITH PARENTS",
    linkPath: "/dashboard/ParentTeacherInbox",
    imagePath: images.imgInbox,
  },
];

const Communication_Parent_Dashboard_Links = [
  {
    subtitle: "Compose New Message",
    color: "#61c4ae",
    title: "NEW MESSAGE TO STAFF",
    linkPath: "/dashboard/ParentMessageTeacher",
    imagePath: images.imgNewMessage,
  },
  {
    subtitle: "View Messages",
    color: "#61c4ae",
    title: "INBOX WITH COORDINATOR",
    linkPath: "/dashboard/Inbox",
    imagePath: images.imgInbox,
  },
  {
    subtitle: "View Messages",
    color: "#61c4ae",
    title: "INBOX WITH STAFF",
    linkPath: "/dashboard/ParentTeacherInbox",
    imagePath: images.imgInbox,
  },
];

const FamilyTreeLink = [
  {
    linkPath: "/dashboard/AddParent",
    imagePath: images.imgAddParentResgitration,
    title: "ADD PARENT",
    subtitle: "Create Parent username and password",
  },
  {
    linkPath: "/dashboard/RegisterStudentToParent",
    imagePath: images.imgRegisterParentResgitration,
    title: "ASSIGN CHILD",
    subtitle: "Register Student to Parent",
  },
]
const ParentPortalManager_Links = [
  {
    linkPath: "/dashboard/FamilyTreeDashboard",
    imagePath: images.imgFamilyTree,
    title: "Family Tree",
    subtitle: "Create Parent and Assign child",
  },
  // {
  //   linkPath: "/dashboard/AddParent",
  //   imagePath: images.imgAddParentResgitration,
  //   title: "ADD PARENT",
  //   subtitle: "Create Parent username and password",
  // },
  {
    linkPath: "/dashboard/UpdateParentRegistration",
    imagePath: images.imgUpdateParentResgitration,
    title: "UPDATE PARENT",
    subtitle: "Update Parent username and password",
  },
  // {
  //   linkPath: "/dashboard/RegisterStudentToParent",
  //   imagePath: images.imgRegisterParentResgitration,
  //   title: "ASSIGN CHILD",
  //   subtitle: "Register Student to Parent",
  // },
  {
    linkPath: "/dashboard/SearchParentByChild",
    imagePath: images.imgSearchParentRegistration,
    title: "SEARCH PARENT",
    subtitle: "Search Parent by Child",
  },
];
const StaffModules_Links = [
  {
    linkPath: "/dashboard/staff",
    imagePath: images.addstaff,
    title: "ADD STAFF",
    subtitle: "Add Staff Details Into System",
  },
  {
    linkPath: "/dashboard/ViewStaff",
    imagePath: images.viewstaff,
    title: "VIEW STAFF",
    subtitle: "Manage Staff Details",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  }
];

const Student_Admin_Admission_Link=[
  {
    title: "ADD ADMISSION",
    subtitle: "Add Students Details Into System",
    linkPath: "/dashboard/addForm",
    imagePath: images.imgStudentAdd,
    color: "#65c8ce",

  },
  {
    title: "VIEW ADMISSIONS",
    subtitle: "Details Of Enrolled Students",
    linkPath: "/dashboard/viewAddForm",
    imagePath: images.imgViewAddForm,
    color: "#65c8ce",

  }
] 
const Student_Admission_Links = [
  {
    title: "ADD ADMISSION",
    subtitle: "Add Students Details Into System",
    linkPath: "/dashboard/addForm",
    imagePath: images.imgStudentAdd,
    color: "#65c8ce",

  },
  {
    title: "VIEW ADMISSIONS",
    subtitle: "Details Of Enrolled Students",
    linkPath: "/dashboard/viewAddForm",
    imagePath: images.imgViewAddForm,
    color: "#65c8ce",

  }
  ,{
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  }
];
const Admission_Smart_Links = [
  {
    title: "STUDENT ADMISSION",
    subtitle: "Student Admission Process",
    linkPath: "/dashboard/StudentAdmissionAdminDashboard",
    color: "#65c8ce",
    imagePath: images.imgadmis,
  },
  //   {
  //     title: "MANAGERS",
  //     subtitle: "Manage Different Managers",
  //     linkPath: "/dashboard/ManagersDashboard",
  //     imagePath: images.imgManagerDashboard,
  //   },
  {
    title: "ENTRY EXAMS",
    subtitle: "Manage Exam Content",
    linkPath: "/dashboard/AdminExamEntryDashboard",
    color: "#65c8ce",
    imagePath: images.imgEntryExam,
  },
  {
    title: "PARENT REGISTRATION",
    subtitle: "Register student to Parents",
    linkPath: "/dashboard/ParentPortalManagerDashboard",
    color: "#65c8ce",
    imagePath: images.imgParentRegistration,
  },
  {
    imagePath: images.imgStudentRecordManagement,
    linkPath: "/dashboard/SearchStudent",
    subtitle: "Manage Student Record",
    color: "#65c8ce",
    title: "STUDENT RECORDS",
  },
  {
    title: "ONLINE REGISTRATION FORM",
    subtitle: "Student Registration",
    linkPath: "/dashboard/AdminStudentFrontDesk",
    color: "#65c8ce",
    imagePath: images.imgFrontDeskStudent,
  },
  {
    linkPath: "/dashboard/CensusDashboard",
    headingImagePath: "",
    imagePath: images.imgCensusModule,
    subtitle: "Handle Census Module",
    color: "#65c8ce",
    title: "CENSUS MODULE",
  },
  
{
  linkPath: "/dashboard/CMSAdminDashboard",
  imagePath: images.imgAcademicCMS,
  color: "#8DC640",
  subtitle: "Competency Management System",
  title: "CMS",
}
];
const Finance_Manager_Link = [
  {
    linkPath: "/dashboard/AddFinanceManager",
    imagePath: images.imgAddManager,
  },
  {
    linkPath: "/dashboard/ViewFinanceManager",
    imagePath: images.imgViewManager,
  },
];
const Mangers_Link = [
  {
    linkPath: "/dashboard/AddManager",
    imagePath: images.imgAddManager,
  },
  {
    linkPath: "/dashboard/ViewManager",
    imagePath: images.imgViewManager,
  },
];
const Accounts_Dashboard_Links = [
  {
    linkPath: "/dashboard/FeesDashboard",
    imagePath: images.imgFeeCollectionUpdate,
    title: "FEES MODULE",
    subtitle: "Manage Student Fees",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/BillingDashboardAdmin",
    imagePath: images.imgBillingMain,
    title: "BILLING MODULE",
    subtitle: "Manage Billing Activities",
    color: "#8a3593",
  },
];
const Fees_Dashboard_Links = [
  {
    title: "NEW FEE TYPE",
    subtitle: "Add New Fee Type for Classes",
    linkPath: "/dashboard/FeeTypeDashboard",
    color: "#8a3593",
    imagePath: images.imgAddFeeType,
  },
  // {
  //   title: "ADD FEE TYPE",
  //   subtitle: "Add Fee Type for Classes",
  //   linkPath: "/dashboard/AddFeeTypeNew",
  //   color: "#8a3593",
  //   imagePath: images.imgAddFeeType,
  // },
  // {
  //   title: "VIEW FEE TYPE",
  //   subtitle: "View Fee Type for Classes",
  //   linkPath: "/dashboard/ViewFeeTypeNew",
  //   color: "#8a3593",
  //   imagePath: images.imgViewFeeType,
  // },
  // {
  //   title: "FEES COLLECTION",
  //   subtitle: "Fees Collection of Students",
  //   linkPath: "/dashboard/FeesCollectionNew",
  //   color: "#8a3593",
  //   imagePath: images.imgFeeCollection,
  // },
  // {
  //   title: "MONTHLY FEE LIST",
  //   subtitle: "View Monthly Fee List of Students",
  //   linkPath: "/dashboard/MonthlyFeeListNew",
  //   color: "#8a3593",
  //   imagePath: images.imgFeeView,
  // },
  // {
  //   title: "FEE REPORTS",
  //   subtitle: "View Fee Report of Students",
  //   linkPath: "/dashboard/FeeReportDashboard",
  //   color: "#8a3593",
  //   imagePath: images.imgFeeReport,
  // },
  {
    title: "Invoice Booking",
    subtitle: "Add Invoice Booking",
    linkPath: "/dashboard/invoicebooking",
    color: "#8a3593",
    imagePath: images.imgInvoiceBooking,
  },
  {
    title: "Advance Revenue",
    subtitle: "View Advance Revenue",
    linkPath: "/dashboard/advanceRevenue",
    color: "#8a3593",
    imagePath: images.imgInvoiceBooking,
  },
  {
    title: "Receipt Booking",
    subtitle: "View Receipt Booking",
    linkPath: "/dashboard/receiptBooking",
    color: "#8a3593",
    imagePath: images.imgInvoiceBooking,
  },
  {
    title: "Voucher Collection",
    subtitle: "View Voucher Collection",
    linkPath: "/dashboard/feevouchers",
    color: "#8a3593",
    imagePath: images.imgInvoiceBooking,
  },
  {
    title: "Fee Accounts",
    subtitle: "View Fee Accounts",
    linkPath: "/dashboard/FeeAccounts",
    color: "#8a3593",
    imagePath: images.imgInvoiceBooking,
  },
  // {
  //   linkPath: "/dashboard/CMSManagerDashboard",
  //   imagePath: images.imgAcademicCMS,
  //   color: "#8DC640",
  //   subtitle: "Competency Management System",
  //   title: "CMS",
  // },
];
const Fees_Type_Links = [
  {
    title: "Add Academic Fee Type",
    subtitle: "Add Academic Fee Type for Classes",
    linkPath: "/dashboard/AcademicFeeType",
    color: "#8a3593",
    imagePath: images.imgAddFeeType,
  },
  {
    title: "View Academic Fee Type",
    subtitle: "View Academic Fee Type for Classes",
    linkPath: "/dashboard/ViewAcademicFeeType",
    color: "#8a3593",
    imagePath: images.imgAddFeeType,
  },
  {
    title: "Add Other Fee Type",
    subtitle: "Add Other Fee Type for Classes",
    linkPath: "/dashboard/OtherFeeType",
    color: "#8a3593",
    imagePath: images.imgViewFeeType,
  },
  {
    title: "View Other Fee Type",
    subtitle: "Add Other Fee Type for Classes",
    linkPath: "/dashboard/ViewOtherFeeType",
    color: "#8a3593",
    imagePath: images.imgFeeCollection,
  },
];

let FinanceFeeReportLinks = [
  {
    linkPath: "/dashboard/DateWiseFeeReport",
    imagePath: images.imgFeeReportDateWise,
    title: "DATE WISE FEE",
    subtitle: "Date Wise Fee Report",
    color: "#8a3593",
  }
  ,{
    linkPath: "/dashboard/AccountsDashboard",
    imagePath: images.imgFeeReportMonthly,
    title: "MONTHLY FEE",
    subtitle: "Monthly Fee Report",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/AccountsDashboard",
    imagePath: images.imgFeeReportYearly,
    title: "YEARLY FEE",
    subtitle: "Yearly Fee Report",
    color: "#8a3593",
  },
];
const Billing_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddBilling",
    imagePath: images.imgaddBilling,
    title: "ADD BILLING",
    subtitle: "Add Billing Information",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/ViewBilling",
    imagePath: images.imgViewBilling,
    title: "VIEW BILLING",
    subtitle: "View Billing Information",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];
const Billing_Dashboard_Admin_Links = [
  {
    linkPath: "/dashboard/AddBilling",
    imagePath: images.imgaddBilling,
    title: "ADD BILLING",
    subtitle: "Add Billing Information",
    color: "#8a3593",
  },
  {
    linkPath: "/dashboard/ViewBilling",
    imagePath: images.imgViewBilling,
    title: "VIEW BILLING",
    subtitle: "View Billing Information",
    color: "#8a3593",
  },
  
];
const PayRoll_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddPayRoll",
    imagePath: images.imgAddPayRoll,
    subtitle: "Add Staff Payroll",
    color: "#314857",
    title: "ADD PAYROLL",
  },
  {
    linkPath: "/dashboard/ViewPayRoll",
    imagePath: images.imgViewPayRoll,
    subtitle: "View Staff Payroll",
    color: "#314857",
    title: "VIEW PAYROLL",
  },
  {
    linkPath: "/dashboard/ViewPaySlip",
    imagePath: images.imgViewPaySlip,
    subtitle: "View Payslip List",
    color: "#314857",
    title: "VIEW PAYSLIP",
  },
];
const Library_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddBookCategory",
    imagePath: images.imgAddBookCategory,
    subtitle: "Add Book Category in Library",
    color: "#8cc63f",
    title: "ADD BOOK CATEGORY",
  },
  {
    linkPath: "/dashboard/ViewBookCategory",
    imagePath: images.imgViewBookCategroy,
    subtitle: "View Book Category in Library",
    color: "#8cc63f",
    title: "VIEW BOOK CATEGORY",
  },
  {
    linkPath: "/dashboard/AddBook",
    imagePath: images.imgAddBook,
    subtitle: "Add Book in Library",
    color: "#8cc63f",
    title: "ADD BOOK",
  },
  {
    linkPath: "/dashboard/ViewBook",
    imagePath: images.imgViewBook,
    subtitle: "View Book in Library",
    color: "#8cc63f",
    title: "VIEW BOOK",
  },
  {
    linkPath: "/dashboard/IssueBook",
    imagePath: images.imgIssuedBook,
    subtitle: "Issue Book from Library",
    color: "#8cc63f",
    title: "ISSUE BOOK",
  },
  {
    linkPath: "/dashboard/ViewIssuedBook",
    imagePath: images.imgViewIssued,
    subtitle: "View Issued Books from Library",
    color: "#8cc63f",
    title: "VIEW ISSUED BOOK",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  }
];
const Event_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddEvent",
    imagePath: images.imgAddEvent,
    color: "#287ABF",
    subtitle: "Add Event Details",
    title: "ADD EVENT",
  },
  {
    linkPath: "/dashboard/UpdateEvent",
    imagePath: images.imgUpdateEvent,
    color: "#287ABF",
    subtitle: "Add and View Class In Events",
    title: "UPDATE EVENT",
  },
  {
    linkPath: "/dashboard/ViewEvent",
    imagePath: images.imgViewEvent,
    color: "#287ABF",
    subtitle: "View Events",
    title: "VIEW EVENT",
  },
];

const StudentReport_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddStudentProgressReport",
    imagePath: images.AddStudentProgressReport,
    subtitle: "Add Student Progress Report",
    title: "STUDENT PROGRESS",
    color:"#e0cf36"
  },
  {
    title: "STUDENT PROGRESS DETAILS",
    linkPath: "/dashboard/ViewStudentProgressReport",
    imagePath: images.ViewStudentProgressReport,
    subtitle: "View Progress Report of Student",
    color: "#e0cf36",
  },
];

const Maintenance_Coordiantor_Dashboard_Links = [
  {
    linkPath: "/dashboard/CampusMaintenanceDashboard",
    imagePath: images.imgDashboardCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Manage Campus Maintenance",
    title: "CAMPUS MAINTENANCE",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  }
];
const Floor_Link=[
  {
    linkPath: "/dashboard/AddFloor",
    imagePath: images.imgAddFloorCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Add Campus Floor",
    title: "ADD FLOOR",
  },
  {
    linkPath: "/dashboard/ViewFloor",
    imagePath: images.imgViewFloorCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Manage Campus Floor",
    title: "VIEW FLOOR",
  },
];
const Room_Link=[
  {
    linkPath: "/dashboard/AddRoom",
    imagePath: images.imgAddRoomCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Add Campus Room",
    title: "ADD ROOM",
  },
  {
    linkPath: "/dashboard/ViewRoom",
    imagePath: images.imgViewRoomCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Manage Campus ROOM",
    title: "VIEW ROOM",
  }
];


const Campus_Maintenance_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddCleanlinessCategory",
    imagePath: images.imgAddCleanlinessCategoryCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Add Maintenance Category of Campus",
    title: "ADD MAINTENANCE CATEGORY",
  },
  {
    linkPath: "/dashboard/ViewCleanlinessCategory",
    imagePath: images.imgViewCleanlinessCategoryCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Manage Maintenance Category of Campus",
    title: "VIEW MAINTENANCE CATEGORY",
  },
  {
    linkPath: "/dashboard/AddCyclicCleanliness",
    imagePath: images.imgAddCyclicCleanlinessCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Add Cyclic Maintenance of Campus",
    title: "ADD CYCLIC MAINTENANCE",
  },
  {
    linkPath: "/dashboard/ViewCyclicCleanliness",
    imagePath: images.imgViewCyclicCleanlinessCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Manage Cyclic Maintenance of Campus",
    title: "VIEW CYCLIC MAINTENANCE",
  },
  {
    linkPath: "/dashboard/AddEmergencyMaintenance",
    imagePath: images.imgAddEmergencyMaintenanceCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Add Emergency Maintenance",
    title: "ADD EMERGENCY MAINTENANCE",
  },
  {
    linkPath: "/dashboard/ViewEmergencyMaintenance",
    imagePath: images.imgViewEmergencyMaintenanceCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Manage Emergency Maintenance",
    title: "VIEW EMERGENCY MAINTENANCE",
  },
  {
    linkPath: "/dashboard/NotificationEmergencyMaintenance",
    imagePath: images.imgNotificationEmergencyMaintenanceCampusMaintenance,
    color: "#FCB01A",
    subtitle: "Notifications of Emergency Maintenance",
    title: "EMERGENCY MAINTENANCE NOTIFICATION",
  },
];

const SENManager_Dashboard_Links = [
  {
    linkPath: "/dashboard/StudentTalentDashboard",
    imagePath: images.StudentTalentSENManagerD,
    title: "TALENT MANAGER",
    subtitle: "Manage Talent Manager",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
  {
    linkPath: "/dashboard/SpecialEducationNeedDashboardAdmin",
    imagePath: images.imgAddSpecialEducation,
    color: "#287ABF",
    subtitle: "Special Education Needs",
    title: "Special Education Needs",
  },
];

const SENManager_Links = [
  {
    linkPath: "/dashboard/AddTalentCategory",
    imagePath: images.AddTalentCategorySENManagerD,
    title: "ADD TALENT CATEGORY",
    subtitle: "Add Talent Category Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewTalentCategory",
    imagePath: images.ViewTalentCategorySENManagerD,
    title: "VIEW TALENT CATEGORY",
    subtitle: "Manage Talent Category",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/AddTalentSubCategory",
    imagePath: images.AddTalentSubCategorySENManagerD,
    title: "ADD TALENT SUB CATEGORY",
    subtitle: "Add Talent Sub Category Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewTalentSubCategory",
    imagePath: images.ViewTalentSubCategorySENManagerD,
    title: "VIEW TALENT SUB CATEGORY",
    subtitle: "Manage Talent Sub Category",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/AddStudentTalent",
    imagePath: images.AddStudentTalentSENManagerD,
    title: "ADD STUDENT TALENT",
    subtitle: "Add Student Talent Details Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewStudentTalent",
    imagePath: images.ViewStudentTalentSENManagerD,
    title: "VIEW STUDENT TALENT",
    subtitle: "Manage Student Talent",
    color: "#287ABF",
  },
];

const WellBeingManager_Dashboard_Links = [
  {
    linkPath: "/dashboard/DailyReportsWellBeingManager",
    imagePath: images.DailyReportsWellbeingManagerD,
    title: "DAILY REPORTS",
    subtitle: "Manage Daily Reports",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewFlagManagement",
    imagePath: images.FlagManagementAttendanceWellbeingManagerD,
    title: "FLAG MANAGEMENT ATTENDANCE",
    subtitle: "Manage Flag Management Attendance",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewFlagManagementSkill",
    imagePath: images.FlagManagementSkillWellbeingManagerD,
    title: "VIEW FLAG MANAGEMENT SKILL",
    subtitle: "Manage Flag Management Skill",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewDailyDisciplineReport",
    imagePath: images.DailyDisciplineReportWellbeingManagerD,
    title: "VIEW DAILY DISCIPLINE REPORT",
    subtitle: "Manage Daily Discipline Report",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewDailyClassAttendance",
    imagePath: images.DailyClassAttendanceWellbeingManagerD,
    title: "DAILY CLASS ATTENDANCE",
    subtitle: "Manage Daily Class Attendance",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewMonthlyStudentAttendanceReport",
    imagePath: images.MonthlyStudentAttendanceWellbeingManagerD,
    title: "VIEW MONTHLY STUDENT ATTENDANCE",
    subtitle: "Manage Monthly Student Attendance",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewStudentSkillAverageReport",
    imagePath: images.StudentSkillAverageWellbeingManagerD,
    title: "VIEW STUDENT SKILL AVERAGE REPORT",
    subtitle: "Manage Student Skill Average Report",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewStudentDisciplineReport",
    imagePath: images.StudentDisciplineReportWellbeingManager,
    title: "VIEW STUDENT DISCIPLINE REPORT",
    subtitle: "Manage Student Discipline Report",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/MedicalHistory",
    imagePath: images.imgViewMedicalHistoryD,
    title: "MEDICAL HISTORY",
    subtitle: "Manage Medical History",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

const WellBeingManager_Report_Links = [
  {
    linkPath: "/dashboard/ViewAbsentReport",
    imagePath: images.DailyAbsentReportWellbeingManagerD,
    title: "DAILY ABSENT REPORT",
    subtitle: "Manage Daily Absent Report",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewLateReport",
    imagePath: images.DailyLateReportWellbeingManagerD,
    title: "VIEW LATE REPORT",
    subtitle: "Manage Late Report",
    color: "#287ABF",
  },
];

const Pastrol_Dashboard_links = [
  {
    linkPath: "/dashboard/PastrolSENManagerDashboard",
    imagePath: images.SENManagerPastrolD,
    title: "SEN Manager",
    subtitle: "Manage SEN System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/PastrolWellbeingManagerDashboard",
    imagePath: images.WellbeingManagerPastrolD,
    title: "WellBeing Manager",
    subtitle: "Manage WellBeing Manager",
    color: "#287ABF",
  },
];

const Class_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddClass",
    imagePath: images.imgAddClass,
    title: "ADD CLASS",
    subtitle: "Add Class Details",
  },
  {
    linkPath: "/dashboard/ViewClass",
    imagePath: images.imgViewClass,
    title: "VIEW CLASS",
    subtitle: "Manage Class Details",
  },
];

const Section_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddSection",
    imagePath: images.imgAddSection,
    title: "ADD SECTION",
    subtitle: "Add Section Details",
  },
  {
    linkPath: "/dashboard/ViewSection",
    imagePath: images.imgViewSection,
    title: "VIEW SECTION",
    subtitle: "Manage Section Details",
  },
];

const Session_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddSession",
    imagePath: images.imgAddSession,
    title: "ADD SESSION",
    subtitle: "Add Session Details",
  },
  {
    linkPath: "/dashboard/ViewSession",
    imagePath: images.imgViewSession,
    title: "VIEW SESSION",
    subtitle: "Manage Session Details",
  },
];

const Subject_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddSubject",
    imagePath: images.imgAddSubject,
    title: "ADD SUBJECT",
    subtitle: "Add Subject Details",
  },
  {
    linkPath: "/dashboard/ViewSubject",
    imagePath: images.imgViewSubject,
    title: "VIEW SUBJECT",
    subtitle: "Manage Subject Details",
  },
];

const ClassTeacher_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddClassTeacher",
    imagePath: images.imgAddClassTeacher,
    title: "ADD CLASS TEACHER",
    subtitle: "Add Class Teacher For A Grade",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/ViewClassTeacher",
    imagePath: images.imgViewClassTeacher,
    title: "VIEW CLASS TEACHERS",
    subtitle: "Manage Assigned Class Teachers",
    color: "#314857",
  },
];

const Indent_Dashboard_Links = [
  {
    linkPath: "/dashboard/ViewIndentForm",
    imagePath: images.imgViewIndentForm,
    subtitle: "View Indent Details",
    title: "VIEW INDENT",
  },
];

const IndentStaff_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddIndent",
    imagePath: images.imgAcademicIndent,
    title: "ADD INDENT",
    subtitle: "Add New Indent Requests",
    color: "#8DC640",
  },
  {
    linkPath: "/dashboard/ViewIndentByStaff",
    imagePath: images.imgViewIndent,
    title: "VIEW INDENT",
    subtitle: "Manage Indent Forms",
    color: "#8DC640",
  },
];

const TeacherEvaluation_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddTeacherEvaluation",
    imagePath: images.imgAddTeacherEvaluation,
    subtitle: "Add Teacher Evaluation Details",
    title: "ADD EVALUATION",
  },
  {
    subtitle: "View Teacher Evaluation Details",
    title: "VIEW EVALUATION",
    linkPath: "/dashboard/ViewTeacherEvaluation",
    imagePath: images.imgViewTeacherEvaluation,
  },
];

const PastoralsLinks = [
  {
    linkPath: "/dashboard/MedicalDashboard",
    imagePath: images.imgMedicalCenterD,
    title: "MEDICAL CENTER",
    subtitle: "Manage Medical Center",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ActivityDashboard",
    imagePath: images.imgPastoralActivityD,
    title: "ACTIVITIES",
    subtitle: "Manage Activities",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ExclusionDashboard",
    imagePath: images.imgExclusionManagerD,
    title: "EXCLUSIONS",
    subtitle: "Manage Exclusions",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/PastrolSENManagerDashboard",
    imagePath: images.SENManagerPastrolD,
    title: "SEN MANAGER",
    subtitle: "Manage SEN Manager",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/PastrolWellbeingManagerDashboard",
    imagePath: images.WellbeingManagerPastrolD,
    title: "WELLBEING MANAGER",
    subtitle: "Manage WellBeing Manager",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/StudentProfileDashboard",
    imagePath: images.imgStudentProfile,
    title: "STUDENT PROFILE",
    subtitle: "Manage Students Profile",
    color: "#287ABF",
  },
  
{
  linkPath: "/dashboard/CMSAdminDashboard",
  imagePath: images.imgAcademicCMS,
  color: "#8DC640",
  subtitle: "Competency Management System",
  title: "CMS",

},
  // {
  //   linkPath: "/dashboard/CampusMaintenanceDashboard",
  //   imagePath: images.imgDashboardCampusMaintenance,
  //   title: "CAMPUS MAINTENANCE",
  //   subtitle: "Manage Campus Maintenance",
  //   color: "#287abf",
  // },
];

const Grade_Link_Coordinator=[
  {
    linkPath: "/dashboard/AssignCoordinatorDashboard",
    imagePath: images.imgGradeCoordinator,
    title: "GRADE COORDINATOR",
    subtitle: "Manage Grade Coordinators",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  }
]
const News_Dashboard_Links = [
  {
    title: "BULLETIN",
    subtitle: "Bulletin Details",
    linkPath: "/dashboard/AddBulletin",
    imagePath: images.imgBulletin,
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];
const Medical_Dashboard_Links = [
  {
    linkPath: "/dashboard/PatientDashboard",
    imagePath: images.imgPatientD,
    title: "PATIENT",
    subtitle: "Manage Patients",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/DoctorDashboard",
    imagePath: images.imgDoctorD,
    title: "DOCTOR / NURSE",
    subtitle: "Manage Doctor/Nurse",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/MedicalHistory",
    imagePath: images.imgViewMedHistoryD,
    title: "MEDICAL HISTORY",
    subtitle: "View Medical Histories",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];
const Medical_Dashboard_Links_For_Manager = [
  {
    linkPath: "/dashboard/PatientDashboard",
    imagePath: images.imgPatientD,
    title: "PATIENT",
    subtitle: "Manage Patients",
    color: "#287ABF",
  },
  // {
  //   linkPath: "/dashboard/DoctorDashboard",
  //   imagePath: images.imgDoctorD,
  //   title: "DOCTOR / NURSE",
  //   subtitle: "Manage Doctor/Nurse",
  //   color: "#287ABF",
  // },
  {
    linkPath: "/dashboard/MedicalHistory",
    imagePath: images.imgViewMedHistoryD,
    title: "MEDICAL HISTORY",
    subtitle: "View Medical Histories",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

const Doctor_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddDoctor",
    imagePath: images.imgAddDoctorD,
    title: "ADD DOCTOR / NURSE",
    subtitle: "Add Doctor/Nurse Details Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewDoctor",
    imagePath: images.imgViewDoctorD,
    title: "VIEW DOCTOR / NURSE",
    subtitle: "Manage Doctor/Nurse Details",
    color: "#287ABF",
  },
];

const Patient_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddPatient2",
    imagePath: images.imgAddpatientD,
    title: "ADD PATIENT",
    subtitle: "Add Patient Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/PatientDetail",
    imagePath: images.imgViewPatientD,
    title: "VIEW PATIENTS",
    subtitle: "Manage Patient Details",
    color: "#287ABF",
  },
];

const CampusSecurity_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddVisitorEntry",
    imagePath: images.imgCSAddVisitor,
    title: "ADD VISITOR",
    subtitle: "Add Details Of New Visitor Entry",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/VisitorEntryRecord",
    imagePath: images.imgCSViewVisitor,
    title: "VIEW VISITOR",
    subtitle: "Manage Visitors In School Premises",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/GenerateVisitorCardView",
    imagePath: images.imgCSGenerateCard,
    title: "GENERATE VISITOR CARDS",
    subtitle: "Manage & Generate Visitor Cards",
    color: "#fcb01a",
  },
];

const Leave_Dashboard_Links = [
  {
    linkPath: "/dashboard/ViewLeave",
    imagePath: images.imgViewLeaveStaff,
    subtitle: "Student Leaves",
    title: "LEAVE",
    color: '#F05025'
  },
];

const StudentLeave_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddLeave",
    imagePath: images.imgAddLeave,
    subtitle: "Add New Leave Request",
    title: "ADD LEAVE",
  },
  {
    linkPath: "/dashboard/ViewLeaveByStudent",
    imagePath: images.imgViewLeaveStudent,
    subtitle: "View Reviewed Leave Requests",
    title: "VIEW LEAVE BY STUDENT",
  },
];

const SecurityManager_Links = [
  {
    linkPath: "/dashboard/CampusSecurityDashboard",
    imagePath: images.imgCSecurity,
    color: "#fcb01a",
    subtitle: "Manage Visitors In School Premises",
    title: "VISITOR",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#E0CF36",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

const ProcurementManager_Links = [
  {
    linkPath: "/dashboard/IndentDashboard",
    imagePath: images.imgViewItem,
    subtitle: "Procurement Item List",
    color: "#fcb01a",
    title: "PROCUREMENT DETAILS",
  }
  ,
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

const Tour_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddTour",
    imagePath: images.imgAddTourD,
    title: "Add Tour",
    subtitle: "Add Tour Details",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewTour",
    imagePath: images.imgViewTourD,
    title: "View Tour",
    subtitle: "Manage Tour Details",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewFilterTour",
    imagePath: images.imgViewTourFiltersD,
    title: "View Tour Filters",
    subtitle: "Filtered Tour Details",
    color: "#287ABF",
  },
];

const Activity_Dashboard_Links = [
  {
    linkPath: "/dashboard/TourDashboard",
    imagePath: images.imgPastoralTourD,
    title: "TOUR",
    subtitle: "Manage Tours",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/CertificateDashboard",
    imagePath: images.imgActivityCertificateD,
    title: "CERTIFICATE",
    subtitle: "Manage Certificates",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/AwardDashboard",
    imagePath: images.imgAwardD,
    title: "AWARD",
    subtitle: "Manage Awards",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/EventCertificateDashboard",
    imagePath: images.imgEventCertificateD,
    title: "EVENT CERTIFICATE",
    subtitle: "Manage Event Certificates",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/GameDashboard",
    imagePath: images.imgGamesD,
    title: "OFF GAMES",
    subtitle: "Manage Games",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/EventDashboard",
    imagePath: images.imgAddEvent,
    title: "EVENT",
    subtitle: "Manage Events",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

const Certificate_Dashboard_Links = [
  {
    linkPath: "/dashboard/OtherCertificateDashboard",
    imagePath: images.imgOtherCertificateD,
    title: "Other Certificates",
    subtitle: "Manage Other Certificates",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/CertificateCategoryDashboard",
    imagePath: images.imgCertificateCategoryD,
    title: "Certifacate Category",
    subtitle: "Manage Certificate Category",
    color: "#287ABF",
  },
];

const OtherCertificate_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddOtherCertificate",
    imagePath: images.imgAddOtherCertificateD,
    title: "Add Other Certificate",
    subtitle: "Add Other Certificate Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewOtherCertificate",
    imagePath: images.imgViewOtherCertificateD,
    title: "View Other Certificate",
    subtitle: "Manage Other Certificate Details",
    color: "#287ABF",
  },
];

const CertificateCategory_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddCertificateCategory",
    imagePath: images.imgAddCategoryD,
    title: "Add Certificate Category",
    subtitle: "Add Certificate Category Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewCertificateCategory",
    imagePath: images.imgViewCertificateCategoryD,
    title: "View Certificate Category",
    subtitle: "Manage Certificate Category Details",
    color: "#287ABF",
  },
];

const Award_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddAward",
    imagePath: images.imgAddAwardD,
    title: "Add Award",
    subtitle: "Add Award Details Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewAward",
    imagePath: images.imgViewAwardD,
    title: "View Award",
    subtitle: "Manage Award Details",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/AddAwardCategory",
    imagePath: images.imgAddAwardCategoryD,
    title: "Add Award Category",
    subtitle: "Add Award Category Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewAwardCategory",
    imagePath: images.imgViewAwardCategoryD,
    title: "View Award Category",
    subtitle: "Manage Award Category",
    color: "#287ABF",
  },
];

const Game_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddGame",
    imagePath: images.imgAddGamesD,
    title: "Add Game",
    subtitle: "Add Game Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewGame",
    imagePath: images.imgViewGamesD,
    title: "View Game",
    subtitle: "Manage Game Details",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/OffGameDashboard",
    imagePath: images.imgOffGamesD,
    title: "Off Games",
    subtitle: "Manage Off Games",
    color: "#287ABF",
  },
];

const OffGame_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddOffGame",
    imagePath: images.imgAddOffGameD,
    title: "Add Off Game",
    subtitle: "Add Off Game Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewOffGame",
    imagePath: images.imgViewOffGameD,
    title: "View Off Game",
    subtitle: "Manage Off Game Details",
    color: "#287ABF",
  },
];

const Entry_Exam_Admin_Link =[
  {
    title: "ADD CANDIDATE",
    subtitle: "Add Candidate Details Into System",
    linkPath: "/dashboard/AddEntryExam",
    imagePath: images.imgAddCandidate,
  },
  {
    title: "VIEW CANDIDATE",
    subtitle: "Candidate Details",
    linkPath: "/dashboard/ViewEntryExam",
    imagePath: images.imgViewCandidate,
  }
];


const Entry_Exam_Dashboard_Links = [
  {
    title: "ADD CANDIDATE",
    subtitle: "Add Candidate Details Into System",
    linkPath: "/dashboard/AddEntryExam",
    imagePath: images.imgAddCandidate,
  },
  {
    title: "VIEW CANDIDATE",
    subtitle: "Candidate Details",
    linkPath: "/dashboard/ViewEntryExam",
    imagePath: images.imgViewCandidate,
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];
const Event_Certificate_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddEventCertificate",
    imagePath: images.imgAddEventCertificateD,
    title: "Add Event Certificate",
    subtitle: "Add Event Certificate Into Details",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewEventCertificate",
    imagePath: images.imgViewEventCertificateD,
    title: "View Event Certificate",
    subtitle: "Manage Event Certificate Details",
    color: "#287ABF",
  },
];

const Student_Coordinator_Dashboard_Links = [
  //    {
  //   linkPath: "/dashboard/AssignClassNewAdmit",
  //   imagePath: images.imgAssignClassToStudent,
  //   title: "NEW ADMISSIONS",
  //   subtitle: "Manage New Admitted Students",
  //   color: "#314857",
  // },
  {
    linkPath: "/dashboard/ClassOfStudent",
    imagePath: images.imgClassOfStudent,
    title: "CLASS OF STUDENTS",
    subtitle: "Manage Students In Classes",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/TransferStudent",
    imagePath: images.imgTransferStudent,
    title: "TRANSFER STUDENT",
    subtitle: "Manage Student Transfers",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

const Transport_Coordinator_Dashboard_Links = [
  {
    linkPath: "/dashboard/RouteDashboard",
    imagePath: images.imgRoutes,
    title: "ROUTE MODULE",
    subtitle: "Manage Route Details Details",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/DriverDashboard",
    imagePath: images.imgDriver,
    title: "DRIVER MODULE",
    subtitle: "Manage Driver Details",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/VehicleDashboard",
    imagePath: images.imgVehicle,
    title: "VEHICLE MODULE",
    subtitle: "Manage Vehicle Details",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/TransportMaintenanceDashboard",
    imagePath: images.imgReport,
    title: "TRANSPORT MAINTENANCE",
    subtitle: "Manage Event Certificate Details",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

const Routes_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddRoute",
    imagePath: images.imgAddRoute,
    title: "ADD ROUTE",
    subtitle: "Manage Add Route Details",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/ViewRoutes",
    imagePath: images.imgViewRoute,
    title: "VIEW ROUTE",
    subtitle: "Manage View Route Details",
    color: "#fcb01a",
  },
];

const Driver_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddDriver",
    imagePath: images.imgAddDriver,
    title: "ADD DRIVER",
    subtitle: "Manage Add Driver Details",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/ViewDriver",
    imagePath: images.imgViewDriver,
    title: "VIEW DRIVER",
    subtitle: "Manage View Driver Details",
    color: "#fcb01a",
  },
];

const Vehicle_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddVehicle",
    imagePath: images.imgAddVehicle,
    title: "ADD VEHICLE",
    subtitle: "Manage Add Vehicle Details",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/ViewVehicle",
    imagePath: images.imgViewVehicle,
    title: "VIEW VEHICLE",
    subtitle: "Manage View Vehicle Details",
    color: "#fcb01a",
  },
];

const Transport_Maintenance_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddTransportMaintenance",
    imagePath: images.imgAddReport,
    title: "ADD REPORT",
    subtitle: "Add Maintenance Report",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/ViewTransportMaintenance",
    imagePath: images.imgViewReport,
    title: "VIEW REPORT",
    subtitle: "Manage Maintenance Report",
    color: "#fcb01a",
  },
];

const Staff_Coordinator_Dashboard_Links = [
  {
    linkPath: "/dashboard/AssignSubjectDashboard",
    imagePath: images.imgAssignSubject,
    title: "ASSIGN SUBJECT",
    subtitle: "Assign Subjects To Teacher",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/ClassTeacherDashboard",
    imagePath: images.imgClassTeacher,
    title: "CLASS TEACHER",
    subtitle: "Manage Class Teachers",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/AssignHODDashboard",
    imagePath: images.imgAssignHOD,
    title: "STAFF IN DEPARTMENT",
    subtitle: "Manage Staff In Departments",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/AssignCoordinatorDashboard",
    imagePath: images.imgGradeCoordinator,
    title: "GRADE COORDINATOR",
    subtitle: "Manage Grade Coordinators",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

const Assign_Subject_Dashboard_Links = [
  {
    linkPath: "/dashboard/ViewStaffSubject",
    imagePath: images.imgAssignSubjectToTeacher,
    title: "STAFF SUBJECTS",
    subtitle: "Manage Subjects By Staff",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/ViewAssignSubject",
    imagePath: images.imgViewAssignSubject,
    title: "ASSIGNED SUBJECTS",
    subtitle: "Manage Assigned Subjects",
    color: "#314857",
  },
];

const Assign_HOD_Dashboard_Links = [
  {
    linkPath: "/dashboard/StaffToHOD",
    imagePath: images.imgAddStaffInDepartment,
    title: "ADD STAFF",
    subtitle: "Add Staff Into Departments",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/ViewStaffInHOD",
    imagePath: images.imgViewAssignHOD,
    title: "VIEW STAFF",
    subtitle: "Manage Staff In Departments",
    color: "#314857",
  },
];

const Exclusion_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddExclusion",
    imagePath: images.imgAddComplainD,
    title: "ADD COMPLAINT",
    subtitle: "Add Complaint Into System",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewExclusion",
    imagePath: images.imgViewComplainD,
    title: "VIEW COMPLAINT",
    subtitle: "Manage Complaint Details",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ViewInvestigationCommittee",
    imagePath: images.imgInvestigationCommitteeD,
    title: "INVESTIGATION COMMITTEE",
    subtitle: "Manage Investigation Committee",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/StudentExclusionRecord",
    imagePath: images.imgStudentExclusionRecordD,
    title: "STUDENT EXCLUSION RECORD",
    subtitle: "Manage Student Exclusion Record",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/InvestigationList",
    imagePath: images.imgInvestigationListD,
    title: "INVESTIGATION LIST",
    subtitle: "Manage Investigation List",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

const Student_Profile_Dashboard_Links = [
  {
    linkPath: "/dashboard/StudentSkillByStudentProfile",
    imagePath: images.imgStudentSkillsSP,
    title: "STUDENT SKILLS",
    subtitle: "View Student Skills",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/StudentDescriptiveByStudentProfile",
    imagePath: images.imgdescriptiveSP,
    title: "STUDENT DESCRIPTIVE REPORT",
    subtitle: "Manage Student Reports",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/ContinousAssessmentByStudentProfile",
    imagePath: images.imgCASP,
    title: "CONTINOUS ASSESSMENT",
    subtitle: "Manage Continous Assessment",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/StudentDisciplineByStudentProfile",
    imagePath: images.imgSubDisciplineSP,
    title: "STUDENT DISCIPLINE",
    subtitle: "Manage Student Discipline",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/StudentAttendanceByStudentProfile",
    imagePath: images.imgAttendanceSP,
    title: "ATTENDANCE",
    subtitle: "Manage Student Attendances",
    color: "#287ABF",
  },
  {
    linkPath: "/dashboard/StudentProgressReportByStudentProfile",
    imagePath: images.imgProgressReportSP,
    title: "PROGRESS REPORT",
    subtitle: "Manage Student Progress Reports",
    color: "#287ABF",
  },
];

const Assign_Coordinator_Dashboard_Links = [
  {
    linkPath: "/dashboard/AssignStaffCoordinator",
    imagePath: images.imgAssignCoordinator,
    title: "ADD GRADE COORDINATOR",
    subtitle: "Assign Staff As Grade Coordinator",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/ViewStaffCoordinator",
    imagePath: images.imgViewCoordinator,
    title: "VIEW GRADE COORIDNATOR",
    subtitle: "Manage Staff In Grade Coordinator",
    color: "#314857",
  },
];

const Staff_Dashboard_Links = [
  {
    linkPath: "/dashboard/StaffModuleLMSDashboard",
    headingImagePath: "",
    imagePath: images.imgAcademicLMS,
    subtitle: "Learning Management System",
    color: "#65c8ce",
    title: "LMS",
  },

  {
    linkPath: "/dashboard/CMSStaffDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8cc63f",
    subtitle: "Competency Management System",
    title: "CMS",
  },
  {
    linkPath: "/dashboard/StaffModuleSIDDashboard",
    imagePath: images.imgAcademicSID,
    color: "#f04f24",
    subtitle: "Student Information Desk",
    title: "SID",
  },
  {
    linkPath: "/dashboard/CommunicationStaffDashboard",
    imagePath: images.imgComDash1,
    color: "#61C4AE",
    subtitle: "Manage Communication Activities",
    title: "COMMUNICATION MODULE",
  },
  {
    linkPath: "/dashboard/ViewHrDevelopment",
    imagePath: images.imgHrDevelopmentMain,
    subtitle: "View Traning & Development",
    title: "STAFF TRAINING & DEVELOPMET",
  },
  {
    linkPath: "/dashboard/SpecialEducationNeedDashboard",
    imagePath: images.imgAddSpecialEducation,
    subtitle: "Manage Special Education Need",
    title: "Special Education Need",
    color: '#287ABF'
  }
];
let Canteen_Links = [
  {
    linkPath: "/dashboard/AddCanteen",
    headingImagePath: "",
    imagePath: images.imgAddCanteen,
    subtitle: "Add Canteen Information",
    color: "#fcb01a",
    title: "ADD CANTEEN",
  },
  {
    linkPath: "/dashboard/ViewCanteen",
    headingImagePath: "",
    imagePath: images.imgViewCanteen,
    subtitle: "View Canteen Information",
    color: "#fcb01a",
    title: "VIEW CANTEEN",
  },

  {
    linkPath: "/dashboard/AddCanteenItems",
    headingImagePath: "",
    imagePath: images.imgCanteenAddItem,
    subtitle: "Add Canteen Items Information",
    color: "#fcb01a",
    title: "ADD CANTEEN ITEMS",
  },
  {
    linkPath: "/dashboard/ViewCanteenItems",
    headingImagePath: "",
    imagePath: images.imgCanteenViewItem,
    subtitle: "View Canteen Items Information",
    color: "#fcb01a",
    title: "VIEW CANTEEN ITEMS",
  },
  {
    linkPath: "/dashboard/AddCanteenCategory",
    headingImagePath: "",
    imagePath: images.imgCanteenAddCategory,
    subtitle: "Add Canteen Category Information",
    color: "#fcb01a",
    title: "ADD CANTEEN CATEGORY",
  },
  {
    linkPath: "/dashboard/ViewCanteenCategory",
    headingImagePath: "",
    imagePath: images.imgCanteenViewCategory,
    subtitle: "View Canteen Category Information",
    color: "#fcb01a",
    title: "VIEW CANTEEN CATEGORY",
  },
];
let Administration_Dashboard_Links = [
  {
    linkPath: "/dashboard/InventoryDashboard",
    headingImagePath: "",
    imagePath: images.imgInventoryMain,
    subtitle: "Handle Inventory Module",
    color: "#fcb01a",
    title: "INVENTORY MODULE",
  },
  {
    linkPath: "/dashboard/CampusDashboard",
    headingImagePath: "",
    imagePath: images.imgCampus,
    subtitle: "Handle Campus Module",
    color: "#fcb01a",
    title: "CAMPUS MODULE",
  },

  {
    linkPath: "/dashboard/CanteenDashboard",
    headingImagePath: "",
    imagePath: images.imgCanteen,
    subtitle: "Handle Canteen Module",
    color: "#fcb01a",
    title: "CANTEEN MODULE",
  },
  {
    linkPath: "/dashboard/TransportDashboard",
    headingImagePath: "",
    imagePath: images.imgVehicle,
    subtitle: "Handle Transport Module",
    color: "#fcb01a",
    title: "TRANSPORT MODULE",
  },
  {
    linkPath: "/dashboard/IndentDashboard",
    headingImagePath: "",
    imagePath: images.imgViewItem,
    subtitle: "Handle Procurement Module",
    color: "#fcb01a",
    title: "PROCUREMENT MODULE",
  },
  {
    linkPath: "/dashboard/SecurityDashboard",
    headingImagePath: "",
    imagePath: images.imgCampusSecurity,
    subtitle: "Handle Campus Security",
    color: "#fcb01a",
    title: "CAMPUS SECURITY",
  },
  {
    linkPath: "/dashboard/CampusMaintenanceDashboard",
    imagePath: images.imgDashboardCampusMaintenance,
    title: "CAMPUS MAINTENANCE",
    subtitle: "Manage Campus Maintenance",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/FloorPlanDashboard",
    imagePath: images.imgFloorPlan,
    title: "FLOOR PLAN",
    subtitle: "Manage Floor Plan",
    color: "#fcb01a",
  },
  {
    linkPath: "/dashboard/CMSAdminDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  }
];

let Skill_Dashboard_Links = [
  {
    linkPath: "/dashboard/StudentSkill",
    headingImagePath: "",
    imagePath: images.imgStudentSkillsAdd,
    subtitle: "Add Student Skills Information",
    color: "#f47856",
    title: "ADD STUDENT SKILL",
  },
  {
    linkPath: "/dashboard/ViewStudentSkills",
    headingImagePath: "",
    imagePath: images.imgStudentSkillsView,
    subtitle: "View Student Skills Information",
    color: "#f47856",
    title: "VIEW STUDENT SKILL",
  },
];
let Descriptive_Dashboard_Links = [
  {
    linkPath: "/dashboard/DescriptiveReport",
    headingImagePath: "",
    imagePath: images.imgDescriptiveAdd,
    subtitle: "Add Student Skills Information",
    color: "#f47856",
    title: "ADD DESCRIPTIVE REPORT",
  },
  {
    linkPath: "/dashboard/EditDescriptiveReport",
    headingImagePath: "",
    imagePath: images.imgDescriptiveView,
    subtitle: "View Student Skills Information",
    color: "#f47856",
    title: "VIEW DESCRIPTIVE REPORT",
  },
];

let Attendance_Dashboard_Links = [
  {
    linkPath: "/dashboard/StudentAttendance",
    headingImagePath: "",
    imagePath: images.imgStudentAttendanceAdd,
    subtitle: "Add Student Skills Information",
    color: "#f47856",
    title: "ADD STUDENT ATTENDANCE",
  },
  {
    linkPath: "/dashboard/ViewStudentAttendance",
    headingImagePath: "",
    imagePath: images.imgStudentAttendanceView,
    subtitle: "View Student Skills Information",
    color: "#f47856",
    title: "VIEW STUDENT ATTENDANCE",
  },
  {
    linkPath: "/dashboard/MonthlyAttendanceView",
    headingImagePath: "",
    imagePath: images.imgStudentAttendanceView,
    subtitle: "View Student Skills Information",
    color: "#f47856",
    title: "VIEW MONTHLY ATTENDANCE",
  },
];
let Class_Discipline_Dashboard_Links = [
  {
    linkPath: "/dashboard/ClassDiscipline",
    headingImagePath: "",
    imagePath: images.imgAddClassDiscipline,
    subtitle: "Add Student Skills Information",
    color: "#f47856",
    title: "ADD CLASS DISCIPLINE",
  },
  {
    linkPath: "/dashboard/ViewclassDisciplinaryRecords",
    headingImagePath: "",
    imagePath: images.imgViewClassDiscipline,
    subtitle: "View Student Skills Information",
    color: "#f47856",
    title: "VIEW CLASS DISCIPLINE",
  },
];
let Campus_Dashboard_Links = [
  {
    linkPath: "/dashboard/CampusDashboard/AddCampus",
    headingImagePath: "",
    imagePath: images.imgCampusAdd,
    subtitle: "Add Campus Detail Information",
    color: "#fcb01a",
    title: "ADD CAMPUS",
  },
  {
    linkPath: "/dashboard/CampusDashboard/ViewCampus",
    headingImagePath: "",
    imagePath: images.imgCampusView,
    subtitle: "View Campus Detail Information",
    color: "#fcb01a",
    title: "VIEW CAMPUS",
  },
];
let FrontDesk_Dashboard = [
  {
    imagePath: images.imgFrontDeskAdd,
    linkPath: "/dashboard/StaffEnquiry",
    subtitle: "Add Staff Registration Details",
    title: "ADD ONLINE REGISTRATION FORM",
  },
  {
    imagePath: images.imgFrontDeskView,
    linkPath: "/dashboard/ViewStaffEnquiryPagination",
    subtitle: "View Staff Registration Details",
    title: "VIEW ONLINE REGISTRATION FORM",
  },
  {
    imagePath: images.imgFrontAdmittedViewStaff,
    linkPath: "/dashboard/ViewAdmittedStaff",
    subtitle: "Manage Admitted Staff Details",
    title: "VIEW ADMITTED STAFF",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];

let FrontDesk_Student_Dashboard = [
  {
    imagePath: images.imgAddStudentInquiry,
    linkPath: "/dashboard/StudentEnquiry",
    subtitle: "Add Student Registration Details",
    title: "ADD ONLINE REGISTRATION FORM",
  },
  {
    imagePath: images.imgViewStudentInquiry,
    linkPath: "/dashboard/ViewStudentEnquiryPagination",
    subtitle: "View Student Registration Details",
    title: "VIEW ONLINE REGISTRATION FORM",
  },
  {
    imagePath: images.imgViewAdmittedStudents,
    linkPath: "/dashboard/ViewAdmittedStudent",
    subtitle: "Manage Admitted Student Details",
    title: "VIEW ADMITTED STUDENT",
  },
  {
    linkPath: "/dashboard/CMSManagerDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#E0CF36",
    subtitle: "Competency Management System",
    title: "CMS",
  },
];


let FrontDesk_Student_Admin_Dashboard = [
  {
    imagePath: images.imgAddStudentInquiry,
    linkPath: "/dashboard/StudentEnquiry",
    subtitle: "Add Student Registration Details",
    title: "ADD ONLINE REGISTRATION FORM",
  },
  {
    imagePath: images.imgViewStudentInquiry,
    linkPath: "/dashboard/ViewStudentEnquiryPagination",
    subtitle: "View Student Registration Details",
    title: "VIEW ONLINE REGISTRATION FORM",
  },
  {
    imagePath: images.imgViewAdmittedStudents,
    linkPath: "/dashboard/ViewAdmittedStudent",
    subtitle: "Manage Admitted Student Details",
    title: "VIEW ADMITTED STUDENT",
  },
];
let Hr_Development_Links = [
  {
    linkPath: "/dashboard/HrDevelopmentAndTraining",
    headingImagePath: "",
    imagePath: images.imgHrDevelopmentAdd,
    subtitle: "Add Staff Development Program",
    color: "#20344c",
    title: "ADD DEVELOPMENT PROGRAM",
  },
  {
    linkPath: "/dashboard/ViewHrDevelopment",
    headingImagePath: "",
    imagePath: images.imgHrDevelopmentView,
    subtitle: "View Staff Development Program",
    color: "#20344c",
    title: "VIEW DEVELOPMENT PROGRAM",
  },
  {
    linkPath: "/dashboard/EditHrDevelopment",
    headingImagePath: "",
    imagePath: images.imgHrDevelopmentEdit,
    subtitle: "Edit Staff Development Program",
    color: "#20344c",
    title: "EDIT DEVELOPMENT PROGRAM",
  },
];
let Department_Links = [
  {
    linkPath: "/dashboard/AddDepartment",
    headingImagePath: "",
    imagePath: images.imgDepartment,
    subtitle: "Add Department Details",
    color: "#20344c",
    title: "ADD DEPARTMENT",
  },
  {
    linkPath: "/dashboard/ViewDepartment",
    headingImagePath: "",
    imagePath: images.imgDepartmentView,
    subtitle: "View Department Details",
    color: "#20344c",
    title: "VIEW DEPARTMENT",
  },
  {
    linkPath: "/dashboard/SearchStaffByDepartment",
    headingImagePath: "",
    imagePath: images.imgDepartmentSearchStaffByDepartment,
    subtitle: "Search Staff by Department Details",
    color: "#20344c",
    title: "SEARCH STAFF BY DEPARTMENT",
  },
];
let Post_Links = [
  {
    linkPath: "/dashboard/AddPost",
    headingImagePath: "",
    imagePath: images.imgPostAdd,
    subtitle: "Add Post Details",
    color: "#20344c",
    title: "ADD POST",
  },
  {
    linkPath: "/dashboard/ViewPost",
    headingImagePath: "",
    imagePath: images.imgPostView,
    subtitle: "View Post Details",
    color: "#20344c",
    title: "VIEW POST",
  },
  {
    linkPath: "/dashboard/SearchStaffByPost",
    headingImagePath: "",
    imagePath: images.imgDepartmentSearchStaffByPost,
    subtitle: "Search Staff by Post Details",
    color: "#20344c",
    title: "SEARCH STAFF BY POST",
  },
];

let Student_Dashboard_Links = [
  {
    linkPath: "/dashboard/StudentSkillView",
    headingImagePath: "",
    imagePath: images.imgAcademicSkill,
    subtitle: "View Student Skill",
    color: "#20344c",
    title: "STUDENT SKILL",
  },
  {
    linkPath: "/dashboard/StudentDescriptiveView",
    headingImagePath: "",
    imagePath: images.imgAcademicDescriptive,
    subtitle: "View Descriptive Report",
    color: "#20344c",
    title: "DESCRIPTIVE REPORT",
  },
  {
    linkPath: "/dashboard/StudentModuleLMSDashboard",
    imagePath: images.imgAcademicLMS,
    headingImagePath: "",
    subtitle: "View LMS Related Information",
    color: "#65C8CE",
    title: "LEARNING MANAGMENT SYSTEM",
  },
  {
    linkPath: "/dashboard/CaReportList",
    imagePath: images.imgCASP,
    headingImagePath: "",
    subtitle: "Continouse Report List",
    color: "#65C8CE",
    title: "CONTINUOUS REPORT DETAILS",
  },
  {
    linkPath: "/dashboard/StudentTimeTable",
    imagePath: images.imgStaffTimeTable,
    subtitle: "View Student Timetable",
    color: "#8DC640",
    title: "TIMETABLE",
  },
];
let Parent_Dashboard_Links = [
  {
    linkPath: "/dashboard/StudentSkillViewP",
    headingImagePath: "",
    imagePath: images.imgAcademicSkill,
    subtitle: "View Child Student Skill",
    color: "#942e8d",
    title: "STUDENT SKILL",
  },
  {
    linkPath: "/dashboard/StudentDescriptiveViewP",
    headingImagePath: "",
    imagePath: images.imgAcademicDescriptive,
    subtitle: "View Child Descriptive Report",
    color: "#61c4ae",
    title: "DESCRIPTIVE REPORT",
  },
  {
    linkPath: "/dashboard/StudentFeeHistoryP",
    headingImagePath: "",
    imagePath: images.imgAccountLedg,
    subtitle: "View Child Fee History",
    color: "#942e8d",
    title: "STUDENT FEE HISTORY",
  },
  {
    linkPath: "/dashboard/CaParentReportList",
    imagePath: images.imgCASP,
    headingImagePath: "",
    subtitle: "Continouse Assessment Report List",
    color: "#65C8CE",
    title: "CONTINUOUS ASSESSMENT REPORT DETAILS",
  },
  {
    linkPath: "/dashboard/CommunicationParentDashboard",
    imagePath: images.imgComDash1,
    color: "#61C4AE",
    subtitle: "Manage Communication Activities",
    title: "COMMUNICATION MODULE",
  },
];

let StudentModuleLMS_Dashboard_Links = [
  {
    linkPath: "/dashboard/ViewHomeWorkStudent",
    imagePath: images.imgViewHomework,
    subtitle: "View Class Homework",
    color: "#65C8CE",
    title: "HOMEWORK",
  },
  {
    linkPath: "/dashboard/ViewExamInfoStudent",
    imagePath: images.imgViewExamInfo,
    subtitle: "View Exam Related Information",
    color: "#65C8CE",
    title: "VIEW EXAM INFO",
  },
  {
    linkPath: "/dashboard/ViewQuizInfoStudent",
    imagePath: images.imgViewQuizInfo,
    subtitle: "View Quiz Related Information",
    color: "#65C8CE",
    title: "VIEW QUIZ INFO",
  },
  {
    linkPath: "/dashboard/ViewAssignmentStudent",
    imagePath: images.imgViewAssignment,
    subtitle: "View Class Assignment",
    color: "#65C8CE",
    title: "VIEW ASSIGNMENT",
  },
  {
    linkPath: "/dashboard/ViewSyllabusStudentInit",
    imagePath: images.imgViewSyllabus,
    subtitle: "View Class Syllabus",
    color: "#65C8CE",
    title: "VIEW SYLLABUS",
  },
  {
    linkPath: "/dashboard/ViewPastPaperStudentInit",
    imagePath: images.imgViewPastPaper,
    subtitle: "View Class Past Paper",
    color: "#65C8CE",
    title: "VIEW PAST PAPER",
  },
  {
    linkPath: "/dashboard/ViewTestInfoStudent",
    imagePath: images.imgViewTestInfoStudent,
    subtitle: "View Short Test Related Information",
    color: "#65C8CE",
    title: "VIEW SHORT TEST INFO",
  },
];

let StaffModuleLMS_Dashboard_Links = [
  {
    linkPath: "/dashboard/StaffModuleLMSHomeworkDashboard",
    imagePath: images.imgAcademicHomeWork,
    subtitle: "Manage Class Homework",
    color: "#65C8CE",
    title: "HOMEWORK",
  },
  {
    linkPath: "/dashboard/StaffModuleLMSExamInfoDashboard",
    imagePath: images.imgAcademicExam,
    subtitle: "Manage Exam",
    color: "#65C8CE",
    title: "EXAM",
  },
  {
    linkPath: "/dashboard/StaffModuleLMSQuizInfoDashboard",
    imagePath: images.imgAcademicQuiz,
    subtitle: "Manage Class Quiz",
    color: "#65C8CE",
    title: "QUIZ",
  },
  {
    linkPath: "/dashboard/StaffModuleLMSAssignmentDashboard",
    imagePath: images.imgAcademicAssignment,
    subtitle: "Manage Class Assignment",
    color: "#65C8CE",
    title: "ASSIGNMENT",
  },
  {
    linkPath: "/dashboard/StaffModuleLMSLessonPlanDashboard",
    imagePath: images.imgAcademicLesson,
    subtitle: "Manage Class Lesson Plan",
    color: "#65C8CE",
    title: "LESSON PLAN",
  },
  {
    linkPath: "/dashboard/StaffModuleLMSSyllabusDashboard",
    imagePath: images.imgAcademicSyllabus,
    subtitle: "Manage Syllabus",
    color: "#65C8CE",
    title: "SYLLABUS",
  },
  {
    linkPath: "/dashboard/StaffModuleLMSPastPaperDashboard",
    imagePath: images.imgAcademicPastPaper,
    subtitle: "Manage Past Papers",
    color: "#65C8CE",
    title: "PAST PAPERS",
  },
  {
    linkPath: "/dashboard/StaffModuleLMSTestDashboard",
    imagePath: images.imgAcademicTest,
    subtitle: "Manage Class Test",
    color: "#65C8CE",
    title: "TEST",
  },
  {
    linkPath: "/dashboard/OnlineResourceStaffDashboard",
    imagePath: images.imgAcademicOnlineResource,
    subtitle: "Online Resource",
    color: "#65C8CE",
    title: "Online Resource",
  },
];

const StaffModuleLMS_Homework_Links = [
  {
    linkPath: "/dashboard/AddHomeWorkStaff",
    imagePath: images.imgAddHomework,
    subtitle: "Add Homework Data into System",
    color: "#65C8CE",
    title: "ADD HOMEWORK",
  },
  {
    linkPath: "/dashboard/ViewHomeWorkStaff",
    imagePath: images.imgViewHomework,
    subtitle: "View and Manage Homework Data",
    color: "#65C8CE",
    title: "VIEW HOMEWORK",
  },
];

const StaffModuleLMS_QuizInfo_Links = [
  {
    linkPath: "/dashboard/AddQuizInfoStaff",
    imagePath: images.imgAddQuizInfo,
    subtitle: "Add Quiz info Into System",
    color: "#65C8CE",
    title: "ADD QUIZ INFO",
  },
  {
    linkPath: "/dashboard/ViewQuizInfoStaff",
    imagePath: images.imgViewQuizInfo,
    subtitle: "View and Manage Quiz info",
    color: "#65C8CE",
    title: "VIEW QUIZ INFO",
  },
  {
    linkPath: "/dashboard/AddQuizStaff",
    imagePath: images.imgAddQuiz,
    subtitle: "Add Quiz Details Into System",
    color: "#65C8CE",
    title: "ADD QUIZ DETAILS",
  },
  {
    linkPath: "/dashboard/ViewQuizStaff",
    imagePath: images.imgViewQuiz,
    subtitle: "View and Manage Quiz Details",
    color: "#65C8CE",
    title: "VIEW QUIZ DETAILS",
  },
];

const StaffModuleLMS_Test_Links = [
  {
    linkPath: "/dashboard/AddTestInfoStaff",
    imagePath: images.imgAddTestInfo,
    subtitle: "Add Short Test info Into System",
    color: "#65C8CE",
    title: "ADD SHORT TEST INFO",
  },
  {
    linkPath: "/dashboard/ViewTestInfoStaff",
    imagePath: images.imgViewTestInfo,
    subtitle: "View and Manage Test info",
    color: "#65C8CE",
    title: "VIEW SHORT TEST INFO",
  },
  {
    linkPath: "/dashboard/AddTestStaff",
    imagePath: images.imgAddTest,
    subtitle: "Add Short Test Details Into System",
    color: "#65C8CE",
    title: "ADD SHORT TEST DETAILS",
  },
  {
    linkPath: "/dashboard/ViewTestStaff",
    imagePath: images.imgViewTest,
    subtitle: "View and Manage Test Details",
    color: "#65C8CE",
    title: "VIEW SHORT TEST DETAILS",
  },
];

const StaffModuleLMS_LessonPlan_Links = [
  {
    linkPath: "/dashboard/AddLessonPlanStaff",
    imagePath: images.imgAddLessonPlan,
    subtitle: "Add Lesson Plan Data into System",
    color: "#65C8CE",
    title: "ADD LESSON PLAN",
  },
  {
    linkPath: "/dashboard/ViewLessonPlanStaff",
    imagePath: images.imgViewLessonPlan,
    subtitle: "View and Manage Lesson Plan Data",
    color: "#65C8CE",
    title: "VIEW LESSON PLAN",
  },
];

const StaffModuleLMS_PastPaper_Links = [
  {
    linkPath: "/dashboard/AddPastPapersStaff",
    imagePath: images.imgAddPastPaper,
    subtitle: "Add Past Paper Data into System",
    color: "#65C8CE",
    title: "ADD PAST PAPER",
  },
  {
    linkPath: "/dashboard/ViewPastPapersStaff",
    imagePath: images.imgViewPastPaper,
    subtitle: "View and Manage Past Paper Data",
    color: "#65C8CE",
    title: "VIEW PAST PAPER",
  },
];

const StaffModuleLMS_Assignment_Links = [
  {
    linkPath: "/dashboard/AddAssignmentStaff",
    imagePath: images.imgAddAssignment,
    subtitle: "Add Assignment Data into System",
    color: "#65C8CE",
    title: "ADD ASSIGNMENT",
  },
  {
    linkPath: "/dashboard/ViewAssignmentStaff",
    imagePath: images.imgViewAssignment,
    subtitle: "View and Manage Assignment",
    color: "#65C8CE",
    title: "VIEW ASSIGNMENT",
  },
];

const StaffModuleLMS_Syllabus_Links = [
  {
    linkPath: "/dashboard/AddSyllabusStaff",
    imagePath: images.imgAddSyllabus,
    subtitle: "Add Syllabus Data into System",
    color: "#65C8CE",
    title: "ADD SYLLABUS",
  },
  {
    linkPath: "/dashboard/ViewSyllabusStaff",
    imagePath: images.imgViewSyllabus,
    subtitle: "View and Manage Syllabus Data",
    color: "#65C8CE",
    title: "VIEW SYLLABUS",
  },
];

const StudentModuleLMS_Homework_Links = [
  {
    linkPath: "/dashboard/ViewHomeWorkStudent",
    imagePath: images.imgViewHomework,
  },
];

const StaffModuleLMS_ExamInfo_Links = [
  {
    linkPath: "/dashboard/AddExamInfoStaff",
    imagePath: images.imgAddExamInfo,
    subtitle: "Add Exam Info into System",
    color: "#65C8CE",
    title: "ADD EXAM INFO",
  },
  {
    linkPath: "/dashboard/ViewExamInfoStaff",
    imagePath: images.imgViewExamInfo,
    subtitle: "View and Manage Exam Info",
    color: "#65C8CE",
    title: "VIEW EXAM INFO",
  },
  {
    linkPath: "/dashboard/AddExamDetailsStaff",
    imagePath: images.imgAddExamDetails,
    subtitle: "Add Exam Details into System",
    color: "#65C8CE",
    title: "ADD EXAM DETAILS",
  },
  {
    linkPath: "/dashboard/ViewExamDetailsStaff",
    imagePath: images.imgViewExamDetails,
    subtitle: "View and Manage Exam Details",
    color: "#65C8CE",
    title: "VIEW EXAM DETAILS",
  },
];

let DeptModuleLMS_Dashboard_Links = [
  {
    linkPath: "/dashboard/ViewExamInfoDept",
    imagePath: images.imgViewExamInfo,
    subtitle: "View and Manage Exam Info",
    color: "#65C8CE",
    title: "EXAM INFO",
  },
  {
    linkPath: "/dashboard/ViewExamDetailsDept",
    imagePath: images.imgViewExamDetails,
    subtitle: "View and Manage Exam Details",
    color: "#65C8CE",
    title: "EXAM DETAILS",
  },
  {
    linkPath: "/dashboard/ViewQuizInfoDept",
    imagePath: images.imgViewQuizInfo,
    subtitle: "View and Manage Quiz Info",
    color: "#65C8CE",
    title: "QUIZ INFO",
  },
  {
    linkPath: "/dashboard/ViewQuizDept",
    imagePath: images.imgViewQuiz,
    subtitle: "View and Manage Quiz Details",
    color: "#65C8CE",
    title: "QUIZ DETAILS",
  },
  {
    linkPath: "/dashboard/ViewLessonPlanDept",
    imagePath: images.imgViewLessonPlan,
    subtitle: "View and Manage Lesson Plan",
    color: "#65C8CE",
    title: "LESSON PLAN",
  },
  {
    linkPath: "/dashboard/ViewSyllabusDept",
    imagePath: images.imgViewSyllabus,
    subtitle: "View and Manage Syllabus",
    color: "#65C8CE",
    title: "SYLLABUS",
  },
  {
    linkPath: "/dashboard/ViewPastPaperDept",
    imagePath: images.imgViewPastPaper,
    subtitle: "View and Manage Past Papers",
    color: "#65C8CE",
    title: "PAST PAPERS",
  },
  {
    linkPath: "/dashboard/ViewTestInfoDept",
    imagePath: images.imgViewTestInfo,
    subtitle: "View and Manage Test Info",
    color: "#65C8CE",
    title: "TEST INFO",
  },
  {
    linkPath: "/dashboard/ViewTestDept",
    imagePath: images.imgViewTestDept,
    subtitle: "View and Manage Test Details",
    color: "#65C8CE",
    title: "TEST DETAILS",
  },
];

const HOD_Dashboard_Links = [
  {
    linkPath: "/dashboard/StaffLeaveDeptDashboard",
    imagePath: images.imgStaffLeave,
    subtitle: "Manage Staff Leave and Short Leave",
    color: "#8DC640",
    title: "STAFF LEAVE",
  },
  {
    linkPath: "/dashboard/DeptModuleLMSDashboard",
    imagePath: images.imgAcademicLMS,
    subtitle: "Manage Learning Management Systems",
    color: "#65C8CE",
    title: "LEARNING MANAGMENT SYSTEM",
  },
  {
    linkPath: "/dashboard/CMSHODDashboard",
    imagePath: images.imgAcademicCMS,
    color: "#8DC640",
    subtitle: "Competency Management System",
    title: "CMS",
  }

];

const CMS_HOD_Dashboard_Link=[
  {
    linkPath: "/dashboard/IndentHODDashboard",
    imagePath: images.imgAcademicIndent,
    subtitle: "Indent Information",
    color: "#8DC640",
    title: "INDENT FORM",
  },

  {
    subtitle: "Leave Reports",
    color: '#8DC640',
    title: "LEAVE",
    linkPath: "/dashboard/HODLeaveDashboard",
    imagePath: images.imgStaffLeave,
  },
  {
    linkPath: "/dashboard/CommunicationHODDashboard",
    headingImagePath: "",
    imagePath: images.imgCommuncationCoordinator,
    subtitle: "Manage Communications",
    color: "#61C4AE",
    title: "COMMUNICATION",
  },
  {
    linkPath: "/dashboard/ViewHrDevelopment",
    imagePath: images.imgHrDevelopmentMain,
    subtitle: "View Traning & Development",
    title: "STAFF TRAINING & DEVELOPMET",
  },
]
const Communication_Manager_Dashboard_Links = [
  {
    subtitle: "Compose New Message",
    color: "#61c4ae",
    title: "NEW MESSAGE",
    linkPath: "/dashboard/ManagerMessage",
    imagePath: images.imgNewMessage,
  },
  {
    subtitle: "View Messages",
    color: "#61c4ae",
    title: "INBOX",
    linkPath: "/dashboard/Inbox",
    imagePath: images.imgInbox,
  },
];

const Communication_Dean_Dashboard_Links = [
  {
    subtitle: "Compose New Message",
    color: "#61c4ae",
    title: "NEW MESSAGE",
    linkPath: "/dashboard/DeanManageMessage",
    imagePath: images.imgNewMessage,
  },
  {
    subtitle: "View Messages",
    color: "#61c4ae",
    title: "INBOX",
    linkPath: "/dashboard/DeanInbox",
    imagePath: images.imgInbox,
  },
];

const CMS_HOD_Msg =[
  {
    subtitle: "Compose New Message",
    color: "#61c4ae",
    title: "NEW MESSAGE TO COORDINATOR",
    linkPath: "/dashboard/HODMessage",
    imagePath: images.imgNewMessage,
  },
  {
    subtitle: "View Messages",
    color: "#61c4ae",
    title: "INBOX WITH COORDINATOR",
    linkPath: "/dashboard/HODInbox",
    imagePath: images.imgInbox,
  }
]
const CMS_Dean_Dashboard_Link=[
  {
    linkPath: "/dashboard/IndentDeanDashboard",
    imagePath: images.imgAcademicIndent,
    subtitle: "Indent Information",
    color: "#8DC640",
    title: "INDENT FORM",
  },

  {
    subtitle: "Leave Reports",
    color: '#8DC640',
    title: "LEAVE",
    linkPath: "/dashboard/DeanLeaveDashboard",
    imagePath: images.imgStaffLeave,
  },
  {
    linkPath: "/dashboard/CommunicationDeanDashboard",
    headingImagePath: "",
    imagePath: images.imgCommuncationCoordinator,
    subtitle: "Manage Communications",
    color: "#61C4AE",
    title: "COMMUNICATION",
  },
  {
    linkPath: "/dashboard/ViewHrDevelopment",
    imagePath: images.imgHrDevelopmentMain,
    subtitle: "View Traning & Development",
    title: "STAFF TRAINING & DEVELOPMET",
  }
]


const CMS_Manager_Dashboard_Links = [
  {
    linkPath: "/dashboard/IndentManagerDashboard",
    imagePath: images.imgAcademicIndent,
    subtitle: "Indent Information",
    color: "#8DC640",
    title: "INDENT FORM",
  },

  {
    subtitle: "Leave Reports",
    color: '#8DC640',
    title: "LEAVE",
    linkPath: "/dashboard/ManagerLeaveDashboard",
    imagePath: images.imgStaffLeave,
  },
  {
    linkPath: "/dashboard/CommunicationManagerDashboard",
    headingImagePath: "",
    imagePath: images.imgCommuncationCoordinator,
    subtitle: "Manage Communications",
    color: "#61C4AE",
    title: "COMMUNICATION",
  },
  {
    linkPath: "/dashboard/ViewHrDevelopment",
    imagePath: images.imgHrDevelopmentMain,
    subtitle: "View Traning & Development",
    title: "STAFF TRAINING & DEVELOPMET",
  }
];


const Staff_SID_Links = [
  {
    linkPath: "/dashboard/StudentSkillDashboard",
    headingImagePath: "",
    imagePath: images.imgAcademicSkill,
    subtitle: "Handle Student Skills Details",
    color: "#fcb01a",
    title: "STUDENT SKILL",
  },
  {
    linkPath: "/dashboard/DescriptiveDashboard",
    headingImagePath: "",
    imagePath: images.imgAcademicDescriptive,
    subtitle: "Handle Descriptive Report Details",
    color: "#fcb01a",
    title: "DESCRIPTIVE REPORT",
  },

  {
    linkPath: "/dashboard/StudentAttendanceDashboard",
    headingImagePath: "",
    imagePath: images.imgStudentAttendance,
    subtitle: "Handle Student Attendance Details",
    color: "#fcb01a",
    title: "STUDENT ATTENDANCE",
  },
  {
    linkPath: "/dashboard/ClassDiscilineDashboard",
    headingImagePath: "",
    imagePath: images.imgClassDiscipline,
    subtitle: "Handle Class Discipline Details",
    color: "#fcb01a",
    title: "CLASS DISCIPLINE",
  },
  {
    subtitle: "Mark Student Discipline",
    color: "#fcb01a",
    title: "STUDENT DISCIPLINE",
    linkPath: "/dashboard/StudentDisciplineDashboard",
    imagePath: images.imgViewSubDiscipline,
  },
  {
    subtitle: "Continouse Assessment",
    color: "#fcb01a",
    title: "CONTINUOUS ASSESSMENT",
    linkPath: "/dashboard/CADashboard",
    imagePath: images.imgCAImage,
  },
  {
    subtitle: "Student Progress Report",
    color: "#fcb01a",
    title: "STUDENT PROGRESS",
    linkPath: "/dashboard/StudentProgressReportDashboard",
    imagePath: images.DashboardStudentProgressReport,
  },
  {
    subtitle: "Leave Reports",
    color: "#fcb01a",
    title: "STUDENT LEAVE",
    linkPath: "/dashboard/LeaveDashboard",
    imagePath: images.imgAcademicLeave,
  },
  
];

const StaffLeave_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddStaffLeave",
    imagePath: images.imgAddStaffLeave,
    subtitle: "Add Leave Application",
    color: "#8DC640",
    title: "ADD LEAVE",
  },
  {
    linkPath: "/dashboard/ViewStaffLeave",
    imagePath: images.imgViewStaffLeave,
    subtitle: "View Leave Application",
    color: "#8DC640",
    title: "VIEW LEAVE",
  },
  {
    linkPath: "/dashboard/AddStaffShortLeave",
    imagePath: images.imgAddStaffShortLeave,
    subtitle: "Add Short Leave Application",
    color: "#8DC640",
    title: "ADD SHORT LEAVE",
  },
  {
    linkPath: "/dashboard/ViewStaffShortLeave",
    imagePath: images.imgViewStaffShortLeave,
    subtitle: "View Short Leave Application",
    color: "#8DC640",
    title: "VIEW SHORT LEAVE",
  },
];

const StaffLeaveHR_Dashboard_Links = [
  {
    linkPath: "/dashboard/ViewStaffLeaveForHR",
    imagePath: images.imgStaffLeaveUpdated,
    subtitle: "View and Manage Staff Short Leave Applcation",
    title: "VIEW STAFF LEAVE",
  },
  {
    linkPath: "/dashboard/ViewStaffShortLeaveForHR",
    imagePath: images.imgStaffShortLeaveUpdated,
    subtitle: "View and Manage Staff Short Leave Application",
    title: "VIEW STAFF SHORT LEAVE",
  },
];

const StaffLeaveDept_Dashboard_Links = [
  {
    linkPath: "/dashboard/ViewStaffLeaveDept",
    imagePath: images.imgViewStaffLeave,
    subtitle: "View Staff Leave Apllcation",
    color: "#8DC640",
    title: "VIEW STAFF LEAVE",
  },
  {
    linkPath: "/dashboard/ViewStaffShortLeaveDept",
    imagePath: images.imgViewStaffShortLeave,
    subtitle: "View Staff Short Leave Application",
    color: "#8DC640",
    title: "VIEW STAFF SHORT LEAVE",
  },
];

const StaffModuleCMS_Dashboard = [
  {
    linkPath: "/dashboard/StaffLeaveDashboard",
    imagePath: images.imgStaffLeave,
    subtitle: "View and Manage Leave and Short Leave",
    color: "#8DC640",
    title: "STAFF LEAVE",
  },
];

const CMS_Staff_Dashboard_Links = [
  {
    linkPath: "/dashboard/IndentStaffDashboard",
    imagePath: images.imgAcademicIndent,
    subtitle: "Indent Information",
    color: "#8DC640",
    title: "INDENT FORM",
  },
  {
    linkPath: "/dashboard/StaffLeaveDashboard",
    imagePath: images.imgStaffLeave,
    subtitle: "View and Manage Leave and Short Leave",
    color: "#8DC640",
    title: "STAFF LEAVE",
  },
  {
    linkPath: "/dashboard/ViewEvaluationByStaff",
    imagePath: images.imgAcademicTeacherEvaluation,
    subtitle: "View Evaluation Report",
    color: "#8DC640",
    title: "Evaluation Report",
  },,

  // {
  //   linkPath: "/dashboard/CommunicationManagerDashboard",
  //   headingImagePath: "",
  //   imagePath: images.imageModuleCommunicationCMS,
  //   subtitle: "Manage Communications",
  //   color: "#8DC640",
  //   title: "COMMUNICATION",
  // },
  {
    linkPath: "/dashboard/BioMetricStaffDashboard",
    headingImagePath: "",
    imagePath: images.imgBioMetric,
    subtitle: "Manage Biometric Attendance",
    color: "#8DC640",
    title: "BIOMETRIC STAFF ATTENDANCE",
  },
  {
    linkPath: "/dashboard/StaffTimeTable",
    imagePath: images.imgStaffTimeTable,
    subtitle: "View Staff Timetable",
    color: "#8DC640",
    title: "TIMETABLE",
  },
  {
    linkPath: "/dashboard/ViewTourByStaff",
    imagePath: images.imgTourG,
    subtitle: "View Tours",
    color: "#8DC640",
    title: "TOURS",
  },
  {
    linkPath: "/dashboard/StaffEventView",
    imagePath: images.imgEventG,
    subtitle: "View Events",
    color: "#8DC640",
    title: "EVENTS",
  },
  {
    linkPath: "/dashboard/EventCertificateByStaff",
    imagePath: images.imgEventCertificateG,
    subtitle: "View Event Certificates",
    color: "#8DC640",
    title: "EVENT CERTIFICATES",
  },
];

const BioMetric_Dashboard_Links = [
  {
    linkPath: "/dashboard/BioMetricRegistered",
    imagePath: images.imgBioMetricRegistered,
    title: "BIOMETRIC REGISTERED",
    subtitle: "Manage Biometric Registered Staff",
    color: "#314857",
  },
  {
    linkPath: "/dashboard/BioMetricAttendance",
    imagePath: images.imgBioMetricRegistered,
    color: "#314857",
    subtitle: "Manage Biometric Attendances",
    title: "BIOMETRIC ATTENDANCE",
  },
];

const BioMetric_Staff_Dashboard_Links = [
  {
    linkPath: "/dashboard/BioMetricAttendance",
    imagePath: images.imgBioMetricAttendance,
    color: "#8DC640",
    subtitle: "Manage Biometric Attendances",
    title: "BIOMETRIC ATTENDANCE",
  },
];

const System_Smart_Dashboard_Links = [
  {
    linkPath: "/dashboard/AddLoginTimeLimit",
    imagePath: images.imgAddLoginTime,
    color: "#9999CB",
    subtitle: "Add Login Time Limit",
    title: "ADD TIME LIMIT",
  },
  {
    linkPath: "/dashboard/ViewLoginTimeLimit",
    imagePath: images.imgViewLoginTime,
    color: "#9999CB",
    subtitle: "Manage Login Time Limit",
    title: "VIEW TIME LIMIT",
  },
];

const Floor_Plan_Links = [
  {
    linkPath: "/dashboard/Floor",
    imagePath: images.imgAddFloorCampusMaintenance,
    color: "#fcb01a",
    subtitle: "Manage Floors",
    title: "FLOOR",
  },
  {
    linkPath: "/dashboard/Room",
    imagePath: images.imgAddRoomCampusMaintenance,
    color: "#fcb01a",
    subtitle: "Manage Rooms",
    title: "ROOM",
  },
];

const StudentDisciplineDashboard_link = [
  {
    linkPath: "/dashboard/StudentDisciplineRecord",
    imagePath: images.imgViewSubDiscipline,
    color: "#fcb01a",
    subtitle: "Add Student Discipline Marks ",
    title: "ADD STUDENT DISCIPLINE",
  },
  {
    linkPath: "/dashboard/EditStudentDisciplineRecord",
    imagePath: images.imgViewSubDiscipline,
    color: "#fcb01a",
    subtitle: "Edit Student Discipline Marks",
    title: "EDIT STUDENT DISCIPLINE",
  },
  {
    linkPath: "/dashboard/ViewStudentDiscipline",
    imagePath: images.imgViewSubDiscipline,
    color: "#fcb01a",
    subtitle: "View Student Discipline Marks",
    title: "View STUDENT DISCIPLINE",
  },
];

const SpecialEducationDashboard_link = [
  {
    linkPath: "/dashboard/SpecialEducation",
    imagePath: images.imgAddSpecialEducation,
    color: "#287ABF",
    subtitle: "Add Special Needs",
    title: "Add Special Needs",
  },
  {
    linkPath: "/dashboard/ViewSpecialEducationRecords",
    imagePath: images.imgViewSpecialEducation,
    color: "#287ABF",
    subtitle: "View Special Needs",
    title: "View Special Needs",
  },
];

const SpecialEducationDashboard_link_Admin = [
  {
    linkPath: "/dashboard/SpecialEducationAdmin",
    imagePath: images.imgAddSpecialEducation,
    color: "#287ABF",
    subtitle: "Add Special Needs",
    title: "Add Special Needs",
  },
  {
    linkPath: "/dashboard/ViewSpecialEducationRecordAdmin",
    imagePath: images.imgViewSpecialEducation,
    color: "#287ABF",
    subtitle: "View Special Needs",
    title: "View Special Needs",
  },
];


export default {
  hrLinks,
  PayRoll_Dashboard_Links,
  StaffModules_Links,
  ownerLinks,
  academicLinks,
  timetableLinks,
  LPWLinks,
  financeLinks,
  budgetLinks,
  HrsmartLinks,
  academicLmsLink,
  timeTable_module_links,
  timeTable_module_links2,
  academicCMSLinks,
  academicSIDLinks,
  Communication_Dashboard_Links,
  Communication_Staff_Dashboard_Links,
  Communication_Parent_Dashboard_Links,
  ParentPortalManager_Links,
  Admission_Smart_Links,
  Student_Admission_Links,
  Mangers_Link,
  Accounts_Dashboard_Links,
  Fees_Dashboard_Links,
  homeWorkDashlinks,
  caModuleLinks,
  Finance_Manager_Link,
  FinanceFeeReportLinks,
  Billing_Dashboard_Links,
  Library_Dashboard_Links,
  Event_Dashboard_Links,
  StudentReport_Dashboard_Links,
  Campus_Maintenance_Dashboard_Links,
  Maintenance_Coordiantor_Dashboard_Links,
  SENManager_Dashboard_Links,
  SENManager_Links,
  WellBeingManager_Dashboard_Links,
  WellBeingManager_Report_Links,
  Pastrol_Dashboard_links,

  Class_Dashboard_Links,
  Section_Dashboard_Links,
  Session_Dashboard_Links,
  Subject_Dashboard_Links,
  ClassTeacher_Dashboard_Links,
  Indent_Dashboard_Links,
  IndentStaff_Dashboard_Links,
  TeacherEvaluation_Dashboard_Links,
  PastoralsLinks,
  Medical_Dashboard_Links,
  Doctor_Dashboard_Links,
  Patient_Dashboard_Links,
  CampusSecurity_Dashboard_Links,
  Leave_Dashboard_Links,
  StudentLeave_Dashboard_Links,
  SecurityManager_Links,
  ProcurementManager_Links,
  Tour_Dashboard_Links,
  Activity_Dashboard_Links,
  Certificate_Dashboard_Links,
  OtherCertificate_Dashboard_Links,
  CertificateCategory_Dashboard_Links,
  Award_Dashboard_Links,
  Game_Dashboard_Links,
  OffGame_Dashboard_Links,
  Entry_Exam_Dashboard_Links,
  Event_Certificate_Dashboard_Links,
  Student_Coordinator_Dashboard_Links,
  Transport_Coordinator_Dashboard_Links,
  Vehicle_Dashboard_Links,
  Driver_Dashboard_Links,
  Transport_Maintenance_Dashboard_Links,
  Routes_Dashboard_Links,
  Staff_Coordinator_Dashboard_Links,
  Assign_Subject_Dashboard_Links,
  Assign_HOD_Dashboard_Links,
  Exclusion_Dashboard_Links,
  Student_Profile_Dashboard_Links,
  Assign_Coordinator_Dashboard_Links,
  budget_Dashboard_Links,
  Census_Links,
  Inventory_Links,
  Staff_Dashboard_Links,
  Canteen_Links,
  Administration_Dashboard_Links,
  Skill_Dashboard_Links,
  Descriptive_Dashboard_Links,
  Attendance_Dashboard_Links,
  Class_Discipline_Dashboard_Links,
  Campus_Dashboard_Links,
  FrontDesk_Dashboard,
  FrontDesk_Student_Dashboard,
  Hr_Development_Links,
  Department_Links,
  Post_Links,
  Student_Dashboard_Links,
  Parent_Dashboard_Links,

  HOD_Dashboard_Links,
  StaffModuleLMS_Dashboard_Links,
  StaffModuleLMS_Homework_Links,
  StaffModuleLMS_QuizInfo_Links,
  StaffModuleLMS_LessonPlan_Links,
  StaffModuleLMS_Syllabus_Links,
  StaffModuleLMS_PastPaper_Links,
  StaffModuleLMS_Assignment_Links,
  StaffModuleLMS_ExamInfo_Links,
  DeptModuleLMS_Dashboard_Links,
  StaffModuleLMS_Test_Links,

  StudentModuleLMS_Dashboard_Links,
  StudentModuleLMS_Homework_Links,

  Communication_Manager_Dashboard_Links,
  CMS_Manager_Dashboard_Links,
  Staff_SID_Links,

  StaffLeave_Dashboard_Links,
  StaffLeaveDept_Dashboard_Links,
  StaffLeaveHR_Dashboard_Links,
  StaffModuleCMS_Dashboard,
  CMS_Staff_Dashboard_Links,

  BioMetric_Dashboard_Links,
  BioMetric_Staff_Dashboard_Links,
  System_Smart_Dashboard_Links,

  Medical_Dashboard_Links_For_Manager,
  
  Floor_Plan_Links,
  Floor_Link,
  Room_Link,
  StudentDisciplineDashboard_link,
  Accountant_Dashboard_Links,
  AdminCMSDashboard_Link,
  IndentAdminDashboard_Link,
  AdminLeaves_Link,
  IndentManagerDashboard_Link,
  IndentHODDashboard_Link,
  ManagerLeaves_Link,
  News_Dashboard_Links,
  Grade_Link_Coordinator,
  Student_Admin_Admission_Link,
  Entry_Exam_Admin_Link,
  FrontDesk_Student_Admin_Dashboard,
  Billing_Dashboard_Admin_Links,
  CMS_Dean_Dashboard_Link,
  IndentDeanDashboard_Link,
  DeanLeaves_Link,
  Dean_Leaves_Link,
  Communication_Dean_Dashboard_Links,
  CMS_HOD_Dashboard_Link,
  HODLeaves_Link,
  CMS_HOD_Msg,

  academicPlan,
  caModuleLinksAdmin,
  FamilyTreeLink,
  Fees_Type_Links,
  OnlineResourceLinks,
  OnlineResourceLinks_Staff,
  SpecialEducationDashboard_link,
  SpecialEducationDashboard_link_Admin
};

import React, { Component } from "react";
import * as PostActions from "../../Actions/Post";
import * as sharedActions from "../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateandTime from "../Transport/DateandTime";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AdminName: "",
      AdminType: "",
      postName: "",
      departmentId: "",
      select: "",
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.select !== nextProps.selectData) {
      return {
        select: nextProps.selectData,
      };
    }
    return null;
  }

  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      departmentId: result.DepartmentId,
    });
  };

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { postName, departmentId } = this.state;
    if (Object.is(postName, "") || Object.is(departmentId, "")) {
      this.setState({
        errorMsg: true,
      });
    } else {
      const data = {
        postName,
        departmentId,
        accountType: this.props.role,
        accountId: this.props.name,
      };
      this.props.sharedActions
        .addRecord("/api/Post", data, this.props.PostActions.addPost)
        .then((success) => {
          this.setState({
            errorMsg: false,
            postName: "",
            msg: true,
          });
        })
        .catch((error) => {});
      console.log(
        this.state.vehicleNo,
        this.state.transportType,
        this.state.capacity
      );
    }
    console.log(this.state);
  };
  componentDidMount() {
    this.props.sharedActions
      .gAllData(
        "/api/Department/All",
        this.props.PostActions.getDepartmentForPost
      )
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div>
        <section class="mb-4">
          <h2 class="h1-responsive font-weight-bold text-center my-4">
            Add Post
          </h2>

          <p class="text-center w-responsive mx-auto mb-5">School Smart ®</p>

          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ThemeContext.Consumer>
              {(context) => (
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  {this.state.errorMsg ? (
                    <p style={{ color: "red" }}>
                      Please fill the form properly!
                    </p>
                  ) : (
                    ""
                  )}
                  {this.state.msg ? (
                    <p style={{ color: "green" }}>Post Added Successfully!</p>
                  ) : (
                    ""
                  )}

                  <form id="contact-form" name="contact-form">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <DateandTime />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-user-shield animated fadeIn"
                        /> */}
                            Admin Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            name="AdminName"
                            value={this.props.name}
                            readOnly={true}
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-user-alt animated fadeIn"
                        /> */}
                            Admin Type
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            value={this.props.role}
                            readOnly={true}
                            type="text"
                            name="AdminType"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i className="fas fa-user" style={{ marginRight: 4 }} /> */}
                            Post Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Post Name"
                            type="text"
                            name="postName"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-map animated fadeIn"
                        /> */}
                            Department Name
                          </label>
                          <select
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            onChange={this.onSelectChange}
                            class="custom-select"
                            name="routeId"
                          >
                            <option selected={this.state.msg ? true : false}>
                              Choose Department...
                            </option>
                            {this.state.select.map((item, index) => (
                              <option
                                key={index}
                                value={`${JSON.stringify(item)}`}
                              >
                                {item.departmentName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                    <div class="text-center text-md-right">
                      <button
                        onClick={this.onHandleSubmit}
                        class="buttonHover2"
                        style={{
                          background: context.themeColors.primaryColor,
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>

                  <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
          </div>
        </section>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    PostActions: bindActionCreators(PostActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    auth: state.setUserReducer.auth,
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
    accountId: state.setUserReducer.users.accountId,
    selectData: state.setPostReducer.select,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPost);

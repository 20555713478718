import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general';
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = [
	'Name',
	'Class Name',
	'Section',
	'Subject',
	'Department',
	'Actions'
];

let renderFields = ['name', 'className', 'section', 'subjectName', 'postHOD'];

const ViewStaffInHOD = props => {
    const themeColor = useContext(ThemeContext);

	const [enabled, setEnabled] = useState('false');
	const [id, setId] = useState('');
	const [modalData, setModalData] = useState([]);
	const [staffData, setStaffData] = useState([]);
	const [hodData, setHODData] = useState([]);
	const [accountType, setAccountType] = useState(props.role);
	const [accountId, setAccountId] = useState(props.name);
	const [hodId, setHODId] = useState('');
	const [postHODId, setPostHODId] = useState('');
	const [update, setUpdate] = useState('');

	const [isHODLoading, HOD] = httpWithRequiredSetData(
		'/api/HODPost',
		[],
		data => {
			setHODData(data);
		}
	);

	const [searchURL, setSearchURL] = useState(
		'/api/EnrollStaffInHOD/?hodPostId=&pageNumber=1&pageSize=10'
	);

	const [disableBPage, setdisableBPage] = useState(true);
	const [customPageSize, setCustomPageSize] = useState('10');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumber, setPageNumber] = useState([]);
	const [disablepage, setdisablepage] = useState(false);
	const [pagination, setPaginationData] = useState({});

	const [
		isLoading,
		fetchData,
		setFetchData,
		setIsLoading
	] = httpWithRequiredSetData(searchURL, [], data => {
		let pageNumber = [];
		for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
			pageNumber.push(i);
		}
		setPageNumber(pageNumber);
		setStaffData(data['staffList']);

		setPaginationData(data['paginationMetadata']);
	});

	useEffect(() => {
		return () => {
			$('[data-toggle="tooltip"]').tooltip('hide');
		};
	}, []);

	const handleClickNextFrwd = () => {
		console.log('pagelength: ', pageNumber.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumber.length) {
			setdisablepage(true);
		} else {
			setCurrentPage(prevState => prevState + 1);
			console.log(currentPage);

			setdisableBPage(false);
		}
	};

	const onHandleChange = e => {
		if (e.target.name === 'hodId') {
			if (e.target.value !== 'Select Department') {
				setHODId(e.target.value);
			}
		} else if (e.target.name === 'postHODId') {
			if (e.target.value !== 'Select Department') {
				setPostHODId(e.target.value);
			}
		}
	};

	useEffect(() => {
		console.log('Do something after counter has changed', currentPage);
		setIsLoading(true);
		props.sharedActions
			.getDataWithoutDispatch(
				general.condtionalServicesStaffInHOD(hodId, currentPage, customPageSize)
			)
			.then(success => {
				setStaffData(success['staffList']);
				setIsLoading(false);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);
			})
			.catch(error => {
				setIsLoading(false);
				setStaffData([]);
			});
	}, [currentPage, hodId, update, customPageSize]);

	const handleClickNext = event => {
		setCurrentPage(Number(event.target.id));
		setdisableBPage(false);
		setdisableBPage(false);
	};
	const handleClickNextBack = () => {
		if (currentPage === 1) {
			setdisableBPage(true);
			setdisablepage(false);
		} else {
			setCurrentPage(prevState => prevState - 1);
		}
	};
	const [msg, setSnackMsg] = useState('');

	const onChangePageSize = e => {
		if (e.target.value !== 'Page Entries') {
			setCustomPageSize(e.target.value);
			setCurrentPage(1);
		}
	};

	const onEditClick = itemId => {
		onTakeItem(itemId);
		setEnabled(false);
	};

	const onTakeItem = itemId => {
		setModalData([]);

		let getData = staffData.filter(
			item => parseInt(item.id) === parseInt(itemId)
		);

		setModalData(getData);
		setPostHODId(getData[0].hodPostId);
		setId(getData[0].id);
	};

	const onClickDelete = id => {
		setIsLoading(true);
		console.log(id);

		props.sharedActions
			.deleteRecordWithoutDispatchWithoutSlash(
				`/api/RemoveStaffFromHOD/?id=`,
				id
			)
			.then(success => {
				// if (success.status === 200) {
				let getNewFilterArray = staffData.filter(item => item.id !== id);
				setSnackMsg('Remove Successfully!');
				setStaffData(getNewFilterArray);
				setIsLoading(false);
				props.snackbar();
				setId(null);
				// }
			})
			.catch(error => {
				setIsLoading(false);
				console.log(error);
			});
	};

	const onHandleSubmitUpdate = e => {
		e.preventDefault();

		const data = {
			hodPostId: postHODId
		};

		props.sharedActions
			.patchParticularRecord(`/api/EditHodPost/?id=${id}`, data)
			.then(success => {
				staffData.forEach((item, index) => {
					if (item.id == id) {
						staffData.splice(index, 1, modalData[0]);
					}
				});
				setEnabled(false);
				setSnackMsg('Edited Record Successfully!');
				props.snackbar();
				setTimeout(() => {
					setUpdate(id);
				}, 1000);
			});
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor
					}}
					scope="row"
				>
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${themeColor.themeColors.primaryColor}`,
							padding: 2,
							borderColor: themeColor.themeColors.primaryColor
						}}
					>
						{item[item1]}
					</td>
				))}
				<td
					style={{
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeColor.themeColors.primaryColor
					}}
				>
					<a href="#" data-toggle="tooltip" title="Edit!">
						<button
							id="firstbutton"
							onClick={() => onEditClick(item.id)}
							data-toggle="modal"
							data-target="#myModal"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
						>
							<i class="fas fa-pen"></i>
						</button>
					</a>

					<a href="#" data-toggle="tooltip" title="Delete!">
						<button
							onClick={() => onEditClick(item.id)}
							data-toggle="modal"
							data-target="#myModal1"
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								background: 'none',
								marginLeft: 5,
								marginBottom: 5
							}}
						>
							<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
						</button>
					</a>
				</td>
			</tr>
		));

	return (
		<div>
			<SnackBar msg={msg} />
			<div class="modal" id="myModal1">
				<div class="modal-dialog">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Confirm
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<div>
								<div style={{ textAlign: 'center' }}>
									<h4>Are you sure ?</h4>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly'
									}}
								>
									<div>
										<button
											// disabled={this.state.enabled}
											data-dismiss="modal"
											onClick={() => onClickDelete(id)}
											type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
										>
											Yes
										</button>
									</div>
									<div>
										<button
											data-dismiss="modal"
											// disabled={this.state.enabled}
											type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal" id="myModal">
				<div class="modal-dialog modal-md modal-dialog-centered">
					<div class="modal-content modal_content_custom">
						<div
							style={{
								borderBottomLeftRadius: 30,
								borderBottomRightRadius: 30,
								borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
							}}
							class="modal-header modal_custom_header"
						>
							<h4 style={{ color: 'white' }} class="modal-title">
								Details
							</h4>
							<button
								style={{ color: 'white' }}
								type="button"
								class="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div class="modal-body">
							<form onSubmit={e => onHandleSubmitUpdate(e)}>
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<fieldset disabled={!enabled}>
												<div class="row">
													<SelectionInput
														newFlag="customWidth"
														feildName="postHODId"
														selectName={'Department'}
														selectedText={'Select Department'}
														optionsArrys={hodData}
														errors={{}}
														isValidte={false}
														stateData={{
															postHODId
														}}
														optionType="dynamicWithPropIdAndName"
														property={'postHOD'}
														propertyId={'id'}
														onHandleChange={e => onHandleChange(e)}
														useFlag={false}
													/>
												</div>
											</fieldset>
											<br />
											<br />
											<button
												disabled={enabled}
												onClick={() => setEnabled(true)}
												type="button"
                                                class="btn btn-primary buttonAppear"
                                                style={{background: themeColor.themeColors.primaryColor}}
											>
												Edit
											</button>
											<button
												style={{ marginLeft: 5, background: themeColor.themeColors.primaryColor }}
												disabled={!enabled}
												type="submit"
												class="btn btn-primary buttonAppear"
											>
												Save
											</button>
										</div>
									))}
							</form>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								style={{ background: '#00435D' }}
								class="btn btn-danger"
								data-dismiss="modal"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>

			<div style={{ textAlign: 'right' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
							{' '}
							Assigned Departments{' '}
						</h2>
					</div>
					<div
						style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
					>
						<Pagination
							disableBPage={disableBPage}
							disablepage={disablepage}
							currentPage={currentPage}
							pageNumber={pageNumber}
							handleClickNext={handleClickNext}
							handleClickNextBack={handleClickNextBack}
							handleClickNextFrwd={handleClickNextFrwd}
						/>
						<div
							style={{
								width: 'fit-content',
								marginTop: -34,
								display: 'flex',
								justifyContent: 'flex-end',
								marginLeft: 'auto'
							}}
						>
							<SelectionInput
								selectName="Entries"
								selectFont={14}
								selectFontWeight={'bold'}
								newFlag="customWidth"
								feildName="customPageSize"
								selectedText={'Page Entries'}
								optionsArrys={['10', '20', '30', '40', '50']}
								errors={{}}
								isValidte={false}
								stateData={{
									customPageSize: customPageSize
								}}
								optionType="static"
								onHandleChange={onChangePageSize}
								useFlag={false}
							/>
						</div>
					</div>
				</div>
				<CustomFilterSectionLayout displayDirection="column">
					<div
						style={{
							width: '30%',
							margin: '0 auto',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<React.Fragment>
							<SelectionInput
								newFlag="customWidth"
								feildName="hodId"
								selectName={'Department'}
								selectedText={'Select Department'}
								optionsArrys={hodData}
								errors={{}}
								isValidte={false}
								stateData={{
									hodId
								}}
								optionType="dynamicWithPropIdAndName"
								property={'postHOD'}
								propertyId={'id'}
								onHandleChange={e => onHandleChange(e)}
								useFlag={false}
							/>
						</React.Fragment>
					</div>
				</CustomFilterSectionLayout>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {pagination['totalPages']}
					</span>
				</div>
			</div>
			{isLoading ? (
				<Spinner />
			) : staffData.length < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								{tHead.map(item => (
									<th
										style={{
											border: `1px solid ${themeColor.themeColors.primaryColor}`,
											borderColor: Colors.WHITE
										}}
										scope="col"
									>
										{item}
									</th>
								))}
							</tr>
						</thead>
						<tbody>{bodyRendering(staffData)}</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStaffInHOD);

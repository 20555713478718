import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import Header from '../../Assets/images/3 headers-03.png';
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";


class PrintLessonPlanStaff extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: false,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

    }

    static contextType = ThemeContext

    componentDidMount(){
        console.log(this.props.testInfoData);
    }

    
    PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
		setTimeout(function() {
			window.location.reload();
		}, 700);
	};


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    render(){
        const { isLoading, accountId, accountType } = this.state;
        const { testInfoData, date, className, section, term,currentSession } = this.props.location.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderSyllabusData = testInfoData && testInfoData.test.map((item, index) => {
            return (
                <tr key={index} >
                <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.className}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.section}
                    </td>                   
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.gradeName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.subjectName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.sessionName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.term}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.testType}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.testName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.testDate}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.testTime}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.duration}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.totalMarks}
                    </td>
            </tr>
           
                
                );
            
        }) 
 

        return (
            <div className="page-header"> 
                <div id="printDiv" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row" >
								<div
									style={{
                                        width: '100%',
                                        height: '100%'
									}}
								>
									<img src={Header} width={'100%'} height={'100%'} />
								</div>
						</div>

                    <div class="row" style={{marginTop: '2%'}} >
							<div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h3>View Short Test Info</h3>
							</div>
						</div>

                        <div class="row my-3" >
                        <div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h5>{date}</h5>
							</div>
                            </div>

                    <div class="my-2 py-2 px-2" style={{
                        border: `3px solid ${this.context.themeColors.primaryColor}`,
                    }} >
                        <div class="row">
                            
                            <div class="col-md-3" >
                                    <span>Class: <strong style={{color: this.context.themeColors.primaryColor}} >{className}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Section: <strong style={{color: this.context.themeColors.primaryColor}} >{section}</strong></span>     
                            </div>
                            
                            <div class="col-md-3" >
                                    <span>Session: <strong style={{color: this.context.themeColors.primaryColor}} >{currentSession}</strong></span>     
                            </div>
                            
                            <div class="col-md-3" >
                                    <span>Term: <strong style={{color: this.context.themeColors.primaryColor}} >{term}</strong></span>     
                            </div>
                            
                            <div class="col-md-3" >
                                    <span>Page No: <strong style={{color: this.context.themeColors.primaryColor}} >{testInfoData && testInfoData.paginationMetadata.currentPage} - {testInfoData && testInfoData.paginationMetadata.totalPages}</strong></span>     
                            </div>
                        </div>
                        
                       
                    </div>
                    <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                        
                    </div>
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
                            <th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Grade
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Subject
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Session
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Term
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Test Type
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Test Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Test Date
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Test Time
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Test Duration
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Test Marks
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderSyllabusData}</tbody>
					</table>
				</div>
            </div>
            
            <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-12">
                            
                <div>
                  <div class="">
                    <div class="text-left">Printed By: {this.props.userData.unique_name}</div>
                    <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
                      Powered by School Smart®
                    </div>
                  </div>
                </div>
                </div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-10">
                    
                    </div>
                    <div class="col-md-2">
                    <button class="btn btn-primary buttonAppear" onClick={() => this.PrintContent('printDiv')}>
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintLessonPlanStaff);

import React, { Component } from "react";
import $ from "jquery";
import "../Admin/viewAdmin.css";
import Axios from "axios";
import config from "../../config/config";
import { connect } from "react-redux";
import SnackBar from "../../components/SnackBar/SnackBar";
import ThemeContext from "../../context/themeContext/ThemeContext";
import DatePicker from "react-date-picker";
import Moment from "moment";

const AdminDetails = [
  {
    username: "Muhammad Hamza",
    role: "Admin",
    id: 1,
  },
  {
    username: "Muhammad Hamza",
    role: "Admin",
    id: 1,
  },
  {
    username: "Muhammad Hamza",
    role: "Admin",
    id: 1,
  },
  {
    username: "Muhammad Hamza",
    role: "Admin",
    id: 1,
  },
  {
    username: "Muhammad Hamza",
    role: "Admin",
    id: 1,
  },
  {
    username: "Muhammad Sumair",
    role: "Admin",
    id: 2,
  },
  {
    username: "Asad Akhtar",
    role: "Admin",
    id: 3,
  },
];
class ViewCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      modalData: null,
      name: "",
      description: "********",
      name: "",
      enabled: false,
      id: null,
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      msg: "",
      fromdate: "",
      todate: "",
      searchFilter: false,
      categoryName: "",
      changeIndex: 4
    };
  }

  static contextType = ThemeContext;

  // TODO make this code reuseable
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
    if (currentPage > 4) {
      debugger;
      this.setState({
        changeIndex: +this.state.changeIndex + 1,
      })
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;

    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        changeIndex: +this.state.changeIndex - 1,
        currentPage: prevState.currentPage - 1,
      }));
    }
  };
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    let token = "";
    if (localStorage.jwtToken) {
      token = localStorage.getItem("jwtToken");
    }
    Axios.get(`${config.localhttp}/api/InventoryCategories`, {
      headers: { Authorization: `Bearer  ${token}` },
    })
      .then((success) => {
        console.log(success);
        this.setState({
          isLoading: false,
          adminData: success.data,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        console.log(error);
      });
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    console.log(itemId);

    const { adminData } = this.state;
    let token = "";
    if (localStorage.jwtToken) {
      token = localStorage.getItem("jwtToken");
    }
    var Headconfig = {
      headers: { Authorization: "Bearer " + token },
    };
    var bodyParameters = { id: itemId };

    Axios.delete(`${config.localhttp}/api/InventoryCategories/${itemId}`, {
      data: { id: itemId }, // or data: jdId, depending on how you handle it in the back end
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          let getData = adminData.filter((item) => item.id !== itemId);

          this.setState({
            adminData: getData,
            msg: "Delete Record Successfully!",
          });
          this.props.snackbar();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      name: getData[0].name,
      description: getData[0].description,
      id: getData[0].id,
    });
  };
  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  onHandleSubmit = (e) => {
    e.preventDefault();
    const { name, description, id } = this.state;
    let token = "";
    if (localStorage.jwtToken) {
      token = localStorage.getItem("jwtToken");
    }
    var Headconfig = {
      headers: { Authorization: "Bearer " + token },
    };
    var bodyParameters = {
      id,
      name,
      description,
      date: new Date().toDateString(),
      time: new Date().toLocaleTimeString(),
      accountId: this.props.data.unique_name,
      accountType: this.props.data.role,
    };

    Axios.put(
      `${config.localhttp}/api/InventoryCategories/${id}`,
      bodyParameters,
      Headconfig
    )
      .then((response) => {
        console.log(response);

        if (response.status == 200) {
          var admindata = [...this.state.adminData];
          var index = admindata.findIndex((obj) => obj.id === id);
          admindata[index].name = name;
          admindata[index].description = description;
          this.setState({
            adminData: admindata,
            enabled: false,
            msg: "Edit Record Successfully!",
          });
          this.props.snackbar();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };

  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
    window.location.reload();
  };
  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    let FromDate = Moment(this.state.fromdate).format("MM/DD/YYYY");
    let ToDate = Moment(this.state.todate).format("MM/DD/YYYY");
    let token = "";
    if (localStorage.jwtToken) {
      token = localStorage.getItem("jwtToken");
    }

    if (this.state.searchFilter) {
      Axios.get(`${config.localhttp}/api/InventoryCategories/?name=${this.state.categoryName}`, {
        headers: { Authorization: `Bearer  ${token}` },
      })
        .then((success) => {
          debugger;
          this.setState({
            adminData: success.data,
            isLoading: false,
            btnLoad: false,
            searchCheck: false,
          });
        })
        .catch((err) => {
          this.setState({
            adminData: [],
            errorText: err.response.data.Message,
            isLoading: false,
            btnLoad: false,
            searchCheck: true,
            badRequest: true,
          });

          console.log(err);
        });
    }
    else {
      Axios.get(`${config.localhttp}/api/ICatDate/?fromDate=${FromDate}&toDate=${ToDate}`, {
        headers: { Authorization: `Bearer  ${token}` },
      })
        .then((success) => {
          debugger;
          this.setState({
            adminData: success.data,
            isLoading: false,
            btnLoad: false,
            searchCheck: false,
          });
        })
        .catch((err) => {
          this.setState({
            errorText: err.response.data.Message,
            isLoading: false,
            btnLoad: false,
            searchCheck: true,
            badRequest: true,
          });

          console.log(err);
        });
    }

  };
  changeDate = (date, state) => {
    this.setState({
      [state]: date,
    });
    console.log(date);
  };
  render() {
    const { adminData, modalData } = this.state;
    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= this.state.changeIndex ? (
        <ThemeContext.Consumer>
          {(context) => (
            <li
              class={`page-item `}
            >
              <a
                key={number}
                id={number}
                onClick={this.handleClickNext}
                class="page-link "
                href="#"
                style={{
                  background: `${this.state.currentPage === number ? context.themeColors.primaryColor : ""
                    }`,
                  color: `${this.state.currentPage === number
                    ? "#fff"
                    : context.themeColors.primaryColor
                    }`,
                }}
              >
                {number}
              </a>
            </li>
          )}
        </ThemeContext.Consumer>
      ) : (
        ""
      );
    });
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = currentTodos.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{item.index}</th>
          <td>{item.name}</td>
          <td>{item.description}</td>
          <td>{item.date}</td>
          <td>{item.time}</td>
          <td>{item.accountType}</td>
          <td>{item.accountId == "Administration Smart" ? "Admin" : item.accountId}</td>
          <td className="no-print">
            <a href="#" data-toggle="tooltip" title="Edit!">
              <button
                id="firstbutton"
                onClick={() => this.onEditClick(item.id)}
                onMouseOver={this.btnTechHover()}
                data-toggle="modal"
                data-target="#myModal"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i class="fas fa-pen"></i>
              </button>
            </a>
            <a href="#" data-toggle="tooltip" title="Delete!">
              <button
                onClick={() => this.onEditClick(item.id)}
                data-toggle="modal"
                data-target="#myModal1"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
              </button>
            </a>
          </td>
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  background: this.context.themeColors.primaryColor,
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  background: this.context.themeColors.primaryColor,
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="username">Name:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.name}
                              onChange={this.onHandleText}
                              name="name"
                              class="form-control"
                              id="name"
                            />
                          </div>
                          <div class="form-group">
                            <label for="pwd">Description:</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.description}
                              onChange={this.onHandleText}
                              type="text"
                              name="description"
                              class="form-control"
                              id="description"
                            />
                          </div>
                        </fieldset>
                        <button
                          disabled={this.state.enabled}
                          onClick={() =>
                            this.setState({ enabled: true, password: "" })
                          }
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <img
            src={require("../../Assets/images/header.png")}
            style={{
              width: "100%",
              height: "150",
            }}
          />
        </div>
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table
                class="table table-hover table-bordered"
                style={{ textAlign: "center" }}
              >
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr className="vendorListHeading">
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">AccountId</th>
                    <th scope="col">AccountType</th>
                    <th scope="col" className="no-print">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTodos}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
    );
    return (
      <div>
        <div id="div1">
          <div className="page-header">
            <SnackBar msg={this.state.msg} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h2
                  style={{ background: this.context.themeColors.primaryColor }}
                  className="h1-responsive font-weight-bold text-center  generalHead"
                >
                  Category Details
                </h2>
              </div>
              <nav className="no-print" aria-label="Page navigation example">
                <ul
                  style={{ color: this.context.themeColors.primaryColor }}
                  class="pagination"
                >
                  <li
                    class={`page-item ${this.state.disableBPage ? "disabled" : ""
                      }`}
                  >
                    <a
                      onClick={this.handleClickNextBack}
                      class="page-link"
                      href="#"
                    >
                      {this.state.disableBPage ? (
                        <i
                          style={{ color: "grey" }}
                          class="far fa-stop-circle"
                        ></i>
                      ) : (
                        <i style={{ color: "grey" }}
                          class="fas fa-backward"></i>
                      )}
                    </a>
                  </li>
                  {renderPageNumbers}
                  <li
                    class={`page-item ${this.state.disablepage ? "disabled" : ""
                      }`}
                  >
                    <a
                      onClick={this.handleClickNextFrwd}
                      class="page-link"
                      href="#"
                    >
                      {this.state.disablepage ? (
                        <i
                          style={{ color: "grey" }}
                          class="far fa-stop-circle"
                        ></i>
                      ) : (
                        <i style={{ color: "grey" }} class="fas fa-forward"></i>
                      )}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="row justify-content-between no-print">
                        <div className="col-md-3">

                        </div>
          <div class="col-md-2">
                            <div class="md-form mb-0">
                              <label for="email" class="">
                                Entries
                              </label>
                              <select
                                onChange={(e) => this.setState({
                                  todosPerPage: e.target.value
                                })}
                                class="custom-select"
                                name="CategoryName"
                              >
                                <option
                                  selected={this.state.msg ? true : false}
                                  value="10"
                                >
                                  {" "}
                                  Select Entries
                                </option>
                                
                                  <option value={this.state.adminData.length}>
                                    All Entries
                                  </option>
                                  <option value="10">
                                    10
                                  </option>
                                  <option value="20">
                                    20
                                  </option>
                                  <option value="30">
                                    30
                                  </option>
                                  <option value="40">
                                    40
                                  </option>
                                  <option value="50">
                                    50
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
          </div>
          <div class="row justify-content-center no-print">
            {!this.state.searchFilter ?
              <React.Fragment>
                <div class="col-md-2" style={{ marginTop: 20 }}>
                  <div class="md-form mb-0">
                    <label for="email" class="text-center">
                      From Date
                </label>
                    <DatePicker
                      onChange={(e) => this.changeDate(e, "fromdate")}
                      value={this.state.fromdate}
                    />
                  </div>
                </div>
                <div class="col-md-2" style={{ marginTop: 20 }}>
                  <div class="md-form mb-0">
                    <label for="email" class="text-center">
                      To Date
                </label>
                    <DatePicker
                      onChange={(e) => this.changeDate(e, "todate")}
                      value={this.state.todate}
                      minDate={this.state.fromdate}
                    />
                  </div>
                </div>
              </React.Fragment>

              : <div class="col-md-3" style={{ marginTop: 20 }}>
                <div class="md-form mb-0">
                  <label for="email" class="">
                    Name
              </label>
                  <input
                    type="text"
                    name="categoryName"
                    class="form-control"
                    onChange={this.onHandleText}
                    placeholder="Enter category name"
                  />
                </div>
              </div>}
            <div className="col-md-4" style={{ marginTop: 50 }}>

              <input
                type="checkbox"
                value={this.state.monthlyFee2}
                checked={this.state.searchFilter}
                onChange={() => this.setState({
                  searchFilter: !this.state.searchFilter
                })}
                name="monthlyFee"
              />
              <label
                style={{
                  // color: context.themeColors.primaryColor,
                  fontWeight: "bold",
                  marginLeft: 10
                }}
              >
                Search category name wise
  </label>
            </div>
          </div>
          <br />
          <div class="col-md-12 text-center no-print">
            <button
              type="submit"
              // disabled={this.state.fromdate !== "" && this.state.todate !== "" ? false : true}
              style={{
                backgroundColor:
                  this.context.themeColors.primaryColor,
                border: "none",
                fontSize: 20,
                borderRadius: 4,
                color: "white",
              }}
              onClick={this.onSearchStudent}
            >
              Search
              <i
                style={{ color: "white", marginLeft: 4 }}
                class="fas fa-search"
              ></i>
              <span
                style={{ marginBottom: 5 }}
                class={`${this.state.btnLoad ? "spinner-border spinner-border-sm" : ""
                  }`}
              ></span>
            </button>
          </div>
          {this.state.isLoading ? Loader : MainContent}
          <div class="page-footer">
            <div class="text-left">Printed By: {this.props.data.role}</div>
            <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
              Powered by School Smart®
            </div>
          </div>
        </div>
        <div
          class="text-center text-md-right displayElemet"
          style={{ marginBottom: 10 }}
        >
          <label for="name" class="" onClick={() => this.PrintContent("div1")}>
            <i
              className="fas fa-print fa-2x animated fadeIn"
              style={{ marginRight: 4 }}
            />
            Print
          </label>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    data: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, null)(ViewCategory);

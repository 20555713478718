import React, { Component, useContext } from "react";
import DateandTime from "./../Transport/DateandTime";

import GeneralRadioOptionSelection from "../../components/Admission/GeneralRadioOptionSelection";
import { useState } from "react";
import { Types } from "../../hooks/HookReducers/StaffReducer";
import Button from "../../components/Button";
import themeContext from "../../context/themeContext/ThemeContext";

const MedicalConditionStaff = (props) => {
  const themeColor = useContext(themeContext);
  const {
    staffId,
    dispatch,
    errors,
    onPreviousClick,
    checkObjIsEmptyOrNot,
    getValidatedArray,
    edit = false,
    onUpdate,
    editData = {},
    putLoader,
    newState,
  } = props;

  const [stateData, setStateData] = useState(
    edit
      ? editData
      : {
          threatCondition: "",
          needMedication: "",
          otherMedication: "",
          threatConditionReason: "",
          needMedicationReason: "",
          otherMedicationReason: "",
        }
  );
  const onEditHandleSubmit = (e) => {
    let getSpecificField = removeSpecificFields(
      {
        threatCondition: "",
        needMedication: "",
        otherMedication: "",
        staffId: "",
        MedicalConditionId: "",
      },
      stateData
    );
    debugger;
    if (!checkObjIsEmptyOrNot(e, getSpecificField, "medicalCondition")) {
      debugger;
      onUpdate(dispatch, "ON_SET_MEDICAL_INFO", {
        name: "medicalDetails",
        MedicalConditionStaffs: stateData,
      });
    }
  };

  function removeSpecificFields(removeFieldsObj, originalObj) {
    let getDesireObj = {};
    let holdOriginalKeys = Object.keys(originalObj);
    let holdRemoveKeys = Object.keys(removeFieldsObj);
    let getDesireKeys = holdOriginalKeys.filter(
      (item, index) => !holdRemoveKeys.includes(item)
    );
    console.log(getDesireKeys);
    getDesireKeys.forEach((element) => {
      getDesireObj[element] = originalObj[element];
    });
    return getDesireObj;
  }
  const onHandleTextChange = (e) => {
    let getCloneData = { ...stateData };
    getCloneData[e.target.name] = e.target.value;
    setStateData(getCloneData);
  };
  const onNextStep = async (e) => {
    e.preventDefault();
    debugger;
    let getSpecificField = removeSpecificFields(
      {
        threatCondition: "",
        needMedication: "",
        otherMedication: "",
      },
      stateData
    );
    debugger;
    if (!checkObjIsEmptyOrNot(e, getSpecificField, "medicalCondition")) {
      debugger;
      debugger;
      await dispatch({
        type: Types.ON_MEDICAL_INFO_DISPATCH,
        payload: { MedicalConditionStaffs: stateData },
      });
      debugger;
    }
  };
  return (
    <div>
      <section class="mb-6">
        <DateandTime />
        <div
          class="row"
          style={{
            justifyContent: "left",
            textAlign: "left",
          }}
        >
          {
            <p style={{ color: "red" }}>
              {errors["medicalCondition"]
                ? "Please fill the form properly"
                : ""}{" "}
            </p>
          }
          <div class="col-md-12 mb-md-0 mb-5">
            <form
              id="contact-form"
              name="contact-form"
              // onSubmit={this.onHandleSubmit}
              noValidate
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0">{/*<DateandTime/>*/}</div>
                </div>
              </div>
              <br />

              <div class="col">
                <GeneralRadioOptionSelection
                  onHandleTextChange={onHandleTextChange}
                  stateData={stateData}
                  Name="Threat Conditon:"
                  idForYes="customRadiothreCY"
                  idForNo="customRadiodobthreCN"
                  feildName="threatConditionReason"
                />
                {stateData.threatConditionReason === "Yes" ? (
                  <div class="md-form mb-0">
                    <textarea
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        boxShadow: "none",
                        width: "60%",
                      }}
                      onChange={(e) => onHandleTextChange(e)}
                      type="text"
                      value={stateData.threatCondition}
                      name={"threatCondition"}
                      class="form-control"
                      id="threadCondition"
                      placeholder="Threat Conditon"
                    ></textarea>
                  </div>
                ) : stateData.threatConditionReason === "No" ? (
                  <p></p>
                ) : (
                  ""
                )}
                <GeneralRadioOptionSelection
                  onHandleTextChange={onHandleTextChange}
                  stateData={stateData}
                  Name="Need Medication:"
                  idForYes="customRadioNMY"
                  idForNo="customRadiodobthreNMN"
                  feildName="needMedicationReason"
                />
                {stateData.needMedicationReason === "Yes" ? (
                  <React.Fragment>
                    <div class="md-form mb-0">
                      <textarea
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "60%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.needMedication}
                        name={"needMedication"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Need Medication"
                      ></textarea>
                    </div>
                  </React.Fragment>
                ) : stateData.needMedicationReason === "No" ? (
                  <p></p>
                ) : (
                  ""
                )}

                <GeneralRadioOptionSelection
                  onHandleTextChange={onHandleTextChange}
                  stateData={stateData}
                  Name="Other Medication:"
                  idForYes="customRadioOMY"
                  idForNo="customRadiodobOMN"
                  feildName="otherMedicationReason"
                />
                {stateData.otherMedicationReason === "Yes" ? (
                  <div class="md-form mb-0">
                    <textarea
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        boxShadow: "none",
                        width: "60%",
                      }}
                      onChange={(e) => onHandleTextChange(e)}
                      name={"otherMedication"}
                      value={stateData.otherMedication}
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      placeholder="Other Medication"
                    ></textarea>
                  </div>
                ) : stateData.otherMedicationReason === "No" ? (
                  <p></p>
                ) : (
                  ""
                )}
              </div>
              <br />
            </form>
            {!edit ? (
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  <button
                    class="btns"
                    style={{
                      marginRight: 25,
                      background: themeColor.themeColors.primaryColor,
                    }}
                    onClick={(e) => onNextStep(e)}
                  >
                    Next
                  </button>
                </div>
                <div>
                  <button
                    style={{ background: themeColor.themeColors.primaryColor }}
                    class="btns"
                    onClick={() => onPreviousClick()}
                  >
                    Previous
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {edit ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  btnBackColor={themeColor.themeColors.primaryColor}
                  stateData={{ isLoading: putLoader }}
                  btnName="Update"
                  buttonClass="btn-smart"
                  textColor="#ffff"
                  loaderBmargin={5}
                  marginTop={20}
                  onHandleSubmit={onEditHandleSubmit}
                />
              </div>
            ) : (
              ""
            )}
            <div class="status" />
          </div>
        </div>
      </section>
    </div>
  );
};
export default MedicalConditionStaff;

import React, { Component } from "react";
import $ from "jquery";
import "../Admin/viewAdmin.css";
import Axios from "axios";
import config from "../../config/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as StockActions from "../../Actions/Stock";
import * as sharedActions from "../../Actions/sharedActions";
import setCanteenReducer from "../../Reducers/setCanteenReducer";
import SnackBar from "../../components/SnackBar/SnackBar";
import ThemeContext from "../../context/themeContext/ThemeContext";
import DatePicker from "react-date-picker";
import Moment from "moment";

class ViewStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      modalData: null,
      campusName: "",
      campusId: null,
      canteenName: "",
      handlerName: "",
      handlerId: null,
      enabled: false,
      id: null,
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      fromdate: "",
      todate: "",
      msg: "",
      select: [],
      handler: [],
      range: false,
      changeIndex: 4
    };
  }
  static contextType = ThemeContext;

  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
    if (currentPage > 4) {
      debugger;
      this.setState({
        changeIndex: +this.state.changeIndex + 1,
      })
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
        changeIndex: +this.state.changeIndex - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.adminData !== nextProps.AllTransportData && prevState.range === false) {
      return {
        adminData: nextProps.AllTransportData,
      };
    }
    return null;
  }
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    if (this.props.allTransportState.apiFlag) {
      this.props.sharedActions
        .getAllData(
          "/api/StockDetails",
          this.props.StockActions.setAllStockInRedux,
          this.props.StockActions.updateStockFlag
        )
        .then((success) => {
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.props.StockActions.updateStockFlag(false);

          this.setState({
            isLoading: false,
          });

          console.log(err);
        });
    }
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/StockDetails",
        itemId,
        this.props.StockActions.deleteStock
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        // window.location.reload();
        this.props.snackbar();
        debugger;
      })
      .catch((error) => { });
    debugger;
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.ID === itemId);

    this.setState({
      modalData: getData,
      routeFees: getData[0].routeFees,
      routeName: getData[0].routeName,
      id: getData[0].ID,
    });
  };

  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { id, stockIn } = this.state;
    var bodyParameters = {
      id,
      date: new Date().toDateString(),
      time: new Date().toTimeString(),
    };
    this.props.sharedActions
      .editRecord(
        "/api/Route/",
        id,
        bodyParameters,
        this.props.routesActions.updateTransport
      )
      .then((success) => {
        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        this.props.snackbar();
      })
      .catch((err) => { });
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
    window.location.reload();
  };

  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    let FromDate = Moment(this.state.fromdate).format("MM/DD/YYYY");
    let ToDate = Moment(this.state.todate).format("MM/DD/YYYY");
    debugger;
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/StockDetails/?dateFrom=${FromDate}&dateTo=${ToDate}`
      )
      .then((success) => {
        debugger;
        this.setState({
          adminData: success,
          isLoading: false,
          btnLoad: false,
          searchCheck: false,
          range: true
        });
      })
      .catch((err) => {
        this.setState({
          errorText: err.response.data.Message,
          isLoading: false,
          btnLoad: false,
          searchCheck: true,
          badRequest: true,
          range: false
        });

        console.log(err);
      });
  };
  changeDate = (date, state) => {
    this.setState({
      [state]: date,
    });
    console.log(date);
  };

  render() {
    const { adminData, modalData } = this.state;

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= this.state.changeIndex ? (
        <ThemeContext.Consumer>
          {(context) => (
            <li
              class={`page-item `}
            >
              <a
                key={number}
                id={number}
                onClick={this.handleClickNext}
                class="page-link "
                href="#"
                style={{
                  background: `${this.state.currentPage === number ? context.themeColors.primaryColor : ""
                    }`,
                  color: `${this.state.currentPage === number
                    ? "#fff"
                    : context.themeColors.primaryColor
                    }`,
                }}
              >
                {number}
              </a>
            </li>
          )}
        </ThemeContext.Consumer>
      ) : (
        ""
      );
    });
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = currentTodos.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{item.index}</th>
          <td>{item.Stock}</td>
          <td>{item.Name}</td>
          <td>{item.accountId}</td>
          <td>{item.accountType == "Administration Smart" ? "Admin" : item.accountType}</td>
          <td>{item.date}</td>
          <td>{item.time}</td>

          <td className="no-print">
            {/* <a href="#" data-toggle="tooltip" title="Edit!">
              <button
                id="firstbutton"
                onClick={() => this.onEditClick(item.ID)}
                onMouseOver={this.btnTechHover()}
                data-toggle="modal"
                data-target="#myModal"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i class="fas fa-pen"></i>
              </button>
            </a> */}
            <a href="#" data-toggle="tooltip" title="Delete!">
              <button
                onClick={() => this.onEditClick(item.ID)}
                data-toggle="modal"
                data-target="#myModal1"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
              </button>
            </a>
          </td>
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  background: this.context.themeColors.primaryColor,
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  background: this.context.themeColors.primaryColor,
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="routeName">Stock In:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.routeName}
                              onChange={this.onHandleText}
                              name="routeName"
                              class="form-control"
                              id="routeName"
                            />
                          </div>
                          <div class="form-group">
                            <label for="routeFees">Route Fees</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.routeFees}
                              onChange={this.onHandleText}
                              type="text"
                              name="routeFees"
                              class="form-control"
                              id="routeFees"
                            />
                          </div>
                        </fieldset>
                        <button
                          disabled={this.state.enabled}
                          onClick={() => this.setState({ enabled: true })}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <img
            src={require("../../Assets/images/header.png")}
            style={{
              width: "100%",
              height: "150",
            }}
          />
        </div>
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table
                class="table table-hover table-bordered"
                style={{ textAlign: "center" }}
              >
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr className="vendorListHeading">
                    <th scope="col">#</th>
                    <th scope="col">Stock In</th>
                    <th scope="col">Item Name</th>
                    <th scope="col">Account Id</th>
                    <th scope="col">Account Type</th>
                    <th scope="col">Date </th>
                    <th scope="col">Time </th>
                    <th scope="col" className="no-print">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTodos}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
    );
    return (
      <div>
        <div id="div1">
          <div className="page-header">
            <SnackBar msg={this.state.msg} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h2
                  style={{ background: this.context.themeColors.primaryColor }}
                  className="h1-responsive font-weight-bold text-center  generalHead"
                >
                  Stock Details
                </h2>
              </div>
              <nav aria-label="Page navigation example" className="no-print">
                <ul style={{ color: "#01AC8A" }} class="pagination">
                  <li
                    class={`page-item ${this.state.disableBPage ? "disabled" : ""
                      }`}
                  >
                    <a
                      onClick={this.handleClickNextBack}
                      class="page-link"
                      href="#"
                    >
                      {this.state.disableBPage ? (
                        <i
                          style={{ color: "grey" }}
                          class="far fa-stop-circle"
                        ></i>
                      ) : (
                        <i style={{ color: "grey" }} class="fas fa-backward"></i>
                      )}
                    </a>
                  </li>
                  {renderPageNumbers}
                  <li
                    class={`page-item ${this.state.disablepage ? "disabled" : ""
                      }`}
                  >
                    <a
                      onClick={this.handleClickNextFrwd}
                      class="page-link"
                      href="#"
                    >
                      {this.state.disablepage ? (
                        <i
                          style={{ color: "grey" }}
                          class="far fa-stop-circle"
                        ></i>
                      ) : (
                        <i style={{ color: "grey" }} class="fas fa-forward"></i>
                      )}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="row justify-content-between no-print">
                        <div className="col-md-3">

                        </div>
          <div class="col-md-2">
                            <div class="md-form mb-0">
                              <label for="email" class="">
                                Entries
                              </label>
                              <select
                                onChange={(e) => this.setState({
                                  todosPerPage: e.target.value
                                })}
                                class="custom-select"
                                name="CategoryName"
                              >
                                <option
                                  selected={this.state.msg ? true : false}
                                  value="10"
                                >
                                  {" "}
                                  Select Entries
                                </option>
                                <option value={this.state.adminData.length}>
                                    All Entries
                                  </option>
                                  <option value="10">
                                    10
                                  </option>
                                  <option value="20">
                                    20
                                  </option>
                                  <option value="30">
                                    30
                                  </option>
                                  <option value="40">
                                    40
                                  </option>
                                  <option value="50">
                                    50
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
          </div>
          <div class="row justify-content-center no-print">
            <div class="col-md-2" style={{ marginTop: 20 }}>
              <div class="md-form mb-0">
                <label for="email" class="text-center">
                  From Date
                </label>
                <DatePicker
                  onChange={(e) => this.changeDate(e, "fromdate")}
                  value={this.state.fromdate}
                />
              </div>
            </div>
            <div class="col-md-2" style={{ marginTop: 20 }}>
              <div class="md-form mb-0">
                <label for="email" class="text-center">
                  To Date
                </label>
                <DatePicker
                  onChange={(e) => this.changeDate(e, "todate")}
                  value={this.state.todate}
                  minDate={this.state.fromdate}
                />
              </div>
            </div>
          </div>
          <br />
          <div class="col-md-12 text-center no-print">
            <button
              type="submit"
              disabled={this.state.fromdate !== "" && this.state.todate !== "" ? false : true}
              style={{
                backgroundColor:
                  this.state.fromdate !== "" && this.state.todate !== "" ? this.context.themeColors.primaryColor : "#ABD0BC",
                border: "none",
                fontSize: 20,
                borderRadius: 4,
                color: "white",
              }}
              onClick={this.onSearchStudent}
            >
              Search
              <i
                style={{ color: "white", marginLeft: 4 }}
                class="fas fa-search"
              ></i>
              <span
                style={{ marginBottom: 5 }}
                class={`${this.state.btnLoad ? "spinner-border spinner-border-sm" : ""
                  }`}
              ></span>
            </button>
          </div>
          {this.props.allTransportState.apiFlag ? Loader : MainContent}
          <div class="page-footer">
            <div class="text-left">Printed By: {this.props.users.role}</div>
            <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
              Powered by School Smart®
            </div>
          </div>
        </div>
        <div
          class="text-center text-md-right displayElemet"
          style={{ marginBottom: 10 }}
        >
          <label for="name" class="" onClick={() => this.PrintContent("div1")}>
            <i
              className="fas fa-print fa-2x animated fadeIn"
              style={{ marginRight: 4 }}
            />
            Print
          </label>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StockActions: bindActionCreators(StockActions, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllTransportData: state.setStockReducer.Data,
    allTransportState: state.setStockReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewStock);

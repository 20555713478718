import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import TextArea from "../../components/TextArea";
import TextInput from "../../components/TextInput/TextInput";
import FormLayoutWrapper from "../../components/HOC/FormLayoutWrapper";
import * as sharedActions from "../../Actions/sharedActions";
import Button from "../../components/Button";
import SnackBar from "../../components/SnackBar/SnackBar";
import { bindActionCreators } from "redux";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddAwardCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      accountId: props.name,
      accountType: props.role,
      errors: {},
      errorMsg: false,
      awardName: "",
    };
  }

  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }

  onHandleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { awardName, accountId, accountType } = this.state;

    if (awardName === "") {
      this.setState({
        error: true,
        errorMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      const data = {
        awardName,
        accountId,
        accountType,
      };
      console.log(data);
      this.props.sharedActions
        .simpleAddRequest("/api/AwardCategory/", data)
        .then((success) => {
          this.setState({
            msg: "Added Award Category Successfully",
            isLoading: false,
            errorMsg: false,
            error: false,
            awardName: "",
          });
          this.props.snackbar();
        });
    }
  };
  render() {
    const { errors } = this.state;

    const Form = (
      <ThemeContext.Consumer>
        {(context) => (
          <FormLayoutWrapper
            formName="Add Award Category"
            borderColor={context.themeColors.primaryColor}
            borderRadius={15}
            styleHead={true}
          >
            {this.state.errorMsg ? (
              <p style={{ color: "red", textAlign: "center" }}>
                Please fill the form properly
              </p>
            ) : (
              ""
            )}

            <div className="row">
              <TextInput
                feildName={"accountId"}
                inputLabelName={"Admin Name"}
                errors={errors}
                stateData={this.state}
                placeholder="Admin Name"
                readOnly={true}
                type={"text"}
                isValidte={false}
              />

              <TextInput
                feildName={"accountType"}
                inputLabelName={"Account Type"}
                errors={errors}
                stateData={this.state}
                placeholder="Account Type"
                readOnly={true}
                type={"text"}
                isValidte={false}
              />

              <TextInput
                marginTop={20}
                feildName={"awardName"}
                inputLabelName={"Award Name"}
                onHandleChange={this.onHandleTextChange}
                errors={errors}
                stateData={this.state}
                placeholder={"Award Name"}
                isValidte={false}
              />
            </div>
            <br />
            <Button
              width="100%"
              buttonClass="customButton"
              btnName="Add"
              loaderBmargin={5}
              stateData={this.state}
              marginTop={20}
              onHandleSubmit={this.onSubmit}
              margin={"0 auto"}
              textColor={"white"}
              btnBackColor={context.themeColors.primaryColor}
            />
          </FormLayoutWrapper>
        )}
      </ThemeContext.Consumer>
    );
    return (
      <React.Fragment>
        <SnackBar msg={this.state.msg} />
        {Form}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAwardCategory);

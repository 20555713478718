import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import DateandTime from '../Transport/DateandTime';
import isNull from 'lodash/isNull'

import SnackBar from "../../components/SnackBar/SnackBar";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";




class AddStudentTalent extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorMsg: false,
            successMsg: false,
            msg: '',
            isLoading: false,

            termList: ['1st term', '2nd term', '3rd term'],
            term: null,
            session: null,
            sessionId: null,
            classId: null,
            sectionId: null,
            studentId: null,
            categoryId: null,
            subCategoryId: null,
            reward: null,
            valid: null,
        }
    }

    componentDidMount() {
            this.getSimple('talentCategory', 'talentCategory')
            this.getSimple('talentSubCategory', 'talentSubCategory')
            this.getSimple('session', 'session')
            this.getSimple('Student', 'student')
    }


    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
        })
    }

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    onHandleTextChange = e => {
        console.log([e.target.name], e.target.value);
        
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleValidationTextChange = e => {
        console.log([e.target.name], e.target.value);
        
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
                this.handleValidation()
        })
    }

    handleValidation = () => {
        const {  sessionId, classId, sectionId, studentId }  = this.state

        if(
            isNull(sessionId) || 
            isNull(classId) || 
            isNull(sectionId) || 
            isNull(studentId) 
        
        ){
        
    }else{
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/StudentTalents/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&studentId=${studentId}`
        ).then(success => {
            this.setState({
                valid: success,
                isLoading: false
            })

        }).catch(err => {

            this.setState({
                valid: null,
                errorMsg: true,
                successMsg: false,
                valid: null,                
                msg: err.response ? err.response.data.Message : 'Error Occured!!',
                isLoading: false
            })

            console.error('Error Name: ', err.response)
        })
    }
    
}

    handleSubmit = e => {
        e.preventDefault();
        const { 
            term,
            sessionId,
            classId,
            sectionId,
            studentId,
            categoryId,
            subCategoryId,
            reward
         } = this.state;
        if(
            isNull(sessionId) || 
            isNull(classId) || 
            isNull(sectionId) || 
            isNull(studentId) || 
            isNull(categoryId)  || 
            isNull(subCategoryId) || 
            isNull(term) || 
            isNull(reward) 

        ){
            this.setState({
                msg: 'Please fill the form respectively!'
            })
        }else{

        let data = {
            accountId: this.props.userData.unique_name,
            accountType: this.props.userData.role,
            term,
            sessionId,
            classId,
            sectionId,
            studentId,
            categoryId,
            subCategoryId,
            reward
        }

        console.log(data);

        this.props.sharedActions.addRecordWithoutDispatch(
            `/api/StudentTalents`,
            data
        ).then(success => {
            console.log(success);
            
            this.setState({
                msg: 'Student Talent Added!',
                successMsg: true,
                isLoading: false,
                term: '',
                sessionId: '',
                classId: '',
                sectionId: '',
                studentId: '',
                categoryId: '',
                subCategoryId: '',
                reward: ''
            })
            this.props.snackbar();

        }).catch(error => {
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured', error);
            
        })
    }
    }
    
    checkBook = (studentId) => {
        const { student } = this.state;
        let f = student.student.filter((data) => {
            return data.studentId == studentId
        })

        if(f.length > 0 ){
            this.setState({
                classId: f[0].classId,
                sectionId: f[0].sectionId,
                msg: 'Student Found!!',
            }, () => {
                this.handleValidation()
            })
            this.props.snackbar();
        }else{
            this.setState({
                msg: 'Student Not Found!!',
            })
            this.props.snackbar();
        }
        

    }
    
    render(){

        const { successMsg, errorMsg, msg, isLoading, termList, student, session, talentCategory, talentSubCategory, valid,
            sessionId, classId, sectionId, studentId, term, categoryId, subCategoryId, reward 
             } = this.state;

        return (
            <div className="container">
				<section  className="mb-4">
                <ThemeContext.Consumer>
                    {(context) => (
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        Add Student Talent
                    </h2>
                     )}
                </ThemeContext.Consumer>

              <SnackBar msg={this.state.msg} backColor={this.state.backColor} />

                    <p class="text-center w-responsive mx-auto mb-5" />

                    <div className="row" style={{
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>

                <ThemeContext.Consumer>
                {(context) => (
                        <div className="col-md-9 mb-md-0 mb-5" style={{
                            border: 'solid',
                            borderColor: context.themeColors.primaryColor,
                            padding: 40,
                            borderRadius: 15,
                            width: 'auto'

                        }}>
                      <LoaderWrapper isLoading={this.state.isLoading}>
                            <form id="payroll-form" name="payroll-form" onSubmit={this.handleSubmit} >
                                <div className="row">
									<div className="col-md-12">
										<div className="md-form mb-0">
											<DateandTime />
										</div>
									</div>
								</div>
								<br />
                                <div class="row">
									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminName" class="">
												Admin Name
											</label>
											<input
												style={{ borderColor: context.themeColors.primaryColor }}
												type="text"
												name="AdminName"
												class="form-control"
												readOnly={true}
												value={this.props.userData.unique_name}
											/>
										</div>
								    </div>

									<div class="col-md-6">
										<div class="md-form mb-0">
											<label for="AdminType" class="">
												Admin Type
											</label>
											<input
												style={{ borderColor: context.themeColors.primaryColor }}
												type="text"
												name="AdminType"
												class="form-control"
												readOnly={true}
												value={this.props.userData.role}
											/>
										</div>
								    </div>
                                </div>
                                    <br/>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="sessionId">
                                                Session
                                            </label>
                                            <select class="custom-select" 
                                                value={sessionId}
                                                name="sessionId"
                                                onChange={this.handleValidationTextChange}
                                                disabled={!session}

                                            >
                                                        <option value="">Please Select Session</option>
                                                       {session && session.session.map((value, index) => {
                                                           
                                                           return <option value={value.sessionId}>{value.currentSession}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <label for="studentId">
                                                Student ID
                                            </label>
                                        <div className="input-group md-form mb-3" >
                                            
                                            <input class="form-control" 
                                                type='text'
                                                value={studentId}
                                                name="studentId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!student}
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" 
                                                disabled={!studentId && !student}
                                                onClick={() => this.checkBook(studentId)}
                                                type="button">
                                                    <i class="fas fa-search" ></i>
                                                    </button>
                                            </div>
                                        </div>
                                        </div>
                                </div>  
                                <div className="row">
                                    
                                <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="classId">
                                                Class Name
                                            </label>
                                            <select class="custom-select" 
                                                value={classId}
                                                name="classId"
                                                onChange={this.handleValidationTextChange}
                                                disabled={!student}
                                            >
                                                        <option value="">Please Select Class</option>
                                                       {student && student.classes.map((value, index) => {
                                                           
                                                           return <option value={value.classId}>{value.className}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                    <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="sectionId">
                                                Section
                                            </label>
                                            <select class="custom-select" 
                                                value={sectionId}
                                                name="sectionId"
                                                onChange={this.handleValidationTextChange}
                                                disabled={!classId}

                                            >
                                                        <option value="">Please Select Section</option>
                                                       {student && student.sections.map((value, index) => {


                                                                return value.classId == classId && 
                                                                <option value={value.sectionId}>{value.section}</option>

                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                </div>
                                <div className="row">
                                    
                                <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                            <label for="studentId">
                                                Student
                                            </label>
                                            <select class="custom-select" 
                                                value={studentId}
                                                name="studentId"
                                                onChange={this.handleValidationTextChange}
                                                disabled={!sectionId}
                                            >
                                                        <option value="">Please Select Student</option>
                                                       {student && student.student.map((value, index) => {
                                                           
                                                           return value.classId == classId && value.sectionId == sectionId && 
                                                           <option value={value.studentId}>{value.Student}</option>
                                                       })}
                                            </select>  
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                        <label for="term">
                                                Term
                                            </label>
                                            <select class="custom-select" 
                                                value={term}
                                                name="term"
                                                onChange={this.onHandleTextChange}
                                                disabled={!sectionId || !valid }
                                            >
                                                        <option value="">Please Select Term</option>
                                                        {termList.map((value, index) => {
                                                           
                                                           return <option value={value}>{value}</option>
                                                       })}
                                            </select>
                                        </div>
                                        </div>
                                        
                                       
                                </div>
                                <div className="row">
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                        <label for="categoryId">
                                                Category
                                            </label>
                                            <select class="custom-select" 
                                                value={categoryId}
                                                name="categoryId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!term || !talentCategory}
                                            >
                                                        <option value="">Please Select Category</option>
                                                        {talentCategory && talentCategory.map((value, index) => {
                                                           
                                                           return <option value={value.id}>{value.categoryName}</option>
                                                       })}
                                            </select>
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                        <label for="subCategoryId">
                                                Sub Category
                                            </label>
                                            <select class="custom-select" 
                                                value={subCategoryId}
                                                name="subCategoryId"
                                                onChange={this.onHandleTextChange}
                                                disabled={!categoryId || !talentSubCategory}
                                            >
                                                        <option value="">Please Select Sub Category</option>
                                                        {talentSubCategory && talentSubCategory.map((value, index) => {
                                                           
                                                           return categoryId == value.talentCategoryId && 
                                                           <option value={value.id}>{value.subCategoryName}</option>
                                                       })}
                                            </select>
                                        </div>
                                        </div>
                                </div>
                                <div class="row" >

                                <div className="col-md-6">
                                        <div className="md-form mb-0" >
                                        <label for="reward">
                                                Reward
                                            </label>
                                            <input
												style={{ borderColor: context.themeColors.primaryColor }}
												type="text"
												name="reward"
												class="form-control"
                                                value={reward}
                                                onChange={this.onHandleTextChange}
                                                disabled={!subCategoryId}
											/>
                                        </div>
                                        </div>
                                        
                                       

                                </div>
                                
                                        <br/>
                                        <br/>
                                        <div className="text-center text-md-center">
                                            <button type="submit" className="btns" style={{background: context.themeColors.primaryColor}}>
                                                Add
                                                <span style={{
                                                    marginBottom: 5
                                                }}
                                                className={isLoading && 'spinner-border spinner-border-sm'}>
                                                </span>
                                            </button>
                                        </div>
                                     
                            </form>
                            </LoaderWrapper>
                            <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
                    </div>
                </section>
			</div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddStudentTalent);

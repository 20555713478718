import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CampusActions from "../../Actions/Campus";
import * as sharedActions from "../../Actions/sharedActions";
import DateandTime from "../Transport/DateandTime";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddCampus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campusName: "",
      campusCity: "",
      campusAddress: "",
      campusPhoneNumber: "",
      errors: {},
      msg: false,
      errorMsg: false,
    };
  }
  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleValidation() {
    let fields = this.state.campusName;
    let errors = {};
    let formIsValid = true;

    //Name
    if (!fields) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    }

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }
  handleSubmit = (e) => {
    const {
      campusAddress,
      campusCity,
      campusName,
      campusPhoneNumber,
    } = this.state;
    e.preventDefault();
    if (
      Object.is(campusAddress, "") ||
      Object.is(campusCity, "") ||
      Object.is(campusName, "") ||
      Object.is(campusPhoneNumber, "")
    ) {
      this.setState({
        error: true,
        errorMsg: true,
      });
    } else {
      console.log(this.state);
      const {
        campusPhoneNumber,
        campusName,
        campusCity,
        campusAddress,
      } = this.state;
      var bodyParameters = {
        campusAddress,
        campusName,
        campusCity,
        campusPhoneNumber,
        accountType: this.props.userData.role,
        accountId: this.props.userData.unique_name,
      };

      this.props.sharedActions
        .addRecord(
          "/api/CampusDetail",
          bodyParameters,
          this.props.campusActions.addCampus
        )
        .then((success) => {
          this.setState({
            campusAddress: "",
            campusName: "",
            campusCity: "",
            campusPhoneNumber: "",
            msg: true,
            errorMsg: false,
          });
          this.props.campusActions.updateCampusFlag(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  render() {
    return (
          <ThemeContext.Consumer>
            {(context) => (
      <div className="container">
        <section class="mb-4">
          <h2 class="h1-responsive font-weight-bold text-center my-4 generalHead" 
            style={{ background: context.themeColors.primaryColor }}
          >
            Add Campus
          </h2>

          <p class="text-center w-responsive mx-auto mb-5" />

          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  {this.state.errorMsg ? (
                    <p style={{ color: "red", textAlign: "center" }}>
                      Please fill the form properly
                    </p>
                  ) : (
                    ""
                  )}
                  {this.state.msg ? (
                    <p style={{ color: "green", textAlign: "center" }}>
                      Added Successfully!
                    </p>
                  ) : (
                    ""
                  )}
                  <form
                    onSubmit={this.handleSubmit}
                    id="contact-form"
                    name="contact-form"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <DateandTime />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Name
                          </label>
                          <input
                            type="text"
                            name="AdminName"
                            class="form-control"
                            readOnly={true}
                            value={this.props.userData.unique_name}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Type
                          </label>
                          <input
                            type="text"
                            name="AdminType"
                            class="form-control"
                            readOnly={true}
                            value={this.props.userData.role}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Campus Name
                          </label>
                          <input
                            type="text"
                            placeholder="Campus Name"
                            class="form-control"
                            name="campusName"
                            value={this.state.campusName}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            City
                          </label>
                          <input
                            type="text"
                            placeholder="Campus City"
                            name="campusCity"
                            value={this.state.campusCity}
                            onChange={this.onHandleTextChange}
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Address
                          </label>
                          <input
                            type="text"
                            placeholder="Campus Address"
                            name="campusAddress"
                            value={this.state.campusAddress}
                            onChange={this.onHandleTextChange}
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Phone Number
                          </label>
                          <input
                            type="number"
                            placeholder="Phone Number"
                            name="campusPhoneNumber"
                            value={this.state.campusPhoneNumber}
                            onChange={this.onHandleTextChange}
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div class="text-center text-md-right">
                      <button
                        type="submit"
                        class="buttonHover2"
                        style={{
                          background: context.themeColors.primaryColor,
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>

                  <div class="status" />
                </div>
          </div>
        </section>
      </div>
              )}
            </ThemeContext.Consumer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    campusActions: bindActionCreators(CampusActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCampus);

import React, { Component } from "react";
import "./Form.css";

class StudentWithdrawDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { widthDraw } = this.props;
    console.log(widthDraw, "WidthDraw Ka Data haii");
    return (
      <div>
        <section class="mb-4">
          <div class="row print">
            <div class="col-md-12">
              <div class="md-form mb-0">
                <h2 className="FormHeadingStyle">Withdraw Details:</h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row rowCheck" style={{ marginTop: 10 }}>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Date Leaving: </label>
                &nbsp;&nbsp;
                {new Date(widthDraw && widthDraw.dateLeaving).toDateString()}
              </div>
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Class Left:</label>
                &nbsp;&nbsp;{widthDraw && widthDraw.classLeft}{" "}
              </div>
            </div>
            <div class="row rowCheck">
              <div class="col colCheck">
                <label style={{ fontWeight: "bold" }}>Reason:</label>{" "}
                &nbsp;&nbsp;{widthDraw && widthDraw.reason}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default StudentWithdrawDetails;

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import Header from '../../Assets/images/3 headers-03.png';
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";


class PrintStudentSkillAverageReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: false,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }
	static contextType = ThemeContext

    componentDidMount(){

    }

    
    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
            window.print();
        document.body.innerHTML = restorepage;
    };


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    render(){
        const { isLoading, accountId, accountType } = this.state;
        const { studentSkill, classSectionGrade, session, classId, sectionId, subjectId, sessionId } = this.props.location.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderStudentSkillData = studentSkill && studentSkill.items.map((item, index) => {
            return (
                <tr key={index} >
                    <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.stduentName}
                        {/* Wrong Student Spelling Because of API */}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.className}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.section}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.subjectName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.term}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.session}
                    </td>
                    <td className="borderSpace" style={{ padding: 1, color: 'red' }}>
                        {item.average}
                    </td>
                </tr>
                
                );
            
        }) 

        return (
            <div className="page-header"> 
                <div id="printDiv" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row" >
								<div
									style={{
                                        width: '100%',
                                        height: '100%'
									}}
								>
									<img src={Header} width={'100%'} height={'100%'} />
								</div>
						</div>

                    <div class="row" style={{marginTop: '2%'}} >
							<div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h3>Student Skill Report</h3>
							</div>
						</div>

                    <div class="my-2 py-2 px-2" style={{
                        border: `3px solid ${this.context.themeColors.primaryColor}`,
                    }} >
                     <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                            <div class="col-md-3" >
                                    <span>Class: <strong style={{color: this.context.themeColors.primaryColor}} >{studentSkill && classSectionGrade.classes.filter(data => data.classId == classId)[0].className}</strong></span>     
                            </div>
                            <div class="col-md-3"  >
                                    <span>Section: <strong style={{color: this.context.themeColors.primaryColor}} >{studentSkill && classSectionGrade.sections.filter(data => data.sectionId == sectionId)[0].section}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Subject: <strong style={{color: this.context.themeColors.primaryColor}} >{studentSkill && classSectionGrade.subject.filter(data => data.subjectId == subjectId)[0].subjectName}</strong></span>     
                            </div>
                            <div class="col-md-3" >
                                    <span>Session: <strong style={{color: this.context.themeColors.primaryColor}} >{studentSkill && session.session.filter(data => data.sessionId == sessionId)[0].currentSession}</strong></span>     
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Calss
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Subject
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Term
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Session
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Average
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderStudentSkillData}</tbody>
					</table>

				</div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-4">
                            <span>Printed By: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.unique_name}</strong></span>     
                    </div>
                    <div class="col-md-4 text-center">
                             
                            <span>Role: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.role}</strong></span>     

                    </div>
                    <div class="col-md-4 text-right">
                             
                            <span>Time: <strong style={{color: this.context.themeColors.primaryColor}} >{moment().format('HH:mm - MM/DD/YYYY')}</strong></span>     

                    </div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-12 text-right">
                    <button class="btn btn-primary buttonAppear" onClick={() => this.PrintContent('printPage')}>
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintStudentSkillAverageReport);

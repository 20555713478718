import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import Pagination from '../../../components/Pagination/Pagination';
import Spinner from '../../../components/Spinner/Spinner';
import Colors from '../../../utils/app_constants/colors_constants';
import TextArea from '../../../components/TextArea';
import InboxContext from '../../../context/InboxContext/index';
import themeContext from "../../../context/themeContext/ThemeContext";

let tHead = [
	'Name',
	'Title',
	'Date',
	'Time',
	'Account ID',
	'Account Type',
	'Actions'
];

let renderFields = [
	'name',
	'title',
	'date',
	'time',
	'accountId',
	'accountType'
];

const InboxComponent = props => {
    const themeColor = useContext(themeContext);

	const [message, setMessage] = useState({});
	const [isMessageLoading, setIsMessageLoading] = useState(false);

	const handleClickNextFrwd = (event, context) => {
		console.log('pagelength: ', context.pageNumber.length);
		console.log('current page: ', context.currentPage);
		if (context.currentPage === context.pageNumber.length) {
			context.setdisablepage(true);
		} else {
			context.setCurrentPage(prevState => prevState + 1);
			console.log(context.currentPage);

			context.setdisableBPage(false);
		}
	};

	const handleClickNext = (event, context) => {
		context.setCurrentPage(Number(event.target.id));
		context.setdisableBPage(false);
		context.setdisableBPage(false);
	};
	const handleClickNextBack = (event, context) => {
		if (context.currentPage === 1) {
			context.setdisableBPage(true);
			context.setdisablepage(false);
		} else {
			context.setCurrentPage(prevState => prevState - 1);
		}
	};

	const onChangePageSize = (e, context) => {
		if (e.target.value !== 'Page Entries') {
			context.setCustomPageSize(e.target.value);
			context.setCurrentPage(1);
		}
	};

	const getMessage = (id, context) => {
		setIsMessageLoading(true);
		props.sharedActions
			.getDataWithoutDispatch(`/api/View${context.account}Msg/?id=${id}`)
			.then(success => {
				setMessage(success);
				setIsMessageLoading(false);
				context.setUpdate(id);
			})
			.catch(error => {
				setIsMessageLoading(false);
				setMessage('');
			});
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor
					}}
					scope="row"
				>
					<span
						style={{
							height: '10px',
							width: '10px',
							backgroundColor: `${item.status == 0 ? 'red' : '#bbb'}`,
							borderRadius: '50%',
							display: 'inline-block'
						}}
					></span>{' '}
					{''}
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${themeColor.themeColors.primaryColor}`,
							padding: 2,
							borderColor: themeColor.themeColors.primaryColor,
							backgroundColor: `${item.status == 0 ? '#e6e6e6' : '#fff'}`
						}}
					>
						{item[item1]}
					</td>
				))}
				<td
					className="displayElemet"
					style={{
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeColor.themeColors.primaryColor
					}}
				>
					<InboxContext.Consumer>
						{context => (
							<a href="#" data-toggle="tooltip" title="View!">
								<button
									id="firstbutton"
									onClick={() => getMessage(item.id, context)}
									data-toggle="modal"
									data-target="#myModal1"
									style={{
										cursor: 'pointer',
										fontSize: 20,
										border: 'none',
										marginLeft: 5,
										background: 'none',
										marginBottom: 5
									}}
								>
									<i
										class="fas fa-envelope"
										style={{ color: Colors.SEA_GREEN_THEME }}
									></i>
								</button>
							</a>
						)}
					</InboxContext.Consumer>
				</td>
			</tr>
		));

	const messageView = (
		<div class="row">
			<div class="col-md-12 form-inline">
				<div class="col-md-2">
					<strong>From</strong>
				</div>
				<div class="col-md-10 border-bottom">
					<strong
						style={{
							backgroundColor: '#bbb'
						}}
					>
						{message.name}
					</strong>
				</div>
			</div>

			<br />

			<div class="col-md-12 form-inline" style={{ marginTop: '20px' }}>
				<div class="col-md-2">
					<strong>Date & Time</strong>
				</div>
				<div class="col-md-10 border-bottom">
					<strong
						style={{
							backgroundColor: '#bbb'
						}}
					>
						{message.date}
					</strong>
					{'     '}
					<strong
						style={{
							backgroundColor: '#bbb',
							marginLeft: '10px'
						}}
					>
						{' '}
						{message.time}{' '}
					</strong>
				</div>
			</div>

			<div class="col-md-12 form-inline" style={{ marginTop: '20px' }}>
				<div class="col-md-12 text-center border-bottom">
					<h4 className="h1-responsive font-weight-bold my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
						<strong
							style={{
								color: '#fff'
							}}
						>
							MESSAGE
						</strong>
						{'     '}
					</h4>
				</div>
			</div>

			<div class="col-md-12 form-inline" style={{ marginTop: '20px' }}>
				<div class="col-md-12 text-center border-bottom">
					<div class="col-md-12 ">
						<TextArea
							row={15}
							readOnly={true}
							customWidth={true}
							enterWidth={'220%'}
							feildName={'message'}
							errors={{}}
							stateData={message}
							// iconClassName={'fas fa-percentage'}
							placeholder={'Message'}
							isValidte={false}
						/>
					</div>
					<br />
					<br />
				</div>
				<br />
				<br />
			</div>
		</div>
	);

	return (
		<InboxContext.Consumer>
			{context => (
				<div id="div1">
					<div class="modal" id="myModal1">
						<div class="modal-dialog modal-lg modal-dialog-centered">
							<div class="modal-content modal_content_custom">
								<div
									style={{
										borderBottomLeftRadius: 30,
										borderBottomRightRadius: 30,
										borderTopLeftRadius: 18,
										borderTopRightRadius: 18,
										background: themeColor.themeColors.primaryColor
									}}
									class="modal-header modal_custom_header"
								>
									<h4 style={{ color: 'white' }} class="modal-title">
										Message
									</h4>
									<button
										style={{ color: 'white' }}
										type="button"
										class="close"
										data-dismiss="modal"
									>
										&times;
									</button>
								</div>
								<div class="modal-body">
									<div>
										<div>{isMessageLoading ? <Spinner /> : messageView}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div style={{ textAlign: 'right' }}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>
								<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
									{' '}
									Inbox{' '}
								</h2>
							</div>
							<div class="displayElemet">
								<div
									style={{
										marginTop: 40,
										display: 'flex',
										flexDirection: 'column'
									}}
								>
									<Pagination
										disableBPage={context.disableBPage}
										disablepage={context.disablepage}
										currentPage={context.currentPage}
										pageNumber={context.pageNumber}
										handleClickNext={e => handleClickNext(e, context)}
										handleClickNextBack={e => handleClickNextBack(e, context)}
										handleClickNextFrwd={e => handleClickNextFrwd(e, context)}
									/>
									<div
										style={{
											width: 'fit-content',
											marginTop: -34,
											display: 'flex',
											justifyContent: 'flex-end',
											marginLeft: 'auto'
										}}
									>
										<SelectionInput
											selectName="Entries"
											selectFont={14}
											selectFontWeight={'bold'}
											newFlag="customWidth"
											feildName="customPageSize"
											selectedText={'Page Entries'}
											optionsArrys={['10', '20', '30', '40', '50']}
											errors={{}}
											isValidte={false}
											stateData={{
												customPageSize: context.customPageSize
											}}
											optionType="static"
											onHandleChange={e => onChangePageSize(e, context)}
											useFlag={false}
										/>
									</div>
								</div>
							</div>
						</div>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span className="customBadge" style={{ fontSize: 14 }}>
								Page: {context.currentPage}
							</span>

							<span className="customBadge" style={{ fontSize: 14 }}>
								Total Page: {context.pagination['totalPages']}
							</span>
						</div>
					</div>
					<br />
					{context.isLoading ? (
						<Spinner />
					) : context.Inbox.length < 1 ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								color: 'red'
							}}
						>
							<strong>No Data</strong>
						</div>
					) : (
						<div className="table-responsive">
							<table class="table table-hover">
								<thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
									<tr class="vendorListHeading">
										<th scope="col">#</th>
										{tHead.map(item =>
											item === 'Actions' ? (
												<th
													className="displayElemet"
													style={{
														border: `1px solid ${Colors.SEA_GREEN_THEME}`,
														borderColor: Colors.WHITE
													}}
													scope="col"
												>
													{item}
												</th>
											) : (
												<th
													style={{
														border: `1px solid ${Colors.SEA_GREEN_THEME}`,
														borderColor: Colors.WHITE
													}}
													scope="col"
												>
													{item}
												</th>
											)
										)}
									</tr>
								</thead>
								<tbody>{bodyRendering(context.Inbox)}</tbody>
							</table>
						</div>
					)}
				</div>
			)}
		</InboxContext.Consumer>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InboxComponent);

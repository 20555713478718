import React from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import TextInput from '../../components/TextInput/TextInput'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import * as sharedActions from '../../Actions/sharedActions';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import Spinner from '../../components/Spinner/Spinner';
import general from '../../utils/general';
import TextArea from '../../components/TextArea'
import ThemeContext from "../../context/themeContext/ThemeContext";
class AddTransportMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            accountId: props.name,
            accountType: props.role,
            errors: {},
            errorMsg: false,
            isLoadingInitialData: true,
            vehicleDetail: {},
            sessionData: [],
            isLoadingSearch: false,
            sessionId: '',
            maintenance: '',
            month: '',
            driverId: '',
            vehicleId: '',
            otherDetails: '',
            driverIssue: '',
            driverName: ''
        }
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: false,
                    errorMsg: false
                });
            }, 3000);
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        this.props.sharedActions
            .getDataWithoutDispatch(
                '/api/Session'
            )
            .then(success => {
                this.setState({
                    sessionData: success.session,
                    isLoadingInitialData: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoadingInitialData: false
                });
                console.log(error);
            });
    }

    onSubmitSearch = e => {
        const { vehicleNo } = this.state;

        if (vehicleNo === '') {
            this.setState({
                error: true,
                errorMsg: true,
            })
        } else {
            this.setState({
                isLoadingSearch: true
            })

            this.props.sharedActions
                .getDataWithoutDispatch(
                    `/api/GetDriverFromVehicle?vehicleNo=${vehicleNo}`
                )
                .then(success => {
                    this.setState({
                        driverId: success.driverId,
                        vehicleId: success.vehicleId,
                        driverName: success.driverName,
                        isLoadingSearch: false,
                    })
                })
                .catch(error => {
                    this.setState({
                        isLoadingSearch: false
                    })
                })
        }
    }

    onHandleTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHandleSelectChange = e => {
        if (e.target.value !== 'Month' && e.target.value !== 'Maintenance' & e.target.value !== 'Session') {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { vehicleId, driverId, month, sessionId, maintenance, otherDetails, driverIssue, accountType, accountId } = this.state
        if (vehicleId === '' ||
            driverId === '' ||
            month === '' ||
            sessionId === '' ||
            maintenance === ''
        ) {
            this.setState({
                error: true,
                errorMsg: true
            })
        }
        else {
            this.setState({
                isLoading: true
            })
            const data = {
                vehicleId,
                driverId,
                month,
                sessionId,
                maintenance,
                otherDetails,
                driverIssue,
                accountId,
                accountType
            }
            console.log(data);
            this.props.sharedActions
                .simpleAddRequest('/api/TransportMaintenance/',
                    data)
                .then(success => {
                    this.setState({
                        msg: 'Added Transport Maintenance Successfully',
                        sessionId: '',
                        maintenance: '',
                        month: '',
                        driverId: '',
                        vehicleId: '',
                        otherDetails: '',
                        driverIssue: '',
                        driverName: '',
                        isLoading: false,
                        errorMsg: false,
                        error: false,
                    })
                    this.props.snackbar();
                })
        }

    }
    render() {
        const { errors, isLoadingInitialData, isLoadingSearch, sessionData } = this.state;

        const Form = (
            <ThemeContext.Consumer>
            {(context) => (
            <FormLayoutWrapper
                formName='Add Transport Maintenance'
                borderColor={context.themeColors.primaryColor}
                borderRadius={15}
            >
                {this.state.errorMsg ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please fill the form properly
                    </p>
                ) : (
                        ''
                    )}

                <div className="row" style={{ justifyContent: 'center' }}>
                    <TextInput
                        feildName={'vehicleNo'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        placeholder={'Enter Vehicle #'}
                        isValidte={false}
                    />
                </div>
                <Button
                    icon={true}
                    width="100%"
                    buttonClass="customButton"
                    btnName="Search"
                    loaderBmargin={5}
                    stateData={{
                        isLoading: isLoadingSearch
                    }}
                    marginTop={20}
                    onHandleSubmit={this.onSubmitSearch}
                    margin={'0 auto'}
                    textColor='white'
                />


                <div className="row">

                    <TextInput
                        marginTop={20}
                        feildName={'driverName'}
                        inputLabelName={'Driver Name'}
                        errors={errors}
                        stateData={this.state}
                        placeholder="Driver Name"
                        readOnly={true}
                        type={'text'}
                        isValidte={false}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'month'}
                        selectName={'Month'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={general.Months}
                        selectedText={'Month'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="static"
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'sessionId'}
                        selectName={'Session'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={sessionData}
                        selectedText={'Session'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="dynamicWithPropIdAndName"
                        property={'currentSession'}
                        propertyId={'sessionId'}
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <SelectionInput
                        marginTop={20}
                        feildName={'maintenance'}
                        selectName={'Maintenance'}
                        onHandleChange={e => this.onHandleSelectChange(e)}
                        errors={errors}
                        optionsArrys={general.maintenance}
                        selectedText={'Maintenance'}
                        stateData={this.state}
                        // iconClassName={'fas fa-building'}
                        optionType="static"
                        successflag={'successflag'}
                        isValidte={false}
                    // editUse={'staffEdit'}
                    />

                    <TextArea
                        feildName={'otherDetails'}
                        inputLabelName={'Other Details'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Other Details'}
                        isValidte={false}
                    />

                    <TextArea
                        feildName={'Driver Issue'}
                        inputLabelName={'Driver Issue'}
                        onHandleChange={this.onHandleTextChange}
                        errors={errors}
                        stateData={this.state}
                        // iconClassName={'fas fa-percentage'}
                        placeholder={'Driver Issue'}
                        isValidte={false}
                    />



                </div>
                <br />
                <Button
                    width="100%"
                    buttonClass="customButton"
                    btnName="Add"
                    loaderBmargin={5}
                    stateData={this.state}
                    marginTop={20}
                    onHandleSubmit={this.onSubmit}
                    margin={'0 auto'}
                    textColor={'white'}
                />

            </FormLayoutWrapper>
            )}
            </ThemeContext.Consumer>
        )
        return (
            <React.Fragment>
                <SnackBar msg={this.state.msg} />
                {isLoadingInitialData ? <Spinner /> : Form}
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTransportMaintenance);
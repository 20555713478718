import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as sharedActions from '../../Actions/sharedActions';
import general from '../../utils/general';
import moment from 'moment';
import Colors from '../../utils/app_constants/colors_constants';
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import themeContext from "../../context/themeContext/ThemeContext";

const DeparmentWiseTimeTable = props => {
    const themeColor = useContext(themeContext);
	
	const [isLoading, setIsLoading] = useState(true);
	const [timeTableData, setTimeTableData] = useState({});
	const [tHead, setTHead] = useState([]);
	const [renderFields, setRenderFields] = useState([]);
	const [periods, setPeriods] = useState([]);
	const [departmentData, setDepartmentData] = useState({});
	const [timeTablePeriods, setTimeTablePeriods] = useState([])

	const [state, setState] = useState({
		today: moment(),
		from_date: '',
		to_date: ''
	});

	const [isDepartmentLoading, departments] = httpWithRequiredSetData(
		`/api/HODDashboard/?username=${props.name}`,
		[],
		data => {
			setDepartmentData(data);
		}
	);

	useEffect(() => {
		if (
			state['today'].format('dddd') !== 'Friday' &&
			state['today'].format('dddd') !== 'Saturday'
		) {
			const from_date = moment()
			.startOf('week');

			const to_date = moment()
				.endOf('week')
				.subtract(2, 'days');

			props.sharedActions
				.getDataWithoutDispatch(`/api/HODDashboard/?username=${props.name}`)
				.then(data => {
					setDepartmentData(data);

					props.sharedActions
						.getDataWithoutDispatch(
							`/api/departmentWiseTimetable/?departmentId=${data.hodPostId}
						&fromDate=${general.dateToFormat(from_date)}&toDate=${general.dateToFormat(
								to_date
							)}`
						)
						.then(dataa => {
							setTHead(dataa.days);
							setPeriods(dataa.staffWisePeriodsList);
							setTimeTablePeriods(data.period)
							setIsLoading(false);
						})
						.catch(err => {
							setTHead([]);
							setIsLoading(false);
						});
				})
				.catch(err => {});
		} else {
			const from_date = moment()
				.add(1, 'weeks')
				.startOf('week');
			const to_date = moment()
				.add(1, 'weeks')
				.endOf('week')
				.subtract(2, 'days');

			props.sharedActions
				.getDataWithoutDispatch(`/api/HODDashboard/?username=${props.name}`)
				.then(data => {
					setDepartmentData(data);

					props.sharedActions
						.getDataWithoutDispatch(
							`/api/departmentWiseTimetable/?departmentId=${data.hodPostId}
					&fromDate=${general.dateToFormat(from_date)}&toDate=${general.dateToFormat(
								to_date
							)}`
						)
						.then(data => {
							setTHead(data.days);
							setPeriods(data.staffWisePeriodsList);
							setTimeTablePeriods(data.period)

							setIsLoading(false);
						})
						.catch(err => {
							setIsLoading(false);
						});
				})
				.catch(err => {});
		}
	}, []);

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					height="140px"
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor,
						textAlign: 'center',
						verticalAlign: 'middle',
						background: '#A9A9A9',
						color: 'white'
					}}
					scope="row"
				>
					<div>{item.staffName}</div>
				</th>
				{item.dateWise.map((item1, index) =>
					item1.periodsData.map((item2, index) => (
						<td
							height="120px"
							style={
								item2.periods.length < 1
									? {
											fontWeight: 'bold',
											border: `1px solid white`,
											padding: 2,
											borderColor: themeColor.themeColors.primaryColor,
											textAlign: 'center',
											verticalAlign: 'middle',
											color: themeColor.themeColors.primaryColor
									  }
									: {
											fontWeight: 'bold',
											border: `1px solid white`,
											padding: 2,
											borderColor: 'white',
											textAlign: 'center',
											verticalAlign: 'middle',
											backgroundColor:
												general.timeTableColors[
													Math.floor(
														Math.random() *
															Math.floor(general.timeTableColors.length)
													)
												],
											color: 'white'
									  }
							}
						>
							{item2.periods.length < 1 ? (
								<div
									style={{
										transform: 'rotate(90deg)'
									}}
								>
									<strong>/</strong>
								</div>
							) : (
								<div
									style={{
										transform: 'rotate(90deg)'
									}}
								>
									{item2.periods.map((item3, index) => (
										<strong>{`${item3.className}-${item3.section} `}</strong>
									))}
								</div>
							)}
						</td>
					))
				)}
			</tr>
		));

	const TimeTableView = (
		<div className="table-responsive">
			<div style={{ textAlign: 'right' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
							{' '}
							{departmentData.hodPostName} Department Timetable{' '}
						</h2>
					</div>
				</div>
			</div>
			<table
				class="table table-hover text-center animated slideInUp"
				style={{
					borderCollapse: 'separate',
					borderSpacing: '5px 10px',
					fontSize: '22px'
				}}
			>
				<thead style={{ background: '#A9A9A9', color: 'white' }}>
					<tr class="vendorListHeading">
						<th
							rowSpan={2}
							scope="col"
							style={{
								textAlign: 'center',
								verticalAlign: 'middle'
							}}
						>
							<i
								class="fas fa-chalkboard-teacher"
								style={{ color: 'white' }}
							></i>{' '}
							<strong> Staff</strong>
						</th>
						{tHead.map(item => (
							<th
								colSpan={timeTablePeriods.length}
								style={{
									border: `1px solid ${themeColor.themeColors.primaryColor}`,
									borderColor: Colors.WHITE
								}}
								scope="col"
							>
								<div>
									<strong>{item.date}</strong>
									<br />
									<strong>{item.dayName}</strong>
								</div>
							</th>
						))}
					</tr>
					<tr class="vendorListHeading">
					{[...timeTablePeriods,...timeTablePeriods,...timeTablePeriods,...timeTablePeriods,...timeTablePeriods].map(item => (
							<th
								style={{
									border: `1px solid ${themeColor.themeColors.primaryColor}`,
									borderColor: Colors.WHITE
								}}
								scope="col"
							>
								<div>
									<strong>{item}</strong>
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>{bodyRendering(periods)}</tbody>
			</table>
		</div>
	);

	return (
		<div id="div1">
			{isLoading ? (
				<Spinner />
			) : tHead < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				TimeTableView
			)}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeparmentWiseTimeTable);

import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination'
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import DatePicker from 'react-date-picker';
import themeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Month',
    'Date',
    'Class',
    'Section',
    'Session',
    'Tour',
    'Description',
];

let renderFields = [
    'month',
    'tourDate',
    'className',
    'section',
    'session',
    'tour',
    'description',
]

const ViewFilterTour = props => {
    const themeColor = useContext(themeContext);
    const [classId, setClassId] = useState('');
    const [sectionId, setSectionId] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [date, setDate] = useState('')

    const [classData, setClassData] = useState([]);
    const [sectionData, setSectionData] = useState([]);
    const [sessionData, setSessionData] = useState([]);
    const [tourData, setTourData] = useState([])

    const [isClassSectionLoading, classSectionData] = httpWithRequiredSetData(
        '/api/ClassesSections',
        [],
        data => {
            setClassData(data['classes']);
        }
    );

    const [isSessionLoading, sData] = httpWithRequiredSetData(
        '/api/session',
        [],
        data => {
            setSessionData(data['session'])
        }
    );

    const [searchURL, setSearchURL] = useState(
        '/api/AllTours?pageNumber=1&pageSize=10&classId=&sectionId=&sessionId=&date='
    );

    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});


    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setTourData(data['tourList']);

        setPaginationData(data['paginationMetadata']);
    });

    const onClassChange = inputClassId => {
        if (inputClassId !== 'Choose Class') {
            let getSection = general.dataFilteration(
                classSectionData['gradeSection'],
                ['sectionId', 'section'],
                {
                    id: inputClassId
                }
            );

            setIsLoading(true);
            setClassId(inputClassId);
            props.sharedActions
                .getDataWithoutDispatch(
                    general.condtionalServicesTour(
                        inputClassId,
                        '',
                        sessionId,
                        date,
                        currentPage,
                        customPageSize
                    )
                )
                .then(success => {
                    console.log(success);

                    setTourData(success['tourList']);
                    let pageNumber = [];
                    for (
                        let i = 1;
                        i <= success['paginationMetadata']['totalPages'];
                        i++
                    ) {
                        pageNumber.push(i);
                    }
                    setPageNumber(pageNumber);
                    setPaginationData(success['paginationMetadata']);
                    setSectionData(getSection);

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    setTourData([])
                });
        }
    };


    const onSectionChange = inputSectionId => {
        if (inputSectionId !== 'Choose Section') {
            setIsLoading(true);
            setSectionId(inputSectionId);
            props.sharedActions
                .getDataWithoutDispatch(
                    general.condtionalServicesTour(
                        classId,
                        inputSectionId,
                        sessionId,
                        date,
                        currentPage,
                        customPageSize
                    )
                )
                .then(success => {
                    setTourData(success['tourList']);
                    let pageNumber = [];
                    for (
                        let i = 1;
                        i <= success['paginationMetadata']['totalPages'];
                        i++
                    ) {
                        pageNumber.push(i);
                    }
                    setPageNumber(pageNumber);
                    setPaginationData(success['paginationMetadata']);

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    setTourData([])
                });
        }
    };

    const onHandleSelectChange = inputSessionId => {
        if (inputSessionId !== 'Session') {
            setIsLoading(true);
            setSessionId(inputSessionId);
            props.sharedActions
                .getDataWithoutDispatch(
                    general.condtionalServicesTour(
                        classId,
                        sectionId,
                        inputSessionId,
                        date,
                        currentPage,
                        customPageSize
                    )
                )
                .then(success => {
                    setTourData(success['tourList']);
                    let pageNumber = [];
                    for (
                        let i = 1;
                        i <= success['paginationMetadata']['totalPages'];
                        i++
                    ) {
                        pageNumber.push(i);
                    }
                    setPageNumber(pageNumber);
                    setPaginationData(success['paginationMetadata']);

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    setTourData([])
                });
        }
    };

    const onDateChange = date => {

        setIsLoading(true);
        setDate(date)

        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesTour(
                    classId,
                    sectionId,
                    sessionId,
                    date,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setTourData(success['tourList']);
                let pageNumber = [];
                for (
                    let i = 1;
                    i <= success['paginationMetadata']['totalPages'];
                    i++
                ) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);

                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setTourData([])
            });
    }

    useEffect(() => {
        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesTour(
                    classId,
                    sectionId,
                    sessionId,
                    date,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setTourData(success['tourList']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setTourData([])
            });
    }, [currentPage, /* getdepartmentId, */ customPageSize]);


    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };
    const [msg, setSnackMsg] = useState('');

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
        window.location.reload();
    };

    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${themeColor.themeColors.primaryColor}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
            </tr>
        ));

    return (
        <div>
            <SnackBar msg={msg} />
            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Tour Filter Details{' '}
                        </h2>
                    </div>
                    <div
                        style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                    >
                        <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={handleClickNext}
                            handleClickNextBack={handleClickNextBack}
                            handleClickNextFrwd={handleClickNextFrwd}
                        />
                        <div
                            style={{
                                width: 'fit-content',
                                marginTop: -34,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto'
                            }}
                        >
                            <SelectionInput
                                selectName="Entries"
                                selectFont={14}
                                selectFontWeight={'bold'}
                                newFlag="customWidth"
                                feildName="customPageSize"
                                selectedText={'Page Entries'}
                                optionsArrys={['10', '20', '30', '40', '50']}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    customPageSize: customPageSize
                                }}
                                optionType="static"
                                onHandleChange={onChangePageSize}
                                useFlag={false}
                            />
                        </div>
                    </div>
                </div>
                <CustomFilterSectionLayout displayDirection="column">
                    <div
                        style={{
                            width: '30%',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <React.Fragment>
                            <SelectionInput
                                selectName="Class"
                                newFlag="customWidth"
                                feildName="classId"
                                selectedText={'Choose Class'}
                                optionsArrys={classData}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    classId: classId
                                }}
                                optionType="dynamic"
                                onHandleChange={e => onClassChange(e.target.value)}
                                property={'className'}
                                propertyId={'id'}
                                useFlag={false}
                            />
                            <SelectionInput
                                selectName="Section"
                                newFlag="customWidth"
                                feildName="sectionId"
                                selectedText={'Choose Section'}
                                optionsArrys={sectionData}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    sectionId: sectionId
                                }}
                                optionType="dynamic"
                                onHandleChange={e => onSectionChange(e.target.value)}
                                property={'section'}
                                propertyId={'sectionId'}
                                useFlag={false}
                            />

                            <SelectionInput
                                feildName={'sessionId'}
                                selectName={'Session'}
                                onHandleChange={e => onHandleSelectChange(e.target.value)}
                                errors={{}}
                                optionsArrys={sessionData}
                                selectedText={'Session'}
                                stateData={{ sessionId: sessionId }}
                                // iconClassName={'fas fa-building'}
                                optionType="dynamicWithPropIdAndName"
                                property={'currentSession'}
                                propertyId={'sessionId'}
                                successflag={'successflag'}
                                isValidte={false}
                            // editUse={'staffEdit'}
                            />


                            <div class="col-md-6" style={{ marginTop: 25 }}>
                                <div class="md-form mb-0">
                                    <label for="email" class="">
                                        Date On
											</label>
                                    <br />

                                    <DatePicker
                                        //                                    maxDate={true}
                                        onChange={e => onDateChange(e)}
                                        value={date}
                                        selectDateOnly
                                    />
                                </div>
                            </div>
                        </React.Fragment>

                    </div>
                </CustomFilterSectionLayout>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) :
                tourData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Classes Found</strong>
                </div>
                    : (

                        <div id='div1' className="table-responsive">
                            <div class="">
          <img
            src={require("../../Assets/images/header.png")}
            style={{
              width: "100%",
              height: "150",
            }}
          />
        </div>
                            <table class="table table-hover">
                                <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <tr>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            <th
                                                style={{
                                                    border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                                    borderColor: Colors.WHITE
                                                }}
                                                scope="col"
                                            >
                                                {item}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{bodyRendering(tourData)}</tbody>
                            </table>
                            <div class="page-footer">
            <div class="text-left">Printed By: {props.role}</div>
            <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
              Powered by School Smart®
            </div>
          </div>
                        </div>
                    )}
                     <div class="text-center text-md-right">
                    <label for="name" class="" onClick={() => PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
function mapStateToProps(state) {
    return {
        name: state.setUserReducer.users.unique_name,
        role: state.setUserReducer.users.role,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewFilterTour);
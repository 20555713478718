class RoleTypes {
  static OWNER = "owner";
  static ACADMIC_SMART = "academic smart";
  static STUDENT_SMART = "student";
  static STAFF_SMART = "staff";
  static FINANCE_SMART = "finance smart";
  static HR_SMART = "hr smart";
  static PARENT_SMART = "parent";
  static HOD_SMART = "hod";

  static COMMUNICATION_SMART = "communication smart";
  static GENERAL_MANAGER_SMART = "general manager";
  static ADMISSION_SMART = "student smart";
  static ADMINISTRATION_SMART = "administration smart";
  static ADMISSION_MANAGER = "admission manager";
  static ACCOUNTANT_MANAGER = "accountant";
  static BILLING_MANAGER = "accountant (bill)";
  static ENTRY_EXAM_COORDINATOR = "entry exam coordinator";
  static REGISTRATION_COORDINATOR = "registration coordinator";
  static WELLBEING_COORDINATOR = "wellbeing coordinator";
  static ACTIVITY_COORDINATOR = "activity coordinator";
  static MEDICAL_STAFF = "medical staff";
  static STAFF_COORDINATOR = "staff coordinator";
  static STUDENT_COORDINATOR = "student coordinator";
  static MAINTENANCE_COORDINATOR = "maintenance coordinator";
  static TRANSPORT_COORDINATOR = "transport coordinator";
  static CANTEEN_COORDINATOR = "canteen coordinator";
  static PORTAL_COORDINATOR = "portal coordinator";
  static EXAM_COORDINATOR = "exam coordinator";
  static TIMETABLE_COORDINATOR = "timetable coordinator";
  static NEWS_COORDINATOR = "news coordinator";
  static FRONT_DESK = "front desk (hr)";
  static Grade_Coordinator = "grade coordinator";
  static SEN = "sen";
  static SECURITY_MANAGER = "security staff";
  static PROCUREMENT_MANAGER = "procurement coordinator";
  static PASTORAL_SMART = "pastoral smart";
  static EXCLUSION_MANAGER = "exclusion manager";
  static COMMUNICATION_COORDINATOR = "communication coordinator";
  static EXCLUSION_COORDINATOR = "exclusion coordinator";
  static STAFF_FORM_MANAGER = "staff form";
  static STUDENT_FORM_MANAGER = "student form";
  static INVENTORY_MANAGER = "inventory manager";
  static LIBRARY_MANAGER = "library manager";
  static DOCTOR_MANAGER = "doctor";

  //Dean Roles
  static DEAN_ARABIC = "dean arabic";
  static DEAN_ACTIVITIES = "dean activities";
  static DEAN_ENGLISH = "dean english";
  //End Dean Roles

  //Coordinator Roles
  static COORDINATOR_LANGUAGE = "coordinator language";
  static Coordinator_Maths_Sciences = "coordinator maths & sciences";
  static Coordinator_Business_Ict = "coordinator business & ict";

  //End Coordinator Roles

  //HOD Dashboard Start
  static PHYSICS_HOD = "physics";
  static MATHEMATICS_HOD = "mathematics";
  static CHEMISTRY_HOD = "chemistry";
  static BIOLOGY_HOD = "biology";
  static GENERAL_SCIENCE_HOD = "general science";
  static ENGLISH_HOD = "english";
  static FRENCHCHINESE_HOD = "french/chinese";
  static ICT_HOD = "ict";
  static BUSINESS_ACCOUNTS_HOD = "business/accounts/economics";
  static ARABIC_HOD = "arabic";
  static ISLAMIC_HOD = "islamic";
  static SOCIAL_HOD = "social/life skill";
  static MUSIC_HOD = "music";
  static ARTS_HOD = "arts";
  static SPORTS_HOD = "sports";
  static DESIGN_DRAMA_HOD = "design/drama/gp";
}

export default RoleTypes;

import React, { Component } from "react";
import "./Hr.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as DevelopementActions from "../../Actions/HrDevelopment";
import * as sharedActions from "../../Actions/sharedActions";
import DateandTime from "../Transport/DateandTime";
import $ from "jquery";
import DatePicker from "react-date-picker";
import ThemeContext from "../../context/themeContext/ThemeContext";

class HrDevelopmentAndTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      chooseFile: null,
      description: "",
      name: "",
      title: "",
      trainingDate: "",
      trainingTime: "",
      fees: "",
      location: "",
      type: "",
      link: "",
      fileName: "",
      errors: {},
      msg: false,
      errorMsg: false,
    };
  }
  onChangec = (e) => {
    var file = this.refs.file.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      this.setState({
        file: reader.result,
      });
    }.bind(this);
  };
  componentDidMount() {
    $("#chooseFile").bind("change", function () {
      var filename = $("#chooseFile").val();
      if (/^\s*$/.test(filename)) {
        $(".file-upload").removeClass("active");
        $("#noFile").text("No file chosen...");
      } else {
        $(".file-upload").addClass("active");
        $("#noFile").text(filename.replace("C:\\fakepath\\", ""));
      }
    });
  }
  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }
  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChange = (e) => {
    if (
      (e.target.files[0].size < 2000000 &&
        e.target.files[0].type == "image/jpeg") ||
      e.target.files[0].type == "image/png"
    ) {
      this.setState({
        chooseFile: e.target.files[0],
      });
    } else {
      window.alert("Select only 2 MB File and File should be in JPEG and PNG");
    }

    console.log(this.state.chooseFile);
    console.log(e.target.files[0].size);
  };
  handleChange = (date, state) => {
    this.setState({
      [state]: date,
    });
    console.log(date);
  };
  handleSubmit = (e) => {
    const {
      description,
      type,
      fees,
      title,
      chooseFile,
      location,
      trainingTime,
      trainingDate,
    } = this.state;
    e.preventDefault();
    if (
      Object.is(description, "") ||
      Object.is(type, "") ||
      Object.is(fees, "") ||
      Object.is(title, "") ||
      Object.is(chooseFile, "") ||
      Object.is(location, "") ||
      Object.is(trainingTime, "") ||
      Object.is(trainingDate, "")
    ) {
      this.setState({
        error: true,
        errorMsg: true,
      });
    } else {
      console.log(this.state);
      const {
        description,
        title,
        fees,
        type,
        location,
        trainingTime,
        trainingDate,
        link,
      } = this.state;
      let file = this.state.chooseFile;
      let accountType = this.state.accountType;
      let accountId = this.state.accountId;
      accountId = this.props.userData.unique_name;
      accountType = this.props.userData.role;
      let data = new FormData();
      data.append("file", file);
      data.append("description", description);
      data.append("title", title);
      data.append("fees", fees);
      data.append("type", type);
      data.append("location", location);
      data.append("trainingTime", trainingTime);
      data.append("trainingDate", trainingDate);
      data.append("link", link);
      data.append("accountType", accountType);
      data.append("accountId", accountId);
      console.log("DATA Part", data);

      this.props.sharedActions
        .addRecord(
          "/api/HrDevTrainning",
          data,
          this.props.DevelopementActions.addDevelopment
        )
        .then((success) => {
          this.setState({
            description: "",
            link: "",
            title: "",
            fees: "",
            type: "",
            location: "",
            trainingTime: "",
            trainingDate: "",
            chooseFile: "",
            file: "",
            msg: true,
            errorMsg: false,
          });
          // this.props.campusActions.updateCampusFlag(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    console.log(this.state);
  };
  render() {
    return (
      <div>
        <section class="mb-4">
          <h2 class="h1-responsive font-weight-bold text-center my-4">
            HR Development And Training
          </h2>

          <p class="text-center w-responsive mx-auto mb-5" />

          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ThemeContext.Consumer>
              {(context) => (
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  {this.state.errorMsg ? (
                    <p style={{ color: "red", textAlign: "center" }}>
                      Please fill the form properly
                    </p>
                  ) : (
                    ""
                  )}
                  {this.state.msg ? (
                    <p style={{ color: "green", textAlign: "center" }}>
                      Added Successfully!
                    </p>
                  ) : (
                    ""
                  )}
                  <form
                    onSubmit={this.handleSubmit}
                    id="contact-form"
                    name="contact-form"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <DateandTime />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Name
                          </label>
                          <input
                            type="text"
                            name="AdminName"
                            class="form-control"
                            readOnly={true}
                            value={this.props.userData.unique_name}
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Type
                          </label>
                          <input
                            type="text"
                            name="AdminName"
                            class="form-control"
                            readOnly={true}
                            value={this.props.userData.role}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Link
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            placeholder="Link (Optional)"
                            class="form-control"
                            name="link"
                            value={this.state.link}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Title
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            placeholder="Title Name"
                            class="form-control"
                            name="title"
                            value={this.state.title}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Type
                          </label>
                          <select
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            onChange={this.onHandleTextChange}
                            class="custom-select"
                            name="type"
                          >
                            <option selected={this.state.msg ? true : false}>
                              Select Type
                            </option>
                            <option value="Conference">Conference</option>
                            <option value="Courses">Courses</option>
                            <option value="Seminars">Seminars</option>
                            <option value="Training">Training</option>
                            <option value="Workshop">Workshop</option>
                            <option value="Face To Face">Face To Face</option>
                            <option value="Online">Online</option>
                            <option value="Webinar">Webinar</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                             Time
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            placeholder="Training Time"
                            class="form-control"
                            name="trainingTime"
                            value={this.state.trainingTime}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div
                        class="col-md-6"
                        style={{
                          marginTop: 20,
                          borderColor: context.themeColors.primaryColor,
                        }}
                      >
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Fees
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            placeholder="Fees"
                            class="form-control"
                            name="fees"
                            value={this.state.fees}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div
                        class="col-md-6"
                        style={{
                          marginTop: 20,
                          borderColor: context.themeColors.primaryColor,
                        }}
                      >
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Location
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            placeholder="Location"
                            class="form-control"
                            name="location"
                            value={this.state.location}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                             Date
                          </label>
                          <br />
                          <DatePicker
                            selected={this.state.trainingDate}
                            onChange={(e) =>
                              this.handleChange(e, "trainingDate")
                            }
                            value={this.state.trainingDate}
                            selectDateOnly
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 50 }}>
                        <div class="md-form mb-0">
                          <div class="file-upload">
                            <div class="file-select">
                              <div class="file-select-button" id="fileName">
                                Choose File
                              </div>
                              <div class="file-select-name" id="noFile">
                                No File Choosen
                              </div>
                              <input
                                type="file"
                                name="chooseFile"
                                id="chooseFile"
                                onChange={this.onChange}
                                accept="image/png, image/jpeg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-6"
                        style={{
                          marginTop: 20,
                          borderColor: context.themeColors.primaryColor,
                        }}
                      >
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Description
                          </label>
                          <textarea
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            class="form-control rounded-0"
                            id="exampleFormControlTextarea2"
                            rows="3"
                            name="description"
                            onChange={this.onHandleTextChange}
                            placeholder="Add Here.."
                            value={this.state.description}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div class="text-center text-md-right">
                      <button
                        onClick={this.handleSubmit}
                        class="buttonHover2"
                        style={{
                          background: context.themeColors.primaryColor,
                        }}
                      >
                        Upload
                      </button>
                    </div>
                    <div class="status" />
                  </form>
                </div>
              )}
            </ThemeContext.Consumer>
          </div>
        </section>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    DevelopementActions: bindActionCreators(DevelopementActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HrDevelopmentAndTraining);

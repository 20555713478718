import React, { Component } from "react";
import $ from "jquery";
import RenderDynamicRows from "./RenderTextBoxex";
import ThemeContext from "../../context/themeContext/ThemeContext";

class MultipleSkill extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("#addBtn").hover(
        function () {
          $(this).css(
            "background",
            "linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%)"
          );
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("background", "#0A4F5E");
        }
      );
    });
  };
  render() {
    const {
      onHandleTextChange,
      stateData,
      updateSpecialArrObjOnChange,
      onClickAddItem,
      onClickDeleteItem,
      onChanging,
    } = this.props;
    const { session } = stateData.session;
    return (
      <div class="animated fadeInDown">
        <h5 class="h5-responsive font-weight-bold text-left my-4">
          Add Student Skils:
        </h5>
        <small class="text-danger">
          Add Grading from 1 to 5 to fill these Information.
        </small>

        <div style={{ width: "100%" }}>
          <ThemeContext.Consumer>
            {(context) => (
              <div class="table-responsive">
                <table class="table table-hover text-center">
                  <thead
                    style={{
                      background: context.themeColors.primaryColor,
                      color: "white",
                    }}
                  >
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Student Name</th>
                      <th scope="col">Academic Progress</th>
                      <th scope="col">Efforts</th>
                      <th scope="col">Participants</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stateData &&
                      stateData.SkillReports.map((item, index) => (
                        <tr key={index}>
                          <th scope={"row"}>{index + 1}</th>
                          <RenderDynamicRows
                            arrName={"SkillReports"}
                            updateSpecialArrObjOnChange={
                              updateSpecialArrObjOnChange
                            }
                            rowIndex={index}
                            onHandleTextChange={onHandleTextChange}
                            stateData={stateData}
                            itemObj={item}
                          />
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </ThemeContext.Consumer>

          {/* <div className={'row'} style={{ justifyContent: 'flex-end' }}>
						<div>
							<button
								id={'addBtn'}
								onMouseEnter={this.btnTechHover()}
								onClick={() =>
									onClickAddItem('DriverHistories', {
										FineDetails: '',
										FineFee: '',
										FineType: '',
										TicketNo: ''
									})
								}
								style={{
									background: '#0A4F5E',
									color: '#FFFFFF',
									borderRadius: 100
								}}
								type="button"
								class="btn"
							>
								<i class="fas fa-plus" />
							</button>
						</div>
						<div style={{ marginLeft: 8 }}>
							<button
								onClick={() => onClickDeleteItem('DriverHistories')}
								style={{ color: '#FFFFFF', borderRadius: 100 }}
								type="button"
								class="btn btn-danger"
							>
								<i class="far fa-trash-alt" />
							</button>
						</div>
					</div> */}
        </div>
        <br />
        <br />
        <br />
        {/*<div class="text-center text-md-center">*/}
        {/*<button class="btns">Add</button>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default MultipleSkill;

import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import general from "../../utils/general";
import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewLeave extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      reportData: {},
      leaveData: [],
      classData: [],
      sectionData: [],
      subjectData: [],
      isLoading: false,
      isLoadingInitialData: true,
      snackColor: "",
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      classId: "",
      subjectId: "",
      sectionId: "",
      errorMsg: false,
    };
  }

  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/DescriptiveReport?userName=${this.props.name}`
      )
      .then((success) => {
        this.setState({
          reportData: success,
          classData: success.classes,
          staffClassSection: success.staffClassSection,
          isLoadingInitialData: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoadingInitialData: false,
        });
      });
  }

  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: "",
          errorMsg: false,
          snackColor: "",
        });
      }, 3000);
    }
  }

  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };

  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { leaveData } = this.state;
    let getData = leaveData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      id: getData[0].id,
      data: getData[0],
    });
  };

  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };

  onHandleClassChange = (e) => {
    const { staffClassSection } = this.state;

    let sectionData = general.dataFilteration(
      staffClassSection,
      ["section", "sectionId"],
      {
        classId: parseInt(e.target.value),
      }
    );
    this.setState({
      [e.target.name]: e.target.value,
      sectionData: sectionData,
    });
  };

  onHandleSectionChange = (e) => {
    const { staffClassSection, classId } = this.state;

    let subjectData = general.dataFilteration(
      staffClassSection,
      ["subjectName", "subjectId"],
      {
        classId: parseInt(classId),
        sectionId: parseInt(e.target.value),
      }
    );
    this.setState({
      [e.target.name]: e.target.value,
      subjectData: subjectData,
    });
  };

  onHandleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { subjectId, classId, sectionId } = this.state;

    if (
      Object.is(subjectId, "") ||
      Object.is(classId, "") ||
      Object.is(sectionId, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      this.props.sharedActions
        .getDataWithoutDispatch(
          `/api/ViewLeaveBySubject?subjectId=${subjectId}`
        )
        .then((success) => {
          this.setState({
            msg: "Leaves for the Selected Query",
            leaveData: success,
            isLoading: false,
            classId: "",
            subjectId: "",
            sectionId: "",
          });
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 404) {
            // 404-Error no understanding of 404 Error
            this.setState({
              snackColor: "#ff0000",
              msg: error.response.data.Message,
              isLoading: false,
            });
          }
        });
      this.props.snackbar();
    }
  };

  onTakeItemApprove = (e) => {
    this.setState({
      isLoading: true,
    });
    const { data, id } = this.state;
    console.log(data);

    data.status = "Approved";

    console.log(data);

    this.props.sharedActions
      .editRecordWithoutDispatch("/api/StudentLeaveApplication/", id, data)
      .then((success) => {
        this.setState({
          enabled: false,
          msg: "Approved Record Successfully!",
          id: "",
          isLoading: false,
        });
        this.props.snackbar();
      })
      .catch((err) => {});
  };

  render() {
    const theme = this.context;
    const {
      errors,
      classData,
      sectionData,
      leaveData,
      subjectData,
    } = this.state;
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          class="spinner-border"
          style={{ color: theme.themeColors.primaryColor }}
        ></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(leaveData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= 3 ? (
        <li
          class={`page-item ${
            this.state.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={this.handleClickNext}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      ) : (
        ""
      );
    });
    // parseFloat
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = leaveData.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((item, index) => {
      return (
        <tr className="bordersSpace" key={index}>
          <th className="bordersSpace" scope="row">
            {index + 1}
          </th>
          <td className="bordersSpace">{item.studentName}</td>
          <td className="bordersSpace">{item.leaveType}</td>
          <td className="bordersSpace">{item.fromdate}</td>
          <td className="bordersSpace">{item.todate}</td>

          <td className="bordersSpace">{item.accountId}</td>
          <td className="bordersSpace">{item.accountType}</td>
          <td className="bordersSpace">{item.status}</td>

          <td className="bordersSpace">
            <a href="#" data-toggle="tooltip" title="Approve!">
              <button
                disabled={item.status === "Approved" ? true : false}
                onClick={() => this.onEditClick(item.id)}
                data-toggle="modal"
                data-target="#myModal1"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i
                  style={
                    item.status === "On-Hold"
                      ? { color: theme.themeColors.primaryColor }
                      : { color: "#d3d3d3" }
                  }
                  class="fas fa-check"
                ></i>
              </button>
            </a>
          </td>
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <div>
                  <div style={{ textAlign: "center" }}>
                    <h4>Are you sure ?</h4>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div>
                      <button
                        disabled={this.state.enabled}
                        data-dismiss="modal"
                        onClick={() => this.onTakeItemApprove(this.state.id)}
                        type="button"
                        class="btn btn-primary buttonAppear"
                      >
                        Yes
                      </button>
                    </div>
                    <div>
                      <button
                        data-dismiss="modal"
                        disabled={this.state.enabled}
                        type="button"
                        class="btn btn-primary buttonAppear"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table class="table table-hover" style={{ textAlign: "center" }}>
            <thead
              style={{
                background: theme.themeColors.primaryColor,
                color: "white",
              }}
            >
              <tr>
                <th scope="col">#</th>
                <th scope="col">Student Name</th>
                <th scope="col"> Leave Type</th>
                <th scope="col">Date From</th>
                <th scope="col">Date To</th>

                <th scope="col">Account Id</th>
                <th scope="col">Account Type</th>
                <th scope="col"> Status </th>

                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>{renderTodos}</tbody>
          </table>
        </div>
      </div>
    );

    const SearchContent = (
      <div>
        {this.state.errorMsg ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Please Select all fields!
          </p>
        ) : (
          ""
        )}

        <form
          onSubmit={this.onSubmit}
          class="form-inline"
          style={{ justifyContent: "center" }}
        >
          <div class="row">
            <div class="col-md-3">
              <SelectionInput
                feildName={"classId"}
                selectName={"Class"}
                onHandleChange={(e) => this.onHandleClassChange(e)}
                errors={errors}
                optionsArrys={classData}
                selectedText={"Class Name"}
                stateData={this.state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={"className"}
                propertyId={"classId"}
                isValidte={false}
                // editUse={'staffEdit'}
              />
            </div>
            <div class="col-md-3">
              <SelectionInput
                feildName={"sectionId"}
                selectName={"Section"}
                onHandleChange={(e) => this.onHandleSectionChange(e)}
                errors={errors}
                optionsArrys={sectionData}
                selectedText={"Section"}
                stateData={this.state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={"section"}
                propertyId={"sectionId"}
                isValidte={false}
                // editUse={'staffEdit'}
              />
            </div>
            <div class="col-md-3">
              <SelectionInput
                feildName={"subjectId"}
                selectName={"Subject"}
                onHandleChange={(e) => this.onHandleSelectChange(e)}
                errors={errors}
                optionsArrys={subjectData}
                selectedText={"Subject"}
                stateData={this.state}
                // iconClassName={'fas fa-building'}
                optionType="dynamicWithPropIdAndName"
                property={"subjectName"}
                propertyId={"subjectId"}
                isValidte={false}
                // editUse={'staffEdit'}
              />
            </div>
            <div class="col-md-3" style={{ marginTop: 26 }}>
              <button
                type="submit"
                class="btn"
                style={{
                  backgroundColor: theme.themeColors.primaryColor,
                  color: "white",
                }}
              >
                {" "}
                Search{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
    );

    return (
      <div>
        <div className="page-header">
          <SnackBar backColor={this.state.snackColor} msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <h2>Leave&nbsp;Details</h2>
              </div>
            </div>
            <nav aria-label="Page navigation example">
              <ul
                style={{ color: theme.themeColors.primaryColor }}
                class="pagination"
              >
                <li
                  class={`page-item ${
                    this.state.disableBPage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextBack}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disableBPage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-backward"></i>
                    )}
                  </a>
                </li>
                {renderPageNumbers}
                <li
                  class={`page-item ${
                    this.state.disablepage ? "disabled" : ""
                  }`}
                >
                  <a
                    onClick={this.handleClickNextFrwd}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disablepage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-forward"></i>
                    )}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {this.state.isLoadingInitialData ? Loader : SearchContent}

        <br />
        {this.state.isLoading ? Loader : MainContent}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewLeave);

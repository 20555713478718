
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import Header from '../../Assets/images/3 headers-03.png';
import moment from 'moment'
import { Pie } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import GraphLayoutForAcademicCalendar from '../../components/Graphs/GraphGeneralLayout/GraphLayoutForAcademicCalendar'

import ThemeContext from "../../context/themeContext/ThemeContext";


class PrintAcademicCalendar extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            colorList: {
                red: 'FF4040',
                yellow: 'FFBB00',
                blue: '00FFF0',
                green: '0FFF00'
            },
            msg: '',
            GraphData: {
				datasets: [
					{
						// barPercentage: 0.4,
						// minBarLength: 2,
						label: 'Average Off Days',
						data: [
                            this.props.location.state.calendarData.calendarData.filter(d => d.hexColor == 'FF4040').length,     
                            this.props.location.state.calendarData.calendarData.filter(d => d.hexColor == 'FFBB00').length,    
                            this.props.location.state.calendarData.calendarData.filter(d => d.hexColor == '00FFF0').length,   
                            this.props.location.state.calendarData.calendarData.filter(d => d.hexColor == '0FFF00').length,  
                        ],
						backgroundColor: [
							'#FF4040',
							'#FFBB00',
							'#00FFF0',
							'#0FFF00',
						],
						borderColor: [
							'#abe2b9'
						]
					}
				],
				labels: ['Exams', 'Holidays', 'Parent Meetings', 'School Events'],
				options: {
					showLines: true
				}
			},
			lineGraph: true,
			pieGraph: false,
			barGraph: false,

            daysList: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday'],
            monthList: [],
            dateList: [],
            totalNumofDays: [],
            totalNumofOffDays: [],


            fromMonth: this.props.location.state.fromMonth,
            toMonth: this.props.location.state.toMonth,
            fromYear: this.props.location.state.fromYear,
            toYear: this.props.location.state.toYear,
            calendarData: this.props.location.state.calendarData,

        }

    }

    static contextType = ThemeContext

    componentDidMount(){

        
        this.generateMonthList()
    }

    generateMonthList = () => {
        const { fromMonth, fromYear, toMonth, toYear, monthList, calendarData } = this.state;
        console.log(fromMonth, fromYear, toMonth, toYear, calendarData);
        
        let from = moment(`${fromMonth}-${fromYear}`, "MMMM-YYYY").format("MM-YYYY")
        let to = moment(`${toMonth}-${toYear}`, "MMMM-YYYY").format("MM-YYYY")

        while (moment(from, "MM-YYYY")._d <= moment(to, "MM-YYYY")._d) {
            var fill = calendarData.calendarData.filter((data, index) => {
                return moment(from, "MM-YYYY").format("MM-YYYY") == moment(data.date, 'MM/DD/YYYY').format("MM-YYYY")
            })
            monthList.push({
                month: moment(from, "MM-YYYY").format("MMMM"),
                year: moment(from, "MM-YYYY").format("YYYY"),
                data: fill
            })
            if(moment(from, "MM-YYYY")._d <= moment(to, "MM-YYYY")._d){
                console.log("Loading Done")
                this.setState({
                    isLoading: false
                })
            }
            from = moment(from, "MM-YYYY").add(1, 'months').format("MM-YYYY")

        }


    }

    
    generateDate = (dateObject, data) => {
        
        let blanks = []
        let daysInMonth = []
        let firstDay = moment(dateObject).startOf("month").format("d"); 
        let numOfDaysInMonth = moment(dateObject).daysInMonth()
        let monthYear = moment(dateObject).format("MM-YYYY"); 
        let rows = [];
        let cells = [];
        let days = 0;
        let offDays= 0;

        for (let i = 0; i < firstDay; i++) {
            blanks.push(
              <td className="borderSpace" style={{ padding: 1, paddingLeft: 5 }}>{""}</td>
            );
          }
        for (let d = 1; d <= numOfDaysInMonth; d++) {
            
            
            var fill = data.filter((val, item) => {
                return moment(val.date, "MM/DD/YYYY").format("D") == d
            })
            
            let checker = moment(`${d}-${monthYear}`, "D-MM-YYYY").format("dddd")
            if(!(checker == "Friday" || checker == "Saturday")){
                console.log(checker);
                
                    days= days + 1
            }
            
            
            daysInMonth.push(
              fill.length > 0 ? <td className="borderSpace" style={{ padding: 1, paddingLeft: 5, color: `#${fill[0].hexColor}`  }}>{`${d} - ${fill[0].title}`}</td> : 
              <td className="borderSpace" style={{ padding: 1, paddingLeft: 5, color: `${(checker == "Friday" || checker == "Saturday") && "LightGray"}` }}>{d}</td>
            );
          }
        
        var totalSlots = [...blanks, ...daysInMonth];
          
        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
              cells.push(row); // if index not equal 7 that means not go to next week
            } else {
              rows.push(cells); // when reach next week we contain all td in last week to rows 
              cells = []; // empty container 
              cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
              rows.push(cells);
            }
          });
          this.state.totalNumofDays.push(days)
          console.log(this.state.totalNumofDays);
          
          return rows.map((d, i) => {
            return <tr className="borderSpace" style={{ padding: 1, paddingLeft: 5}}>{d}</tr>;
          });
        
    }

    onGraphChange = e => {
        this.state.totalNumofDays.length = 0;
        
		if (e.target.value == 'Line') {
			this.setState({
				lineGraph: true,
				pieGraph: false,
				barGraph: false
			});
		} else if (e.target.value == 'Pie') {
			this.setState({
				lineGraph: false,
				pieGraph: true,
				barGraph: false
			});
		} else if (e.target.value == 'Bar') {
			this.setState({
				lineGraph: false,
				pieGraph: false,
				barGraph: true
			});
		}
	};


    
    // PrintContent = el => {
    //     var restorepage = document.body.innerHTML;
    //     var Printcontent = document.getElementById(el).innerHTML;
    //     document.body.innerHTML = Printcontent;
        
    //     setTimeout(() => {
    //         window.print();
    //         window.location.reload();
    //     }, 700)
    //     document.body.innerHTML = restorepage;
    // };

    
	PrintContent = () => {
		setTimeout(() => {
			window.print();
			window.location.reload();
		}, 700);
	};


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    render(){

        const {isLoading, editToggle, msg, 
            floorName, floorId, roomName, roomId, categoryName, categoryId, staffName, staffId, accountId, accountType, date, status, 
            daysList, dateList, monthList, totalNumofDays, calendarData,
            lineGraph, pieGraph, barGraph
        } = this.state;

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderMonthlyTable = !isLoading && monthList.map((item, index) => {
            
            return (
                <div className="table-responsive table-bordered"  style={{overflow: 'hidden'}}>
                    <div className="row" >
                        <div className="col-md-12 text-center" style={{ background: this.context.themeColors.primaryColor, color: 'white', fontSize: 20 }}>
                            {item.month} - {item.year}
                        </div>
                    </div>
					<table className="table">
						<thead style={{ backgroundColor: 'white', color: this.context.themeColors.primaryColor }}>
							<tr>
								{
                                    moment.weekdays().map((data, index) => {
                                        return <th scope="col" style={{ padding: 1, paddingLeft: 5 }}>
                                            {data}
                                        </th>
                                    })
                                }
							</tr>
						</thead>
                            <tbody>{this.generateDate(moment(`${item.month}-${item.year}`, "MMMM-YYYY"), item.data)}</tbody>
					</table>
                    
				</div>
            );
        })

        return (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div id="printPage" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row" >
								<div
									style={{
                                        flex: 1,
                                        width: '100%',
                                        height: '100%'
									}}
								>
									<img src={Header} width={'100%'} height={'100%'} />
								</div>
						</div>

                    <div class="row" style={{marginTop: '2%'}} >
							<div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h3>Academic Calender</h3>
								
							</div>
						</div>
                        <div class="my-2 py-2 px-2" style={{
                        border: `3px solid ${this.context.themeColors.primaryColor}`,
                    }} >
                        <div class="row">
                            <div class="col-md-4" >
                                    <span>Total Number of Days: <strong style={{color: this.context.themeColors.primaryColor}} >{this.state.totalNumofDays.reduce((a, b) => a + b, 0)}</strong></span>     
                            </div>
                            <div class="col-md-4"  >
                                    <span>Total Num of Events: <strong style={{color: this.context.themeColors.primaryColor}} >{calendarData.calendarData.length}</strong></span>     
                            </div>
                            <div class="col-md-4"  >
                                    <span>Exam Days: <strong style={{color: this.context.themeColors.primaryColor}} >{calendarData.calendarData.filter(d => d.hexColor == 'FF4040').length}</strong></span>     
                            </div>
                            <div class="col-md-4"  >
                                    <span>Holidays: <strong style={{color: this.context.themeColors.primaryColor}} >{calendarData.calendarData.filter(d => d.hexColor == 'FFBB00').length}</strong></span>     
                            </div>
                            <div class="col-md-4"  >
                                    <span>Parent Meetings: <strong style={{color: this.context.themeColors.primaryColor}} >{calendarData.calendarData.filter(d => d.hexColor == '00FFF0').length}</strong></span>     
                            </div>
                            <div class="col-md-4"  >
                                    <span>School Events: <strong style={{color: this.context.themeColors.primaryColor}} >{calendarData.calendarData.filter(d => d.hexColor == '0FFF00').length}</strong></span>     
                            </div>
                        </div>
                        
                       
                    </div>
                    <div class="my-2 py-2 px-2" style={{
                        border: `3px solid ${this.context.themeColors.primaryColor}`,
                    }} >
                        <div class="row">
                            <div class="col-md-3" >
                                    <div class="d-inline-block" style={{height: 10, width: 10, borderRadius: 10, backgroundColor: "#FF4040"}} ></div><span><strong style={{color: this.context.themeColors.primaryColor}} >  Exams</strong></span>     
                            </div>
                            
                            <div class="col-md-3" >
                                    <div class="d-inline-block" style={{height: 10, width: 10, borderRadius: 10, backgroundColor: "#FFBB00"}} ></div><span><strong style={{color: this.context.themeColors.primaryColor}} >  Holidays</strong></span>     
                            </div>
                            
                            <div class="col-md-3" >
                                    <div class="d-inline-block" style={{height: 10, width: 10, borderRadius: 10, backgroundColor: "#00FFF0"}} ></div><span><strong style={{color: this.context.themeColors.primaryColor}} >  Parent Meetings</strong></span>     
                            </div>
                            
                            <div class="col-md-3" >
                                    <div class="d-inline-block" style={{height: 10, width: 10, borderRadius: 10, backgroundColor: "#0FFF00"}} ></div><span><strong style={{color: this.context.themeColors.primaryColor}} >  School Events</strong></span>     
                            </div>

                        </div>
                        
                       
                    </div>
                    {isLoading ? Loader :  renderMonthlyTable}
                    
                </div>
                <div class="dataVisuals">
							<br />
							<GraphLayoutForAcademicCalendar
                                scaleValue={this.state.totalNumofDays.reduce((a, b) => a + b, 0)}
                                scaleName="Total School Days"
								titleName={'Analysis'}
								titleIcon={'fas fa-chart-bar'}
								graphList={true}
								onGraphChange={this.onGraphChange}
								children={() => (
									<div class="row">
										<div class="col-md-11">
											{lineGraph ? (
												<Line
													height={90}
													width={200}
													data={this.state.GraphData}
												/>
											) : pieGraph ? (
												<Pie
													height={90}
													width={200}
													data={this.state.GraphData}
												/>
											) : barGraph ? (
												<Bar height={100} data={this.state.GraphData} />
											) : (
												''
											)}
										</div>
									</div>
								)}
							/>
						</div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-10">
                    
                    </div>
                    <div class="col-md-2">
                    <button class="btn btn-primary buttonAppear  no-print" onClick={this.PrintContent}>
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintAcademicCalendar);

import React, { Component } from 'react';
import DateandTime from '../Transport/DateandTime';
import * as sharedActions from '../../Actions/sharedActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AddMultipleItems from './AddMultipleItems';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment'

import SnackBar from "../../components/SnackBar/SnackBar";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddCyclicCleaness extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: false,
			successMsg: false,
			validMsg: false,
			msg: '',
			floorData: null,
			floorId: '',
			roomId: '',
			type: '',
			cetegory: [],
			categoryId: '',
			CyclicCleaness: [
				{
					categoryId: '',
					checkBox: 'No',
					Comment: '',
				}
			],
			addButton: false
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.select !== nextProps.selectData) {
			return {
				select: nextProps.selectData
			};
		}
		return null;
	}

	onClickDeleteItem = arrName => {
		let getStateArr = this.state[`${arrName}`];
		let getDeleteLength = getStateArr.length - 1;
		if (getDeleteLength > 0) {
			let getNewDetails = getStateArr.filter(
				(item, index) => index !== getDeleteLength
			);
			console.log(getNewDetails);
			this.setState({
				[arrName]: [...getNewDetails]
			});
		}
	};

	onClickAddItem = (arrName, obj) => {
		let getStateArr = this.state[`${arrName}`];
		getStateArr = [...getStateArr, obj];
		console.log(arrName);
		this.setState({
			[arrName]: getStateArr,
			addButton: false
		});
	};
	updateSpecialArrObjOnChange = (e, index, arrName) => {
		const changeArrOfObj = this.state[`${arrName}`];
		changeArrOfObj[index][e.target.name] = e.target.value;
		console.log(e.target.name, e.target.value);

		if (!isEmpty(changeArrOfObj[index].floorId) && !isEmpty(changeArrOfObj[index].roomId) && !isEmpty(changeArrOfObj[index].categoryId)) {
			let parsedDate = moment(new Date).format('MM/DD/YYYY')
			console.log(parsedDate)
			this.props.sharedActions.getDataWithoutDispatch(
				`/api/CyclicValidation/?floorId=${changeArrOfObj[index].floorId}&roomId=${changeArrOfObj[index].roomId}&categoryId=${changeArrOfObj[index].categoryId}&date=${parsedDate}`
			).then(success => {
				console.log('Validation', success);
				if (success == 'You may Proceed') {
					let val = changeArrOfObj.filter((item, innerIndex) => {
						console.log(item, index, innerIndex);
						console.log((
							changeArrOfObj[index].floorId == item.floorId &&
							changeArrOfObj[index].roomId == item.roomId &&
							changeArrOfObj[index].categoryId == item.categoryId
						));

						if (!(index == innerIndex) &&
							(changeArrOfObj[index].floorId == item.floorId &&
								changeArrOfObj[index].roomId == item.roomId &&
								changeArrOfObj[index].categoryId == item.categoryId)
						) {
							console.log("In");
							return true
						}

					})
					if (val.length > 0) {

						this.setState({
							addButton: false,
							msg: 'Data Already Exists!',
						})
						this.props.snackbar();

					} else {
						this.setState({
							addButton: true,
						})
					}

				}
			}).catch(error => {
				console.log(error);

				this.setState({
					addButton: false,
					msg: 'Data Already Exists!',
				})
				this.props.snackbar();
			})
		}
		this.setState({
			[arrName]: changeArrOfObj
		});
	};

	updateSpecialArrObjOnCheck = (e, index, arrName) => {
		const changeArrOfObj = this.state[`${arrName}`];
		changeArrOfObj[index][e.target.name] = e.target.checked ? 'Yes' : 'No';
		this.setState({
			[arrName]: changeArrOfObj
		});
	};

	getSimple = (type, state) => {
		this.props.sharedActions.getDataWithoutDispatch(
			`/api/${type}`
		).then(success => {
			this.setState({
				[state]: success,
				isLoading: false
			})


		}).catch(error => {
			this.setState({
				msg: 'Error Occured!!',
				isLoading: false
			})
			console.error('Error occured', error);
		})
	}


	onHandleTextChange = e => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onHandleCheckChange = e => {
		console.log([e.target.name], e.target.checked, e.target);

		this.setState({
			[e.target.name]: e.target.checked
		})
	}

	validateFunc = e => {
		e.preventDefault();

		const { CyclicCleaness } = this.state;
		CyclicCleaness.map((arr, index) => {

			let res = CyclicCleaness.filter((data, innerIndex) => {
				console.log(data.categoryId, arr.categoryId);
				if (index != innerIndex) {
					return data.categoryId == arr.categoryId
				}
			})

			if (res.length > 0) {
				this.setState({
					msg: 'Please Choose Different Category!',
				})
				this.props.snackbar();
				return false
			}
			for (var key in arr) {

				if (arr[key] === "") {
					console.log(key + " is blank");
					this.setState({
						msg: 'Please fill the form Respectively!',
					})
					this.props.snackbar();
					return false
				}
			}


			if (CyclicCleaness.length === index + 1) {
				this.onHandleSubmit()
			}
		})
	}

	onHandleSubmit = () => {

		const { CyclicCleaness, floorId, roomId, type } = this.state;
		let create = []
		let parser = CyclicCleaness.map(val => {
			create.push({
				...val,
				type,
				floorId,
				roomId,
				accountId: this.props.userData.unique_name,
				accountType: this.props.userData.role,
			})
		})
		let data = {
			"CyclicCleaness": create
		}

		console.log(data);

		this.props.sharedActions.addRecordWithoutDispatch(
			`/api/CyclicCleaness`,
			data
		).then(success => {
			console.log(success);

			this.setState({
				msg: 'Cyclic Cleaness Added!',
				isLoading: false,
				floorId: '',
				floorName: '',
				type: '',
				CyclicCleaness: [{
					categoryId: '',
					checkBox: 'No',
					Comment: '',
				}]
			})
			this.props.snackbar();
		}).catch(error => {
			this.setState({
				msg: error.response ? error.response.data.Message : 'Error Occured!!',
				isLoading: false
			})
			this.props.snackbar();
			console.error('Error occured', error);

		})
	}
	componentDidMount() {
		// this.getSimple('Dropdown/FloorRoom', 'floorData')

	}
	searchByType = e => {
		this.getSimple2nd(`Dropdown/FloorRoom/?type=${e.target.value}`, 'floorData')
		this.setState({
			type: e.target.value
		})
	}
	getSimple2nd = (type, state2) => {
		this.props.sharedActions.getDataWithoutDispatch(
			`/api/${type}`
		).then(success => {
			debugger;
			this.setState({
				// [state]: success.category,
				[state2]: success,
				isLoading: false
			})


		}).catch(error => {
			this.setState({
				msg: 'Error Occured!!',
				isLoading: false
			})
			console.error('Error occured', error);
		})
	}
	componentDidUpdate() {
		if (this.state.successMsg || this.state.errorMsg) {
			setTimeout(() => {
				this.setState({
					successMsg: false,
					errorMsg: false
				});
			}, 3000);
			if (this.state.validMsg) {
				if (this.state.addButton) {
					this.setState({
						validMsg: false
					})
				}
			}
		}

	}
	Message = (msg, color, fontSize, flag) => {
		if (flag) {
			return (
				<p style={{ fontSize: fontSize }} class={`text-${color}`}>
					{msg}
				</p>
			);
		}
	};
	render() {
		const { floorData, floorId, roomId, addButton, isLoading } = this.state;
		return (
			<div className="container">
				<section className="mb-4">
					<ThemeContext.Consumer>
						{(context) => (
							<h2
								style={{ background: context.themeColors.primaryColor }}
								className="h1-responsive font-weight-bold text-center my-4 generalHead">
								Add Cyclic Cleanliness
							</h2>
						)}
					</ThemeContext.Consumer>

					<SnackBar msg={this.state.msg} backColor={this.state.backColor} />

					<p class="text-center w-responsive mx-auto mb-5" />

					<div className="row" style={{
						justifyContent: 'center',
						textAlign: 'center'
					}}>

						<ThemeContext.Consumer>
							{(context) => (
								<div className="col-md-9 mb-md-0 mb-5" style={{
									border: 'solid',
									borderColor: context.themeColors.primaryColor,
									padding: 40,
									borderRadius: 15,
									width: 'auto'

								}}>
									<LoaderWrapper isLoading={this.state.isLoading}>

										<form id="contact-form" name="contact-form" onSubmit={this.validateFunc} >
											<div class="row">
												<div class="col-md-12">
													<div class="md-form mb-0">
														<DateandTime />
													</div>
												</div>
											</div>
											<br />
											<div class="row">
												<div class="col-md-6" style={{ marginTop: 20 }}>
													<div class="md-form mb-0">
														<label for="name" class="">
															Admin Name
											</label>
														<input
															value={this.props.userData.unique_name}
															readOnly={true}
															type="text"
															name="AdminName"
															class="form-control"
															onChange={this.onHandleTextChange}
														/>
													</div>
												</div>

												<div class="col-md-6" style={{ marginTop: 20 }}>
													<div class="md-form mb-0">
														<label for="name" class="">
															Admin Type
											</label>
														<input
															value={this.props.userData.role}
															readOnly={true}
															type="text"
															name="AdminType"
															class="form-control"
															onChange={this.onHandleTextChange}
														/>
													</div>
												</div>
											</div>
											<div class="row" >
												<div className="col-md-6">
													<label for="categoryName">
														Type
                                        </label>
													<select class="custom-select"
														value={this.state.type}
														name="type"
														onChange={this.searchByType}

													>
														<option value="">Please Select Type</option>
														<option value="Daily">Daily</option>
														<option value="Weekly">Weekly</option>
														<option value="Monthly">Monthly</option>
														<option value="Quarterly">Quarterly</option>
														<option value="HalfYearly">HalfYearly</option>
														<option value="Annually">Annually</option>
													</select>
												</div>
												<div className="col-md-6">
													<div className="md-form mb-0" >
														<label for="floorId">
															Floor Name
                                            </label>
														<select class="custom-select"
															value={floorId}
															name="floorId"
															onChange={this.onHandleTextChange}
															disabled={!floorData}
														>
															<option value="">Please Select Floor</option>
															{floorData && floorData.floors.map((value, index) => {


																return <option value={value.id}>{value.floorName}</option>
															})}
														</select>
													</div>
												</div>

												<div className="col-md-6">
													<div className="md-form mb-0" >
														<label for="roomId">
															Room Name
                                            </label>
														<select class="custom-select"
															value={roomId}
															name="roomId"
															onChange={this.onHandleTextChange}
															disabled={!floorId}
														>
															<option value="-">Please Select Room</option>
															{floorData && floorData.rooms.map((value, index) => {

																return floorId == value.floorId &&
																	<option value={value.id}>{value.roomName}</option>
															})}
														</select>
													</div>
												</div>
											</div>

											<div class="row">
												<div class="col-md-12" style={{ marginTop: 20 }}>
													<div class="md-form mb-0">
														{floorData && <AddMultipleItems
															onHandleTextChange={this.onHandleTextChange}
															onHandleCheckChange={this.onHandleCheckChange}
															stateData={this.state}
															updateSpecialArrObjOnChange={this.updateSpecialArrObjOnChange}
															updateSpecialArrObjOnCheck={this.updateSpecialArrObjOnCheck}
															onClickAddItem={this.onClickAddItem}
															onClickDeleteItem={this.onClickDeleteItem}
															addButton={addButton}
															primaryColor={context.themeColors.primaryColor}
														/>}
													</div>
												</div>
											</div>
											<div className="text-center text-md-center">
												<button type="submit" className="btns" style={{
													background: context.themeColors.primaryColor,
												}}>
													Add
										<span
														style={{
															marginBottom: 5
														}}
														className={
															isLoading && 'spinner-border spinner-border-sm'
														}
													></span>
												</button>
											</div>
										</form>
									</LoaderWrapper>
									<div class="status" />
								</div>
							)}
						</ThemeContext.Consumer>
					</div>
				</section>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCyclicCleaness);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Transport.css";
import * as vehicleActions from "../../Actions/Vehicle";
import * as sharedActions from "../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateandTime from "../Transport/DateandTime";
import DatePicker from "react-date-picker";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleNo: "",
      transportType: "",
      capacity: "",
      vehicleDateOfReg: "",
      vehicleDateOfRegExpiry: "",
      startingServiceKm: "",
      midServiceKm: "",
      endingServiceKm: "",
      errorMsg: false,
      routeName: "",
      routeId: "",
      msg: false,
      select: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.select !== nextProps.selectData) {
      return {
        select: nextProps.selectData,
      };
    }
    return null;
  }

  handleChange = (date, state) => {
    this.setState({
      [state]: date,
    });
    console.log(date);
  };
  handleChange2 = (date, state) => {
    this.setState({
      [state]: date,
    });
    console.log(date);
  };

  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      routeId: result.id,
    });
  };

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleTextChange2 = (e) => {
    console.log(e.target.value);
    let x = Number(e.target.value) + 8000;
    let y = x + 2000;
    this.setState({
      startingServiceKm: Number(e.target.value),
      midServiceKm: x,
      endingServiceKm: y,
    });
  };
  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      capacity,
      transportType,
      vehicleNo,
      routeId,
      vehicleDateOfReg,
      vehicleDateOfRegExpiry,
      startingServiceKm,
      midServiceKm,
      endingServiceKm,
    } = this.state;
    if (
      Object.is(vehicleNo, "") ||
      Object.is(transportType, "") ||
      Object.is(capacity, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      const data = {
        capacity,
        transportType,
        vehicleNo,
        routeId,
        vehicleDateOfReg,
        vehicleDateOfRegExpiry,
        startingServiceKm,
        midServiceKm,
        endingServiceKm,
        accountType: this.props.role,
        accountId: this.props.name,
      };
      this.props.sharedActions
        .addRecord("/api/Vehicle", data, this.props.vehicleActions.addVehicle)
        .then((success) => {
          this.setState({
            errorMsg: false,
            transportType: "",
            vehicleNo: "",
            msg: true,
          });
        })
        .catch((error) => {});
      console.log(
        this.state.vehicleNo,
        this.state.transportType,
        this.state.capacity
      );
    }
    console.log(this.state);
  };
  componentDidMount() {
    this.props.sharedActions
      .gAllData("/api/Route", this.props.vehicleActions.getRouteForVehicle)
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
          <ThemeContext.Consumer>
            {(context) => (
      <div>
        <section class="mb-4">
          <h2 class="h1-responsive font-weight-bold text-center my-4 generalHead"
            style={{ background: context.themeColors.primaryColor }}>
            Add Vehicle
          </h2>
          <p class="text-center w-responsive mx-auto mb-2" align="center" />
          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  {this.state.errorMsg ? (
                    <p style={{ color: "red" }}>
                      Please fill the form properly!
                    </p>
                  ) : (
                    ""
                  )}
                  {this.state.msg ? (
                    <p style={{ color: "green" }}>
                      Vehicle Added Successfully!
                    </p>
                  ) : (
                    ""
                  )}

                  <form id="contact-form" name="contact-form">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <DateandTime />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            name="AdminName"
                            value={this.props.name}
                            readOnly={true}
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Type
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            value={this.props.role}
                            readOnly={true}
                            type="text"
                            name="AdminType"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Route Name
                          </label>
                          <select
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            onChange={this.onSelectChange}
                            class="custom-select"
                            name="routeId"
                          >
                            <option selected={this.state.msg ? true : false}>
                              Choose Route...
                            </option>
                            {this.state.select.map((item, index) => (
                              <option
                                key={index}
                                value={`${JSON.stringify(item)}`}
                              >
                                {item.dropOff}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Vehicle Registration
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Enter Vehicle Registration"
                            type="text"
                            id="vehicleNo"
                            onChange={this.onHandleTextChange}
                            value={this.state.vehicleNo}
                            name="vehicleNo"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Transportation Type
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder={"Enter Transport Type"}
                            onChange={this.onHandleTextChange}
                            type="text"
                            id="TransportType"
                            name="transportType"
                            value={this.state.TransportType}
                            className="form-control "
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Capacity
                          </label>
                          <input
                            placeholder="Enter Capacity"
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            id="Capacity"
                            name="capacity"
                            value={this.state.Capacity}
                            className="form-control "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Starting Service KM
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Enter Starting Service KM"
                            type="text"
                            id="startingServiceKm"
                            name="startingServiceKm"
                            value={this.state.startingServiceKm}
                            className="form-control "
                            onChange={this.onHandleTextChange2}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Mid Service KM
                          </label>
                          <input
                            placeholder={"Enter Mid Service Km"}
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            id="midServiceKm"
                            name="midServiceKm"
                            value={this.state.midServiceKm}
                            className="form-control "
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Ending Service KM
                          </label>
                          <input
                            placeholder={"Enter End Service Km"}
                            type="text"
                            id="endingServiceKm"
                            name="endingServiceKm"
                            value={this.state.endingServiceKm}
                            className="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Date of Registration
                          </label>
                          <br />
                          <DatePicker
                            selected={this.state.licenseIssueDate}
                            onChange={(e) =>
                              this.handleChange(e, "vehicleDateOfReg")
                            }
                            value={this.state.vehicleDateOfReg}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Expiry Date of Registration
                          </label>
                          <br />
                          <DatePicker
                            selected={this.state.licenseExpirayDate}
                            onChange={(e) =>
                              this.handleChange2(e, "vehicleDateOfRegExpiry")
                            }
                            value={this.state.vehicleDateOfRegExpiry}
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                  </form>
                  <br />
                  <br />
                  <div class="text-center text-md-right">
                    <button
                      onClick={this.onHandleSubmit}
                      class="buttonHover2"
                      style={{
                        background: context.themeColors.primaryColor,
                      }}
                    >
                      Add
                    </button>
                  </div>
                  <div class="status" />
                </div>
          </div>
        </section>
      </div>
              )}
            </ThemeContext.Consumer>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    vehicleActions: bindActionCreators(vehicleActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    auth: state.setUserReducer.auth,
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
    accountId: state.setUserReducer.users.accountId,
    selectData: state.setVehicleReducer.select,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddVehicle);

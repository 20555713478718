import React from "react";
import ViewRendering from "../../../components/ViewRendering";
import { renderingStuff, images } from "../../../utils";
import NewViewRendering from "../../../components/ViewRendering/NewViewRendering";

function AcademicTimeTable() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <img
              src={images.imgAcademicTimeTable}
              class="img-fluid"
              alt="Responsive image"
              height="50"
              width="50"
            />
            <h4 className="mt-2" style={{ letterSpacing: 10 }}>
              Time Table Dashboard
            </h4>
          </div>
        </div>
      </div>
      <NewViewRendering
        centered={true}
        centerWidth={"60%"}
        mainHead={true}
        backColor={true}
        mainHeading={images.imgTThead}
        data={renderingStuff.timeTable_module_links}
      />
    </div>
  );
}

export default AcademicTimeTable;

import React from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";

export default function StudentList(props) {
  const {
    updateSpecialArrObjOnChange,
    stateData,
    addFunction,
    viewFunction,
  } = props;
  return (
    <div>
      <div class="animated fadeInDown">
        <h5 class="h5-responsive font-weight-bold text-left my-4">
          Select Student:
        </h5>

        <div style={{ width: "100%" }}>
          <div class="table-responsive">
            <ThemeContext.Consumer>
              {(context) => (
                <table class="table table-hover text-left">
                  <thead
                    style={{
                      background: context.themeColors.primaryColor,
                      color: "white",
                    }}
                  >
                    <tr>
                      <th class="text-center">#</th>
                      <th>Student Name</th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stateData &&
                      stateData.ClassDiscipline.map((item, index) => (
                        <tr key={index}>
                          <th class="text-center">{index + 1}</th>
                          <th>{item.studentName}</th>
                          <th class="text-center">
                            <a
                              href="#"
                              data-toggle="tooltip"
                              title="Add Discipline"
                            >
                              <button
                                onClick={(e) =>
                                  addFunction(
                                    e,
                                    item.studentId,
                                    item.studentName
                                  )
                                }
                                class="buttonHover"
                              >
                                <i
                                  style={{ color: "#9db3e3" }}
                                  class="fas fa-plus-circle"
                                >
                                  {" "}
                                </i>
                              </button>
                            </a>
                            <a
                              href="#"
                              data-toggle="tooltip"
                              title="View Discipline"
                            >
                              <button
                                onClick={(e) =>
                                  viewFunction(
                                    e,
                                    item.studentId,
                                    item.studentName
                                  )
                                }
                                class="buttonHover"
                              >
                                <i class="fas fa-edit"> </i>
                              </button>
                            </a>
                          </th>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </ThemeContext.Consumer>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import FormLayoutWrapper from "../../../components/HOC/FormLayoutWrapper";
import SelectionInput from "../../../components/SelectionInput/SelectionInput";
import RowWrapper from "../../../components/style/RowWrapper";
import CustomLabel from "../../../components/Label/CustomLabel";
import Button from "../../../components/Button";
import useHttp from "../../../hooks/http";
import LoaderWrapper from "../../../components/HOC/LoaderWrapper";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import SnackBar from "../../../components/SnackBar/SnackBar";
import { connect } from "react-redux";
import validateInput, {
  CHECK_PRIMARY_INFO,
  CHECK_CUT_OFF,
  CHECK_SUBSTITUTION,
} from "../../../validator/inputValidator";
import {
  dataFilteration,
  addDataMiddleware,
  isEmptyFieldInArrOBJ,
  dateConversion,
} from "../../../utils/general/index";
import TextInput from "../../../components/TextInput/TextInput";
import DatePicker from "react-date-picker";
import TimePicker from "../../../components/TimePicker";
import { useContext } from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";
const format = "h:mm a";
function SubstitutionPeriority(props) {
  const themeContext = useContext(ThemeContext);
  const [errors, setError] = useState({});
  const [periorityName, setPeriorityName] = useState("");
  const [periorityNumber, setPeriorityNumber] = useState("");
  const [snackbarMessage, setMsg] = useState("");
  const [customLoader, setCustomLoader] = useState(false);

  const isValid = () => {
    const { errors, isValid } = validateInput(
      { periorityName, periorityNumber },
      CHECK_SUBSTITUTION
    );
    debugger;
    if (!isValid) {
      debugger;
      setError(errors);
    } else {
      debugger;
      setError(errors);
    }
    debugger;
    return isValid;
  };

  debugger;

  const onSubmit = () => {
    console.log(periorityName);
    console.log(periorityNumber);
    debugger;
    if (isValid()) {
      setCustomLoader(true);
      props.sharedAction
        .simpleAddRequest(`/api/SubstitutionPriority`, {
          priorityName: periorityName,
          priority: periorityNumber,
          accountId: props.name,
          accountType: props.role,
        })
        .then((success) => {
          debugger;
          setCustomLoader(false);
          setPeriorityNumber("");
          setPeriorityName("");
          setMsg("Successfully Submitted");
          props.snackbar();
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 400) {
            setCustomLoader(false);
            setMsg(error.response.data.Message);

            props.snackbar();
          }
        });
    }
  };
  const onChangePeriorityName = (e) => {
    if (e.target.name !== "Type") setPeriorityName(e.target.value);
  };
  const onChangePeriorityNumber = (e) => {
    debugger;
    if (e.target.name !== "Select") {
      debugger;
      setPeriorityNumber(e.target.value);
    }
  };

  return (
    <div>
      <SnackBar backColor={"#000"} msg={snackbarMessage} />

      <FormLayoutWrapper
        styleHead={true}
        formName={"Substitution Periority"}
        borderColor={themeContext.themeColors.primaryColor}
        borderRadius={5}
        errorFlag={false}
        flagName={"error"}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <p>Periority Name: </p>
            <select
              value={periorityName}
              onChange={onChangePeriorityName}
              className={"form-control"}
            >
              <option selected={true} value="Select">
                Select
              </option>
              <option value="Department"> Department </option>
              <option value="Grade"> Grade </option>
              <option value="LessLesson"> Less Lesson </option>
              <option value="Free-Available"> Free-Available</option>
            </select>
            {errors && errors["periorityName"] ? (
              <p style={{ color: "red", fontSize: 10 }}>Field Required</p>
            ) : (
              ""
            )}
          </div>
          <div>
            <p>Periority #</p>

            <select
              value={periorityNumber}
              onChange={onChangePeriorityNumber}
              className={"form-control"}
            >
              <option selected={true} value="Select">
                Select
              </option>
              <option value="1st"> 1st </option>
              <option value="2nd"> 2nd </option>
              <option value="3rd"> 3rd </option>
              <option value="4th"> 4th </option>
            </select>
            {errors && errors["periorityNumber"] ? (
              <p style={{ color: "red", fontSize: 10 }}>Field Required</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <RowWrapper wrap={"wrap"}>
          <TextInput
            // marginTop={20}
            feildName={"accountId"}
            // inputLabelName={'Percentage Basic'}
            errors={{}}
            stateData={{ accountId: props.role, disabledField: true }}
            // iconClassName={'fas fa-percentage'}
            placeholder={"Account Id"}
            isValidte={false}
            disabledField={"disabledField"}
          />
          <TextInput
            // marginTop={20}
            feildName={"accountType"}
            // inputLabelName={'Percentage Basic'}
            errors={{}}
            stateData={{ accountType: props.name, disabledField: true }}
            // iconClassName={'fas fa-percentage'}
            isValidte={false}
            placeholder={"Account Type"}
            disabledField={"disabledField"}
          />
        </RowWrapper>
        <br />
        <br />
        <Button
          buttonClass={"btnStandard"}
          btnName="Submit"
          textColor={"#fff"}
          onHandleSubmit={onSubmit}
          stateData={{ isLoading: customLoader }}
        />
      </FormLayoutWrapper>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubstitutionPeriority);

// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import $ from 'jquery';

// class StudentAdmissionDashboard extends Component {
// 	state = {};
// 	componentDidMount() {
// 		$(document).click(function(e) {
// 			if (!$(e.target).is('.panel-body')) {
// 				$('.collapse').collapse('hide');
// 			}
// 		});
// 	}
// 	render() {
// 		return (
// 			<div>
// 				<section class="mb-4">
// 					<h3 class="h3-responsive font-weight-bold text-center my-4">
// 						Admisson Form Module's
// 					</h3>
// 					<div
// 						class="row"
// 						style={{
// 							justifyContent: 'center',
// 							textAlign: 'center',
// 							marginBottom: -45
// 						}}
// 					>
// 						<div class="col-md-8">
// 							<div class="md-form mb-0">
// 								<hr class="style-head" />
// 							</div>
// 						</div>
// 					</div>

// 					<p class="text-center w-responsive mx-auto mb-5" />

// 					<div
// 						class="row"
// 						style={{ textAlign: 'center', justifyContent: 'center' }}
// 					>
// 						<div class="col-md-9 mb-md-0 mb-5">
// 							<form id="contact-form" name="contact-form">
// 								<div class="row">
// 									<div class="col-md-6">
// 										<div class="md-form mb-0">
// 											<Link to="/dashboard/AddForm">
// 												<img
// 													src={require('../Assets/StudentAdmission/addstudent.svg')}
// 													width="250"
// 													height="250"
// 												/>
// 											</Link>
// 										</div>
// 									</div>
// 									<div class="col-md-6">
// 										<div class="md-form mb-0">
// 											<Link to="/dashboard/ViewAddForm">
// 												<img
// 													src={require('../Assets/StudentAdmission/viewstudent.svg')}
// 													width="250"
// 													height="250"
// 												/>
// 											</Link>
// 										</div>
// 									</div>
// 								</div>
// 							</form>
// 							<div class="status" />
// 						</div>
// 					</div>
// 				</section>
// 			</div>
// 		);
// 	}
// }

// export default StudentAdmissionDashboard;
import React from "react";
import ViewRendering from "../components/ViewRendering";
import { renderingStuff, images } from "../utils";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";
import { useContext } from "react";
import ThemeContext from "../context/themeContext/ThemeContext";

export default function StudentAdmissionDashboard(props) {
  const themeColor = useContext(ThemeContext);
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <img
                src={require("../Assets/New_Admission_Icon/admission form.svg")}
                class="img-fluid"
                alt="Responsive image"
                height="50"
                width="50"
              />
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Student Admission Dashboard
              </h4>
            </div>
          </div>
        </div>
      </div>
      <NewViewRendering
        {...props}
        data={renderingStuff.Student_Admission_Links}
      />
    </div>
  );
}

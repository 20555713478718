import React, { Component } from "react";
import * as sharedActions from "../../Actions/sharedActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/HOC/LoaderWrapper";

class FeeReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReceiptData: "",
      StudentFeeTypes: [],
      chequeDetails: [],
      isLoading: true,
    };
  }
  componentDidMount() {
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/GetInVoiceFee/?voucherId=${this.props.match.params.id}`
      )
      .then((success) => {
        debugger;
        this.setState({
          isLoading: false,
          ReceiptData: success.BindStudentFeeRecords,
          StudentFeeTypes: success.StudentFeeTypes,
          chequeDetails: success.ChequeDetails,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: true,
        });
        debugger;
      });
  }
  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
  };
  render() {
    const { ReceiptData, StudentFeeTypes, chequeDetails } = this.state;
    return (
      <div>
        <Loader isLoading={this.state.isLoading}>
          <div id="div1">
            <div class="container">
              <div class="">
                <img
                  src={require("../../Assets/images/header.png")}
                  style={{
                    height: 220,
                  }}
                />
              </div>

              <div class="text-left font-weight-bold">
                <h5>SCHOOL COPY</h5>
              </div>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Date: {ReceiptData.date}</th>
                    <th scope="col">Receipt No: {ReceiptData.id}</th>
                  </tr>
                </thead>
              </table>
              <div class="row">
                <div class="col-md-6">
                  <label>Student Name:</label>
                  <label class="fontBoldColor">{ReceiptData.name}</label> <br />
                  <label>Class:</label>
                  <label class="fontBoldColor">
                    {ReceiptData.className} - {ReceiptData.section}
                  </label>
                  <br />
                  <label>Fee Month:</label>
                  <label class="fontBoldColor">{ReceiptData.feeMonths}</label>
                  <br />
                  <label>Paid By:</label>
                  <label class="fontBoldColor">{ReceiptData.paymentType}</label>
                  <br></br>
                  <label>Cheque Details: </label>
                  <br />
                  {chequeDetails.map((item, index) => (
                    <React.Fragment>
                      <li>
                        Cheque No :{" "}
                        <label class="fontBoldColor">{item.chequeNo}</label> -
                        Date:
                        <label class="fontBoldColor">{item.date}</label>
                      </li>
                    </React.Fragment>
                  ))}
                  <br />
                </div>
                <div class="col-md-6">
                  <label>Amount: </label>
                  <label class="fontBoldColor text-center">
                    {ReceiptData.totalAmount}/.
                  </label>{" "}
                  <br />
                  {StudentFeeTypes.map((item, index) => (
                    <React.Fragment>
                      <li>
                        {item.feeType} :{" "}
                        <label class="fontBoldColor">{item.fee}</label>
                      </li>
                    </React.Fragment>
                  ))}
                  <br />
                  <label>Payable Type:</label>
                  <label class="fontBoldColor">{ReceiptData.payableType}</label>
                  <br />
                  <label>Balance:</label>
                  <label class="fontBoldColor">
                    {ReceiptData.balance}/.
                  </label>{" "}
                  <br />
                  <label>Total Amount: </label>
                  <label class="fontBoldColor">
                    {ReceiptData.totalAmount}/.
                  </label>
                </div>
              </div>
              <br />
              <br />
              <hr
                style={{ backgroundColor: "#17a2b8", borderStyle: "bold" }}
              ></hr>
              <div class="page-footer">
                <div class="text-left">Printed By: {this.props.users.role}</div>
                <div
                  class="text-right"
                  style={{ marginTop: -25, marginRight: 20 }}
                >
                  Powered by School Smart®
                </div>
              </div>
              {/* Parent Copy */}
              <br />
              {/* <div class="hojaa"> */}
              <div class="">
                <img
                  src={require("../../Assets/images/header.png")}
                  style={{
                    width: "100%",
                    height: 220,
                  }}
                />
              </div>

              <div class="text-left font-weight-bold">
                <h5>PARENT COPY</h5>
              </div>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Date: {ReceiptData.date}</th>
                    <th scope="col">Receipt No: {ReceiptData.id}</th>
                  </tr>
                </thead>
              </table>
              <div class="row">
                <div class="col-md-6">
                  <label>Student Name:</label>
                  <label class="fontBoldColor">{ReceiptData.name}</label> <br />
                  <label>Class:</label>
                  <label class="fontBoldColor">
                    {ReceiptData.className} - {ReceiptData.section}
                  </label>
                  <br />
                  <label>Fee Month:</label>
                  <label class="fontBoldColor">{ReceiptData.feeMonths}</label>
                  <br />
                  <label>Paid By:</label>
                  <label class="fontBoldColor">
                    {ReceiptData.paymentType}
                  </label>{" "}
                  <br></br>
                  <label>Cheque Details: </label>
                  <br />
                  {chequeDetails.map((item, index) => (
                    <React.Fragment>
                      <li>
                        Cheque No :{" "}
                        <label class="fontBoldColor">{item.chequeNo}</label> -
                        Date:
                        <label class="fontBoldColor">{item.date}</label>
                      </li>
                    </React.Fragment>
                  ))}
                  <br />
                </div>
                <div class="col-md-6">
                  <label>Amount: </label>
                  <label class="fontBoldColor text-center">
                    {ReceiptData.totalAmount}/.
                  </label>{" "}
                  <br />
                  {this.state.StudentFeeTypes.map((item, index) => (
                    <React.Fragment>
                      <li>
                        {item.feeType} :{" "}
                        <label class="fontBoldColor">{item.fee}</label>
                      </li>
                    </React.Fragment>
                  ))}
                  <br />
                  <label>Payable Type:</label>
                  <label class="fontBoldColor">{ReceiptData.payableType}</label>
                  <br />
                  <label>Balance:</label>
                  <label class="fontBoldColor">
                    {ReceiptData.balance}/.
                  </label>{" "}
                  <br />
                  <label>Total Amount: </label>
                  <label class="fontBoldColor">
                    {ReceiptData.totalAmount}/.
                  </label>
                </div>
              </div>
              <br />
              <br />

              <hr
                style={{ backgroundColor: "#17a2b8", borderStyle: "bold" }}
              ></hr>
              <div class="page-footer">
                <div class="text-left">Printed By: {this.props.users.role}</div>
                <div
                  class="text-right"
                  style={{ marginTop: -25, marginRight: 20 }}
                >
                  Powered by School Smart®
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div class="text-center text-md-right">
            <label
              for="name"
              class=""
              onClick={() => this.PrintContent("div1")}
            >
              <i
                className="fas fa-print fa-2x animated fadeIn"
                style={{ marginRight: 4 }}
              />
              Print It Out
            </label>
          </div>
        </Loader>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    getData: state.setStaffFormReducer.getSingleFormData,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FeeReceipt);

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import Header from '../../Assets/images/3 headers-03.png';
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";



class PrintFlagManagment extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: false,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }

    static contextType = ThemeContext

    componentDidMount(){

    }

    
    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
    };


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    render(){
        const { isLoading, accountId, accountType } = this.state;
        const { report, flagType, ApiList, month, year } = this.props.location.state

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderReportData = ![ApiList[1], ApiList[3], ApiList[5], ApiList[7]].includes(flagType) ? report && report.studentList.map((item, index) => {
            return (

            <tr key={index} >
                <th scope="row" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.studentName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    <i class="fa fa-flag-o" style={{fontSize: 24, color: isEmpty(item.flagColor) ? 'Green' : item.flagColor }} ></i>
                </td>
            </tr>
            
            );
        }) :  report && 
            <tr key={1} >
                <th scope="row" style={{ padding: 1 }} >
                    {1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {report.studentName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                <i class="fa fa-flag-o" style={{fontSize: 24, color: isEmpty(report.flagColor) ? 'Green' : report.flagColor }} ></i>

                </td>
            </tr> 

        return (
            <div className="page-header"> 
                <div id="printPage" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row" >
                        <div>
								<div
									style={{
                                        width: '100%',
                                        height: '100%'
									}}
								>
									<img src={Header} width={'100%'} height={'100%'} />
								</div>
                                </div>
						</div>

                    <div class="row" style={{marginTop: '2%'}} >
							<div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h3>Flag Management Attendance Report</h3>
								
							</div>
						</div>

                    <div class="my-2 py-2 px-2" style={{
                        border: `3px solid ${this.context.themeColors.primaryColor}`,
                    }} >{[ApiList[1], ApiList[3], ApiList[5], ApiList[7]].includes(flagType) ?
                        <div class="row my-3 mx-2" style={{paddingLeft: '20px'}} >
                           <div class="col-md-4" >
                                    <span>Student ID: <strong style={{color: this.context.themeColors.primaryColor}} >{report && report.studentId}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Class: <strong style={{color: this.context.themeColors.primaryColor}} >{report && report.className}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Section: <strong style={{color: this.context.themeColors.primaryColor}} >{report && report.section}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Subject: <strong style={{color: this.context.themeColors.primaryColor}} >{report && report.subjectName}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Month: <strong style={{color: this.context.themeColors.primaryColor}} >{report && month}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Year: <strong style={{color: this.context.themeColors.primaryColor}} >{report && year}</strong></span>     
                            </div>
                        </div> : <div class="row my-3 mx-2" style={{paddingLeft: '20px'}} >
                            <div class="col-md-4" >
                                    <span>Class: <strong style={{color: this.context.themeColors.primaryColor}} >{report && report.className}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Section: <strong style={{color: this.context.themeColors.primaryColor}} >{report && report.section}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Subject: <strong style={{color: this.context.themeColors.primaryColor}} >{report && report.subjectName}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Month: <strong style={{color: this.context.themeColors.primaryColor}} >{report && month}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Year: <strong style={{color: this.context.themeColors.primaryColor}} >{report && year}</strong></span>     
                            </div>
                        </div>}
                    </div>
                    <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                        
                    </div>
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
                            <th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Role
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Absent
								</th>
                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderReportData}</tbody>
					</table>
                    
				</div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-4">
                            <span>Printed By: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.unique_name}</strong></span>     
                    </div>
                    <div class="col-md-4 text-center">
                             
                            <span>Role: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.role}</strong></span>     

                    </div>
                    <div class="col-md-4 text-right">
                             
                            <span>Time: <strong style={{color: this.context.themeColors.primaryColor}} >{moment().format('HH:mm - MM/DD/YYYY')}</strong></span>     

                    </div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-10">
                    
                    </div>
                    <div class="col-md-2">
                    <button class="btn btn-primary buttonAppear" onClick={() => this.PrintContent('printPage')}>
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintFlagManagment);

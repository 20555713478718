import React from "react";
import { useContext } from "react";
import themeContext from "../../context/themeContext/ThemeContext";

export default function CustomBadge(props) {
  const themeColor = useContext(themeContext);
  const {
    badgeText,
    badgeIconClass,
    bottom = 20,
    left = 5,
    top,
    fontSize = 18,
  } = props;
  return (
    <span
      style={{
        position: "relative",
        bottom,
        left,
        top,
        fontSize,
        background: themeColor.themeColors.primaryColor,
        borderColor: themeColor.themeColors.primaryColor,
      }}
      className="custom-badge-filter newPrintCss"
    >
      <i class={badgeIconClass}></i>
      {badgeText}
    </span>
  );
}

import React, { useEffect, useState, useRef, useContext } from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import DatePicker from 'react-date-picker';
import { Link } from 'react-router-dom';
import SelectionInput from '../../../components/SelectionInput/SelectionInput';
import TextInput from '../../../components/TextInput/TextInput'
import * as sharedActions from '../../../Actions/sharedActions';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { bindActionCreators } from 'redux';
import Spinner from '../../../components/Spinner/Spinner';
import Pagination from '../../../components/Pagination/Pagination'
import httpWithRequiredSetData from '../../../hooks/Services/httpWithRequiredSetData';
import general from '../../../utils/general'
import Colors from '../../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import themeContext from "../../../context/themeContext/ThemeContext";

let tHeadStudent = [
    'Patient',
    'Grade',
    'Doctor',
    'Date',
    'Contact #',
    'Problem',
    'Account',
    'Account Type',
    'Actions'
];

let renderFieldStudent = [
    'name',
    'grade',
    'doctorName',
    'date',
    'contactNo',
    'problem',
    'accountId',
    'accountType'
]
let tHeadStaff = [
    'Patient',
    'Staff',
    'Doctor',
    'Date',
    'Contact #',
    'Problem',
    'Account',
    'Account Type',
    'Actions'
];

let renderFieldStaff = [
    'name',
    'staffPost',
    'doctorName',
    'date',
    'contactNo',
    'problem',
    'accountId',
    'accountType'
]

const PatientDetail = (props) => {
    const themeColor = useContext(themeContext);

    const [studentStaff, setStudentStaff] = useState('');
    const [date, setDate] = useState('');
    const [doctorId, setDoctorId] = useState('')

    const [dId, setDId] = useState(null);
    const [eId, setEId] = useState(null);
    const [enabled, setEnabled] = useState(false);

    const [patientData, setPatientData] = useState([]);

    const [modalData, setModalData] = useState([]);
    let [patientName, setPatientName] = useState('');
    let [doctorName, setDoctorName] = useState('');
    let [problem, setProblem] = useState('');
    const [accountId, setAccountId] = useState(props.name)
    const [accountType, setAccountType] = useState(props.role)

    const [errors, setErrors] = useState([])
    const [msg, setSnackMsg] = useState('');
    /*     const [searchURL, setSearchURL] = useState(
            '/api/PatientDetail?PageNumber=1&pageSize=10&doctorId=&date=&studentStaff=Student'
        ); */
    const [doctorURL, setDoctorURL] = useState(
        '/api/Doctor/'
    );


    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});
    const mounted = useRef();

    /*     const [
            isLoading,
            fetchData,
            setFetchData,
            setIsLoading
        ] = httpWithRequiredSetData(searchURL, [], data => {
            let pageNumber = [];
            for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
                pageNumber.push(i);
            }
            setPageNumber(pageNumber);
            setPatientData(data['patientList']);
    
            setPaginationData(data['paginationMetadata']);
        }); */

    const [
        isLoading,
        doctorData,
        setDoctorData,
        setIsLoading
    ] = httpWithRequiredSetData(doctorURL, [], data => {
        setDoctorData(data);
    });

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            $('[data-toggle="tooltip"]').tooltip();
        }
    });
    useEffect(() => {
        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesPatient(
                    studentStaff,
                    doctorId,
                    date,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setPatientData(success['patientList']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setPatientData([])
            });
    }, [currentPage, date, doctorId, customPageSize]);

    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const onStudentStaffChange = val => {
        if (val !== 'Student/Staff') {
            setDoctorId('');
            setDate('')
            setIsLoading(true);
            setStudentStaff(val)
            props.sharedActions
                .getDataWithoutDispatch(
                    `/api/PatientDetail?pageNumber=1&pageSize=10&doctorId=&date=&studentStaff=${val}`
                )
                .then(success => {
                    setPatientData(success['patientList'])
                    let pageNumber = [];
                    for (
                        let i = 1;
                        i <= success['paginationMetadata']['totalPages'];
                        i++
                    ) {
                        pageNumber.push(i);
                    }
                    setPageNumber(pageNumber);
                    setPaginationData(success['paginationMetadata']);
                    setIsLoading(false);
                })
                .catch(error => {
                    setPatientData([])
                    setIsLoading(false);
                });

        }
    }

    const onDoctorChange = doctor => {
        if (doctor !== 'Doctor') {
            setIsLoading(true)
            setDoctorId(doctor)
        }
    }

    const onDateChange = date => {
        setIsLoading(true)
        setDate(date)

/*         let tempDate = new Date(date)
        let apiDate = ((tempDate.getMonth() > 8) ? (tempDate.getMonth() + 1) : ('0' + (tempDate.getMonth() + 1))) + '/' + ((tempDate.getDate() > 9) ? tempDate.getDate() : ('0' + tempDate.getDate())) + '/' + tempDate.getFullYear();


        props.sharedActions
            .getDataWithoutDispatch(
                `/api/PatientDetail?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&doctorId=${doctorId}&date=${apiDate}`
            )
            .then(success => {
                //                setPatientData(success[])
                let pageNumber = [];
                for (
                    let i = 1;
                    i <= success['paginationMetadata']['totalPages'];
                    i++
                ) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
                setIsLoading(false);
            })
            .catch(error => {
                setPatientData([])
                setIsLoading(false);
            });

 */    }

    const setDeleteID = id => {
        setDId(id);
    };

    const onClickDelete = id => {
        setIsLoading(true)

        props.sharedActions
            .deleteRecordWithoutDispatch(
                '/api/PatientDetail/',
                dId
            )
            .then(success => {
                // if (success.status === 200) {
                let getNewFilterArray = patientData.filter(item => item.id !== dId);
                setSnackMsg('Removed Successfully!');
                setPatientData(getNewFilterArray);
                setIsLoading(false)
                props.snackbar();
                setDId(null);
                // }
            })
            .catch(error => {
                setIsLoading(false)
                console.log(error);
            });
    };

    const btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    const onTakeItem = itemId => {
        setModalData([])
        let getData = patientData.filter(item => item.id === itemId);
        setModalData(getData)
    };

    const onEditClick = itemId => {
        onTakeItem(itemId);
        setEId(itemId)
        setEnabled(false)
    };

    const onHandleTextChange = e => {

        modalData[0].problem = e.target.value
        setProblem(e.target.value)
    };

    const onHandleSubmit = e => {
        e.preventDefault();

        if (problem !== '') {
            const data = {
                problem: modalData[0].problem,
                accountId: accountId,
                accountType: accountType
            }
            console.log(data);

            setIsLoading(true)
            props.sharedActions
                .editPatchRecordWithoutDispatch(
                    '/api/PatientDetail/',
                    eId,
                    data
                )
                .then(success => {
                    patientData.forEach((item, index) => {
                        if (item.id === eId) {
                            patientData.splice(index, 1, modalData[0])
                        }
                    })
                    setEnabled(false)
                    setSnackMsg('Edited Record Success!')
                    setEId('')
                    setIsLoading(false)
                    props.snackbar();
                })
        }
    }
    const bodyRendering = data =>
        data &&
        data.map((item, index) =>
            studentStaff === 'Student' ? (
                (
                    <tr>
                        <th
                            style={{
                                padding: 2,
                                border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                borderColor: themeColor.themeColors.primaryColor
                            }}
                            scope="row"
                        >
                            {index + 1}
                        </th>
                        {renderFieldStudent.map((item1, index) => (
                            <td
                                style={{
                                    fontWeight: `${index === 0 ? 'bold' : 'normal'}`,
                                    border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                    padding: 2,
                                    borderColor: themeColor.themeColors.primaryColor
                                }}
                            >
                                {item[item1]}
                            </td>
                        )
                        )}
                        <td
                            style={{
                                border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                padding: 2,
                                borderColor: themeColor.themeColors.primaryColor
                            }}
                        >
                            <Link to={`/dashboard/MedicalSlipTemplate/${item.id}/${item.studentStaff}`}>
                                <button
                                    id="firstbutton"
                                    // onClick={() => this.onEditClick(item.id)}
                                    onMouseOver={btnTechHover()}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 20,
                                        border: 'none',
                                        marginLeft: 5,
                                        background: 'none',
                                        marginBottom: 5
                                    }}
                                >
                                    <a href="#" data-toggle="tooltip" title="View!">
                                        <i style={{ color: '#01AC8A' }} class="fas fa-list"></i>
                                    </a>
                                </button>
                            </Link>
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                <button
                                    id="firstbutton"
                                    onClick={() => onEditClick(item.id)}
                                    onMouseOver={btnTechHover()}
                                    data-toggle="modal"
                                    data-target="#myModal"
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 20,
                                        border: 'none',
                                        marginLeft: 5,
                                        background: 'none',
                                        marginBottom: 5
                                    }}
                                >
                                    <i class="fas fa-pen"></i>
                                </button>
                            </a>

                            <a href="#" data-toggle="tooltip" title="Delete!">
                                <button
                                    onClick={() => setDeleteID(item.id)}
                                    data-toggle="modal"
                                    data-target="#myModal1"
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 20,
                                        border: 'none',
                                        background: 'none',
                                        marginLeft: 5,
                                        marginBottom: 5
                                    }}
                                >
                                    <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                                </button>
                            </a>
                        </td>
                    </tr>
                )
            ) : (<tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFieldStaff.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index === 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${themeColor.themeColors.primaryColor}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                )
                )}
                <td
                    style={{
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >
                    <Link to={`/dashboard/MedicalSlipTemplate/${item.id}/${item.studentStaff}`}>
                        <button
                            id="firstbutton"
                            // onClick={() => this.onEditClick(item.id)}
                            onMouseOver={btnTechHover()}
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <a href="#" data-toggle="tooltip" title="View!">
                                <i style={{ color: '#01AC8A' }} class="fas fa-list"></i>
                            </a>
                        </button>
                    </Link>
                    <a href="#" data-toggle="tooltip" title="Edit!">
                        <button
                            id="firstbutton"
                            onClick={() => onEditClick(item.id)}
                            onMouseOver={btnTechHover()}
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <i class="fas fa-pen"></i>
                        </button>
                    </a>
                    <a href="#" data-toggle="tooltip" title="Delete!">
                        <button
                            onClick={() => setDeleteID(item.id)}
                            data-toggle="modal"
                            data-target="#myModal1"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                background: 'none',
                                marginLeft: 5,
                                marginBottom: 5
                            }}
                        >
                            <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                        </button>
                    </a>
                </td>
            </tr>
                )
        );

    return (
        <div>
            <SnackBar msg={msg} />
            <div class="modal" id="myModal1">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Confirm
                    </h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
                    </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Are you sure ?</h4>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div>
                                        <button
                                            // disabled={this.state.enabled}
                                            data-dismiss="modal"
                                            onClick={() => onClickDelete(dId)}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            Yes
                                </button>
                                    </div>
                                    <div>
                                        <button
                                            data-dismiss="modal"
                                            // disabled={this.state.enabled}
                                            type="button"
                                            class="btn btn-primary buttonAppear"
                                            style={{background: themeColor.themeColors.primaryColor}}
                                        >
                                            No
                                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content modal_content_custom">
                        <div
                            style={{
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18,
                                background: themeColor.themeColors.primaryColor
                            }}
                            class="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} class="modal-title">
                                Details
								</h4>
                            <button
                                style={{ color: 'white' }}
                                type="button"
                                class="close"
                                data-dismiss="modal"
                            >
                                &times;
								</button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={e => onHandleSubmit(e)}>
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            <fieldset disabled={!enabled}>
                                                <TextInput
                                                    customWidth={true}
                                                    feildName={'patientName'}
                                                    inputLabelName={'Patient Name'}
                                                    errors={errors}
                                                    stateData={{
                                                        patientName: item.name
                                                    }}
                                                    placeholder={'Patient Name'}
                                                    readOnly={true}
                                                    type={'text'}
                                                    isValidte={false}
                                                />
                                                <TextInput
                                                    customWidth={true}
                                                    feildName={'doctorName'}
                                                    inputLabelName={'Doctor Name'}
                                                    errors={errors}
                                                    stateData={{
                                                        doctorName: item.doctorName
                                                    }}
                                                    placeholder="Doctor Name"
                                                    readOnly={true}
                                                    type={'text'}
                                                    isValidte={false}
                                                />

                                                <TextInput
                                                    customWidth={true}
                                                    feildName={'problem'}
                                                    inputLabelName={'Problem'}
                                                    errors={errors}
                                                    stateData={{
                                                        problem: item.problem
                                                    }}
                                                    onHandleChange={onHandleTextChange}
                                                    placeholder="Problem"
                                                    type={'text'}
                                                    isValidte={false}
                                                />

                                            </fieldset>
                                            <br />
                                            <br />
                                            <button
                                                disabled={enabled}
                                                onClick={() => setEnabled(true)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                                style={{background:themeColor.themeColors.primaryColor}}
                                            >
                                                Edit
												</button>
                                            <button
                                                style={{ marginLeft: 5, background: themeColor.themeColors.primaryColor }}
                                                disabled={!enabled}
                                                type="submit"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Save
												</button>
                                        </div>
                                    ))}
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                style={{ background: '#00435D' }}
                                class="btn btn-danger"
                                data-dismiss="modal"
                            >
                                Close
								</button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2
                                      style={{ background: themeColor.themeColors.primaryColor }}

                         className="h1-responsive font-weight-bold text-left my-4 generalHead">
                            {' '}
                            Patient Details{' '}
                        </h2>
                    </div>
                    <div
                        style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                    >
                        <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={handleClickNext}
                            handleClickNextBack={handleClickNextBack}
                            handleClickNextFrwd={handleClickNextFrwd}
                        />
                        <div
                            style={{
                                width: 'fit-content',
                                marginTop: -34,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto'
                            }}
                        >
                            <SelectionInput
                                selectName="Entries"
                                selectFont={14}
                                selectFontWeight={'bold'}
                                newFlag="customWidth"
                                feildName="customPageSize"
                                selectedText={'Page Entries'}
                                optionsArrys={['10', '20', '30', '40', '50']}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    customPageSize: customPageSize
                                }}
                                optionType="static"
                                onHandleChange={onChangePageSize}
                                useFlag={false}
                            />
                        </div>
                    </div>
                </div>
                <CustomFilterSectionLayout displayDirection="column">
                    <div
                        style={{
                            width: '30%',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >

                        <SelectionInput
                            newFlag="customWidth"
                            feildName="studentStaff"
                            selectedText={'Student/Staff'}
                            optionsArrys={general.patientType}
                            errors={errors}
                            isValidte={false}
                            stateData={{
                                studentStaff
                            }}
                            optionType="static"
                            onHandleChange={e => onStudentStaffChange(e.target.value)}
                            useFlag={false}
                        />

                        {studentStaff !== '' ? (
                            <React.Fragment>
                                <SelectionInput
                                    newFlag="customWidth"
                                    feildName="doctorId"
                                    selectedText={'Doctor'}
                                    optionsArrys={doctorData}
                                    errors={errors}
                                    isValidte={false}
                                    stateData={{
                                        doctorId: doctorId
                                    }}
                                    optionType="dynamic"
                                    onHandleChange={e => onDoctorChange(e.target.value)}
                                    property={'doctorName'}
                                    propertyId={'id'}
                                    useFlag={false}
                                />
                                <div class="col-md-6" style={{ marginTop: 12 }}>
                                    <div class="md-form mb-0">
                                        <br />
                                        <DatePicker
                                            //                                    maxDate={true}
                                            onChange={e => onDateChange(e)}
                                            value={date}
                                            selectDateOnly
                                        />
                                    </div>
                                </div>
                            </React.Fragment>

                        ) : ('')}
                    </div>
                </CustomFilterSectionLayout>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) :
                patientData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>
                    : (
                        <div className="table-responsive">
                            <table class="table table-hover">
                                <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <tr>
                                        <th scope="col">#</th>
                                        {studentStaff === 'Student' ? (tHeadStudent.map(item => (
                                            <th
                                                style={{
                                                    border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                                    borderColor: themeColor.themeColors.primaryColor
                                                }}
                                                scope="col"
                                            >
                                                {item}
                                            </th>
                                        ))) : (tHeadStaff.map(item => (
                                            <th
                                                style={{
                                                    border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                                    borderColor: themeColor.themeColors.primaryColor
                                                }}
                                                scope="col"
                                            >
                                                {item}
                                            </th>
                                        )))}
                                    </tr>
                                </thead>
                                <tbody> {bodyRendering(patientData)}</tbody>
                            </table>
                        </div>
                    )
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientDetail);
import React from "react";

export default function CardsForDiscipline(props) {
  const { onChangeDrop, info, heading , Index } = props;
  console.log("information", info);
  console.log("Key", Index);
  return (
    <div>
      <h4 style={{padding: 0}}>{Index == 0 ? heading : "-"}</h4>
      <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
        <div class="mainflip">
          <div class="frontside">
            <div class="card">
              <div class="card-body text-center">
                <p>
                  <i
                    style={{ color: "#01425e" }}
                    class="fas fa-angle-double-right fa-2x"
                  ></i>
                </p>
                <h4 class="card-title">{info.Heading}</h4>
                <p class="card-text">
                  Add the Discipline Information about the {info.Heading}.
                </p>
                <button
                  href="#"
                  class="btn btn-sm"
                  style={{ backgroundColor: "#01425e", color: "white" }}
                >
                  <i class="fa fa-plus"></i>
                </button>
                {info.state1 || info.state2 !== "" ? (
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      Found: <a>{info.state1}</a>
                    </li>
                    <li class="list-inline-item">
                      Reason: <a>{info.state2}</a>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div class="backside">
            <div class="card">
              <div class="card-body text-center mt-4">
                <h4 class="card-title">{info.Heading}</h4>
                <div class="row">
                  <div class="col-md-12">
                    <div class="md-form mb-0">
                      <select
                        onChange={(e) => onChangeDrop(e)}
                        class="custom-select"
                        name={info.name1}
                      >
                        <option>Select Yes / No..</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <br />
                  </div>
                  {info.state1 == "Yes" ? (
                    <div class="col-md-12">
                      <div class="md-form mb-0">
                        <input
                          class="form-control"
                          type="text"
                          value={info.state2}
                          placeholder="Reason"
                          name={info.name2}
                          onChange={(e) => onChangeDrop(e)}
                        />
                      </div>
                      <br />
                    </div>
                  ) : null}
                  {/* <div class="col-md-12">
										<div class="md-form mb-0">
											<select
												onChange={e => onChangeDrop(e)}
												class="custom-select"
												name={info.name3}
											>
												<option>Action Taken..</option>
												<option value="CB">Contact Book</option>
												<option value="HoD">Department Head</option>
												<option value="DP">Deputy Principal</option>
												<option value="CP">Contact Parent</option>
												<option value="S">Suspension</option>
												<option value="Ex">Expulsion</option>
											</select>
										</div>
									</div> */}
                </div>

                <ul class="list-inline">
                  <li class="list-inline-item">
                    Found: <a>{info.state1}</a>
                  </li>
                  <li class="list-inline-item">
                    Reason: <a>{info.state2}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

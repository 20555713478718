import React, { useEffect, useState, useRef } from "react";
import { ViewDataTable } from "../../components/Edit";
import Spinner from "../../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import $ from "jquery";
import SnackBar from "../../components/SnackBar/SnackBar";
import Axios from "axios";
import * as sharedActions from "../../Actions/sharedActions";

import config from "../../config/config";
import SelectionInput from "../../components/SelectionInput/SelectionInput";
import Button from "../../components/Button";
import Colors from "../../utils/app_constants/colors_constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import httpWithRequiredSetData from "../../hooks/Services/httpWithRequiredSetData";
import Pagination from "../../components/Pagination/Pagination";
import useHttp from "../../hooks/http";
import useHttpWithGetSpecificData from "../../hooks/Services/httpWithGetSpecificData";
import CustomBadge from "../../components/CustomBadge/CustomBadge";
import CustomFilterSectionLayout from "../../components/CustomFilterSectionLayout/CustomFilterSectionLayout";
import general, { dataFilteration } from "../../utils/general";
import { General } from "../../utils";
import { useContext } from "react";
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = ["Name", "Month", "Actions"];
let renderFields = ["name", "term"];

const AcademicHeadResultSheet = (props) => {
  const themeContext = useContext(ThemeContext);
  // const [classes,setClasses] = useState([]);
  // const [section,setSection] = useState([]);
  const [month, setMonth] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isSessionLoading, sessions] = useHttp("/api/Session", []);
  const [staffClassSection, setStaffClassSection] = useState([]);
  const [isLoader, fetchDataClasses] = useHttp(
    `/api/DescriptiveReport?userName=${props.name}`,
    []
  );
  const [isLoadingFetchSession, fetchSessionData] = useHttp(`/api/Session`, []);

  debugger;

  const [sessionId, setSessionId] = useState("");
  debugger;
  const [subjectId, setSubjectId] = useState("");
  debugger;

  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [term, setTerm] = useState("");
  const [sectionId, setSectionId] = useState("");
  debugger;
  const [classes, setClasses] = useState([]);
  const [isClassSectionLoading, classSectionData] = httpWithRequiredSetData(
    `/api/getclasssectiongrade`,
    [],
    (data) => {
      debugger;
      setClasses(data["classes"]);
      setStaffClassSection(data["grade"]);
    }
  );
  const [classId, setClassId] = useState("");
  debugger;
  const [disableBPage, setdisableBPage] = useState(true);

  const [customPageSize, setCustomPageSize] = useState("10");
  const [dropDownSearch, setDropDownSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [num, setNum] = useState(1);
  const [prevNum, setPrevNum] = useState(1);
  const [numOfRenderRecord, setNumOfRenderRecord] = useState(10);
  const [pageNumber, setPageNumber] = useState([]);
  const [staffData, setStaffData] = useState(null);
  const [disablepage, setdisablepage] = useState(false);
  const [customLoader, setCustomLoader] = useState(false);
  const [pagination, setPaginationData] = useState({});
  const [getdepartmentId, setDepartmentId] = useState(null);
  const [postId, setPostId] = useState("");
  const [filteredPost, setFilteredPost] = useState([]);
  const mounted = useRef();

  const [
    isLoading,
    fetchData,
    setFetchData,
    setIsLoading,
  ] = httpWithRequiredSetData(
    `/api/CA_PrimaryList_StaffPortal/?userName=${props.name}`,
    [],
    (data) => {
      debugger;
      debugger;
      let newData = data["PrimaryToolViews"].map((item) => {
        return {
          ...item,
          month: "",
        };
      });
      setStaffData(newData);
    }
  );
  const [isDepartLoading, departmentData = []] = useHttpWithGetSpecificData(
    "/api/StaffPostDepart",
    [],
    "department"
  );
  const [isPostLoading, posts = []] = useHttpWithGetSpecificData(
    "/api/StaffPostDepart",
    [],
    "post"
  );

  const onSectionChange = (event) => {
    if (event.target.value !== "Choose Section") {
      setSectionId(event.target.value);
      let getSubjects = dataFilteration(
        staffClassSection,
        ["subjectId", "subjectName"],
        {
          classId: classId,
          sectionId: event.target.value,
        }
      );
      setSubjects(getSubjects);
      debugger;
    }
  };
  const onSessionChange = (event) => {
    if (event.target.value !== "Choose Session") {
      setSessionId(event.target.value);
    }
  };
  const onTermChange = (event) => {
    if (event.target.value !== "Choose Term") {
      setTerm(event.target.value);
    }
  };
  const onSubjectChange = (event) => {
    if (event.target.value !== "Choose Subject") {
      setSubjectId(event.target.value);
    }
  };
  const onSearch = () => {
    setIsSearching(true);
    props.sharedAction
      .getDataWithoutDispatch(
        `/api/OverallResultSheet/?classId=${classId}&sectionId=${sectionId}&sessionId=${sessionId}&term=${term}`
      )
      .then((success) => {
        debugger;
        setStaffData(success);
        props.history.push(
          `/dashboard/catermWiseReport/${classId}/${sectionId}/${sessionId}/${term}`
        );
        setIsSearching(false);
      })
      .catch((error) => {
        setIsSearching(false);

        setSnackMsg("Data Not Found");
        props.snackbar();
        debugger;
      });
  };
  const onClassChange = (event) => {
    debugger;
    setSectionId("");
    setSubjectId("");

    if (event.target.value !== "Choose Class") {
      debugger;
      let getSection = dataFilteration(
        staffClassSection,
        ["sectionId", "section"],
        {
          classId: parseInt(event.target.value),
        }
      );

      debugger;
      setClassId(event.target.value);
      setSections(getSection);
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      $('[data-toggle="tooltip"]').tooltip();
    }
  });
  useEffect(() => {
    return () => {
      $('[data-toggle="tooltip"]').tooltip("hide");
    };
  }, []);
  const handleClickNextFrwd = () => {
    console.log("pagelength: ", pageNumber.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumber.length) {
      setdisablepage(true);
    } else {
      setCurrentPage((prevState) => prevState + 1);
      console.log(currentPage);

      setdisableBPage(false);
    }
  };
  const handleClickNext = (event) => {
    setCurrentPage(Number(event.target.id));
    setdisableBPage(false);
    setdisableBPage(false);
  };
  const handleClickNextBack = () => {
    if (currentPage === 1) {
      setdisableBPage(true);
      setdisablepage(false);
    } else {
      setCurrentPage((prevState) => prevState - 1);
    }
  };
  const [msg, setSnackMsg] = useState("");
  const [dId, setDId] = useState(null);
  const btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };

  const onChangePageSize = (e) => {
    if (e.target.value !== "Page Entries") {
      setCustomPageSize(e.target.value);
      setCurrentPage(1);
    }
  };
  // Delete function
  const onChangeMonth = (event, takeIndex) => {
    let getElement = JSON.parse(JSON.stringify(staffData));
    getElement[takeIndex]["month"] = event.target.value;
    setStaffData(getElement);
    debugger;
  };
  const setDeleteID = (id) => {
    setDId(id);
  };
  const onClickDelete = (id) => {
    console.log(dId);

    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    Axios.delete(`${config.localhttp}/api/StudentAdmissionForm/${dId}`, {
      data: { id }, // or data: jdId, depending on how you handle it in the back end
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((success) => {
        // if (success.status === 200) {
        let getNewFilterArray = staffData.filter((item) => item.id !== dId);
        setSnackMsg("Remove Successfully!");
        setStaffData(getNewFilterArray);
        props.snackbar();
        setDId(null);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);

  console.log("fetchd data: ", fetch);
  const bodyRendering = (data) =>
    data &&
    data.map((item, uperindex) => (
      <tr>
        <th
          style={{
            padding: 2,
            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
            borderColor: Colors.SEA_GREEN_THEME,
          }}
          scope="row"
        >
          {uperindex + 1}
        </th>
        {renderFields.map((item1, index) =>
          index == 1 ? (
            <td
              style={{
                border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                padding: 4,
                borderColor: Colors.SEA_GREEN_THEME,
              }}
            >
              <select
                onChange={(e) => onChangeMonth(e, uperindex)}
                className="form-control"
                name="month"
                id="month"
              >
                <option>Select Month</option>
                {General.Months.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </td>
          ) : (
            <td
              style={{
                fontWeight: `${index == 0 ? "bold" : "normal"}`,
                border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                padding: 2,
                borderColor: Colors.SEA_GREEN_THEME,
              }}
            >
              {item[item1]}
            </td>
          )
        )}
        <td
          style={{
            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
            padding: 2,
            borderColor: Colors.SEA_GREEN_THEME,
          }}
        >
          <a href="#" data-toggle="tooltip" title="Add!">
            <Link
              className={!item.month ? "disabled-link" : ""}
              to={`/dashboard/CaAddFeature/${item.pId}/${item.month}`}
            >
              <button
                disabled={!item.month ? true : false}
                id="firstbutton"
                // onClick={() => this.onEditClick(item.id)}
                onMouseOver={btnTechHover()}
                data-toggle="modal"
                data-target="#myModal"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i class="fas fa-plus"></i>
              </button>
            </Link>
          </a>
          <a href="#" data-toggle="tooltip" title="View!">
            <Link
              className={!item.month ? "disabled-link" : ""}
              to={`/dashboard/CaViewFeature/${item.pId}/${item.month}`}
            >
              <button
                disabled={!item.month ? true : false}
                id="firstbutton"
                // onClick={() => this.onEditClick(item.id)}
                onMouseOver={btnTechHover()}
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i
                  style={{
                    color: !item.month
                      ? Colors.DISABLE_GREY
                      : Colors.SEA_GREEN_THEME,
                  }}
                  class="fas fa-list"
                ></i>
              </button>
            </Link>
          </a>
          <a href="#" data-toggle="tooltip" title="Enter Final Marks">
            <Link
              // className={!item.month ? 'disabled-link' : ''}
              to={`/dashboard/CaAddExamV2Feature/${item.pId}`}
            >
              <button
                // disabled={!item.month ? true : false}
                onClick={() => setDeleteID(item.id)}
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i
                  // style={{
                  // 	color: !item.month
                  // 		? Colors.DISABLE_GREY
                  // 		: Colors.SEA_GREEN_THEME
                  // }}
                  class="fas fa-plus-square"
                ></i>
              </button>
            </Link>
          </a>
          <a href="#" data-toggle="tooltip" title="View Final Marks!">
            <Link
              // className={!item.month ? 'disabled-link' : ''}
              to={`/dashboard/CaViewExamV2Feature/${item.pId}`}
            >
              <button
                // disabled={!item.month ? true : false}
                onClick={() => setDeleteID(item.id)}
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i
                  // style={{
                  // 	color: !item.month
                  // 		? Colors.DISABLE_GREY
                  // 		: Colors.SEA_GREEN_THEME
                  // }}
                  class="fas fa-list"
                ></i>
              </button>
            </Link>
          </a>
        </td>
      </tr>
    ));

  return (
    <div>
      <SnackBar backColor={Colors.RED_DANGER} msg={msg} />
      <div class="modal" id="myModal1">
        <div class="modal-dialog">
          <div class="modal-content modal_content_custom">
            <div
              style={{
                borderBottomLeftRadius: 30,
                borderBottomRightRadius: 30,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
              }}
              class="modal-header modal_custom_header"
            >
              <h4 style={{ color: "white" }} class="modal-title">
                Confirm
              </h4>
              <button
                style={{ color: "white" }}
                type="button"
                class="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div style={{ textAlign: "center" }}>
                  <h4>Are you sure ?</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div>
                    <button
                      // disabled={this.state.enabled}
                      data-dismiss="modal"
                      onClick={() => onClickDelete(dId)}
                      type="button"
                      class="btn btn-primary buttonAppear"
                    >
                      Yes
                    </button>
                  </div>
                  <div>
                    <button
                      data-dismiss="modal"
                      // disabled={this.state.enabled}
                      type="button"
                      class="btn btn-primary buttonAppear"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "right" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h2
              style={{ background: themeContext.themeColors.primaryColor }}
              className="h1-responsive font-weight-bold text-left my-4 generalHead"
            >
              {" "}
              Result Sheet{" "}
            </h2>
          </div>
          <div
            style={{ marginTop: 40, display: "flex", flexDirection: "column" }}
          ></div>
        </div>
        <CustomFilterSectionLayout displayDirection="column">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
            }}
          >
            <div>
              <select
                onChange={onClassChange}
                className="form-control"
                name="class"
                id="class"
              >
                <option value="one">Choose Class</option>

                {classes &&
                  classes.map((item) => (
                    <option value={item.classId}>{item.className}</option>
                  ))}
              </select>
            </div>
            <div>
              <select
                disabled={sections && sections.length < 1 ? true : false}
                onChange={onSectionChange}
                className="form-control"
                name="class"
                id="class"
              >
                <option value="one">Choose Section</option>
                {sections &&
                  sections.map((item) => (
                    <option value={item.sectionId}>{item.section}</option>
                  ))}
              </select>
            </div>

            <div>
              <select
                disabled={sessions && sessions.length < 1 ? true : false}
                onChange={onSessionChange}
                className="form-control"
                name="class"
                id="class"
              >
                <option value="one">Choose Session</option>
                {fetchSessionData &&
                  fetchSessionData.session.map((item) => (
                    <option value={item.sessionId}>
                      {item.currentSession}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <select onChange={onTermChange} className="form-control">
                <option value="one">Choose Term</option>
                <option value="1st">1st Term</option>
                <option value="2nd">2nd Term</option>
                <option value="3rd">3rd Term</option>
              </select>
            </div>
            <div>
              {/* <Link
								to={`/dashboard/catermWiseReport/${classId}/${sectionId}/${sessionId}/${term}`}
							> */}
              <Button
                stateData={{
                  isLoading: isSearching,
                }}
                customClause={
                  classId !== "" &&
                  sessionId !== "" &&
                  term !== "" &&
                  sectionId !== ""
                    ? false
                    : true
                }
                buttonClass={"btn-smart-one"}
                textColor={Colors.WHITE}
                btnName={"Search"}
                onHandleSubmit={onSearch}
              />
              {/* </Link> */}
            </div>
          </div>
        </CustomFilterSectionLayout>
      </div>

      {/* {isLoading ? (
				<Spinner />
			) : (
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: '#01AC8A', color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								{tHead.map(item => (
									<th
										style={{
											border: `1px solid ${Colors.SEA_GREEN_THEME}`,
											borderColor: Colors.WHITE
										}}
										scope="col"
									>
										{item}
									</th>
								))}
							</tr>
						</thead> */}
      {/* <tbody>{bodyRendering(staffData)}</tbody> */}
      {/* </table>
				</div>
			)} */}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcademicHeadResultSheet);

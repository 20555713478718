import React, { useEffect, useState,useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../../Actions/sharedActions';
import Colors from '../../../utils/app_constants/colors_constants';
import httpWithRequiredSetData from '../../../hooks/Services/httpWithRequiredSetData';
import Spinner from '../../../components/Spinner/Spinner';
import TextInput from '../../../components/TextInput/TextInput';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import SnackBar from '../../../components/SnackBar/SnackBar';
import themeContext from "../../../context/themeContext/ThemeContext";

const ManagerMessage = props => {
    const themeColor = useContext(themeContext);

	const [msg, setSnackMsg] = useState('');
	const [coordinatorData, setCoordinatorData] = useState({});
	const [update, setUpdate] = useState(false);
	const [hodData, setHODData] = useState({});
	const [state, setState] = useState({
		title: '',
		message: '',
		accountId: props.name,
		accountType: props.role,
		errorMsg: false
	});

	const [isLoadingCoordinator, coordinator] = httpWithRequiredSetData(
		'/api/GetCommunicationCoordinator',
		[],
		data => {
			setCoordinatorData(data);
		}
	);

	const [isLoadingSender, Sender] = httpWithRequiredSetData(
		`/api/ManagerCoordinator/?username=${props.name}`,
		[],
		data => {
			setHODData(data);
		}
	);

	useEffect(() => {}, [update]);

	const onHandleTextChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));
	};

	const onSubmit = e => {
		e.preventDefault();

		if (state['message'] === '') {
			setState(prevState => ({
				...prevState,
				errorMsg: true
			}));
		} else {
			setState(prevState => ({
				...prevState,
				isLoading: true
			}));

			const data = {
				FromPostId: hodData.postId,
				FromStaffId: hodData.staffId,
				title: state['title'],
				message: state['message'],
				accountId: state['accountId'],
				accountType: state['accountType']
			};

			props.sharedActions
				.simpleAddRequest('/api/ManagerMsgToCoordinator', data)
				.then(success => {
					setSnackMsg('Message Sent Successfully!');
					props.snackbar();
					setState(prevState => ({
						isLoading: false,
						title: '',
						message: '',
						accountId: props.name,
						accountType: props.role,
						errorMsg: false
					}));
				})
				.catch(error => {
					if (error && error.response && error.response.status == 404) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							isLoading: false
						}));
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					} else if (error && error.response && error.response.status == 400) {
						// 404-Error no understanding of 404 Error
						setState(prevState => ({
							...prevState,
							isLoading: false
						}));
						props.snackbar();
						setSnackMsg(error.response.data.Message);
					}
				});
		}
	};

	useEffect(() => {
		if (state['errorMsg']) {
			setTimeout(() => {
				setState(prevState => ({
					...prevState,
					errorMsg: false
				}));
			}, 3000);
		}
	});

	const cards = (
		<div class="card">
			<div
				class="card-header text-center"
				style={{ backgroundColor: '#d3d3d3', color: '#fff' }}
			>
				<h3>
					<strong>NEW MESSAGE</strong>
				</h3>
			</div>
			<div class="card-body">
				<div class="row ">
					<div class="col-md-12">
						{state.errorMsg ? (
							<p style={{ color: 'red', textAlign: 'center' }}>
								Please add a message
							</p>
						) : (
							''
						)}
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							padding: '10px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>From</strong>
						</div>
						<div class="col-md-6 d-flex">
							<TextInput
								feildName={'post'}
								errors={{}}
								stateData={hodData}
								placeholder="Post Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>

							<TextInput
								feildName={'name'}
								errors={{}}
								stateData={hodData}
								placeholder="Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							padding: '10px',
							marginTop: '15px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>To</strong>
						</div>
						<div class="col-md-6 d-flex">
							<TextInput
								feildName={'coordinatorPostName'}
								errors={{}}
								stateData={coordinatorData}
								placeholder="Coordinator Post Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>

							<TextInput
								feildName={'coordinatorName'}
								errors={{}}
								stateData={coordinatorData}
								placeholder="Coordinator Name"
								readOnly={true}
								type={'text'}
								isValidte={false}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '15px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>Title</strong>
						</div>
						<div class="col-md-12">
							<TextInput
								feildName={'title'}
								errors={{}}
								stateData={state}
								placeholder="Add Title"
								type={'text'}
								isValidte={false}
								onHandleChange={onHandleTextChange}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '30px'
						}}
					>
						<div class="col-md-1" style={{ marginTop: '38px' }}>
							<strong>Message</strong>
						</div>
						<div class="col-md-12">
							<TextArea
								feildName={'message'}
								onHandleChange={onHandleTextChange}
								errors={{}}
								stateData={state}
								// iconClassName={'fas fa-percentage'}
								placeholder={'Add Message'}
								isValidte={false}
								row={15}
								customWidth={true}
								enterWidth={'180%'}
							/>
						</div>
					</div>
					<div
						class="col-md-12 border-top border-info d-flex justify-content-start"
						style={{
							marginTop: '30px'
						}}
					>
						<Button
							customClause={false}
							btnBackColor={Colors.DARK_SEA_GREEN_THEME}
							width="150%"
							buttonClass="customButton"
							btnName="Send"
							loaderBmargin={5}
							stateData={state}
							marginTop={20}
							onHandleSubmit={onSubmit}
							margin={'0 auto'}
							textColor={'white'}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<React.Fragment>
			<SnackBar msg={msg} />
			<div>
				<h2 className="h1-responsive font-weight-bold my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
					{' '}
					Compose New Message{' '}
				</h2>
			</div>
			<br />
			<br />

			{isLoadingSender ? <Spinner /> : cards}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		name: state.setUserReducer.users.unique_name,
		role: state.setUserReducer.users.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ManagerMessage);

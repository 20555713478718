import React, { Component } from "react";
import $ from "jquery";
import "../Admin/viewAdmin.css";
import Axios from "axios";
import config from "../../config/config";
import { connect } from "react-redux";
import ThemeContext from "../../context/themeContext/ThemeContext";

class VoucherDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      modalData: null,
      name: "",
      contactNo: "",
      address: "",
      description: "",
      enabled: false,
      id: null,
      isLoading: true,
      disablepage: false,
      disableBPage: false,
      currentPage: 1,
      todosPerPage: 10,
      changeIndex: 4,
      sessions: [],
      sessionId: "",
      paymentType: "",
      status: "",
      pages: [],
      BindStudentDefineFees: [],
      BindDiscountFees: [],
      GraphData: {
        datasets: [
          {
            // barPercentage: 0.4,
            // minBarLength: 2,
            label: "Course Average",
            data: [2, 2, 2],
            backgroundColor: [
              "#dfeaea",
              "#ea766c",
              "#abe2b9",
              "#adbceb",
              "#be9e9d",
              "#9d5b4d",
              "#e4d86b",
              "#9ecf54",
              "#e4d86b",
              "#ea766c",
              "#abe2b9",
              "#adbceb",
            ],
            borderColor: [
              "#abe2b9",
              // '#ea766c',
              // '#adbceb',
              // '#be9e9d',
              // '#9d5b4d',
              // '#e4d86b',
              // '#9ecf54',
              // '#e4d86b'
            ],
            // borderWidth: 2
          },
        ],
        labels: ["BIO", "ICT", "MATH"],
        options: {
          showLines: true,
        },
      },
    };
  }

  static contextType = ThemeContext;

  // TODO make this code reuseable
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage, pages } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
    if (currentPage > 4) {
      debugger;
      this.setState({
        changeIndex: +this.state.changeIndex + 1,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
  };
  paginationRequest = (number) => {
    this.setState({
      searchCheck: true,
    });
    console.log(number);
    var token;
    if (localStorage.jwtToken) {
      token = localStorage.getItem("jwtToken");
    }
    Axios.get(
      `${config.localhttp}/api/StudentInstallmentList/?pageNumber=${number}&pageSize=20&sessionId=${this.state.sessionId}&paymentType=${this.state.paymentType}&status=${this.state.status}`,
      {
        headers: { Authorization: `Bearer  ${token}` },
      }
    )
      .then((success) => {
        console.log(success);
        debugger;
        this.setState({
          isLoading: false,
          adminData: success.data.recieptList,
          pages: success.data.paginationMetadata,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error);
      });
  };
  onSearchVouchers = (e) => {
    e.preventDefault();
    this.setState({
      searchCheck: true,
    });

    var token;
    if (localStorage.jwtToken) {
      token = localStorage.getItem("jwtToken");
    }
    Axios.get(
      `${config.localhttp}/api/StudentInstallmentList/?pageNumber=1&pageSize=20&sessionId=${this.state.sessionId}&paymentType=${this.state.paymentType}&status=${this.state.status}`,
      {
        headers: { Authorization: `Bearer  ${token}` },
      }
    )
      .then((success) => {
        console.log(success);
        debugger;
        this.setState({
          isLoading: false,
          adminData: success.data.recieptList,
          pages: success.data.paginationMetadata,
        });
      })
      .catch((error) => {
        this.setState({
          adminData: [],
          isLoading: false,
          pages: [],
        });
        console.log(error);
      });
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;

    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        changeIndex: +this.state.changeIndex - 1,
        currentPage: prevState.currentPage - 1,
      }));
    }
  };
  componentDidMount() {
    const { match } = this.props;
    let token = "";
    if (localStorage.jwtToken) {
      token = localStorage.getItem("jwtToken");
    }
    Axios.get(
      `${config.localhttp}/api/ShowStundentVoucher/?sessionId=${match.params.session}&studentId=${match.params.student}&classId=${match.params.class}&paymentType=${match.params.paymentType}`,
      {
        headers: { Authorization: `Bearer  ${token}` },
      }
    )
      .then((success) => {
        console.log(success);
        this.setState({
          isLoading: false,
          adminData: success.data.StudentInstallmentList,
          BindStudentDefineFees:
            success.data.StudentInstallmentList[0]["BindStudentDefineFees"],
          BindDiscountFees:
            success.data.StudentInstallmentList[0]["BindDiscountFees"],
        });
        debugger;
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error);
      });
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    console.log(itemId);

    const { adminData } = this.state;
    let token = "";
    if (localStorage.jwtToken) {
      token = localStorage.getItem("jwtToken");
    }
    var Headconfig = {
      headers: { Authorization: "Bearer " + token },
    };
    var bodyParameters = { id: itemId };

    Axios.delete(`${config.localhttp}/api/Suppliers/${itemId}`, {
      data: { id: itemId }, // or data: jdId, depending on how you handle it in the back end
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          let getData = adminData.filter((item) => item.id !== itemId);

          this.setState({
            adminData: getData,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      name: getData[0].name,
      description: getData[0].description,
      address: getData[0].address,
      contactNo: getData[0].contactNo,
      id: getData[0].id,
    });
  };
  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onHandleSubmit = (e) => {
    e.preventDefault();
    const { name, description, id, contactNo, address } = this.state;
    let token = "";
    if (localStorage.jwtToken) {
      token = localStorage.getItem("jwtToken");
    }
    var Headconfig = {
      headers: { Authorization: "Bearer " + token },
    };
    var bodyParameters = {
      id,
      name,
      description,
      address,
      contactNo,
      date: new Date().toDateString(),
      time: new Date().toLocaleTimeString(),
      accountId: this.props.data.unique_name,
      accountType: this.props.data.role,
    };

    Axios.put(
      `${config.localhttp}/api/Suppliers/${id}`,
      bodyParameters,
      Headconfig
    )
      .then((response) => {
        console.log(response);

        if (response.status == 200) {
          var admindata = [...this.state.adminData];
          var index = admindata.findIndex((obj) => obj.id === id);
          admindata[index].name = name;
          admindata[index].description = description;
          admindata[index].contactNo = contactNo;
          admindata[index].address = address;
          this.setState({ adminData: admindata, enabled: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };

  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
    window.location.reload();
  };

  render() {
    const {
      adminData,
      modalData,
      sessions,
      pages,
      BindStudentDefineFees,
      BindDiscountFees
    } = this.state;
    console.log("Admin data: ", this.state.adminData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (let i = 1; i <= Math.ceil(pages.totalCount / pages.pageSize); i++) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <ThemeContext.Consumer>
          {(context) => (
            <li class={`page-item `}>
              <a
                key={number}
                id={number}
                onClick={() => this.paginationRequest(number)}
                class="page-link"
                href="#"
                style={{
                  background: `${
                    pages.currentPage === number
                      ? context.themeColors.primaryColor
                      : ""
                  }`,
                  color: `${
                    pages.currentPage === number
                      ? "#fff"
                      : context.themeColors.primaryColor
                  }`,
                }}
              >
                {number}
              </a>
            </li>
          )}
        </ThemeContext.Consumer>
      );
    });
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    // const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = BindStudentDefineFees.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.feeName}</td>
          <td>{item.fee}</td>
        </tr>
      );
    });
    const renderTodosTwo = BindDiscountFees.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.discountType}</td>
          <td>{item.feeName}</td>
          <td>{item.fee}</td>
          <td>{item.percentage}</td>
          <td>{item.discount}</td>
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  background: this.context.themeColors.primaryColor,
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  background: this.context.themeColors.primaryColor,
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="name">Name:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.name}
                              onChange={this.onHandleText}
                              name="name"
                              class="form-control"
                              id="name"
                            />
                          </div>
                          <div class="form-group">
                            <label for="description">Description:</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.contactNo}
                              onChange={this.onHandleText}
                              type="text"
                              name="contactNo"
                              class="form-control"
                              id="contactNo"
                            />
                          </div>
                          <div class="form-group">
                            <label for="address">Address:</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.address}
                              onChange={this.onHandleText}
                              type="text"
                              name="address"
                              class="form-control"
                              id="address"
                            />
                          </div>
                          <div class="form-group">
                            <label for="description">Description:</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.description}
                              onChange={this.onHandleText}
                              type="text"
                              name="description"
                              class="form-control"
                              id="description"
                            />
                          </div>
                        </fieldset>
                        <button
                          disabled={this.state.enabled}
                          onClick={() =>
                            this.setState({ enabled: true, password: "" })
                          }
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <img
            src={require("../../Assets/images/header.png")}
            style={{
              width: "100%",
              height: "150",
            }}
          />
        </div>
        {this.state.adminData.length > 0 ? (
          <div className="row border p-4 m-4">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <label>
                    Voucher#:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData && this.state.adminData[0].vId}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Session:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData && this.state.adminData[0].session}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Date:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData && this.state.adminData[0].date}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Student:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData &&
                        this.state.adminData[0].studentName}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Class:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData &&
                        this.state.adminData[0].className}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Section:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData && this.state.adminData[0].section}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Payment Type:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData &&
                        this.state.adminData[0].paymentType}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Payment Method:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData &&
                        this.state.adminData[0].paymentMethod}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Fee paid By:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData &&
                        this.state.adminData[0].feePaidBy}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Total:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData &&
                        this.state.adminData[0].total}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Month:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData &&
                        this.state.adminData[0].month}
                    </span>{" "}
                  </label>
                </div>
                <div className="col-md-4">
                  <label>
                    Status:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.adminData &&
                        this.state.adminData[0].status}
                    </span>{" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="table-responsive">
            <h4>Fee Detail: </h4>
          <ThemeContext.Consumer>
            {(context) => (
              <table
                class="table table-hover table-bordered"
                style={{ textAlign: "center" }}
              >
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr className="vendorListHeading">
                    <th scope="col">#</th>
                    <th scope="col">Fee Name</th>
                    <th scope="col">Fee</th>
                  </tr>
                </thead>
                <tbody>{adminData.length > 0 ? renderTodos : null}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>

        <div className="table-responsive mt-5">
        <h4>Discount: </h4>
          <ThemeContext.Consumer>
            {(context) => (
              <table
                class="table table-hover table-bordered"
                style={{ textAlign: "center" }}
              >
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr className="vendorListHeading">
                    <th scope="col">#</th>
                    <th scope="col">Discount Type</th>
                    <th scope="col">Fee Name</th>
                    <th scope="col">Fee</th>
                    <th scope="col">Percentage</th>
                    <th scope="col">Discount</th>
                  </tr>
                </thead>
                <tbody>{adminData.length > 0 ? renderTodosTwo : null}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
    );
    return (
      <div>
        <div id="div1">
          <div className="page-header">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h2
                  style={{ background: this.context.themeColors.primaryColor }}
                  className="h1-responsive font-weight-bold text-center  generalHead"
                >
                  Voucher Details
                </h2>
              </div>
              <nav aria-label="Page navigation example" className="no-print">
                <ul
                  style={{ color: this.context.themeColors.primaryColor }}
                  class="pagination"
                >
                  {/* <li
                    class={`page-item ${
                      this.state.disableBPage ? "disabled" : ""
                    }`}
                  >
                    <a
                      onClick={this.handleClickNextBack}
                      class="page-link"
                      href="#"
                    >
                      {this.state.disableBPage ? (
                        <i
                          style={{ color: "grey" }}
                          class="far fa-stop-circle"
                        ></i>
                      ) : (
                        <i
                          style={{ color: "grey" }}
                          class="fas fa-backward"
                        ></i>
                      )}
                    </a>
                  </li> */}
                  {renderPageNumbers}
                  {/* <li
                    class={`page-item ${
                      this.state.disablepage ? "disabled" : ""
                    }`}
                  >
                    <a
                      onClick={this.handleClickNextFrwd}
                      class="page-link"
                      href="#"
                    >
                      {this.state.disablepage ? (
                        <i
                          style={{ color: "grey" }}
                          class="far fa-stop-circle"
                        ></i>
                      ) : (
                        <i style={{ color: "grey" }} class="fas fa-forward"></i>
                      )}
                    </a>
                  </li> */}
                </ul>
              </nav>
            </div>
          </div>
          {/* <div className="row justify-content-between no-print">
            <div className="col-md-3"></div>
            <div class="col-md-2">
              <div class="md-form mb-0">
                <label for="email" class="">
                  Entries
                </label>
                <select
                  onChange={(e) =>
                    this.setState({
                      todosPerPage: e.target.value,
                    })
                  }
                  class="custom-select"
                  name="CategoryName"
                >
                  <option selected={this.state.msg ? true : false} value="10">
                    {" "}
                    Select Entries
                  </option>
                  <option value={this.state.adminData.length}>
                    All Entries
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  ))
                </select>
              </div>
            </div>
          </div> */}

          {this.state.isLoading ? Loader : MainContent}
          <div class="page-footer">
            <div class="text-left">Printed By: {this.props.data.role}</div>
            <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
              Powered by School Smart®
            </div>
          </div>
        </div>
        <div
          class="text-center text-md-right displayElemet"
          style={{ marginBottom: 10 }}
        >
          <label for="name" class="" onClick={() => this.PrintContent("div1")}>
            <i
              className="fas fa-print fa-2x animated fadeIn"
              style={{ marginRight: 4 }}
            />
            Print
          </label>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    data: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, null)(VoucherDetails);

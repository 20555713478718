import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";


const FormDate = [{
    id: 1,
    question: 'Ability to understand, process and implement information introduced',
},{
    id: 2,
    question: 'Ideas generation and developing generic research activities'
},{
    id: 3,
    question: 'Creativity and critical thinking'
},{
    id: 4,
    question: 'Proficient at learning in arange of ways, including through information technologies'
},{
    id: 5,
    question: 'Tasks managment and process documentation'
},{
    id: 6,
    question: 'Knowledge of practical and technical consendirations related to tasks/projects'
},{
    id: 7,
    question: 'Attendance'
},{
    id: 8,
    question: 'Classroom interactivity'
},{
    id: 9,
    question: 'Ability of working and productivity under stress'
},{
    id: 10,
    question: 'Performance and ability of working within teamwork'
}, {
    id: 11,
    question: 'Artwork otcomes and presentations quality'
}, {
    id: 12,
    question: 'Communication and language skills'
},{
    id: 13,
    question: 'Meeting with dead lines'
},{
    id: 14,
    question: 'Interest in the course'
}]



class ViewReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            payslipData: [],
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            msg: '',
            backColor: '',
            editIndex: '',
            accountId: null,
            accountType: null, 


            
            reportData: [],
            classId: null,
            studentId: null,
            sectionId: null,
            subjectId: null,
            className: null,
            section: null,
            studentName: null,
            subjectName: null,
            staffId: null,
            staffName: null,
            status: null,
            comment1: null,
            comment2: null,
            comment3: null,
            comment4: null,
            comment5: null,
            comment6: null,
            comment7: null,
            comment8: null,
            comment9: null,
            comment10: null,
            comment11: null,
            comment12: null,
            comment13: null,
            comment14: null,
        }

        


    }

	static contextType = ThemeContext

    componentDidMount(){

        this.getSimple('Student', 'student')
        this.getSimple('classSectionGradeTeacher', 'classSectionGrade')


    }


	getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => {
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                isLoading: false
            })
            this.props.snackbar();
            console.error('Error occured');
        })
    }

   

    onEditClick = id => {
        const { reportData } = this.state
        let modalData = reportData.filter((items, index ) =>  {
            if(id === items.id){
                    
            this.setState({
                editIndex: index
            })
            console.log(index)
            return id === items.id 
            }
        })
        console.log(modalData)
        this.setState({
            id: modalData[0].id,
            classId: modalData[0].classId,
            className: modalData[0].className,
            sectionId: modalData[0].sectionId,
            section: modalData[0].section,
            studentId: modalData[0].studentId,
            studentName: modalData[0].studentName,
            subjectId: modalData[0].subjectId,
            subjectName: modalData[0].subjectName,
            staffId: modalData[0].staffId,
            staffName: modalData[0].staffName,
            comment1: modalData[0].comment1,
            comment2: modalData[0].comment2,
            comment3: modalData[0].comment3,
            comment4: modalData[0].comment4,
            comment5: modalData[0].comment5,
            comment6: modalData[0].comment6,
            comment7: modalData[0].comment7,
            comment8: modalData[0].comment8,
            comment9: modalData[0].comment9,
            comment10: modalData[0].comment10,
            comment11: modalData[0].comment11,
            comment12: modalData[0].comment12,
            comment13: modalData[0].comment13,
            comment14: modalData[0].comment14,
            date: modalData[0].date,
            time: modalData[0].time,
            status: modalData[0].status,
            accountId: modalData[0].accountId,
            accountType: modalData[0].accountType,
            
        }, () => {
            $('#reportModal').modal({
                show: true
            })
        })
    }

    
    

    

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
    };
    
    handleSubmit = e => {
        e.preventDefault();
        console.log('Submit Called')

        const {  
            id,
            classId,
            className,
            sectionId,
            section,
            studentId,
            studentName,
            subjectId,
            subjectName,
            staffId,
            staffName,
            comment1,
            comment2,
            comment3,
            comment4,
            comment5,
            comment6,
            comment7,
            comment8,
            comment9,
            comment10,
            comment11,
            comment12,
            comment13,
            comment14,
            date,
            time,
            status,
            accountId,
            accountType,  
        } = this.state;

        let data = {
            id,
            classId,
            className,
            sectionId,
            section,
            studentId,
            studentName,
            subjectId,
            subjectName,
            staffId,
            staffName,
            comment1,
            comment2,
            comment3,
            comment4,
            comment5,
            comment6,
            comment7,
            comment8,
            comment9,
            comment10,
            comment11,
            comment12,
            comment13,
            comment14,
            date,
            time,
            status,
            accountId,
            accountType,  
        }

        console.log('data model', data)
        this.props.sharedActions.editRecordWithoutDispatch(
            '/api/StudentProgressTrackReport/',
            id,
            data
        ).then(success => {
            console.log('success', success)
            this.state.reportData.splice(this.state.editIndex, 1, data)
            
                
                
            this.setState({
                msg: 'Record Changed Successfully!',
                editToggle: false
                
            })
            this.props.snackbar();
            $('#reportModal').modal('hide')
            

        }).catch(error => {
            console.error('Error name: ', error)
                
                $('#reportModal').modal('hide')
            this.setState({
                msg: error.response ? error.response.data.Message : 'Error Occured!!',
                editToggle: false
            })
            this.props.snackbar();


        })
    }


    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value);
        
    };
    
    checkBook = (studentId) => {
        const { student } = this.state;
        let f = student.student.filter((data) => {
            return data.studentId == studentId
        })

        if(f.length > 0 ){
            this.setState({
                classId: f[0].classId,
                sectionId: f[0].sectionId,
                msg: 'Student Found!!',
                successMsg: true
            })
        }else{
            this.setState({
                msg: 'Student Not Found!!',
                errorMsg: true,
            })
        }
        

    }


    render(){

        const { isLoading, editToggle, msg,
            student, classSectionGrade, 
            reportData,
            comment1,
            comment2,
            comment3,
            comment4,
            comment5,
            comment6,
            comment7,
            comment8,
            comment9,
            comment10,
            comment11,
            comment12,
            comment13,
            comment14,
            classId,
            sectionId,
            studentId,
            subjectId,
            staffId,
            accountId,
            accountType

 } = this.state;

        

 const renderFormData = FormDate.map((data, index) => {
    return (
    <div> <br/>
        <div className="row">
        <div className="col-md-12">
            <div className="md-form mb-0" >
                 <p class="text-justify" style={{color: 'black', fontWeight: 'bold'}} ><strong>{data.id}). </strong>{data.question}</p>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                    <input checked={eval(`comment${data.id}`) == 'Excellent'} disabled={!editToggle} type="radio" id={`Excellent${data.id}`} onChange={this.onHandleText} value='Excellent' name={`comment${data.id}`} class="custom-control-input"/>
                    <label class="custom-control-label" for={`Excellent${data.id}`}>Excellent</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                    <input checked={eval(`comment${data.id}`) == 'Very Good'} disabled={!editToggle} type="radio" id={`VeryGood${data.id}`} onChange={this.onHandleText} value='Very Good' name={`comment${data.id}`} class="custom-control-input"/>
                    <label class="custom-control-label" for={`VeryGood${data.id}`}>Very Good</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                    <input checked={eval(`comment${data.id}`) == 'Good'} disabled={!editToggle} type="radio" id={`Good${data.id}`} onChange={this.onHandleText} value='Good' name={`comment${data.id}`} class="custom-control-input"/>
                    <label class="custom-control-label" for={`Good${data.id}`}>Good</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                    <input checked={eval(`comment${data.id}`) == 'Average'} disabled={!editToggle} type="radio" id={`Average${data.id}`} onChange={this.onHandleText} value='Average' name={`comment${data.id}`} class="custom-control-input"/>
                    <label class="custom-control-label" for={`Average${data.id}`}>Average</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                    <input checked={eval(`comment${data.id}`) == 'Need More Work'} disabled={!editToggle} type="radio" id={`NeedMoreWork${data.id}`} onChange={this.onHandleText} value='Need More Work' name={`comment${data.id}`} class="custom-control-input"/>
                    <label class="custom-control-label" for={`NeedMoreWork${data.id}`}>Need More Work</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                    <input checked={eval(`comment${data.id}`) == 'Not Applicable'} disabled={!editToggle} type="radio" id={`NotApplicable${data.id}`} onChange={this.onHandleText} value='Not Applicable' name={`comment${data.id}`} class="custom-control-input"/>
                    <label class="custom-control-label" for={`NotApplicable${data.id}`}>Not Applicable</label>
            </div>
        </div>

    </div>
</div>
    );
})

const commentSection = (
    <div style={{
        border: `2px solid #5fe3e3`,
        borderRadius: '5px',
        padding: '5px'
    }}>
        <br/>
        {renderFormData}
        <br/>
        
    </div>
);

        const editReportData = (
            <div>
            <div className="modal" id="reportModal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content modal_content_custom">
                        <div
                            style={{
								background: this.context.themeColors.primaryColor,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                borderTopLeftRadius: 18,
                                borderTopRightRadius: 18
                            }}
                            className="modal-header modal_custom_header"
                        >
                            <h4 style={{ color: 'white' }} className="modal-title">
                                Edit
                            </h4>
                            <button
                                style={{ color: 'white' }}
                                on
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleSubmit} >
                            <fieldset disabled={!editToggle}>
                            <div className="row">
                                    <div className="col-md-6">
                                <div className="form-group">
                                <label for="accountId">Account Name:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountId}
                                name="modalDataAccountId"
                                className="form-control"
                                id="accountId"
                                />
                                </div>
                                     </div>
                                    <div className="col-md-6">

                                <div className="form-group">
                                <label for="accountType">Account Type:</label>
                                <input style={{
                                    borderTop: 0,
                                    borderRight: 0,
                                    borderLeft: 0
                                }}
                                type="text"
                                disabled
                                value={accountType}
                                name="modalDataAccountType"
                                className="form-control"
                                id="accountType"
                                />
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="col-md-6">

                                <div className="form-group">
                                <label for="classId">
                                                Class
                                            </label>
                                            <select class="custom-select" 
                                                value={classId}
                                                name="classId"
                                                onChange={this.onHandleText}
                                                disabled


                                            >
                                                        <option value="">Please Select Class</option>
                                                       {student && student.classes.map((value, index) => {


                                                                return <option value={value.classId}>{value.className}</option>

                                                       })}
                                            </select>
                                </div>
                                </div>
                                <div className="col-md-6">

                                <div className="form-group">
                                <label for="sectionId">
                                                Section
                                            </label>
                                            <select class="custom-select" 
                                                value={sectionId}
                                                name="sectionId"
                                                onChange={this.onHandleText}
                                                disabled
                                            >
                                                        <option value="">Please Select Section</option>
                                                       {student && student.sections.map((value, index) => {
                                                           
                                                           return  value.classId == classId && 
                                                           <option value={value.sectionId}>{value.section}</option>
                                                       })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                
                            <div className="row">
                                    <div className="col-md-6">

                                <div className="form-group">
                                <label for="studentId">
                                                Student
                                            </label>
                                            <select class="custom-select" 
                                                value={studentId}
                                                name="studentId"
                                                onChange={this.onHandleText}
                                                disabled

                                            >
                                                        <option value="">Please Select Student</option>
                                                        {student && student.student.map((value, index) => {
                                                           
                                                           return  value.classId == classId && value.sectionId == sectionId &&
                                                           <option value={value.studentId}>{value.Student}</option>
                                                       })}
                                            </select>
                                </div>
                                </div>
                                <div className="col-md-6">

                                <div className="form-group">
                                <label for="subjectId">
                                                Subject
                                            </label>
                                            <select class="custom-select" 
                                                value={subjectId}
                                                name="subjectId"
                                                onChange={this.onHandleText}
                                                disabled

                                            >
                                                        <option value="">Please Select Subject</option>
                                                        {classSectionGrade && classSectionGrade.subject.map((value, index) => {
                                                           
                                                           return  value.classId == classId && 
                                                           <option value={value.subjectId}>{value.subjectName}</option>
                                                       })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">

                                <div className="form-group">
                                <label for="staffId">
                                                Teacher
                                            </label>
                                            <select class="custom-select" 
                                                value={staffId}
                                                name="staffId"
                                                onChange={this.onHandleText}
                                                disabled

                                            >
                                                        <option value="">Please Select Teacher</option>
                                                        {classSectionGrade && classSectionGrade.staff.map((value, index) => {
                                                                        
                                                                        return  value.classId == classId && value.sectionId == sectionId && value.subjectId == subjectId && 
                                                                        <option value={value.staffId}>{value.staffName}</option>
                                                                    })}
                                            </select>
                                </div>
                                </div>
                                </div>
                                <div className="form-group">
                                                       {commentSection}
                                </div>
                            </fieldset>
                            <br />
							<br />
								<button
									disabled={editToggle}
									onClick={() => this.setState({ editToggle: true })}
									type="button"
									class="btn btn-primary buttonAppear"
									>
								    	Edit
									</button>
									<button
										style={{ marginLeft: 5 }}
										disabled={!editToggle}
										type="submit"
										class="btn btn-primary buttonAppear"
										>
										Save
									</button>
                            </form>
                        </div>
                        <div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    onClick={() => this.setState({editToggle: false})}
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
                    </div>
                </div>
            </div>
            </div>                 
        )

        const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
			</div>
        );
        
        
        const renderReportData = reportData.map((item, index) => {
            return (

            <tr key={index} >
                <th scope="row" className="borderSpace" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.studentName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.className}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.section}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.subjectName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.staffName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.status}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                <a href="#" data-toggle="tooltip" title="Edit!">
                            
                            <button
								id="firstbutton"
                                onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</a>
                        <Link to={{
                        pathname: "PrintStudentProgressReport",
                        state: {item: item}
                    }}  >
                        <a href="#" data-toggle="tooltip" title="Edit!">
                            
                            <button
								id="secondbutton"
								onMouseOver={this.btnTechHover()}
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i className="fas fa-list"></i>
							</button>
						</a> 
                        
                    </Link>
							
                </td>
                
            </tr>
            
            );
        }) 

        return (
        <div>
            <section  className="mb-4">
            <h2  
                    style={{ background: this.context.themeColors.primaryColor }}
                    className="h1-responsive font-weight-bold text-center  generalHead">
                    View Student Progress Report
                </h2>
        <div>
            <SnackBar msg={msg} />
                <br/>
                    <div class="row my-3 mx-5 py-4" style={{
                        border: `2px solid ${this.context.themeColors.primaryColor}`,
                        borderRadius: 20
                    }} >
                        <div className="col-md-4">
                                        <div className="input-group md-form mb-3" >
                                            
                                            <input class="form-control" 
                                                type='text'
                                                value={studentId}
                                                name="studentId"
                                                onChange={this.onHandleText}
                                                placeholder="Student ID"
                                                disabled={!student && !classSectionGrade}
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" 
                                                disabled={!studentId && !student && !classSectionGrade}
                                                onClick={() => this.checkBook(studentId)}
                                                type="button">
                                                    <i class="fas fa-search" ></i>
                                                    </button>
                                            </div>
                                        </div>
                                        </div>

                         <div class="col-md-4 align-right">
                                    <select class="form-control mr-sm-2" 
                                        name="classId" 
                                        onChange={this.onHandleText}
                                        value={classId}
                                        disabled={!student}
                                         >
                                            
                                            <option value="">Please Select Class</option>
                                            {student && student.classes.map((value, index) => {
                                                return <option value={value.classId}>{value.className}</option>
                                            })}
    
                                        </select>
                                    
                        </div>
                        <div class="col-md-4 align-right" style={{marginBottom: '1%'}} >
                                    <select class="form-control mr-sm-2" 
                                        name="sectionId" 
                                        onChange={this.onHandleText}
                                        value={sectionId}
                                        disabled={!classId}
                                        >
    
                                                        <option value="">Please Select Section</option>
                                                       {student && student.sections.map((value, index) => {
                                                           
                                                           return  value.classId == classId && 
                                                           <option value={value.sectionId}>{value.section}</option>
                                                       })}

                                        </select>
                                    
                        </div>
                        <div class="col-md-6 align-right">
                                    <select class="form-control mr-sm-2" 
                                        name="studentId" 
                                        onChange={this.onHandleText}
                                        value={studentId}
                                        disabled={!sectionId}
                                        >
                                                        
                                                        <option value="">Please Select Student</option>
                                                        {student && student.student.map((value, index) => {
                                                           
                                                           return  value.classId == classId && value.sectionId == sectionId &&
                                                           <option value={value.studentId}>{value.Student}</option>
                                                       })}
    
                                        </select>
                                    
                        </div>
                        <div class="col-md-6 align-right">
                                    <select class="form-control mr-sm-2" 
                                        name="subjectId" 
                                        onChange={this.onHandleText}
                                        value={subjectId}
                                        disabled={!sectionId}
                                        >
    
                                                        <option value="">Please Select Subject</option>
                                                        {classSectionGrade && classSectionGrade.subject.map((value, index) => {
                                                           
                                                           return  value.classId == classId && 
                                                           <option value={value.subjectId}>{value.subjectName}</option>
                                                       })}
    
                                        </select>
                                    
                        </div>
                    </div>
                    <div class="row my-2">
                        <div class="col-md-12  text-center" >
                        <button class="btn btn-primary buttonAppear" 
                                disabled={!subjectId}
                                onClick={() => {
                                    this.state.reportData.length = 0
                                    this.getSimple(`/StudentProgressTrackReport/?studentId=${studentId}&classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}`, 'reportData')
                                }}
                            >
                        <i class="fa fa-search"></i> Search</button>
                        </div>
                    </div>
                    <br/>
                    <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                        <div class="col-md-3" >
                                <h5>Class: <strong style={{color: 'teal'}} >{reportData.length > 0 && reportData[0].className}</strong></h5>     
                        </div>
                        <div class="col-md-3" >
                                <h5>Section: <strong style={{color: 'teal'}} >{reportData.length > 0 && reportData[0].section}</strong></h5>     
                        </div>
                        <div class="col-md-3" >
                                <h5>Student: <strong style={{color: 'teal'}} >{reportData.length > 0 && reportData[0].studentName}</strong></h5>     
                        </div>
                        <div class="col-md-3 "  >
                                <h5>Subject: <strong style={{color: 'teal'}} >{reportData.length > 0 && reportData[0].subjectName}</strong></h5>     
                        </div>
                    </div>
                    
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Acoount Role
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Section
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Subject
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Teacher
								</th>
                                <th scope="col" style={{ padding: 1 }}>
									Status
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Actions
								</th>
                                

                                
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderReportData}</tbody>
					</table>
				</div>
                </div>
                {editReportData}
                </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewReport);
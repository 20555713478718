import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SkillAction from "../../Actions/StudentSkill";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import DropDownsForData from "../DescriptiveReportModule/TermClassSubjectSelect";
import { Link } from "react-router-dom";
import ThemeContext from "../../context/themeContext/ThemeContext";

import $ from "jquery";
// import TableComponent from "./TableComponent";
class StudentSkillViewAdmin extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      SkillReports: [],
      adminData: [],
      modalData: null,
      vehicleNo: "",
      capacity: "",
      transportType: "",
      enabled: false,
      id: null,
      select: [],
      efforts: "",
      participation: "",
      average: "",
      academicProgress: "",
      studentName: "",
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      increament: 1,
      selectClassData: "",
      classId: "",
      sectionId: "",
      sections: [],
      subjects: [],
      session: [],
      sessionId: "",
      subjectId: "",
      staffId: "",
      term: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      multiple: [],
      errorText: "",
      badRequest: false,
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.adminData !== nextProps.AllTransportData ||
      prevState.selectClassData !== nextProps.selectData ||
      prevState.session !== nextProps.SessionSelect
    ) {
      return {
        adminData: nextProps.AllTransportData,
        selectClassData: nextProps.selectData,
        session: nextProps.SessionSelect,
      };
    }
    return null;
  }
  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    const sections = this.state.selectClassData.sections;
    const subs = this.state.selectClassData.subject;
    let check = sections.filter((item) => item.classId == result.classId);
    console.log(check);

    this.setState({
      sections: check,
      staffId: check[0].staffId,
      staffName: check[0].staffName,
      classId: result.classId,
      className: result.className,
    });
    let checksub = subs.filter((item) => item.classId == result.classId);
    this.setState({
      subjects: checksub,
    });
  };
  onSectionChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    const sections = this.state.selectClassData.subject;
    let check2 = sections.filter((item) => item.classId == this.state.classId);
    this.setState({
      sectionId: result.sectionId,
      sectionName: result.section,
    });
    console.log(this.state.staffId);
  };
  onSessionChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    this.setState({
      sessionId: result.sessionId,
      sessionName: result.currentSession,
    });
    console.log(this.state);
  };
  onSubjectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    const Teachers = this.state.selectClassData.staff;
    let check2 = Teachers.filter(
      (item) =>
        item.classId == this.state.classId &&
        item.sectionId == this.state.sectionId &&
        item.subjectId == result.subjectId
    );
    this.setState({
      staffName: check2[0].staffName,
      subjectId: result.subjectId,
      subjectName: result.subjectName,
    });
  };
  componentDidUpdate() {
    $(".tooltip").tooltip("hide");

    if (
      this.state.msg ||
      this.state.errorMsg ||
      this.state.exist ||
      this.state.badRequest
    ) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          exist: false,
          badRequest: false,
        });
      }, 3000);
    }
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .gAllData(
        `/api/classSectionGradeTeacher`,
        this.props.SkillAction.getClassSectionSubjectForStudentSkill
      )
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.sharedActions
      .gAllData("/api/Session", this.props.SkillAction.getSessionsForSkills)
      .then((success) => {})
      .catch((error) => {});
    // this.props.sharedActions.getAllData(`/api/Assignments/getassign/Student/?Classid=0&secid=0`, this.props.AssignmentActions.getParticularAssignment, this.props.AssignmentActions.updateAssignmentFlag)
    //     .then((success) => {
    //
    //         this.setState({
    //             isLoading: false,
    //         })
    //     }).catch((err) => {
    //         this.props.AssignmentActions.updateAssignmentFlag(false);

    //         this.setState({
    //             isLoading: false
    //         });
    //
    //         console.log(err);
    //     })
  }
  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    this.props.sharedActions
      .getAllData(
        `/api/studentSkillView/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&term=${this.state.term}&sessionId=${this.state.sessionId}&subjectId=${this.state.subjectId}`,
        this.props.SkillAction.setAllSkillsViewInRedux,
        this.props.SkillAction.updateSkillFlagView
      )
      .then((success) => {
        this.setState({
          isLoading: false,
          btnLoad: false,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.props.SkillAction.updateSkillFlagView(true);

        this.setState({
          errorText: err.response.data.Message,
          isLoading: false,
          btnLoad: false,
          searchCheck: true,
          badRequest: true,
        });

        console.log(err);
      });
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/Vehicle/",
        itemId,
        this.props.routesActions.deleteVehicle
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.skillReportId === itemId);

    this.setState({
      modalData: getData,
      efforts: getData[0].efforts,
      average: getData[0].average,
      academicProgress: getData[0].academicProgress,
      id: getData[0].skillReportId,
      studentName: getData[0].stduentName,
      participation: getData[0].participation,
    });
  };

  onHandleTextNumber = (e) => {
    console.log(e.target.value);
    if (e.target.value <= 5) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    console.log(this.state.effort);
  };
  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { efforts, participation, academicProgress, id } = this.state;
    let data = { id, efforts, participation, academicProgress };

    this.props.sharedActions
      .editPatchRecord(
        "/api/editSkillReport/?id=",
        id,
        data,
        this.props.SkillAction.updateSkill
      )
      .then((success) => {
        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        // this.props.campusActions.updateCampusFlag(true);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(this.state);
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    if (e.target.value <= 5) {
      const changeArrOfObj = this.state[`${arrName}`];
      changeArrOfObj[index][e.target.name] = e.target.value;
      this.setState({
        [arrName]: changeArrOfObj,
      });
    }
  };
  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangingData = (index, event) => {
    let dataStrcuture = [
      {
        studentId: 1,
        studentName: "Muhammad Hamza",
        effort: "",
        average: "",
        participation: "",
      },
    ];

    const { multiple } = this.state;
    var check = [...multiple];
    check[index][event.target.name] = event.target.value;
    this.setState({ multiple: check });
  };
  PrintContent = (el) => {
    //     var printW = window.open("");
    // var html = "<div>Hello World";
    // html += "</div>";

    // printW.document.write(html);
    // printW.document.close();
    // printW.focus();
    // printW.print();

    // var toPrint = document.getElementById(el);

    // var popupWin = window.open('', '_blank');

    // popupWin.document.open();

    // popupWin.document.write('<html><title>::Preview::</title><link rel="stylesheet" type="text/css" href="Form.css" /></head><body onload="window.print()">')

    // popupWin.document.write(toPrint.innerHTML);

    // popupWin.document.write('</html>');

    // popupWin.document.close();

    // var myWindow = window.open("", "");
    // var Printcontent = document.getElementById(el).innerHTML;
    // document.body.innerHTML = Printcontent;
    // myWindow.document.write(document.body.innerHTML);
    // myWindow.focus();

    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
  };
  render() {
    const theme = this.context;
    const {
      adminData,
      modalData,
      classId,
      sectionId,
      sessionId,
      subjectId,
      term,
    } = this.state;
    console.log("Daata", this.state.SkillReports);
    console.log("Admin Data", this.state.adminData);
    console.log("Classes Ka Data", this.state.selectClassData);
    console.log("Session Ka Data", this.state.session);
    const { session } = this.state.session;
    const { classes } = this.state.selectClassData;
    // const { studentEnuiry } = this.state.adminData
    // const { paginationMetadata } = this.state.adminData
    // console.log("Pagination Ka Data", paginationMetadata);
    // console.log("Pagination Checking Ka Data", this.state.checking);

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <label style={{ color: "red" }}>No Data</label>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          class={`page-item ${
            this.state.checking.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={() => this.paginationRequest(number)}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      );
    });
    // const { currentPage, todosPerPage } = this.state;
    // const indexOfLastTodo = currentPage * todosPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    // const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = adminData.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row" className="bordersSpace" style={{ padding: 2 }}>
            {index + 1}
          </th>
          <td className="bordersSpace" style={{ padding: 2 }}>
            {item.stduentName}
          </td>
          <td className="bordersSpace" style={{ padding: 2 }}>
            {item.efforts}
          </td>
          <td className="bordersSpace" style={{ padding: 2 }}>
            {item.participation}
          </td>
          <td className="bordersSpace" style={{ padding: 2 }}>
            {item.academicProgress}
          </td>
          <td className="bordersSpace" style={{ padding: 2 }}>
            {item.average}
          </td>
          <td className="bordersSpace no-print">
            <a href="#" data-toggle="tooltip" title="Edit!">
              <button
                id="firstbutton"
                onClick={() => this.onEditClick(item.skillReportId)}
                onMouseOver={this.btnTechHover()}
                data-toggle="modal"
                data-target="#myModal"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i class="fas fa-pen"></i>
              </button>
            </a>
            <Link
              to={`/dashboard/PrintSkillReport/${item.studentId}/${item.classId}/${item.sectionId}/${item.term}/${item.sessionId}`}
            >
              <a id="tooltipId" data-toggle="tooltip" title="Print Report">
                <button
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    background: "none",
                    marginLeft: 5,
                    marginBottom: 5,
                  }}
                >
                  <i
                    style={{ color: theme.themeColors.primaryColor }}
                    class="fas fa-list"
                  ></i>
                </button>
              </a>
            </Link>
          </td>
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div id="div1">
          <div class="divclass">
            <img src={require("../../Assets/images/3 headers-03.png")} />
          </div>
          <br />
          <div class="colCheckb">
            <div class="container">
              <div class="row justify-content-between">
                <div class="col-md-4">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Term: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {this.state.term}
                    </label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Class: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {this.state.className}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col-md-4">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Session: &nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {this.state.sessionName}
                    </label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Section: &nbsp;&nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {this.state.sectionName}
                    </label>
                  </div>
                </div>
              </div>

              <div class="row justify-content-between">
                <div class="col-md-4">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Staff: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {this.state.staffName}
                    </label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="md-form mb-0">
                    <label for="name" class="">
                      Subject: &nbsp;&nbsp;
                    </label>
                    <label
                      for="name"
                      class=""
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "#01425e",
                      }}
                    >
                      {this.state.subjectName}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="modal" id="myModal1">
            <div class="modal-dialog">
              <div class="modal-content modal_content_custom">
                <div
                  style={{
                    borderBottomLeftRadius: 30,
                    borderBottomRightRadius: 30,
                    borderTopLeftRadius: 18,
                    borderTopRightRadius: 18,
                  }}
                  class="modal-header modal_custom_header"
                >
                  <h4 style={{ color: "white" }} class="modal-title">
                    Confirm
                  </h4>
                  <button
                    style={{ color: "white" }}
                    type="button"
                    class="close"
                    data-dismiss="modal"
                  >
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <div style={{ textAlign: "center" }}>
                          <h4>Are you sure ?</h4>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div>
                            <button
                              disabled={this.state.enabled}
                              data-dismiss="modal"
                              onClick={() =>
                                this.onTakeItemDelete(this.state.id)
                              }
                              type="button"
                              class="btn btn-primary buttonAppear"
                            >
                              Yes
                            </button>
                          </div>
                          <div>
                            <button
                              data-dismiss="modal"
                              disabled={this.state.enabled}
                              type="button"
                              class="btn btn-primary buttonAppear"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div class="modal" id="myModal">
            <div class="modal-dialog">
              <div class="modal-content modal_content_custom">
                <div
                  style={{
                    borderBottomLeftRadius: 30,
                    borderBottomRightRadius: 30,
                    borderTopLeftRadius: 18,
                    borderTopRightRadius: 18,
                  }}
                  class="modal-header modal_custom_header"
                >
                  <h4 style={{ color: "white" }} class="modal-title">
                    Details
                  </h4>
                  <button
                    style={{ color: "white" }}
                    type="button"
                    class="close"
                    data-dismiss="modal"
                  >
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  <form onSubmit={this.onHandleSubmit}>
                    {modalData &&
                      modalData.map((item, index) => (
                        <div key={index}>
                          <fieldset disabled={!this.state.enabled}>
                            <div class="form-group">
                              <label for="campusName">Student Name:</label>
                              <input
                                readOnly={true}
                                style={{
                                  borderTop: 0,
                                  borderLeft: 0,
                                  borderRight: 0,
                                  boxShadow: "none",
                                }}
                                type="text"
                                value={this.state.studentName}
                                onChange={this.onHandleText}
                                name="campusName"
                                class="form-control"
                                id="campusName"
                              />
                            </div>
                            <div class="form-group">
                              <label for="Campus City">Effort</label>
                              <input
                                required
                                style={{
                                  borderTop: 0,
                                  borderLeft: 0,
                                  borderRight: 0,
                                  boxShadow: "none",
                                }}
                                value={this.state.efforts}
                                onChange={this.onHandleTextNumber}
                                type="number"
                                name="efforts"
                                class="form-control"
                                id="campusCity"
                              />
                            </div>
                            <div class="form-group">
                              <label for="Campus Address">Participation</label>
                              <input
                                required
                                style={{
                                  borderTop: 0,
                                  borderLeft: 0,
                                  borderRight: 0,
                                  boxShadow: "none",
                                }}
                                value={this.state.participation}
                                onChange={this.onHandleTextNumber}
                                type="number"
                                name="participation"
                                class="form-control"
                                id="campusAddress"
                              />
                            </div>
                            <div class="form-group">
                              <label for="Campus Phone">
                                Academic Progress
                              </label>
                              <input
                                required
                                style={{
                                  borderTop: 0,
                                  borderLeft: 0,
                                  borderRight: 0,
                                  boxShadow: "none",
                                }}
                                value={this.state.academicProgress}
                                onChange={this.onHandleTextNumber}
                                type="number"
                                name="academicProgress"
                                class="form-control"
                                id="campusPhoneNumber"
                              />
                            </div>
                          </fieldset>
                          <button
                            disabled={this.state.enabled}
                            onClick={() => this.setState({ enabled: true })}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Edit
                          </button>
                          <button
                            style={{ marginLeft: 5 }}
                            disabled={!this.state.enabled}
                            type="submit"
                            class="btn btn-primary buttonAppear"
                          >
                            Save
                          </button>
                        </div>
                      ))}
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    style={{ background: "#00435D" }}
                    class="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table class="table table-hover text-center">
              <thead
                style={{
                  background: theme.themeColors.primaryColor,
                  color: "white",
                }}
                class="bordersSpace"
              >
                <tr class="vendorListHeading">
                  <th class="bordersSpace" style={{ padding: 2 }}>
                    #
                  </th>
                  <th class="bordersSpace" style={{ padding: 2 }}>
                    Student Name
                  </th>
                  <th class="bordersSpace" style={{ padding: 2 }}>
                    Efforts
                  </th>
                  <th class="bordersSpace" style={{ padding: 2 }}>
                    Participation
                  </th>
                  <th class="bordersSpace" style={{ padding: 2 }}>
                    Academic Progress
                  </th>
                  <th class="bordersSpace" style={{ padding: 2 }}>
                    Average
                  </th>
                  <th
                    class="bordersSpace"
                    class="no-print"
                    style={{ padding: 2 }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>{renderTodos}</tbody>
            </table>
          </div>
          {this.state.errorMsg ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Grading Marks Already Exist For This Term
            </p>
          ) : (
            ""
          )}
          {this.state.msg ? (
            <p style={{ color: "green", textAlign: "center" }}>
              Added Successfully!
            </p>
          ) : (
            ""
          )}
          {this.state.exist ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Please Fill the Fields
            </p>
          ) : (
            ""
          )}
          {/* <MultipleSkills onHandleTextChange={this.onChangeText}
											stateData={this.state}
											updateSpecialArrObjOnChange={
												this.updateSpecialArrObjOnChange
											}
											onClickAddItem={this.onClickAddItem}
                                            onClickDeleteItem={this.onClickDeleteItem}
                                            onChanging={this.onChangingData}
                                            session={this.state.session}
                                            />
                                             <div class="text-center text-md-right">
                    <button type="submit" class="btnss" onClick={this.onHandleSubmit}>
                        Submit
                                        <span style={{ marginBottom: 5 }} class={`${this.state.btnLoad ? 'spinner-border spinner-border-sm' : ''}`}></span>
                    </button>
                </div> */}
        </div>
        <div class="text-center text-md-right">
          <label for="name" class="" onClick={() => this.PrintContent("div1")}>
            <i
              className="fas fa-print fa-2x animated fadeIn"
              style={{ marginRight: 4 }}
            />
            Print It Out
          </label>
        </div>
      </div>
    );
    return (
      <div>
        {this.state.badRequest ? (
          <p style={{ color: "red", textAlign: "center" }}>
            {this.state.errorText}
          </p>
        ) : (
          ""
        )}
        <div className="page-header">
          <SnackBar msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div>
              <h3
                style={{ background: theme.themeColors.primaryColor }}
                class="generalHead"
              >
                View Student Skills
              </h3>
            </div>
            <nav aria-label="Page navigation example">
              <ul
                style={{ color: theme.themeColors.primaryColor }}
                class="pagination"
              >
                {/* {renderPageNumbers} */}
              </ul>
            </nav>
          </div>
        </div>
        <DropDownsForData
          onChangeDrop={this.onSelectChange}
          onSectionDrop={this.onSectionChange}
          onSubjectDrop={this.onSubjectChange}
          onSessionDrop={this.onSessionChange}
          onHandleChange={this.onHandleText}
          ClassData={classes}
          SessionData={session}
          SectionData={this.state.sections}
          SubjectData={this.state.subjects}
          flag={this.state.msg}
        />
        <br />
        <div class="col-md-12 text-center">
          <button
            type="submit"
            disabled={
              classId !== "" &&
              sessionId !== "" &&
              sectionId !== "" &&
              term !== "" &&
              subjectId !== ""
                ? false
                : true
            }
            style={{
              backgroundColor:
                classId !== "" &&
                sessionId !== "" &&
                sectionId !== "" &&
                term !== "" &&
                subjectId !== ""
                  ? theme.themeColors.primaryColor
                  : theme.themeColors.secondryColor,
              border: "none",
              fontSize: 20,
              borderRadius: 4,
              color: "white",
            }}
            onClick={this.onSearchStudent}
          >
            Search
            <i
              style={{ color: "white", marginLeft: 4 }}
              class="fas fa-search"
            ></i>
            <span
              style={{ marginBottom: 5 }}
              class={`${
                this.state.btnLoad ? "spinner-border spinner-border-sm" : ""
              }`}
            ></span>
          </button>
        </div>
        <br />
        {this.props.allTransportState.apiFlagAll ? Loader : MainContent}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SkillAction: bindActionCreators(SkillAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    AllTransportData: state.setStudentSkillReducer.DataP,
    selectData: state.setStudentSkillReducer.selectStudent,
    SessionSelect: state.setStudentSkillReducer.selectSession,
    allTransportState: state.setStudentSkillReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentSkillViewAdmin);

import React, { Component } from 'react';
import GeneralRadioOptionSelection from '../../components/Admission/GeneralRadioOptionSelection';
import Axios from 'axios';
import DateandTime from './../Transport/DateandTime';
import './../Admission/studentDocument.css';
import GeneralFileUploadOption from '../../components/Admission/GeneralFileUploadOption';
import SpecificFileUpload from '../../components/Admission/SpecificFileUpload';
export default class StudentDocument extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gridNum: 4
		};
	}
	fileUpload(files, files1) {
		let data = new FormData();
		// data.append('profession',this.state.profession);
		// data.append('name',this.state.name);
		// data.append('gender',this.state.gender);
		data.append('filePath', files, files.name);
		data.append('filePath1', files1, files1.name);

		console.log(data);

		// data.append('voterProfileDataId',this.props.getUserOriginalId);
		let token = '';
		if (localStorage.jwtToken) {
			token += localStorage.getItem('jwtToken') + token;
		}
		var Headconfig = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: 'Bearer ' + token
			}
		};
		// var bodyParameters = { data }
	//	`http://www.schoolsmart.somee.com/api/StudentDocument`
		Axios.post(
			`http://www.schoolsmart.org.uk/api/StudentDocument`,
			data,
			Headconfig
		)
			.then(res => {
				console.log('response', res);
			})
			.catch(err => {
				console.log('err', err);
			});
	}
	onSubmit = () => {
		const { stateData } = this.props;
		this.fileUpload(stateData.filePath, stateData.filePath1);
	};
	render() {
		const {
			onHandleTextChange,
			stateData,
			fileOnChange,
			onCustomRadioStudentDocs
		} = this.props;

		return (
			<div class="col-md-9 mb-md-0 mb-5">
				<DateandTime />
				<form
					id="contact-form"
					name="contact-form"
					// onSubmit={this.onHandleSubmit}
					noValidate
				>
					{/*<div class="row">*/}
					{/*<div class="col-md-12">*/}
					{/*<div class="md-form mb-0">*/}
					{/*/!*<DateandTime/>*!/*/}
					{/*</div>*/}
					{/*</div>*/}
					{/*</div>*/}
					<br />
					<div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div
										style={{
											paddingBottom: 30,
											paddingRight: 30,
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										<SpecificFileUpload
											onHandleTextChange={onCustomRadioStudentDocs}
											stateData={stateData}
											Name="Date Of Birth Certificate:"
											idForYes="customRadiodobC"
											idForNo="customRadiodob"
											feildName="dobCertificate"
											uploadName="filePath"
										/>
										<GeneralFileUploadOption
											name="filePath"
											feildName={'dobCertificate'}
											fileOnChange={fileOnChange}
											stateData={stateData}
										/>
									</div>
									<div
										style={{
											paddingBottom: 30,
											paddingRight: 30,
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										<SpecificFileUpload
											onHandleTextChange={onCustomRadioStudentDocs}
											stateData={stateData}
											Name="Transfer Certificate:"
											idForYes="customRadiopassTransY"
											idForNo="customRadiopassTransN"
											feildName="transferCertificate"
											uploadName="filePath1"
										/>
										<GeneralFileUploadOption
											name="filePath1"
											feildName={'transferCertificate'}
											fileOnChange={fileOnChange}
											stateData={stateData}
										/>
									</div>
									<div
										style={{
											paddingBottom: 30,
											paddingRight: 30,
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										<SpecificFileUpload
											onHandleTextChange={onCustomRadioStudentDocs}
											stateData={stateData}
											Name="Ministry Vacination:"
											idForYes="customRadiominisY"
											idForNo="customRadiominisN"
											feildName="ministryVacination"
											uploadName="filePath_ministryVacination"
										/>

										<GeneralFileUploadOption
											name="filePath_ministryVacination"
											feildName={'ministryVacination'}
											fileOnChange={fileOnChange}
											stateData={stateData}
										/>
									</div>
									<div
										style={{
											paddingBottom: 30,
											paddingRight: 30,
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										<SpecificFileUpload
											onHandleTextChange={onCustomRadioStudentDocs}
											stateData={stateData}
											Name="Last Result:"
											idForYes="customRadiolastRY"
											idForNo="customRadiolastRN"
											feildName="lastResult"
											uploadName="filePath_lastResult"
										/>
										<GeneralFileUploadOption
											name="filePath_lastResult"
											feildName={'lastResult'}
											fileOnChange={fileOnChange}
											stateData={stateData}
										/>
									</div>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div
										style={{
											paddingBottom: 30,
											paddingRight: 30,
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										<SpecificFileUpload
											onHandleTextChange={onCustomRadioStudentDocs}
											stateData={stateData}
											Name="Medical History:"
											idForYes="customRadiomedY"
											idForNo="customRadiomedN"
											feildName="medicalHistory"
											uploadName="filePath_medicalHistory"
										/>
										<GeneralFileUploadOption
											name="filePath_medicalHistory"
											feildName={'medicalHistory'}
											fileOnChange={fileOnChange}
											stateData={stateData}
										/>
									</div>
									<div
										style={{
											paddingBottom: 30,
											paddingRight: 30,
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										<SpecificFileUpload
											onHandleTextChange={onCustomRadioStudentDocs}
											stateData={stateData}
											Name="Passport Copy:"
											idForYes="customRadiopassY"
											idForNo="customRadiopassN"
											feildName="passportCopy"
											uploadName="filePath_passportCopy"
										/>
										<GeneralFileUploadOption
											name="filePath_passportCopy"
											feildName={'passportCopy'}
											fileOnChange={fileOnChange}
											stateData={stateData}
										/>
									</div>
									<div
										style={{
											paddingBottom: 30,
											paddingRight: 30,
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										<SpecificFileUpload
											onHandleTextChange={onCustomRadioStudentDocs}
											stateData={stateData}
											Name="Id Card Copy:"
											idForYes="customRadioidcardY"
											idForNo="customRadioidcardN"
											feildName="idCardCopy"
											uploadName="filePath_idCardCopy"
										/>
										<GeneralFileUploadOption
											name="filePath_idCardCopy"
											feildName={'idCardCopy'}
											fileOnChange={fileOnChange}
											stateData={stateData}
										/>
									</div>
									<div
										style={{
											paddingBottom: 30,
											paddingRight: 30,
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										<SpecificFileUpload
											onHandleTextChange={onCustomRadioStudentDocs}
											stateData={stateData}
											Name="Test Result:"
											idForYes="customRadiotestRY"
											idForNo="customRadiotestRN"
											feildName="testResult"
											uploadName="filePath_testResult"
										/>
										<GeneralFileUploadOption
											name="filePath_testResult"
											feildName={'testResult'}
											fileOnChange={fileOnChange}
											stateData={stateData}
										/>
									</div>
								</div>

								{/* <div style={{display:'flex',flexDirection:'column'}} >
                        <div style={{  paddingBottom:30, paddingRight:30, display:'flex' , flexDirection: 'row' , flexWrap:'wrap' }} >
                        <GeneralRadioOptionSelection
                                onHandleTextChange={onHandleTextChange}
                                stateData={stateData}
                                Name='Medical History:'
                                idForYes='customRadiomedY'
                                idForNo='customRadiomedN'
                                feildName='medicalHistory'
                                />
                            <GeneralFileUploadOption
                                name='filePath_medicalHistory'
                                feildName={'medicalHistory'}
                                fileOnChange={fileOnChange}
                                stateData={stateData}
                                />
                        </div>
                        
                        </div> */}
							</div>
						</div>
						{/* <div className='row' >
                             
                        <div style={{  paddingBottom:30, paddingRight:30 , display: 'flex', flexDirection: 'column' }} >
                            
                        </div>
                        <div style={{ paddingBottom:30, paddingRight:30  , display: 'flex', flexDirection: 'column' }} >
                            <GeneralRadioOptionSelection
                                onHandleTextChange={onHandleTextChange}
                                stateData={stateData}
                                Name='Passport Copy:'
                                idForYes='customRadiopassY'
                                idForNo='customRadiopassN'
                                feildName='passportCopy'
                                />
                            <GeneralFileUploadOption
                                name='filePath_passportCopy'
                                feildName={'passportCopy'}
                                fileOnChange={fileOnChange}
                                stateData={stateData}
                                />
                        </div>
                        <div style={{  paddingBottom:30, paddingRight:30 , display: 'flex', flexDirection: 'column' }} >

                            <GeneralRadioOptionSelection
                                onHandleTextChange={onHandleTextChange}
                                stateData={stateData}
                                Name='Id Card Copy:'
                                idForYes='customRadiocardY'
                                idForNo='customRadiocardN'
                                feildName='idCardCopy'
                                />
                            <GeneralFileUploadOption
                                name='filePath_idCardCopy'
                                feildName={'idCardCopy'}
                                fileOnChange={fileOnChange}
                                stateData={stateData}
                                />
                        </div>
                    </div>
                    <div className='row' >

                        <div style={{  paddingBottom:30, paddingRight:30 , display: 'flex', flexDirection: 'column' }} >
                            <div>

                            <GeneralRadioOptionSelection
                                onHandleTextChange={onHandleTextChange}
                                stateData={stateData}
                                Name='Last Result:'
                                idForYes='customRadiolastY'
                                idForNo='customRadiolastN'
                                feildName='lastResult'
                                />

                                </div>
                                <div>

                            <GeneralFileUploadOption
                                name='filePath_lastResult'
                                feildName={'lastResult'}
                                fileOnChange={fileOnChange}
                                stateData={stateData}
                                />
                                </div>
                        </div>
                        </div> */}
						{/* <div className='row' >
                        </div>
                        
                        
                        <div style={{ direction: 'flex', flexDirection: 'column' }} >
                        
                        <GeneralRadioOptionSelection
                        onHandleTextChange={onHandleTextChange}
                        stateData={stateData}
                        Name='Id Card Copy:'
                        idForYes='customRadiocardY'
                        idForNo='customRadiocardN'
                        feildName='idCardCopy'
                        />
                        </div>
                        </div>
                        <GeneralRadioOptionSelection
                        onHandleTextChange={onHandleTextChange}
                        stateData={stateData}
                        Name='Last Result:'
                        idForYes='customRadiolastY'
                        idForNo='customRadiolastN'
                        feildName='lastResult'
                    />
                    <GeneralRadioOptionSelection
                    onHandleTextChange={onHandleTextChange}
                        stateData={stateData}
                        Name='Test Result:'
                        idForYes='customRadiotestY'
                        idForNo='customRadiotestN'
                        feildName='testResult'
                    /> */}
						<br />
						<br />
						{/*<div class="text-center text-md-center">*/}
						{/*<button class="btns">Add</button>*/}
					</div>
				</form>
				<div class="status" />
			</div>
		);
	}
}

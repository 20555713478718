import React, { useContext } from "react";
import ViewRendering from "../components/ViewRendering";
import { renderingStuff, images } from "../utils";
import ThemeContext from "../context/themeContext/ThemeContext";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";

export default function EntryExamDashboard(props) {
  const themeColor = useContext(ThemeContext);
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <img
                src={require("../Assets/New_Admission_Icon/entry exam.svg")}
                class="img-fluid"
                alt="Responsive image"
                height="50"
                width="50"
              />
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Entry Exam Dashboard
              </h4>
            </div>
          </div>
        </div>
      </div>

      <NewViewRendering
        {...props}
        data={renderingStuff.Entry_Exam_Dashboard_Links}
      />
    </div>
  );
}

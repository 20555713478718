import React, { Component } from "react";
import DateandTime from "../Transport/DateandTime";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as StaffEnquiryActions from "../../Actions/StaffEnquiry";
import * as sharedActions from "../../Actions/sharedActions";
import ThemeContext from "../../context/themeContext/ThemeContext";

class StaffEnquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AdminName: "",
      AdminType: "",
      applicantName: "",
      fatherName: "",
      motherName: "",
      address: "",
      city: "",
      state: "",
      country: "",
      emailId: "",
      postalCode: "",
      dob: "",
      phoneNo: "",
      mobileNo: "",
      status: "",
      departmentId: "",
      postAppliedFor: "",
      classId: "",
      previousAcademics: "",
      referenceType: "",
      referenceName: "",
      details: "",
      select: "",
      selectTwo: "",

      post: [],
      errorMsg: false,
      msg: false,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.select !== nextProps.selectData ||
      prevState.selectTwo !== nextProps.selectDepartment
    ) {
      return {
        select: nextProps.selectData,
        selectTwo: nextProps.selectDepartment,
      };
    }
    return null;
  }
  componentDidMount() {
    this.props.sharedActions
      .gAllData(
        "/api/ClassSection",
        this.props.StaffEnquiryActions.getClassesForStaffEnquiry
      )
      .then((success) => {
        debugger;
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.sharedActions
      .gAllData(
        "/api/StaffPostDepart",
        this.props.StaffEnquiryActions.getDepartmentForStaffEnquiry
      )
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSelectChange = (e, clas) => {
    console.log(e.target.value);
    debugger;
    debugger;
    this.setState({
      classId: e.target.value,
    });
  };
  onSelectDepartmentChange = (e) => {
    console.log(e.target.value);

    const posts = this.state.selectTwo.post;
    let checking = posts.filter(
      (item) => item.departmentId === parseInt(e.target.value)
    );
    console.log(checking);

    this.setState({
      post: checking,
      // gradeName: check[0] && check[0].section,
      departmentId: e.target.value,
    });
  };
  onSelectPostChange = (e) => {
    console.log(e.target.value);

    this.setState({
      postId: e.target.value,
    });
  };
  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      applicantName,
      fatherName,
      motherName,
      mobileNo,
      address,
      city,
      state,
      country,
      emailId,
      postalCode,
      dob,
      phoneNo,
      classId,
      departmentId,
      postId,
      previousAcademics,
      referenceType,
      referenceName,
      details,
    } = this.state;
    if (
      Object.is(applicantName, "") ||
      Object.is(fatherName, "") ||
      Object.is(motherName, "") ||
      Object.is(address, "") ||
      Object.is(city, "") ||
      Object.is(state, "") ||
      Object.is(phoneNo, "") ||
      Object.is(mobileNo, "") ||
      Object.is(details, "") ||
      Object.is(country, "") ||
      Object.is(postalCode, "") ||
      Object.is(emailId, "") ||
      Object.is(referenceName, "") ||
      Object.is(referenceType, "") ||
      Object.is(previousAcademics, "") ||
      Object.is(classId, "") ||
      Object.is(dob, "") ||
      Object.is(departmentId, "") ||
      Object.is(postId, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      const data = {
        applicantName,
        fatherName,
        motherName,
        mobileNo,
        address,
        city,
        state,
        country,
        emailId,
        postalCode,
        dob,
        phoneNo,
        classId,
        previousAcademics,
        referenceType,
        referenceName,
        details,
        departmentId,
        postId,
        accountType: this.props.userData.role,
        accountId: this.props.userData.unique_name,
      };
      this.props.sharedActions
        .addRecord(
          "/api/StaffEnquiryForm",
          data,
          this.props.StaffEnquiryActions.addStaffEnquiry
        )
        .then((success) => {
          this.setState({
            applicantName: "",
            fatherName: "",
            motherName: "",
            mobileNo: "",
            address: "",
            city: "",
            state: "",
            country: "",
            emailId: "",
            postalCode: "",
            dob: "",
            phoneNo: "",
            status: "",
            className: "",
            previousAcademics: "",
            referenceType: "",
            referenceName: "",
            details: "",
            errorMsg: false,
            msg: true,
          });
        })
        .catch((error) => {});
    }
    console.log(this.state);
  };
  render() {
    const { classList } = this.state.select;
    const { department } = this.state.selectTwo;
    console.log("Department", department);
    console.log("Classes", classList);
    return (
      <div>
        <section class="mb-4">
          <h2 class="h1-responsive font-weight-bold text-center my-4">
            Online Registration Form
          </h2>

          <p class="text-center w-responsive mx-auto mb-2" align="center" />

          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ThemeContext.Consumer>
              {(context) => (
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  {this.state.errorMsg ? (
                    <p style={{ color: "red" }}>
                      Please fill the form properly!
                    </p>
                  ) : (
                    ""
                  )}
                  {this.state.msg ? (
                    <p style={{ color: "green" }}>
                      Staff Enquiry Added Successfully!
                    </p>
                  ) : (
                    ""
                  )}
                  <form
                    id="contact-form"
                    name="contact-form"
                    onSubmit={this.onHandleSubmit}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <DateandTime />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-user-shield animated fadeIn"
                                            /> */}
                            Admin Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            name="AdminName"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                            value={this.props.userData.unique_name}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-user-alt animated fadeIn"
                                            /> */}
                            Admin Type
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            name="AdminType"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                            value={this.props.userData.role}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-child animated fadeIn"
                                            /> */}
                            Applicant Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Applicant Name"
                            type="text"
                            id="name"
                            name="applicantName"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-male animated fadeIn"
                                            /> */}
                            Father Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Father Name"
                            type="text"
                            id="email"
                            name="fatherName"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-female animated fadeIn"
                                            /> */}
                            Mother Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Mother Name"
                            type="text"
                            id="email"
                            name="motherName"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-address-card animated fadeIn"
                                            /> */}
                            Address
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Address"
                            type="text"
                            id="email"
                            name="address"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-building animated fadeIn"
                                            /> */}
                            City
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="City"
                            type="text"
                            id="email"
                            name="city"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-globe-asia animated fadeIn"
                                            /> */}
                            Country
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Country"
                            type="text"
                            id="email"
                            name="country"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-flag animated fadeIn"
                                            /> */}
                            State
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="State"
                            type="text"
                            id="email"
                            name="state"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-envelop-open-text animated fadeIn"
                                            /> */}
                            Email Id
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Email Id"
                            type="text"
                            id="email"
                            name="emailId"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-mail-bulk animated fadeIn"
                                            /> */}
                            Postal Code
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Postal Code"
                            type="text"
                            id="email"
                            name="postalCode"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-birthday-cake animated fadeIn"
                                            /> */}
                            Date Of Birth
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Date Of Birth"
                            type="text"
                            id="email"
                            name="dob"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-phone-volume animated fadeIn"
                                            /> */}
                            Phone Number
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Phone Number"
                            type="text"
                            id="email"
                            name="phoneNo"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-phone animated fadeIn"
                                            /> */}
                            Mobile Number
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Mobile Number"
                            type="text"
                            id="email"
                            name="mobileNo"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-door-open animated fadeIn"
                                            /> */}
                            Class Name
                          </label>
                          <select
                            onChange={(e) => this.onSelectChange(e)}
                            class="custom-select"
                            name="classId"
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                          >
                            <option selected={this.state.msg ? true : false}>
                              Choose Class...
                            </option>
                            {classList &&
                              classList.map((item, index) => (
                                <option key={index} value={`${item.id}`}>
                                  {item.className}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-door-open animated fadeIn"
                                            /> */}
                            Department
                          </label>
                          <select
                            onChange={this.onSelectDepartmentChange}
                            class="custom-select"
                            name="className"
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                          >
                            <option selected={this.state.msg ? true : false}>
                              Choose Department...
                            </option>
                            {department &&
                              department.map((item, index) => (
                                <option
                                  key={index}
                                  value={`${item.departmentId}`}
                                >
                                  {item.departmentName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      {/* <ClassComponent classes={classList} onHandleChange={this.onSelectChange} /> */}

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-university animated fadeIn"
                                            /> */}
                            Post Applied For
                          </label>
                          <select
                            onChange={this.onSelectPostChange}
                            class="custom-select"
                            name="className"
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                          >
                            <option selected={this.state.msg ? true : false}>
                              Choose Post...
                            </option>
                            {this.state.post.map((item, index) => (
                              <option key={index} value={`${item.postId}`}>
                                {item.postName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-university animated fadeIn"
                                            /> */}
                            Previous Academics
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Previous Academics"
                            type="text"
                            id="email"
                            name="previousAcademics"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-link animated fadeIn"
                                            /> */}
                            Reference Type
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Reference Type"
                            type="text"
                            id="email"
                            name="referenceType"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-link animated fadeIn"
                                            /> */}
                            Reference Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Reference Name"
                            type="text"
                            id="email"
                            name="referenceName"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                                                style={{ marginRight: 4 }}
                                                className="fas fa-info animated fadeIn"
                                            /> */}
                            Details
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            placeholder="Details"
                            type="text"
                            id="email"
                            name="details"
                            className="form-control  "
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="text-center text-md-right">
                      <button
                        type="submit"
                        class="buttonHover2"
                        style={{
                          background: context.themeColors.primaryColor,
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                  <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
          </div>
        </section>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    StaffEnquiryActions: bindActionCreators(StaffEnquiryActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
    selectData: state.setStaffEnquiryReducer.select,
    selectDepartment: state.setStaffEnquiryReducer.selectDepart,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StaffEnquiry);

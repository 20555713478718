import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import Header from '../../Assets/images/3 headers-03.png';
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";


class PrintDailyDisciplineReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            payrollData: [],
            search: '',
            isLoading: false,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',

			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }
	static contextType = ThemeContext

    componentDidMount(){

    }

    
    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
    };


    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};



    render(){
        const { isLoading, accountId, accountType } = this.state;
        const { disciplineReport, date } = this.props.location.state

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderDisciplineReportData = disciplineReport && disciplineReport.map((item, index) => {
            return (
                <tr key={index} >
                    <th scope="row" style={{ padding: 1 }} >
                        {index + 1}
                    </th>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.className}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.section}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.subjectName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.staffName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {moment(item.date).format('MM/DD/YYYY')}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.time}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.sessionName}
                    </td>
                    <td className="borderSpace" style={{ padding: 1 }}>
                        {item.term}
                    </td>
                </tr>
                
                );
            
        }) 


        return (
            <div className="page-header"> 
                <div id="printPage" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row" >
								<div
									style={{
                                        flex: 1,
                                        width: '100%',
                                        height: '100%'
									}}
								>
									<img src={Header} width={'100%'} height={'100%'} />
								</div>
						</div>

                    <div class="row" style={{marginTop: '2%'}} >
							<div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h3>Daily Discipline Report</h3>
								
							</div>
						</div>
                        
                        <div class="row my-3" >
                        <div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h5>{date}</h5>
							</div>
                            </div>

                    
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: this.context.themeColors.primaryColor, color: 'white' }}>
                            <tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Class
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Section
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Subject
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Staff Name
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Date
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Time
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Session
								</th>
                                <th scope="col" style={{ padding: 1 }}>
                                    Term
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderDisciplineReportData}</tbody>
					</table>
                    
				</div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-4">
                            <span>Printed By: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.unique_name}</strong></span>     
                    </div>
                    <div class="col-md-4 text-center">
                             
                            <span>Role: <strong style={{color: this.context.themeColors.primaryColor}} >{this.props.userData.role}</strong></span>     

                    </div>
                    <div class="col-md-4 text-right">
                             
                            <span>Time: <strong style={{color: this.context.themeColors.primaryColor}} >{moment().format('HH:mm - MM/DD/YYYY')}</strong></span>     

                    </div>
                </div>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-10">
                    
                    </div>
                    <div class="col-md-2">
                    <button class="btn btn-primary buttonAppear" onClick={() => this.PrintContent('printPage')}>
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintDailyDisciplineReport);

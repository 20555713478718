import React from 'react';
import ThemeContext from "../../context/themeContext/ThemeContext";

export default function GenerateTable(props) {
	const { Data, pageNo, primaryColor } = props;
	return (
		<ThemeContext.Consumer>
		{(context) => (
		<React.Fragment>
			{pageNo == '1' ? (
				<div className="table-responsive">
					<table class="table text-center">
						<thead class="headCol bordersSpace" style={{background: context.themeColors.primaryColor}}>
							<tr class="vendorListHeading bordersSpace">
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}} rowSpan="2">
									NO.
								</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}} rowSpan="2">
									Students
								</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}} colSpan="3">
									Class Work
								</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}} colSpan="3">
									Home Work
								</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}} colSpan="3">
									Forgetting Work
								</th>
							</tr>
							<tr class="vendorListHeading bordersSpace">
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>Warn 1</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>Warn 2</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>Action Taken</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>Warn 1</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>Warn 2</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>Action Taken</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>Warn 1</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>Warn 2</th>
								<th className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>Action Taken</th>
							</tr>
						</thead>

						<tbody>
							{Data && Data.disciplineList.map((item, index) => (
								<tr class="font-weight-bold bordersSpace" key={index}>
									<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{index + 1}</td>
									<td class="bordersSpace font-weight-bold" style={{borderColor: context.themeColors.primaryColor}}>
										{item.subjectName}
									</td>
									{item.studentDisciplinceRecord.map((data, indexes) => (
										<React.Fragment>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.classWrn1}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.classWrn2}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.classAction}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.homeWorkWrn1}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.homeWorkWrn2}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.homeWorkAction}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.forgettenBooksWrn1}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.forgettenBooksWrn2}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.forgettenBooksAction}</td>
										</React.Fragment>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="table-responsive">
					<table class="table text-center">
						<thead style={{ background: primaryColor, color: 'white' }} class="headCol bordersSpace">
							<tr class="vendorListHeading bordersSpace">
								<th style={{border: `1px solid ${primaryColor}`}} rowSpan="2">
									NO.
								</th>
								<th style={{border: `1px solid ${primaryColor}`}} rowSpan="2">
									Students
								</th>
								<th style={{border: `1px solid ${primaryColor}`}} colSpan="3">
									Late Coming
								</th>
								<th style={{border: `1px solid ${primaryColor}`}} colSpan="3">
									Behaviour
								</th>
								<th style={{border: `1px solid ${primaryColor}`}} colSpan="3">
									Other
								</th>
							</tr>
							<tr class="vendorListHeading">
								<th style={{border: `1px solid ${primaryColor}`}}>Warn 1</th>
								<th style={{border: `1px solid ${primaryColor}`}}>Warn 2</th>
								<th style={{border: `1px solid ${primaryColor}`}}>Action Taken</th>
								<th style={{border: `1px solid ${primaryColor}`}}>Warn 1</th>
								<th style={{border: `1px solid ${primaryColor}`}}>Warn 2</th>
								<th style={{border: `1px solid ${primaryColor}`}}>Action Taken</th>
								<th style={{border: `1px solid ${primaryColor}`}}>Warn 1</th>
								<th style={{border: `1px solid ${primaryColor}`}}>Warn 2</th>
								<th style={{border: `1px solid ${primaryColor}`}}>Action Taken</th>
							</tr>
						</thead>

						<tbody>
							{Data && Data.disciplineList.map((item, index) => (
								<tr class="font-weight-bold" key={index}>
									<td style={{border: `1px solid ${primaryColor}`}}>{index + 1}</td>
									<td class="bordersSpace font-weight-bold" style={{border: `1px solid ${primaryColor}`}}>
										{item.subjectName}
									</td>
									{item.studentDisciplinceRecord.map((data, indexes) => (
										<React.Fragment>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.lateWrn1}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.lateWrn2}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.lateAction}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.behaviourWrn1}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.behaviourWrn2}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.behaviourAction}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.otherWrn1}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.otherWrn2}</td>
											<td className="bordersSpace" style={{borderColor: context.themeColors.primaryColor}}>{data.otherAction}</td>
										</React.Fragment>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
		              )}
					  </ThemeContext.Consumer>
	);
}

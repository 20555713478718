import React, { Component, useEffect } from "react";
import SubItemComponent from "../SubItemComponent/SubItemComponent";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  checkForFilteration,
  checkingProperty,
  extractIds,
  viewClassReturn,
  disableColorOverCondition,
} from "../../utils/general";
import SelectionInput from "../SelectionInput/SelectionInput";
import config from "../../config/config";

export default function ItemComponent(props) {
  const {
    extraData,
    extraRedirectProperty,
    conditionalIconsColor,
    onSetTerm,
    exceptionalHandler,
    excludedData,
    objItem,
    rowIndex,
    data,
    redirectIds = [],
  } = props;

  const btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };

  useEffect(() => {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    return () => {
      $('[data-toggle="tooltip"]').tooltip("hide");
    };
  }, []);

  let getKeys = Object.keys(objItem);

  if (checkingProperty(getKeys, excludedData)) {
    getKeys = checkForFilteration(getKeys, excludedData);
  }

  const renderAccToType = (
    type,
    renderItem,
    dataItem,
    rowIndex,
    keyItem,
    exceptionalHandler1,
    ...args
  ) => {
    if (type === "actionLink") {
      return (
        <Link
          className={
            renderItem["exceptionCase"]
              ? exceptionalHandler(renderItem, dataItem, rowIndex)
              : ""
          }
          to={`${renderItem.initialPath}${extractIds(
            redirectIds,
            data[rowIndex],
            renderItem && renderItem["isEnable"]
              ? `${renderItem[extraRedirectProperty]}`
              : ""
          )}`}
        >
          <a id="tooltipId" data-toggle="tooltip" title={renderItem.title}>
            <button
              id="firstbutton"
              onMouseOver={btnTechHover()}
              style={{
                cursor: "pointer",
                fontSize: 20,
                border: "none",
                marginLeft: 5,
                background: "none",
                marginBottom: 5,
              }}
            >
              <i
                style={{
                  color: renderItem["exceptionCase"]
                    ? conditionalIconsColor(renderItem, dataItem, rowIndex)
                    : renderItem.iconColor,
                }}
                class={renderItem.iconName}
              ></i>
            </button>
          </a>
        </Link>
      );
    } else if (type === "modalButton") {
      return (
        <button
          disabled={!renderItem.isEnable}
          // id="modalbutton"
          onMouseOver={btnTechHover()}
          style={{
            cursor: "pointer",
            fontSize: 20,
            border: "none",
            marginLeft: 5,
            background: "none",
            marginBottom: 5,
          }}
          type="button"
          // data-target={renderItem.modalKey}
          data-toggle="modal"
          onClick={() => {
            try {
              let pattern = /\d+/g;
              let getId = extractIds(
                redirectIds,
                data[rowIndex],
                renderItem && renderItem["isEnable"]
                  ? `${renderItem[extraRedirectProperty]}`
                  : ""
              ).match(pattern);
              debugger;
              debugger;
              renderItem.handler(
                getId == null ? "no Id found" : getId[0],
                dataItem,
                extraData
              );
            } catch (error) {
              debugger;
            }
          }}
        >
          <i
            style={{
              color: renderItem.iconColor,
            }}
            class={renderItem.iconName}
          ></i>
        </button>
      );
    } else if (type === "downloadLink") {
      return dataItem && dataItem[rowIndex].status == "Paid" ? (
        <a
          href={`${config.localhttp}${renderItem.initialPath}${extractIds(
            redirectIds,
            data[rowIndex],
            "",
            true
          )}`}
          id="tooltipId"
          data-toggle="tooltip"
          title={renderItem.title}
        >
          <button
            id="firstbutton"
            onMouseOver={btnTechHover()}
            style={{
              cursor: "pointer",
              fontSize: 20,
              border: "none",
              marginLeft: 5,
              background: "none",
              marginBottom: 5,
            }}
          >
            <i
              style={{
                color:
                  dataItem && dataItem[rowIndex].status == "Paid"
                    ? renderItem.iconColor
                    : "#d3d3d3",
              }}
              class={renderItem.iconName}
            ></i>
          </button>
        </a>
      ) : (
        <button
          id="firstbutton"
          style={{
            cursor: "pointer",
            fontSize: 20,
            border: "none",
            marginLeft: 5,
            background: "none",
            marginBottom: 5,
          }}
        >
          <i
            style={{
              color: "#d3d3d3",
            }}
            class={renderItem.iconName}
          ></i>
        </button>
      );
      // </Link>
    } else {
      return (
        <a data-toggle="tooltip" title={renderItem.title}>
          <button
            onClick={() => alert("handler should be added")}
            data-toggle="modal"
            data-target="#myModal1"
            style={{
              cursor: "pointer",
              fontSize: 20,
              border: "none",
              background: "none",
              marginLeft: 5,
              marginBottom: 5,
            }}
          >
            <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
          </button>
        </a>
      );
    }
  };
  const checkToRender = (keyItem, index) => {
    if (keyItem == "Action") {
      return (
        <td key={index} style={{ textAlign: "center", paddingLeft: 0 }}>
          {data[rowIndex][keyItem].map((item, index) => (
            <React.Fragment>
              {renderAccToType(item.type, item, data, rowIndex, keyItem)}
            </React.Fragment>
          ))}
        </td>
      );
      // action with handler
    } else if (keyItem == "fields") {
      return data[rowIndex][keyItem].map((item, index) => (
        <React.Fragment>
          <td key={index}>
            <div>
              <select
                onChange={(e) => onSetTerm(e, rowIndex, item.feildName)}
                class="form-control"
              >
                <option>Choose Term</option>
                <option>1st Term</option>
                <option>2nd Term</option>
                <option>3rd Term</option>
              </select>
            </div>
          </td>
        </React.Fragment>
      ));
    } else {
      return (
        <SubItemComponent
          key={index}
          keyItem={keyItem}
          data={data}
          rowIndex={rowIndex}
        />
      );
    }
  };
  return (
    <React.Fragment>
      {getKeys.map((keyItem, index) => (
        <React.Fragment>{checkToRender(keyItem, index)}</React.Fragment>
      ))}{" "}
    </React.Fragment>
  );
}

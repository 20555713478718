import React from "react";
import ViewRendering from "../../../components/ViewRendering";
import { renderingStuff, images } from "../../../utils";
import DashboardLayout from "../../../components/HOC/DashboardLayout";
import NewViewRendering from "../../../components/ViewRendering/NewViewRendering";

function AcademicSID(props) {
  return (
    <div>
      <DashboardLayout name="SID Dashboard" image={images.imgAcademicSID}>
        <NewViewRendering {...props} data={renderingStuff.academicSIDLinks} />
      </DashboardLayout>
    </div>
  );
}

export default AcademicSID;

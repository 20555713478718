import React from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import Spinner from '../../components/Spinner/Spinner';
import Colors from '../../utils/app_constants/colors_constants';
import { Link } from 'react-router-dom';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import general from '../../utils/general'
import ThemeContext from "../../context/themeContext/ThemeContext";

const tHead = [
    'Student',
    'Class',
    'Section',
    'Reward',
    'Actions'
]

class ViewStudentInReward extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            studentInAwardData: [],
            isLoading: true,
            errorMsg: false,
            id: '',
            awardDetails: {},
            modalData: [],
            mainData: {},
            classData: [],
            sectionData: [],
            studentData: [],
            studentId: '',
            classId: '',
            sectionId: '',
            awardId: '',
            awardCategoryData: [],
            awardName: '',
            className: '',
            section: '',
            studentName: '',
        }
    }

    componentDidMount() {
        const { match } = this.props;
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        const promises = [

            this.props.sharedActions
                .getDataWithoutDispatch(
                    '/api/Student'
                ),
            this.props.sharedActions
                .getDataWithoutDispatch(
                    `/api/ViewStudentReward/?id=${match.params.id}`
                ),
            this.props.sharedActions
                .getDataWithoutDispatch(
                    `/api/AwardCategory/`
                ),
        ]

        Promise.all(promises)
            .then(success => {
                this.setState({
                    studentInAwardData: success[1].student,
                    awardDetails: success[1].award,
                    mainData: success[0],
                    classData: success[0].classes,
                    awardCategoryData: success[2],
                    isLoading: false
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    componentDidUpdate() {
        if (this.state.msg || this.state.errorMsg) {
            setTimeout(() => {
                this.setState({
                    msg: '',
                    errorMsg: false,
                });
            }, 3000);
        }
    }

    onTakeItem = itemId => {
        this.setState({
            modalData: []
        });
        const { studentInAwardData } = this.state;
        let getData = studentInAwardData.filter(item => item.studentAwardId === itemId);

        this.setState({
            modalData: getData,
            id: getData[0].studentAwardId,
            studentId: getData[0].studentId,
            classId: getData[0].classId,
            sectionId: getData[0].sectionId,
            awardCategoryId: getData[0].awardCategoryId,
            awardName: getData[0].awardName,
            className: getData[0].className,
            section: getData[0].section,
            studentName: getData[0].student
        })

    }


    onEditClick = itemId => {
        this.onTakeItem(itemId);
        this.setState({ enabled: false });
    };

    onTakeItemDelete = itemId => {
        const { studentInAwardData } = this.state;

        this.setState({
            isLoading: true
        })

        this.props.sharedActions
            .deleteRecordWithoutDispatchWithoutSlash(
                '/api/DeleteStudentFromAawrd/?id=',
                itemId,
            )
            .then(success => {
                let getNewFilterArray = studentInAwardData.filter(item => item.studentAwardId !== itemId);
                this.setState({
                    msg: 'Delete Record Successfully!',
                    studentInAwardData: getNewFilterArray,
                    isLoading: false
                });
                this.props.snackbar();
            })
            .catch(error => { });
    };

    btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };

    onHandleSubmitUpdate = (e) => {
        e.preventDefault();

        const { modalData, id, classId, sectionId, studentId, awardCategoryId, studentInAwardData, awardName, className, section, studentName } = this.state;

        modalData[0].classId = classId
        modalData[0].sectionId = sectionId
        modalData[0].studentId = studentId
        modalData[0].awardId = awardCategoryId
        modalData[0].awardName = awardName
        modalData[0].className = className
        modalData[0].section = section
        modalData[0].student = studentName

        const data = {
            classId,
            sectionId,
            studentId,
            awardCategoryId
        }

        this.props.sharedActions
            .patchParticularRecord(
                `/api/UpdateStudentAward/?id=${id}`,
                data,
            )
            .then(success => {
                studentInAwardData.forEach((item, index) => {
                    if (item.studentAwardId == id) {
                        studentInAwardData.splice(index, 1, modalData[0])
                    }
                })
                this.setState({ enabled: false, msg: 'Edited Record Successfully!' });
                this.props.snackbar();
            })
            .catch(err => { });
    };

    onHandleSelectChange = (e) => {
        const { awardCategoryData, mainData } = this.state;
        if (e.target.value !== 'Award' || 'Student') {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
        if (e.target.name === 'awardCategoryId') {
            const filteredAward = awardCategoryData.filter(item => item.id == e.target.value)
            this.setState({
                awardName: filteredAward[0].awardName
            })
        }
        if (e.target.name === 'studentId') {
            const filteredStudent = mainData.student.filter(item => item.studentId == e.target.value)
            this.setState({
                studentName: filteredStudent[0].Student
            })
        }
    }

    onHandleSectionChange = (e) => {
        const { mainData, classId } = this.state;

        if (e.target.value !== 'Section') {

            const filteredStudent = general.dataFilteration(
                mainData.student,
                ['Student', 'studentId'],
                {
                    classId: classId,
                    sectionId: e.target.value
                }
            )

            const filteredSection = mainData.sections.filter(item => item.sectionId == e.target.value)

            this.setState({
                studentData: filteredStudent,
                sectionId: e.target.value,
                section: filteredSection[0].section
            })
        }
    }

    onHandleClassChange = (e) => {
        const { mainData } = this.state

        if (e.target.value !== 'Class') {

            const filteredSection = general.dataFilteration(
                mainData.sections,
                ['section', 'sectionId'],
                {
                    classId: e.target.value
                }
            )
            const filteredClass = mainData.classes.filter(item => item.classId == e.target.value)

            this.setState({
                sectionData: filteredSection,
                classId: e.target.value,
                className: filteredClass[0].className
            })
        }
    }

    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
        window.location.reload();
    };

    render() {
        const { errors, isLoading, studentInAwardData, id, awardDetails, modalData, enabled, classData, sectionData, studentData, awardCategoryData } = this.state;

        const renderTodos = studentInAwardData.map((item, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
                <tr className="" key={index}>
                    <th className="" scope="row">
                        {index + 1}
                    </th>
                    <td className="">{item.student}</td>
                    <td className="">{item.className}</td>
                    <td className="">{item.section}</td>
                    <td className="">{item.awardName}</td>

                    <td className="displayElemet">

                        <a href="#" data-toggle="tooltip" title="Edit!">
                            <button
                                id="firstbutton"
                                onClick={() => this.onEditClick(item.studentAwardId)}
                                onMouseOver={this.btnTechHover()}
                                data-toggle="modal"
                                data-target="#myModal"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    marginLeft: 5,
                                    background: 'none',
                                    marginBottom: 5
                                }}
                            >
                                <i class="fas fa-pen"></i>
                            </button>
                        </a>

                        <a href="#" data-toggle="tooltip" title="Delete!">
                            <button
                                onClick={() => this.onEditClick(item.studentAwardId)}
                                data-toggle="modal"
                                data-target="#myModal1"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    border: 'none',
                                    background: 'none',
                                    marginLeft: 5,
                                    marginBottom: 5
                                }}
                            >
                                <i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
                            </button>
                        </a>
                    </td>
                </tr>
                                                    )}
                                                    </ThemeContext.Consumer>
            );
        });


        const MainContent = (
            <ThemeContext.Consumer>
                {(context) => (
            <div>
                <div class="modal" id="myModal">
                    <div class="modal-dialog modal-md modal-dialog-centered">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18,
                                    background: context.themeColors.primaryColor
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Details
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={e => this.onHandleSubmitUpdate(e)}>
                                    {modalData &&
                                        modalData.map((item, index) => (
                                            <div key={index}>
                                                <fieldset disabled={!enabled}>
                                                    <div class='row'>

                                                        <SelectionInput
                                                            feildName={'classId'}
                                                            selectName={'Class'}
                                                            onHandleChange={e => this.onHandleClassChange(e)}
                                                            errors={errors}
                                                            optionsArrys={classData}
                                                            selectedText={'Class'}
                                                            stateData={this.state}
                                                            // iconClassName={'fas fa-building'}
                                                            optionType="dynamicWithPropIdAndName"
                                                            property={'className'}
                                                            propertyId={'classId'}
                                                            successflag={'successflag'}
                                                            isValidte={false}
                                                        // editUse={'staffEdit'}
                                                        />

                                                        <SelectionInput
                                                            feildName={'sectionId'}
                                                            selectName={'Section'}
                                                            onHandleChange={e => this.onHandleSectionChange(e)}
                                                            errors={errors}
                                                            optionsArrys={sectionData}
                                                            selectedText={'Section'}
                                                            stateData={this.state}
                                                            // iconClassName={'fas fa-building'}
                                                            optionType="dynamicWithPropIdAndName"
                                                            property={'section'}
                                                            propertyId={'sectionId'}
                                                            successflag={'successflag'}
                                                            isValidte={false}
                                                        // editUse={'staffEdit'}
                                                        />

                                                        <SelectionInput
                                                            feildName={'studentId'}
                                                            selectName={'Student'}
                                                            onHandleChange={e => this.onHandleSelectChange(e)}
                                                            errors={errors}
                                                            optionsArrys={studentData}
                                                            selectedText={'Student'}
                                                            stateData={this.state}
                                                            // iconClassName={'fas fa-building'}
                                                            optionType="dynamicWithPropIdAndName"
                                                            property={'Student'}
                                                            propertyId={'studentId'}
                                                            successflag={'successflag'}
                                                            isValidte={false}
                                                        // editUse={'staffEdit'}
                                                        />

                                                        <SelectionInput
                                                            feildName={'awardCategoryId'}
                                                            selectName={'Award'}
                                                            onHandleChange={e => this.onHandleSelectChange(e)}
                                                            errors={errors}
                                                            optionsArrys={awardCategoryData}
                                                            selectedText={'Award'}
                                                            stateData={this.state}
                                                            // iconClassName={'fas fa-building'}
                                                            optionType="dynamicWithPropIdAndName"
                                                            property={'awardName'}
                                                            propertyId={'id'}
                                                            successflag={'successflag'}
                                                            isValidte={false}
                                                        // editUse={'staffEdit'}
                                                        />
                                                    </div>
                                                </fieldset>
                                                <br />
                                                <br />
                                                <button
                                                    disabled={enabled}
                                                    onClick={() => this.setState({ enabled: true })}
                                                    type="button"
                                                    class="btn btn-primary buttonAppear"
                                                    style={{background: context.themeColors.primaryColor}}
                                                >
                                                    Edit
												</button>
                                                <button
                                                    style={{ marginLeft: 5, background: context.themeColors.primaryColor }}
                                                    disabled={!enabled}
                                                    type="submit"
                                                    class="btn btn-primary buttonAppear"
                                                >
                                                    Save
												</button>
                                            </div>
                                        ))}
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    style={{ background: '#00435D' }}
                                    class="btn btn-danger"
                                    data-dismiss="modal"
                                >
                                    Close
								</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal" id="myModal1">
                    <div class="modal-dialog">
                        <div class="modal-content modal_content_custom">
                            <div
                                style={{
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,
                                    borderTopLeftRadius: 18,
                                    borderTopRightRadius: 18,
                                    background:context.themeColors.primaryColor
                                }}
                                class="modal-header modal_custom_header"
                            >
                                <h4 style={{ color: 'white' }} class="modal-title">
                                    Confirm
								</h4>
                                <button
                                    style={{ color: 'white' }}
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                >
                                    &times;
								</button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <h4>Are you sure ?</h4>
                                    </div>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-evenly' }}
                                    >
                                        <div>
                                            <button
                                            style={{background:context.themeColors.primaryColor}}
                                                disabled={this.state.enabled}
                                                data-dismiss="modal"
                                                onClick={() => this.onTakeItemDelete(id)}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                Yes
											</button>
                                        </div>
                                        <div>
                                            <button
                                            style={{background: context.themeColors.primaryColor}}
                                                data-dismiss="modal"
                                                disabled={this.state.enabled}
                                                type="button"
                                                class="btn btn-primary buttonAppear"
                                            >
                                                No
											</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header text-center" style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                        <h3> <strong>Reward Details</strong></h3>
                    </div>
                    <div class="card-body">
                        <div class='row' style={{ margin: '0 auto' }}>
                            <div class='col-md-6'>
                                <strong style={{ fontSize: 20 }}>Award Name: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                                >{awardDetails.description}</label>
                            </div>
                            <div class='col-md-3'>
                                <strong style={{ fontSize: 20 }}>Date: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                                >{awardDetails.date}</label>
                            </div>

                            <div class='col-md-3'>
                                <strong style={{ fontSize: 20 }}>Month: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                                >{awardDetails.month}</label>
                            </div>

                            <br />
                            <br />

                            <div class='col-md-6'>
                                <strong style={{ fontSize: 20 }}>Session: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                                >{awardDetails.session}</label>
                            </div>

                            <div class='col-md-6'>
                                <strong style={{ fontSize: 20 }}>Time: </strong>&nbsp;
								<label
                                    for="name"
                                    class=""
                                    style={{ fontWeight: 'bold', fontSize: 20, color: '#01425e' }}
                                >{awardDetails.time}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                {/*                 <div class='col-md-12 print'>
                    <h2 style={{
                        backgroundColor: '#01ac8a',
                        textAlign: 'center',
                        color: 'white'
                    }}
                        className="h1-responsive font-weight-bold text-center my-4 generalHead">
                        {' '}
                            Student Details{' '}
                    </h2>
                </div>
 */}
                <div className="table-responsive">
                    <table class="table table-hover table-bordered">
                        <thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
                            <tr class="vendorListHeading">
                                <th scope="col">#</th>
                                {tHead.map(item => (
                                    item === 'Actions' ?
                                        <th className='displayElemet'
                                            style={{
                                                border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                borderColor: Colors.WHITE
                                            }}
                                            scope="col"
                                        >
                                            {item}
                                        </th>
                                        :
                                        <th
                                            style={{
                                                border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                borderColor: Colors.WHITE
                                            }}
                                            scope="col"
                                        >
                                            {item}
                                        </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>{renderTodos}</tbody>
                    </table>
                </div>
            </div >
                                                )}
                                                </ThemeContext.Consumer>
        );

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div id='div1'>
                <div className="page-header">
                    <SnackBar msg={this.state.msg} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: context.themeColors.primaryColor}}>
                                {' '}
                    Students For Reward{' '}
                            </h2>
                        </div>

                    </div>
                </div>
                <br />

                {isLoading ? <Spinner /> : MainContent}
                <div class="text-center text-md-right displayElemet">
                    <label for="name" class="" onClick={() => this.PrintContent('div1')}>
                        <i
                            className="fas fa-print fa-2x animated fadeIn"
                            style={{ marginRight: 4 }}
                        />
                        Print
                            </label>
                </div>

            </div>
                                    )}
                                    </ThemeContext.Consumer>
        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewStudentInReward);
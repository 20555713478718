import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import general from '../../utils/general';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';

import themeContext from "../../context/themeContext/ThemeContext";


const InvestigationCommittee = props => {

    const themeColor = useContext(themeContext);


	const [committeeData, setCommitteeData] = useState({});
	const [departmentData, setDepartmentData] = useState([]);
	const [hodData, setHodData] = useState([]);
	const [staffData, setStaffData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [msg, setSnackMsg] = useState('');
	const [state, setState] = useState({
		accountId: props.name,
		accountType: props.role,
		classId: '',
		sectionId: '',
		sessionId: '',
		studentId: '',
		chooseFile: '',
		findings: '',
		recommendation: '',
		ministryCode: '',
		committeeMembers: '',
		committeeHeadName: '',
		committeeMemberName1: '-',
		committeeMemberName2: '-',
		committeeMemberName3: '-',
		committeeMemberName4: '-',

		isLoading: '',
		errorMsg: ''
	});

	const [isSessionLoading, sessions] = httpWithRequiredSetData(
		'/api/CommitteeHeadDropdown',
		[],
		data => {
			setCommitteeData(data);
			setDepartmentData(data['department']);
		}
	);

	const [isStaffLoading, staffs] = httpWithRequiredSetData(
		'/api/StaffPostDepart',
		[],
		data => {
			setStaffData(data['staff']);
		}
	);

	useEffect(() => {
		if (msg || state['errorMsg']) {
			setTimeout(() => {
				setState(prevState => ({
					...prevState,
					errorMsg: false
				}));
			}, 3000);
		}
	});

	useEffect(() => {
		props.sharedActions
			.getDataWithoutDispatch(
				`/api/StudentInformationById?studentId=${props.match.params.id}`
			)
			.then(success => {
				setState(prevState => ({
					...prevState,
					isLoading: false,
					classId: success.classId,
					sectionId: success.sectionId,
					studentId: success.studentAdmissionFormId,
					name: success.studentName,
					section: success.section,
					className: success.className,
					session: success.session,
					sessionId: success.sessionId
				}));
			})
			.catch(error => {
				setState(prevState => ({
					...prevState,
					isLoading: false
				}));
			});
	}, []);

	const onHandleSelectChange = e => {
		if (
			e.target.value !== 'Select Department' &&
			e.target.value !== 'Select Committee Head' &&
			e.target.value !== 'Select Committee Member' &&
			e.target.value !== 'Select # of Members'
		) {
			const target = e.target;
			if (target.name === 'hodPostId') {
				const hodData = general.dataFilteration(
					committeeData.hod,
					['staffId', 'staffName'],
					{ hodPostId: e.target.value }
				);

				setHodData(hodData);
				setState(prevState => ({
					...prevState,
					[target.name]: target.value
				}));
			} else {
				setState(prevState => ({
					...prevState,
					[target.name]: target.value
				}));
			}
		}
	};

	const onHandleTextChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));
	};

	const onChangeFile = e => {
		const target = e.target;

		setState(prevState => ({
			...prevState,
			chooseFile: target.files[0]
		}));

		console.log(target.files[0]);
	};

	const onSubmit = e => {
		e.preventDefault();
		console.log(state);

		if (
			state['classId'] === '' ||
			state['sectionId'] === '' ||
			state['sessionId'] === '' ||
			state['studentId'] === '' ||
			state['findings'] === '' ||
			state['ministryCode'] === '' ||
			state['committeeHeadName'] === '' ||
			state['committeeMemberName1'] === '-' ||
			state['chooseFile'] === ''
		) {
			setState(prevState => ({
				...prevState,
				errorMsg: true
			}));
		} else {
			setIsLoading(true);

			let data = new FormData();

			data.append('classId', state['classId']);
			data.append('sectionId', state['sectionId']);
			data.append('sessionId', state['sessionId']);
			data.append('studentId', state['studentId']);
			data.append('findings', state['findings']);
			data.append('MinistryCode', state['ministryCode']);
			data.append('recommendation', state['recommendation']);
			data.append('committeeHeadName', state['committeeHeadName']);
			data.append('committeeMemberName1', state['committeeMemberName1']);
			data.append('committeeMemberName2', state['committeeMemberName2']);
			data.append('committeeMemberName3', state['committeeMemberName3']);
			data.append('committeeMemberName4', state['committeeMemberName4']);
			data.append('file', state['chooseFile']);
			data.append('accountId', state['accountId']);
			data.append('accountType', state['accountType']);

			props.sharedActions
				.simpleAddRequest('/api/InvestigationCommittee', data)
				.then(success => {
					setIsLoading(false);
					setHodData([]);
					setSnackMsg('Added Committee Successfully!');
					setState({
						accountId: props.name,
						accountType: props.role,
						chooseFile: '',
						findings: '',
						recommendation: '',
						ministryCode: '',
						committeeMembers: '',
						committeeHeadName: '',
						committeeMemberName1: '-',
						committeeMemberName2: '-',
						committeeMemberName3: '-',
						committeeMemberName4: '-',
						isLoading: '',
						errorMsg: ''
					});
					props.snackbar();
				})
				.catch(error => {
					if (error && error.response && error.response.status === 400) {
						setSnackMsg(error.response.data.Message);
						props.snackbar();
					}
					setIsLoading(false);
				});
		}
	};

	const Student = (
		<div>
			<div className="row">
				<TextInput
					feildName={'className'}
					inputLabelName={'Class Name'}
					errors={{}}
					stateData={state}
					placeholder="Class Name"
					readOnly={true}
					type={'text'}
					isValidte={false}
				/>

				<TextInput
					feildName={'section'}
					inputLabelName={'Section'}
					errors={{}}
					stateData={state}
					placeholder="Section"
					readOnly={true}
					type={'text'}
					isValidte={false}
				/>

				<TextInput
					marginTop={20}
					feildName={'name'}
					inputLabelName={'Student Name'}
					errors={{}}
					stateData={state}
					placeholder="Student Name"
					readOnly={true}
					type={'text'}
					isValidte={false}
				/>

				<TextInput
					marginTop={20}
					feildName={'session'}
					inputLabelName={'Session'}
					errors={{}}
					stateData={state}
					placeholder="Session"
					readOnly={true}
					type={'text'}
					isValidte={false}
				/>

				<SelectionInput
					marginTop={20}
					feildName={'hodPostId'}
					selectName={'Department'}
					onHandleChange={e => onHandleSelectChange(e)}
					errors={{}}
					optionsArrys={departmentData}
					selectedText={'Select Department'}
					stateData={state}
					// iconClassName={'fas fa-building'}
					optionType="dynamicWithPropIdAndName"
					property={'postHOD'}
					propertyId={'hodPostId'}
					successflag={'successflag'}
					isValidte={false}
					// editUse={'staffEdit'}
				/>

				<SelectionInput
					marginTop={20}
					feildName={'committeeHeadName'}
					selectName={'Committee Head'}
					onHandleChange={e => onHandleSelectChange(e)}
					errors={{}}
					optionsArrys={hodData}
					selectedText={'Select Committee Head'}
					stateData={state}
					// iconClassName={'fas fa-building'}
					optionType="dynamicWithPropIdAndName"
					property={'staffName'}
					propertyId={'staffName'}
					successflag={'successflag'}
					isValidte={false}
					// editUse={'staffEdit'}
				/>

				<SelectionInput
					marginTop={20}
					feildName={'committeeMembers'}
					selectName={'Number of Members'}
					onHandleChange={e => onHandleSelectChange(e)}
					errors={{}}
					optionsArrys={general.committeeMember}
					selectedText={'Select # of Members'}
					stateData={state}
					// iconClassName={'fas fa-building'}
					optionType="static"
					successflag={'successflag'}
					isValidte={false}
					// editUse={'staffEdit'}
				/>

				{state['committeeMembers'] === ''
					? ''
					: (() => {
							const options = [];

							for (
								let members = 0;
								members < parseInt(state['committeeMembers']);
								members++
							) {
								options.push(
									<SelectionInput
										marginTop={20}
										feildName={`committeeMemberName${members + 1}`}
										selectName={`Committee Member ${members + 1}`}
										onHandleChange={e => onHandleSelectChange(e)}
										errors={{}}
										optionsArrys={staffData}
										selectedText={'Select Committee Member'}
										stateData={state}
										// iconClassName={'fas fa-building'}
										optionType="dynamicWithPropIdAndName"
										property={'staffName'}
										propertyId={'staffName'}
										successflag={'successflag'}
										isValidte={false}
										// editUse={'staffEdit'}
									/>
								);
							}

							return options;
					  })()}

				<TextInput
					marginTop={20}
					feildName={'findings'}
					inputLabelName={'Findings'}
					onHandleChange={onHandleTextChange}
					errors={{}}
					stateData={state}
					// iconClassName={'fas fa-percentage'}
					placeholder={'Findings'}
					isValidte={false}
				/>

				<TextInput
					marginTop={20}
					feildName={'ministryCode'}
					inputLabelName={'Ministry Code'}
					onHandleChange={onHandleTextChange}
					errors={{}}
					stateData={state}
					// iconClassName={'fas fa-percentage'}
					placeholder={'Ministry Code'}
					isValidte={false}
				/>

				<TextArea
					marginTop={20}
					feildName={'recommendation'}
					inputLabelName={'Recommendation'}
					onHandleChange={onHandleTextChange}
					errors={{}}
					stateData={state}
					// iconClassName={'fas fa-percentage'}
					placeholder={'Recommendation'}
					isValidte={false}
				/>

				<div class="col-md-6" style={{ marginTop: 47 }}>
					<div class="md-form mb-0">
						<label
							for="email"
							class=""
							style={{
								fontSize: 17,
								color: '#000',
								fontWeight: 'normal'
							}}
						>
							File
						</label>

						<div class="file-upload">
							<div class="file-select">
								<div class="file-select-button" id="fileName">
									Choose File
								</div>
								<div class="file-select-name" id="noFile">
									{state.chooseFile === ''
										? 'No File Choosen'
										: state.chooseFile.name}
								</div>
								<input
									type="file"
									name="chooseFile"
									id="chooseFile"
									onChange={onChangeFile}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	const Form = (
		<FormLayoutWrapper
			formName="Investigation Committee"
			borderColor={themeColor.themeColors.primaryColor}
			borderRadius={15}
		>
			{state.errorMsg ? (
				<p style={{ color: 'red', textAlign: 'center' }}>
					Please fill the form properly
				</p>
			) : (
				''
			)}

			<div className="row">{Student}</div>

			<br />

			<Button
				width="100%"
				buttonClass="customButton"
				btnName="Add"
				loaderBmargin={5}
				stateData={{
					isLoading
				}}
				marginTop={20}
				onHandleSubmit={onSubmit}
				margin={'0 auto'}
				textColor={'white'}
				btnBackColor={themeColor.themeColors.primaryColor}
			/>
		</FormLayoutWrapper>
	);

	return (
		<React.Fragment>
			<SnackBar msg={msg} />
			{Form}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		name: state.setUserReducer.users.unique_name,
		role: state.setUserReducer.users.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InvestigationCommittee);

import React from "react";
import ViewRendering from "../components/ViewRendering";
import { renderingStuff, images } from "../utils";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";

export default function catCommunicationDashboard(props) {
  return (
    <div>
    <div className="row">
      <div className="col-md-12">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <img
              src={images.imgComDash1}
              class="img-fluid"
              alt="Responsive image"
              height="50"
              width="50"
            />
            <h4 className="mt-2" style={{ letterSpacing: 10 }}>
              Communication Dashboard
            </h4>
          </div>
        </div>
      </div>
    </div>
          <NewViewRendering
        {...props}
        data={renderingStuff.Communication_Dashboard_Links}
      />
    </div>
  );
}

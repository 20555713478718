import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../Actions/sharedActions";
import SnackBar from "../../components/SnackBar/SnackBar";
import { Link } from "react-router-dom";
import months from "../../utils/general";
import CustomFilterSectionLayout from "../../components/CustomFilterSectionLayout/CustomFilterSectionLayout";
import $ from "jquery";
import MultipleSubjects from "./MultipleSubjects";
import ThemeContext from "../../context/themeContext/ThemeContext";
import { contextType } from "rc-time-picker";

class ViewEntryExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionId: "",
      name: "",
      fatherName: "",
      classId2: "",
      sessionId2: "",
      contactNo: "",
      email: "",
      EntryExamMarks: [],
      errorText: "",
      AllBillingData: [],
      monthlyStudentList: [],
      newMonthlyStudentList: [],
      adminData: [],
      filterCheck: false,
      status: "",
      msgSnack: "",
      backColor: "",
      year: "",
      month: "",
      modalData: null,
      enabled: false,
      id: null,
      select: [],
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      increament: 1,
      sectionId: "",
      sections: [],
      session: [],
      classes: [],
      subjects: [],
      sessionId: "",
      classId: "",
      name2: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      badRequest: false,
      errorText: "",
      examId: "",
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    const changeArrOfObj = this.state[`${arrName}`];
    changeArrOfObj[index][e.target.name] = e.target.value;
    this.setState({
      [arrName]: changeArrOfObj,
    });
  };
  componentDidUpdate() {
    $(".tooltip").tooltip("hide");

    if (
      this.state.msg ||
      this.state.errorMsg ||
      this.state.exist ||
      this.state.badRequest
    ) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          exist: false,
          badRequest: false,
        });
      }, 3000);
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.props.sharedActions
      .getDataWithoutDispatch(
        "/api/EntryExam?PageNumber=1&pageSize=10&classId=&sessionId=&name="
      )
      .then((success) => {
        debugger;
        this.setState({
          AllBillingData: success.list,
          checking: success.paginationMetadata,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.setState({});

        console.log(err);
      });

    this.props.sharedActions
      .getDataWithoutDispatch("/api/Session")
      .then((success) => {
        this.setState({
          session: success,
        });
      })
      .catch((err) => {
        this.setState({});

        console.log(err);
      });

    this.props.sharedActions
      .getDataWithoutDispatch("/api/ClassSection")
      .then((success) => {
        this.setState({
          classes: success.classList,
        });
      })
      .catch((err) => {
        this.setState({});

        console.log(err);
      });
  }

  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecordWithoutDispatchWithoutSlash("/api/EntryExam/", itemId)
      .then((success) => {
        debugger;
        let deleteData = this.state.AllBillingData.filter(
          (item) => item.id != itemId
        );
        this.setState({
          AllBillingData: deleteData,
          msg: "Record Deleted Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {
        debugger;
      });
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { AllBillingData } = this.state;
    let getData = AllBillingData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      sessionId2: getData[0].sessionId,
      name2: getData[0].name,
      fatherName: getData[0].fatherName,
      classId: getData[0].classId,
      contactNo: getData[0].contactNo,
      email: getData[0].email,
      id: getData[0].id,
    });
  };

  onHandleText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    debugger;
  };

  onStatusChange = (e) => {
    console.log(e.target.value);
    this.setState(
      {
        status: e.target.value,
      },
      this.hitFilterApi
    );
  };
  Reload = () => {
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  };
  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      id,
      name2,
      email,
      classId,
      sessionId2,
      contactNo,
      fatherName,
    } = this.state;
    var bodyParameters = {
      id,
      name: name2,
      email,
      classId,
      sessionId: sessionId2,
      contactNo,
      fatherName,
    };
    this.props.sharedActions
      .editRecordWithoutDispatch(`/api/EntryExam/`, id, bodyParameters)
      .then((success) => {
        debugger;
        this.setState({
          enabled: false,
          msg: "Edit Record Successfully!",
        });
        this.props.snackbar();
        this.Reload();
      })
      .catch((err) => {
        this.setState({
          errorMsg: true,
          errorText: err.response.data.Message,
        });
        debugger;
      });
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };

  hitFilterApi = (e) => {
    this.setState({
      searchCheck: true,
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/EntryExam?PageNumber=1&pageSize=10&classId=${this.state.classId}&sessionId=${this.state.sessionId}&name=${this.state.name}`
      )
      .then((success) => {
        debugger;
        this.setState({
          AllBillingData: success.list,
          checking: success.paginationMetadata,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  sessionChange = (e) => {
    this.setState(
      {
        sessionId: e.target.value,
      },
      this.hitFilterApi
    );
  };
  classChange = (e) => {
    this.setState(
      {
        classId: e.target.value,
      },
      this.hitFilterApi
    );
  };

  onMonthChange = (e) => {
    this.setState(
      {
        month: e.target.value,
      },
      this.hitFilterApi
    );
  };
  nameChange = (e) => {
    this.setState(
      {
        name: e.target.value,
      },
      this.hitFilterApi
    );
  };

  paginationRequest = (number) => {
    this.setState({
      searchCheck: true,
    });
    console.log(number);

    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/EntryExam?PageNumber=${number}&pageSize=10&classId=${this.state.classId}&sessionId=${this.state.sessionId}&name=${this.state.name}`
      )
      .then((success) => {
        this.setState({
          AllBillingData: success.list,
          checking: success.paginationMetadata,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });

        console.log(err);
      });
  };
  onAddClick = (examId, classId) => {
    this.props.sharedActions
      .getDataWithoutDispatch(`/api/getsubjectByClass?classId=${classId}`)
      .then((success) => {
        this.setState({
          subjects: success,
          examId: examId,
          EntryExamMarks: [
            {
              entryExamId: examId,
              subjectId: "",
              marks: "",
              totalMarks: "",
            },
          ],
        });
      })
      .catch((err) => {
        this.setState({});

        console.log(err);
      });
  };
  onSubmitSubjects = (e) => {
    const { EntryExamMarks } = this.state;
    const data = {
      EntryExamMarks,
    };
    this.props.sharedActions
      .simpleAddRequest("/api/EntryExamMark", data)
      .then((success) => {
        this.setState({
          errorMsg: false,
          EntryExamMarks: [
            {
              entryExamId: "",
              subjectId: "",
              marks: "",
            },
          ],
          msg: "Subject Added Successfully",
          backColor: "#01ac8a",
        });
        $("#myModalAdd").modal("hide");
        this.props.snackbar();
      })
      .catch((error) => {
        this.setState({
          msg: error.response.data.Message,
          backColor: "red",
        });
        this.props.snackbar();
      });
  };
  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
  };
  render() {
    const { modalData } = this.state;
    const { session } = this.state.session;
    console.log(this.state.EntryExamMarks);

    const Loader = (
      <ThemeContext.Consumer>
        {(context) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              class="spinner-border"
              style={{ color: context.themeColors.primaryColor }}
            ></div>
          </div>
        )}
      </ThemeContext.Consumer>
    );

    let pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          class={`page-item ${
            this.state.checking.currentPage === number ? "active" : ""
          } `}
        >
          <ThemeContext.Consumer>
            {(context) => (
              <a
                style={{
                  background: context.themeColors.primaryColor,
                  borderColor: context.themeColors.primaryColor,
                }}
                key={number}
                id={number}
                onClick={() => this.paginationRequest(number)}
                class="page-link "
                href="#"
              >
                {number}
              </a>
            )}
          </ThemeContext.Consumer>
        </li>
      );
    });

    const renderBillData = this.state.AllBillingData.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.name}</td>
          <td>{item.fatherName}</td>
          <td>{item.contactNo}</td>
          <td>{item.email}</td>
          <td>{item.session}</td>
          <td>{item.className}</td>

          <td class="no-print">
            <a href="#" data-toggle="tooltip" title="Add Subjects!">
              <button
                onClick={() => this.onAddClick(item.id, item.classId)}
                data-toggle="modal"
                data-target="#myModalAdd"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i style={{ color: "#D11A2A" }} class="fas fa-plus"></i>
              </button>
            </a>
            <a href="#" data-toggle="tooltip" title="Edit!">
              <button
                id="firstbutton"
                onClick={() => this.onEditClick(item.id)}
                onMouseOver={this.btnTechHover()}
                data-toggle="modal"
                data-target="#myModal"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  marginLeft: 5,
                  background: "none",
                  marginBottom: 5,
                }}
              >
                <i class="fas fa-pen"></i>
              </button>
            </a>
            <a href="#" data-toggle="tooltip" title="Delete!">
              <button
                onClick={() => this.onEditClick(item.id)}
                data-toggle="modal"
                data-target="#myModal1"
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  border: "none",
                  background: "none",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
              </button>
            </a>
            <ThemeContext.Consumer>
              {(context) => (
                <Link to={`/dashboard/ViewSubjectMarks/${item.id}`}>
                  <a id="tooltipId" data-toggle="tooltip" title="Print Report">
                    <button
                      style={{
                        cursor: "pointer",
                        fontSize: 20,
                        border: "none",
                        background: "none",
                        marginLeft: 5,
                        marginBottom: 5,
                      }}
                    >
                      <i
                        style={{ color: context.themeColors.primaryColor }}
                        class="fas fa-list"
                      ></i>
                    </button>
                  </a>
                </Link>
              )}
            </ThemeContext.Consumer>
          </td>
        </tr>
      );
    });

    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <ThemeContext.Consumer>
            {(context) => (
              <div class="modal-dialog">
                <div class="modal-content modal_content_custom">
                  <div
                    style={{
                      borderBottomLeftRadius: 30,
                      borderBottomRightRadius: 30,
                      borderTopLeftRadius: 18,
                      borderTopRightRadius: 18,
                      background: context.themeColors.primaryColor,
                    }}
                    class="modal-header modal_custom_header"
                  >
                    <h4 style={{ color: "white" }} class="modal-title">
                      Confirm
                    </h4>
                    <button
                      style={{ color: "white" }}
                      type="button"
                      class="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    {modalData &&
                      modalData.map((item, index) => (
                        <div key={index}>
                          <div style={{ textAlign: "center" }}>
                            <h4>Are you sure ?</h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div>
                              <button
                                disabled={this.state.enabled}
                                data-dismiss="modal"
                                onClick={() =>
                                  this.onTakeItemDelete(this.state.id)
                                }
                                type="button"
                                class="btn btn-primary buttonAppear"
                                style={{
                                  background: context.themeColors.primaryColor,
                                  borderColor: context.themeColors.primaryColor,
                                }}
                              >
                                Yes
                              </button>
                            </div>
                            <div>
                              <button
                                data-dismiss="modal"
                                disabled={this.state.enabled}
                                style={{
                                  background: context.themeColors.primaryColor,
                                  borderColor: context.themeColors.primaryColor,
                                }}
                                type="button"
                                class="btn btn-primary buttonAppear"
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </ThemeContext.Consumer>
        </div>

        <div class="modal" id="myModalAdd">
          <ThemeContext.Consumer>
            {(context) => (
              <div class="modal-dialog modal-lg">
                <div class="modal-content modal_content_custom">
                  <div
                    style={{
                      borderBottomLeftRadius: 30,
                      borderBottomRightRadius: 30,
                      borderTopLeftRadius: 18,
                      borderTopRightRadius: 18,
                      background: context.themeColors.primaryColor,
                    }}
                    class="modal-header modal_custom_header"
                  >
                    <h4 style={{ color: "white" }} class="modal-title">
                      Confirm
                    </h4>
                    <button
                      style={{ color: "white" }}
                      type="button"
                      class="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="container">
                      <MultipleSubjects
                        onHandleTextChange={this.onChangeText}
                        stateData={this.state}
                        updateSpecialArrObjOnChange={
                          this.updateSpecialArrObjOnChange
                        }
                        onClickAddItem={this.onClickAddItem}
                        onClickDeleteItem={this.onClickDeleteItem}
                      />
                      <div class="text-right text-md-right">
                        <button
                          onClick={this.onSubmitSubjects}
                          class="btns"
                          style={{
                            background: context.themeColors.primaryColor,
                          }}
                        >
                          Add Subject
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ThemeContext.Consumer>
        </div>

        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <ThemeContext.Consumer>
              {(context) => (
                <div class="modal-content modal_content_custom">
                  <div
                    style={{
                      borderBottomLeftRadius: 30,
                      borderBottomRightRadius: 30,
                      borderTopLeftRadius: 18,
                      borderTopRightRadius: 18,
                      background: context.themeColors.primaryColor,
                    }}
                    class="modal-header modal_custom_header"
                  >
                    <h4 style={{ color: "white" }} class="modal-title">
                      Details
                    </h4>
                    <button
                      style={{ color: "white" }}
                      type="button"
                      class="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    {this.state.errorMsg ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        {this.state.errorText}
                      </p>
                    ) : (
                      ""
                    )}
                    <form onSubmit={this.onHandleSubmit}>
                      {modalData &&
                        modalData.map((item, index) => (
                          <div key={index}>
                            <fieldset disabled={!this.state.enabled}>
                              <div class="form-group">
                                <label for="campusName">Change Session:</label>
                                <div
                                  class="col-md-12"
                                  style={{ marginTop: 20 }}
                                >
                                  <div class="md-form mb-0">
                                    <select
                                      onChange={this.onHandleText}
                                      class="custom-select"
                                      name="sessionId2"
                                    >
                                      <option
                                        value=""
                                        selected={this.state.msg ? true : false}
                                      >
                                        All Sessions
                                      </option>
                                      {session &&
                                        session.map((item, index) => (
                                          <option
                                            key={index}
                                            value={`${item.sessionId}`}
                                          >
                                            {item.currentSession}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="campusName">Name:</label>
                                <input
                                  style={{
                                    borderTop: 0,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    boxShadow: "none",
                                  }}
                                  type="text"
                                  value={this.state.name2}
                                  onChange={this.onHandleText}
                                  name="name2"
                                  class="form-control"
                                  id="campusName"
                                />
                              </div>
                              <div class="form-group">
                                <label for="campusName">Father Name:</label>
                                <input
                                  style={{
                                    borderTop: 0,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    boxShadow: "none",
                                  }}
                                  type="text"
                                  value={this.state.fatherName}
                                  onChange={this.onHandleText}
                                  name="fatherName"
                                  class="form-control"
                                  id="campusName"
                                />
                              </div>
                              <div class="form-group">
                                <label for="campusName">Contact No:</label>
                                <input
                                  style={{
                                    borderTop: 0,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    boxShadow: "none",
                                  }}
                                  type="number"
                                  value={this.state.contactNo}
                                  onChange={this.onHandleText}
                                  name="contactNo"
                                  class="form-control"
                                  id="campusName"
                                />
                              </div>
                              <div class="form-group">
                                <label for="campusName">Email:</label>
                                <input
                                  style={{
                                    borderTop: 0,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    boxShadow: "none",
                                  }}
                                  type="email"
                                  value={this.state.email}
                                  onChange={this.onHandleText}
                                  name="email"
                                  class="form-control"
                                  id="campusName"
                                />
                              </div>
                            </fieldset>
                            <button
                              disabled={this.state.enabled}
                              onClick={() => this.setState({ enabled: true })}
                              type="button"
                              style={{
                                borderColor: context.themeColors.primaryColor,
                                background: context.themeColors.primaryColor,
                              }}
                              class="btn btn-primary buttonAppear"
                            >
                              Edit
                            </button>
                            <button
                              style={{
                                marginLeft: 5,
                                background: context.themeColors.primaryColor,
                                borderColor: context.themeColors.primaryColor,
                              }}
                              disabled={!this.state.enabled}
                              type="submit"
                              class="btn btn-primary buttonAppear"
                            >
                              Save
                            </button>
                          </div>
                        ))}
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      style={{ background: context.themeColors.primaryColor }}
                      class="btn btn-danger"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </ThemeContext.Consumer>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="customBadge" style={{ fontSize: 14 }}>
            Page: {this.state.checking.currentPage}
          </span>

          <span className="customBadge" style={{ fontSize: 14 }}>
            Total Page: {this.state.checking.totalPages}
          </span>
        </div>
        <table
          class="table table-bordered table-striped"
          style={{ textAlign: "center" }}
        >
          <ThemeContext.Consumer>
            {(context) => (
              <thead
                style={{
                  background: context.themeColors.primaryColor,
                  color: "white",
                }}
              >
                <tr class="vendorListHeading">
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Father</th>
                  <th scope="col">Contact No</th>
                  <th scope="col">Email</th>
                  <th scope="col">Session</th>
                  <th scope="col">Class</th>

                  <th class="no-print" scope="col">
                    Actions
                  </th>
                </tr>
              </thead>
            )}
          </ThemeContext.Consumer>
          <tbody>{renderBillData}</tbody>
        </table>
      </div>
    );
    return (
      <div>
        <div id="div1">
          <SnackBar msg={this.state.msg} backColor={this.state.backColor} />

          {this.state.exist ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Please Fill the Fields
            </p>
          ) : (
            ""
          )}
          {this.state.badRequest ? (
            <p style={{ color: "red", textAlign: "center" }}>
              {this.state.errorText}
            </p>
          ) : (
            ""
          )}
          <div className="page-header">
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <ThemeContext.Consumer>
                  {(context) => (
                    <h4
                      style={{ background: context.themeColors.primaryColor }}
                      class="generalHead"
                    >
                      View Candidate List
                    </h4>
                  )}
                </ThemeContext.Consumer>
              </div>
              <div class="col-md-3 text-right no-print">
                <nav aria-label="Page navigation example">
                  <ThemeContext.Consumer>
                    {(context) => (
                      <ul
                        style={{ color: context.themeColors.primaryColor }}
                        class="pagination"
                      >
                        {renderPageNumbers}
                      </ul>
                    )}
                  </ThemeContext.Consumer>
                </nav>
              </div>
            </div>
          </div>
          <br />
          <div class="container no-print">
            <CustomFilterSectionLayout displayDirection="column">
              <div class="row" style={{ justifyContent: "center" }}>
                <div class="col-md-3" style={{ marginTop: 20 }}>
                  <div class="md-form mb-0">
                    <select
                      onChange={this.sessionChange}
                      class="custom-select"
                      name="sessionId"
                    >
                      <option value="" selected={this.state.msg ? true : false}>
                        All Sessions
                      </option>
                      {session &&
                        session.map((item, index) => (
                          <option key={index} value={`${item.sessionId}`}>
                            {item.currentSession}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div class="col-md-3" style={{ marginTop: 20 }}>
                  <div class="md-form mb-0">
                    <select
                      onChange={this.classChange}
                      class="custom-select"
                      name="month"
                    >
                      <option value="" selected={this.state.msg ? true : false}>
                        All Classes.
                      </option>
                      {this.state.classes &&
                        this.state.classes.map((item, index) => (
                          <option key={index} value={`${item.id}`}>
                            {item.className}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div class="col-md-3" style={{ marginTop: 20 }}>
                  <div class="md-form mb-0">
                    <input
                      placeholder="Name"
                      type="text"
                      name="name"
                      class="form-control"
                      value={this.state.name}
                      onChange={this.nameChange}
                    />
                  </div>
                </div>
              </div>
            </CustomFilterSectionLayout>
          </div>
          <br />
          <br />
          {this.state.searchCheck ? Loader : MainContent}
          <div class="text-right hiding">
            <label>Print By: {this.props.users.unique_name}</label>
          </div>
        </div>
        <div class="text-center text-md-right">
          <label for="name" class="" onClick={() => this.PrintContent("div1")}>
            <i
              className="fas fa-print fa-2x animated fadeIn"
              style={{ marginRight: 4 }}
            />
            Print
          </label>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    allTransportState: state.setStudentSkillReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEntryExam);

import React from "react";
import ViewRendering from "../../../components/ViewRendering";
import { renderingStuff, images } from "../../../utils";
import DashboardLayout from "../../../components/HOC/DashboardLayout";
import NewViewRendering from "../../../components/ViewRendering/NewViewRendering";

export default function CADashboard(props) {
  return (
    <div>
      <DashboardLayout name="CA Dashboard" image={images.imgCAImage}>
        <NewViewRendering {...props} data={renderingStuff.caModuleLinks} />
      </DashboardLayout>
      {/* <ViewRendering
				centered={true}
				centerWidth={'50%'}
				mainHeading={images.imgAcHead}
				data={renderingStuff.caModuleLinks}
				mainHead={true}
			/> */}
    </div>
  );
}

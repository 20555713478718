
// tradeMark smart images
import imageFSmart from '../../Assets/smart-assets/finance-smart.svg';
import imageStuSmart from '../../Assets/smart-assets/student-smart.svg';
import imageAdSmart from '../../Assets/smart-assets/admins-smart.svg';
import imagehrSmart from '../../Assets/smart-assets/hr-smart.svg';
import imageLiSmart from '../../Assets/smart-assets/library-smart.svg';
import imageMaSmart from '../../Assets/smart-assets/manager-smart.svg';
import imagePsSmart from '../../Assets/smart-assets/pastoral-smart.svg';
import imageTTSmart from '../../Assets/smart-assets/timetable-smart.svg';
import imageCCmart from '../../Assets/smart-assets/communication-smart.svg';
import imageAdminsmart from '../../Assets/smart-assets/administration-smart.svg';
import imageAcadsmart from '../../Assets/smart-assets/academic-smart.svg';
import imageSSSmart from '../../Assets/smart-assets/system-smart.svg';
// end of tradeMark smart images

// system smart Links 

import imageSystemSmart from '../../Assets/System-Smart-Dashboard/system-smart.svg';
// end of system smart Links

// module images

import imageModulefianance from '../../Assets/main/finance-smart.svg';
import imageModulePastoral from '../../Assets/main/pastoral-smart.svg';
import imageModuleStudent from '../../Assets/main/student-smart.svg';
import imageModuleTimetable from '../../Assets/main/timetable-smart.png';
import imageModuleSystem from '../../Assets/main/system-smart.svg';
import imageModuleLibrary from '../../Assets/main/library-smart.svg';
import imageModuleHr from '../../Assets/main/hr-smart.svg';
import imageModuleCommunication from '../../Assets/main/communication-smart.svg';
import imageModuleCommunicationCMS from '../../Assets/CommunicationCoordinatorDashboard/communication coordinatorG icon.svg';
import imageModuleAdmins from '../../Assets/main/admins-smart.svg';
import imageModuleAdministration from '../../Assets/main/administration-smart.svg';
import imageModuleAcademic from '../../Assets/main/academic-smart.svg';
import imageModuleManagers from '../../Assets/main/managers-smart.svg';


// end of module images
import imgFDash from "../../Assets/icons/humza icons-01.svg";
import imgBDash from "../../Assets/icons/humza icons-02.svg";
import imgAdDash from "../../Assets/Main_Dashboard_New/administration.svg";
import imgadmis from "../../Assets/New_Admission_Icon/admission form.svg";
import imgAcDash from "../../Assets/Main_Dashboard_New/academic.svg";
import imgAdmissionDash from "../../Assets/New_Admission_Icon/admission smart.svg";
import imgViewAddForm from "../../Assets/New_Admission_Icon/view admission form.svg";
import imgHr from "../../Assets/New_Admission_Icon/hr.svg";
import imgAdminForm from "../../Assets/New_Admission_Icon/admin orangered.svg";
import imgComDash from "../../Assets/icons/humza icons-06.svg";
import imgHRDash from "../../Assets/icons/humza icons-07.svg";
import imgPasDash from "../../Assets/icons/humza icons-08.svg";
import imgSysDash from "../../Assets/icons/humza icons-09.svg";
import imgGMDash from "../../Assets/icons/humza icons-10.svg";
import ttSubstitutionPeriodList from "../../Assets/icons/time-table-others/substituion-period-list.svg";
import ttApplySubstitution from "../../Assets/icons/time-table-others/apply-substitution.svg";
import ttViewCutOffTime from "../../Assets/icons/time-table-others/view-cutoff-time.svg";
import ttAddCutOffTime from "../../Assets/icons/time-table-others/add-cut-off-time.svg";
import ttAllPriorityList from "../../Assets/icons/time-table-others/all-priority-list.svg";
import ttAllDailySubstitution from "../../Assets/icons/time-table-others/daily-substituion-record.svg";

import imgTT from "../../Assets/images/timetable1.svg";
import imgLPW from "../../Assets/images/timetable2.svg";
import addTT from "../../Assets/images/Timetable-01.svg";
import viewTT from "../../Assets/images/timetable-16-01.svg";
import addLPW from "../../Assets/images/lesson-01.svg";
import viewLPW from "../../Assets/images/lesson-16-01.svg";
import imgTThead from "../../Assets/images/timetable smart-03.svg";
import imgAcHead from "../../Assets/Academic/academic head.svg";
import imgFHead from "../../Assets/images/Finance-02.svg";
import imgALDash from "../../Assets/FinanceDashboardimg/new icon for humza-11.svg";
import imgBudgetDash from "../../Assets/New_Finance_Icon/budget.svg";
import imgFinanceManager from "../../Assets/New_Finance_Icon/managers.svg";
import imgAddIcon from "../../Assets/images/add.svg";
import imgViewIcon from "../../Assets/images/view.svg";
import imgHRSRM from "../../Assets/HRIcons/final-06.svg";
import imgHRDevTrain from "../../Assets/HRIcons/final-02.svg";
import imgHRFrontDesk from "../../Assets/FrontDeskDashIcon/front desk (HR).svg";
import imgHRDept from "../../Assets/HRIcons/final-04.svg";
import imgHRpost from "../../Assets/HRIcons/final-05.svg";
import imgHRStaffDoc from "../../Assets/HRIcons/final-01.svg";
import imgHRSalary from "../../Assets/HRIcons/final-07.svg";
import imgHRVacany from "../../Assets/HRIcons/final-09.svg";
import imgHrInterview from "../../Assets/HRIcons/final-10.svg";
import imgHrHead from "../../Assets/images/HR-04.svg";

//New Icons Of Dashboard Owner

import imgStaffDocs from "../../Assets/New_HR_Icons/staffdoc.svg";
import imgFDash1 from "../../Assets/FinanceDashboardimg/White Strokes/finance smart (2).svg";
import imgInvoiceBooking from "../../Assets/FinanceDashboardimg/White Strokes/invoice booking icon.svg";
import imgStudentView from "../../Assets/StudentAdmission/viewstudent.svg";
import imgStudentAdd from "../../Assets/New_Admission_Icon/add form.svg";
import imgBDash1 from "../../Assets/icons/DashboardIcon/FinalDashboardICons/business smart final icon.svg";
import imgAdDash1 from "../../Assets/icons/DashboardIcon/FinalDashboardICons/administration final icon.svg";
import imgAcDash1 from "../../Assets/icons/DashboardIcon/FinalDashboardICons/academic smart final icon.svg";
import imgAdmissionDash1 from "../../Assets/icons/DashboardIcon/FinalDashboardICons/admission smart final icon.svg";
import imgComDash1 from "../../Assets/Main_Dashboard_New/communication.svg";
import imgHRDash1 from "../../Assets/icons/DashboardIcon/FinalDashboardICons/hr smart final icon.svg";
import imgPasDash1 from "../../Assets/Main_Dashboard_New/pastoral.svg";
import imgSysDash1 from "../../Assets/icons/DashboardIcon/FinalDashboardICons/system smart final icon.svg";
import imgGMDash1 from "../../Assets/icons/DashboardIcon/FinalDashboardICons/general manager smart final icon.svg";
import imgLabSmart from "../../Assets/icons/DashboardIcon/FinalDashboardICons/lab smart.svg";

//End New Icons of Dashboard Owner

// temp images of academics

import imgCaPmTool from "../../Assets/Academic/primary-information.svg";
import imgCAImage from "../../Assets/Academic/continuous-assessment.svg";
import imgCAViewImage from "../../Assets/Academic/view-continuous-assessment.svg";

import imgAcademicLMS from "../../Assets/Academic/learning management system.svg";
import imgAcademicCMS from "../../Assets/Academic/competency management system.svg";
import imgAcademicPlan from "../../Assets/Academic/academicPlan.svg";
import imgAcademicSID from "../../Assets/Academic/sid.svg";
import imgAcademicHomeWork from "../../Assets/Academic/homework.svg";
import imgAcademicAssignment from "../../Assets/Academic/assignment.svg";
import imgAcademicLesson from "../../Assets/Academic/lesson plan.svg";
import imgAcademicExam from "../../Assets/Academic/exam.svg";
import imgAcademicSyllabus from "../../Assets/Academic/syllabus.svg";
import imgAcademicTimeTable from "../../Assets/Academic/time-table.svg";
import imgAcademicQuiz from "../../Assets/Academic/quiz.svg";
import imgAcademicTest from "../../Assets/Academic/test.svg";
import imgAcademicPastPaper from "../../Assets/Academic/past paper.svg";
import imgAcademicClass from "../../Assets/Academic/class.svg";
import imgAcademicSection from "../../Assets/Academic/section.svg";
import imgAcademicSession from "../../Assets/Academic/session.svg";
import imgAcademicSubject from "../../Assets/Academic/subject.svg";
import imgAcademicAssignClassTeacher from "../../Assets/Academic/past paper.svg";
import imgAcademicIndent from "../../Assets/Academic/indent form.svg";
import imgAcademicTeacherEvaluation from "../../Assets/Academic/teachers evaluation.svg";
import imgAcademicCalendar from "../../Assets/Academic/academic calendar.svg";

import imgAcademicSkill from "../../Assets/SkillDashboard/student skills.svg";
import imgAcademicDescriptive from "../../Assets/DescriptiveDashboard/discriptive report.svg";

import imgAcademicOnlineResource from "../../Assets/Academic/online resources icon.svg";

// end of temp images

// images of discipline : student and subject

import imgAddSubDiscipline from "../../Assets/SubjectAndStudentDiscipline/add_subject_discipline.svg";
import imgSubDiscipline from "../../Assets/SubjectAndStudentDiscipline/subject_discipline.svg";
import imgViewSubDiscipline from "../../Assets/SubjectAndStudentDiscipline/view_subject_discipline.svg";

// image of discipline :end

// student Class Dsiciline images
import imgAddClassDiscipline from "../../Assets/ClassDisciplineDashboard/add class discipline.svg";
import imgViewClassDiscipline from "../../Assets/ClassDisciplineDashboard/view class discipline.svg";
import imgClassDiscipline from "../../Assets/ClassDisciplineDashboard/class discipline.svg";
import imgUpdateClassDiscipline from "../../Assets/ClassDisciplineDashboard/update class discipline.svg";
//end class disciplie images

// Communication Dashboard Images
import imgStudentManager from "../../Assets/CommunicationDashboard/student portal manager.svg";
import imgParentManager from "../../Assets/AdditionalFixesBranchIcons/parents management.svg";
import imgAddParentResgitration from "../../Assets/CommunicationDashboard/add parent regist.svg";
import imgUpdateParentResgitration from "../../Assets/CommunicationDashboard/update parent regist.svg";
import imgRegisterParentResgitration from "../../Assets/CommunicationDashboard/assign child.svg";
import imgSearchParentRegistration from "../../Assets/CommunicationDashboard/search parent regist.svg";
import imgParentRegistration from "../../Assets/CommunicationDashboard/parent registration.svg";
import imgFamilyTree from "../../Assets/CommunicationDashboard/family tree in sky blue.svg";
import imgEventCalender from "../../Assets/CommunicationDashboard/eventcalendar.svg";
import imgBulletin from "../../Assets/CommunicationDashboard/bulletin.svg";
import imgPortalCoordinator from "../../Assets/CommunicationDashboard/portal coordinator.svg";
//end Communication Dashboard Images
// staff imports
import addstaff from "../../Assets/New_HR_Icons/add staff.svg";
import viewstaff from "../../Assets/New_HR_Icons/view staff.svg";
// end of staff imports

// Manager Dashboard Images
import imgManagerDashboard from "../../Assets/New_Admission_Icon/manager schoolbusyellow.svg";
import imgAddManager from "../../Assets/ManagerDashboard/add manager.svg";
import imgViewManager from "../../Assets/ManagerDashboard/view manager.svg";
// End Manager Dashboard Images

//Accounts Dashboard Icons
import imgFeesMain from "../../Assets/FinanceDashboardimg/fees.svg";
import imgBillingMain from "../../Assets/New_Finance_Icon/billing.svg";
import imgAddFeeType from "../../Assets/FinanceDashboardimg/White Strokes/add fees type.svg";
import imgViewFeeType from "../../Assets/FinanceDashboardimg/White Strokes/view fees type.svg";
import imgFeeCollection from "../../Assets/FinanceDashboardimg/White Strokes/fees collection.svg";
import imgFeeView from "../../Assets/FinanceDashboardimg/White Strokes/view fees.svg";
import imgFees from "../../Assets/New_Finance_Icon/fees.svg";
import imgAccounts from "../../Assets/New_Finance_Icon/account.svg";
import imgFeeReport from "../../Assets/FinanceDashboardimg/fee report icon.svg";
import imgFeeReportMonthly from "../../Assets/FinanceDashboardimg/monthly fee icon.svg";
import imgFeeReportYearly from "../../Assets/FinanceDashboardimg/yearly fee icon.svg";
import imgFeeReportDateWise from "../../Assets/FinanceDashboardimg/date wise fee icon.svg";

//End Accounts Dashboard Icons

//Billing Dashboard Icons
import imgaddBilling from "../../Assets/New_Finance_Icon/add billing.svg";
import imgViewBilling from "../../Assets/New_Finance_Icon/view billing.svg";
//End Billing Icons
//PayRoll Icons
import imgAddPayRoll from "../../Assets/PayRolDashboard/add payroll.svg";
import imgViewPayRoll from "../../Assets/PayRolDashboard/view payroll.svg";
import imgViewPaySlip from "../../Assets/PayRolDashboard/view payslip.svg";
import imgPayRoll from "../../Assets/PayRolDashboard/payroll.svg";
//End PayRoll Icons
//Library Dashboard Icons
import imgAddBook from "../../Assets/LibraryDashboard/add book.svg";
import imgViewBook from "../../Assets/LibraryDashboard/view book.svg";
import imgViewIssued from "../../Assets/LibraryDashboard/book details.svg";
import imgIssuedBook from "../../Assets/LibraryDashboard/issue books.svg";
import imgAddBookCategory from "../../Assets/LibraryDashboard/addbookcaegoryiconp.svg";
import imgViewBookCategroy from "../../Assets/LibraryDashboard/viewbookcategoryicon.svg";
import imgLibraryManage from "../../Assets/Main_Dashboard_New/library.svg";
//End Library Dashboard Icons

//Event Dashboard Images
import imgAddEvent from "../../Assets/AdditionalFixesBranchIcons/add event.svg";
import imgViewEvent from "../../Assets/AdditionalFixesBranchIcons/view event.svg";
import imgUpdateEvent from "../../Assets/AdditionalFixesBranchIcons/update event.svg";
//End Event Dashboard Images

//Student Progress Report
import AddStudentProgressReport from "../../Assets/StudentProgressDashboard/add progress report.svg";
import ViewStudentProgressReport from "../../Assets/StudentProgressDashboard/view progress report.svg";
import DashboardStudentProgressReport from "../../Assets/StudentProgressDashboard/student progress report.svg";
//End Student Progress Report

//Maintenace Manager
import imgMaintenanceCoordinator from '../../Assets/CampusMaintenanceDashboard/maintenance coordinator.svg'
import imgDashboardCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/campus maintenance.svg";
//End Maintenace Manager

//Campus Maintenance
import imgAddFloorCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/add floor.svg";
import imgViewFloorCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/view floor.svg";
import imgAddRoomCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/add room.svg";
import imgViewRoomCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/view room.svg";
import imgAddCleanlinessCategoryCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/add cleanliness category.svg";
import imgViewCleanlinessCategoryCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/view cleanliness category.svg";
import imgAddCyclicCleanlinessCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/add cyclic cleanliness.svg";
import imgViewCyclicCleanlinessCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/view cyclic cleanliness.svg";
import imgAddEmergencyMaintenanceCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/add emergency maintenance.svg";
import imgViewEmergencyMaintenanceCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/view emergency maintenance.svg";
import imgNotificationEmergencyMaintenanceCampusMaintenance from "../../Assets/CampusMaintenanceDashboard/emergency maintenance notification.svg";
//End Campus Maintenance

//SEN Manager

import AddStudentTalentSENManager from "../../Assets/SENManagerDashboard/add student talent.svg";
import ViewStudentTalentSENManager from "../../Assets/SENManagerDashboard/view student talent.svg";
import AddTalentCategorySENManager from "../../Assets/SENManagerDashboard/add talent category.svg";
import ViewTalentCategorySENManager from "../../Assets/SENManagerDashboard/view talent category.svg";
import AddTalentSubCategorySENManager from "../../Assets/SENManagerDashboard/add talent sub-category.svg";
import ViewTalentSubCategorySENManager from "../../Assets/SENManagerDashboard/view talent sub-category.svg";
import StudentTalentSENManager from "../../Assets/SENManagerDashboard/student talent.svg";

//End SEN Manager

//Wellbeing Manager

import DailyAbsentReportWellbeingManager from "../../Assets/WellbeingDashboard/daily absent report.svg";
import DailyLateReportWellbeingManager from "../../Assets/WellbeingDashboard/daily late report.svg";
import DailyReportsWellbeingManager from "../../Assets/WellbeingDashboard/daily reports.svg";
import FlagManagementWellbeingManager from "../../Assets/WellbeingDashboard/flag management.svg";
import FlagManagementSkillWellbeingManager from "../../Assets/WellbeingDashboard/flag management skills.svg";
import FlagManagementAttendanceWellbeingManager from "../../Assets/WellbeingDashboard/flag management attendance.svg";
import DailyDisciplineReportWellbeingManager from "../../Assets/WellbeingDashboard/daily discipline report.svg";
import DailyClassAttendanceWellbeingManager from "../../Assets/WellbeingDashboard/daily class attendance.svg";
import MonthlyStudentAttendanceWellbeingManager from "../../Assets/WellbeingDashboard/monthly student attendance.svg";
import StudentDisciplineReportWellbeingManager from "../../Assets/WellbeingDashboard/student discipline report.svg";
import StudentSkillAverageWellbeingManager from "../../Assets/WellbeingDashboard/student skill average.svg";
import ViewMedicalHistoryWellbeingManager from "../../Assets/WellbeingDashboard/view medical history.svg";

//End Wellbeing Manager

//Pastrol

import SENManagerPastrol from "../../Assets/PastrolDashboard/SEN manager.svg";
import WellbeingManagerPastrol from "../../Assets/PastrolDashboard/wellbeing manager.svg";

//End Pastrol
//#region Class imports
import imgAddClass from "../../Assets/ClassDashboard/add class.svg";
import imgViewClass from "../../Assets/ClassDashboard/view class.svg";
//#endregion

//#region Section imports
import imgAddSection from "../../Assets/SectionDashboard/add section.svg";
import imgViewSection from "../../Assets/SectionDashboard/view section.svg";
//#endregion

//#region Session imports
import imgAddSession from "../../Assets/SessionDashboard/add session.svg";
import imgViewSession from "../../Assets/SessionDashboard/view session.svg";
//#endregion

//#region Subject imports
import imgAddSubject from "../../Assets/SubjectDashboard/add subject.svg";
import imgViewSubject from "../../Assets/SubjectDashboard/view subject.svg";
//#endregion

//#region Assign Class Teacher imports
import imgAddClassTeacher from "../../Assets/StaffCoordinatorDashboard/assign class teacher.svg";
import imgViewClassTeacher from "../../Assets/StaffCoordinatorDashboard/view assigned class teacher.svg";
import imgClassTeacher from "../../Assets/StaffCoordinatorDashboard/class teacher.svg";
//#endregion

//#region Subject imports
import imgAddIndent from "../../Assets/IndentDashboard/add indent form.svg";
import imgViewIndent from "../../Assets/IndentDashboard/view indent form.svg";
import imgAdminProcurement from "../../Assets/AdministrationDashboard/procurement.svg";
//#endregion

//#region Subject imports
import imgFloorPlan from "../../Assets/AdministrationDashboard/floor plan icon.svg";
import imgRoom from "../../Assets/AdministrationDashboard/room icon 2.svg";
import imgFloor from "../../Assets/AdministrationDashboard/floor.svg";
//#endregion


//#region Subject imports
import imgAddTeacherEvaluation from "../../Assets/TeacherEvaluationDashboard/add teachers evaluation.svg";
import imgViewTeacherEvaluation from "../../Assets/TeacherEvaluationDashboard/view teachers evaluation.svg";
//#endregion

//# Pastoral Imports
import imgPastoralMedicalCenter from "../../Assets/Pastoral/medical center.svg";
import imgStudent from '../../Assets/Pastoral/student.png';
//#end pastoral imports

//#region Medical Center imports
import imgDoctor from "../../Assets/MedicalCenterDashboard/doctor/doctor.svg";
import imgAddDoctor from "../../Assets/MedicalCenterDashboard/doctor/add doctor.svg";
import imgViewDoctor from "../../Assets/MedicalCenterDashboard/doctor/view doctor.svg";

import imgPatient from "../../Assets/MedicalCenterDashboard/patient/patient.svg";
import imgAddPatient from "../../Assets/MedicalCenterDashboard/patient/add patient.svg";
import imgViewPatient from "../../Assets/MedicalCenterDashboard/patient/view patient details.svg";

import imgViewMedHistory from "../../Assets/MedicalCenterDashboard/view medical history.svg";
//#endregion

//#region Campus Security imports
import imgCampusSecurity from '../../Assets/VisitorDashboard/campus security.svg'
import imgCSecurity from "../../Assets/VisitorDashboard/visitor.svg";
import imgCSAddVisitor from "../../Assets/VisitorDashboard/add visitor entries.svg";
import imgCSViewVisitor from "../../Assets/VisitorDashboard/view visitor entries.svg";
import imgCSGenerateCard from "../../Assets/VisitorDashboard/generate visitor card.svg";
//#endregion

//#region Leave imports
import imgAcademicLeave from "../../Assets/LeaveDashboard/leave.svg";
import imgAddLeave from "../../Assets/LeaveDashboard/add leave.svg";
import imgViewLeaveStaff from "../../Assets/LeaveDashboard/view leave by staff.svg";
import imgViewLeaveStudent from "../../Assets/LeaveDashboard/view leave by student.svg";
//#endregion

//#region Attendance import

//#endregion

//#region Headings import
import imgPastoralSmart from "../../Assets/images/pastoral smart heading.svg";
import imgMedicalCenter from "../../Assets/images/medical center heading.svg";
//#endregion

//# Tour import
import imgAddTour from "../../Assets/TourDashboard/add tour.svg";
import imgViewTour from "../../Assets/TourDashboard/view tour.svg";
import imgPastoralTour from "../../Assets/TourDashboard/tour.svg";
import imgViewTourFilters from "../../Assets/TourDashboard/tour search.svg";
import imgTourG from "../../Assets/TourDashboard/tour iconG.svg";
//#endregion

//# Tour import
import imgPastoralActivity from "../../Assets/ActivityDashboard/activity icon 2.svg";
//#endregion

//#region
import imgAddEventCertificate from "../../Assets/EventDashboard/add event info.svg";
import imgEventCertificate from "../../Assets/EventDashboard/event certificate.svg";
import imgViewEventCertificate from "../../Assets/EventDashboard/view event info.svg";
import imgEventCertificateG from "../../Assets/EventDashboard/event certificate iconG.svg";
import imgEventG from "../../Assets/EventDashboard/add event iconG.svg";

//#endregion

//#region Other Certificate
import imgCertificateTemplate1 from "../../Assets/OtherCertificateDashboard/certificate1.png";
import imgCertificateTemplate2 from "../../Assets/OtherCertificateDashboard/certificate2.png";
import imgAddCertificate from "../../Assets/OtherCertificateDashboard/add certificate.svg";
import imgViewCertificate from "../../Assets/OtherCertificateDashboard/view certificate.svg";
import imgActivityCertificate from "../../Assets/OtherCertificateDashboard/certificate.svg";
import imgAddCertificateCategory from "../../Assets/OtherCertificateDashboard/add category.svg";
import imgViewCertificateCategory from "../../Assets/OtherCertificateDashboard/view category.svg";
import imgCertificateCategory from "../../Assets/OtherCertificateDashboard/certificate category.svg";
import imgAddOtherCertificate from "../../Assets/OtherCertificateDashboard/add other certificate.svg";
import imgViewOtherCertificate from "../../Assets/OtherCertificateDashboard/view other certificate.svg";
import imgOtherCertificate from "../../Assets/OtherCertificateDashboard/other certificate.svg";
//#endregion

//Candidate Dashboard Images
import imgAddCandidate from "../../Assets/New_Admission_Icon/add candidate info.svg";
import imgViewCandidate from "../../Assets/New_Admission_Icon/view candidate info.svg";
import imgEntryExam from "../../Assets/New_Admission_Icon/entry exam.svg";
//End Candidate Dashboard Images
//#region Award
import imgAddAward from "../../Assets/AwardDashboard/add award.svg";
import imgViewAward from "../../Assets/AwardDashboard/view award.svg";
import imgAward from "../../Assets/AwardDashboard/award.svg";
import imgAddAwardCategory from "../../Assets/AwardDashboard/add award category.svg";
import imgViewAwardCategory from "../../Assets/AwardDashboard/view award category.svg";
//#endregion

//#region Game
import imgGames from "../../Assets/GameDashboard/games.svg";
import imgAddGames from "../../Assets/GameDashboard/add game.svg";
import imgViewGames from "../../Assets/GameDashboard/view game.svg";
import imgOffGames from "../../Assets/GameDashboard/off games.svg";
import imgAddOffGame from "../../Assets/GameDashboard/add off game.svg";
import imgViewOffGame from "../../Assets/GameDashboard/view off game .svg";
//#endregion

//#region Transport
import imgRoutes from "../../Assets/TransportDashboard/route.svg";
import imgAddRoute from "../../Assets/TransportDashboard/add route.svg";
import imgViewRoute from "../../Assets/TransportDashboard/view route.svg";

import imgDriver from "../../Assets/TransportDashboard/driver.svg";
import imgAddDriver from "../../Assets/TransportDashboard/add driver.svg";
import imgViewDriver from "../../Assets/TransportDashboard/view driver.svg";

import imgVehicle from "../../Assets/TransportDashboard/vehicle.svg";
import imgAddVehicle from "../../Assets/TransportDashboard/add vehicle.svg";
import imgViewVehicle from "../../Assets/TransportDashboard/view vehicle.svg";

import imgReport from "../../Assets/TransportDashboard/maintenance report.svg";
import imgAddReport from "../../Assets/TransportDashboard/add maintenance report.svg";
import imgViewReport from "../../Assets/TransportDashboard/view maintenance report.svg";

//#endregion

//#region student coordinator
import imgStudentCoordinator from "../../Assets/StudentCoordinatorDashboard/student coordinator.svg";
import imgClassOfStudent from "../../Assets/StudentCoordinatorDashboard/class of stduents.svg";
import imgTransferStudent from "../../Assets/StudentCoordinatorDashboard/transfer students.svg";
import imgAssignClassToStudent from "../../Assets/StudentCoordinatorDashboard/new admissions.svg";
//#endregion

//#region staff coordinator
import imgStaffCoordinator from "../../Assets/StaffCoordinatorDashboard/staff coordinator.svg";
import imgAssignSubject from "../../Assets/StaffCoordinatorDashboard/teachers subject.svg";
import imgViewAssignSubject from "../../Assets/StaffCoordinatorDashboard/view assigned subject.svg";
import imgAssignSubjectToTeacher from "../../Assets/StaffCoordinatorDashboard/assign sub to teacher.svg";
import imgAssignHOD from "../../Assets/StaffCoordinatorDashboard/staff in department.svg";
import imgViewAssignHOD from "../../Assets/StaffCoordinatorDashboard/view assigned staff in depart.svg";
import imgAddStaffInDepartment from "../../Assets/StaffCoordinatorDashboard/assign staff in department.svg";
import imgAssignCoordinator from "../../Assets/StaffCoordinatorDashboard/assign grade coordinator.svg";
import imgViewCoordinator from "../../Assets/StaffCoordinatorDashboard/view assigned grade coordinator.svg";
import imgGradeCoordinator from "../../Assets/StaffCoordinatorDashboard/garde coordinator.svg";
//#endregion

//#region exclusions
import imgAddExclusion from "../../Assets/ExclusionDashboard/add exclusion.svg";
import imgViewExclusion from "../../Assets/ExclusionDashboard/view exclusion.svg";
import imgInvestigationCommittee from "../../Assets/ExclusionDashboard/investigation committee.svg";
import imgExclusionManager from "../../Assets/ExclusionDashboard/exclusion manager.svg";
import imgStudentExclusionRecord from "../../Assets/ExclusionDashboard/student exclusion records.svg";
import imgAddComplain from "../../Assets/ExclusionDashboard/add complain.svg";
import imgViewComplain from "../../Assets/ExclusionDashboard/view complain.svg";
import imgInvestigationList from "../../Assets/ExclusionDashboard/view investigation exclusions.svg";
//#endregion

//Heading Images
import imgFinanaceHeading from "../../Assets/New_Admission_Icon/finance.png";
import imgSystemSmart from "../../Assets/main/system-smart.svg";
import imgAdmissionHeading from "../../Assets/New_Admission_Icon/admission.png";
import imgHRHeading from "../../Assets/DoratPictures/S.png";

//End Heading Images
import imgAccountLedg from "../../Assets/New_Finance_Icon/account ledger.svg";
import imgAssignBudget from "../../Assets/New_Finance_Icon/assign budget.svg";
import imgViewBudget from "../../Assets/New_Finance_Icon/view budget.svg";
import imgViewBudgetItems from "../../Assets/New_Finance_Icon/view and serach items.svg";
import imgAddBudgetItems from "../../Assets/New_Finance_Icon/add items.svg";
//Budget Dashboard Icons

//End Budget Dashboard Icons

//#region Pastoral

import imgPastoralActivityD from "../../Assets/Pastoral/activities.svg";
import imgAddAwardCategoryD from "../../Assets/Pastoral/add award category.svg";
import imgAddAwardD from "../../Assets/Pastoral/add award.svg";
import imgAddCategoryD from "../../Assets/Pastoral/add category.svg";
import imgAddDoctorD from "../../Assets/Pastoral/add doc.svg";
import imgAddEventCertificateD from "../../Assets/Pastoral/add event info.svg";
import imgAddGamesD from "../../Assets/Pastoral/add games.svg";
import imgAddOffGameD from "../../Assets/Pastoral/add off game.svg";
import imgAddOtherCertificateD from "../../Assets/Pastoral/add other certificate.svg";
import imgAddpatientD from "../../Assets/Pastoral/add patient.svg";
import AddStudentTalentSENManagerD from "../../Assets/Pastoral/add student talent.svg";
import AddTalentCategorySENManagerD from "../../Assets/Pastoral/add talent category.svg";
import AddTalentSubCategorySENManagerD from "../../Assets/Pastoral/add talent sub category.svg";
import imgAddTourD from "../../Assets/Pastoral/add tour.svg";
import imgAwardD from "../../Assets/Pastoral/award.svg";
import imgCertificateCategoryD from "../../Assets/Pastoral/certificate category.svg";
import imgActivityCertificateD from "../../Assets/Pastoral/certificates.svg";
import DailyAbsentReportWellbeingManagerD from "../../Assets/Pastoral/daily absent report.svg";
import DailyClassAttendanceWellbeingManagerD from "../../Assets/Pastoral/daily class attendance.svg";
import DailyDisciplineReportWellbeingManagerD from "../../Assets/Pastoral/daily discipline report.svg";
import DailyLateReportWellbeingManagerD from "../../Assets/Pastoral/daily late report.svg";
import DailyReportsWellbeingManagerD from "../../Assets/Pastoral/daily reports.svg";
import imgDoctorD from "../../Assets/Pastoral/doctor.svg";
import imgEventCertificateD from "../../Assets/Pastoral/event certificate.svg";
import FlagManagementAttendanceWellbeingManagerD from "../../Assets/Pastoral/flag management attendance.svg";
import FlagManagementSkillWellbeingManagerD from "../../Assets/Pastoral/flag management skills.svg";
import imgGamesD from "../../Assets/Pastoral/games.svg";
import imgMedicalCenterD from "../../Assets/Pastoral/medical centre.svg";
import imgViewMedHistoryD from "../../Assets/Pastoral/medical history.svg";
import MonthlyStudentAttendanceWellbeingManagerD from "../../Assets/Pastoral/monthly student attendance.svg";
import imgOffGamesD from "../../Assets/Pastoral/off games.svg";
import imgOtherCertificateD from "../../Assets/Pastoral/other certificate.svg";
import imgPatientD from "../../Assets/Pastoral/patient.svg";
import SENManagerPastrolD from "../../Assets/Pastoral/sen manager.svg";
import StudentDisciplineReportWellbeingManagerD from "../../Assets/Pastoral/student discipline report.svg";
import StudentSkillAverageWellbeingManagerD from "../../Assets/Pastoral/student skill average.svg";
import StudentTalentSENManagerD from "../../Assets/Pastoral/student talent.svg";
import imgViewTourFiltersD from "../../Assets/Pastoral/tour search.svg";
import imgPastoralTourD from "../../Assets/Pastoral/tour.svg";
import imgViewAwardCategoryD from "../../Assets/Pastoral/view award category.svg";
import imgViewAwardD from "../../Assets/Pastoral/view award.svg";
import imgViewCertificateCategoryD from "../../Assets/Pastoral/view category.svg";
import imgViewDoctorD from "../../Assets/Pastoral/view doc.svg";
import imgViewEventCertificateD from "../../Assets/Pastoral/view event info.svg";
import imgViewGamesD from "../../Assets/Pastoral/view games.svg";
import imgViewMedicalHistoryD from "../../Assets/Pastoral/view med history.svg";
import imgViewOffGameD from "../../Assets/Pastoral/view off game.svg";
import imgViewOtherCertificateD from "../../Assets/Pastoral/view other certificate.svg";
import imgViewPatientD from "../../Assets/Pastoral/view patient.svg";
import ViewStudentTalentSENManagerD from "../../Assets/Pastoral/view student talent.svg";
import ViewTalentCategorySENManagerD from "../../Assets/Pastoral/view talent category.svg";
import ViewTalentSubCategorySENManagerD from "../../Assets/Pastoral/view talent sub category.svg";
import imgViewTourD from "../../Assets/Pastoral/view tour.svg";
import WellbeingManagerPastrolD from "../../Assets/Pastoral/wellbeing manager.svg";
import imgPastoralSmartD from "../../Assets/Pastoral/pastoral.svg";
import imgAddComplainD from "../../Assets/Pastoral/add complaint.svg";
import imgViewComplainD from "../../Assets/Pastoral/view complaint.svg";
import imgStudentExclusionRecordD from "../../Assets/Pastoral/student exclusion record.svg";
import imgInvestigationListD from "../../Assets/Pastoral/view investigation exclusion.svg";
import imgInvestigationCommitteeD from "../../Assets/Pastoral/investigation committee.svg";
import imgExclusionManagerD from "../../Assets/Pastoral/exclusion.svg";
//#endregion

//Inventory Icons
import imgInventoryMain from "../../Assets/InventoryDashIcon/inventory.svg";
import imgAddCategory from "../../Assets/InventoryDashIcon/add category.svg";
import imgViewCategory from "../../Assets/InventoryDashIcon/view category.svg";
import imgAddSupplier from "../../Assets/InventoryDashIcon/add supplier.svg";
import imgViewSupplier from "../../Assets/InventoryDashIcon/view supplier.svg";
import imgAddItem from "../../Assets/InventoryDashIcon/add itemss.svg";
import imgViewItem from "../../Assets/InventoryDashIcon/view items.svg";
import imgAddStock from "../../Assets/InventoryDashIcon/add stock.svg";
import imgViewStock from "../../Assets/InventoryDashIcon/view stock.svg";
import imgAddStockUsage from "../../Assets/InventoryDashIcon/add stock usage.svg";
import imgViewStockUsage from "../../Assets/InventoryDashIcon/view stock usage.svg";
//End Inventory Icons

//Census Icons
import imgCensusMain from "../../Assets/CensusDashIcon/census.svg";
import imgSearchStudent from "../../Assets/CensusDashIcon/search student.svg";
import imgSearchAgeWise from "../../Assets/CensusDashIcon/search age wise.svg";
import imgSearchClassWise from "../../Assets/CensusDashIcon/search classwise.svg";
import imgAddHomeWork from "../../Assets/HomeWorkDashboard/add hw.svg";
import imgViewHomeWork from "../../Assets/HomeWorkDashboard/view hw.svg";

//End Census Icons

//#region Communication Coordinator
import imgComCo1 from "../../Assets/CommunicationCoordinatorDashboard/academic coordinator.svg";
import imgComCo2 from "../../Assets/CommunicationCoordinatorDashboard/manager.svg";
import imgComCo3 from "../../Assets/CommunicationCoordinatorDashboard/dean.svg";
import imgComCo4 from "../../Assets/CommunicationCoordinatorDashboard/head of department.svg";
import imgComCo5 from "../../Assets/CommunicationCoordinatorDashboard/staff.svg";
import imgCommuncationCoordinator from "../../Assets/CommunicationCoordinatorDashboard/communication coordinator.svg";
import imgNewMessage from "../../Assets/CommunicationCoordinatorDashboard/new message.svg";
import imgInbox from "../../Assets/CommunicationCoordinatorDashboard/inbox.svg";
//#endregion

//#region Student Profile
import imgAttendanceSP from "../../Assets/StudentProfile/attendance.svg";
import imgCASP from "../../Assets/StudentProfile/continuous assessment.svg";
import imgdescriptiveSP from "../../Assets/StudentProfile/descriptive reportt.svg";
import imgStudentProfile from "../../Assets/StudentProfile/student profile.svg";
import imgProgressReportSP from "../../Assets/StudentProfile/students progress report.svg";
import imgStudentSkillsSP from "../../Assets/StudentProfile/students skills.svg";
import imgSubDisciplineSP from "../../Assets/StudentProfile/subject discipline.svg";
//#endregion
//Student Attendance
import imgStudentAttendance from "../../Assets/AttendanceDashboard/student attendance.svg";
import imgStudentAttendanceAdd from "../../Assets/AttendanceDashboard/add attendance.svg";
import imgStudentAttendanceView from "../../Assets/AttendanceDashboard/view attendance.svg";

//End Student Attendance

//Student Skill
import imgStudentSkillsAdd from "../../Assets/SkillDashboard/add student skills.svg";
import imgStudentSkillsView from "../../Assets/SkillDashboard/view student skills.svg";

//End Student Skill
//Descriptive Icons
import imgDescriptiveAdd from "../../Assets/DescriptiveDashboard/add descriptive report.svg";
import imgDescriptiveView from "../../Assets/DescriptiveDashboard/view descriptive report.svg";

//End Descriptive Icon

//Campus Icons
import imgCampusAdd from "../../Assets/CampusDashboard/add campus.svg";
import imgCampusView from "../../Assets/CampusDashboard/view campus.svg";
import imgCampus from "../../Assets/CampusDashboard/campus.svg";
//End Campus Icon

//Campus Icons
import imgCanteen from "../../Assets/CanteenDashIcon/canteen.svg";
import imgCanteenAddCategory from "../../Assets/CanteenDashIcon/add canteen category.svg";
import imgCanteenViewCategory from "../../Assets/CanteenDashIcon/view canteen category.svg";
import imgCanteenAddItem from "../../Assets/CanteenDashIcon/add canteen item.svg";
import imgCanteenViewItem from "../../Assets/CanteenDashIcon/view canteen item.svg";
import imgAddCanteen from "../../Assets/CanteenDashIcon/add canteen.svg";
import imgViewCanteen from "../../Assets/CanteenDashIcon/view canteen.svg";

//End Campus Icons

//HR Dev and Training Icons
import imgHrDevelopmentAdd from "../../Assets/HRIcons/add hr dev. nd training.svg";
import imgHrDevelopmentView from "../../Assets/HRIcons/view hr dev. nd training.svg";
import imgHrDevelopmentEdit from "../../Assets/HRIcons/edit hr dev. nd training.svg";
import imgHrDevelopmentMain from "../../Assets/HRIcons/hr dev. nd training.svg";
//End HR and Dev Training Icon

//Front Desk Icons
import imgFrontDeskAdd from "../../Assets/FrontDeskDashIcon/add student enquiry and staff enquiry.svg";
import imgFrontDeskView from "../../Assets/FrontDeskDashIcon/view student enquiry and staff enquiry.svg";
import imgFrontAdmittedViewStudent from "../../Assets/FrontDeskDashIcon/view admitted student.svg";
import imgFrontAdmittedViewStaff from "../../Assets/FrontDeskDashIcon/view admitted staff.svg";
//End Front Desk Icon

//Department Icons
import imgDepartment from "../../Assets/New_HR_Icons/department.svg";
import imgDepartmentAdd from "../../Assets/New_HR_Icons/add department.svg";
import imgDepartmentView from "../../Assets/New_HR_Icons/view department.svg";
import imgDepartmentSearchStaffByDepartment from "../../Assets/New_HR_Icons/search staff by dprt.svg";
//End Department Icon

//Post Icons
import imgPost from "../../Assets/New_HR_Icons/post.svg";
import imgPostAdd from "../../Assets/New_HR_Icons/add post.svg";
import imgPostView from "../../Assets/New_HR_Icons/view post.svg";
import imgDepartmentSearchStaffByPost from "../../Assets/New_HR_Icons/search staff by post.svg";
//End Post Icon
//homework dashboard
import imgAddHomework from "../../Assets/HomeWorkDashboard/add hw.svg";
import imgViewHomework from "../../Assets/HomeWorkDashboard/view hw.svg";

//end homework dashboard

//Exam Info Dashboard

import imgAddExamInfo from "../../Assets/ExamDashboard/add exam info.svg";
import imgViewExamInfo from "../../Assets/ExamDashboard/view exam info.svg";
import imgAddExamDetails from "../../Assets/ExamDashboard/add exam details.svg";
import imgViewExamDetails from "../../Assets/ExamDashboard/view exam details.svg";

//end Exam Info Dashboard

//Quiz Info Dashboard

import imgAddQuizInfo from "../../Assets/QuizDashboard/add quiz info.svg";
import imgViewQuizInfo from "../../Assets/QuizDashboard/view quiz info.svg";
import imgAddQuiz from "../../Assets/QuizDashboard/add quiz.svg";
import imgViewQuiz from "../../Assets/QuizDashboard/view quiz.svg";

//End Quiz info Dashboard

//Assignment Dashboard

import imgAddAssignment from "../../Assets/AssignmentDashboard/add assignment.svg";
import imgViewAssignment from "../../Assets/AssignmentDashboard/view assignment.svg";

//End Assignment Dashboard

//Lesson Plan Dashboard

import imgAddLessonPlan from "../../Assets/LessonPlanDashboard/add lesson plan.svg";
import imgViewLessonPlan from "../../Assets/LessonPlanDashboard/view lesson plan.svg";

///End Lesson plan Dashboard

//Syllabus Dashboard

import imgAddSyllabus from "../../Assets/SyllabusDashboard/add syllabus.svg";
import imgViewSyllabus from "../../Assets/SyllabusDashboard/view syllabus.svg";

//End Syllabus Dashboard

//Past Paper Dashboard

import imgAddPastPaper from "../../Assets/PastPapersDashboard/add past paper.svg";
import imgViewPastPaper from "../../Assets/PastPapersDashboard/view past paper.svg";

//End Past Paper Dashboard

//Test Dashboard

import imgAddTestInfo from '../../Assets/TestDashboard/add test info.svg'
import imgAddTest from '../../Assets/TestDashboard/add test.svg'
import imgViewTestInfo from '../../Assets/TestDashboard/view test info.svg'
import imgViewTest from '../../Assets/TestDashboard/view test.svg'
import imgViewTestInfoDept from '../../Assets/TestDashboard/view test info dept.svg'
import imgViewTestDept from '../../Assets/TestDashboard/view test dept.svg'
import imgViewTestInfoStudent from '../../Assets/TestDashboard/view test info student.svg'

//End Test Dashboard

//Staff Leave

import imgStaffLeave from '../../Assets/StaffLeaveDashboard/staff leave.svg';
import imgViewStaffLeave from '../../Assets/StaffLeaveDashboard/view leave.svg';
import imgAddStaffLeave from '../../Assets/StaffLeaveDashboard/add leave.svg';
import imgAddStaffShortLeave from '../../Assets/StaffLeaveDashboard/add short leave.svg';
import imgViewStaffShortLeave from '../../Assets/StaffLeaveDashboard/view short leave.svg';
import imgStaffShortLeave from '../../Assets/StaffLeaveDashboard/short leave.svg';
import imgStaffLeaveHR from '../../Assets/StaffLeaveDashboard/view leave hr.svg'
import imgStaffLeaveDept from '../../Assets/StaffLeaveDashboard/view leave department.svg'

//end Staff Leave

//#region BioMetric
import imgBioMetricAttendance from "../../Assets/BioMetricDashboard/biometric attendance.svg";
import imgBioMetricRegistered from "../../Assets/BioMetricDashboard/biometric attendance icon.svg";
import imgBioMetric from "../../Assets/BioMetricDashboard/biometric main icon.svg";
//#endregion

//Staff Time Table
import imgStaffTimeTable from '../../Assets/TimeTableDashboard/staff timetable.svg';
import imgConsolidatedTimeTable from '../../Assets/TimeTableDashboard/consolidated timetable.svg';
import imgTimeTableCooridnator from '../../Assets/TimeTableDashboard/timetable coordinator.svg';
//end Time Table

//#region Additional Fixes
import imgAddStudentInquiry from '../../Assets/AdditionalFixesBranchIcons/add student inquiry.svg'
import imgCensusModule from '../../Assets/AdditionalFixesBranchIcons/census module.svg'
import imgFeeCollectionUpdate from '../../Assets/AdditionalFixesBranchIcons/fee collection.svg'
import imgFrontDeskStudent from '../../Assets/AdditionalFixesBranchIcons/front desk.svg'
import imgStudentRecordManagement from '../../Assets/AdditionalFixesBranchIcons/student record mangement.svg'
import imgViewAdmittedStudents from '../../Assets/AdditionalFixesBranchIcons/view admitted studentss.svg'
import imgViewAgeWise from '../../Assets/AdditionalFixesBranchIcons/view age wise.svg'
import imgViewClassWise from '../../Assets/AdditionalFixesBranchIcons/view class wise.svg'
import imgViewIndentForm from '../../Assets/AdditionalFixesBranchIcons/view indent formmm.svg'
import imgStaffLeaveUpdated from '../../Assets/AdditionalFixesBranchIcons/view staff leave.svg'
import imgStaffShortLeaveUpdated from '../../Assets/AdditionalFixesBranchIcons/view staff short leave.svg'
import imgViewStudentInquiry from '../../Assets/AdditionalFixesBranchIcons/view student inquiry.svg'
import imgViewStudentWise from '../../Assets/AdditionalFixesBranchIcons/view student wise.svg'
//#endregion

// //#region  Login time
import imgViewLoginTime from '../../Assets/LoginTimeDashboard/view login time.svg';
import imgAddLoginTime from '../../Assets/LoginTimeDashboard/add login time.svg';
//end Login Time

//Special Education
import imgAddSpecialEducation from '../../Assets/SENManagerDashboard/special education add icon 1.svg';
import imgViewSpecialEducation from '../../Assets/SENManagerDashboard/special education veiw icon.svg';

export default {
// export of smart assests
          imageFSmart,
          imageStuSmart,
          imageAdSmart,
          imagehrSmart,
          imageLiSmart,
          imageMaSmart,
          imagePsSmart,
          imageTTSmart,
          imageCCmart,
          imageAdminsmart,
          imageAcadsmart,
          imageSSSmart,
// end of exports of smart assests
  // system smart exports
      imageSystemSmart,
  // end of system smart exports

  // module images exports

imageModulefianance,
imageModuleAcademic,
imageModuleStudent,
imageModuleSystem,
imageModuleTimetable,
imageModuleAdministration,
imageModuleAdmins,
imageModuleCommunication,
imageModuleHr,
imageModulePastoral,
imageModuleLibrary,
imageModuleManagers,

// end of module image 

  imgStudentAdd,
  imgStudentView,
  imgAddFeeType,
  imgViewFeeType,
  imgFeeCollection,
  imgFeeView,
  imgFeesMain,
  imgBillingMain,
  imgBDash,
  imgFDash,
  imgAdDash,
  imgAcDash,
  imgAdmissionDash,
  imgViewAddForm,
  imgHr,
  imgAdminForm,
  imgComDash,
  imgHRDash,
  imgPasDash,
  imgSysDash,
  imgGMDash,

  imgBDash1,
  imgFDash1,
  imgStaffDocs,
  imgAdDash1,
  imgAcDash1,
  imgAdmissionDash1,
  imgComDash1,
  imgHRDash1,
  imgPasDash1,
  imgSysDash1,
  imgGMDash1,
  imgLPW,
  imgTT,
  ttSubstitutionPeriodList,
  ttApplySubstitution,
  ttViewCutOffTime,
  ttAddCutOffTime,

  addTT,
  viewTT,
  ttAllPriorityList,
  ttAllDailySubstitution,
  addLPW,
  viewLPW,
  imgTThead,
  imgAcHead,
  imgFHead,
  imgALDash,
  imgBudgetDash,
  imgAddIcon,
  imgViewIcon,
  imgHRSRM,
  imgHRDevTrain,
  imgHRFrontDesk,
  imgHRDept,
  imgHRpost,
  imgHRStaffDoc,
  imgHRVacany,
  imgHRSalary,
  imgHrInterview,
  imgHrHead,
  imgAcademicLMS,
  imgCaPmTool,
  imgCAImage,
  imgCAViewImage,
  imgAcademicCMS,
  imgAcademicSID,
  imgAcademicHomeWork,
  imgAcademicAssignment,
  imgAcademicLesson,
  imgAcademicExam,
  imgAcademicSyllabus,
  imgAcademicTimeTable,
  imgAcademicQuiz,
  imgAcademicTest,
  imgAcademicPastPaper,
  imgAcademicClass,
  imgAcademicSection,
  imgAcademicSession,
  imgAcademicSubject,
  imgAcademicAssignClassTeacher,
  imgAcademicIndent,
  imgAcademicTeacherEvaluation,
  imgAcademicCalendar,

  imgAcademicSkill,
  imgAcademicDescriptive,
  imgAddSubDiscipline,
  imgSubDiscipline,
  imgViewSubDiscipline,

  imgAddClassDiscipline,
  imgViewClassDiscipline,
  imgClassDiscipline,
  imgUpdateClassDiscipline,

  imgStudentManager,
  imgParentManager,
  imgEventCalender,
  imgBulletin,
  imgPortalCoordinator,
  
  imgAddParentResgitration,
  imgUpdateParentResgitration,
  imgRegisterParentResgitration,
  addstaff,
  viewstaff,
  imgManagerDashboard,
  imgAddManager,
  imgViewManager,
  imgadmis,

  imgaddBilling,
  imgViewBilling,
  imgAddPayRoll,
  imgViewPayRoll,
  imgViewPaySlip,
  imgPayRoll,
  imgAddBook,
  imgViewBook,
  imgIssuedBook,
  imgViewIssued,
  imgLibraryManage,
  imgAddBookCategory,
  imgViewBookCategroy,
  imgLabSmart,

  imgAddEvent,
  imgViewEvent,
  imgUpdateEvent,

  AddStudentProgressReport,
  ViewStudentProgressReport,
  DashboardStudentProgressReport,
  
  imgMaintenanceCoordinator,
  imgDashboardCampusMaintenance,

  imgAddFloorCampusMaintenance,
  imgViewFloorCampusMaintenance,
  imgAddRoomCampusMaintenance,
  imgViewRoomCampusMaintenance,
  imgAddCleanlinessCategoryCampusMaintenance,
  imgViewCleanlinessCategoryCampusMaintenance,
  imgAddCyclicCleanlinessCampusMaintenance,
  imgViewCyclicCleanlinessCampusMaintenance,
  imgAddEmergencyMaintenanceCampusMaintenance,
  imgViewEmergencyMaintenanceCampusMaintenance,
  imgNotificationEmergencyMaintenanceCampusMaintenance,

  AddStudentTalentSENManager,
  ViewStudentTalentSENManager,
  AddTalentCategorySENManager,
  ViewTalentCategorySENManager,
  AddTalentSubCategorySENManager,
  ViewTalentSubCategorySENManager,
  StudentTalentSENManager,

  DailyAbsentReportWellbeingManager,
  DailyLateReportWellbeingManager,
  DailyReportsWellbeingManager,
  FlagManagementWellbeingManager,
  FlagManagementAttendanceWellbeingManager,
  FlagManagementSkillWellbeingManager,
  StudentSkillAverageWellbeingManager,
  StudentDisciplineReportWellbeingManager,
  MonthlyStudentAttendanceWellbeingManager,
  DailyClassAttendanceWellbeingManager,
  DailyDisciplineReportWellbeingManager,
  ViewMedicalHistoryWellbeingManager,

  SENManagerPastrol,
  WellbeingManagerPastrol,

  imgAddClass,
  imgViewClass,
  imgAddSection,
  imgViewSection,
  imgAddSession,
  imgViewSession,
  imgAddSubject,
  imgViewSubject,
  imgClassTeacher,
  imgAddClassTeacher,
  imgViewClassTeacher,
  imgAddIndent,
  imgViewIndent,
  imgAdminProcurement,
  imgAddTeacherEvaluation,
  imgViewTeacherEvaluation,

  imgPastoralMedicalCenter,
  imgStudent,

  imgDoctor,
  imgAddDoctor,
  imgViewDoctor,
  imgPatient,
  imgAddPatient,
  imgViewPatient,
  imgViewMedHistory,

  imgCampusSecurity,
  imgCSecurity,
  imgCSGenerateCard,
  imgCSViewVisitor,
  imgCSAddVisitor,

  imgAcademicLeave,
  imgAddLeave,
  imgViewLeaveStaff,
  imgViewLeaveStudent,

  imgMedicalCenter,
  imgPastoralSmart,

  imgAddTour,
  imgPastoralTour,
  imgViewTourFilters,
  imgViewTour,
  imgPastoralActivity,

  imgCertificateTemplate1,
  imgCertificateTemplate2,
  imgActivityCertificate,
  imgAddCertificate,
  imgViewCertificate,
  imgOtherCertificate,
  imgAddOtherCertificate,
  imgViewOtherCertificate,
  imgAddCertificateCategory,
  imgCertificateCategory,
  imgViewCertificateCategory,

  imgAward,
  imgAddAward,
  imgViewAward,
  imgAddAwardCategory,
  imgViewAwardCategory,

  imgGames,
  imgAddGames,
  imgViewGames,
  imgOffGames,
  imgAddOffGame,
  imgViewOffGame,
  imgAddCandidate,
  imgViewCandidate,
  imgEntryExam,

  imgAddEventCertificate,
  imgViewEventCertificate,
  imgEventCertificate,

  imgRoutes,
  imgAddRoute,
  imgViewRoute,

  imgDriver,
  imgAddDriver,
  imgViewDriver,

  imgVehicle,
  imgAddVehicle,
  imgViewVehicle,

  imgReport,
  imgAddReport,
  imgViewReport,

  imgStudentCoordinator,
  imgClassOfStudent,
  imgTransferStudent,
  imgAssignClassToStudent,

  imgStaffCoordinator,
  imgAssignSubjectToTeacher,
  imgViewAssignSubject,
  imgAssignSubject,
  imgAddStaffInDepartment,
  imgAssignHOD,
  imgViewAssignHOD,
  imgAssignCoordinator,
  imgViewCoordinator,
  imgGradeCoordinator,

  imgExclusionManager,
  imgAddExclusion,
  imgViewExclusion,
  imgStudentExclusionRecord,
  imgInvestigationCommittee,
  imgAddComplain,
  imgViewComplain,
  imgInvestigationList,
  imgFinanaceHeading,
  imgSystemSmart,
  imgAdmissionHeading,
  imgHRHeading,
  imgSearchParentRegistration,
  imgParentRegistration,
  imgFinanceManager,
  imgFees,
  imgFeeReport,
  imgFeeReportMonthly,
  imgFeeReportYearly,
  imgFeeReportDateWise,
  imgAccounts,
  imgAccountLedg,
  imgAssignBudget,
  imgViewBudget,
  imgAddBudgetItems,
  imgViewBudgetItems,

  imgPastoralActivityD,
  imgAddAwardCategoryD,
  imgAddAwardD,
  imgAddCategoryD,
  imgAddDoctorD,
  imgAddEventCertificateD,
  imgAddGamesD,
  imgAddOffGameD,
  imgAddOtherCertificateD,
  imgAddpatientD,
  AddStudentTalentSENManagerD,
  AddTalentCategorySENManagerD,
  AddTalentSubCategorySENManagerD,
  imgAddTourD,
  imgAwardD,
  imgCertificateCategoryD,
  imgActivityCertificateD,
  DailyAbsentReportWellbeingManagerD,
  DailyClassAttendanceWellbeingManagerD,
  DailyDisciplineReportWellbeingManagerD,
  DailyLateReportWellbeingManagerD,
  DailyReportsWellbeingManagerD,
  imgDoctorD,
  imgEventCertificateD,
  FlagManagementAttendanceWellbeingManagerD,
  FlagManagementSkillWellbeingManagerD,
  imgGamesD,
  imgMedicalCenterD,
  imgViewMedicalHistoryD,
  MonthlyStudentAttendanceWellbeingManagerD,
  imgOffGamesD,
  imgOtherCertificateD,
  imgPatientD,
  SENManagerPastrolD,
  StudentDisciplineReportWellbeingManagerD,
  StudentSkillAverageWellbeingManagerD,
  StudentTalentSENManagerD,
  imgViewTourFiltersD,
  imgPastoralTourD,
  imgViewAwardCategoryD,
  imgViewAwardD,
  imgViewCertificateCategoryD,
  imgViewDoctorD,
  imgViewEventCertificateD,
  imgViewGamesD,
  imgViewMedHistoryD,
  imgViewOffGameD,
  imgViewOtherCertificateD,
  imgViewPatientD,
  ViewStudentTalentSENManagerD,
  ViewTalentCategorySENManagerD,
  ViewTalentSubCategorySENManagerD,
  imgViewTourD,
  WellbeingManagerPastrolD,
  imgPastoralSmartD,
  imgAddComplainD,
  imgViewComplainD,
  imgStudentExclusionRecordD,
  imgInvestigationCommitteeD,
  imgInvestigationListD,
  imgExclusionManagerD,
  imgInventoryMain,
  imgAddStock,
  imgViewStock,
  imgAddCategory,
  imgViewCategory,
  imgAddSupplier,
  imgViewSupplier,
  imgAddItem,
  imgViewItem,
  imgAddStockUsage,
  imgViewStockUsage,
  imgCensusMain,
  imgSearchAgeWise,
  imgSearchClassWise,
  imgSearchStudent,

  imgComCo1,
  imgComCo2,
  imgComCo3,
  imgComCo4,
  imgComCo5,
  imgCommuncationCoordinator,
  imgNewMessage,
  imgInbox,

  imgAttendanceSP,
  imgCASP,
  imgdescriptiveSP,
  imgStudentProfile,
  imgProgressReportSP,
  imgStudentSkillsSP,
  imgSubDisciplineSP,

  imgStudentAttendance,
  imgStudentAttendanceAdd,
  imgStudentAttendanceView,
  imgStudentSkillsAdd,
  imgStudentSkillsView,
  imgDescriptiveAdd,
  imgDescriptiveView,
  imgCampus,
  imgCampusAdd,
  imgCampusView,
  imgCanteen,
  imgCanteenAddCategory,
  imgCanteenViewCategory,
  imgCanteenAddItem,
  imgCanteenViewItem,
  imgAddCanteen,
  imgViewCanteen,
  imgHrDevelopmentAdd,
  imgHrDevelopmentEdit,
  imgHrDevelopmentView,
  imgHrDevelopmentMain,
  imgFrontAdmittedViewStaff,
  imgFrontAdmittedViewStudent,
  imgFrontDeskAdd,
  imgFrontDeskView,
  imgDepartment,
  imgDepartmentAdd,
  imgDepartmentView,
  imgDepartmentSearchStaffByDepartment,
  imgPost,
  imgPostAdd,
  imgPostView,
  imgDepartmentSearchStaffByPost,

  imgAddHomework,
  imgViewHomework,

  imgAddExamInfo,
  imgViewExamInfo,
  imgAddExamDetails,
  imgViewExamDetails,

  imgAddQuizInfo,
  imgViewQuizInfo,
  imgAddQuiz,
  imgViewQuiz,

  imgAddAssignment,
  imgViewAssignment,

  imgAddLessonPlan,
  imgViewLessonPlan,

  imgAddSyllabus,
  imgViewSyllabus,

  imgAddPastPaper,
  imgViewPastPaper,

  imgAddTest,
  imgAddTestInfo,
  imgViewTest,
  imgViewTestInfo,
  imgViewTestDept,
  imgViewTestInfoDept,
  imgViewTestInfoStudent,
  
  imgStaffLeave,
	imgAddStaffLeave,
	imgViewStaffLeave,
	imgStaffShortLeave,
	imgAddStaffShortLeave,
  imgViewStaffShortLeave,
  imgStaffLeaveHR,
  imgStaffLeaveDept,
  

  imgBioMetric,
  imgBioMetricAttendance,
  imgBioMetricRegistered,

  imgTimeTableCooridnator,
	imgConsolidatedTimeTable,
  imgStaffTimeTable,
  
  imgAddStudentInquiry,
  imgCensusModule,
  imgFeeCollectionUpdate,
  imgFrontDeskStudent,
  imgStudentRecordManagement,
  imgViewAdmittedStudents,
  imgViewAgeWise,
  imgViewClassWise,
  imgViewIndentForm,
  imgStaffLeaveUpdated,
  imgStaffShortLeaveUpdated,
  imgViewStudentInquiry,
  imgViewStudentWise,

  imgAddLoginTime,
  imgViewLoginTime,

  imgFloor,
  imgFloorPlan,
  imgRoom,
  imageModuleCommunicationCMS,
  imgEventCertificateG,
  imgEventG,
  imgTourG,

  imgAcademicPlan,
  imgFamilyTree,

  imgAcademicOnlineResource,
  imgInvoiceBooking,

  imgAddSpecialEducation,
  imgViewSpecialEducation,
};

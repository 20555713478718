import React, { Component } from "react";
import ThemeContext from "../../context/themeContext/ThemeContext";

class ClassComponent extends Component {
  state = {};
  render() {
    const {
      classes,
      onHandleChange,
      sectionsData,
      onHandleSection,
    } = this.props;
    return (
      <ThemeContext.Consumer>
        {(context) => (
          <div class="row">
            <div class="col-md-6" style={{ marginTop: 20 }}>
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/* <i
                                style={{ marginRight: 4 }}
                                className="fas fa-map animated fadeIn"
                            /> */}
                  Class Name
                </label>
                <select
                  onChange={(e) => onHandleChange(e)}
                  class="custom-select"
                  name="className"
                  style={{ borderColor: context.themeColors.primaryColor }}
                >
                  <option selected={this.state.msg ? true : false}>
                    Choose Class...
                  </option>
                  {classes &&
                    classes.map((item, index) => (
                      <option key={index} value={`${item.id}`}>
                        {item.className}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div class="col-md-6" style={{ marginTop: 20 }}>
              <div class="md-form mb-0">
                <label for="email" class="">
                  {/* <i
                                style={{ marginRight: 4 }}
                                className="fas fa-map animated fadeIn"
                            /> */}
                  Section
                </label>
                <select
                  onChange={(e) => onHandleSection(e)}
                  class="custom-select"
                  name="className"
                  style={{ borderColor: context.themeColors.primaryColor }}
                >
                  <option selected={this.state.msg ? true : false}>
                    Choose Section...
                  </option>
                  {sectionsData &&
                    sectionsData.map((item, index) => (
                      <option key={index} value={`${item.sectionId}`}>
                        {item.section}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default ClassComponent;

import { General } from "../../utils";
import staff from "../../hooks/HookReducers/StaffReducer";
let errors = {};

function getStaffDocsDetails(stateData, dispatch) {
  const staffDocs = [
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Experience Letter",
      dispatchFlag: true,
      idForYes: "customRadioSELY",
      idForNo: "customRadioSELN",
      feildName: "experienceLetter",
      uploadName: "experienceLetterfilePath",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "IELTS Certificate",
      dispatchFlag: true,
      idForYes: "customRadioSICY",
      idForNo: "customRadioSICN",
      feildName: "iletsCertificate",
      uploadName: "iletsCertificatefilePath",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Ministry Approval",
      dispatchFlag: true,
      idForYes: "customRadioSMAY",
      idForNo: "customRadioSMAN",
      feildName: "ministryApproval",
      uploadName: "ministryApprovalfilePath",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Last Qualification",
      dispatchFlag: true,
      idForYes: "customRadioSLQY",
      idForNo: "customRadioSLQN",
      feildName: "lastQualification",
      uploadName: "lastQualificationfilePath",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Passport Copy",
      dispatchFlag: true,
      idForYes: "customRadioSPCY",
      idForNo: "customRadioSPCN",
      feildName: "passportCopy",
      uploadName: "passportCopyfilePath",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Id Card Copy",
      dispatchFlag: true,
      idForYes: "customRadioSICCY",
      idForNo: "customRadioSICCN",
      feildName: "idCardCopy",
      uploadName: "idCardCopysfilePath",
    },
  ];
  return staffDocs;
}
function getStaffDetailsFeilds(
  state,
  stateData,
  editMode = false,
  dispatch,
  ...args
) {
  console.log(args);
  debugger;
  // for post purpose
  let getDepartData = stateData && stateData.depart;
  // end post purpose data

  let getFilteredSection = [];
  let getFilteredPost = [];
  let getFilteredSubjects = [];
  let getFilteredStaffpost = [];
  try {
    getFilteredStaffpost = General.staffPost.filter(
      (item) => item.name === parseInt(state.staffPost)
    );

    getFilteredSection =
      args[5] &&
      args[5].sections.filter(
        (item) => item.classId === parseInt(state.classId)
      );
    getFilteredPost =
      args[1] &&
      args[1].post.filter(
        (item) => item.departmentId === parseInt(state.departmentId)
      );
    getFilteredSubjects =
      args[5] &&
      args[5].subject.filter(
        (item) => item.classId === parseInt(state.classId)
      );
  } catch (e) {}
  debugger;
  const staffFeilds = [
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "StaffFirstName",
      inputLabelName: "First Name",
      errors,
      placeholder: "First Name",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "lastName",
      inputLabelName: "Last Name",
      errors,
      placeholder: "Last Name",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "fullNameArabic",
      inputLabelName: "Arabic Name",
      errors,
      placeholder: "Arabic Name",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "fatherName",
      inputLabelName: "Father Name",
      errors,
      placeholder: "Father Name",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "designation",
      inputLabelName: "Previous Designation",
      errors,
      placeholder: "Previous Designation",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "state",
      inputLabelName: "Institution",
      errors,
      placeholder: "Institution",
      // readOnly={true}
      design: true,
      type: "text",
    },

    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "email",
      inputLabelName: "Email",
      errors,
      placeholder: "Email",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      type: "date",
      marginTop: 0,
      feildName: "dob",
      inputLabelName: "Date Of Birth",
      dispatchFlag: true,
      stateData,
      dispatch,
      extraType: "age",
    },
    {
      type: "date",
      marginTop: 0,
      feildName: "dateOfJoining",
      inputLabelName: "Date Of Joining",
      dispatchFlag: true,
      stateData,
      dispatch,
      extraType: "",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      // onHandleChange: General.handleDumpInputChange,
      stateData,
      feildName: "age",
      inputLabelName: "Age",
      errors,
      placeholder: "Age",
      readOnly: true,
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "basicSalary",
      inputLabelName: "Basic Salary",
      errors,
      placeholder: "Basic Salary",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      marginTop: 0,
      feildName: "staffPostId",
      selectName: "Portal Reference",
      selectedText: "Choose Post.",
      optionsArrays: General.staffPost,
      errors,
      stateData: stateData,
      optionType: "dynamic",
      design: true,
      isValidte: false,
      dispatchFlag: true,
      dispatch,
      handleFlag: false,
      propterty: "name",
      propertyId: "id",
      type: "select",
    },
    {
      marginTop: 0,
      feildName: "religion",
      selectName: "Religion",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.religion,
      selectedText: "Choose Religion...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      feildName: "bloodGroup",
      selectName: "Blood Group",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.BloodGroup,
      selectedText: "Choose Group...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      feildName: "gender",
      selectName: "Gender",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.gender,
      selectedText: "Choose Gender...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      feildName: "maritailStatus",
      selectName: "Marital Status",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.maritalStatus,
      selectedText: "Marital Status...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      feildName: "nationality",
      selectName: "Nationality",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.country.map((item) => item.name),
      selectedText: "Choose Nationality...",
      optionType: "static",
      type: "flagSelect",
    },
    {
      marginTop: 0,
      feildName: "pob",
      selectName: "Place Of Birth",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.country.map((item) => item.name),
      selectedText: "Choose Place Of Birth...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      feildName: "appraisalStatus",
      selectName: "Status",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.status,
      selectedText: "Choose Status...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      feildName: "status",
      selectName: "Appraisal Status",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.appStatus,
      selectedText: "Choose Status...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      selectName: "Campus Name",
      feildName: "campusId",
      selectedText: "Choose Campus...",
      optionsArrays: args[3],
      errors,
      stateData: stateData,
      optionType: "dynamic",
      // onHandleChange: General.handleDumpInputChange,
      design: true,
      isValidte: false,
      dispatchFlag: true,
      dispatch,
      handleFlag: false,
      propterty: "campusName",
      propertyId: "id",
      type: "select",
    },
    {
      marginTop: 0,
      selectName: "Position",
      feildName: "departmentId",
      selectedText: "Choose Depart",
      optionsArrays: editMode
        ? args && args[1] && args["1"]["department"]
        : getDepartData,
      errors,
      stateData: stateData,
      optionType: "dynamic",
      // onHandleChange: General.handleDumpInputChange,
      design: true,
      isValidte: false,
      dispatchFlag: true,
      dispatch,
      handleFlag: false,
      propterty: "departmentName",
      propertyId: "departmentId",
      type: "select",
      isFeildDisabled: editMode ? true : false,
    },
    {
      marginTop: 0,
      selectName: "Post",
      feildName: "postId",
      selectedText: "Choose Post",
      optionsArrays: editMode ? getFilteredPost : stateData && stateData.post,
      errors,
      stateData: stateData,
      optionType: "dynamic",
      // onHandleChange: General.handleDumpInputChange,
      design: true,
      isValidte: false,
      dispatchFlag: true,
      dispatch,
      handleFlag: false,
      propterty: "postName",
      propertyId: "postId",
      type: "select",
    },
    {
      marginTop: 0,
      selectName: "Class",
      feildName: "classId",
      selectedText: "Choose Class",
      optionsArrays: args[0],
      errors,
      stateData: state,
      optionType: "dynamic",
      // onHandleChange: General.handleDumpInputChange,
      design: true,
      isValidte: false,
      handleFlag: false,
      dispatchFlag: false,
      dynamicDispatchFlag: true,
      type: "select",
      propterty: "className",
      propertyId: "classId",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      // onHandleChange: General.handleDumpInputChange,
      stateData,
      feildName: "gradeName",
      inputLabelName: "GradeName",
      errors,
      placeholder: "GradeName",
      readOnly: true,
      design: true,
      type: "text",
    },
    {
      marginTop: 0,
      selectName: "Section",
      feildName: "sectionId",
      selectedText: "Choose Section",
      optionsArrays: editMode
        ? getFilteredSection
        : stateData && stateData.grade,
      errors,
      stateData: stateData,
      optionType: "dynamic",
      // onHandleChange: General.handleDumpInputChange,
      design: true,
      isValidte: false,
      dispatchFlag: true,
      dispatch,
      handleFlag: false,
      propterty: "section",
      propertyId: "sectionId",
      type: "select",
    },
    {
      marginTop: 0,
      selectName: "Subject",
      feildName: "subjectId",
      selectedText: "Choose Subject",
      optionsArrays: editMode
        ? getFilteredSubjects
        : stateData && stateData.subjectData,
      errors,
      stateData: stateData,
      optionType: "dynamic",
      // onHandleChange: General.handleDumpInputChange,
      design: true,
      isValidte: false,
      dispatchFlag: true,
      dispatch,
      handleFlag: false,
      propterty: "subjectName",
      propertyId: "subjectId",
      type: "select",
    },

    // {
    // 	enterWidth: 300,
    // 	customWidth: true,
    // 	isValidte: false,
    // 	onHandleChange: General.handleDumpInputChange,
    // 	stateData: state,
    // 	feildName: 'username',
    // 	inputLabelName: 'Username',
    // 	errors,
    // 	placeholder: 'Username',
    // 	focusEvent: General.checkBlur,
    // 	focus: true,
    // 	readOnly: editMode
    // 		? !stateData['isAccountEditAccess']
    // 		: !stateData['isAccountAccess'],
    // 	design: true,
    // 	type: 'text'
    // },
    // {
    // 	enterWidth: 300,
    // 	customWidth: true,
    // 	isValidte: false,
    // 	onHandleChange: General.handleDumpInputChange,
    // 	stateData: state,
    // 	feildName: 'password',
    // 	inputLabelName: 'Password',
    // 	errors,
    // 	placeholder: 'Password',
    // 	readOnly: editMode
    // 		? !stateData['isAccountEditAccess']
    // 		: !stateData['isAccountAccess'],

    // 	design: true,
    // 	type: 'text'
    // }
  ];
  return staffFeilds;
}
function getPassportFeildData(
  nonStateData,
  stateData,
  editMode = false,
  dispatch
) {
  let newStateData = editMode ? nonStateData : stateData;

  const passportFields = [
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      feildName: "staffPassportNo",
      inputLabelName: "Passport Number",
      errors: errors,
      stateData: nonStateData,
      placeholder: "Passport Number",
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      feildName: "staffIdcardNo",
      inputLabelName: "Id Card Number",
      errors: errors,
      stateData: nonStateData,
      placeholder: "Id Card Number",
      design: true,
      type: "text",
    },
    {
      type: "date",
      marginTop: 0,
      feildName: "staffPassportDOI",
      inputLabelName: "Passport Issue Date",
      dispatchFlag: true,
      dispatch,
      stateData: newStateData,
      extraType: "",
    },
    {
      type: "date",
      marginTop: 0,
      feildName: "staffIdcardDOI",
      inputLabelName: "Id Card Issue Date",
      dispatchFlag: true,
      dispatch,
      stateData: newStateData,
      extraType: "",
    },
    {
      type: "date",
      marginTop: 0,
      feildName: "staffPassportDOE",
      inputLabelName: "Passport Expiry Date",
      dispatchFlag: true,
      dispatch,
      stateData: newStateData,
      extraType: "",
    },
    {
      type: "date",
      marginTop: 0,
      feildName: "staffIdcardDOE",
      inputLabelName: "Id Expiry Date",
      dispatchFlag: true,
      stateData: newStateData,
      dispatch,
      extraType: "",
    },
  ];
  return passportFields;
}
function getStaffFeildData(state, dispatch) {
  let fields = [
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "city",
      inputLabelName: "City",
      errors,
      placeholder: "City",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "houseNo",
      inputLabelName: "House No",
      errors,
      placeholder: "House No",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "wayNo",
      inputLabelName: "Way No",
      errors,
      placeholder: "Way No",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "mobileNumber",
      inputLabelName: "Phone Number",
      errors,
      placeholder: "Phone Number",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "StreetNo",
      inputLabelName: "Area",
      errors,
      placeholder: "Area",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      marginTop: 0,
      feildName: "country",
      selectName: "Country",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.country.map((item) => item.name),
      selectedText: "Choose Country",
      optionType: "static",
      type: "select",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "address",
      inputLabelName: "State/Province",
      errors,
      placeholder: "Add State/Province",
      row: 3,
      col: 25,
      // readOnly={true}
      design: true,
      type: "textarea",
    },
  ];
  return fields;
}

export default {
  getStaffFeildData,
  getPassportFeildData,
  getStaffDetailsFeilds,
  getStaffDocsDetails,
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CanteenCategoryActions from "../../Actions/CanteenCategory";
import * as sharedActions from "../../Actions/sharedActions";
import DateandTime from "../Transport/DateandTime";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddCanteenCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CategoryName: "",
      CanteenName: "",
      select: "",
      msg: false,
      errorMsg: false,
      CanteenId: "",
      CanteenName: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.select !== nextProps.selectData) {
      return {
        select: nextProps.selectData,
      };
    }
    return null;
  }

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      CanteenId: result.t1.id,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { CategoryName, CanteenName, select } = this.state;
    if (Object.is(CategoryName, "")) {
      this.setState({
        errorMsg: true,
      });
    } else {
      this.setState({
        valid: true,
      });
      var bodyParameters = {
        name: this.state.CategoryName,
        canteenId: this.state.CanteenId,
        accountType: this.props.userData.unique_name,
        accountId: this.props.userData.role,
      };
      this.props.sharedActions
        .addRecord(
          "/api/ItemsCategories",
          bodyParameters,
          this.props.CanteenCategoryActions.addCanteenCategory
        )
        .then((success) => {
          this.props.CanteenCategoryActions.updateCanteenCategoryFlag(true);
          this.setState({
            CategoryName: "",
            CanteenName: "",
            select: "",
            msg: true,
            errorMsg: false,
          });
        })
        .catch((error) => {});
    }
  };
  componentDidMount() {
    this.props.sharedActions
      .gAllData(
        "/api/Canteens",
        this.props.CanteenCategoryActions.getCanteenForCategory
      )
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }
  Message = (msg, color, fontSize, flag) => {
    if (flag) {
      return (
        <p style={{ fontSize: fontSize }} class={`text-${color}`}>
          {msg}
        </p>
      );
    }
  };
  render() {
    return (
      <div>
        <section class="mb-4">
          <h2 class="h1-responsive font-weight-bold text-center my-4">
            Add Items Category
          </h2>

          <p class="text-center w-responsive mx-auto mb-5" />

          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ThemeContext.Consumer>
              {(context) => (
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  {this.Message(
                    "Category Added Successfully!",
                    "success",
                    20,
                    this.state.msg
                  )}
                  {this.Message(
                    "Please fill the form properly!",
                    "danger",
                    20,
                    this.state.errorMsg
                  )}
                  <form
                    onSubmit={this.onHandleSubmit}
                    id="contact-form"
                    name="contact-form"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <DateandTime />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                          className="fas fa-user-shield animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
                            Admin Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            readOnly={true}
                            value={this.props.userData.unique_name}
                            type="text"
                            name="AdminName"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                          className="fas fa-user-alt animated fadeIn"
                          style={{ marginRight: 4 }}
                        /> */}
                            Admin Type
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            readOnly={true}
                            value={this.props.userData.role}
                            type="text"
                            name="AdminType"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                          className="fas fa-building"
                          style={{ marginRight: 4 }}
                        /> */}
                            Category Name
                          </label>
                          <input
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            type="text"
                            name="CategoryName"
                            class="form-control"
                            value={this.state.CategoryName}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-building animated fadeIn"
                        /> */}
                            Canteen Name
                          </label>
                          <select
                            onChange={this.onSelectChange}
                            class="custom-select"
                            style={{
                              borderColor: context.themeColors.primaryColor,
                            }}
                            name="CanteenName"
                          >
                            <option selected={this.state.msg ? true : false}>
                              Choose Canteen...
                            </option>
                            {this.state.select.map((item, index) => (
                              <option
                                key={index}
                                value={`${JSON.stringify(item)}`}
                              >
                                {item.t1.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                    <div class="text-center text-md-right">
                      <button
                        type="submit"
                        class="buttonHover2"
                        style={{
                          background: context.themeColors.primaryColor,
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>

                  <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
          </div>
        </section>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    CanteenCategoryActions: bindActionCreators(
      CanteenCategoryActions,
      dispatch
    ),
  };
}

function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
    selectData: state.setCanteenCategoryReducer.select,
    // handlerData: state.setCanteenReducer.selectHandler
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCanteenCategory);

import React, { Component } from "react";
import DateandTime from "../Transport/DateandTime";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as StockUsageActions from "../../Actions/StockUsage";
import * as sharedActions from "../../Actions/sharedActions";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddStockUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionForUsage: "",
      quantity: "",
      itemId: "",
      itemName: "",
      staffId: "",
      finalStaffId: "0",
      staffSections: [],
      staffs: [],
      staffName: null,
      finalStudentId: "0",
      studentId: null,
      studentName: null,
      select: [],
      dummy: [],
      dummyStaff: [],
      students: [],
      sections: [],
      errorMsg: false,
      msg: false,
      studentCheck: false,
      staffCheck: false,
      check: false,
      check2: false,
      grade: null,
      gradeName: null,
      staffGradeName: null,
      staffClassName: null,
      className: null,
      barcodeId: "",
      barcodeCheck: true,
      Barcode: "",
      floors: [],
      rooms: [],
      roomsFilter: [],
      department: [],
      postFilter: [],
      floorId: '0',
      roomId: '0',
      itemMessage: "",
      availableQuantity: "",
      itemerror: false,
      floorCheck: false,
      roomCheck: false,
      bCode: false,
      checked: false,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.select !== nextProps.select ||
      prevState.dummy !== nextProps.selectStudent ||
      prevState.dummyStaff !== nextProps.selectStaff ||
      prevState.Barcode !== nextProps.BarcodeData
    ) {
      return {
        select: nextProps.select,
        dummy: nextProps.selectStudent,
        dummyStaff: nextProps.selectStaff,
        Barcode: nextProps.BarcodeData,
      };
    }
    return null;
  }

  //UI Functions
  studentChange = (e) => {
    this.setState({
      studentCheck: !this.state.studentCheck,
      staffCheck: false,
      check: !this.state.check,
      check2: false,
      floorCheck: false,
      roomCheck: false
    });
  };
  staffChange = (e) => {
    this.setState({
      staffCheck: !this.state.staffCheck,
      studentCheck: false,
      check: false,
      check2: !this.state.check2,
      floorCheck: false,
      roomCheck: false
    });
  };
  selectFloor = (e) => {
    this.setState({
      floorCheck: !this.state.floorCheck,
      studentCheck: false,
      staffCheck: false,
      check: false,
      check2: false,
      roomCheck: false
    });
  };
  selectRooom = (e) => {
    this.setState({
      roomCheck: !this.state.roomCheck,
      studentCheck: false,
      staffCheck: false,
      check: false,
      check2: false,
      floorCheck: false
    });
  };
  barcodeValid = (e) => {
    e.preventDefault();
    this.setState({
      barcodeCheck: !this.state.barcodeCheck,
    });
  };
  onDisableCheck = (e) => {
    this.setState({
      barcodeCheck: !this.state.barcodeCheck,
      checked: !this.state.checked,
    });
  };

  componentDidMount() {
    this.props.sharedActions
      .gAllData(
        "/api/InventoryItems",
        this.props.StockUsageActions.getItemsForStockUsage
      )
      .then((success) => { })
      .catch((error) => { });
    this.props.sharedActions
      .getDataWithoutDispatch(
        "/api/Dropdown/FloorRoom"
      )
      .then((success) => {
        debugger;
        console.log("Floor Dropdwon API", success);
        this.setState({
          floors: success.floors,
          rooms: success.rooms
        })
      })
      .catch((error) => { });
    this.props.sharedActions
      .getDataWithoutDispatch(
        "/api/StaffPostDepart"
      )
      .then((success) => {
        debugger;
        console.log("Department API", success);
        this.setState({
          department: success
        })
      })
      .catch((error) => { });

    this.props.sharedActions
      .gAllData(
        "/api/Student",
        this.props.StockUsageActions.getStudentForStockUsage
      )
      .then((success) => { })
      .catch((error) => { });
    this.props.sharedActions
      .gAllData(
        "/api/Staff",
        this.props.StockUsageActions.getStaffForStockUsage
      )
      .then((success) => { })
      .catch((error) => { });
  }

  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onPrice = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ quantity: e.target.value });
      console.log(this.state.quantity);
    }
  };
  //End UI Functions

  //Items Function
  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      itemId: result.id,
      availableQuantity: result.currentStock
    });
  };
  //End Item Function

  //Barcode Function
  onSelectBarcodeChange = (e) => {
    console.log(e.target.value);
    this.setState({
      barcodeId: e.target.value,
    });

    this.props.sharedActions
      .gAllData(
        `/api/barcodes/?barcode=${e.target.value}`,
        this.props.StockUsageActions.getItemsForStockUsageBarcode
      )
      .then((success) => {
        this.setState({
          bCode: true,
          itemId: this.state.Barcode.id,
          itemName: this.state.Barcode.name,
        });
      })
      .catch((error) => {
        this.setState({
          bCode: false,
        });
      });
  };
  //End Barcode Function

  //Student Functions
  onSelectSectionChange = (e) => {
    console.log(e.target.value);

    const sections = this.state.dummy.sections;
    let check = sections.filter((item) => item.classId == e.target.value);
    console.log(check);

    this.setState({
      sections: check,
      gradeName: check[0] && check[0].section,
      className: e.target.value,
    });
  };

  onSelectStudentChange = (e) => {
    console.log(e.target.value);

    const student = this.state.dummy.student;
    let check2 = student.filter(
      (item) =>
        item.sectionId == e.target.value && item.classId == this.state.className
    );
    console.log(check2);

    this.setState({
      students: check2,
      studentId: check2[0] && check2[0].studentAdmissionFormId,
      studentName: e.target.value,
    });
  };
  onSelectFloorChange = (e) => {
    console.log(e.target.value);

    let check2 = this.state.rooms.filter(
      (item) =>
        item.floorId == e.target.value
    );
    console.log(check2);

    this.setState({
      roomsFilter: check2,
    });
  };

  onSelectFinalStudent = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      finalStudentId: result.studentId,
    });
  };

  //End Student Functions

  //Staff Functions
  onSelectStaffSectionChange = (e) => {
    console.log(e.target.value);

    const staffSections = this.state.dummyStaff.staffSections;
    let check = staffSections.filter((item) => item.classId == e.target.value);
    console.log(check);

    this.setState({
      staffSections: check,
      staffGradeName: check[0] && check[0].section,
      staffClassName: e.target.value,
    });
  };
  onDepartmentChange = (e) => {
    console.log(e.target.value);

    const post = this.state.department.post;
    let check = post.filter((item) => item.departmentId == e.target.value);
    console.log(check);

    this.setState({
      postFilter: check,
    });
  };
  onPostChange = (e) => {
    console.log(e.target.value);

    const post = this.state.department.staff;
    let check = post.filter((item) => item.postId == e.target.value);
    console.log(check);

    this.setState({
      staffs: check,
    });
  };

  onSelectStaffChange = (e) => {
    console.log(e.target.value);

    const staff = this.state.dummyStaff.staff;
    let check2 = staff.filter(
      (item) =>
        item.sectionId == e.target.value &&
        item.classId == this.state.staffClassName
    );
    console.log(check2);

    this.setState({
      staffs: check2,
      staffId: check2[0] && check2[0].staffId,
      staffName: e.target.value,
    });
  };

  onSelectFinalStaff = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      finalStaffId: result.staffId,
    });
  };
  //End Staff Functions

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { descriptionForUsage, quantity } = this.state;
    if (Object.is(descriptionForUsage, "") || Object.is(quantity, "")) {
      this.setState({
        valid: false,
        errorMsg: true,
      });
    } else {
      this.setState({
        valid: true,
      });
      var bodyParameters = {
        descriptionForUsage: this.state.descriptionForUsage,
        quantity: this.state.quantity,
        itemId: this.state.itemId,
        staffId: this.state.finalStaffId,
        studentId: this.state.finalStudentId,
        floorId: this.state.floorId,
        roomId: this.state.roomId,
        accountId: this.props.userData.unique_name,
        accountType: this.props.userData.role,
      };
      debugger;
      this.props.sharedActions
        .addRecord(
          `/api/stockUsages`,
          bodyParameters,
          this.props.StockUsageActions.addStockUsage
        )
        .then((success) => {
          debugger;
          // this.props.adminActions.updateGlobalFlag(true);
          this.setState({
            quantity: "",
            descriptionForUsage: "",
            barcodeId: "",
            bCode: false,
            barcodeCheck: true,
            studentCheck: false,
            staffCheck: false,
            msg: true,
            errorMsg: false,
            checked: false,
            check: false,
            check2: false,
            roomCheck: false,
            floorCheck: false
          });
        })
        .catch((error) => {
          debugger;
          this.setState({
            itemMessage: error.response.data.Message,
            itemerror: true
          })
        });
    }
    console.log(this.state);
  };
  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg || this.state.itemerror) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          itemerror: false
        });
      }, 3000);
    }
  }
  Message = (msg, color, fontSize, flag) => {
    if (flag) {
      return (
        <p style={{ fontSize: fontSize }} class={`text-${color}`}>
          {msg}
        </p>
      );
    }
  };
  render() {
    let inputC = {
      borderColor: "black",
    };
    const std = this.state.studentCheck;
    const stf = this.state.staffCheck;
    const { supplier, select, dummy } = this.state;
    const { classes } = this.state.dummy;
    const { student } = this.state.dummy;
    const { sections } = this.state.dummy;
    const { staffClass } = this.state.dummyStaff;
    console.log("STAFFS", this.state.staffs);

    return (
      <div>
        <section class="mb-4">
          <ThemeContext.Consumer>
            {(context) => (
              <h2
                style={{ background: context.themeColors.primaryColor }}
                className="h1-responsive font-weight-bold text-center my-4 generalHead">
                Add Stock Usage
              </h2>
            )}
          </ThemeContext.Consumer>

          <p class="text-center w-responsive mx-auto mb-5" />

          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ThemeContext.Consumer>
              {(context) => (
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  {this.Message(
                    "Stock Added Successfully!",
                    "success",
                    20,
                    this.state.msg
                  )}
                  {this.Message(
                    "Please fill the form properly!",
                    "danger",
                    20,
                    this.state.errorMsg
                  )}
                  {this.Message(
                    this.state.itemMessage,
                    "danger",
                    20,
                    this.state.itemerror
                  )}
                  <form
                    id="contact-form"
                    name="contact-form"
                    onSubmit={this.onHandleSubmit}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <DateandTime />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Name
                          </label>
                          <input
                            readOnly={true}
                            value={this.props.userData.unique_name}
                            type="text"
                            name="AdminName"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Admin Type
                          </label>
                          <input
                            readOnly={true}
                            value={this.props.userData.role}
                            type="text"
                            name="AdminType"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Barcode
                            <input
                              type="checkbox"
                              onClick={this.onDisableCheck}
                              style={{ marginLeft: 20 }}
                              checked={this.state.checked}
                            />
                          </label>
                          <input
                            disabled={this.state.barcodeCheck}
                            type="text"
                            name="barcode"
                            value={this.state.barcodeId}
                            class="form-control"
                            onChange={this.onSelectBarcodeChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            Item Name
                          </label>
                          <select
                            onChange={this.onSelectChange}
                            class="custom-select"
                            name="itemId"
                            style={
                              this.state.bCode ? { borderColor: "green" } : null
                            }
                          >
                            {this.state.bCode ? (
                              <option>{this.state.itemName}</option>
                            ) : (
                              <option selected={this.state.msg ? true : false}>
                                Choose Items...
                              </option>
                            )}
                            {this.state.select.map((item, index) => (
                              <option
                                key={index}
                                value={`${JSON.stringify(item)}`}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Quantity
                          </label>
                          <br />
                          <input
                            type="text"
                            name="quantity"
                            class="form-control"
                            value={this.state.quantity}
                            onChange={this.onPrice}
                          />
                          {this.state.availableQuantity !== "" ?
                            <label className="text-success"> <span> Available Quantity = {this.state.availableQuantity}</span></label>
                            : null
                          }
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            Description For Usage
                          </label>
                          <textarea
                            type="text"
                            name="descriptionForUsage"
                            class="form-control"
                            value={this.state.descriptionForUsage}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0" style={{ marginTop: 40 }}>
                          <label style={{ marginRight: 5 }}>Student</label>
                          <input
                            style={{ marginRight: 20 }}
                            type="checkbox"
                            onChange={this.studentChange}
                            checked={this.state.check}
                          />
                          <label style={{ marginRight: 5 }}>Staff</label>
                          <input
                            style={{ marginRight: 20 }}
                            type="checkbox"
                            onChange={this.staffChange}
                            checked={this.state.check2}
                          />
                          <label style={{ marginRight: 5 }}>Floor</label>
                          <input
                            style={{ marginRight: 20 }}
                            type="checkbox"
                            onChange={this.selectFloor}
                            checked={this.state.floorCheck}
                          />
                          <label style={{ marginRight: 5 }}>Room</label>
                          <input
                            style={{ marginRight: 20 }}
                            type="checkbox"
                            onChange={this.selectRooom}
                            checked={this.state.roomCheck}
                          />
                        </div>
                      </div>
                    </div>

                    {stf ? (
                      <div class="row">
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Department
                            </label>
                            <select
                              onChange={this.onDepartmentChange}
                              class="custom-select"
                              name="staffId"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Select Deaprtment...
                              </option>
                              {this.state.department && this.state.department.department.map((item, index) => (
                                <option key={index} value={`${item.departmentId}`}>
                                  {item.departmentName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Post
                            </label>
                            <select
                              onChange={this.onPostChange}
                              class="custom-select"
                              name="staffId"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Select Post...
                              </option>
                              {this.state.postFilter && this.state.postFilter.map((item, index) => (
                                <option key={index} value={`${item.postId}`}>
                                  {item.postName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Staff Class Name
                            </label>
                            <select
                              onChange={this.onSelectStaffSectionChange}
                              class="custom-select"
                              name="staffId"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Choose Name...
                              </option>
                              {staffClass.map((item, index) => (
                                <option key={index} value={`${item.classId}`}>
                                  {item.className}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Staff Class Section
                            </label>
                            <select
                              onChange={this.onSelectStaffChange}
                              class="custom-select"
                              name="sectionName"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Choose Section...
                              </option>
                              {this.state.staffSections.map((item, index) => (
                                <option value={`${item.sectionId}`}>
                                  {item.section}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div> */}
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Staff Id's
                            </label>
                            <select
                              onChange={this.onSelectFinalStaff}
                              class="custom-select"
                              name="finalStudentId"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Choose Staff Name
                              </option>
                              {this.state.staffs.map((item, index) => (
                                <option value={`${JSON.stringify(item)}`}>
                                  {item.staffName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}

                    {std ? (
                      <div class="row">
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Student Class Name
                            </label>
                            <select
                              onChange={this.onSelectSectionChange}
                              class="custom-select"
                              name="studentId"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Choose Id..
                              </option>
                              {classes.map((item, index) => (
                                <option key={index} value={`${item.classId}`}>
                                  {item.className}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Class Section
                            </label>
                            <select
                              onChange={this.onSelectStudentChange}
                              class="custom-select"
                              name="sectionName"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Choose Section...
                              </option>
                              {this.state.sections.map((item, index) => (
                                <option value={`${item.sectionId}`}>
                                  {item.section}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Student Name
                            </label>
                            <select
                              onChange={this.onSelectFinalStudent}
                              class="custom-select"
                              name="finalStudentId"
                            >
                              <option selected={this.state.msg ? true : false}>
                                Choose Name...
                              </option>
                              {this.state.students.map((item, index) => (
                                <option value={`${JSON.stringify(item)}`}>
                                  {item.Student}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.floorCheck ? (
                      <div class="row">
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Floor
                            </label>
                            <select
                              onChange={this.onHandleTextChange}
                              class="custom-select"
                              name="floorId"
                            >
                              <option>
                                Select Floor
                              </option>
                              {this.state.floors.map((item, index) => (
                                <option key={index} value={`${item.id}`}>
                                  {item.floorName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.roomCheck ? (
                      <div class="row">
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Floor
                            </label>
                            <select
                              onChange={this.onSelectFloorChange}
                              class="custom-select"
                              name="floorId"
                            >
                              <option>
                                Select Floor
                              </option>
                              {this.state.floors.map((item, index) => (
                                <option key={index} value={`${item.id}`}>
                                  {item.floorName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" style={{ marginTop: 20 }}>
                          <div class="md-form mb-0">
                            <label for="email" class="">
                              Room
                            </label>
                            <select
                              onChange={this.onHandleTextChange}
                              class="custom-select"
                              name="roomId"
                            >
                              <option>
                                Select Room
                              </option>
                              {this.state.roomsFilter.map((item, index) => (
                                <option key={index} value={`${item.id}`}>
                                  {item.roomName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <br />
                    <br />
                    <div class="text-center text-md-right">
                      <button
                        type="submit"
                        class="btns"
                        style={{
                          background: context.themeColors.primaryColor,
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>

                  <div class="status" />
                </div>
              )}
            </ThemeContext.Consumer>
          </div>
        </section>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    StockUsageActions: bindActionCreators(StockUsageActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
    select: state.setStockUsageReducer.select,
    BarcodeData: state.setStockUsageReducer.barcodeSelect,
    selectStudent: state.setStockUsageReducer.selectStudent,
    selectStaff: state.setStockUsageReducer.selectStaff,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddStockUsage);

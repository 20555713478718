import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull'
import { months } from 'moment';
import { counter } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment'
import Header from '../../Assets/images/3 headers-03.png';
import LoaderWrapper from "../../components/HOC/LoaderWrapper";
import ThemeContext from "../../context/themeContext/ThemeContext";




class PrintCyclicCleaness extends Component {
    constructor(props){
        super(props);
        this.state = {
            search: '',
            isLoading: false,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',

            monthList: [],

        }

    }

	static contextType = ThemeContext

    componentDidMount(){
        this.generateMonthAndYear(30)
    }

    generateMonthAndYear = (limit) => {
        const year = new Date().getFullYear();
        const years = Array.from(new Array(limit), (val, index) => index + year);
        this.setState({
            yearList: years,
            monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        })
    }

    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
    };
    
    
    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
    };


    render(){

        const { monthList, isLoading, msg } = this.state
        const { cyclicCleaness, floorData } = this.props.location.state

        const Loader = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border" style={{ color: this.context.themeColors.primaryColor }}></div>
            </div>
        );
        
        const renderCyclicCleanessData = cyclicCleaness && cyclicCleaness.datelist.map((item, index) => {
            return (
                moment(new Date).format(`MM/DD/YYYY`) >= item &&  <tr key={index} >
                <th scope="row" className="borderSpace" style={{ padding: 1 }}  >
                    {item}
                </th>
                {floorData.category.map((data, index) => {
                            var fill = cyclicCleaness.dateWise.filter(val => {
                                return data.id == val.categoryId
                            })
                            console.log('fill', fill);
                            

                           return  fill.length == 1 && item == fill[0].date &&  <td className="borderSpace" style={{ padding: 1 }} >
                            {fill[0].cleaness[0].checkBox == 'Yes' ? <span style={{color: 'green'}} class="fas fa-check"></span> : <span style={{color: 'red'}} class="fas fa-times"></span>}
                        </td> 
                          
                })}
                
               
                {cyclicCleaness.dateWise.length > 0 && floorData && floorData.category.map(data => {
                    var fill = cyclicCleaness.dateWise.filter(val => {
                        return data.id == val.categoryId
                    })
        
                    console.log('Table Heading', fill);
                    
                    return (
                        fill.length > 0 && item != fill[0].date && <td className="borderSpace" style={{ padding: 1 }} ></td> 
                    );
                })}
            </tr> 
            );
        }) 

        const renderTableheading = cyclicCleaness && floorData && floorData.category.map((data, index) => {
            var fill = cyclicCleaness.dateWise.filter(val => {
                return data.id == val.categoryId
            })

            console.log('Table Heading', fill);
            
            return (
                fill.length > 0 && <th scope="col" key={fill[0].categoryId} style={{ padding: 1 }}>
                                {fill[0].categoryName}
                </th>
            );
        })

        return (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div id="slip" style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div
					>
						<div class="row" >
								<div
									style={{
                                        flex: 1,
                                        width: '100%',
                                        height: 180
									}}
								>
									<img src={Header} width={'100%'} height={'100%'} />
								</div>
						</div>

                    <div class="row" style={{marginTop: '2%'}} >
							<div class="text-center col-xs-12 col-sm-12 col-md-12">
								<h3>Cyclic Cleanliness</h3>
								
							</div>
						</div>

                    <div class="my-2 py-2 px-2" style={{
                        border: `3px solid ${this.context.themeColors.primaryColor}`,
                    }} >
                        <div class="row">
                        <div class="col-md-6" >
                                <p style={{
                                    margin: '0px'
                                    }}>Floor: <strong style={{color: this.context.themeColors.primaryColor}} >{cyclicCleaness && cyclicCleaness.info.floorName}</strong></p>     
                        </div>
                        <div class="col-md-6  text-right" >
                                <p style={{
                                    margin: '0px'
                                    }}>Month: <strong style={{color: this.context.themeColors.primaryColor}} >{cyclicCleaness && monthList[cyclicCleaness.datelist[0].split("/")[0] - 1]}</strong></p>     
                        </div>
                            
                        </div>
                        <div class="row">
                        <div class="col-md-6" >
                                <p style={{
                                    margin: '0px'
                                    }}>Room: <strong style={{color: this.context.themeColors.primaryColor}} >{cyclicCleaness && cyclicCleaness.info.roomName}</strong></p>     
                        </div>
                        <div class="col-md-6 text-right"  >
                                <p style={{
                                margin: '0px'
                                }}>Year: <strong style={{color: this.context.themeColors.primaryColor}} >{cyclicCleaness && cyclicCleaness.datelist[0].split("/")[2]}</strong></p>     
                        </div>     
                        </div>
                        
                       
                    </div>
                    </div>
                    <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                        
                    </div>
                    
                    <div className="table-responsive">
					<table className="table table-hover text-center table-bordered">
						<thead style={{ backgroundColor: this.context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									Date
								</th>
                                {renderTableheading}
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderCyclicCleanessData}</tbody>
					</table>
				</div>
                </div>
                <br/>
                <div class="row" style={{marginBottom: '2%', marginRight: '1%'}} >
                    <div class="col-md-10">
                    
                    </div>
                    <div class="col-md-2">
                    <button class="btn btn-primary buttonAppear" onClick={() => this.PrintContent('slip')}>
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
                <br/>
                </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintCyclicCleaness);

import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import TextInput from '../../components/TextInput/TextInput'
import Button from '../../components/Button';
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Name',
    'Class',
    'Section',
    'Subject',
];

let renderFields = [
    'staffName',
    'className',
    'section',
    'subjectName',
]

const AssignSubjectToStaffRenew = props => {
    const themeColor = useContext(ThemeContext);

    const [isLoading, setIsLoading] = useState(true)
    const [classData, setClassData] = useState([])
    const [sectionData, setSectionData] = useState([])
    const [subjectData, setSubjectData] = useState([])
    const [postData, setPostData] = useState([])
    const [staffData, setStaffData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [msg, setSnackMsg] = useState('')
    const [state, setState] = useState({
        accountId: props.name,
        accountType: props.role,
        classId: '',
        sectionId: '',
        subjectId: '',
        gradeId: '',
        gradeName: '',
        staffName: props.match.params.name,
        staffId: props.match.params.id,
        isLoading: false,
        backColor: ''
    })
    const [mainData, setMainData] = useState({})

    const [staffDataLoading, setStaffDataLoading] = httpWithRequiredSetData(
        `/api/ListOfStaffInAssignSubjectToTeacher/?staffId=${props.match.params.id}`,
        [],
        data => {
            setStaffData(data);
        }
    );

    const [isLoadingMainData, setIsLoadingMainData] = httpWithRequiredSetData(
        `/api/classSectionGradeTeacher/?staffId=${props.match.params.id}`,
        [],
        data => {
            setMainData(data);
            setClassData(data.classes)

            setIsLoading(false)
        }
    );

    useEffect(() => {
        if (state['backColor'] !== '') {
            setTimeout(() => {
                setState(prevState => ({
                    ...prevState, backColor: ''
                }))
            }, 3000);
        }
        if (msg || state['errorMsg']) {
            setTimeout(() => {
                setState(prevState => ({
                    ...prevState, errorMsg: false
                }))
            }, 3000);
        }
    });

    const onSubmit = (e) => {
        e.preventDefault();

        if (state['classId'] === '' ||
            state['sectionId'] === '' ||
            state['subjectId'] === '' ||
            state['staffId'] === '' ||
            state['gradeId'] === ''
        ) {
            setState(prevState => ({
                ...prevState, errorMsg: true
            }))
        } else {
            setState(prevState => ({
                ...prevState, isLoading: true
            }))

            const data = {
                classId: state['classId'],
                sectionId: state['sectionId'],
                subjectId: state['subjectId'],
                staffId: state['staffId'],
                gradeId: state['gradeId'],
                accountId: state['accountId'],
                accountType: state['accountType']
            }

            props.sharedActions
                .simpleAddRequest('/api/AssignSubjects',
                    data)
                .then(success => {
                    setSnackMsg('Assigned Subject Successfully!')
                    setState(prevState => ({
                        ...prevState,
                        accountId: props.name,
                        accountType: props.role,
                        classId: '',
                        sectionId: '',
                        subjectId: '',
                        gradeId: '',
                        gradeName: '',
                        isLoading: '',
                        errorMsg: '',
                    }))
                    setState(prevState => ({
                        ...prevState, isLoading: false
                    }))
                    props.snackbar();
                })
                .catch(error => {
                    if (error && error.response && error.response.status == 404) {
                        // 404-Error no understanding of 404 Error
                        setState(prevState => ({
                            ...prevState, isLoading: false, backColor: '#FF0000'
                        }))
                        setSnackMsg(error.response.data.Message)
                        props.snackbar();
                    } else if (error && error.response && error.response.status == 400) {
                        // 404-Error no understanding of 404 Error
                        setState(prevState => ({
                            ...prevState, isLoading: false, backColor: '#FF0000'
                        }))
                        setSnackMsg(error.response.data.Message)
                        props.snackbar();
                    }
                    setState(prevState => ({
                        ...prevState, isLoading: false
                    }))
                })

        }
    }

    const onHandleClassChange = e => {

        if (e.target.value !== 'Class Name') {
            let sectionData = general.dataFilteration(
                mainData.sections,
                ['section', 'sectionId'],
                {
                    classId: parseInt(e.target.value)
                })

            let subjectData = general.dataFilteration(
                mainData.subject,
                ['subjectName', 'subjectId'],
                {
                    classId: parseInt(e.target.value),
                })

            let gradeData = general.dataFilteration(
                mainData.grade,
                ['gradeId', 'gradeName'],
                {
                    classId: parseInt(e.target.value),
                })

            const target = e.target;

            setState(prevState => ({
                ...prevState, [target.name]: target.value,
                gradeId: gradeData[0].gradeId,
                gradeName: gradeData[0].gradeName
            }))

            setSectionData(sectionData)
            setSubjectData(subjectData)
        }
    }

    const onHandleSelectChange = e => {

        if (e.target.value !== 'Section Name' && e.target.value !== 'Subject') {
            const target = e.target;

            setState(prevState => ({
                ...prevState, [target.name]: target.value,
            }))
        }
    }

    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${themeColor.themeColors.primaryColor}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${themeColor.themeColors.primaryColor}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
            </tr>
        ));

    const Student = (
        <div>
            <div class='row'>
                <SelectionInput
                    feildName={'classId'}
                    selectName={'Class Name'}
                    onHandleChange={e => onHandleClassChange(e)}
                    errors={{}}
                    optionsArrys={classData}
                    selectedText={'Class Name'}
                    stateData={state}
                    // iconClassName={'fas fa-building'}
                    optionType="dynamicWithPropIdAndName"
                    property={'className'}
                    propertyId={'classId'}
                    successflag={'successflag'}
                    isValidte={false}
                // editUse={'staffEdit'}
                />

                <SelectionInput
                    feildName={'sectionId'}
                    selectName={'Section'}
                    onHandleChange={e => onHandleSelectChange(e)}
                    errors={{}}
                    optionsArrys={sectionData}
                    selectedText={'Section Name'}
                    stateData={state}
                    // iconClassName={'fas fa-building'}
                    optionType="dynamicWithPropIdAndName"
                    property={'section'}
                    propertyId={'sectionId'}
                    successflag={'successflag'}
                    isValidte={false}
                // editUse={'staffEdit'}
                />


                <SelectionInput
                    marginTop={20}
                    feildName={'subjectId'}
                    selectName={'Subject Name'}
                    onHandleChange={e => onHandleSelectChange(e)}
                    errors={{}}
                    optionsArrys={subjectData}
                    selectedText={'Subject'}
                    stateData={state}
                    // iconClassName={'fas fa-building'}
                    optionType="dynamicWithPropIdAndName"
                    property={'subjectName'}
                    propertyId={'subjectId'}
                    successflag={'successflag'}
                    isValidte={false}
                // editUse={'staffEdit'}
                />

                <TextInput
                    marginTop={20}
                    feildName={'staffName'}
                    inputLabelName={'Staff Name'}
                    errors={{}}
                    stateData={state}
                    placeholder="Staff Name"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />

                <TextInput
                    marginTop={20}
                    feildName={'gradeName'}
                    inputLabelName={'Grade Name'}
                    errors={{}}
                    stateData={state}
                    placeholder="Grade Name"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />

            </div>
        </div>
    )

    const Form = (
        <FormLayoutWrapper
            formName='Assign New Subject'
            borderColor={themeColor.themeColors.primaryColor}
            borderRadius={15}
        >
            {state.errorMsg ? (
                <p style={{ color: 'red', textAlign: 'center' }}>
                    Please fill the form properly
                </p>
            ) : (
                    ''
                )}

            <div className="row">
                {Student}
            </div>

            <br />

            <Button
                width="100%"
                buttonClass="customButton"
                btnName="Add"
                loaderBmargin={5}
                stateData={state}
                marginTop={20}
                onHandleSubmit={onSubmit}
                margin={'0 auto'}
                textColor={'white'}
            />

        </FormLayoutWrapper>
    )

    return (
        <div id='div1'>
            <SnackBar backColor={state['backColor']} msg={msg} />

            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Assigned Subjects{' '}
                        </h2>
                    </div>
                </div>

            </div>
            {isLoading ? (
                <Spinner />
            ) :
                staffData.length < 1 ?
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                            <strong>No Subjects Assigned!</strong>
                            <br />

                        </div>
                        {Form}
                    </div>
                    : (
                        <div>
                            <div className="table-responsive">
                                <table class="table table-hover">
                                    <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                        <tr class="vendorListHeading">
                                            <th scope="col">#</th>
                                            {tHead.map(item => (
                                                item === 'Actions' ?
                                                    <th className='displayElemet'
                                                        style={{
                                                            border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                                            borderColor: Colors.WHITE
                                                        }}
                                                        scope="col"
                                                    >
                                                        {item}
                                                    </th>
                                                    :
                                                    <th
                                                        style={{
                                                            border: `1px solid ${themeColor.themeColors.primaryColor}`,
                                                            borderColor: Colors.WHITE
                                                        }}
                                                        scope="col"
                                                    >
                                                        {item}
                                                    </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>{bodyRendering(staffData)}</tbody>
                                </table>
                            </div>
                            {Form}
                        </div>
                    )}

            {/*             {isLoading ? (<Spinner />) : (
                Form
            )} */}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignSubjectToStaffRenew);
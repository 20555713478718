import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as sharedActions from '../../Actions/sharedActions';
import PrintView from '../../components/PrintView/PrintView';

let tHead = [
	'Staff Name',
	'Post Name',
	'Date',
	'Attendance Status',
	'Check In',
	'Check Out'
];

let renderFields = [
	'name',
	'postName',
	'date',
	'attendanceStatus',
	'checkIn',
	'checkOut'
];

const PrintBioMetricAttendance = props => {
	const [state, setState] = useState(props.location.state);

	const PrintContent = el => {
		var restorepage = document.body.innerHTML;
		var Printcontent = document.getElementById(el).innerHTML;
		document.body.innerHTML = Printcontent;
		window.print();
		document.body.innerHTML = restorepage;
	};

	return (
		<PrintView
			data={state['attendanceData']}
			head={tHead}
			renderFields={renderFields}
			title={'Bio Metric Attendance'}
			accountId={props.name}
			accountType={props.role}
			printFn={PrintContent}
		/>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}
function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintBioMetricAttendance);

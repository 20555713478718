import React, { Suspense, lazy } from "react";
import "./login.css";
import "./loginDemo.css";
const LoginForm = lazy(() => import("../Login_Component/LoginForm"));
const LoginLayout = (props) => {
  if (
    Object.is(props.match.params.select, ":Admin") ||
    Object.is(props.match.params.select, ":Student") ||
    Object.is(props.match.params.select, ":Parent") ||
    Object.is(props.match.params.select, ":Staff") ||
    Object.is(props.match.params.select, ":Owner") ||
    Object.is(props.match.params.select, ":General Manager") ||
    Object.is(props.match.params.select, ":Manager") ||
    Object.is(props.match.params.select, ":Dean") ||
    Object.is(props.match.params.select, ":Hod") ||
    Object.is(props.match.params.select, ":Coordinator")
  ) {
  } else {
    props.history.push("/");
  }

  return (
    <div className="mainBackImg">
      <div className="container">
        <div className="row">
          <div
            className="col-md-4 text-center"
            style={{ marginTop: 250 }}
          ></div>
          <div
            className="col-md-4 mainDiv"
            style={{
              padding: 0,
              marginTop: 90,
              boxShadow: "0px 0px 5px 5px #80aac3",
              textAlign: "center",
            }}
          >
            <Suspense
              fallback={<div class="spinner-border text-primary"></div>}
            >
              <LoginForm {...props} />
            </Suspense>
          </div>
          <div className="col-md-4 text-center" style={{ marginTop: 150 }}>
            <img
              src={require("../../Assets/DoratPictures/LogoCut1.png")}
              style={{ marginLeft: 100 }}
              width="200"
              height="150"
              class="img-fluid"
            />
            <img
              src={require("../../Assets/DoratPictures/LogoCut2.png")}
              style={{ marginLeft: 40 }}
              width="400"
              height="150"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;

import React, { useEffect, useState, useContext } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import general from '../../utils/general'
import TextInput from '../../components/TextInput/TextInput'
import Button from '../../components/Button';
import TextArea from '../../components/TextArea'
import FormLayoutWrapper from '../../components/HOC/FormLayoutWrapper';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import themeContext from "../../context/themeContext/ThemeContext";

const AddExclusion = props => {

    const themeColor = useContext(themeContext);

    const [isLoading, setIsLoading] = useState(false)
    const [msg, setSnackMsg] = useState('')
    const [state, setState] = useState({
        accountId: props.name,
        accountType: props.role,
        complainType: '',
        classId: '',
        sectionId: '',
        sessionId: '',
        studentId: '',
        reason: '',
        isLoading: '',
        searchStudentId: '',
        className: '',
        section: '',
        studentName: '',
        errorMsg: '',
        name: '',
        session: ''
    })

    useEffect(() => {
        if (msg || state['errorMsg']) {
            setTimeout(() => {
                setState(prevState => ({
                    ...prevState, errorMsg: false
                }))
            }, 3000);
        }
    });


    const onSubmitGetStudent = (e) => {
        e.preventDefault();

        if (state['searchStudentId'] === '') {
            setState(prevState => ({
                ...prevState, errorMsg: true
            }))
        } else {
            setState(prevState => ({
                ...prevState, isLoading: true
            }))

            props.sharedActions
                .getDataWithoutDispatch(
                    `/api/StudentInformationById?studentId=${state['searchStudentId']}`
                )
                .then(success => {
                    setState(prevState => ({
                        ...prevState,
                        isLoading: false,
                        classId: success.classId,
                        sectionId: success.sectionId,
                        studentId: success.studentAdmissionFormId,
                        name: success.studentName,
                        section: success.section,
                        className: success.className,
                        session: success.session,
                        sessionId: success.sessionId
                    }))
                })
                .catch(error => {
                    setState(prevState => ({
                        ...prevState, isLoading: false
                    }))
                })
        }
    }

    const onHandleSelectChange = e => {
        if (e.target.value !== 'Complain Type' && e.target.value !== 'Session') {
            const target = e.target
            setState(prevState => ({
                ...prevState, [target.name]: target.value
            })
            )
        }
    }

    const onHandleTextChange = e => {
        const target = e.target
        setState(prevState => ({
            ...prevState, [target.name]: target.value
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(state);

        if (state['classId'] === '' ||
            state['sectionId'] === '' ||
            state['sessionId'] === '' ||
            state['studentId'] === '' ||
            state['complainType'] === ''
        ) {
            setState(prevState => ({
                ...prevState, errorMsg: true
            }))
        }
        else {
            setIsLoading(true)

            const data = {
                classId: state['classId'],
                sectionId: state['sectionId'],
                sessionId: state['sessionId'],
                studentId: state['studentId'],
                complainType: state['complainType'],
                reason: state['reason'],
                accountId: state['accountId'],
                accountType: state['accountType']
            }

            props.sharedActions
                .simpleAddRequest('/api/StudentComplains',
                    data)
                .then(success => {
                    setIsLoading(false)
                    setSnackMsg('Added Complain Successfully!')
                    setState({
                        accountId: props.name,
                        accountType: props.role,
                        complainType: '',
                        classId: '',
                        sectionId: '',
                        sessionId: '',
                        studentId: '',
                        reason: '',
                        isLoading: '',
                        searchStudentId: '',
                        className: '',
                        section: '',
                        studentName: '',
                        errorMsg: '',
                        name: '',
                        session: ''
                    })
                    props.snackbar();
                })
                .catch(error => {
                    if (error && error.response && error.response.status == 404) {
                        // 404-Error no understanding of 404 Error
                        setIsLoading(false)
                        props.snackbar();
                        setSnackMsg(error.response.data.Message)
                    } else if (error && error.response && error.response.status == 400) {
                        // 404-Error no understanding of 404 Error
                        setIsLoading(false)
                        props.snackbar();
                        setSnackMsg(error.response.data.Message)
                    }
                    setIsLoading(false)
                })
        }

    }

    const Student = (
        <div>
            <div className="row" style={{ justifyContent: 'center' }}>
                <TextInput
                    feildName={'searchStudentId'}
                    onHandleChange={onHandleTextChange}
                    errors={{}}
                    stateData={state}
                    placeholder={'Enter Student ID'}
                    isValidte={false}
                />
            </div>
            <Button
                icon={true}
                width="100%"
                buttonClass="customButton"
                btnName="Search"
                loaderBmargin={5}
                stateData={state}
                marginTop={20}
                onHandleSubmit={onSubmitGetStudent}
                margin={'0 auto'}
                textColor='white'
                btnBackColor={themeColor.themeColors.primaryColor}
            />

            <div className="row">

                <TextInput
                    feildName={'className'}
                    inputLabelName={'Class Name'}
                    errors={{}}
                    stateData={state}
                    placeholder="Class Name"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />

                <TextInput
                    feildName={'section'}
                    inputLabelName={'Section'}
                    errors={{}}
                    stateData={state}
                    placeholder="Section"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />

                <TextInput
                    marginTop={20}
                    feildName={'name'}
                    inputLabelName={'Student Name'}
                    errors={{}}
                    stateData={state}
                    placeholder="Student Name"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />

                <TextInput
                    marginTop={20}
                    feildName={'session'}
                    inputLabelName={'Session'}
                    errors={{}}
                    stateData={state}
                    placeholder="Session"
                    readOnly={true}
                    type={'text'}
                    isValidte={false}
                />

                <SelectionInput
                    marginTop={20}
                    feildName={'complainType'}
                    selectName={'Exclusion'}
                    onHandleChange={e => onHandleSelectChange(e)}
                    errors={{}}
                    optionsArrys={general.exclusion}
                    selectedText={'Complain Type'}
                    stateData={state}
                    // iconClassName={'fas fa-building'}
                    optionType="static"
                    successflag={'successflag'}
                    isValidte={false}
                // editUse={'staffEdit'}
                />

                <TextArea
                    feildName={'reason'}
                    inputLabelName={'Reason'}
                    onHandleChange={onHandleTextChange}
                    errors={{}}
                    stateData={state}
                    // iconClassName={'fas fa-percentage'}
                    placeholder={'Add Reason'}
                    isValidte={false}
                />

            </div>
        </div>

    );


    const Form = (
        <FormLayoutWrapper
            formName='Add Complain'
            borderColor={themeColor.themeColors.primaryColor}
            borderRadius={15}
        >
            {state.errorMsg ? (
                <p style={{ color: 'red', textAlign: 'center' }}>
                    Please fill the form properly
                </p>
            ) : (
                    ''
                )}

            <div className="row">
                {Student}
            </div>

            <br />

            <Button
                width="100%"
                buttonClass="customButton"
                btnName="Add"
                loaderBmargin={5}
                stateData={{
                    isLoading
                }}
                marginTop={20}
                onHandleSubmit={onSubmit}
                margin={'0 auto'}
                textColor={'white'}
                btnBackColor={themeColor.themeColors.primaryColor}
            />

        </FormLayoutWrapper>
    )

    return (
        <React.Fragment>
            <SnackBar msg={msg} />
            {Form}
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        name: state.setUserReducer.users.unique_name,
        role: state.setUserReducer.users.role,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddExclusion);
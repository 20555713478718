import React, { Component } from 'react';
import '../Admin/viewAdmin.css';

class HrCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imagePreviewUrl:
				'http://ctt.trains.com/sitefiles/images/no-preview-available.png',
			loader: false
		};
	}
	conversionOfImageUrl = url => {
		let getNewSplit = String.raw`${url}`.split('\\').join('//');
		let getnewSliting = getNewSplit.split('//');

		// let getSplitResult = url.split('');
		let filterURL = [
			...getnewSliting.filter(
				item =>
					item !== 'C:' &&
					item !== 'Inetpub' &&
					item !== 'vhosts' &&
					item !== 'httpdocs'
			)
		];

		let getDesireURL = filterURL.join('/');

		// let result = '';
		// for (let i = 4; i < getSplitResult.length; i++) {
		//   if (getSplitResult.length - 1 == i)
		// 	result += getSplitResult[i];
		//   else
		// 	result += getSplitResult[i].concat('\\\\');
		// }
		let finalResult = 'http://'.concat(`${getDesireURL}`);
		let newFinal = finalResult.replace('linkit.life', '');
		debugger;
		return newFinal;
	};
	// componentDidMount() {
	// 	this.setState({
	// 		imagePreviewUrl: this.conversionOfImageUrl(this.props.data && this.props.data.filePath),
	// 		loader: true
	// 	})
	// }
	componentWillReceiveProps(props) {
		const { data } = this.props;
		if (props.data == data) {
			this.setState({
				imagePreviewUrl: this.conversionOfImageUrl(
					this.props.data && this.props.data.filePath
				),
				loader: true
			});
		}
	}
	render() {
		const { data } = this.props;
		console.log('Cards compo', data);
		console.log('Images URL', this.state.imagePreviewUrl);
		const Loader = (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: '#01AC8A' }}></div>
			</div>
		);
		const Cards = (
			<div class="card mb-3" style={{ maxWidth: '540px;' }}>
				<div class="row no-gutters">
					<div class="col-md-2">
						<img
							src={this.state.imagePreviewUrl}
							class="card-img"
							alt="..."
							style={{ maxWidth: '540px;', height: 180 }}
						/>
					</div>
					<div class="col-md-10">
						<div class="card-body">
							<h5 class="card-title">
								{data.title} / {data.type}
							</h5>
							<p class="card-text">
								Fees: {data.fees} ر.ع. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Location:{' '}
								{data.location}
							</p>
							<p class="card-text">
								Date: {data.trainingDate} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Time:{' '}
								{data.trainingTime}
							</p>
							{data.link != '' ? (
								<p class="card-text">
									{data.link} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								</p>
							) : (
								''
							)}
							<p class="card-text">
								<small class="text-muted">{data.description} </small>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
		return <div>{Cards}</div>;
	}
}

export default HrCard;

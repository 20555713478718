import React, { useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination'
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general'
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import themeContext from "../../context/themeContext/ThemeContext";

let tHead = [
    'Student Name',
    'Class',
    'Section',
    'Exclusion Type',
    'From Date',
    'To Date',
    'Session',
    'Status',
    'Actions'
];

let renderFields = [
    'studentName',
    'className',
    'section',
    'exclusionType',
    'fromDate',
    'toDate',
    'sessionName',
    'status',
]

const StudentExclusionRecord = props => {

    const themeColor = useContext(themeContext);

    const [sessionData, setSessionData] = useState([])
    const [sessionId, setSessionId] = useState('')

    const [exclusionData, setExclusionData] = useState([])

    const [searchURL, setSearchURL] = useState(
        `/api/StudentExclusionRecords/?pageNumber=1&pageSize=10`
    );


    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});


    const [
        isLoading,
        fetchData,
        setFetchData,
        setIsLoading
    ] = httpWithRequiredSetData(searchURL, [], data => {
        let pageNumber = [];
        for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
            pageNumber.push(i);
        }
        setPageNumber(pageNumber);
        setExclusionData(data['exclusionList']);

        setPaginationData(data['paginationMetadata']);
    });

    const [isSessionLoading, sessions] = httpWithRequiredSetData(
        '/api/session',
        [],
        data => {
            setSessionData(data['session']);
        }
    );

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                general.condtionalServicesExclusionRecord(
                    sessionId,
                    currentPage,
                    customPageSize
                )
            )
            .then(success => {
                setExclusionData(success['exclusionList']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
                setExclusionData([])
            });
    }, [currentPage, sessionId, customPageSize]);

    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };

    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };

    const onHandleChange = e => {
        if (e.target.name === 'sessionId') {
            if (e.target.value !== 'Session') {
                setSessionId(e.target.value)
            }
        }
    }

    const PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        window.print();
        document.body.innerHTML = restorepage;
    };

    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                            padding: 2,
                            borderColor: themeColor.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td className='displayElemet'
                    style={{
                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                        padding: 2,
                        borderColor: themeColor.themeColors.primaryColor
                    }}
                >
                    <a
                        href={
                            `http://schoolsmart.org.uk/api/ExlusionFiles?fileName=${item.fileName}`
                        }
                        id="tooltipId"
                        data-toggle="tooltip"
                        title="Download File"
                        style={{
                            cursor: 'pointer',
                            fontSize: 20,
                            border: 'none',
                            marginLeft: 10,
                            background: 'none',
                            marginBottom: 5
                        }}

                    >
                        <i style={{ color: '#34495e' }} class="fas fa-file-download"></i>
                    </a>
                </td>
            </tr>
        ));

    return (
        <div id='div1'>
            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
                            {' '}
                            Student Exclusion Records{' '}
                        </h2>
                    </div>
                    <div class='displayElemet'>
                        <div
                            style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                        >
                            <Pagination
                                disableBPage={disableBPage}
                                disablepage={disablepage}
                                currentPage={currentPage}
                                pageNumber={pageNumber}
                                handleClickNext={handleClickNext}
                                handleClickNextBack={handleClickNextBack}
                                handleClickNextFrwd={handleClickNextFrwd}
                            />
                            <div
                                style={{
                                    width: 'fit-content',
                                    marginTop: -34,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginLeft: 'auto'
                                }}
                            >
                                <SelectionInput
                                    selectName="Entries"
                                    selectFont={14}
                                    selectFontWeight={'bold'}
                                    newFlag="customWidth"
                                    feildName="customPageSize"
                                    selectedText={'Page Entries'}
                                    optionsArrys={['10', '20', '30', '40', '50']}
                                    errors={{}}
                                    isValidte={false}
                                    stateData={{
                                        customPageSize: customPageSize
                                    }}
                                    optionType="static"
                                    onHandleChange={onChangePageSize}
                                    useFlag={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class='displayElemet'>
                    <CustomFilterSectionLayout displayDirection="column">
                        <div
                            style={{
                                width: '30%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <React.Fragment>
                                <SelectionInput
                                    newFlag="customWidth"
                                    useFlag={false}
                                    feildName={'sessionId'}
                                    selectName={'Session'}
                                    onHandleChange={e => onHandleChange(e)}
                                    errors={{}}
                                    optionsArrys={sessionData}
                                    selectedText={'Session'}
                                    stateData={{
                                        sessionId: sessionId
                                    }}
                                    // iconClassName={'fas fa-building'}
                                    optionType="dynamicWithPropIdAndName"
                                    property={'currentSession'}
                                    propertyId={'sessionId'}
                                    isValidte={false}
                                // editUse={'staffEdit'}
                                />
                            </React.Fragment>
                        </div>
                    </CustomFilterSectionLayout>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            <br />
            {isLoading ? (
                <Spinner />
            ) :
                exclusionData.length < 1 ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    <strong>No Data</strong>
                </div>
                    : (

                        <div className="table-responsive">
                            <table class="table table-hover">
                                <thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
                                    <tr class='vendorListHeading'>
                                        <th scope="col">#</th>
                                        {tHead.map(item => (
                                            item === 'Actions' ?
                                                <th className='displayElemet'
                                                    style={{
                                                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                                :
                                                <th
                                                    style={{
                                                        border: `1px solid ${Colors.SEA_GREEN_THEME}`,
                                                        borderColor: Colors.WHITE
                                                    }}
                                                    scope="col"
                                                >
                                                    {item}
                                                </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{bodyRendering(exclusionData)}</tbody>
                            </table>
                        </div>
                    )}
            <div class="text-center text-md-right displayElemet">
                <label for="name" class="" onClick={() => PrintContent('div1')}>
                    <i
                        className="fas fa-print fa-2x animated fadeIn"
                        style={{ marginRight: 4 }}
                    />
                        Print
                            </label>
            </div>
            <div class='text-center text-md-right'>
                <strong>{props.name}</strong>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        role: state.setUserReducer.users.role,
        name: state.setUserReducer.users.unique_name,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentExclusionRecord);
const now = new Date();

export default [
	{
		id: 0,
		title: 'All Day Event very long title',
		allDay: true,
		start: new Date(2020, 3, 0),
		end: new Date(2020, 3, 1),
		hexColor: '00AC1A'
	},
	{
		id: 1,
		title: 'Long Event',
		start: new Date(2020, 3, 7),
		end: new Date(2020, 3, 10),
		hexColor: '01AC2A'
	},

	{
		id: 2,
		title: 'DTS STARTS',
		start: new Date(2016, 2, 13, 0, 0, 0),
		end: new Date(2016, 2, 20, 0, 0, 0),
		hexColor: '02AC3A'
	},

	{
		id: 3,
		title: 'DTS ENDS',
		start: new Date(2016, 10, 6, 0, 0, 0),
		end: new Date(2016, 10, 13, 0, 0, 0),
		hexColor: '04AC5A'
	},

	{
		id: 4,
		title: 'Some Event',
		start: new Date(2020, 3, 9, 0, 0, 0),
		end: new Date(2020, 3, 10, 0, 0, 0),
		hexColor: '05AC5A'
	},
	{
		id: 5,
		title: 'Conference',
		start: new Date(2020, 3, 11),
		end: new Date(2020, 3, 13),
		desc: 'Big conference for important people',
		hexColor: '06AC6A'
	},
	{
		id: 6,
		title: 'Meeting',
		start: new Date(2020, 3, 12, 10, 30, 0, 0),
		end: new Date(2020, 3, 12, 12, 30, 0, 0),
		desc: 'Pre-meeting meeting, to prepare for the meeting',
		hexColor: '07AC7A'
	},
	{
		id: 7,
		title: 'Lunch',
		start: new Date(2020, 3, 12, 12, 0, 0, 0),
		end: new Date(2020, 3, 12, 13, 0, 0, 0),
		desc: 'Power lunch',
		hexColor: '08AC8A'
	},
	{
		id: 8,
		title: 'Meeting',
		start: new Date(2020, 3, 12, 14, 0, 0, 0),
		end: new Date(2020, 3, 12, 15, 0, 0, 0),
		hexColor: '09AC9A'
	},
	{
		id: 9,
		title: 'Happy Hour',
		start: new Date(2020, 3, 12, 17, 0, 0, 0),
		end: new Date(2020, 3, 12, 17, 30, 0, 0),
		desc: 'Most important meal of the day',
		hexColor: '09AC1A'
	},
	{
		id: 10,
		title: 'Dinner',
		start: new Date(2020, 3, 12, 20, 0, 0, 0),
		end: new Date(2020, 3, 12, 21, 0, 0, 0),
		hexColor: '09AC2A'
	},
	{
		id: 11,
		title: 'Birthday Party',
		start: new Date(2020, 3, 13, 7, 0, 0),
		end: new Date(2020, 3, 13, 10, 30, 0),
		hexColor: '09AC3A'
	},
	{
		id: 12,
		title: 'Late Night Event',
		start: new Date(2020, 3, 17, 19, 30, 0),
		end: new Date(2020, 3, 18, 2, 0, 0),
		hexColor: '09AC4A'
	},
	{
		id: 12.5,
		title: 'Late Same Night Event',
		start: new Date(2020, 3, 17, 19, 30, 0),
		end: new Date(2020, 3, 17, 23, 30, 0),
		hexColor: '09AC5A'
	},
	{
		id: 13,
		title: 'Multi-day Event',
		start: new Date(2020, 3, 20, 19, 30, 0),
		end: new Date(2020, 3, 22, 2, 0, 0),
		hexColor: '09AC6A'
	},
	{
		id: 14,
		title: 'Today',
		start: new Date(new Date().setHours(new Date().getHours() - 3)),
		end: new Date(new Date().setHours(new Date().getHours() + 3)),
		hexColor: '09AC7A'
	},
	{
		id: 15,
		title: 'Point in Time Event',
		start: now,
		end: now,
		hexColor: '09AC9A'
	},
	{
		id: 16,
		title: 'Video Record',
		start: new Date(2020, 3, 14, 15, 30, 0),
		end: new Date(2020, 3, 14, 19, 0, 0),
		hexColor: '09AC8A'
	},
	{
		id: 17,
		title: 'Dutch Song Producing',
		start: new Date(2020, 3, 14, 16, 30, 0),
		end: new Date(2020, 3, 14, 20, 0, 0),
		hexColor: '01AC8A'
	},
	{
		id: 18,
		title: 'Itaewon Halloween Meeting',
		start: new Date(2020, 3, 14, 16, 30, 0),
		end: new Date(2020, 3, 14, 17, 30, 0),
		hexColor: '03AC8A'
	},
	{
		id: 19,
		title: 'Online Coding Test',
		start: new Date(2020, 3, 14, 17, 30, 0),
		end: new Date(2020, 3, 14, 20, 30, 0),
		hexColor: '04AC8A'
	},
	{
		id: 20,
		title: 'An overlapped Event',
		start: new Date(2020, 3, 14, 17, 0, 0),
		end: new Date(2020, 3, 14, 18, 30, 0),
		hexColor: '05AC8A'
	},
	{
		id: 21,
		title: 'Phone Interview',
		start: new Date(2020, 3, 14, 17, 0, 0),
		end: new Date(2020, 3, 14, 18, 30, 0),
		hexColor: '07AC8A'
	},
	{
		id: 22,
		title: 'Cooking Class',
		start: new Date(2020, 3, 16, 17, 30, 0),
		end: new Date(2020, 3, 16, 19, 0, 0),
		desc: 'focus',
		hexColor: '08AC8A',
		date: new Date(),
		time: new Date().toLocaleTimeString(),
		accountId: 'dawar01',
		accountType: 'owner'
	},
	{
		id: 23,
		title: 'Go to the gym',
		start: new Date(2020, 3, 15, 18, 30, 0),
		end: new Date(2020, 3, 15, 20, 0, 0),
		desc: 'focus',
		hexColor: '11AC8A'
	},
	{
		id: 23,
		title: 'Go to the gym',
		start: new Date(2020, 3, 14, 18, 30, 0),
		end: new Date(2020, 3, 14, 20, 0, 0),
		hexColor: '91AC8A'
	}
];

import React, { Component } from "react";

export default class ParentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridNum: 4,
    };
  }
  render() {
    const { onHandleTextChange, stateData, editData } = this.props;
    return (
      <div>
        <section class="mb-4">
          <div
            class="row"
            style={{
              justifyContent: "left",
              textAlign: "left",
            }}
          >
            <div
              style={{ fontSize: 20, padding: 20 }}
              class="col-md-9 mb-md-0 mb-5"
            >
              <form
                id="contact-form"
                name="contact-form"
                // onSubmit={this.onHandleSubmit}
                noValidate
              >
                {/*<div class="row">*/}
                {/*<div class="col-md-12">*/}
                {/*<div class="md-form mb-0">*/}
                {/*/!*<DateandTime/>*!/*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        Father Name
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.fatherPName}
                        name={"fatherPName"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Father Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Mother Name
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.motherName}
                        name={"motherName"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Mother Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Father Id Card No
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.parentIdCardNo}
                        name={"parentIdCardNo"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Father Id Card No"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Mother Id Card No
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.motherIdCardNo}
                        name={"motherIdCardNo"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Mother Id Card No "
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Father Mobile No
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.parentMobileNo}
                        name={"parentMobileNo"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Father Mobile No"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Mother Mobile No
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.motherMobileNo}
                        name={"motherMobileNo"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Mother Mobile No "
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Father Email Id
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.parentEmailId}
                        name={"parentEmailId"}
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Father Email Id"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Mother Emai Id
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.motherEmaild}
                        name={"motherEmaild"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Mother Emai Id"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Father Occupation
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.occupation}
                        name={"occupation"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Occupation"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Mother Occupation
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.motherOccupation}
                        name={"motherOccupation"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Mother Occupation"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Guardian Name
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.garduainName}
                        name={"garduainName"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Guardian Name "
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Guardian Relationship
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.garduainRelationship}
                        name={"garduainRelationship"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Guardian Relationship"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Family Gross Income
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.income}
                        name={"income"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Family Gross Income"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Emergency Contact
                      </label>
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "80%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        value={stateData.emergencyContact}
                        name={"emergencyContact"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Emergency Contact"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {/*<div class="text-center text-md-center">*/}
                {/*<button class="btns">Add</button>*/}
                {/*</div>*/}
              </form>
              <div class="status" />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from "react";
import $ from "jquery";
import "../Admin/viewAdmin.css";
import Axios from "axios";
import config from "../../config/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as getAllAdminActions from "../../Actions/getAllAdmin";
import setAllAdminReducer from "../../Reducers/setAllAdminReducer";
import * as CampusActions from "../../Actions/Campus";
import * as sharedActions from "../../Actions/sharedActions";
import setCampusReducer from "../../Reducers/setCampusReducer";
import SnackBar from "../../components/SnackBar/SnackBar";
import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewOnlineResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      modalData: null,
      campusAddress: "",
      campusName: "",
      campusCity: "",
      campusPhoneNumber: "",
      enabled: false,
      id: null,
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      url: "",
      msg: "",
    };
  }

  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  //   static getDerivedStateFromProps(nextProps, prevState) {
  //     if (prevState.adminData !== nextProps.AllAdminData) {
  //       return {
  //         adminData: nextProps.AllAdminData,
  //       };
  //     }
  //     return null;
  //   }
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .getDataWithoutDispatch("/api/onlineResources")
      .then((success) => {
        debugger;
        this.setState({
          adminData: success.resource,
          isLoading: false,
        });
        console.log(success);
      })
      .catch((err) => {
        debugger;
        this.setState({
          isLoading: false,
        });
      });
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecordWithoutDispatch("/api/onlineResources/", itemId)
      .then((success) => {
        this.setState({
          msg: "Record Deleted Successfully!",
        });
        this.props.snackbar();
        window.location.reload();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    // campusAddress:'',
    // campusName:'',
    // campusPhoneNumber:"",
    // campusCity:"",
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      url: getData[0].url,
      id: getData[0].id,
    });
  };

  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { url, id, campusCity, campusPhoneNumber, campusName } = this.state;
    var bodyParameters = {
      url,
    };
    this.props.sharedActions
      .editRecordWithoutDispatchAndId(
        `/api/onlineResources/${id}`,
        bodyParameters
      )
      .then((success) => {
        this.setState({ enabled: false, msg: "Record Edit Successfully!" });
        this.props.snackbar();
        window.location.reload();
      })
      .catch((err) => {});
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  render() {
    const { adminData, modalData } = this.state;

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return index <= 3 ? (
        <li
          class={`page-item ${
            this.state.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={this.handleClickNext}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      ) : (
        ""
      );
    });
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = adminData.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderTodos = currentTodos.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td><a href={item.url} target="_blank" >{item.url}</a></td>
          {this.props.users.role === "Staff" ? null : (
            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.onEditClick(item.id)}
                  onMouseOver={this.btnTechHover()}
                  data-toggle="modal"
                  data-target="#myModal"
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
              <a href="#" data-toggle="tooltip" title="Delete!">
                <button
                  onClick={() => this.onEditClick(item.id)}
                  data-toggle="modal"
                  data-target="#myModal1"
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    background: "none",
                    marginLeft: 5,
                    marginBottom: 5,
                  }}
                >
                  <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
                </button>
              </a>
            </td>
          )}
        </tr>
      );
    });
    const MainContent = (
      <div>
        <div class="modal" id="myModal1">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Confirm
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="campusName">Url:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.url}
                              onChange={this.onHandleText}
                              name="url"
                              class="form-control"
                              id="campusName"
                            />
                          </div>
                        </fieldset>
                        <button
                          disabled={this.state.enabled}
                          onClick={() => this.setState({ enabled: true })}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table
                class="table table-hover table-bordered"
                style={{ textAlign: "center" }}
              >
                <thead
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">URL</th>
                    {this.props.users.role === "Staff" ? null : (
                      <th scope="col">Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>{renderTodos}</tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
    );
    return (
      <ThemeContext.Consumer>
        {(context) => (
          <div>
            <div className="page-header">
              <SnackBar msg={this.state.msg} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h2
                    class="h1-responsive font-weight-bold text-center my-4 generalHead"
                    style={{ background: context.themeColors.primaryColor }}
                  >
                    View Online Resource
                  </h2>
                </div>
                <nav aria-label="Page navigation example">
                  <ul style={{ color: "#01AC8A" }} class="pagination">
                    <li
                      class={`page-item ${
                        this.state.disableBPage ? "disabled" : ""
                      }`}
                    >
                      <a
                        onClick={this.handleClickNextBack}
                        class="page-link"
                        href="#"
                      >
                        {this.state.disableBPage ? (
                          <i
                            style={{ color: "grey" }}
                            class="far fa-stop-circle"
                          ></i>
                        ) : (
                          <i class="fas fa-backward"></i>
                        )}
                      </a>
                    </li>
                    {renderPageNumbers}
                    <li
                      class={`page-item ${
                        this.state.disablepage ? "disabled" : ""
                      }`}
                    >
                      <a
                        onClick={this.handleClickNextFrwd}
                        class="page-link"
                        href="#"
                      >
                        {this.state.disablepage ? (
                          <i
                            style={{ color: "grey" }}
                            class="far fa-stop-circle"
                          ></i>
                        ) : (
                          <i class="fas fa-forward"></i>
                        )}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            {this.state.isLoading ? Loader : MainContent}
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(getAllAdminActions, dispatch),
    campusActions: bindActionCreators(CampusActions, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    // AllAdminData: state.setCampusReducer.Data,
    allAdminState: state.setCampusReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewOnlineResource);

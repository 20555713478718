import React, { Component } from "react";
import { connect } from "react-redux";
import * as Picture from "../../utils/general/index";
import "./StaffForm.css";
import { bindActionCreators } from "redux";
import * as StaffActions from "../../Actions/StaffAdmission";
import * as sharedActions from "../../Actions/sharedActions";
import StaffDocumentsPrint from "./StaffDocumentsPrint";
import StaffCertificatePrint from "./StaffCertificatePrint";
import StaffQualificationPrint from "./StaffQualificationPrint";
import StaffSkillsPrint from "./StaffSkillsPrint";
import StaffExperiencePrint from "./StaffExperiencePrint";
import StaffSiblingsPrint from "./StaffSiblingsPrint";
import Moment from "moment";
import StaffMedicalCondition from "./PrintStaffMedical";
import LoaderWrapper from "../../components/HOC/LoaderWrapper";

class PrintStaffAdmissionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl:
        "http://ctt.trains.com/sitefiles/images/no-preview-available.png",
      getSingleAddData: [],
      gotData: false,
      images: "",
      imageData: "",
      isLoading: true,
    };
  }
  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
  };

  componentDidMount() {
    const { match } = this.props;

    this.props.shareAction
      .gAllData(
        `/api/staff/${match.params.id}`,
        this.props.StaffActions.getIndividualStaffData
      )
      .then((success) => {
        this.setState({
          isLoading: false,
          imagePreviewUrl: Picture.makeStaffImageURL(
            this.state.getSingleAddData &&
              this.state.getSingleAddData.StaffImageUpload.fileName
          ),
        });
        console.log(success);
      })
      .catch((error) => {
        this.setState({
          isLoading: true,
        });
      });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.getSingleAddData !== nextProps.getData) {
      return {
        getSingleAddData: nextProps.getData,
      };
    }
    return null;
  }
  render() {
    console.log("Image Path", this.state.imagePreviewUrl);
    console.log("DATA", this.state.getSingleAddData);
    return (
      <div>
        <LoaderWrapper isLoading={this.state.isLoading}>
          <div id="div1">
            <section class="mb-4">
              <div class="">
                <img
                  src={require("../../Assets/images/header.png")}
                  style={{
                    width: "100%",
                    height: "150",
                  }}
                />
              </div>
              <br />
              <div class="row mZero print">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <h2 className="FormHeadingStyle">
                      Staff Registration Form
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row mZero print">
                <div class="col-md-12 text-right">
                  <div class="md-form mb-0">
                    <label class="mr-2">Date:</label>
                    <label class="font-weight-bold">
                      {this.state.getSingleAddData &&
                        this.state.getSingleAddData.Staffs.date}
                    </label>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="row mZero" style={{ float: "right" }}>
                  <div class="col">
                    <div class="md-form mb-0">
                      <img
                        src={this.state.imagePreviewUrl}
                        alt="..."
                        height={220}
                        width={200}
                        class="rounded float-left shadow p-3 bg-light"
                        //   style={{ border: "solid" }}
                      ></img>
                    </div>
                  </div>
                </div>
                <div class="row rowStaffCheck" style={{ marginTop: 10 }}>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Registration No:{" "}
                    </label>
                    &nbsp;&nbsp; 0000
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.staffId}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Name In Arabic:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.fullNameArabic}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>First Name:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.StaffFirstName}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Last Name: </label>
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.lastName}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Father Name:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.fatherName}{" "}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Gender:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.gender}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Age:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.age}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Position:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.DepartmentName.departmentName}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Portal Refference:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.PostName.postName}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Designation:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.designation}{" "}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Subject Name:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.SubjectName.subjectName}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Grade:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.className}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Section:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.section}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Religion:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.religion}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Nationality:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                    this.state.getSingleAddData.Staffs.nationality !== null
                      ? Picture.getCountryName(
                          this.state.getSingleAddData &&
                            this.state.getSingleAddData.Staffs.nationality
                        )
                      : ""}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Date of birth:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      new Date(
                        this.state.getSingleAddData.Staffs.dob
                      ).toDateString()}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Place of birth:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.pob}{" "}
                  </div>
                </div>

                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Mobile Number:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.mobileNumber}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Phone No:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.phoneNo}{" "}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Email:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.email}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Passport Number:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.staffPassportNo}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Passport Issue Date:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      new Date(
                        this.state.getSingleAddData.Staffs.staffPassportDOI
                      ).toLocaleDateString()}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Passport Expiry Date:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      new Date(
                        this.state.getSingleAddData.Staffs.staffPassportDOE
                      ).toLocaleDateString()}
                  </div>
                </div>

                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      ID Card Number:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.staffIdcardNo}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      ID Card Issue Date:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      new Date(
                        this.state.getSingleAddData.Staffs.staffIdcardDOI
                      ).toLocaleDateString()}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      ID Card Expiry Date:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      new Date(
                        this.state.getSingleAddData.Staffs.staffIdcardDOE
                      ).toLocaleDateString()}{" "}
                  </div>
                </div>

                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Campus Name:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.CampusStaff.campusName}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Date Of Joining:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      new Date(
                        this.state.getSingleAddData.Staffs.dateOfJoining
                      ).toLocaleDateString()}{" "}
                  </div>
                </div>

                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Username:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.userName}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Password:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.password}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Maritial Status:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.maritailStatus}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Account Status:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.status}{" "}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Basic Salary:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.basicSalary}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>
                      Appraisal Status:
                    </label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.appraisalStatus}
                  </div>
                </div>
                <div class="row rowStaffCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Account Type:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.accountType}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Account Id:</label>
                    &nbsp;&nbsp;{" "}
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.accountId}
                  </div>
                </div>
              </div>
              <br />
              <div class="row print">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <h2 className="FormHeadingStyle">Staff Address</h2>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="row rowCheck" style={{ marginTop: 10 }}>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>State/Province: </label>{" "}
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.address}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Area:</label>
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.streetNo}
                  </div>
                </div>
                <div class="row rowCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>House No: </label>
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.houseNo}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Way No:</label>
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.wayNo}
                  </div>
                </div>

                <div class="row rowCheck">
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>City:</label>
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.city}{" "}
                  </div>
                  <div class="col colStaffCheck">
                    <label style={{ fontWeight: "bold" }}>Country:</label>
                    &nbsp;&nbsp;
                    {this.state.getSingleAddData &&
                      this.state.getSingleAddData.Staffs.country}{" "}
                  </div>
                </div>
              </div>
              <br />
              <div class="hojaa">
                <StaffDocumentsPrint
                  Document={
                    this.state.getSingleAddData &&
                    this.state.getSingleAddData.StaffDocument
                  }
                />
              </div>
              <div class="row print">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <h2 className="FormHeadingStyle">Staff Certificate</h2>
                  </div>
                </div>
              </div>
              {this.state.getSingleAddData &&
                this.state.getSingleAddData.Certificate.map((item, index) => (
                  <StaffCertificatePrint Document={item} />
                ))}
              <div class="row print">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <h2 className="FormHeadingStyle">Staff Qualifications</h2>
                  </div>
                </div>
              </div>
              {this.state.getSingleAddData &&
                this.state.getSingleAddData.Qualifications.map(
                  (item, index) => <StaffQualificationPrint Document={item} />
                )}

              <StaffMedicalCondition
                medicalCondition={
                  this.state.getSingleAddData &&
                  this.state.getSingleAddData.MedicalConditionStaffs
                }
              />

              <div class="row print">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <h2 className="FormHeadingStyle">Staff Skills</h2>
                  </div>
                </div>
              </div>
              {this.state.getSingleAddData &&
                this.state.getSingleAddData.Skills.map((item, index) => (
                  <StaffSkillsPrint Document={item} />
                ))}
              <div class="row print">
                <div class="col-md-12">
                  <div class="md-form mb-0">
                    <h2 className="FormHeadingStyle">Staff Experiences</h2>
                  </div>
                </div>
              </div>
              {this.state.getSingleAddData &&
                this.state.getSingleAddData.Experiences.map((item, index) => (
                  <StaffExperiencePrint Document={item} />
                ))}
              {/* <div class="row print">
							<div class="col-md-12">
								<div class="md-form mb-0">
									<h2
										style={{
											backgroundColor: '#0A4F5E',
											textAlign: 'center',
											color: 'white'
										}}
									>
										Staff Siblings
									</h2>
								</div>
							</div>
						</div>
						{this.state.getSingleAddData &&
							this.state.getSingleAddData.Siblings.map((item, index) => (
								<StaffSiblingsPrint Document={item} />
							))} */}
            </section>
            {/* <div id="footer">
						<div class="row">
							<div class="col-md-12">
								<div class="text-center text-md-right">
									<label for="name" class="">
										Powered By School Smart®
									</label>
								</div>
							</div>
						</div>
					</div> */}
            <div class="row" style={{ marginTop: 150 }}>
              <div class="col-md-4 text-center">
                <input
                  type="text"
                  disabled
                  style={{ border: 0, borderBottom: "1px solid #000" }}
                />
                <br />
                <label className="font-weight-bold">
                  Principal's Signature
                </label>
              </div>
              <div class="col-md-4 text-center">
                <input
                  type="text"
                  disabled
                  style={{ border: 0, borderBottom: "1px solid #000" }}
                />
                <br />
                <label className="font-weight-bold">School Stamp</label>
              </div>
              <div class="col-md-4 text-center">
                <input
                  type="text"
                  disabled
                  style={{ border: 0, borderBottom: "1px solid #000" }}
                />
                <br />
                <label className="font-weight-bold">
                  Candidate's Signature
                </label>
              </div>
            </div>
            <div class="page-footer">
              <div class="text-left">Printed By: {this.props.users.role}</div>
              <div
                class="text-right"
                style={{ marginTop: -25, marginRight: 20 }}
              >
                Powered by School Smart®
              </div>
            </div>
            <div>
              <p style={{ color: "grey", textAlign: "center" }}>
                1305 building number 464/1, complex number 313. Madinat al Irfan
                Airport Heights - Bosher - Oman.
              </p>
            </div>
          </div>
          <div class="text-center text-md-right">
            <label
              for="name"
              class=""
              onClick={() => this.PrintContent("div1")}
            >
              <i
                className="fas fa-print fa-2x animated fadeIn"
                style={{ marginRight: 4 }}
              />
              Print It Out
            </label>
          </div>
        </LoaderWrapper>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StaffActions: bindActionCreators(StaffActions, dispatch),
    shareAction: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    getData: state.setStaffFormReducer.getSingleFormData,
    users: state.setUserReducer.users,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintStaffAdmissionForm);

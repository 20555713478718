import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'
import moment from 'moment'

import ThemeContext from "../../context/themeContext/ThemeContext";


class ViewAbsentReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            modalEdit: false,
            editToggle: false,
            generateToggle: false,
            generateIndex: '',
            msg: '',
            editIndex: '',
            
            date: moment().format('MM/DD/YYYY'),
			accountId: this.props.userData.unique_name,
			accountType: this.props.userData.role

        }

        


    }

    componentDidMount(){
        this.getSimple('classSectionGradeTeacher', 'classSectionGrade')
    }

    getSimple = (type, state) => {
        this.props.sharedActions.getDataWithoutDispatch(
            `/api/${type}`
        ).then(success => { 
            this.setState({
                [state]: success,
                isLoading: false
            })


        }).catch(error => {
            this.setState({
                msg: 'Error Occured!!',
                errorMsg: true,
                isLoading: false
            })
            console.error('Error occured', error);
        })
    }


    


    PrintContent = el => {
        var restorepage = document.body.innerHTML;
        var Printcontent = document.getElementById(el).innerHTML;
        document.body.innerHTML = Printcontent;
        
        setTimeout(() => {
            window.print();
            window.location.reload();
        }, 700)
        document.body.innerHTML = restorepage;
        window.reload();
        
    };



    myFunction = () => {
		// Get the snackbar DIV
		var x = document.getElementById('snackbar');

		// StepperGeneralButtons the "show" class to DIV
		x.className = 'show';

		// After 3 seconds, remove the show class from DIV
		setTimeout(function() {
		}, 3000);
	};


        handleSearch = () => {
            const { classId, sectionId, subjectId } = this.state
            console.log(classId, sectionId, subjectId );
            
            if( isEmpty(classId) || isEmpty(subjectId) || isEmpty(sectionId) ){
                console.log('Empty');
                
                this.setState({
                    msg: "Please Fill The Form Repectively!",
                })            
                this.props.snackbar()
            }else{
                
                this.getSimple(`AbsentReportingMonthly/Daily/?classId=${classId}&sectionId=${sectionId}&subjectId=${subjectId}`,'absentReport')
                
            }
        }

    btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

    onHandleText = e => {
		this.setState({
			[e.target.name]: e.target.value
        });
        console.log([e.target.name], e.target.value)
    };
    


    render(){

        const { search, isLoading, editToggle, msg, accountId, accountType, classSectionGrade, absentReport, date,
            classId, sectionId, subjectId
        } = this.state;



        const Loader = (
            <ThemeContext.Consumer>
            {(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
			</div>
                                                                                                )}
                                                                                                </ThemeContext.Consumer>
        );
        
        const renderAbsentReportData = absentReport && absentReport.studentList.map((item, index) => {
            return (
                <ThemeContext.Consumer>
                {(context) => (
            <tr key={index} >
                <th scope="row" style={{ padding: 1 }} >
                    {index + 1}
                </th>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.studentName}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountId}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {accountType}
                </td>
                <td className="borderSpace" style={{ padding: 1 }}>
                    {item.absent}
                </td>
            </tr>
                                                                                                )}
                                                                                                </ThemeContext.Consumer>
            );
        }) 

        return (
            <ThemeContext.Consumer>
            {(context) => (
            <div className="page-header"> 
                <SnackBar msg={msg} />
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                    <h2  
                        style={{ background: context.themeColors.primaryColor }}
                        className="h1-responsive font-weight-bold text-center  generalHead">
                        Daily Absent Report
                    </h2>
							</div>
                            <div class="col-xs-6 col-sm-6 col-md-6 text-right">
								<h5>{date}</h5>
							</div>
                        </div>
                        <div class="row my-3 mx-5 py-4" style={{
                            border: `2px solid ${context.themeColors.primaryColor}`,
                            borderRadius: 20
                        }} >
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={classId}
                                                name="classId"
                                                onChange={this.onHandleText}
                                                disabled={!classSectionGrade}
                                            >
                                                        <option value="">Please Select Class</option>
                                                       {classSectionGrade && classSectionGrade.classes.map((value, index) => {
                                                           
                                                           return <option value={value.classId}>{value.className}</option>
                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={sectionId}
                                                name="sectionId"
                                                onChange={this.onHandleText}
                                                disabled={!classId}

                                            >
                                                        <option value="">Please Select Section</option>
                                                       {classSectionGrade && classSectionGrade.sections.map((value, index) => {


                                                                return value.classId == classId && 
                                                                <option value={value.sectionId}>{value.section}</option>

                                                       })}
                                            </select>
                            </div>
                            <div class="col-md-4" >
                            <select class="custom-select" 
                                                value={subjectId}
                                                name="subjectId"
                                                onChange={this.onHandleText}
                                                disabled={!sectionId}

                                            >
                                                        <option value="">Please Select Subject</option>
                                                       {classSectionGrade && classSectionGrade.subject.map((value, index) => {

                                                                return value.classId == classId &&
                                                                <option value={value.subjectId}>{value.subjectName}</option>

                                                       })}
                                            </select>
                            </div>
                            
    
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12  text-center" >
                                            <button
                                            class="btn btn-primary buttonAppear"
                                            onClick={this.handleSearch}
                                            style={{marginRight: '2%', background: context.themeColors.primaryColor}}
                                            >
                            <i class="fa fa-search"></i> Search
                                        </button>
                                        <Link to={{
                            pathname: "PrintAbsentReport",
                            state: {
                                absentReport,
                                date
                            }
                        }}  >
                            <a href="#" data-toggle="tooltip" title="Edit!">
                                
                            <button
                                            class="btn btn-primary buttonAppear"
                                            disabled={!absentReport}
                                            style={{background: context.themeColors.primaryColor}}
                                            >
                            
                                    <i className="fas fa-list"></i> View
                                </button>
                            </a> 
                            
                        </Link>
                            </div>
                            
                        </div>
                        <div class="row my-2 mx-2" style={{paddingLeft: '20px'}} >
                            <div class="col-md-4" >
                                    <span>Class: <strong style={{color: context.themeColors.primaryColor}} >{absentReport && absentReport.className}</strong></span>     
                            </div>
                            <div class="col-md-4"  >
                                    <span>Section: <strong style={{color: context.themeColors.primaryColor}} >{absentReport && absentReport.section}</strong></span>     
                            </div>
                            <div class="col-md-4" >
                                    <span>Subject: <strong style={{color: context.themeColors.primaryColor}} >{absentReport && absentReport.subjectName}</strong></span>     
                            </div>
                        </div>
                    <div className="table-responsive">
					<table className="table table-hover text-center">
						<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col" style={{ padding: 1 }}>
									#
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Student Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Name
								</th>
								<th scope="col" style={{ padding: 1 }}>
									Account Role
								</th>
								<th scope="col" style={{ padding: 1 }}>
                                    Absent
								</th>
							</tr>
						</thead>
						<tbody>{isLoading ? Loader : renderAbsentReportData}</tbody>
					</table>
				</div>
                </div>
            </div>
                                                                                                )}
                                                                                                </ThemeContext.Consumer>
        );
    }

}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch),
	};
}
function mapStateToProps(state) {
	return {
		userData: state.setUserReducer.users,
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewAbsentReport);

import React from "react";
import Colors from "../../utils/app_constants/colors_constants";
import { useContext } from "react";
import themeContext from "../../context/themeContext/ThemeContext";

export default function UploadProgressBar({ percent }) {
  const themeColor = useContext(themeContext);
  return (
    <React.Fragment>
      {percent !== 0 ? (
        <div
          style={{
            height: 30,
            background: themeColor.themeColors.primaryColor,
          }}
          class="progress"
        >
          <div
            class="progress-bar"
            role="progressbar"
            style={{
              width: `${percent}%`,
              backgroundColor: Colors.LIGHT_GREEN,
              textAlign: "center",
            }}
            // style="width: 25%;"
            aria-valuenow={percent}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {percent}%
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

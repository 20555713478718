import React, { Component } from "react";
import DateandTime from "./../Transport/DateandTime";
import GeneralSelectionOptions from "../../components/Admission/GeneralSelectionOptions";
export default class OtherInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridNum: 4,
    };
  }
  render() {
    const { onHandleTextChange, stateData, data } = this.props;

    return (
      <div>
        <section class="mb-4">
          <DateandTime />
          <div
            class="row"
            style={{
              justifyContent: "left",
              textAlign: "left",
            }}
          >
            <div class="col-md-9 mb-md-0 mb-5">
              <form
                id="contact-form"
                name="contact-form"
                // onSubmit={this.onHandleSubmit}
                noValidate
              >
                {/*<div class="row">*/}
                {/*<div class="col-md-12">*/}
                {/*<div class="md-form mb-0">*/}

                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                <br />
                <div className={"row"}>
                  <div class="col-md-12">
                    <div class="md-form mb-0">
                      <label for="email" class="">
                        {/*<i*/}
                        {/*style={{ marginRight: 4 }}*/}
                        {/*className="fas fa-university animated fadeIn"*/}
                        {/*/>*/}
                        Sports
                      </label>
                      <br />
                      <select
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "60%",
                        }}
                        onChange={(e) => onHandleTextChange(e)}
                        class="custom-select"
                        name="sports"
                        value={stateData.sports}
                      >
                        {/* <option selected={true}>Sports Name...</option>
                                                {data.map((item,index)=>(
                                                    <option value={item} key={index} >{item}</option>
                                                ))} */}
                        <GeneralSelectionOptions
                          sectionType={"OtherInformations"}
                          sports={stateData.sports}
                          data={data}
                          {...this.props}
                        />
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="md-form mb-0">
                      <label for="name" class="">
                        <i
                          className="fas fa-house animated fadeIn"
                          style={{ marginRight: 4 }}
                        />
                        Other Activity
                      </label>
                      <textarea
                        onChange={(e) => onHandleTextChange(e)}
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          boxShadow: "none",
                          width: "60%",
                        }}
                        value={stateData.otherActivity}
                        name={"otherActivity"}
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Other Activity"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <br />
                <br />
                {/*<div class="text-center text-md-center">*/}
                {/*<button class="btns">Add</button>*/}
                {/*</div>*/}
              </form>
              <div class="status" />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

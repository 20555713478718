import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/http.js";
import CustomReportComponent from "../../../components/Reports/CustomReportComponent.jsx";
import img1 from "../../../Assets/images/newheader.png";
import CustomReportHeader from "../../../components/Reports/CustomReportHeader/CustomReportHeader.jsx";
import CustomHeading from "../../../components/CustomHeading";
import { MAIN_HEADING } from "../../../utils/general/index.js";
import useCustomHttpWithDataMiddleware from "../../../hooks/Services/httpWithCustomDataMiddleware.js";
import GraphGeneralLayout from "../../../components/Graphs/GraphGeneralLayout/GraphGeneralLayout.jsx";
import { Bar, Line, Pie } from "react-chartjs-2";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import CustomBadge from "../../../components/CustomBadge/CustomBadge.jsx";
import Colors from "../../../utils/app_constants/colors_constants.js";
import SelectionInput from "../../../components/SelectionInput/SelectionInput.jsx";
import Button from "../../../components/Button/index.jsx";
import httpWithRequiredSetData from "../../../hooks/Services/httpWithRequiredSetData";
import SnackBar from "../../../components/SnackBar/SnackBar.jsx";

function CaAddFeature(props) {
  const { pid, type } = props.match.params;

  const [msg, setMsg] = useState("");
  const [isBtnLoadingNew, setIsBtnLoadingNew] = useState(false);
  const [studentDummyData, setStudentDummyData] = useState([]);

  const [graphType, setGraphType] = useState("barChart");
  const [dependencyState, setDependency] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [isLoading, remoteData] = useHttp(`/api/GetToolGraph?pId=${pid}`, []);
  const [isLoader, fetchData, setFetchData] = useHttp(
    `/api/GetViewForAssignMarkInSubTool/?pId=${pid}&month=${type}`,
    []
  );
  const [
    isLoadingFetchData,
    realFetchData,
    setFetchData1,
  ] = httpWithRequiredSetData(
    `/api/GetViewForAssignMarkInSubTool/?pId=${pid}&month=${type}`,
    [],
    (data) => {
      let getNewData = [];
      let subToolData = [];

      let getCloneData = $.extend(true, {}, data);
      let getStudentDummySample = getCloneData["studentMarks"].map((item) => {
        return {
          ...item,
          mark: "",
        };
      });

      setStudentDummyData();
      let getNewCloneData = getCloneData["SubToolTemplate"].filter(
        (item) => item.subTools.length > 0
      );

      let getFirstObj = JSON.parse(
        JSON.stringify(getCloneData["studentMarks"][0])
      );

      getCloneData["SubToolTemplate"] = [...getNewCloneData];

      const filterCloneData = getCloneData["studentMarks"].filter(
        (item) => item.studentName !== "Action"
      );
      const newArr1 = filterCloneData.map((v) => ({
        ...v,
        CA_AssignMark: v["CA_AssignMark"].map((z) => ({
          ...z,
          studentId: v.studentId,
        })),
      }));
      let get_CA_ASSIGN_MARKS = newArr1.map((item) => item.CA_AssignMark);
      // console.log("student ca_assign_marks: ", get_CA_ASSIGN_MARKS);

      get_CA_ASSIGN_MARKS.map((mainItem) =>
        mainItem.map((subitem) => getNewData.push(subitem))
      );
      let getDesireDataWithIndex = getNewData.map((item, index) => {
        return { ...item, index };
      });
      let getStudentIdentity = new Set();
      getCloneData["studentMarks"].map((item) =>
        getStudentIdentity.add(item.studentId)
      );

      [...getStudentIdentity].map((item, index) => {
        let newArry = [];
        getDesireDataWithIndex.map((itemsub) => {
          if (itemsub.studentId === item) {
            newArry.push(itemsub);
          }
        });
        subToolData.push(newArry);
        newArry = [];
      });

      let newdata = filterCloneData.map((item, index) => {
        return { ...item, CA_AssignMark: subToolData[index] };
      });

      console.log(newdata);

      console.log(subToolData);

      // for (let i = 0; i < getCloneData['studentMarks'].length; i++) {
      // 	getCloneData['studentMarks'][i];
      // }

      console.log(getNewData);
      getCloneData["SubToolTemplate"] = [...getNewCloneData];
      getCloneData["studentMarks"] = [...newdata];
      let newStrcutre = { ...getCloneData, studentMarks: [...newdata] };

      console.log(getCloneData);

      if (newStrcutre["SubToolTemplate"].length > 0) {
        newStrcutre["studentMarks"] = [
          ...newStrcutre["studentMarks"],
          { ...getFirstObj, studentName: "Action" },
        ];
      }

      setFetchData1(newStrcutre);
    }
  );

  const returnGraphs = (type) => {
    switch (type) {
      case "barChart":
        return <Bar height={60} data={data} />;

      case "pieChart":
        return <Pie height={60} data={data} />;

      case "lineChart":
        return <Line height={60} data={data} />;

      default:
        break;
    }
  };
  const [data, setData] = useState({
    datasets: [
      {
        barPercentage: 0.4,
        minBarLength: 2,
        label: "Course Average",
        data: [3, 2, 2, 3, 3, 4, 3, 2],
        backgroundColor: [
          "#ea766c",
          "#abe2b9",
          "#adbceb",
          "#be9e9d",
          "#9d5b4d",
          "#e4d86b",
          "#9ecf54",
          "#e4d86b",
        ],
        borderColor: [
          "#ea766c",
          "#abe2b9",
          "#adbceb",
          "#be9e9d",
          "#9d5b4d",
          "#e4d86b",
          "#9ecf54",
          "#e4d86b",
        ],
        borderWidth: 2,
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["BIO", "ICT", "MATH", "SOCIAL", "PHYS", "CHEM", "ENG", "ISL"],
    options: {},
  });
  let studentMarks = [
    {
      studentId: 11,
      studentName: "Ahad Raza",
      pId: 2,
      CA_AssignMark: [
        {
          id: 29,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 15,
          subToolName: "HW1",
          pId: 2,
          studentId: 11,
          marks: 12.0,
        },
        {
          id: 30,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 16,
          subToolName: "HW2",
          pId: 2,
          studentId: 11,
          marks: 13.0,
        },
        {
          id: 31,
          toolId: 7,
          toolName: "Home Work",
          subToolId: 17,
          subToolName: "HW AVG",
          pId: 2,
          studentId: 11,
          marks: 12.5,
        },
        {
          id: 32,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 18,
          subToolName: "CW1",
          pId: 2,
          studentId: 11,
          marks: 14.0,
        },
        {
          id: 33,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 19,
          subToolName: "CW2",
          pId: 2,
          studentId: 11,
          marks: 10.0,
        },
        {
          id: 34,
          toolId: 8,
          toolName: "Class Work",
          subToolId: 20,
          subToolName: "CW AVG",
          pId: 2,
          studentId: 11,
          marks: 12.0,
        },
        {
          id: 35,
          toolId: 9,
          toolName: "Total of CA",
          subToolId: 21,
          subToolName: "CW",
          pId: 2,
          studentId: 11,
          marks: 24.5,
        },
      ],
    },
    {
      studentId: 25,
      studentName: "Rafay Khan",
      pId: 2,
      CA_AssignMark: [],
    },
    {
      studentId: 26,
      studentName: "Taimoor Shah",
      pId: 2,
      CA_AssignMark: [],
    },
    {
      studentId: 27,
      studentName: "Nakash Shah",
      pId: 2,
      CA_AssignMark: [],
    },
    {
      studentId: 28,
      studentName: "Wazeer Khan",
      pId: 2,
      CA_AssignMark: [],
    },
  ];

  useEffect(() => {
    props.sharedAction
      .getDataWithoutDispatch(
        `/api/GetViewForAssignMarkInSubTool/?pId=${pid}&month=${type}`
      )

      .then((success) => {
        let getNewData = [];
        let subToolData = [];
        let getCloneData = $.extend(true, {}, success);
        let getNewCloneData = getCloneData["SubToolTemplate"].filter(
          (item) => item.subTools.length > 0
        );
        debugger;
        let getFirstObj = JSON.parse(
          JSON.stringify(getCloneData["studentMarks"][0])
        );
        debugger;

        getCloneData["SubToolTemplate"] = [...getNewCloneData];

        debugger;
        const filterCloneData = getCloneData["studentMarks"].filter(
          (item) => item.studentName !== "Action"
        );
        debugger;
        debugger;
        const newArr1 = filterCloneData.map((v) => ({
          ...v,
          CA_AssignMark: v["CA_AssignMark"].map((z) => ({
            ...z,
            studentId: v.studentId,
          })),
        }));
        debugger;

        let get_CA_ASSIGN_MARKS = newArr1.map((item) => item.CA_AssignMark);

        get_CA_ASSIGN_MARKS.map((mainItem) =>
          mainItem.map((subitem) => getNewData.push(subitem))
        );
        debugger;
        let getDesireDataWithIndex = getNewData.map((item, index) => {
          return { ...item, index };
        });
        let getStudentIdentity = new Set();
        getCloneData["studentMarks"].map((item) =>
          getStudentIdentity.add(item.studentId)
        );

        [...getStudentIdentity].map((item, index) => {
          let newArry = [];
          getDesireDataWithIndex.map((itemsub) => {
            if (itemsub.studentId === item) {
              newArry.push(itemsub);
            }
          });
          subToolData.push(newArry);
          newArry = [];
        });

        let newdata = filterCloneData.map((item, index) => {
          return { ...item, CA_AssignMark: subToolData[index] };
        });

        console.log(newdata);

        console.log(subToolData);

        console.log(getNewData);
        getCloneData["SubToolTemplate"] = [...getNewCloneData];
        getCloneData["studentMarks"] = [...newdata];
        let newStrcutre = { ...getCloneData, studentMarks: [...newdata] };

        console.log(getCloneData);

        if (newStrcutre["SubToolTemplate"].length > 0) {
          newStrcutre["studentMarks"] = [
            ...newStrcutre["studentMarks"],
            { ...getFirstObj, studentName: "Action" },
          ];
        }

        setFetchData1(JSON.parse(JSON.stringify(newStrcutre)));
      })
      .catch((error) => {});
  }, [dependencyState]);

  useEffect(() => {
    console.log(data);

    let getData = $.extend(true, {}, data);
    let total = [];
    let myArray = [];
    let subToolData = [];
    let getNewData = [];

    props.sharedAction
      .getDataWithoutDispatch(
        `/api/GetViewForAssignMarkInSubTool/?pId=${pid}&month=${type}`
      )
      .then((success) => {
        let getCloneData = $.extend(true, {}, success);
        let getNewCloneData = getCloneData["SubToolTemplate"].filter(
          (item) => item.subTools.length > 0
        );

        let getFirstObj = JSON.parse(
          JSON.stringify(getCloneData["studentMarks"][0])
        );

        getCloneData["SubToolTemplate"] = [...getNewCloneData];

        const filterCloneData = getCloneData["studentMarks"].filter(
          (item) => item.studentName !== "Action"
        );
        const newArr1 = filterCloneData.map((v) => ({
          ...v,
          CA_AssignMark: v["CA_AssignMark"].map((z) => ({
            ...z,
            studentId: v.studentId,
          })),
        }));
        let get_CA_ASSIGN_MARKS = newArr1.map((item) => item.CA_AssignMark);
        // console.log("student ca_assign_marks: ", get_CA_ASSIGN_MARKS);

        get_CA_ASSIGN_MARKS.map((mainItem) =>
          mainItem.map((subitem) => getNewData.push(subitem))
        );
        let getDesireDataWithIndex = getNewData.map((item, index) => {
          return { ...item, index };
        });
        let getStudentIdentity = new Set();
        getCloneData["studentMarks"].map((item) =>
          getStudentIdentity.add(item.studentId)
        );

        [...getStudentIdentity].map((item, index) => {
          let newArry = [];
          getDesireDataWithIndex.map((itemsub) => {
            if (itemsub.studentId === item) {
              newArry.push(itemsub);
            }
          });
          subToolData.push(newArry);
          newArry = [];
        });

        let newdata = filterCloneData.map((item, index) => {
          return { ...item, CA_AssignMark: subToolData[index] };
        });

        console.log(newdata);

        console.log(subToolData);

        // for (let i = 0; i < getCloneData['studentMarks'].length; i++) {
        // 	getCloneData['studentMarks'][i];
        // }

        console.log(getNewData);
        getCloneData["SubToolTemplate"] = [...getNewCloneData];
        getCloneData["studentMarks"] = [...newdata];
        let newStrcutre = { ...getCloneData, studentMarks: [...newdata] };

        console.log(getCloneData);

        if (newStrcutre["SubToolTemplate"].length > 0) {
          newStrcutre["studentMarks"] = [
            ...newStrcutre["studentMarks"],
            { ...getFirstObj, studentName: "Action" },
          ];
        }

        setFetchData1(newStrcutre);
      })
      .catch((error) => {});
    props.sharedAction
      .getDataWithoutDispatch(`/api/GetToolGraph?pId=${pid}`)
      .then((success) => {
        setGraphData(success);
        let getToolsAvg = success["ToolAvgs"].map((item) => item.avg);
        let getToolName = success["ToolAvgs"].map((item) => item.toolName);
        getData["datasets"][0]["data"] = [...getToolsAvg];
        getData["labels"] = [...getToolName];
        setData(getData);
      })
      .catch((error) => {});
  }, []);

  const PrintContent = () => {
    setTimeout(() => {
      window.print();
      window.location.reload();
    }, 700);
  };

  const renderTools = (toolName, arr) => {
    return arr.map((item, index) => <div id="mySpan">{item.subToolName}</div>);
  };

  let SubToolTemplate = [
    {
      pId: 2,
      toolId: 7,
      toolName: "Home Work",
      subTools: [
        {
          id: 15,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW1",
          min: 0,
          max: 15,
        },
        {
          id: 16,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW2",
          min: 0,
          max: 15,
        },
        {
          id: 17,
          toolId: 7,
          toolName: "Home Work",
          pId: 2,
          subToolName: "HW AVG",
          min: 0,
          max: 15,
        },
      ],
    },
    {
      pId: 2,
      toolId: 8,
      toolName: "Class Work",
      subTools: [
        {
          id: 18,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW1",
          min: 0,
          max: 15,
        },
        {
          id: 19,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW2",
          min: 0,
          max: 15,
        },
        {
          id: 20,
          toolId: 8,
          toolName: "Class Work",
          pId: 2,
          subToolName: "CW AVG",
          min: 0,
          max: 15,
        },
      ],
    },
    {
      pId: 2,
      toolId: 9,
      toolName: "Total of CA",
      subTools: [
        {
          id: 21,
          toolId: 9,
          toolName: "Total of CA",
          pId: 2,
          subToolName: "CA",
          min: 0,
          max: 30,
        },
      ],
    },
  ];

  let getToolsId =
    realFetchData &&
    realFetchData["SubToolTemplate"].map((item) => item.toolId);

  let getMarksCA_MARKS =
    realFetchData &&
    realFetchData["studentMarks"].map((item) => item["CA_AssignMark"]);

  const simpleFilter = (arr, Value) => {
    let getElement = arr.filter((item) => item["toolId"] === Value);
    return getElement;
  };
  const changeStrcuture = (toolsNames = [], originalAr = []) => {
    let getNew = toolsNames.map((item) => {
      return { [item]: simpleFilter(originalAr, item) };
    });
    return getNew;
  };
  const onChangeInput = (event, data) => {
    console.log(event.target.value);

    let newData = $.extend(true, {}, realFetchData);
    if (
      parseInt(event.target.value) <= parseInt(data["innerItem"]["max"]) ||
      event.target.value === ""
    ) {
      let getArrObj =
        newData["studentMarks"][data["rowIndex"]]["CA_AssignMark"];
      let getObjIndex = getArrObj.findIndex(
        (item) => item.index === data["innerItem"]["index"]
      );

      let getObj = getArrObj.filter(
        (item) => item.index === data["innerItem"]["index"]
      );

      getObj[0]["mark"] = isNaN(parseInt(event.target.value))
        ? ""
        : parseInt(event.target.value);
      getObj[0]["studentId"] = data["studentId"];

      getArrObj[getObjIndex] = JSON.parse(JSON.stringify(getObj[0]));
      newData["studentMarks"][[data["rowIndex"]]]["CA_AssignMark"] = getArrObj;

      console.log(newData);
      console.log(getObj);

      setFetchData1(newData);
    } else {
      let getArrObj =
        newData["studentMarks"][data["rowIndex"]]["CA_AssignMark"];
      let getObjIndex = getArrObj.findIndex(
        (item) => item.index === data["innerItem"]["index"]
      );

      let getObj = getArrObj.filter(
        (item) => item.index === data["innerItem"]["index"]
      );

      getObj[0]["mark"] = "";

      getArrObj[getObjIndex] = JSON.parse(JSON.stringify(getObj[0]));
      newData["studentMarks"][[data["rowIndex"]]]["CA_AssignMark"] = getArrObj;

      console.log(newData);
      console.log(getObj);

      setFetchData1(newData);
    }
    //
  };
  const renderMarks = (arr) => {
    return arr.map((item, index) => <div>{item.marks}</div>);
  };
  const onMarksSubmit = (data) => {
    let newData = $.extend(true, {}, realFetchData);

    let getNewData = [];

    let getAnotherFilter = newData["studentMarks"].filter(
      (item) => item.studentName !== "Action"
    );

    let getFilteredData = getAnotherFilter.map((item) => item.CA_AssignMark);

    getFilteredData.map((mainItem) =>
      mainItem.map((subitem) => getNewData.push(subitem))
    );

    let getFilteredResult = getNewData.filter(
      (item) => item.subToolName === data["innerItem"]["subToolName"]
    );

    setIsBtnLoadingNew(true);

    props.sharedAction
      .simpleAddRequest(`/api/AssignMarksToStudentSubTool`, {
        AssignMarksToSubTools: [...getFilteredResult],
      })
      .then((success) => {
        setIsBtnLoadingNew(false);

        $(`input[type='number']`).val("");
        setMsg("Successfully Submit");
        props.snackbar();
        setDependency((prevState) => !prevState);
      })
      .catch((error) => {});
  };
  const testedArray = (obj = {}, propName) => {
    let getAllKey = Object.keys(obj);
    let isEmpty = false;

    if (obj.hasOwnProperty(propName) && obj[propName] === "") {
      isEmpty = true;
    }
    return isEmpty;
  };

  const takeToolIds = (toolid, subToolId) => {
    let newData = $.extend(true, {}, realFetchData);
    let getNewData = [];

    let getAnotherFilter = newData["studentMarks"].filter(
      (item) => item.studentName !== "Action"
    );

    let getFilteredData = getAnotherFilter.map((item) => item.CA_AssignMark);

    getFilteredData.map((mainItem) =>
      mainItem.map((subitem) => getNewData.push(subitem))
    );

    let getFilteredResultThroguhId = getNewData.filter(
      (item) => item.toolId == toolid && item.subToolId === subToolId
    );

    let flag = false;

    let getResult = getFilteredResultThroguhId.map((item) =>
      testedArray(item, "mark")
    );
    console.log(getResult);

    console.log(
      "validation result: ",
      getResult.every((item) => item === false)
    );
    return getResult.every((item) => item === false);
  };
  const getDummyToolName = SubToolTemplate.map((item) => item.toolName);
  console.log("toolNAme: ", getDummyToolName);
  const getMarksByToolName = (toolName) => {};
  // console.log(realFetchData);
  const returnVAlue = (innerItem, rowIndex) => {
    let newdata = realFetchData["studentMarks"][rowIndex]["CA_AssignMark"];

    let getNewFilteredDAta = newdata.filter(
      (item) => item.index == innerItem["index"]
    );

    return getNewFilteredDAta[0]["mark"];
    // realFetchData['studentMarks'] &&

    // realFetchData['studentMarks'][rowIndex] &&
    // realFetchData['studentMarks'][rowIndex]['CA_AssignMark'] &&
    // .includes(innerItem['index']) ?
    // realFetchData['studentMarks'][rowIndex]['CA_AssignMark'][
    // 	innerItem['index']
    // ]['mark']
  };
  const getRenderItem = (
    obj = {},
    toolName = [],
    rowIndex,
    innerIndexCheck,
    studentId
  ) => {
    let getKey = Object.keys(obj)[0];

    return obj[getKey].map((innerItem, innerIndex) => (
      <div style={{ textAlign: "center" }}>
        {rowIndex === realFetchData["studentMarks"].length - 1 ? (
          <Button
            customClause={
              !takeToolIds(innerItem["toolId"], innerItem["subToolId"])
            }
            stateData={{
              isLoading: isBtnLoadingNew,
            }}
            buttonClass="btn-smart-one"
            textColor={Colors.WHITE}
            btnName={"Submit"}
            onHandleSubmit={() =>
              onMarksSubmit({
                innerItem,
                innerIndex: innerIndexCheck,
                rowIndex,
                studentId,
              })
            }
          />
        ) : (
          <input
            value={returnVAlue(innerItem, rowIndex)}
            onChange={(e) =>
              onChangeInput(e, {
                innerItem,
                innerIndex: innerIndexCheck,
                rowIndex,
                studentId,
              })
            }
            style={{ width: "40%" }}
            placeholder={`${innerItem["min"]} - ${innerItem["max"]}`}
            type="text"
          />
        )}
      </div>
    ));
  };
  const takeArray = (arr = [], toolName = "", rowIndex, studentId) => {
    let getAllMArks = changeStrcuture(getToolsId, arr);
    console.log("marks:", getAllMArks);
    return getAllMArks.map((item, index) => (
      <td>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {getRenderItem(item, getToolsId, rowIndex, index, studentId)}
        </div>
      </td>
    ));
  };
  const getToolNameForRendering = (nameTool, arr, index) => {
    let getAllMArks = changeStrcuture(getToolsId, arr);
    return getAllMArks[index][nameTool].map((item) => (
      <React.Fragment>
        <td style={{ padding: 10 }}>{item["marks"]}</td>
      </React.Fragment>
    ));
  };
  console.log("Student Discipline Reports", props);
  return (
    <div id="printDiv">
      <div>
        <SnackBar msg={msg} />
        <img src={img1} alt="#img" style={{ width: "100%", height: "30%" }} />
        <CustomHeading
          headingText={"CONTINUOUS  ASSESSMENT REPORT"}
          type={MAIN_HEADING}
          className="reportHead"
        />

        <CustomReportHeader
          headRenderData={[
            { displayData: "Subject Name", fieldName: "subject" },
            { displayData: "Grade Name", fieldName: "gradeName" },
            { displayData: "Course Type", fieldName: "courseType" },
          ]}
          targetedObj="primaryInfoes"
          headData={realFetchData}
        />

        <CustomReportHeader
          headRenderData={[
            { displayData: "Class Name", fieldName: "className" },
            { displayData: "Section", fieldName: "section" },
            { displayData: "Term", fieldName: "term" },
            { displayData: "Session", fieldName: "session" },
          ]}
          targetedObj="primaryInfoes"
          headData={realFetchData}
        />
        <br />
        <table className="table table-bordered">
          <thead>
            <tr>
              <th rowSpan={2}>S #</th>
              <th rowSpan={2}> Student Name </th>
              {realFetchData &&
                realFetchData["SubToolTemplate"].map((item, index) => (
                  <th style={{ textAlign: "center" }}>{`${item.toolName} (${
                    item.subTools[0] && item.subTools[0]["max"]
                  } | ${item.subTools[0] && item.subTools[0]["min"]}) `}</th>
                ))}
            </tr>
            <tr>
              {realFetchData &&
                realFetchData["SubToolTemplate"].map((item, index) => (
                  <th>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        // border: '1px solid black'
                      }}
                    >
                      {renderTools(item.toolName, item.subTools)}
                    </div>
                  </th>
                ))}
            </tr>
            <React.Fragment>
              {realFetchData &&
                realFetchData["studentMarks"].map((item, index) => (
                  <React.Fragment>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item["studentName"]}</td>
                      {takeArray(
                        item["CA_AssignMark"],
                        "e",
                        index,
                        item["studentId"]
                      )}
                    </tr>
                  </React.Fragment>
                ))}
            </React.Fragment>
          </thead>
        </table>
        {/* <table className="table table-bordered">
					<thead>
						<th>
							<table className="table table-bordered">
								<tr>
									<th>S #</th>
									<th> Student Name </th>
									{realFetchData &&
										realFetchData['SubToolTemplate'].map((outerItem, index) => (
											<td
												style={{
													padding: 0,

													flexDirection: 'column'
												}}
											>
												<tr style={{ textAlign: 'center', display: 'block' }}>
													{outerItem['toolName']}
												</tr>
												<tr style={{ alignSelf: 'center' }}>
													{outerItem['subTools'].map(
														(innerItem, innerIndex) => (
															<React.Fragment>
																<td style={{ fontSize: 11 }} id="mySpan">
																	{' '}
																	{innerItem['subToolName']}
																</td>{' '}
															</React.Fragment>
														)
													)}
												</tr>
											</td>
										))}
								</tr>
								{realFetchData &&
									realFetchData['studentMarks'].map((item, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>{item.studentName}</td>
											{getToolsId &&
												getToolsId.map((inneritem, index) => (
													<td>
														{getToolNameForRendering(
															inneritem,
															item['CA_AssignMark'],
															index
														)}
													</td>
												))}
										</tr>
									))}
							</table>
						</th>
					</thead>
				</table> */}
        <br />
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(CaAddFeature);

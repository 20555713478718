import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import TextInput from '../../components/TextInput/TextInput';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import general from '../../utils/general';
import Colors from '../../utils/app_constants/colors_constants';
import CustomFilterSectionLayout from '../../components/CustomFilterSectionLayout/CustomFilterSectionLayout';
import { Link } from 'react-router-dom';
import ThemeContext from "../../context/themeContext/ThemeContext";

let tHead = ['Name', 'Subject', 'Actions'];

let renderFields = ['staffName', 'subjectName'];

const ViewStaffSubjectRenew = props => {
const themeColor = useContext(ThemeContext);

	const [staffId, setStaffId] = useState('');
	const [error, setError] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const [staffData, setStaffData] = useState([]);

	const [searchURL, setSearchURL] = useState(
		`/api/GetSubjectStaffList?&pageNumber=${1}&pageSize=${10}`
	);

	const [disableBPage, setdisableBPage] = useState(true);
	const [customPageSize, setCustomPageSize] = useState('10');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumber, setPageNumber] = useState([]);
	const [disablepage, setdisablepage] = useState(false);
	const [pagination, setPaginationData] = useState({});

	const [
		isLoading,
		fetchData,
		setFetchData,
		setIsLoading
	] = httpWithRequiredSetData(searchURL, [], data => {
		let pageNumber = [];
		for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
			pageNumber.push(i);
		}
		setPageNumber(pageNumber);
		setStaffData(data['staff']);

		setPaginationData(data['paginationMetadata']);
	});

	useEffect(() => {
		if (error || errorMsg) {
			setTimeout(() => {
				setError(false);
				setErrorMsg(false);
			}, 3000);
		}
	});

	const onHandleTextChange = e => {
		setStaffId(e.target.value);
	};

	useEffect(() => {
		return () => {
			$('[data-toggle="tooltip"]').tooltip('hide');
		};
	}, []);

	const handleClickNextFrwd = () => {
		console.log('pagelength: ', pageNumber.length);
		console.log('current page: ', currentPage);
		if (currentPage === pageNumber.length) {
			setdisablepage(true);
		} else {
			setCurrentPage(prevState => prevState + 1);
			console.log(currentPage);

			setdisableBPage(false);
		}
	};

	useEffect(() => {
		console.log('Do something after counter has changed', currentPage);
		setIsLoading(true);
		props.sharedActions
			.getDataWithoutDispatch(
				general.condtionalServicesViewStaffSubject(
					staffId,
					currentPage,
					customPageSize
				)
			)
			.then(success => {
				setStaffData(success['staff']);
				setIsLoading(false);
				let pageNumber = [];
				for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
					pageNumber.push(i);
				}
				setPageNumber(pageNumber);
				setPaginationData(success['paginationMetadata']);
			})
			.catch(error => {
				setIsLoading(false);
				setStaffData([]);
				setPaginationData({});
			});
	}, [currentPage, staffId, customPageSize]);

	const handleClickNext = event => {
		setCurrentPage(Number(event.target.id));
		setdisableBPage(false);
		setdisableBPage(false);
	};
	const handleClickNextBack = () => {
		if (currentPage === 1) {
			setdisableBPage(true);
			setdisablepage(false);
		} else {
			setCurrentPage(prevState => prevState - 1);
		}
	};
	const [msg, setSnackMsg] = useState('');

	const onChangePageSize = e => {
		if (e.target.value !== 'Page Entries') {
			setCustomPageSize(e.target.value);
			setCurrentPage(1);
		}
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor
					}}
					scope="row"
				>
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${themeColor.themeColors.primaryColor}`,
							padding: 2,
							borderColor: themeColor.themeColors.primaryColor
						}}
					>
						{item[item1]}
					</td>
				))}

				<td
					style={{
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeColor.themeColors.primaryColor
					}}
				>
					<Link
						to={`/dashboard/AssignSubjectToStaff/${item.staffId}/${item.staffName}`}
					>
						<button
							id="firstbutton"
							// onClick={() => this.onEditClick(item.id)}
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
						>
							<a href="#" data-toggle="tooltip" title="Assign!">
								<i style={{ color: '#01AC8A' }} class="fas fa-plus-circle"></i>
							</a>
						</button>
					</Link>
				</td>
			</tr>
		));

	return (
		<div>
			<div style={{ textAlign: 'right' }}>
				<SnackBar msg={msg} />
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeColor.themeColors.primaryColor}}>
							{' '}
							Subjects By Staff{' '}
						</h2>
					</div>
					<div
						style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
					>
						<Pagination
							disableBPage={disableBPage}
							disablepage={disablepage}
							currentPage={currentPage}
							pageNumber={pageNumber}
							handleClickNext={handleClickNext}
							handleClickNextBack={handleClickNextBack}
							handleClickNextFrwd={handleClickNextFrwd}
						/>
						<div
							style={{
								width: 'fit-content',
								marginTop: -34,
								display: 'flex',
								justifyContent: 'flex-end',
								marginLeft: 'auto'
							}}
						>
							<SelectionInput
								selectName="Entries"
								selectFont={14}
								selectFontWeight={'bold'}
								newFlag="customWidth"
								feildName="customPageSize"
								selectedText={'Page Entries'}
								optionsArrys={['10', '20', '30', '40', '50']}
								errors={{}}
								isValidte={false}
								stateData={{
									customPageSize: customPageSize
								}}
								optionType="static"
								onHandleChange={onChangePageSize}
								useFlag={false}
							/>
						</div>
					</div>
				</div>
				<CustomFilterSectionLayout displayDirection="column">
					<div
						style={{
							width: '50%',
							margin: '0 auto',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<React.Fragment>
							<TextInput
								feildName={'staffId'}
								inputLabelName={'Staff ID'}
								onHandleChange={onHandleTextChange}
								errors={{}}
								stateData={{
									staffId: staffId
								}}
								placeholder={'Staff ID'}
								isValidte={false}
								customWidth={true}
								enterWidth={'100%'}
							/>
						</React.Fragment>
					</div>
				</CustomFilterSectionLayout>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span className="customBadge" style={{ fontSize: 14 }}>
						Page: {currentPage}
					</span>

					<span className="customBadge" style={{ fontSize: 14 }}>
						Total Page: {pagination['totalPages']}
					</span>
				</div>
			</div>
			{isLoading ? (
				<Spinner />
			) : staffData.length < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								{tHead.map(item => (
									<th
										style={{
											border: `1px solid ${themeColor.themeColors.primaryColor}`,
											borderColor: Colors.WHITE
										}}
										scope="col"
									>
										{item}
									</th>
								))}
							</tr>
						</thead>
						<tbody>{bodyRendering(staffData)}</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		role: state.setUserReducer.users.role,
		name: state.setUserReducer.users.unique_name
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewStaffSubjectRenew);

import React from 'react';
export default function CardForVisitor(props) {
    console.log(props);
    const {
        schoolName,
        passType,
        cardColor,
        number
    } = props;

    return (

        <div class="card shadowForBox border" style={{background: cardColor, color: "#fff", "width": "31rem", "height": "20rem" }}>
            <div class='row print' style={{ marginLeft: '0.2px', width: "31rem" }}>
                <div class=' col-md-12 print' style={{ height: '10rem', display: 'flex' }}>
                    <h5 class='print' style={{ fontSize: 40, fontWeight: 1000, margin: 'auto' }}>{schoolName}</h5>
                </div>
                <div class=' col-md-12 print' style={{  height: '10rem', display: 'flex' }}>
                    <h2 style={{ fontSize: 40, fontWeight: 1000, color: "white", margin: 'auto' }}>{passType}</h2>
                </div>
            </div>
        </div>
    );
}

import React, { Component } from "react";
import SnackBar from "../../components/SnackBar/SnackBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CensusActions from "../../Actions/CensusInformation";
import * as sharedActions from "../../Actions/sharedActions";
import $ from "jquery";
import ShowTable from "./ShowTable";
import ThemeContext from "../../context/themeContext/ThemeContext";

class Testing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      modalData: null,
      sections: [],
      sectionName: "",
      className: "",
      currentSession: "",
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      select: [],
      handler: [],
      check: true,
      btnLoad: false,
      allCheck: true,
      age: ""
    };
  }
  onSelectSectionChange = (e) => {
    console.log(e.target.value);

    const sections = this.props.Document;
    let check = sections.filter((item) => item.classId == e.target.value);
    console.log(check);

    this.setState({
      sections: check,
      className: e.target.value,
    });
  };
  onSelectStudentChange = (e) => {
    console.log(e.target.value);

    this.setState({
      sectionName: e.target.value,
    });
  };
  onSelectSessionChange = (e) => {
    console.log(e.target.value);

    this.setState({
      currentSession: e.target.value,
    });
    console.log(this.state);
  };
  onHandleSubmit = (e) => {
    this.setState({
      btnLoad: true,
    });
    this.props.sharedActions
      .getAllData(
        `/api/filterStudentCensu/?classId=${this.state.className}&sectionId=${this.state.sectionName}&sessionId=${this.state.currentSession}&age=${this.state.age}`,
        this.props.CensusActions.setAllCensusInRedux,
        this.props.CensusActions.updateCensusFlag
      )
      .then((success) => {
        this.setState({
          allCheck: false,
          isLoading: false,
          check: false,
          btnLoad: false,
        });
      })
      .catch((err) => {
        // this.props.CensusActions.updateCensusFlag(false);
        this.setState({
          isLoading: false,
          check: true,
          btnLoad: false,
        });

        console.log(err);
      });
    console.log(this.state);
  };

  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.adminData !== nextProps.AllTransportData && prevState.allCheck === false) {
      return {
        adminData: nextProps.AllTransportData,
      };
    }
    return null;
  }
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions.getDataWithoutDispatch("/api/getCurrentStudentInCensus")
      .then((success) => {
        debugger;
        this.setState({
          check: false,
          adminData: success
        }, () => {
          console.log("Census Data", this.state.adminData);
        })
      })
      .catch((error) => { });
  }
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };
  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
    window.location.reload();
  };
  render() {
    const { data } = this.props;
    const { session } = this.props;
    const { adminData, modalData } = this.state;
    const { studentClass } = this.state.adminData;

    console.log("checking data: ", studentClass);
    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center", color: "red" }}>
        No Data Found
      </div>
    );
    const MainContent = (
      <div>
        <div id="div1">
          <div class="modal" id="myModal1">
            <div class="modal-dialog">
              <div class="modal-content modal_content_custom">
                <div
                  style={{
                    borderBottomLeftRadius: 30,
                    borderBottomRightRadius: 30,
                    borderTopLeftRadius: 18,
                    borderTopRightRadius: 18,
                  }}
                  class="modal-header modal_custom_header"
                >
                  <h4 style={{ color: "white" }} class="modal-title">
                    Confirm
                </h4>
                  <button
                    style={{ color: "white" }}
                    type="button"
                    class="close"
                    data-dismiss="modal"
                  >
                    &times;
                </button>
                </div>
                <div class="modal-body">
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <div style={{ textAlign: "center" }}>
                          <h4>Are you sure ?</h4>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div>
                            <button
                              disabled={this.state.enabled}
                              data-dismiss="modal"
                              onClick={() => this.onTakeItemDelete(this.state.id)}
                              type="button"
                              class="btn btn-primary buttonAppear"
                            >
                              Yes
                          </button>
                          </div>
                          <div>
                            <button
                              data-dismiss="modal"
                              disabled={this.state.enabled}
                              type="button"
                              class="btn btn-primary buttonAppear"
                            >
                              No
                          </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div class="modal" id="myModal">
            <div class="modal-dialog">
              <div class="modal-content modal_content_custom">
                <div
                  style={{
                    borderBottomLeftRadius: 30,
                    borderBottomRightRadius: 30,
                    borderTopLeftRadius: 18,
                    borderTopRightRadius: 18,
                  }}
                  class="modal-header modal_custom_header"
                >
                  <h4 style={{ color: "white" }} class="modal-title">
                    Details
                </h4>
                  <button
                    style={{ color: "white" }}
                    type="button"
                    class="close"
                    data-dismiss="modal"
                  >
                    &times;
                </button>
                </div>
                <div class="modal-body">
                  <form onSubmit={this.onHandleSubmit}>
                    {modalData &&
                      modalData.map((item, index) => (
                        <div key={index}>
                          <fieldset disabled={!this.state.enabled}>
                            <div class="form-group">
                              <label for="routeName">Stock In:</label>
                              <input
                                style={{
                                  borderTop: 0,
                                  borderLeft: 0,
                                  borderRight: 0,
                                  boxShadow: "none",
                                }}
                                type="text"
                                value={this.state.routeName}
                                onChange={this.onHandleText}
                                name="routeName"
                                class="form-control"
                                id="routeName"
                              />
                            </div>
                            <div class="form-group">
                              <label for="routeFees">Route Fees</label>
                              <input
                                required
                                style={{
                                  borderTop: 0,
                                  borderLeft: 0,
                                  borderRight: 0,
                                  boxShadow: "none",
                                }}
                                value={this.state.routeFees}
                                onChange={this.onHandleText}
                                type="text"
                                name="routeFees"
                                class="form-control"
                                id="routeFees"
                              />
                            </div>
                          </fieldset>
                          <button
                            disabled={this.state.enabled}
                            onClick={() => this.setState({ enabled: true })}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Edit
                        </button>
                          <button
                            style={{ marginLeft: 5 }}
                            disabled={!this.state.enabled}
                            type="submit"
                            class="btn btn-primary buttonAppear"
                          >
                            Save
                        </button>
                        </div>
                      ))}
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    style={{ background: "#00435D" }}
                    class="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                </button>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <img
              src={require("../../Assets/images/header.png")}
              style={{
                width: "100%",
                height: "150",
              }}
            />
          </div>
          <div className="table-responsive">
            <ThemeContext.Consumer>
              {(context) => (
                <table class="table table-hover table-bordered">
                  <thead
                    style={{
                      background: context.themeColors.primaryColor,
                      color: "white",
                    }}
                  >
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Current Session</th>
                      <th scope="col">Admission No</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Father Name </th>
                      <th scope="col">Parent Mobile No </th>
                      <th scope="col">Age</th>
                      <th scope="col">Class</th>
                      <th scope="col">Section</th>
                      <th scope="col" className="no-print">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ShowTable data={studentClass} />
                  </tbody>
                </table>
              )}
            </ThemeContext.Consumer>

          </div>
          <div class="page-footer">
            <div class="text-left">Printed By: {this.props.userData.role}</div>
            <div class="text-right" style={{ marginTop: -25, marginRight: 20 }}>
              Powered by School Smart®
            </div>
          </div>
        </div>
        <div
          class="text-center text-md-right displayElemet"
          style={{ marginBottom: 10 }}
        >
          <label for="name" class="" onClick={() => this.PrintContent("div1")}>
            <i
              className="fas fa-print fa-2x animated fadeIn"
              style={{ marginRight: 4 }}
            />
            Print
          </label>
        </div>
      </div>
    );
    return (
      <div>
        <div className="page-header">
          <SnackBar msg={this.state.msg} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2>
                Search&nbsp;<small class="text-muted">Student</small>
              </h2>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                <li
                  class={`page-item ${this.state.disableBPage ? "disabled" : ""
                    }`}
                >
                  <a
                    onClick={this.handleClickNextBack}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disableBPage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-backward"></i>
                    )}
                  </a>
                </li>
                {/* {renderPageNumbers} */}
                <li
                  class={`page-item ${this.state.disablepage ? "disabled" : ""
                    }`}
                >
                  <a
                    onClick={this.handleClickNextFrwd}
                    class="page-link"
                    href="#"
                  >
                    {this.state.disablepage ? (
                      <i
                        style={{ color: "grey" }}
                        class="far fa-stop-circle"
                      ></i>
                    ) : (
                      <i class="fas fa-forward"></i>
                    )}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" style={{ marginTop: 20 }}>
            <div class="md-form mb-0">
              <label for="email" class="">
                Session's
              </label>
              <select
                onChange={this.onSelectSessionChange}
                class="custom-select"
                name="sectionName"
              >
                <option selected={this.state.msg ? true : false}>
                  Select Current Session..
                </option>
                {session &&
                  session.map((item, index) => (
                    <option value={`${item.sessionId}`}>
                      {item.currentSession}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div class="col-md-3" style={{ marginTop: 20 }}>
            <div class="md-form mb-0">
              <label for="email" class="">
                Class Name
              </label>
              <select
                onChange={this.onSelectSectionChange}
                class="custom-select"
                name="studentId"
              >
                <option selected={this.state.msg ? true : false}>
                  Choose Class..
                </option>
                {data &&
                  data.map((item, index) => (
                    <option key={index} value={`${item.id}`}>
                      {item.className}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div class="col-md-3" style={{ marginTop: 20 }}>
            <div class="md-form mb-0">
              <label for="email" class="">
                Class Section
              </label>
              <select
                onChange={this.onSelectStudentChange}
                class="custom-select"
                name="sectionName"
              >
                <option selected={this.state.msg ? true : false}>
                  Choose Section...
                </option>
                {this.state.sections.map((item, index) => (
                  <option value={`${item.sectionId}`}>{item.section}</option>
                ))}
              </select>
            </div>
          </div>
          <div class="col-md-3" style={{ marginTop: 20 }}>
            <div class="md-form mb-0">
              <label for="email" class="">
                Age
              </label>
              <input
                type="text"
                name="age"
                class="form-control"
                onChange={this.onHandleText}
                placeholder="Please Enter Your Age"
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div class="text-center text-md-right">
          <ThemeContext.Consumer>
            {(context) => (
              <button
                type="submit"
                class="buttonHover2"
                style={{
                  background: context.themeColors.primaryColor,
                }}
                onClick={this.onHandleSubmit}
              >
                Search
                <span
                  style={{ marginBottom: 5 }}
                  class={`${this.state.btnLoad ? "spinner-border spinner-border-sm" : ""
                    }`}
                ></span>
              </button>
            )}
          </ThemeContext.Consumer>
        </div>
        {this.state.check ? Loader : MainContent}

      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    CensusActions: bindActionCreators(CensusActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userData: state.setUserReducer.users,
    AllTransportData: state.setCensusInformationReducer.Data,
    allTransportState: state.setCensusInformationReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Testing);

import React from "react";
import ViewRendering from "../../../components/ViewRendering";
import { renderingStuff, images } from "../../../utils";
import NewViewRendering from "../../../components/ViewRendering/NewViewRendering";
import DashboardLayout from "../../../components/HOC/DashboardLayout";

function AcademicLMS(props) {
  return (
    <div>
		<DashboardLayout name="LMS Dashboard" image={images.imgAcademicLMS}>
			<NewViewRendering {...props} data={renderingStuff.academicLmsLink} />
		</DashboardLayout>
    </div>
  );
}

export default AcademicLMS;

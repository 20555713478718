import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import * as sharedActions from '../../Actions/sharedActions';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import SnackBar from '../../components/SnackBar/SnackBar';
import { render } from 'react-dom';
import ThemeContext from "../../context/themeContext/ThemeContext";

class ViewClassTeacherRenew extends React.Component {
	state = {
		staffPostDepart: {},
		classSection: {},
		postData: [],
		staffData: [],
		classTeacherData: [],
		isLoading: true,
		msg: '',
		modalData: null,
		enabled: false,
		id: null,
		accountType: '',
		accountId: '',
		currentPage: 1,
		todosPerPage: 10,
		disablepage: false,
		disabledPB: true,
		disabledP: true,
		successflag: false,
		count: 1,
		errors: {},
		staffId: '',
		classId: '',
		sectionId: '',
		staffN: '',
		snackColor: ''
	};

	componentDidMount() {
		$(document).ready(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});

		this.props.sharedActions
			.getDataWithoutDispatch('/api/assignclassteacher')
			.then(success => {
				this.setState({
					classTeacherData: success,
					isLoading: false
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});
			});

		this.props.sharedActions
			.getDataWithoutDispatch('/api/staffpostdepart')
			.then(success => {
				this.setState({
					staffPostDepart: success
				});
			})
			.catch(err => {
				this.setState({
					isLoading: false
				});
				console.log(err);
			});
	}

	btnTechHover = () => {
		$(document).ready(function() {
			$('button').hover(
				function() {
					$(this).css('box-shadow', '0px 5px 5px grey');
					$(this).css('border-color', 'white');
				},
				function() {
					$(this).css('box-shadow', '0px 0px 0px white');
				}
			);
		});
	};

	onTakeItem = itemId => {
		this.setState({
			modalData: []
		});
		const { classTeacherData } = this.state;

		let getData = classTeacherData.filter(item => item.id === itemId);

		console.log(getData);

		this.setState({
			modalData: getData,
			staffN: getData[0].classTeacher,
			classId: getData[0].classId,
			staffId: getData[0].staffId,
			id: getData[0].id,
			className: getData[0].className,
			section: getData[0].section
		});
	};

	componentDidUpdate() {
		if (this.state.msg) {
			setTimeout(() => {
				this.setState({
					msg: false
				});
			}, 3000);
		}
	}

	onEditClick = itemId => {
		this.onTakeItem(itemId);
		this.setState({
			enabled: false
		});
	};

	onTakeItemDelete = itemId => {
		const { classTeacherData, id } = this.state;
		this.setState({
			modalData: [],
			isLoading: true
		});

		console.log(itemId);

		this.props.sharedActions
			.deleteRecordWithoutDispatch('/api/assignclassteacher', id)
			.then(success => {
				const result = classTeacherData.filter(
					classTeacher => classTeacher.id !== itemId
				);

				this.setState({
					msg: 'Deleted Successfully!',
					classTeacherData: result,
					isLoading: false,
					errors: {},
					classId: ''
				});
				this.props.snackbar();
			})
			.catch(err => {
				console.log(err);
				this.setState({
					isLoading: false
				});
			});
	};

	onChangeDepartHandle = e => {
		const { staffPostDepart } = this.state;

		const postData = staffPostDepart.post.filter(
			(item, index) => item.departmentId == e.target.value
		);
		this.setState({
			departId: e.target.value,
			postData: postData
		});
	};

	onChangePostHandle = e => {
		const { staffPostDepart, departId } = this.state;

		const staffData = staffPostDepart.staff.filter(
			(item, index) =>
				item.departmentId == departId && item.staffId == e.target.value
		);
		this.setState({
			postId: e.target.value,
			staffData: staffData
		});
	};

	onChangeStaffHandle = e => {
		const { staffData } = this.state;
		const staffN = staffData.filter(item => item.staffId == e.target.value);
		this.setState({
			staffN: staffN[0].staffName,
			staffId: e.target.value
		});
	};

	onHandleSubmit = e => {
		e.preventDefault();

		const {
			staffId,
			classTeacherData,
			staffN,
			id,
			className,
			section
		} = this.state;
		var bodyParameters = {
			id,
			staffId,
			classTeacher: staffN,
			className,
			section
		};

		console.log(bodyParameters);
		console.log(id);

		this.props.sharedActions
			.editPatchRecordWithoutDispatch(
				'/api/assignclassteacher/',
				id,
				bodyParameters
			)
			.then(success => {
				classTeacherData.filter((classTeacherItem, index) => {
					if (classTeacherItem.id === id) {
						classTeacherData.splice(index, 1, bodyParameters);
					}
				});
				console.log(bodyParameters);

				this.setState({
					enabled: false,
					msg: 'Edited Record Successfully!',
					editFlag: true,
					selectFlag: true,
					modalData: null
				});
				$(function() {
					$('#myModal').modal('toggle');
				});
				this.props.snackbar();
			})
			.catch(err => {
				if (err && err.response && err.response.status == 400) {
					this.setState({
						isLoading: false,
						subjectData: [],
						msg: err.response.data.Message,
						snackColor: '#ff0000',
						staffId: '',
						departId: '',
						postId: ''
					});
					this.props.snackbar();
				}
			});
	};

	render() {
		const {
			modalData,
			classTeacherData,
			isLoading,
			editFlag,
			className,
			errors,
			id,
			staffData,
			staffPostDepart,
			postData
		} = this.state;
		const Loader = (
			<ThemeContext.Consumer>
            {(context) => (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div class="spinner-border" style={{ color: context.themeColors.primaryColor }}></div>
			</div>
						)}
						</ThemeContext.Consumer>
		);

		let pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(classTeacherData.length / this.state.todosPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number, index) => {
			return index <= 3 ? (
				<ThemeContext.Consumer>
				{(context) => (
				<li
					class={`page-item ${
						this.state.currentPage === number ? 'active' : ''
					} `}
					style={{background: context.themeColors.primaryColor}}
				>
					<a
						key={number}
						id={number}
						onClick={this.handleClickNext}
						class="page-link "
						href="#"
						style={{background: context.themeColors.primaryColor}}
					>
						{number}
					</a>
				</li>
							)}
							</ThemeContext.Consumer>
			) : (
				''
			);
		});
		const { currentPage, todosPerPage } = this.state;
		const indexOfLastTodo = currentPage * todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const currentTodos = classTeacherData.slice(
			indexOfFirstTodo,
			indexOfLastTodo
		);
		const renderTodos = currentTodos.map((item, index) => {
			return (
				<ThemeContext.Consumer>
				{(context) => (
				<tr key={index}>
					<th scope="row">{index + 1}</th>
					<td style={{ padding: 2 }}>{item.className}</td>
					<td style={{ padding: 2 }}>{item.section}</td>
					<td style={{ padding: 2 }}>{item.classTeacher}</td>

					<td style={{ padding: 2 }}>
						<a href="#" data-toggle="tooltip" title="Edit!">
							<button
								id="firstbutton"
								onClick={() => this.onEditClick(item.id)}
								onMouseOver={this.btnTechHover()}
								data-toggle="modal"
								data-target="#myModal"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									marginLeft: 5,
									background: 'none',
									marginBottom: 5
								}}
							>
								<i class="fas fa-pen"></i>
							</button>
						</a>
						<a href="#" data-toggle="tooltip" title="Delete!">
							<button
								onClick={() => this.onEditClick(item.id)}
								data-toggle="modal"
								data-target="#myModal1"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									border: 'none',
									background: 'none',
									marginLeft: 5,
									marginBottom: 5
								}}
							>
								<i style={{ color: '#D11A2A' }} class="fas fa-trash-alt"></i>
							</button>
						</a>
					</td>
				</tr>
							)}
							</ThemeContext.Consumer>
			);
		});

		const MainContent = (
			<ThemeContext.Consumer>
            {(context) => (
			<div>
				<div class="modal" id="myModal1">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18,
									background: context.themeColors.primaryColor
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Confirm
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								{modalData &&
									modalData.map((item, index) => (
										<div key={index}>
											<div style={{ textAlign: 'center' }}>
												<h4>Are you sure ?</h4>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-evenly'
												}}
											>
												<div>
													<button
														disabled={this.state.enabled}
														data-dismiss="modal"
														onClick={() => this.onTakeItemDelete(this.state.id)}
														type="button"
														class="btn btn-primary buttonAppear"
														style={{background: context.themeColors.primaryColor}}
													>
														Yes
													</button>
												</div>
												<div>
													<button
														data-dismiss="modal"
														disabled={this.state.enabled}
														type="button"
														class="btn btn-primary buttonAppear"
														style={{background: context.themeColors.primaryColor}}
													>
														No
													</button>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content modal_content_custom">
							<div
								style={{
									borderBottomLeftRadius: 30,
									borderBottomRightRadius: 30,
									borderTopLeftRadius: 18,
									borderTopRightRadius: 18,
									background: context.themeColors.primaryColor
								}}
								class="modal-header modal_custom_header"
							>
								<h4 style={{ color: 'white' }} class="modal-title">
									Details
								</h4>
								<button
									style={{ color: 'white' }}
									type="button"
									class="close"
									data-dismiss="modal"
								>
									&times;
								</button>
							</div>
							<div class="modal-body">
								<form onSubmit={this.onHandleSubmit}>
									{modalData &&
										modalData.map((item, index) => (
											<div key={index}>
												<fieldset disabled={!this.state.enabled}>
													<SelectionInput
														feildName={'departmentName'}
														isValidte={false}
														newFlag={'customWidth'}
														selectName={'Department Name'}
														onHandleChange={e => this.onChangeDepartHandle(e)}
														errors={errors}
														optionsArrys={staffPostDepart.department}
														selectedText={'Department Name'}
														stateData={this.state}
														optionType="dynamicWithPropIdAndName"
														property={'departmentName'}
														propertyId={'departmentId'}
													/>
													<SelectionInput
														feildName={'postName'}
														isValidte={false}
														newFlag={'customWidth'}
														selectName={'Post Name'}
														onHandleChange={e => this.onChangePostHandle(e)}
														errors={errors}
														optionsArrys={postData}
														selectedText={'Post Name'}
														stateData={this.state}
														optionType="dynamicWithPropIdAndName"
														property={'postName'}
														propertyId={'postId'}
													/>
													<SelectionInput
														feildName={'staffName'}
														isValidte={false}
														newFlag={'customWidth'}
														selectName={'Staff Name'}
														onHandleChange={e => this.onChangeStaffHandle(e)}
														errors={errors}
														optionsArrys={staffData}
														selectedText={'Staff Name'}
														stateData={this.state}
														// iconClassName={'fas fa-clock animated fadeIn'}
														optionType="dynamicWithPropIdAndName"
														property={'staffName'}
														propertyId={'staffId'}
													/>{' '}
													{/*                                                     <SelectionInput
                                                        feildName={'courseType'}
                                                        newFlag={
                                                            'customWidth'
                                                        }
                                                        isValidte={false}
                                                        selectName={'Course Type'}
                                                        onHandleChange={this.onSelectCourseChange}
                                                        errors={errors}
                                                        optionsArrys={general.courseType}
                                                        selectedText={'Course Type'}
                                                        stateData={this.state}
                                                        optionType='static'
                                                        successflag={'successflag'}>
                                                    </SelectionInput>
 */}
												</fieldset>
												<br />
												<br />
												<button
													disabled={this.state.enabled}
													onClick={() =>
														this.setState({ enabled: true, editFlag: false })
													}
													type="button"
													class="btn btn-primary buttonAppear"
													style={{background: context.themeColors.primaryColor}}
												>
													Edit
												</button>
												<button
													style={{ marginLeft: 5, background: context.themeColors.primaryColor }}
													disabled={!this.state.enabled}
													type="submit"
													class="btn btn-primary buttonAppear"
												>
													Save
												</button>
											</div>
										))}
								</form>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									style={{ background: '#00435D' }}
									class="btn btn-danger"
									data-dismiss="modal"
									onClick={() =>
										this.setState({
											editFlag: true
										})
									}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: context.themeColors.primaryColor, color: 'white' }}>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Class Name</th>
								<th scope="col">Section</th>
								<th scope="col">Class Teacher Name</th>

								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>{renderTodos}</tbody>
					</table>
				</div>
			</div>
						)}
						</ThemeContext.Consumer>
		);

		return (
			<ThemeContext.Consumer>
            {(context) => (
			<div>
				<div classname="page-header">
					<SnackBar backColor={this.state.snackColor} msg={this.state.msg} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div>
							<h2>
								Class Teacher&nbsp;<small class="text-muted">Details</small>
							</h2>
						</div>
						<nav aria-label="Page navigation example">
							<ul style={{ color: context.themeColors.primaryColor }} class="pagination">
								<li
									class={`page-item ${
										this.state.disableBPage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextBack}
										class="page-link"
										href="#"
									>
										{this.state.disableBPage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-backward"></i>
										)}
									</a>
								</li>
								{renderPageNumbers}
								<li
									class={`page-item ${
										this.state.disablepage ? 'disabled' : ''
									}`}
								>
									<a
										onClick={this.handleClickNextFrwd}
										class="page-link"
										href="#"
									>
										{this.state.disablepage ? (
											<i
												style={{ color: 'grey' }}
												class="far fa-stop-circle"
											></i>
										) : (
											<i class="fas fa-forward"></i>
										)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>

				{isLoading ? Loader : MainContent}
			</div>
						)}
						</ThemeContext.Consumer>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}

export default connect(
	null,
	mapDispatchToProps
)(ViewClassTeacherRenew);

import React from "react";
import ViewRendering from "../components/ViewRendering";
import { renderingStuff, images } from "../utils";
import DashboardLayout from "../components/HOC/DashboardLayout";
import NewViewRendering from "../components/ViewRendering/NewViewRendering";

export default function TeacherEvaluationDashboard(props) {
  return (
    <div>
      <DashboardLayout
        name="Teacher Evaluation"
        image={images.imgAddTeacherEvaluation}
      >
        <NewViewRendering
          {...props}
          data={renderingStuff.TeacherEvaluation_Dashboard_Links}
        />
      </DashboardLayout>
      {/* <h3 class="h3-responsive font-weight-bold text-center my-4">
				Teacher Evaluation Module's
			</h3>
			<div
				class="row"
				style={{
					justifyContent: 'center',
					textAlign: 'center',
					marginBottom: -30
				}}
			>
				<div class="col-md-8">
					<div class="md-form mb-0">
						<hr class="style-head" />
					</div>
				</div>
			</div>
			<ViewRendering
				centered={true}
				centerWidth={'50%'}
				data={renderingStuff.TeacherEvaluation_Dashboard_Links}
				mainHead={false}
			/> */}
    </div>
  );
}

import { General } from "..";
let errors = {};

function getStudentDetailsFields(
  state,
  stateData,
  editMode = false,
  dispatch,
  ...args
) {
  console.log(args);
  debugger;
  // for post purpose
  // let getDepartData = stateData && stateData.depart;
  // end post purpose data

  // let getFilteredSection = [];
  // let getFilteredPost = [];
  // let getFilteredSubjects = [];
  // try {
  // 	getFilteredSection =
  // 		args[5] &&
  // 		args[5].sections.filter(item => item.classId === parseInt(state.classId));
  // 	getFilteredPost =
  // 		args[1] &&
  // 		args[1].post.filter(
  // 			item => item.departmentId === parseInt(state.departmentId)
  // 		);
  // 	getFilteredSubjects =
  // 		args[5] &&
  // 		args[5].subject.filter(item => item.classId === parseInt(state.classId));
  // } catch (e) {}
  debugger;
  const studentFields = [
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "registrationNo",
      inputLabelName: "Registration No",
      errors,
      placeholder: "Registration No",
      readOnly: true,
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "admissionNo",
      inputLabelName: "Admission No",
      errors,
      placeholder: "RegNo-FM(code)-(F/M)-Year",
      readOnly: true,
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "fullNameArabic",
      inputLabelName: "Arabic Name",
      errors,
      placeholder: "Arabic Name",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "firstName",
      inputLabelName: "First Name",
      errors,
      placeholder: "First Name",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "lastName",
      inputLabelName: "Last Name",
      errors,
      placeholder: "Last Name",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "fatherName",
      inputLabelName: "Father Name",
      errors,
      placeholder: "Father Name",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "rollNo",
      inputLabelName: "Roll No",
      errors,
      placeholder: "Roll No",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "parentId",
      inputLabelName: "Parent Id",
      errors,
      placeholder: "Enter Parent Id",
      // readOnly={true}
      design: true,
      type: "text",
    },
    {
      type: "date",
      marginTop: 0,
      feildName: "dob",
      inputLabelName: "Date Of Birth",
      dispatchFlag: true,
      stateData,
      dispatch,
      extraType: "age",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      // onHandleChange: General.handleDumpInputChange,
      stateData,
      feildName: "age",
      inputLabelName: "Age",
      errors,
      placeholder: "Age",
      readOnly: true,
      design: true,
      type: "text",
    },
    {
      marginTop: 0,
      feildName: "religion",
      selectName: "Religion",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.religion,
      selectedText: "Choose Religion...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      feildName: "gender",
      selectName: "Gender",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.gender,
      selectedText: "Choose Gender...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      feildName: "nationality",
      selectName: "Nationality",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.nationality,
      selectedText: "Choose Nationality...",
      optionType: "static",
      type: "select",
    },
    {
      marginTop: 0,
      selectName: "Campus Name",
      feildName: "campusId",
      selectedText: "Choose Campus...",
      // optionsArrays: args[2],
      optionsArrays: [],
      errors,
      stateData: stateData,
      optionType: "dynamic",
      // onHandleChange: General.handleDumpInputChange,
      design: true,
      isValidte: false,
      dispatchFlag: true,
      dispatch,
      handleFlag: false,
      propterty: "campusName",
      propertyId: "campusId",
      type: "select",
    },
    {
      marginTop: 0,
      selectName: "Class",
      feildName: "classId",
      selectedText: "Choose Class",
      // optionsArrays: args[0],
      optionsArrays: [],
      errors,
      stateData: state,
      optionType: "dynamic",
      // onHandleChange: General.handleDumpInputChange,
      design: true,
      isValidte: false,
      handleFlag: false,
      dispatchFlag: false,
      dynamicDispatchFlag: true,
      type: "select",
      propterty: "className",
      propertyId: "classId",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      // onHandleChange: General.handleDumpInputChange,
      stateData,
      feildName: "gradeName",
      inputLabelName: "GradeName",
      errors,
      placeholder: "GradeName",
      readOnly: true,
      design: true,
      type: "text",
    },
    {
      marginTop: 0,
      selectName: "Section",
      feildName: "sectionId",
      selectedText: "Choose Section",
      // optionsArrays: editMode
      // 	? getFilteredSection
      // 	: stateData && stateData.grade,
      optionsArrays: [],

      errors,
      stateData: stateData,
      optionType: "dynamic",
      // onHandleChange: General.handleDumpInputChange,
      design: true,
      isValidte: false,
      dispatchFlag: true,
      dispatch,
      handleFlag: false,
      propterty: "section",
      propertyId: "sectionId",
      type: "select",
    },

    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "userName",
      inputLabelName: "Username",
      errors,
      placeholder: "Username",
      focusEvent: General.checkBlur,
      focus: true,
      // readOnly: !stateData['isAccountAccess'],
      design: true,
      type: "text",
    },
    {
      enterWidth: 300,
      customWidth: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      stateData: state,
      feildName: "password",
      inputLabelName: "Password",
      errors,
      placeholder: "Password",
      readOnly: false,
      // readOnly:
      // 	editMode && !args[6]
      // 		? false
      // 		: args[6] === true
      // 			? true
      // 			: !stateData['isAccountAccess'],

      design: true,
      type: "text",
    },
    {
      marginTop: 0,
      feildName: "status",
      selectName: "Account Status",
      design: true,
      isValidte: false,
      onHandleChange: General.handleDumpInputChange,
      handleFlag: true,
      stateData: state,
      errors,
      optionsArrays: General.status,
      selectedText: "Choose Status...",
      optionType: "static",
      type: "select",
    },
  ];
  return studentFields;
}
function getStudentDocsDetails(stateData, dispatch) {
  const studentDocs = [
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Date Of Birth Certificate",
      dispatchFlag: true,
      idForYes: "customRadioDOBCY",
      idForNo: "customRadioDOBCN",
      feildName: "dobCertificate",
      uploadName: "fileNameDobCertificate",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Transfer Certificate",
      dispatchFlag: true,
      idForYes: "customRadioTCY",
      idForNo: "customRadioTCN",
      feildName: "transferCertificate",
      uploadName: "fileNameTransferCertifcate",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Ministry Vaccination",
      dispatchFlag: true,
      idForYes: "customRadioMVY",
      idForNo: "customRadioSMVN",
      feildName: "ministryVaccination",
      uploadName: "fileNameMinistryVaccination",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Passport Copy",
      dispatchFlag: true,
      idForYes: "customRadioSAPCY",
      idForNo: "customRadioSAPCN",
      feildName: "passportCopy",
      uploadName: "fileNamePassportCopy",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Id Card Copy",
      dispatchFlag: true,
      idForYes: "customRadioSAIDY",
      idForNo: "customRadioSAIDN",
      feildName: "idCardCopy",
      uploadName: "fileNameIdCard",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Medical History",
      dispatchFlag: true,
      idForYes: "customRadioSAMHY",
      idForNo: "customRadioSAMHN",
      feildName: "medicalHistory",
      uploadName: "fileNameMedicalHistory",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Last Result",
      dispatchFlag: true,
      idForYes: "customRadioSALRY",
      idForNo: "customRadioSALRN",
      feildName: "lastResult",
      uploadName: "fileNameLastResult",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Test Result",
      dispatchFlag: true,
      idForYes: "customRadioSATRY",
      idForNo: "customRadioSATRN",
      feildName: "testResult",
      uploadName: "fileNameTestResult",
    },
  ];
  return studentDocs;
}

function getParentDocDetails(stateData, dispatch) {
  const parentDocs = [
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Father Id Card",
      dispatchFlag: true,
      idForYes: "customRadioSAFIDY",
      idForNo: "customRadioSAFIDN",
      feildName: "idCardFather",
      uploadName: "fileNameIdCardFather",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Father Passport",
      dispatchFlag: true,
      idForYes: "customRadioSAFPY",
      idForNo: "customRadioSAFPN",
      feildName: "passportFather",
      uploadName: "fileNamePassportFather",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Id Card Mother",
      dispatchFlag: true,
      idForYes: "customRadioSAIMY",
      idForNo: "customRadioSAIMN",
      feildName: "idCardMother",
      uploadName: "fileNameIdCardMother",
    },
    {
      type: "radio",
      dispatch,
      stateData,
      Name: "Mother Passport",
      dispatchFlag: true,
      idForYes: "customRadioSAPMY",
      idForNo: "customRadioSAPMN",
      feildName: "passportMother",
      uploadName: "fileNamePassportMother",
    },
  ];
  return parentDocs;
}
export default {
  getParentDocDetails,
  getStudentDocsDetails,
  getStudentDetailsFields,
};

import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sharedActions from '../../Actions/sharedActions';
import SelectionInput from '../../components/SelectionInput/SelectionInput';
import general from '../../utils/general';
import Colors from '../../utils/app_constants/colors_constants';
import { Link } from 'react-router-dom';
import StudentProfileHeadComponentRenew from './StudentProfileHeadComponentRenew';
import Spinner from '../../components/Spinner/Spinner';
import httpWithRequiredSetData from '../../hooks/Services/httpWithRequiredSetData';
import themeContext from "../../context/themeContext/ThemeContext";


let tHead = [
	'Class',
	'Section',
	'Session',
	'Status',
	'Term',
	'Subject',
	'Actions'
];

let renderFields = ['className', 'section', 'session', 'status'];

const StudentProgressReportByStudentProfileRenew = props => {
	const [subjectData, setSubjectData] = useState([]);

    const themeColor = useContext(themeContext);

	const [isLoading, setIsLoading] = useState(false);
	const [msg, setSnackMsg] = useState('');
	const [state, setState] = useState({
		accountId: props.name,
		accountType: props.role,
		studentId: '',
		isLoading: '',
		searchStudentId: '',
		errorMsg: '',
		image: '',
		name: '',
		fatherName: '',
		userName: '',
		dob: '',
		contactNo: '',
		arabicName: '',
		skillData: []
	});

	const [isSubjectLoading, subjects] = httpWithRequiredSetData(
		'/api/classSectionGradeTeacher',
		[],
		data => {
			setSubjectData(data['subject']);
		}
	);

	useEffect(() => {
		if (msg || state['errorMsg']) {
			setTimeout(() => {
				setState(prevState => ({
					...prevState,
					errorMsg: false
				}));
			}, 3000);
		}
	});

	const onHandleChange = (e, stateVar) => {
		if (e.target.value !== 'Select Term') {
			const target = e.target;
			setState(prevState => ({
				...prevState,
				[stateVar]: target.value
			}));
		}
	};

	const onHandleTextChange = e => {
		const target = e.target;
		setState(prevState => ({
			...prevState,
			[target.name]: target.value
		}));
	};

	const onSubmitGetStudent = e => {
		e.preventDefault();

		if (state['searchStudentId'] === '') {
			setState(prevState => ({
				...prevState,
				errorMsg: true
			}));
		} else {
			setState(prevState => ({
				...prevState,
				isLoading: true
			}));

			props.sharedActions
				.getDataWithoutDispatch(
					`/api/StudentProfileDetail?studentId=${state['searchStudentId']}`
				)
				.then(success => {
					if (success.image !== null) {
						setState(prevState => ({
							...prevState,
							image: success.image['fileName']
						}));
					} else {
						setState(prevState => ({
							...prevState,
							image: ''
						}));
					}
					setState(prevState => ({
						...prevState,
						isLoading: false,
						studentId: success.stdAdmissionDetial[0].studentId,
						name: success.stdAdmissionDetial[0].name,
						skillData: success.stdAdmissionDetial,
						fatherName: success.stdAdmissionDetial[0].fatherName,
						userName: success.stdAdmissionDetial[0].userName,
						dob: success.stdAdmissionDetial[0].dob,
						contactNo: success.stdAdmissionDetial[0].parentMobileNo,
						arabicName: success.stdAdmissionDetial[0].fullNameArabic
					}));
				})
				.catch(error => {
					setState(prevState => ({
						...prevState,
						isLoading: false,
						skillData: [],
						name: '',
						image: '',
						studentId: '',
						fatherName: '',
						userName: '',
						dob: '',
						contactNo: '',
						arabicName: ''
					}));
				});
		}
	};

	const bodyRendering = data =>
		data &&
		data.map((item, index) => (
			<tr>
				<th
					style={{
						padding: 2,
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						borderColor: themeColor.themeColors.primaryColor
					}}
					scope="row"
				>
					{index + 1}
				</th>
				{renderFields.map((item1, index) => (
					<td
						style={{
							fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
							border: `1px solid ${themeColor.themeColors.primaryColor}`,
							padding: 2,
							borderColor: themeColor.themeColors.primaryColor
						}}
					>
						{item[item1]}
					</td>
				))}
				<td
					class="mb-0"
					style={{
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeColor.themeColors.primaryColor
					}}
					width="20%"
				>
					<SelectionInput
						newFlag="customWidth"
						useFlag={false}
						feildName={`a${index + 1}`}
						onHandleChange={e => onHandleChange(e, `a${index + 1}`)}
						errors={{}}
						optionsArrys={general.term}
						selectedText={'Select Term'}
						stateData={state}
						// iconClassName={'fas fa-building'}
						optionType="static"
						isValidte={false}
						// editUse={'staffEdit'}
					/>
				</td>

				<td
					class="mb-0"
					style={{
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeColor.themeColors.primaryColor
					}}
					width="20%"
				>
					<SelectionInput
						newFlag="customWidth"
						useFlag={false}
						feildName={`b${index + 1}`}
						onHandleChange={e => onHandleChange(e, `b${index + 1}`)}
						errors={{}}
						optionsArrys={general.dataFilteration(
							subjectData,
							['subjectName', 'subjectId'],
							{
								classId: parseInt(item.classId)
							}
						)}
						selectedText={'Select Subject'}
						stateData={state}
						// iconClassName={'fas fa-building'}
						optionType="dynamicWithPropIdAndName"
						property={'subjectName'}
						propertyId={'subjectId'}
						isValidte={false}
						// editUse={'staffEdit'}
					/>
				</td>

				<td
					className="displayElemet"
					style={{
						border: `1px solid ${themeColor.themeColors.primaryColor}`,
						padding: 2,
						borderColor: themeColor.themeColors.primaryColor
					}}
				>
					<Link
						to={`/dashboard/PrintStudentProgressReportStudentProfile/${
							item.studentId
						}/${item.classId}/${item.sectionId}/${state[`b${index + 1}`]}/${
							item.sessionId
						}/${state[`a${index + 1}`]}`}
						onClick={
							state[`a${index + 1}`] === undefined
								? e => e.preventDefault()
								: ''
						}
					>
						<button
							id="firstbutton"
							// onClick={() => this.onEditClick(item.id)}
							style={{
								cursor: 'pointer',
								fontSize: 20,
								border: 'none',
								marginLeft: 5,
								background: 'none',
								marginBottom: 5
							}}
							disabled={state[`a${index + 1}`] !== undefined ? false : true}
						>
							<a href="#" data-toggle="tooltip" title="View!">
								<i
									style={
										state[`a${index + 1}`] !== undefined
											? { color: themeColor.themeColors.primaryColor }
											: { color: '#d3d3d3' }
									}
									class="fas fa-newspaper"
								></i>
							</a>
						</button>
					</Link>
				</td>
			</tr>
		));

	return (
		<React.Fragment>
			<div>
				<h4 class="generalHead" style={{background: themeColor.themeColors.primaryColor}}>Student Progress Report Profile</h4>
			</div>
			<StudentProfileHeadComponentRenew
				stateData={state}
				onSubmitGetStudent={onSubmitGetStudent}
				onHandleTextChange={onHandleTextChange}
			/>

			<br />
			<div>
				<h4 class="generalHead" style={{background: themeColor.themeColors.primaryColor}}>Class Record</h4>
			</div>
			<br />
			{isLoading ? (
				<Spinner />
			) : state['skillData'].length < 1 ? (
				<div
					style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
				>
					<strong>No Data</strong>
				</div>
			) : (
				<div className="table-responsive">
					<table class="table table-hover">
						<thead style={{ background: themeColor.themeColors.primaryColor, color: 'white' }}>
							<tr class="vendorListHeading">
								<th scope="col">#</th>
								{tHead.map(item =>
									item === 'Actions' ? (
										<th
											className="displayElemet"
											style={{
												border: `1px solid ${themeColor.themeColors.primaryColor}`,
												borderColor: Colors.WHITE
											}}
											scope="col"
										>
											{item}
										</th>
									) : (
										<th
											style={{
												border: `1px solid ${themeColor.themeColors.primaryColor}`,
												borderColor: Colors.WHITE
											}}
											scope="col"
										>
											{item}
										</th>
									)
								)}
							</tr>
						</thead>
						<tbody>{bodyRendering(state['skillData'])}</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		name: state.setUserReducer.users.unique_name,
		role: state.setUserReducer.users.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sharedActions: bindActionCreators(sharedActions, dispatch)
	};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudentProgressReportByStudentProfileRenew);

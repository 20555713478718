import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as sharedActions from "../Actions/sharedActions";
import LoaderWrapper from "../components/HOC/LoaderWrapper";
import images from "../utils/images/index";

class AcademicFormViews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      class: "",
      section: "",
      subject: "",
      post: "",
      userName: "",
      loading: true,
    };
  }
  componentDidMount() {
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/GetAcademicStaffDetail/?id=${this.props.match.params.id}`
      )
      .then((success) => {
        debugger;
        this.setState({
          name: success.name,
          subject: success.subjectName,
          class: success.className,
          section: success.section,
          post: success.postHOD,
          userName: success.userName,
          searchCheck: false,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });

        console.log(err);
      });
  }
  render() {
    return (
      <div>
        <LoaderWrapper isLoading={this.state.loading}>
          <div class="colCheckb">
            <div class="row justify-content-between" style={{ margin: 5 }}>
              <div class="col-md-4">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Name: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {this.state.name}
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Class: &nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {this.state.class}
                  </label>
                </div>
              </div>
            </div>
            <div class="row justify-content-between" style={{ margin: 5 }}>
              <div class="col-md-4">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Subject: &nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {this.state.subject}
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Section: &nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {this.state.section}
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 text-center">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Post: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label
                    for="name"
                    class=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#01425e",
                    }}
                  >
                    {this.state.post}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row justify-content-center mt-2">
            <div className="col-md-6">
              <Link to={`/dashboard/HODDescriptiveView/${this.state.userName}`}>
                <div class="card shadow-lg p-1 mb-5 bg-white rounded">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <img
                          src={images.imgAcademicDescriptive}
                          class="img-fluid"
                          alt="Responsive image"
                          height="120"
                          width="120"
                        />
                      </div>
                      <div class="vl col-md-1"></div>

                      <div class="col-md-6 text-left">
                        <h5 style={{ color: "#f04f24" }}>Descriptive Report</h5>

                        <label className="text-muted">
                          View Descriptive Report
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-6">
              <Link
                to={`/dashboard/HODSubjectDisciplineView/${this.state.userName}`}
              >
                {" "}
                <div class="card shadow-lg p-1 mb-5 bg-white rounded">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <img
                          src={images.imgAcademicDescriptive}
                          class="img-fluid"
                          alt="Responsive image"
                          height="120"
                          width="120"
                        />
                      </div>
                      <div class="vl col-md-1"></div>

                      <div class="col-md-6 text-left">
                        <h5 style={{ color: "#f04f24" }}>
                          View Subject Discipline
                        </h5>

                        <label className="text-muted">HOD</label>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6">
              <Link to={`/dashboard/AcademicHeadResultSheet`}>
                {" "}
                <div class="card shadow-lg p-1 mb-5 bg-white rounded">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <img
                          src={images.imgAcademicDescriptive}
                          class="img-fluid"
                          alt="Responsive image"
                          height="120"
                          width="120"
                        />
                      </div>
                      <div class="vl col-md-1"></div>

                      <div class="col-md-6 text-left">
                        <h5 style={{ color: "#f04f24" }}>CA View</h5>

                        <label className="text-muted">HOD</label>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6">
              <Link
                to={`/dashboard/HODEvaluationFormView/${this.state.userName}`}
              >
                {" "}
                <div class="card shadow-lg p-1 mb-5 bg-white rounded">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <img
                          src={images.imgAcademicDescriptive}
                          class="img-fluid"
                          alt="Responsive image"
                          height="120"
                          width="120"
                        />
                      </div>
                      <div class="vl col-md-1"></div>

                      <div class="col-md-6 text-left">
                        <h5 style={{ color: "#f04f24" }}>
                          Evaluation Form View
                        </h5>

                        <label className="text-muted">HOD</label>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6">
              <Link to={`/dashboard/HODSkillsView/${this.state.userName}`}>
                <div class="card shadow-lg p-1 mb-5 bg-white rounded">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <img
                          src={images.imgAcademicSkill}
                          class="img-fluid"
                          alt="Responsive image"
                          height="120"
                          width="120"
                        />
                      </div>
                      <div class="vl col-md-1"></div>

                      <div class="col-md-6 text-left">
                        <h5 style={{ color: "#f04f24" }}>Skill Report</h5>

                        <label className="text-muted">
                          View Student Skill Report
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6">
              <Link
                to={`/dashboard/HODClassDisciplineView/${this.state.userName}`}
              >
                <div class="card shadow-lg p-1 mb-5 bg-white rounded">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <img
                          src={images.imgClassDiscipline}
                          class="img-fluid"
                          alt="Responsive image"
                          height="120"
                          width="120"
                        />
                      </div>
                      <div class="vl col-md-1"></div>

                      <div class="col-md-6 text-left">
                        <h5 style={{ color: "#f04f24" }}>
                          Class Dsicipline Report
                        </h5>

                        <label className="text-muted">
                          View Class Discipline
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </LoaderWrapper>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    allTransportState: state.setStudentSkillReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AcademicFormViews);

import React, { Component } from "react";
import DatePicker from "react-date-picker";
import DateandTime from "./DateandTime";
import * as driverActions from "../../Actions/Driver";
import * as sharedActions from "../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DriverHistory from "./DriverHistory";
import ThemeContext from "../../context/themeContext/ThemeContext";

class AddDriver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select: "",
      vehicleId: "",
      driverName: "",
      age: "",
      driverMobileNo: "",
      driverIdCardNo: "",
      driverLicense: "",
      driverIdCardNoIssueDate: "23/1/19",
      driverIdCardNoExpiryDate: "24/1/19",
      licenseIssueDate: new Date(),
      licenseExpirayDate: new Date(),
      DriverHistories: [
        {
          fineDetails: "",
          fineFee: "",
          fineType: "",
          ticketNo: "",
        },
      ],
      errorMsg: false,
      msg: false,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.select !== nextProps.selectData) {
      return {
        select: nextProps.selectData,
      };
    }
    return null;
  }

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    const changeArrOfObj = this.state[`${arrName}`];
    changeArrOfObj[index][e.target.name] = e.target.value;
    this.setState({
      [arrName]: changeArrOfObj,
    });
  };
  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange = (date, state) => {
    this.setState({
      [state]: date,
    });
    console.log(date);
  };
  handleChange2 = (date, state) => {
    this.setState({
      [state]: date,
    });
    console.log(date);
  };
  onHandleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    console.log(result);
    this.setState({
      vehicleId: result.id,
    });
  };
  onHandleSubmitCheck = (e) => {
    e.preventDefault();
    const { driverIdCardNo } = this.state;
    if (Object.is(driverIdCardNo, "")) {
      this.setState = {
        checking: false,
      };
    } else {
      this.setState = {
        checking: true,
      };
    }
  };
  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      vehicleId,
      driverName,
      age,
      driverMobileNo,
      driverIdCardNo,
      driverLicense,
      licenseExpirayDate,
      licenseIssueDate,
      DriverHistories,
      driverIdCardNoExpiryDate,
      driverIdCardNoIssueDate,
    } = this.state;
    if (
      Object.is(vehicleId, "") ||
      Object.is(driverName, "") ||
      Object.is(age, "") ||
      Object.is(driverMobileNo, "")
    ) {
      this.setState({
        errorMsg: true,
      });
    } else {
      const data = {
        Drivers: {
          vehicleId,
          driverName,
          age,
          driverMobileNo,
          driverIdCardNo,
          driverLicense,
          licenseIssueDate,
          licenseExpirayDate,
          driverIdCardNoExpiryDate,
          driverIdCardNoIssueDate,
          accountType: this.props.role,
          accountId: this.props.name,
        },
        DriverHistories,
      };
      this.props.sharedActions
        .addRecord("/api/Driver", data, this.props.driverActions.addDriver)
        .then((success) => {
          this.setState({
            errorMsg: false,
            age: "",
            driverName: "",
            driverMobileNo: "",
            driverLicense: "",
            driverIdCardNo: "",
            msg: true,
          });
        })
        .catch((error) => {});
    }
    console.log(this.state);
  };
  componentDidMount() {
    this.props.sharedActions
      .gAllData("/api/Vehicle", this.props.driverActions.getVehicleForDriver)
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidUpdate() {
    if (this.state.msg || this.state.errorMsg) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
        });
      }, 3000);
    }
  }
  Message = (msg, color, fontSize, flag) => {
    if (flag) {
      return (
        <p style={{ fontSize: fontSize }} class={`text-${color}`}>
          {msg}
        </p>
      );
    }
  };
  render() {
    return (
          <ThemeContext.Consumer>
            {(context) => (
      <div>
        <section class="mb-4">
          <h2 class="h1-responsive font-weight-bold text-center my-4 generalHead"
            style={{ background: context.themeColors.primaryColor }}>
            Add Driver
          </h2>

          <p class="text-center w-responsive mx-auto mb-5" />

          <div
            class="row"
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
                <div
                  class="col-md-9 mb-md-0 mb-5"
                  style={{
                    border: "solid",
                    borderColor: context.themeColors.primaryColor,
                    padding: 40,
                    borderRadius: 15,
                    width: "auto",
                  }}
                >
                  {this.Message(
                    "Driver Added Successfully!",
                    "success",
                    20,
                    this.state.msg
                  )}
                  {this.Message(
                    "Please fill the form properly!",
                    "danger",
                    20,
                    this.state.errorMsg
                  )}
                  <form id="contact-form" name="contact-form">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <DateandTime />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-truck-moving animated fadeIn"
                        /> */}
                            Vehicle Name
                          </label>
                          <select
                            onChange={this.onSelectChange}
                            class="custom-select"
                            name="vehicleId"
                          >
                            <option selected={this.state.msg ? true : false}>
                              Choose Vehicle...
                            </option>
                            {this.state.select.map((item, index) => (
                              <option
                                key={index}
                                value={`${JSON.stringify(item)}`}
                              >
                                {item.transportType}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-user-shield animated fadeIn"
                        /> */}
                            Admin Name
                          </label>
                          <input
                            value={this.props.name}
                            readOnly={true}
                            type="text"
                            name="AdminName"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-user-alt animated fadeIn"
                        /> */}
                            Admin Type
                          </label>
                          <input
                            value={this.props.role}
                            readOnly={true}
                            type="text"
                            name="AdminType"
                            class="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="name" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-user-tie animated fadeIn"
                        /> */}
                            Driver Name
                          </label>
                          <input
                            placeholder="Enter Drive Name"
                            type="text"
                            name="driverName"
                            class="form-control"
                            value={this.state.driverName}
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-sort-numeric-up animated fadeIn"
                        /> */}
                            Age
                          </label>
                          <input
                            placeholder="Enter Age"
                            type="text"
                            name="age"
                            className="form-control"
                            onChange={this.onHandleTextChange}
                            value={this.state.age}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-phone animated fadeIn"
                        /> */}
                            Mobile Number
                          </label>
                          <input
                            placeholder="Enter Driver Mobile #"
                            type="text"
                            name="driverMobileNo"
                            className="form-control"
                            onChange={this.onHandleTextChange}
                            value={this.state.driverMobileNo}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-id-badge animated fadeIn"
                        /> */}
                            License Number
                          </label>
                          <input
                            placeholder="Enter Driver License"
                            type="text"
                            name="driverLicense"
                            value={this.state.driverLicense}
                            className="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>

                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-id-badge animated fadeIn"
                        /> */}
                            Id Card Number
                          </label>
                          <input
                            placeholder="Enter Drive Id Card No"
                            type="text"
                            name="driverIdCardNo"
                            value={this.state.driverIdCardNo}
                            className="form-control"
                            onChange={this.onHandleTextChange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-id-badge animated fadeIn"
                        /> */}
                            License Issue Date
                          </label>
                          <br />
                          <DatePicker
                            selected={this.state.licenseIssueDate}
                            onChange={(e) =>
                              this.handleChange(e, "licenseIssueDate")
                            }
                            value={this.state.licenseIssueDate}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{ marginTop: 20 }}>
                        <div class="md-form mb-0">
                          <label for="email" class="">
                            {/* <i
                          style={{ marginRight: 4 }}
                          className="fas fa-id-badge animated fadeIn"
                        /> */}
                            License Expiry Date
                          </label>
                          <br />
                          <DatePicker
                            selected={this.state.licenseExpirayDate}
                            onChange={(e) =>
                              this.handleChange2(e, "licenseExpirayDate")
                            }
                            value={this.state.licenseExpirayDate}
                          />
                        </div>
                      </div>

                      <div>
                        <br />
                        <br />
                        <DriverHistory
                          onHandleTextChange={this.onChangeText}
                          stateData={this.state}
                          updateSpecialArrObjOnChange={
                            this.updateSpecialArrObjOnChange
                          }
                          onClickAddItem={this.onClickAddItem}
                          onClickDeleteItem={this.onClickDeleteItem}
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div class="text-center text-md-right">
                      <button
                        onClick={this.onHandleSubmit}
                        class="buttonHover2"
                        style={{
                          background: context.themeColors.primaryColor,
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                  <div class="status" />
                </div>
          </div>
        </section>
      </div>
              )}
            </ThemeContext.Consumer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedActions: bindActionCreators(sharedActions, dispatch),
    driverActions: bindActionCreators(driverActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    auth: state.setUserReducer.auth,
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
    accountId: state.setUserReducer.users.accountId,
    selectData: state.setDriverReducer.select,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDriver);

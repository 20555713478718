import React from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";

export default function GenerateTable(props) {
  const { Data, Sheet } = props;
  return (
    <React.Fragment>
      {Sheet == "1" ? (
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table text-center">
                <thead
                  class="bordersSpace"
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace" rowSpan="2">
                      NO.
                    </th>
                    <th className="bordersSpace" rowSpan="2">
                      Students
                    </th>
                    <th className="bordersSpace" colSpan="3">
                      Class Work
                    </th>
                    <th className="bordersSpace" colSpan="3">
                      Home Work
                    </th>
                    <th className="bordersSpace" colSpan="3">
                      Forgetting Work
                    </th>
                  </tr>
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace">Warn 1</th>
                    <th className="bordersSpace">Warn 2</th>
                    <th className="bordersSpace">Action Taken</th>
                    <th className="bordersSpace">Warn 1</th>
                    <th className="bordersSpace">Warn 2</th>
                    <th className="bordersSpace">Action Taken</th>
                    <th className="bordersSpace">Warn 1</th>
                    <th className="bordersSpace">Warn 2</th>
                    <th className="bordersSpace">Action Taken</th>
                  </tr>
                </thead>

                <tbody>
                  {Data.map((item, index) => (
                    <tr class="font-weight-bold bordersSpace" key={index}>
                      <td className="bordersSpace">{index + 1}</td>
                      <td class="bordersSpace font-weight-bold">
                        {item.studentName}
                      </td>
                      {item.studentDisciplineList.map((data, indexes) => (
                        <React.Fragment>
                          <td className="bordersSpace">{data.classWrn1}</td>
                          <td className="bordersSpace">{data.classWrn2}</td>
                          <td className="bordersSpace">{data.classAction}</td>
                          <td className="bordersSpace">{data.homeWorkWrn1}</td>
                          <td className="bordersSpace">{data.homeWorkWrn2}</td>
                          <td className="bordersSpace">
                            {data.homeWorkAction}
                          </td>
                          <td className="bordersSpace">
                            {data.forgettenBooksWrn1}
                          </td>
                          <td className="bordersSpace">
                            {data.forgettenBooksWrn2}
                          </td>
                          <td className="bordersSpace">
                            {data.forgettenBooksAction}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      ) : (
        <div className="table-responsive">
          <ThemeContext.Consumer>
            {(context) => (
              <table class="table text-center">
                <thead
                  class="bordersSpace"
                  style={{
                    background: context.themeColors.primaryColor,
                    color: "white",
                  }}
                >
                  <tr class="vendorListHeading bordersSpace">
                    <th className="bordersSpace" rowSpan="2">
                      NO.
                    </th>
                    <th className="bordersSpace" rowSpan="2">
                      Students
                    </th>
                    <th className="bordersSpace" colSpan="3">
                      Late Coming
                    </th>
                    <th className="bordersSpace" colSpan="3">
                      Behaviour
                    </th>
                    <th className="bordersSpace" colSpan="3">
                      Other
                    </th>
                  </tr>
                  <tr class="vendorListHeading">
                    <th className="bordersSpace">Warn 1</th>
                    <th className="bordersSpace">Warn 2</th>
                    <th className="bordersSpace">Action Taken</th>
                    <th className="bordersSpace">Warn 1</th>
                    <th className="bordersSpace">Warn 2</th>
                    <th className="bordersSpace">Action Taken</th>
                    <th className="bordersSpace">Warn 1</th>
                    <th className="bordersSpace">Warn 2</th>
                    <th className="bordersSpace">Action Taken</th>
                  </tr>
                </thead>

                <tbody>
                  {Data.map((item, index) => (
                    <tr class="font-weight-bold" key={index}>
                      <td className="bordersSpace">{index + 1}</td>
                      <td class="bordersSpace font-weight-bold">
                        {item.studentName}
                      </td>
                      {item.studentDisciplineList.map((data, indexes) => (
                        <React.Fragment>
                          <td className="bordersSpace">{data.lateWrn1}</td>
                          <td className="bordersSpace">{data.lateWrn2}</td>
                          <td className="bordersSpace">{data.lateAction}</td>
                          <td className="bordersSpace">{data.behaviourWrn1}</td>
                          <td className="bordersSpace">{data.behaviourWrn2}</td>
                          <td className="bordersSpace">
                            {data.behaviourAction}
                          </td>
                          <td className="bordersSpace">{data.otherWrn1}</td>
                          <td className="bordersSpace">{data.otherWrn2}</td>
                          <td className="bordersSpace">{data.otherAction}</td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ThemeContext.Consumer>
        </div>
      )}
    </React.Fragment>
  );
}

import React, { Component, useState, useEffect } from "react";
import SelectionInput from "../../../components/SelectionInput/SelectionInput";
import LoaderWrapper from "../../../components/HOC/LoaderWrapper";
import useHttp from "../../../hooks/http.js";
import {
  dataFilteration,
  addDataMiddleware,
  isEmptyFieldInArrOBJ,
} from "../../../utils/general/index";
import ListView from "../../../components/List/ListView/ListView.jsx";
import * as sharedActions from "../../../Actions/sharedActions";
import { bindActionCreators } from "redux";
import Button from "../../../components/Button";
import { connect } from "react-redux";
import SnackBar from "../../../components/SnackBar/SnackBar";
import validateInput from "../../../validator/inputValidator";
import { CHECK_MARKS_RANGE } from "../../../validator/inputValidator";
import { Link } from "react-router-dom";
import InfoComponent from "../../../components/InfoComponent/InfoComponent";
import httpWithRequiredSetData from "../../../hooks/Services/httpWithRequiredSetData";
import Colors from "../../../utils/app_constants/colors_constants";
import { months } from "moment";
import { useContext } from "react";
import ThemeContext from "../../../context/themeContext/ThemeContext";
function CaViewExamV2Feature(props) {
  const themeContext = useContext(ThemeContext);
  const [error, setError] = useState({});
  const { type, pid } = props.match.params;
  const [editStudentData, setEditStudentData] = useState([]);
  const [snackbarMessage, setMsg] = useState("");
  const [studentData, setStudentData] = useState("");
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [statusCode, setStatusCode] = useState("");
  const [isLoaderNew, setIsLoader] = useState(true);
  const [term, setTerm] = useState("");
  const [sessions, setSessions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [sections, setSections] = useState([]);
  const [msg, setErrorMSg] = useState("");
  const [customLoader, setCustomLoader] = useState(false);
  const [studentListData, setStudentListData] = useState({});

  useEffect(() => {
    setIsLoader(true);
    props.sharedAction
      .getDataWithoutDispatch(`/api/ExamMarkListCA/?pId=${pid}`)
      .then((success) => {
        debugger;
        // setStatusCode(success)
        setStudentListData(success);
        debugger;
        setIsLoader(false);
      })
      .catch((error) => {
        debugger;
        setStatusCode(error.response.status);
        setIsLoader(false);
        setErrorMSg(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
        );
      });
  }, []);

  const [isLoading, fetchSessionData] = useHttp(`/api/Session`, []);
  console.log("fetch Session Data: ", fetchSessionData);
  const onSetTerm = (e) => {
    if (e.target.value == "Choose Term") {
      setTerm("");
    } else {
      setTerm(e.target.value);
    }
  };
  const onChangeMarks = (e, index) => {
    if (
      parseInt(e.target.value) <= parseInt(studentListData["finalExamMarks"]) ||
      e.target.value === ""
    ) {
      let getCloneData = JSON.parse(JSON.stringify(studentListData));
      debugger;
      getCloneData["studentMark"][index]["examMark"] = e.target.value;
      setStudentListData(getCloneData);
    }
  };
  const isValid = (data, validateType) => {
    const { errors, isValid } = validateInput(data, validateType);
    if (!isValid) {
      setError(errors);
    }
    return isValid;
  };
  const onSessionChange = (e) => {
    if (e.target.value !== "Choose Session") setSessionId(e.target.value);
  };
  const onSubjectChange = (e) => {
    if (e.target.value !== "Choose Subject") setSubjectId(e.target.value);
  };

  const onMarksChange = (e) => {};

  const onSearch = () => {
    setCustomLoader(true);
    props.sharedAction
      .getDataWithoutDispatch(
        `/api/StudentListDisciplinary?classId=${classId}&sectionId=${sectionId}&term=${term}&subjectId=${subjectId}&sessionId=${sessionId}`
      )
      .then((success) => {
        let requiredData = addDataMiddleware(
          {
            marks: "",
            classId,
            sessionId,
            subjectId,
            sectionId,
            term,
            accountId: props.name,
            accountType: props.role,
          },
          success
        );
        setStudentData(requiredData);
        setCustomLoader(false);
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response && error.response.status == 404) {
          // 404-Error no understanding of 404 Error
          setStudentData([]);

          setMsg("404 Not Found");
          props.snackbar();
          setCustomLoader(false);
        }
        if (error && error.response && error.response.status == 400) {
          setStudentData([]);
          console.log(error);

          setMsg(error.response.data.Message);
          props.snackbar();

          setCustomLoader(false);
        }
      });
  };

  const onSubmit = () => {
    props.sharedAction
      .patchParticularRecord(`/api/ExamMarkEditList/?pId=${pid}`, {
        FinalTotalCAs: [...studentListData["studentMark"]],
      })
      .then((success) => {
        // setTerm('Choose Term');
        // setSectionId('');
        // setClassId('');
        // setSubjectId('');
        // setSessionId('');
        debugger;
        // setStudentData([]);
        setMsg("Successfully Submitted");
        props.snackbar();
        props.history.push(`/dashboard/ContinousNewToolCreation`);
      })
      .catch((error) => {
        debugger;
        if (error && error.response && error.response.status == 404) {
          //404-Error no understanding of 404 Error
          setMsg("404 Not Found");

          props.snackbar();
        } else {
          setMsg(
            error &&
              error.response &&
              error.response.data &&
              error.response.data.Message
          );
          props.snackbar();
        }
      });
  };
  const handlePrint = () => {
    let html = `<div>
		<style>
		table, th, td {
			border: 1px solid black;
			border-collapse: collapse;
		  }
		  th, td {
			padding: 5px;
		  }
		</style>
		<h1>EXAM MARKS
		</h1>
		Class Name: ${studentListData["primaryInfo"]["className"]}
		<br/>
		Section: ${studentListData["primaryInfo"]["section"]}
		Session: ${studentListData["primaryInfo"]["session"]}
		<br/>
		Subject: ${studentListData["primaryInfo"]["subjectName"]}
		<br/>
		
		GradeName: ${studentListData["primaryInfo"]["gradeName"]}
		<br/>
		<div>
		<table style="width:100%">
		<tr>
		  <th align="left" >Sr #</th>
		  <th align="left" >Name</th>
		  <th align="left" >Marks (${studentListData["finalExamMarks"]}) </th>
		  </tr>
		${
      studentListData &&
      studentListData["studentMark"].map((data, i) => {
        return `<tr>
						<td>${i + 1}</td>
						<td>${data.studentName}</td>
						<td>${data.examMark}</td>
					</tr>`;
      })
    }
	  </table>
		  </div>
	  </div>`;

    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(html);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  return (
    <LoaderWrapper isLoading={isLoaderNew}>
      <iframe
        id="ifmcontentstoprint"
        style={{ height: 0, width: 0, position: "absolute" }}
      ></iframe>
      <div>
        <h2
          style={{
            width: "fit-content",
            background: themeContext.themeColors.primaryColor,
          }}
          className="text-center generalHead"
        >
          VIEW EXAM MARKS
        </h2>
        <SnackBar backColor={"#000"} msg={snackbarMessage} />
        <br />
        {msg !== "" ? (
          <InfoComponent
            classType="text-danger text-center"
            fontSize={25}
            message={msg}
          />
        ) : (
          ""
        )}
        {error["rangeError"] ? (
          <InfoComponent
            classType="text-danger"
            fontSize={15}
            message={`Note: marks can only be assigned into the range of 0 - 4`}
          />
        ) : (
          ""
        )}
        {msg === "" && !isLoaderNew && statusCode !== 400 ? (
          <LoaderWrapper isLoading={isLoaderNew}>
            <div onClick={() => handlePrint()} style={{ textAlign: "right" }}>
              <i style={{ fontSize: 30 }} className={"fas fa-print"}></i>
              <br />
              Print
            </div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Student Name</th>
                  <th scope="col">
                    Marks ({studentListData["finalExamMarks"]}){" "}
                  </th>
                </tr>
              </thead>

              <tbody>
                {studentListData &&
                  studentListData["studentMark"].map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item && item.studentName}</td>
                      <td>
                        <input
                          value={item.examMark}
                          onChange={(e) => onChangeMarks(e, index)}
                          type="number"
                          name=""
                          id=""
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {studentListData && studentListData["studentMark"].length > 0 ? (
              <Button
                textColor={Colors.WHITE}
                buttonClass="customButton"
                btnName={"Submit"}
                customClause={isEmptyFieldInArrOBJ(
                  studentListData["studentMark"],
                  "examMark"
                )}
                iconSize={19}
                onHandleSubmit={onSubmit}
              />
            ) : null}
          </LoaderWrapper>
        ) : (
          ""
        )}
      </div>
    </LoaderWrapper>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaViewExamV2Feature);

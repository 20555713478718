import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as DescriptiveAction from '../../Actions/DescriptiveReport';
import * as sharedActions from "../../../Actions/sharedActions";
import SnackBar from "../../../components/SnackBar/SnackBar";
import DropDownsForData from "../../DescriptiveReportModule/TermClassSubjectSelect";
import GetStudentList from "./StudentList";

import $ from "jquery";
import { DefaultDraftBlockRenderMap } from "draft-js";
import ThemeContext from "../../../context/themeContext/ThemeContext";
// import TableComponent from "./TableComponent";
class ClassDiscipline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClassDiscipline: [],
      adminData: [],
      modalData: null,
      vehicleNo: "",
      capacity: "",
      transportType: "",
      enabled: false,
      id: null,
      select: [],
      routeId: "",
      routeName: "",
      startingServiceKm: "",
      midServiceKm: "",
      endingServiceKm: "",
      vehicleDateOfReg: "",
      vehicleDateOfRegExpiry: "",
      isLoading: true,
      disablepage: false,
      disableBPage: true,
      currentPage: 1,
      todosPerPage: 10,
      pageCounter: 10,
      msg: "",
      errorMsg: "",
      dropFlag: false,
      selectClassData: "",
      classId: "",
      sectionId: "",
      sections: [],
      subjects: [],
      session: [],
      sessionId: "",
      subjectId: "",
      subjectName: "",
      staffId: "",
      staffName: "",
      term: "",
      description: "",
      btnLoad: false,
      exist: false,
      checking: [],
      searchCheck: true,
      multiple: [],
      badRequest: false,
      errorText: "",
      Loading: true,
    };
  }
  handleClickNextFrwd = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    let pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(adminData.length / this.state.todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("pagelength: ", pageNumbers.length);
    console.log("current page: ", currentPage);
    if (currentPage === pageNumbers.length) {
      this.setState({
        disablepage: true,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
        disableBPage: false,
      }));
    }
  };
  handleClickNextBack = () => {
    const { adminData, todosPerPage, count, currentPage } = this.state;
    console.log("current page: ", currentPage);
    if (currentPage === 1) {
      this.setState({
        disableBPage: true,
        disablepage: false,
      });
    } else {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
      this.setState({
        disablepage: false,
      });
    }
  };
  handleClickNext = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      disablepage: false,
      disableBPage: false,
    });
  };
  // static getDerivedStateFromProps(nextProps, prevState) {
  // 	if (
  // 		prevState.adminData !== nextProps.AllTransportData ||
  // 		prevState.selectClassData !== nextProps.selectData ||
  // 		prevState.session !== nextProps.SessionSelect
  // 	) {
  // 		return {
  // 			adminData: nextProps.AllTransportData,
  // 			selectClassData: nextProps.selectData,
  // 			session: nextProps.SessionSelect
  // 		};
  // 	}
  // 	return null;
  // }
  onSelectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    const sections = this.state.selectClassData.staffClassSection;
    let check = sections.filter((item) => item.classId == result.classId);
    console.log(check);

    this.setState({
      sections: check,
      staffId: check[0].staffId,
      staffName: check[0].staffName,
      classId: result.classId,
      className: result.className,
    });
  };
  onSectionChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    const sections = this.state.selectClassData.staffClassSection;
    let check2 = sections.filter(
      (item) =>
        item.classId == this.state.classId && item.sectionId == result.sectionId
    );
    this.setState({
      subjects: check2,
      sectionId: result.sectionId,
      sectionName: result.section,
    });
    console.log(this.state.staffId);
  };
  onSessionChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    this.setState({
      sessionId: result.sessionId,
      sessionName: result.currentSession,
    });
    console.log(this.state);
  };
  onSubjectChange = (e) => {
    console.log(e.target.value);
    let result = JSON.parse(e.target.value);
    this.setState({
      subjectId: result.subjectId,
      subjectName: result.subjectName,
    });
  };
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
    $(".tooltip").tooltip("hide");

    if (
      this.state.msg ||
      this.state.errorMsg ||
      this.state.exist ||
      this.state.badRequest
    ) {
      setTimeout(() => {
        this.setState({
          msg: false,
          errorMsg: false,
          exist: false,
          badRequest: false,
        });
      }, 3000);
    }
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/DescriptiveReport?userName=${this.props.users.unique_name}`
      )
      .then((success) => {
        this.setState({
          selectClassData: success,
          Loading: false,
        });
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.sharedActions
      .getDataWithoutDispatch("/api/Session")
      .then((success) => {
        this.setState({
          session: success,
        });
      })
      .catch((error) => {});
  }
  onSearchStudent = (e) => {
    this.setState({
      btnLoad: true,
    });
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/ListOfStdDisciplinePost/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&term=${this.state.term}&subjectId=${this.state.subjectId}&sessionId=${this.state.sessionId}`
      )
      .then((success) => {
        let getAllData = [...success];
        let ClassDiscipline = getAllData.map((item, index) => {
          return {
            studentId: item.studentId,
            studentName: item.studentName,
            classId: item.classId,
            subjectId: this.state.subjectId,
            sectionId: this.state.sectionId,
            accountId: this.props.users.unique_name,
            accountType: this.props.users.role,
            sessionId: this.state.sessionId,
            term: this.state.term,
            description: this.state.description,
          };
        });

        this.setState({
          ClassDiscipline,
          isLoading: false,
          btnLoad: false,
          searchCheck: false,
        });
      })
      .catch((err) => {
        this.setState({
          msg: err.response.data.Message,
          // badRequest: true,
          isLoading: false,
          btnLoad: false,
          searchCheck: true,
        });
        this.props.snackbar();

        console.log(err);
      });
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/Vehicle/",
        itemId,
        this.props.routesActions.deleteVehicle
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    const { adminData } = this.state;
    let getData = adminData.filter((item) => item.id === itemId);

    this.setState({
      modalData: getData,
      transportType: getData[0].transportType,
      capacity: getData[0].capacity,
      vehicleNo: getData[0].vehicleNo,
      id: getData[0].id,
      routeName: getData[0].dropOff,
      routeId: getData[0].routeId,
      startingServiceKm: getData[0].startingServiceKm,
      midServiceKm: getData[0].midServiceKm,
      endingServiceKm: getData[0].endingServiceKm,
      vehicleDateOfReg: getData[0].vehicleDateOfReg,
      vehicleDateOfRegExpiry: getData[0].vehicleDateOfRegExpiry,
    });
  };

  onHandleText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  PostCheck = (e, stdId, stdName) => {
    e.preventDefault();
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/CheckIsExistPost/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&term=${this.state.term}&subjectId=${this.state.subjectId}&sessionId=${this.state.sessionId}&studentId=${stdId}`
      )
      .then((success) => {
        this.props.history.push({
          pathname: "/dashboard/AddClassDiscipline",
          state: {
            sessionId: this.state.sessionId,
            sessionName: this.state.sessionName,
            className: this.state.className,
            classId: this.state.classId,
            sectionId: this.state.sectionId,
            sectionName: this.state.sectionName,
            subjectId: this.state.subjectId,
            subjectName: this.state.subjectName,
            staffId: this.state.staffId,
            studentId: stdId,
            studentName: stdName,
            term: this.state.term,
            accountId: this.props.users.unique_name,
            accountType: this.props.users.role,
          },
        });
        debugger;
      })
      .catch((error) => {
        this.setState({
          msg: error.response.data.Message,
        });
        this.props.snackbar();
        debugger;
      });
  };

  ViewCheck = (e, stdId, stdName) => {
    e.preventDefault();
    this.props.sharedActions
      .getDataWithoutDispatch(
        `/api/checkIsExistEdit/?classId=${this.state.classId}&sectionId=${this.state.sectionId}&term=${this.state.term}&subjectId=${this.state.subjectId}&sessionId=${this.state.sessionId}&studentId=${stdId}`
      )
      .then((success) => {
        this.props.history.push({
          pathname: "/dashboard/ViewClassDiscipline",
          state: {
            sessionId: this.state.sessionId,
            sessionName: this.state.sessionName,
            className: this.state.className,
            classId: this.state.classId,
            sectionId: this.state.sectionId,
            sectionName: this.state.sectionName,
            subjectId: this.state.subjectId,
            subjectName: this.state.subjectName,
            staffId: this.state.staffId,
            studentId: stdId,
            studentName: stdName,
            term: this.state.term,
            accountId: this.props.users.unique_name,
            accountType: this.props.users.role,
          },
        });
        debugger;
      })
      .catch((error) => {
        this.setState({
          msg: error.response.data.Message,
        });
        this.props.snackbar();
        debugger;
      });
  };

  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  myFunction = () => {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // StepperGeneralButtons the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  onClickDeleteItem = (arrName) => {
    let getStateArr = this.state[`${arrName}`];
    let getDeleteLength = getStateArr.length - 1;
    if (getDeleteLength > 0) {
      let getNewDetails = getStateArr.filter(
        (item, index) => index !== getDeleteLength
      );
      console.log(getNewDetails);
      this.setState({
        [arrName]: [...getNewDetails],
      });
    }
  };

  onClickAddItem = (arrName, obj) => {
    let getStateArr = this.state[`${arrName}`];
    getStateArr = [...getStateArr, obj];
    console.log(arrName);
    this.setState({
      [arrName]: getStateArr,
    });
  };
  updateSpecialArrObjOnChange = (e, index, arrName) => {
    const changeArrOfObj = this.state[`${arrName}`];
    changeArrOfObj[index][e.target.name] = e.target.value;
    this.setState({
      [arrName]: changeArrOfObj,
    });
  };
  onChangeText = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { classId, sessionId, subjectId, sectionId, term } = this.state;
    console.log("All States", this.state);
    console.log("Daata", this.state.ClassDiscipline);
    console.log("Admin Data", this.state.adminData);
    console.log("Classes Ka Data", this.state.selectClassData);
    console.log("Session Ka Data", this.state.session);
    const { session } = this.state.session;
    const { classes } = this.state.selectClassData;

    console.log("modal data: ", this.state.modalData);
    const Loader = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <label style={{ color: "red" }}>No Data</label>
      </div>
    );
    const PreLoading = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div class="spinner-border" style={{ color: "#01AC8A" }}></div>
      </div>
    );
    let pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(this.state.checking.totalCount / this.state.checking.pageSize);
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          class={`page-item ${
            this.state.checking.currentPage === number ? "active" : ""
          } `}
        >
          <a
            key={number}
            id={number}
            onClick={() => this.paginationRequest(number)}
            class="page-link "
            href="#"
          >
            {number}
          </a>
        </li>
      );
    });

    const MainContent = (
      <GetStudentList
        stateData={this.state}
        updateSpecialArrObjOnChange={this.updateSpecialArrObjOnChange}
        addFunction={this.PostCheck}
        viewFunction={this.ViewCheck}
      />
    );
    return (
      <div>
        <SnackBar backColor={"#000"} msg={this.state.msg} />
        {this.state.errorMsg ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Grading Marks Already Exist For This Term
          </p>
        ) : (
          ""
        )}
        {this.state.exist ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Please Fill the Fields
          </p>
        ) : (
          ""
        )}
        {this.state.badRequest ? (
          <p style={{ color: "red", textAlign: "center" }}>
            {this.state.errorText}
          </p>
        ) : (
          ""
        )}
        <div className="page-header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div>
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Student Class Discipline
              </h4>
            </div>
            <nav aria-label="Page navigation example">
              <ul style={{ color: "#01AC8A" }} class="pagination">
                {/* {renderPageNumbers} */}
              </ul>
            </nav>
          </div>
        </div>
        <br />
        {this.state.Loading ? (
          PreLoading
        ) : (
          <div>
            {" "}
            <DropDownsForData
              onChangeDrop={this.onSelectChange}
              onSectionDrop={this.onSectionChange}
              onSubjectDrop={this.onSubjectChange}
              onSessionDrop={this.onSessionChange}
              onHandleChange={this.onHandleText}
              ClassData={classes}
              SessionData={session}
              SectionData={this.state.sections}
              SubjectData={this.state.subjects}
              flag={this.state.dropFlag}
            />
            <br />
            <div class="col-md-12 text-center">
              <ThemeContext.Consumer>
                {(context) => (
                  <button
                    type="submit"
                    disabled={
                      classId !== "" &&
                      sessionId !== "" &&
                      sectionId !== "" &&
                      term !== "" &&
                      subjectId !== ""
                        ? false
                        : true
                    }
                    style={{
                      backgroundColor:
                        classId !== "" &&
                        sessionId !== "" &&
                        sectionId !== "" &&
                        term !== "" &&
                        subjectId !== ""
                          ? context.themeColors.primaryColor
                          : "#ABD0BC",
                      border: "none",
                      fontSize: 20,
                      borderRadius: 4,
                      color: "white",
                    }}
                    onClick={this.onSearchStudent}
                  >
                    Search
                    <i
                      style={{ color: "white", marginLeft: 4 }}
                      class="fas fa-search"
                    ></i>
                    <span
                      style={{ marginBottom: 5 }}
                      class={`${
                        this.state.btnLoad
                          ? "spinner-border spinner-border-sm"
                          : ""
                      }`}
                    ></span>
                  </button>
                )}
              </ThemeContext.Consumer>
            </div>
            <br />
            {this.state.searchCheck ? Loader : MainContent}
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // DescriptiveAction: bindActionCreators(DescriptiveAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    // AllTransportData: state.setDescriptiveReducer.Data,
    // selectData: state.setDescriptiveReducer.selectStudent,
    // SessionSelect: state.setDescriptiveReducer.selectSession,
    // allTransportState: state.setDescriptiveReducer,
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassDiscipline);

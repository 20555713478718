import React, { useEffect, useState, useRef, useContext } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as sharedActions from "../Actions/sharedActions";
import SnackBar from "../components/SnackBar/SnackBar";
import SelectionInput from "../components/SelectionInput/SelectionInput";
import Pagination from "../components/Pagination/Pagination";
import Spinner from "../components/Spinner/Spinner";
import httpWithRequiredSetData from "../hooks/Services/httpWithRequiredSetData";
import general from "../utils/general";
import Colors from "../utils/app_constants/colors_constants";
import CustomFilterSectionLayout from "../components/CustomFilterSectionLayout/CustomFilterSectionLayout";
import ThemeContext from "../context/themeContext/ThemeContext";

let tHead = [
    'Purpose',
    'Status',
    'Approved By',
    'Account ID',
    'Account Type',
    'Actions'
];

let renderFields = [
    'purpose',
    'status',
    'approvedBy',
    'accountId',
    'accountType',
]

const IndentAdminView = props => {
    const themeContext = useContext(ThemeContext);

    const [dId, setDId] = useState(null);
    const [staffId, setStaffId] = useState(null);

    const [accountId, setAccountId] = useState(props.name)

    const [indentData, setIndentData] = useState([]);

    const [msg, setSnackMsg] = useState('');
    const [disableBPage, setdisableBPage] = useState(true);
    const [customPageSize, setCustomPageSize] = useState('10');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [disablepage, setdisablepage] = useState(false);
    const [pagination, setPaginationData] = useState({});
    const mounted = useRef();

    const [searchURL, setSearchURL] = useState(
        `/api/StaffView?pageNumber=1&pageSize=10&staffId=${staffId}`
    );

    const [isLoading, setIsLoading] = useState(true)

    /*     const [
            isLoading,
            fetchData,
            setFetchData,
            setIsLoading
        ] = httpWithRequiredSetData(searchURL, [], data => {
            console.log(staffId);
            
            let pageNumber = [];
            for (let i = 1; i <= data['paginationMetadata']['totalPages']; i++) {
                pageNumber.push(i);
            }
            setPageNumber(pageNumber);
            console.log('const');
    
            setIndentData(data['indentForm']);
    
            setPaginationData(data['paginationMetadata']);
        }); */

    useEffect(() => {
        console.log('Do something after counter has changed', currentPage);
        setIsLoading(true);
        props.sharedActions
            .getDataWithoutDispatch(
                `/api/StaffView?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&staffId=${staffId}`
            )
            .then(success => {
                setIndentData(success['indentForm']);
                setIsLoading(false);
                let pageNumber = [];
                for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                    pageNumber.push(i);
                }
                setPageNumber(pageNumber);
                setPaginationData(success['paginationMetadata']);
            })
            .catch(error => {
                setIsLoading(false);
            });
    }, [currentPage, customPageSize]);


    useEffect(() => {

        function report() {
            return new Promise(resolve => {
                props.sharedActions
                    .getDataWithoutDispatch(
                        `/api/AdminStaffId?userName=${accountId}`,
                    )
                    .then(success => {
                        resolve(success.adminStaff[0].staffId)
                        setStaffId(success.adminStaff[0].staffId)
                    })
                    .catch(err => {
                        setIsLoading(false)
                    })
            })
        }
        async function indentData() {
            const staffId = await report();
            setStaffId(staffId)
            props.sharedActions
                .getDataWithoutDispatch(
                    `/api/StaffView?pageNumber=${currentPage}&pageSize=${parseInt(customPageSize)}&staffId=${staffId}`
                )
                .then(success => {
                    console.log('report done');
                    setIndentData(success['indentForm']);
                    setIsLoading(false);
                    let pageNumber = [];
                    for (let i = 1; i <= success['paginationMetadata']['totalPages']; i++) {
                        pageNumber.push(i);
                    }
                    setPageNumber(pageNumber);
                    setPaginationData(success['paginationMetadata']);
                })
                .catch(error => {
                    console.log('staff done');
                    setIsLoading(false);
                });
        }
        setIsLoading(true)
        indentData();
        return () => {
            $('[data-toggle="tooltip"]').tooltip('hide');
        };
    }, []);

    const handleClickNextFrwd = () => {
        console.log('pagelength: ', pageNumber.length);
        console.log('current page: ', currentPage);
        if (currentPage === pageNumber.length) {
            setdisablepage(true);
        } else {
            setCurrentPage(prevState => prevState + 1);
            console.log(currentPage);

            setdisableBPage(false);
        }
    };


    const handleClickNext = event => {
        setCurrentPage(Number(event.target.id));
        setdisableBPage(false);
        setdisableBPage(false);
    };
    const handleClickNextBack = () => {
        if (currentPage === 1) {
            setdisableBPage(true);
            setdisablepage(false);
        } else {
            setCurrentPage(prevState => prevState - 1);
        }
    };

    const btnTechHover = () => {
        $(document).ready(function () {
            $('button').hover(
                function () {
                    $(this).css('box-shadow', '0px 5px 5px grey');
                    $(this).css('border-color', 'white');
                },
                function () {
                    $(this).css('box-shadow', '0px 0px 0px white');
                }
            );
        });
    };


    const onChangePageSize = e => {
        if (e.target.value !== 'Page Entries') {
            setCustomPageSize(e.target.value);
            setCurrentPage(1);
        }
    };


    const bodyRendering = data =>
        data &&
        data.map((item, index) => (
            <tr>
                <th
                    style={{
                        padding: 2,
                        border: `1px solid ${themeContext.themeColors.primaryColor}`,
                        borderColor: themeContext.themeColors.primaryColor
                    }}
                    scope="row"
                >
                    {index + 1}
                </th>
                {renderFields.map((item1, index) => (
                    <td
                        style={{
                            fontWeight: `${index == 0 ? 'bold' : 'normal'}`,
                            border: `1px solid ${themeContext.themeColors.primaryColor}`,
                            padding: 2,
                            borderColor: themeContext.themeColors.primaryColor
                        }}
                    >
                        {item[item1]}
                    </td>
                ))}
                <td
                    style={{
                        border: `1px solid ${themeContext.themeColors.primaryColor}`,
                        padding: 2,
                        borderColor: themeContext.themeColors.primaryColor
                    }}
                >
                    <Link to={`/dashboard/IndentFormTemplate/${item.id}`}>
                        <button
                            id="firstbutton"
                            // onClick={() => this.onEditClick(item.id)}
                            onMouseOver={btnTechHover()}
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                border: 'none',
                                marginLeft: 5,
                                background: 'none',
                                marginBottom: 5
                            }}
                        >
                            <a href="#" data-toggle="tooltip" title="View!">
                                <i style={{ color: '#01AC8A' }} class="fas fa-list"></i>
                            </a>
                        </button>
                    </Link>

                </td>
            </tr>
        ));

    return (
        <div>
            <SnackBar msg={msg} />

            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h2 className="h1-responsive font-weight-bold text-left my-4 generalHead" style={{background: themeContext.themeColors.primaryColor}}>
                            {' '}
                            Indent Form{' '}
                        </h2>
                    </div>
                    <div
                        style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}
                    >
                        <Pagination
                            disableBPage={disableBPage}
                            disablepage={disablepage}
                            currentPage={currentPage}
                            pageNumber={pageNumber}
                            handleClickNext={handleClickNext}
                            handleClickNextBack={handleClickNextBack}
                            handleClickNextFrwd={handleClickNextFrwd}
                        />
                        <div
                            style={{
                                width: 'fit-content',
                                marginTop: -34,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto'
                            }}
                        >
                            <SelectionInput
                                selectName="Entries"
                                selectFont={14}
                                selectFontWeight={'bold'}
                                newFlag="customWidth"
                                feildName="customPageSize"
                                selectedText={'Page Entries'}
                                optionsArrys={['10', '20', '30', '40', '50']}
                                errors={{}}
                                isValidte={false}
                                stateData={{
                                    customPageSize: customPageSize
                                }}
                                optionType="static"
                                onHandleChange={onChangePageSize}
                                useFlag={false}
                            />
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Page: {currentPage}
                    </span>

                    <span className="customBadge" style={{ fontSize: 14 }}>
                        Total Page: {pagination['totalPages']}
                    </span>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) : (
                    <div className="table-responsive">
                        <table class="table table-hover">
                            <thead style={{ background: themeContext.themeColors.primaryColor, color: 'white' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    {tHead.map(item => (
                                        <th
                                            style={{
                                                border: `1px solid ${themeContext.themeColors.primaryColor}`,
                                                borderColor: Colors.WHITE
                                            }}
                                            scope="col"
                                        >
                                            {item}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>{bodyRendering(indentData)}</tbody>
                        </table>
                    </div>
                )}
        </div>
    )
}
function mapStateToProps(state) {
    return {
        name: state.setUserReducer.users.unique_name
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sharedActions: bindActionCreators(sharedActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndentAdminView);
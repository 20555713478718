import React, { useState, useReducer } from "react";
import { StaffDoc } from "../../../components/Staff";
import student from "../../../hooks/HookReducers/StudentReducer/StudentReducer";
import General from "../../../utils/general";
import Button from "../../../components/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sharedActions from "../../../Actions/sharedActions";
import SnackBar from "../../../components/SnackBar/SnackBar";
import Document_File_Constant from "../../../utils/app_constants/document_file_constants";
import Axios from "axios";
import config from "../../../config/config";
import Colors from "../../../utils/app_constants/colors_constants";
import UploadProgressBar from "../../../components/UploadProgressBar/UploadProgressBar";
import studentFields from "../../../utils/studentFields";
import { useContext } from "react";
import themeContext from "../../../context/themeContext/ThemeContext";
import ThemeContext from "../../../context/themeContext/ThemeContext";
function StudentDocsUpload(props) {
  const themeColor = useContext(themeContext);
  const { id } = props;
  const [customLoader, setCustomLoader] = useState(false);
  const { urlId, type } = props.match.params;
  debugger;
  const [snackbarMessage, setMsg] = useState("");
  const [check, setCheck] = useState(0);
  const { onSkipDocuments } = props;
  const [errors, setErrors] = useState({});
  const [newState, dispatch] = useReducer(
    student.StudentDocsReducer,
    student.StudentDocState
  );
  debugger;
  const docsChange = (e) => {
    e.preventDefault();
    try {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (
        !General.checkForImageOrFile(
          false,
          true,
          errors,
          file.size,
          2.1,
          file.name,
          "fileType",
          setErrors
        )
      ) {
        dispatch({
          type: "onFileChange",
          payload: {
            name: e.target.name,
            value: e.target.files[0],
          },
        });
      } else {
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onUpload = (e) => {
    console.log(newState);
    const {
      dobCertificate,
      transferCertificate,
      ministryVaccination,
      passportCopy,
      idCardCopy,
      medicalHistory,
      lastResult,
      testResult,
      fileNameDobCertificate,
      fileNameTransferCertifcate,
      fileNameMinistryVaccination,
      fileNamePassportCopy,
      fileNameIdCard,
      fileNameMedicalHistory,
      fileNameLastResult,
      fileNameTestResult,
    } = newState;
    let data = new FormData();
    data.append("studentId", id || urlId);

    //parent docs
    if (dobCertificate === "Yes") {
      data.append(
        "filePath_dobCertificate",
        fileNameDobCertificate,
        fileNameDobCertificate.name
      );
      data.append(
        "dobCertificate",
        dobCertificate + "_" + Document_File_Constant.dobCertificate
      );
    } else {
      data.append(
        "dobCertificate",
        dobCertificate + "_" + Document_File_Constant.dobCertificate
      );
      data.append("filePath_dobCertificate", fileNameDobCertificate);
    }
    if (transferCertificate === "Yes") {
      data.append(
        "filePath_transferCertificate",
        fileNameTransferCertifcate,
        fileNameTransferCertifcate.name
      );
      data.append(
        "transferCertificate",
        transferCertificate + "_" + Document_File_Constant.transferCertificate
      );
    } else {
      data.append(
        "transferCertificate",
        transferCertificate + "_" + Document_File_Constant.transferCertificate
      );
      data.append("filePath_transferCertificate", fileNameTransferCertifcate);
    }
    if (ministryVaccination === "Yes") {
      data.append(
        "filePath_ministryVaccination",
        fileNameMinistryVaccination,
        fileNameMinistryVaccination.name
      );
      data.append(
        "ministryVaccination",
        ministryVaccination + "_" + Document_File_Constant.ministryVaccination
      );
    } else {
      data.append(
        "ministryVaccination",
        ministryVaccination + "_" + Document_File_Constant.ministryVaccination
      );
      data.append("filePath_ministryVaccination", fileNameMinistryVaccination);
    }
    if (medicalHistory === "Yes") {
      data.append(
        "filePath_medicalHistory",
        fileNameMedicalHistory,
        fileNameMedicalHistory.name
      );
      debugger;
      data.append(
        "medicalHistory",
        medicalHistory + "_" + Document_File_Constant.medicalHistory
      );
    } else {
      data.append(
        "medicalHistory",
        medicalHistory + "_" + Document_File_Constant.medicalHistory
      );
      data.append("filePath_medicalHistory", fileNameMedicalHistory);
    }
    if (passportCopy === "Yes") {
      data.append(
        "filePath_passportCopy",
        fileNamePassportCopy,
        fileNamePassportCopy.name
      );
      data.append(
        "passportCopy",
        passportCopy + "_" + Document_File_Constant.passportCopy
      );
    } else {
      data.append(
        "passportCopy",
        passportCopy + "_" + Document_File_Constant.passportCopy
      );
      data.append("filePath_passportCopy", fileNamePassportCopy);
    }
    if (idCardCopy === "Yes") {
      data.append("filePath_idCardCopy", fileNameIdCard, fileNameIdCard.name);
      data.append(
        "idCardCopy",
        idCardCopy + "_" + Document_File_Constant.idCardCopys
      );
    } else {
      data.append(
        "idCardCopy",
        idCardCopy + "_" + Document_File_Constant.idCardCopys
      );
      data.append("filePath_idCardCopy", fileNameIdCard);
    }
    if (lastResult === "Yes") {
      data.append(
        "filePath_lastResult",
        fileNameLastResult,
        fileNameLastResult.name
      );
      data.append(
        "lastResult",
        lastResult + "_" + Document_File_Constant.lastResult
      );
    } else {
      data.append(
        "lastResult",
        lastResult + "_" + Document_File_Constant.lastResult
      );
      data.append("filePath_lastResult", fileNameLastResult);
    }
    if (testResult === "Yes") {
      data.append(
        "filePath_testResult",
        fileNameTestResult,
        fileNameTestResult.name
      );
      data.append(
        "testResult",
        testResult + "_" + Document_File_Constant.testResult
      );
    } else {
      data.append(
        "testResult",
        testResult + "_" + Document_File_Constant.testResult
      );
      data.append("filePath_testResult", fileNameTestResult);
    }

    data.append("accountId", props.name);
    data.append("accountType", props.role);
    let token = "";
    if (localStorage.jwtToken) token = localStorage.getItem("jwtToken");
    setCustomLoader(true);
    Axios.post(`${config.localhttp}/api/DocumentStudent`, data, {
      headers: { Authorization: `Bearer  ${token}` },
      onUploadProgress: (progressEvent) => {
        console.log(
          "percentage: ",
          setCheck(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          ) + "%"
        );
      },
    })
      .then((success) => {
        debugger;

        debugger;
        setMsg(success.data);
        props.snackbar();
        setCustomLoader(false);
        if (type === "edit") {
          props.history.push("/dashboard/ViewAddForm");
        } else {
          onSkipDocuments(e);
        }
      })
      .catch((error) => {});
  };

  return (
    <div>
      <SnackBar backColor={"#000"} msg={snackbarMessage} />
      <ThemeContext.Consumer>
        {(context) => (
          <h1
            style={{ background: context.themeColors.primaryColor }}
            className="generalHead"
          >
            Students Documents
          </h1>
        )}
      </ThemeContext.Consumer>
      <br />
      <UploadProgressBar percent={check} />
      {/* <div
				style={{
					margin: '0 auto',
					width: 'max-content'
				}}
			> */}
      <StaffDoc
        fieldsData={studentFields.getStudentDocsDetails}
        errors={errors}
        edit={false}
        dispatch={dispatch}
        docsChange={docsChange}
        stateData={newState}
      />
      {/* </div> */}
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginLeft: 10, marginBottom: 5 }}>
          <Button
            btnBackColor={themeColor.themeColors.primaryColor}
            icon={true}
            iconSize={12}
            textColor="#fff"
            iconClass="fas fa-upload"
            btnName="Upload"
            buttonClass="btn-smart-one"
            loaderBmargin={2}
            customClause={
              !newState["dobCertificate"] ||
              !newState["transferCertificate"] ||
              !newState["ministryVaccination"] ||
              !newState["passportCopy"] ||
              !newState["idCardCopy"] ||
              !newState["medicalHistory"] ||
              !newState["lastResult"] ||
              !newState["testResult"]
                ? true
                : false
            }
            stateData={{
              isLoading: customLoader,
            }}
            onHandleSubmit={(e) => onUpload(e)}
          />
        </div>
        {type !== "edit" ? (
          <div>
            <span onClick={onSkipDocuments} class="badge badge-light">
              {" "}
              <a style={{ textDecoration: "none", fontSize: 15 }} href="">
                Skip Step{" "}
                <i
                  style={{
                    fontSize: 13,
                    padding: 5,
                    cursor: "pointer",
                    color: "#000",
                  }}
                  class={"fas fa-forward"}
                ></i>{" "}
              </a>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.setUserReducer.users.role,
    name: state.setUserReducer.users.unique_name,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedAction: bindActionCreators(sharedActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentDocsUpload);

import React from 'react';
import NewViewRendering from "../components/ViewRendering/NewViewRendering";
import { renderingStuff, images } from '../utils';

export default function StaffModuleLMSHomeworkDashbaord(props) {
	return (
		<div>
      <div className="row">
        <div className="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <img
                src={images.imgAcademicLMS}
                class="img-fluid"
                alt="Responsive image"
                height="50"
                width="50"
              />
              <h4 className="mt-2" style={{ letterSpacing: 10 }}>
                Lesson Plan Dashboard
              </h4>
            </div>
          </div>
        </div>
      </div>

      <NewViewRendering {...props} data={renderingStuff.StaffModuleLMS_LessonPlan_Links} />
    </div>
	);
}

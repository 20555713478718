import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as FinanceAction from "../../Actions/Finance";
import * as sharedActions from "../../Actions/sharedActions";
import $ from "jquery";
import "../Admin/viewAdmin.css";
import "./financeForm.css";

class TableComponent extends Component {
  state = {
    // data: this.props.data,
    modalData: null,
    enabled: false,
    account: "",
    description: "",
    depositAmount: "",
    withdrawAmount: "",
    id: "",
    date: "",
    recieptNo: "",
    cashWithdrawBy: "",
    cashDepositeFrom: "",
    depositCheck: false,
  };
  btnTechHover = () => {
    $(document).ready(function () {
      $("button").hover(
        function () {
          $(this).css("box-shadow", "0px 5px 5px grey");
          $(this).css("border-color", "white");
        },
        function () {
          $(this).css("box-shadow", "0px 0px 0px white");
        }
      );
    });
  };
  PrintContent = (el) => {
    var restorepage = document.body.innerHTML;
    var Printcontent = document.getElementById(el).innerHTML;
    document.body.innerHTML = Printcontent;
    window.print();
    document.body.innerHTML = restorepage;
  };
  onEditClick = (itemId) => {
    this.onTakeItem(itemId);
    this.setState({ enabled: false });
  };
  onHandleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const {
      id,
      account,
      description,
      depositAmount,
      withdrawAmount,
    } = this.state;
    var bodyParameters = {
      id,
      account,
      description,
      depositAmount,
      withdrawAmount,
      accountId: this.props.users.unique_name,
      accountType: this.props.users.role,
    };
    this.props.sharedActions
      .editRecord(
        "/api/AccountLedger/",
        id,
        bodyParameters,
        this.props.FinanceAction.updateFinance
      )
      .then((success) => {
        this.setState({ enabled: false, msg: "Edit Record Successfully!" });
        this.props.snackbar();
      })
      .catch((err) => {});
  };
  onTakeItem = (itemId) => {
    this.setState({
      modalData: [],
    });
    let getData = this.props.data.filter((item) => item.id === itemId);

    if (getData[0].depositAmount == 0) {
      this.setState({
        depositCheck: true,
        modalData: getData,
        account: getData[0].account,
        description: getData[0].description,
        depositAmount: getData[0].depositAmount,
        withdrawAmount: getData[0].withdrawAmount,
        date: getData[0].date,
        recieptNo: getData[0].recieptNo,
        id: getData[0].id,
        cashDepositeFrom: getData[0].cashDepositeFrom,
        cashWithdrawBy: getData[0].cashWithdrawBy,
      });
    } else {
      this.setState({
        depositCheck: false,
        modalData: getData,
        account: getData[0].account,
        description: getData[0].description,
        depositAmount: getData[0].depositAmount,
        withdrawAmount: getData[0].withdrawAmount,
        date: getData[0].date,
        recieptNo: getData[0].recieptNo,
        id: getData[0].id,
        cashDepositeFrom: getData[0].cashDepositeFrom,
        cashWithdrawBy: getData[0].cashWithdrawBy,
      });
    }
  };
  onTakeItemDelete = (itemId) => {
    this.setState({
      modalData: [],
    });
    this.props.sharedActions
      .deleteRecord(
        "/api/AccountLedger/",
        itemId,
        this.props.FinanceAction.deleteFinance
      )
      .then((success) => {
        this.setState({
          msg: "Delete Record Successfully!",
        });
        this.props.snackbar();
      })
      .catch((error) => {});
  };
  render() {
    const { modalData } = this.state;

    const MainContent = (
      <div>
        <div class="modal" id="myModal3">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Checking
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                {modalData &&
                  modalData.map((item, index) => (
                    <div key={index}>
                      <div style={{ textAlign: "center" }}>
                        <h4>Are you sure ?</h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <button
                            disabled={this.state.enabled}
                            data-dismiss="modal"
                            onClick={() => this.onTakeItemDelete(this.state.id)}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            Yes
                          </button>
                        </div>
                        <div>
                          <button
                            data-dismiss="modal"
                            disabled={this.state.enabled}
                            type="button"
                            class="btn btn-primary buttonAppear"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div class="modal" id="myModal4">
          <div class="modal-dialog">
            <div class="modal-content modal_content_custom">
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Details
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.onHandleSubmit}>
                  {modalData &&
                    modalData.map((item, index) => (
                      <div key={index}>
                        <fieldset disabled={!this.state.enabled}>
                          <div class="form-group">
                            <label for="routeName">Account:</label>
                            <input
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              type="text"
                              value={this.state.account}
                              onChange={this.onHandleText}
                              name="account"
                              class="form-control"
                              id="account"
                            />
                          </div>
                          <div class="form-group">
                            <label for="routeFees">Description</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.description}
                              onChange={this.onHandleText}
                              type="text"
                              name="description"
                              class="form-control"
                              id="routeFees"
                            />
                          </div>
                          <div class="form-group">
                            <label for="routeFees">Withdrawl Amount</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.withdrawAmount}
                              onChange={this.onHandleText}
                              type="text"
                              name="withdrawAmount"
                              class="form-control"
                              id="routeFees"
                            />
                          </div>
                          <div class="form-group">
                            <label for="routeFees">Deposit Amount</label>
                            <input
                              required
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                boxShadow: "none",
                              }}
                              value={this.state.depositAmount}
                              onChange={this.onHandleText}
                              type="text"
                              name="depositAmount"
                              class="form-control"
                              id="routeFees"
                            />
                          </div>
                        </fieldset>
                        <button
                          disabled={this.state.enabled}
                          onClick={() => this.setState({ enabled: true })}
                          type="button"
                          class="btn btn-primary buttonAppear"
                        >
                          Edit
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          disabled={!this.state.enabled}
                          type="submit"
                          class="btn btn-primary buttonAppear"
                        >
                          Save
                        </button>
                      </div>
                    ))}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  style={{ background: "#00435D" }}
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myModalPrint">
          <div class="modal-dialog">
            <div
              class="modal-content modal_content_custom"
              style={{
                width: 800,
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  borderTopLeftRadius: 18,
                  borderTopRightRadius: 18,
                }}
                class="modal-header modal_custom_header"
              >
                <h4 style={{ color: "white" }} class="modal-title">
                  Print
                </h4>
                <button
                  style={{ color: "white" }}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <div id="PrintWalaDiv">
                  <section class="mb-4">
                    <div class="divclassimg">
                      <img
                        src={require("../../Assets/images/3 headers-03.png")}
                      />
                    </div>
                    <br />
                    <div class="row print">
                      <div class="col-md-12">
                        <div class="md-form mb-0">
                          <h2
                            style={{
                              backgroundColor: "#0A4F5E",
                              textAlign: "center",
                              color: "white",
                            }}
                          >
                            Invoice
                          </h2>
                        </div>
                      </div>
                    </div>
                    {modalData &&
                      modalData.map((item, index) => (
                        <div key={index}>
                          <div class="container">
                            <div class="row rowCheckFinance">
                              <div class="col-sm-2 colCheckFinance">
                                <label style={{ fontWeight: "bold" }}>
                                  Reciept No:
                                </label>
                              </div>
                              <div class="col-sm-2 colCheckFinance">
                                {this.state.depositCheck ? (
                                  <label>WD -> 00{this.state.recieptNo}</label>
                                ) : (
                                  <label>DP -> 00{this.state.recieptNo}</label>
                                )}
                              </div>
                              <div class="col-sm-2 offset-md-4 colCheckFinance">
                                <label style={{ fontWeight: "bold" }}>
                                  Date
                                </label>
                              </div>
                              <div class="col-sm-2 colCheckFinance">
                                {this.state.date}
                              </div>
                            </div>
                            <br />
                            <div class="row" style={{ float: "left" }}>
                              {this.state.depositCheck ? (
                                <div class="col-md-12">
                                  <div class="md-form mb-0">
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 19,
                                      }}
                                    >
                                      Withdrawal Name:
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                      class="borderless"
                                      type="text"
                                      value={this.state.cashWithdrawBy}
                                      readOnly={true}
                                      style={{ width: 400 }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div class="col-md-12">
                                  <div class="md-form mb-0">
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 19,
                                      }}
                                    >
                                      Depositor's Name:
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                      class="borderless"
                                      type="text"
                                      value={this.state.cashDepositeFrom}
                                      readOnly={true}
                                      style={{ width: 400 }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <br />
                            <br />
                            <br />
                            <div
                              class="row justify-content-start"
                              style={{ float: "left" }}
                            >
                              <div class="col-12">
                                <div class="md-form mb-0">
                                  <label
                                    style={{ fontWeight: "bold", fontSize: 19 }}
                                  >
                                    For:
                                  </label>
                                  &nbsp;&nbsp;&nbsp;
                                  <input
                                    class="borderless"
                                    type="text"
                                    value={this.state.account}
                                    readOnly={true}
                                    style={{ width: 525 }}
                                  />
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div
                              class="row justify-content-end rowCheckFinance"
                              style={{ marginTop: 10 }}
                            >
                              <div class="col-4 colCheckFinance">
                                <label style={{ fontWeight: "bold" }}>
                                  Withdrawl Amount:
                                </label>
                              </div>
                              <div class="col-4 colCheckFinance">
                                {this.state.withdrawAmount}/-
                              </div>
                            </div>
                            <div class="row justify-content-end rowCheckFinance">
                              <div class="col-4 colCheckFinance">
                                <label style={{ fontWeight: "bold" }}>
                                  Deposit Amount:
                                </label>
                              </div>
                              <div class="col-4 colCheckFinance">
                                {this.state.depositAmount}/-
                              </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div class="row justify-content-between">
                              <div class="col">
                                <label
                                  style={{ fontWeight: "bold", fontSize: 15 }}
                                >
                                  Manager Sign:
                                </label>
                                &nbsp;&nbsp;&nbsp;
                                <input
                                  class="borderless"
                                  type="text"
                                  readOnly={true}
                                  style={{ width: 200 }}
                                />
                              </div>
                              <div class="col">
                                <label
                                  style={{ fontWeight: "bold", fontSize: 15 }}
                                >
                                  Withdrawal Sign:
                                </label>
                                &nbsp;&nbsp;&nbsp;
                                <input
                                  class="borderless"
                                  type="text"
                                  readOnly={true}
                                  style={{ width: 200 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </section>
                </div>
                <br />
                <a
                  href="#"
                  data-toggle="tooltip"
                  title="Print!"
                  style={{ float: "right" }}
                >
                  <button
                    id="firstbutton"
                    onClick={() => this.PrintContent("PrintWalaDiv")}
                    onMouseOver={this.btnTechHover()}
                    style={{
                      cursor: "pointer",
                      fontSize: 20,
                      border: "none",
                      marginLeft: 5,
                      background: "none",
                      marginBottom: 5,
                    }}
                  >
                    <i class="fas fa-print"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        {this.props.data.map((item, index) => (
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{item.account}</td>
            <td>{item.description}</td>
            <td>{item.withdrawAmount}</td>
            <td>{item.cashWithdrawBy}</td>
            <td>{item.depositAmount}</td>
            <td>{item.cashDepositeFrom}</td>
            <td>{item.date}</td>
            <td>{item.time}</td>
            <td>{item.accountId}</td>
            <td>{item.accountType}</td>

            <td>
              <a href="#" data-toggle="tooltip" title="Edit!">
                <button
                  id="firstbutton"
                  onClick={() => this.onEditClick(item.id)}
                  onMouseOver={this.btnTechHover()}
                  data-toggle="modal"
                  data-target="#myModal4"
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    marginLeft: 5,
                    background: "none",
                    marginBottom: 5,
                  }}
                >
                  <i class="fas fa-pen"></i>
                </button>
              </a>
              <a href="#" data-toggle="tooltip" title="Delete!">
                <button
                  onClick={() => this.onEditClick(item.id)}
                  data-toggle="modal"
                  data-target="#myModal3"
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    background: "none",
                    marginLeft: 5,
                    marginBottom: 5,
                  }}
                >
                  <i style={{ color: "#D11A2A" }} class="fas fa-trash-alt"></i>
                </button>
              </a>
              <a href="#" data-toggle="tooltip" title="Print!">
                <button
                  onClick={() => this.onEditClick(item.id)}
                  data-toggle="modal"
                  data-target="#myModalPrint"
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    border: "none",
                    background: "none",
                    marginLeft: 5,
                    marginBottom: 5,
                  }}
                >
                  <i style={{ color: "#D11A2A" }} class="fas fa-print"></i>
                </button>
              </a>
            </td>
          </tr>
        ))}
        {MainContent}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    FinanceAction: bindActionCreators(FinanceAction, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    users: state.setUserReducer.users,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
